import tpl from './property.html'
import * as PVProperty from "../../models/property/propertyLoaders";

export default {
    bindings: {
        deletepropertyfunc: '=',
        modifyvaluefunc: '=',
        modifypropertyfunc: '=',
        pmd: '='
    },
    controller: [function () {

        this.toggleSettings = function () {
            this.pmd.showSettings = !this.pmd.showSettings;
            if (!this.pmd.property.isEditable) this.pmd.showSettings = false;
        };

        this.options = [];
        this.updateOptions = function () {
            if (this.pmd) {
                let options = [];
                this.pmd.property.values.forEach(function (o) {
                    options.push(o);
                });
                if (this.pmd.multipleValues) {
                    options.unshift({value: null, label: "Multiple"});
                }
                this.options = options;
            }
        };

        this.isPropertyInstanceOf = function (classname) {
            let ret = this.pmd.property instanceof PVProperty[classname];
            return ret;
        };

        this.stringToFunction = function (str) {
            let arr = str.split(".");

            let fn = (window || this);
            for (let i = 0, len = arr.length; i < len; i++) {
                fn = fn[arr[i]];
            }

            if (typeof fn !== "function") {
                throw new Error("function not found");
            }
            return fn;
        };

        this.hasChildProps = function () {
            if (!this.pmd || !this.pmd.property) return false;
            return this.pmd.property.childProperties;
        };

    }],
    template: tpl
}

