import {Layer} from "./layer";
import * as THREE from 'three';
import {Object3D, Vector3} from "three";
import {WireframeElement} from "./wireframeElement";
import RaycastHandler, {RaycastHit} from "./raycastHandler";
import {WireframeApplication} from "./wireframeApplication";



export class Object3DLayer extends Layer {
    object:THREE.Object3D = new THREE.Group()
    parent:THREE.Object3D
    private axesHelper:THREE.AxesHelper
    protected raycastHandler:RaycastHandler

    protected scale:THREE.Vector3 = new THREE.Vector3(1,1,1)

    constructor(app:WireframeApplication, name: string, label: string) {
        super(app, name, label)
        this.object.name = name
    }

    get linearWorldScale():number {
        return Math.abs(1 / this.scale.x)
    }
    get areaWorldScale():number {
        return Math.abs(1 / (this.scale.x * this.scale.x))
    }
    get volumeWorldScale():number {
        return Math.abs(1 / (this.scale.x * this.scale.x * this.scale.x))
    }

    set isVisible(a:boolean) {
        if (this.parent) {
            if (a) {
                this.parent.add(this.object)
                this.object.updateMatrixWorld(true)
            } else {
                this.parent.remove(this.object)
            }
        }
        if (this.object) this.object.visible = a
        super.isVisible = a
    }

    get isVisible() {
        return super.isVisible
    }

    raycast(raycaster:THREE.Raycaster):RaycastHit[] {
        return this.raycastHandler.handleRaycast(raycaster)
    }

    set isAxesHelperVisible(vis:boolean) {
        if (vis) {
            if (!this.axesHelper) this.axesHelper = new THREE.AxesHelper(this.linearWorldScale)
            this.object.add(this.axesHelper)
        } else {
            if (this.axesHelper) this.object.remove(this.axesHelper)
        }
    }


}