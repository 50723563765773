import {DoubleProperty} from "./doubleProperty";
import {WireframeElementType} from "../../application/wireframeElementType";
import {UnitType} from "./unitType";

export class ElevationProperty extends DoubleProperty {
    constructor() {
        super();
        this.type = ".ElevationProperty";
        this.name = "Elevation";
        this.defaultValue = 0;
        this.isEditable = false;
        this.isReadonly = false;
        this.isVisible = true;
        this.allowMultiplePerElement = false;
        this.allowMultiplePerWireframe = false;
        this.allowableElementTypes = [WireframeElementType.vertex];
        this.unitType = UnitType.LINEAR
    }
}