import {Injectable} from "@angular/core";
import WireframeLayer from "../../application/wireframeLayer";
import {EdgeElement} from "../../application/edgeElement";
import {DimensionValue, DistanceProperty} from "../../models/property/distanceProperty";

@Injectable({
    providedIn: 'root',
})

export class Measurement {
    edgeElement: EdgeElement;
    propertyValue: DimensionValue;
}

export class MeasurementService {
    constructor() {
    }

    getMeasurements(layer: WireframeLayer): Measurement[] {
        let measurements: Measurement[] = [];
        if (!layer || !layer.wireframe) return measurements;
        let distanceProp = layer.wireframe.findProperty(DistanceProperty, true);
        layer.wireframeElements.forEach((el) => {
            if (!(el instanceof EdgeElement)) return;
            let val = distanceProp.getValue(el.pvObject);
            if (val) {
                let m = new Measurement();
                m.propertyValue = val;
                m.edgeElement = el;
                measurements.push(m)
            }
        });
        return measurements
    }

    createMeasurement(edge: EdgeElement) {
        let distanceProp = edge.wireframeLayer.wireframe.findProperty(DistanceProperty, true) as DistanceProperty;
        distanceProp.setValue(edge, distanceProp.getDefaultValue());
        distanceProp.apply(edge, distanceProp.getPropertyValue(edge.pvObject));

        if (null == edge.label) edge.label = "Measurement-" + edge.id;

        edge.updateGeometry();
        edge.updateMaterials()
    }

}