import {
    ChangeDetectorRef,
    Component, ElementRef, ViewChild
} from '@angular/core';
import {MatAutocomplete, MatOption} from "@angular/material"
import {SceneService} from "../../services/scenes/scene.service";
import {WireframeElement} from "../../application/wireframeElement";

@Component({
    selector: 'wireframe-search',
    styles: [require('./wireframeSearch.component.scss')],
    template: require('./wireframeSearch.component.html')
})
export default class WireframeSearchComponent {
    @ViewChild(MatAutocomplete, {static: false}) matAutocomplete: MatAutocomplete;
    @ViewChild("searchAutocompleteInput", {static: false}) searchAutocompleteInput: ElementRef;
    wireframeElements:WireframeElement[] = [];
    private _searchInputValue: string = "";

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private sceneService: SceneService
    ) {
        let wireframeElements = this.sceneService.getWireframeElements();
        if(wireframeElements){
            wireframeElements.forEach((wireframeElement) => {
                this.wireframeElements.push(wireframeElement);
            })
        }

        this.sceneService.wireframeElementSelected.subscribe(this.onWireframeElementSelected.bind(this));
    }

    get searchInputValue(): string {
        return this._searchInputValue;
    }

    set searchInputValue(searchInputValue: string) {
        this._searchInputValue = searchInputValue;
    }

    get filteredSearchResults(): Array<any> {
        let filterValue = null;
        if (this.searchAutocompleteInput && this.searchAutocompleteInput.nativeElement && this.searchAutocompleteInput.nativeElement.value !== null) {
            filterValue = this.searchAutocompleteInput.nativeElement.value;
        } else {
            filterValue = this.searchInputValue;
        }
        let filteredSearchResults: Array<any> = filterValue
            ? this.wireframeElements.filter(wireframeElement => wireframeElement.name.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
            : this.wireframeElements.filter(wireframeElement => true);
        return filteredSearchResults;
    }

    onSearchResultSelected(event) {
        let matOption = (event.option as MatOption);
        let searchResultValue = matOption.value;
        let wireframeElement = this.findWireframeElementByName(searchResultValue);
        if(wireframeElement){
            let wireframeElements:WireframeElement[] = [wireframeElement];
            this.unselectAllWireframeElements();
            this.selectWireframeElements(wireframeElements);
            this.lookAtSceneWireframeElements(wireframeElements);
        }
        matOption.deselect();
        this.clearSearchInputValue();
        this.detectChanges();
    }

    onWireframeElementSelected(){
        this.clearSearchInputValue();
    }

    findWireframeElementByName(name:string):WireframeElement{
        let wireframeElement:WireframeElement = null;
        if(!this.wireframeElements) return wireframeElement;
        wireframeElement = this.wireframeElements.find((wireframeElement) => {
            return wireframeElement.name == name;
        });
        return wireframeElement;
    }

    unselectAllWireframeElements(){
        this.sceneService.unselectAllSceneWireframeElements();
    }

    selectWireframeElements(wireframeElements:Array<WireframeElement>){
        this.sceneService.setSceneSelectedWireframeElements(wireframeElements);
    }

    lookAtSceneWireframeElements(wireframeElements:Array<WireframeElement>){
        this.sceneService.lookAtSceneWireframeElements(wireframeElements);
    }

    onSearchAutoCompleteClosed(event) {
        // ignore if something is selected
        if (this.matAutocomplete) {
            let selectedOption = this.matAutocomplete.options.find((option) => {
                if (!option) return false;
                return option.selected;
            });

            if (selectedOption) return;
        }
    }

    clearSearchInputValue(){
        if (this.searchAutocompleteInput && this.searchAutocompleteInput.nativeElement && this.searchAutocompleteInput.nativeElement.value !== null) {
            this.searchAutocompleteInput.nativeElement.value = "";
            this.searchAutocompleteInput.nativeElement.blur();
        }
    }

    onInputKeyUp(){
        if (this.searchAutocompleteInput && this.searchAutocompleteInput.nativeElement && this.searchAutocompleteInput.nativeElement.value !== null) {
            this.searchInputValue = this.searchAutocompleteInput.nativeElement.value;
        }
        this.detectChanges();
    }

    detectChanges(){
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

}