import {ProjectImageModel} from "../../models/projectImages/projectImage.model";
import {InteractiveCollection} from "../interactive/interactive.collection";
import * as util from "typescript-collections/dist/lib/util";
import {EventEmitter} from "@angular/core";
import {ProjectImageCollectionFilterExpressionModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterExpression.model";
import {ProjectImageCollectionFilterModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilter.model";
import {ProjectImageCollectionSortModel} from "../../models/projectImageCollectionSort/projectImageCollectionSort.model";
import {ProjectImageCollectionSortByFieldModel} from "../../models/projectImageCollectionSort/projectImageCollectionSortByField.model";

export default class ProjectImageCollection extends InteractiveCollection<ProjectImageModel> {

    projectImageCollectionSortModel: ProjectImageCollectionSortModel;
    projectImageCollectionFilterChanged: EventEmitter<ProjectImageCollectionFilterModel> = new EventEmitter<ProjectImageCollectionFilterModel>();
    projectImageCollectionFilterExpressionModel: ProjectImageCollectionFilterExpressionModel;

    private _withFilterApplied: ProjectImageModel[] = [];
    private _filterChanged:boolean = true;
    private _dataChanged: boolean = true;
    private projectImageCollectionFilterChangedSubscription;

    constructor() {
        super();
        this.projectImageCollectionSortModel = new ProjectImageCollectionSortByFieldModel(this.defaultSortFieldAccessor.bind(this));
        this.projectImageCollectionFilterExpressionModel = new ProjectImageCollectionFilterExpressionModel();
        this.projectImageCollectionFilterChangedSubscription = this.projectImageCollectionFilterExpressionModel.projectImageCollectionFilterChanged.subscribe(this.onProjectImageCollectionFilterUpdated.bind(this));
    }

    add(item: ProjectImageModel): boolean {
        if (!item) return false;
        this._dataChanged = true;
        return super.add(item);
    }

    remove(item: ProjectImageModel, equalsFunction?: util.IEqualsFunction<ProjectImageModel>): boolean {
        if (item) {
            this._dataChanged = true;
        }
        return super.remove(item, equalsFunction);
    }

    removeElementAtIndex(index: number): ProjectImageModel | undefined {
        let item = super.removeElementAtIndex(index);
        if (item) {
            this._dataChanged = true;
        }
        return item;
    }

    clear(): void {
        super.clear();
        this._dataChanged = true;
    }

    onProjectImageCollectionFilterUpdated(projectImageListFilterModel: ProjectImageCollectionFilterModel) {
        this._filterChanged = true;
        this.triggerProjectImageCollectionFilterChanged(projectImageListFilterModel);
    }

    triggerProjectImageCollectionFilterChanged(projectImageListFilterModel: ProjectImageCollectionFilterModel) {
        this.projectImageCollectionFilterChanged.emit(projectImageListFilterModel);
    }

    get withFilterApplied(): ProjectImageModel[] {
        if (!this.projectImageCollectionFilterExpressionModel) return this._withFilterApplied;
        if(this._filterChanged || this._dataChanged) {
            // run the filter
            let filteredProjectImageCollection: ProjectImageModel[] = this.filter(this.projectImageCollectionFilterExpressionModel.filter.bind(this.projectImageCollectionFilterExpressionModel));
            filteredProjectImageCollection = filteredProjectImageCollection.sort(this.projectImageCollectionSortModel.sort.bind(this.projectImageCollectionSortModel));
            this._withFilterApplied.length = 0;
            this._withFilterApplied.push(...filteredProjectImageCollection);
            this._filterChanged = false;
            this._dataChanged = false;
        }
        return this._withFilterApplied;
    }

    reapplyFilter() {
        let originalWithFilterApplied = this.withFilterApplied;
        this._withFilterApplied = []; // create a new reference
        this._withFilterApplied.push(...originalWithFilterApplied);
    }

    defaultSortFieldAccessor(projectImageModel: ProjectImageModel) {
        return projectImageModel.createdDate;
    }

    get allTags(): Array<string> {
        let tags: Array<string> = [];
        this.forEach((projectImageModel) => {
            if (!projectImageModel) return;
            let projectImageModelTags = projectImageModel.tags;
            projectImageModelTags.forEach((tag) => {
                if (tags.indexOf(tag) >= 0) return;
                tags.push(tag);
            })
        });
        return tags;
    }

    get allAnnotationClasses(): Array<string> {
        let annotationClasses: Array<string> = [];
        this.forEach((projectImageModel) => {
            if (!projectImageModel) return;
            let projectImageModelAnnotationClasses = projectImageModel.classesUsedInAnnotation;
            if (!projectImageModelAnnotationClasses) return;
            projectImageModelAnnotationClasses.forEach((annotationClass) => {
                if (annotationClasses.indexOf(annotationClass) >= 0) return;
                annotationClasses.push(annotationClass);
            })
        });
        return annotationClasses;
    }

    get gpsBounds(): number[][] {
        let bounds: number[][] = [[0, 0], [0, 0]];
        let first = true;
        this.forEach((projectImageModel) => {
            let lat = projectImageModel.gpsLatitude;
            let long = projectImageModel.gpsLongitude;

            // no gps information, skip
            if (typeof lat === "undefined" || lat === null) return;
            if (typeof long === "undefined" || long === null) return;

            if (first) {
                // initialize
                first = !first;
                bounds[0][0] = lat;
                bounds[0][1] = long;
                bounds[1][0] = lat;
                bounds[1][1] = long;
            }

            // min lat
            if (lat < bounds[0][0]) {
                bounds[0][0] = lat;
            }

            // max lat
            if (lat > bounds[1][0]) {
                bounds[1][0] = lat;
            }

            // min long
            if (long < bounds[0][1]) {
                bounds[0][1] = long;
            }

            // max long
            if (long > bounds[1][1]) {
                bounds[1][1] = long;
            }
        });
        return bounds;
    }

    get projectImageModelUrls(): string[]{
        let projectImageModelUrls: string[] = [];
        this.forEach((currentProjectImageModel) => {
            projectImageModelUrls.push(currentProjectImageModel.url);
        });
        return projectImageModelUrls;
    }

    findProjectImageModelByUrl(projectImageModelUrl: string): ProjectImageModel {
        let projectImageModel: ProjectImageModel = null;
        this.forEach((currentProjectImageModel) => {
            if (currentProjectImageModel.url == projectImageModelUrl) {
                projectImageModel = currentProjectImageModel;
            }
        });
        return projectImageModel;
    }
}