import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef, KeyValueDiffers, NgZone,
    OnDestroy,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {ModalService} from "../../services/modals/modal.service";
import {AnnotationModel} from "../../models/annotations/annotation.model";
import AnnotationComponent from "../annotations/annotation.component";
import {ProjectImageCollectionService} from "../../services/projects/projectImageCollection.service";
import {ProjectImageModel} from "../../models/projectImages/projectImage.model";
import {ProjectImageInventoryModel} from "../../models/projectImageInventories/projectImageInventory.model";
import ProjectImageCollection from "../../collections/projectImages/projectImage.collection";
import {SceneService} from "../../services/scenes/scene.service";
import {ProjectImageListFilterType} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterType.enum";
import {ProjectImageCollectionFilterCameraPointViewModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterCameraPointView.model";
import {ProjectImageCollectionSortByCameraPointViewsModel} from "../../models/projectImageCollectionSort/projectImageCollectionSortByCameraPointViews.model";
import {ProjectImageCollectionSortByFieldModel} from "../../models/projectImageCollectionSort/projectImageCollectionSortByField.model";
import {CameraPointView} from "../../models/cameras/cameraPointView";
import {CameraView} from "../../models/cameras/cameraView";
import {UserActivityService} from "../../services/userActivity/userActivity.service";
import {UserActivity} from "../../models/userActivity";

@Component({
    selector: 'project-image-manager',
    styles: [require('./projectImageManager.component.scss')],
    template: require('./projectImageManager.component.html')
})
export default class ProjectImageManagerComponent implements AfterViewInit, OnDestroy {
    // change detection
    private changeDetectorInterval: any;
    private viewInitialized: boolean = false;

    // annotations
    @ViewChild("annotationContainer", {read: ViewContainerRef, static: false}) annotationContainer: ViewContainerRef;
    public annotationComponent: ComponentRef<AnnotationComponent>;
    private annotationModalId: string = 'annotation-modal';
    protected annotationModels: Array<AnnotationModel>;
    protected annotationClasses: Array<string>;

    // tags
    protected allTags: Array<string> = [];

    // project image collection
    private defaultImageCollectionKey: string = 'default';
    private projectImageCollectionKeys: string[] = [];
    private projectImageInventoryModel: ProjectImageInventoryModel;
    protected projectImageCollection: ProjectImageCollection = new ProjectImageCollection();

    // project image collection view
    private projectImageCollectionViewType: string = 'cards';

    private projectImageCollectionFocusedChangingSubscription;
    private projectImageCollectionFocusedChangedSubscription;
    private projectImageCollectionFilterChangedSubscription;
    private sceneVisibleCameraPointViewsChangedSubscription;
    private sceneCameraFocusedSubscription;
    private sceneCameraHighlightedSubscription;

    constructor(
        private zone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
        private differs: KeyValueDiffers,
        private componentFactoryResolver: ComponentFactoryResolver,
        private modalService: ModalService,
        private projectImageCollectionService: ProjectImageCollectionService,
        private sceneService: SceneService,
        private userActivityService: UserActivityService
    ) {
        // listen for focus changes
        this.projectImageCollectionFocusedChangingSubscription = this.projectImageCollection.focusedChanging.subscribe(this.onProjectImageCollectionFocusChanging.bind(this));
        this.projectImageCollectionFocusedChangedSubscription = this.projectImageCollection.focusedChanged.subscribe(this.onProjectImageCollectionFocusChanged.bind(this));
        this.projectImageCollectionFilterChangedSubscription = this.projectImageCollection.projectImageCollectionFilterChanged.subscribe(this.onProjectImageCollectionFilterChanged.bind(this));

        // listen for scene changes
        this.sceneVisibleCameraPointViewsChangedSubscription = this.sceneService.sceneVisibleCameraPointViewsChanged.subscribe(this.onSceneVisibleCameraPointViewsChanged.bind(this));
        this.sceneCameraFocusedSubscription = this.sceneService.sceneCameraFocused.subscribe(this.onSceneCameraFocused.bind(this));
        this.sceneCameraHighlightedSubscription = this.sceneService.sceneCameraHighlighted.subscribe(this.onSceneCameraHighlighted.bind(this));

        // get available image collections
        this.projectImageCollectionKeys = this.projectImageCollectionService.getProjectImageCollectionKeys();

        // load the image collection
        this.loadProjectImageCollection(this.defaultImageCollectionKey);
    }

    ngAfterViewInit(): void {
        this.viewInitialized = true;
        // manage change detection manually
        this.changeDetectorRef.detach();
        this.changeDetectorInterval = setInterval(() => {
            this.detectChanges();
        }, 250);
    }

    ngOnDestroy(): void {
        if (this.changeDetectorInterval) clearInterval(this.changeDetectorInterval);
        if (this.projectImageCollectionFocusedChangingSubscription) this.projectImageCollectionFocusedChangingSubscription.unsubscribe();
        if (this.projectImageCollectionFocusedChangedSubscription) this.projectImageCollectionFocusedChangedSubscription.unsubscribe();
        if (this.projectImageCollectionFilterChangedSubscription) this.projectImageCollectionFilterChangedSubscription.unsubscribe();
        if (this.sceneVisibleCameraPointViewsChangedSubscription) this.sceneVisibleCameraPointViewsChangedSubscription.unsubscribe();
        if (this.sceneCameraFocusedSubscription) this.sceneCameraFocusedSubscription.unsubscribe();
        if (this.sceneCameraHighlightedSubscription) this.sceneCameraHighlightedSubscription.unsubscribe();
    }

    detectChanges() {
        if (!this.viewInitialized) return;
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    performUpdate() {
        this.detectChanges();
    }

    loadProjectImageCollection(imageCollectionKey: string): void {
        this.projectImageInventoryModel = this.projectImageCollectionService.getProjectImageInventoryModel(imageCollectionKey);
        let imageKeys = this.projectImageCollectionService.getProjectImageKeys(imageCollectionKey);
        if (!imageKeys.length) return;

        // add project image models
        this.projectImageCollection.clear();
        imageKeys.forEach((imageKey) => {
            // add the project image
            let projectImageModel = this.projectImageCollectionService.getProjectImageModel(imageCollectionKey, imageKey,);
            this.projectImageCollection.add(projectImageModel);
        });

        // add annotation filters
        this.updateAnnotationFilters();

        // add tag filters
        this.updateTagFilters();

        this.performUpdate();
    }

    updateAnnotationFilters(): void {
        this.annotationClasses = this.projectImageInventoryModel.allAnnotationClasses;
    }

    updateTagFilters(): void {
        this.allTags = this.projectImageCollection.allTags;
    }

    getProjectImageModelMedia(projectImageModel: ProjectImageModel, onFullfilled: (ProjectImageModel) => void = null) {
        let self = this;
        let imageKey = projectImageModel.url;

        // retrieve the image data from storage
        let onProgress = (percent) => {
            // the final handler will set 100% after all properties have been assigned
            if (percent === 100) return;
            projectImageModel.mediaPercentLoaded = percent;
            self.performUpdate();
        };

        this.zone.runOutsideAngular(() => {
            self.projectImageCollectionService.getProjectImageModelMedia(self.projectImageInventoryModel.name, imageKey, onProgress).then(currentProjectImageModel => {
                projectImageModel.mediaUrl = currentProjectImageModel.mediaUrl;
                projectImageModel.mediaPercentLoaded = 100;
                if (onFullfilled) onFullfilled(projectImageModel);
                self.performUpdate();
            })
        });
    }

    getProjectImageModelPrint(projectImageModel: ProjectImageModel, onFullfilled: (ProjectImageModel) => void = null) {
        let self = this;
        let imageKey = projectImageModel.url;

        // retrieve the image data from storage
        let onProgress = (percent) => {
            // the final handler will set 100% after all properties have been assigned
            if (percent === 100) return;
            projectImageModel.printPercentLoaded = percent;
            self.performUpdate();
        };

        this.zone.runOutsideAngular(() => {
            self.projectImageCollectionService.getProjectImageModelPrint(self.projectImageInventoryModel.name, imageKey, onProgress).then(currentProjectImageModel => {
                projectImageModel.printUrl = currentProjectImageModel.printUrl;
                projectImageModel.printPercentLoaded = 100;
                if (onFullfilled) onFullfilled(projectImageModel);
                self.performUpdate();
            })
        });
    }

    getProjectImageModelThumbnail(projectImageModel: ProjectImageModel, onFullfilled: (ProjectImageModel) => void = null) {
        let self = this;
        let imageKey = projectImageModel.url;

        // retrieve the image data from storage
        let onProgress = (percent) => {
            // the final handler will set 100% after all properties have been assigned
            if (percent === 100) return;
            projectImageModel.thumbnailPercentLoaded = percent;
            self.performUpdate();
        };
        this.zone.runOutsideAngular(() => {
            self.projectImageCollectionService.getProjectImageModelThumbnail(self.projectImageInventoryModel.name, imageKey, onProgress).then(currentProjectImageModel => {
                projectImageModel.thumbnailUrl = currentProjectImageModel.thumbnailUrl;
                projectImageModel.thumbnailPercentLoaded = 100;
                if (onFullfilled) onFullfilled(projectImageModel);
                self.performUpdate();
            })
        });
    }

    onViewChanged(projectImageCollectionViewType) {
        this.projectImageCollectionViewType = projectImageCollectionViewType;
        this.performUpdate();
    }

    onProjectImageCollectionKeyChanged(projectImageCollectionKey) {
        this.loadProjectImageCollection(projectImageCollectionKey);
        this.performUpdate();
    }

    onProjectImageCollectionFilterChanged() {

        // is the sort by point visibility
        if (this.projectImageCollection.projectImageCollectionSortModel instanceof ProjectImageCollectionSortByCameraPointViewsModel) {

            // is there an existing point visibility filter?
            let existingProjectImageFilterModel: ProjectImageCollectionFilterCameraPointViewModel = null;
            this.projectImageCollection.projectImageCollectionFilterExpressionModel.projectImageListFilterCollection.forEach((projectImageListFilterModel) => {
                if (projectImageListFilterModel.filterType !== ProjectImageListFilterType.PointVisibility) return;
                existingProjectImageFilterModel = projectImageListFilterModel as ProjectImageCollectionFilterCameraPointViewModel;
            });

            // restore default sort
            if (!existingProjectImageFilterModel) {
                this.projectImageCollection.projectImageCollectionSortModel = new ProjectImageCollectionSortByFieldModel(this.projectImageCollection.defaultSortFieldAccessor);
            }
        }

        this.projectImageCollection.reapplyFilter();
        this.performUpdate();
    }

    onSceneCameraHighlighted(cameraView: CameraView) {
        let projectImageModel = this.findProjectImageModelBySceneCameraView(cameraView);

        // highlight
        if (projectImageModel) {
            projectImageModel.highlighted = true;
        }

        this.performUpdate();
    }

    onSceneCameraFocused(cameraView: CameraView) {
        let projectImageModel = this.findProjectImageModelBySceneCameraView(cameraView);

        // focus
        if (projectImageModel) {
            projectImageModel.focused = true;
        }

        this.performUpdate();
    }

    findProjectImageModelBySceneCameraView(cameraView: CameraView): ProjectImageModel {
        if (!cameraView) return null;
        let frameId = cameraView.frameId;
        if (typeof frameId === 'undefined' || frameId === null) return null;
        let matchingProjectImageModel = null;
        this.projectImageCollection.forEach((projectImageModel) => {
            if (projectImageModel.frameId === frameId) {
                matchingProjectImageModel = projectImageModel;
            }
        });

        return matchingProjectImageModel;
    }

    onSceneVisibleCameraPointViewsChanged(cameraPointViews: CameraPointView[]) {

        // update the filter
        let existingProjectImageFilterModel: ProjectImageCollectionFilterCameraPointViewModel = null;
        this.projectImageCollection.projectImageCollectionFilterExpressionModel.projectImageListFilterCollection.forEach((projectImageListFilterModel) => {
            if (projectImageListFilterModel.filterType !== ProjectImageListFilterType.PointVisibility) return;
            existingProjectImageFilterModel = projectImageListFilterModel as ProjectImageCollectionFilterCameraPointViewModel;
        });
        if (existingProjectImageFilterModel) {
            existingProjectImageFilterModel.cameraPointViews = cameraPointViews;
        } else {
            let projectImageListFilterPointVisibilityModel = new ProjectImageCollectionFilterCameraPointViewModel();
            projectImageListFilterPointVisibilityModel.cameraPointViews = cameraPointViews;
            this.projectImageCollection.projectImageCollectionFilterExpressionModel.addFilter(projectImageListFilterPointVisibilityModel);
        }

        // update the sort
        this.projectImageCollection.projectImageCollectionSortModel = new ProjectImageCollectionSortByCameraPointViewsModel(cameraPointViews);

        // update image models
        this.clearProjectImageModelCameraPointViews();
        this.setProjectImageModelCameraPointViews(cameraPointViews);
        this.performUpdate();
    }

    clearProjectImageModelCameraPointViews() {
        if (!this.projectImageCollection) return;
        this.projectImageCollection.forEach((projectImageModel) => {
            projectImageModel.cameraPointView = null;
        })
    }

    setProjectImageModelCameraPointViews(cameraPointViews: CameraPointView[]) {
        if (!cameraPointViews) return;
        cameraPointViews.forEach((cameraPointView) => {
            let cameraView = cameraPointView.cameraView;
            if (!cameraView) return;
            let projectImageModel = this.findProjectImageModelBySceneCameraView(cameraView);
            if (!projectImageModel) return;
            projectImageModel.cameraPointView = cameraPointView;
        });
    }

    onProjectImageModelVisibilityChange(visibleProjectImageModels: ProjectImageModel[]): void {
        let self = this;
        if (!visibleProjectImageModels) return;
        visibleProjectImageModels.forEach((projectImageModel) => {
            self.getProjectImageModelThumbnail(projectImageModel);
            if (this.projectImageCollectionViewType === 'thumbnails') {
                self.getProjectImageModelPrint(projectImageModel)
            }
        });
        this.performUpdate();
    }

    onProjectImageCollectionFocusChanging() {
        this.closeProjectImageAnnotation();
    }

    onProjectImageCollectionFocusChanged(projectImageModel: ProjectImageModel) {
        this.openProjectImageAnnotation(projectImageModel);
    }

    onAnnotationCloseClick() {
        this.saveProjectImageAnnotation();
        this.closeProjectImageAnnotation();
    }

    onAnnotationPreviousClick() {
        this.gotoPreviousAnnotation();
    }

    onAnnotationNextClick() {
        this.gotoNextAnnotation();
    }

    onAnnotationKeyUpEnter(event) {
        event.preventDefault();
        this.saveProjectImageAnnotation();
        this.closeProjectImageAnnotation();
    }

    onAnnotationKeyUpArrowLeft(event) {
        event.preventDefault();
        this.gotoPreviousAnnotation();
    }

    onAnnotationKeyUpArrowRight(event) {
        event.preventDefault();
        this.gotoNextAnnotation();
    }

    gotoPreviousAnnotation() {
        this.saveProjectImageAnnotation();
        let projectImageModel = this.getCurrentAnnotationImageModel();
        if (!projectImageModel) return;
        let filteredProjectImageArray = this.projectImageCollection.withFilterApplied;
        let currentIndex = filteredProjectImageArray.indexOf(projectImageModel);
        if (currentIndex <= 0) return;
        let previousIndex = currentIndex - 1;
        let previousProjectImageModel = filteredProjectImageArray[previousIndex];
        if (!previousProjectImageModel) return;
        this.clearAnnotationContainer();
        previousProjectImageModel.focused = true;
        this.getProjectImageModelPrint(previousProjectImageModel);

        let backwardBufferCount = 3;
        let backwardBuffer = [];
        for (let i = 0; i < backwardBufferCount; i++) {
            let index = currentIndex - i;
            if (index >= 0) {
                let bufferedProjectImageModel = filteredProjectImageArray[index];
                backwardBuffer.push(bufferedProjectImageModel);
            }
        }
        backwardBuffer.forEach((bufferedProjectImageModel) => {
            this.getProjectImageModelPrint(bufferedProjectImageModel);
        });
    }

    gotoNextAnnotation() {
        this.saveProjectImageAnnotation();
        let projectImageModel = this.getCurrentAnnotationImageModel();
        if (!projectImageModel) return;
        let filteredProjectImageArray = this.projectImageCollection.withFilterApplied;
        let currentIndex = filteredProjectImageArray.indexOf(projectImageModel);
        if (currentIndex >= filteredProjectImageArray.length) return;
        let nextIndex = currentIndex + 1;
        let nextProjectImageModel = filteredProjectImageArray[nextIndex];
        if (!nextProjectImageModel) return;
        this.clearAnnotationContainer();
        nextProjectImageModel.focused = true;
        this.getProjectImageModelPrint(nextProjectImageModel);

        // buffer forward
        let forwardBufferCount = 3;
        let forwardBuffer = [];
        for (let i = 0; i < forwardBufferCount; i++) {
            let index = currentIndex + i;
            if (index < filteredProjectImageArray.length) {
                let bufferedProjectImageModel = filteredProjectImageArray[index];
                forwardBuffer.push(bufferedProjectImageModel);
            }
        }
        forwardBuffer.forEach((bufferedProjectImageModel) => {
            this.getProjectImageModelPrint(bufferedProjectImageModel);
        });
    }

    getCurrentAnnotationImageModel(): ProjectImageModel {
        let projectImageModel: ProjectImageModel = null;
        if (!this.annotationComponent) return projectImageModel;
        if (!this.annotationComponent.instance) return projectImageModel;
        if (!this.annotationComponent.instance.projectImageModel) return projectImageModel;
        projectImageModel = this.annotationComponent.instance.projectImageModel;
        return projectImageModel;
    }

    saveProjectImageAnnotation() {
        if (!this.annotationComponent) return;
        let modified = this.annotationComponent.instance.annotationModified;
        if (!modified) {
            this.userActivityService.registerUserActivity(new UserActivity(false))
            return;
        }
        let projectImageModel = this.annotationComponent.instance.projectImageModel;
        let annotationModels = this.annotationComponent.instance.annotationModels;
        projectImageModel.annotations = annotationModels;

        this.projectImageCollectionService.saveProjectImageModel(this.projectImageInventoryModel.name, projectImageModel);

        // update annotation filters
        this.updateAnnotationFilters();

        // update tag filters
        this.updateTagFilters();
        this.userActivityService.registerUserActivity(new UserActivity(true))
    }

    openProjectImageAnnotation(projectImageModel: ProjectImageModel) {
        if (!projectImageModel) return;

        // listen for media requests
        projectImageModel.thumbnailRequestedEvent.subscribe(() => {
            this.getProjectImageModelThumbnail(projectImageModel);
        });
        projectImageModel.printRequestedEvent.subscribe(() => {
            this.getProjectImageModelPrint(projectImageModel);
        });
        projectImageModel.mediaRequestedEvent.subscribe(() => {
            this.getProjectImageModelMedia(projectImageModel);
        });

        this.getProjectImageModelThumbnail(projectImageModel);
        this.getProjectImageModelPrint(projectImageModel);
        let classes = this.projectImageInventoryModel.allAnnotationClasses;
        this.annotationModels = [];
        if (projectImageModel.annotations) {
            projectImageModel.annotations.forEach((annotation) => {
                let currentAnnotation = new AnnotationModel().deserialize(annotation);
                if (!currentAnnotation.geometry) return;
                this.annotationModels.push(currentAnnotation);
            })
        }
        let annotationComponentFactory = this.componentFactoryResolver.resolveComponentFactory(AnnotationComponent);
        this.annotationComponent = this.annotationContainer.createComponent(annotationComponentFactory);
        this.annotationComponent.instance.projectImageModel = projectImageModel;
        this.annotationComponent.instance.projectImageCollection = this.projectImageCollection;
        this.annotationComponent.instance.annotationModels = this.annotationModels;
        this.annotationComponent.instance.annotationClasses = classes;
        this.openAnnotationModal();
        this.performUpdate();
    }

    closeProjectImageAnnotation() {
        this.clearAnnotationContainer();
        this.closeAnnotationModal();
        this.performUpdate();
    }

    clearAnnotationContainer() {
        this.annotationContainer.clear();
        this.detectChanges();
    }

    openAnnotationModal() {
        this.openModal(this.annotationModalId);
    }

    closeAnnotationModal() {
        this.closeModal(this.annotationModalId);
    }

    openModal(id: string) {
        this.modalService.open(id);
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }
}