import {Theme} from "../services/themes/theme.service";

export const BlueTheme: Theme = {
    name: 'blue',
    properties: {
        '--ptv-theme-primary-color': '#419EF7',
        '--ptv-theme-error-color': '#f14336',
        '--ptv-theme-host-background': '#0F141A',
        '--ptv-theme-host-text-foreground': '#FEFEFE',
        '--ptv-theme-host-text-focused-foreground': '#FFFFFF',
        '--ptv-theme-host-form-accent': '#313d50',
        '--ptv-theme-host-form-label-foreground': '#7f8fa5',
        '--ptv-theme-host-dialog-foreground': '#b9c7d9',
        '--ptv-theme-host-dialog-background': '#1b2431',
        '--ptv-theme-host-header-background': '#273143',
        '--ptv-theme-host-header-logo-foreground': '#419EF7',
        '--ptv-theme-host-header-nav-active-accent': '#419EF7',
        '--ptv-theme-host-header-nav-icon-foreground': '#FFFFFF',
        '--ptv-theme-app-background': '#0F141A',
        '--ptv-theme-app-foreground': '#fefefe',
        '--ptv-theme-app-primary-action-foreground': '#FFFFFF',
        '--ptv-theme-app-primary-action-background': '#34aa44',
        '--ptv-theme-app-header-background': '#0F141A',
        '--ptv-theme-app-header-foreground': '#fefefe',
        '--ptv-theme-list-container-background': '#263142',
        '--ptv-theme-list-item-background': '#263142',
        '--ptv-theme-list-item-header-foreground': '#FFFFFF',
        '--ptv-theme-list-item-paragraph-foreground': '#7F8FA4',
        '--ptv-theme-list-item-icon-foreground': '#FFFFFF',
        '--ptv-theme-swatch-0-background-0': '#101317',
        '--ptv-theme-swatch-0-foreground-0': '#92949a',
        '--ptv-theme-swatch-0-foreground-1': '#1e2126',
        '--ptv-theme-swatch-1-background-0': '#1b2431',
        '--ptv-theme-swatch-1-foreground-0': '#7f8fa5',
        '--ptv-theme-swatch-1-foreground-1': '#222b39',
        '--ptv-theme-swatch-2-background-0': '#273143',
        '--ptv-theme-swatch-2-foreground-0': '#b9c7d9',
        '--ptv-theme-swatch-2-foreground-1': '#7f8fa5',
        '--ptv-theme-swatch-3-background-0': '#e1e3e6',
        '--ptv-theme-swatch-3-foreground-0': '#464952',
        '--ptv-theme-swatch-3-foreground-1': '#9d9fa4'
    }
};