import tpl from './wireframeActions.html'
import PVUtils from '../../pvUtils'
import {PV} from '../../wireframe'
import {WireframeApplication} from "../../application/wireframeApplication";
import {EdgeTypeProperty} from "../../models/property/edgeTypeProperty";
import {WireframeElementType} from "../../application/wireframeElementType";
import {PlaneDetectionMode} from "../../application/planeDetectionMode";
import {LogLevel} from "../../application/logLevel";
import {GeometryTool} from "../../application/geometryTool";
import {PlaneElement} from "../../application/planeElement";
import {VertexElement} from "../../application/vertexElement";
import {SceneService} from "../../services/scenes/scene.service";


export default {
    bindings: {},
    controller: ['apiService', 'sceneService', '$scope', function (apiService, sceneService: SceneService, $scope) {
        let ctrl = this;
        let app: WireframeApplication = sceneService.wireframeApplication

        this.sketch_clearExistingProperties
        this.sketch_enforceSymmetry

        this.canCreatePlane = function () {
            return app.selectedElements.filter(function (el) {
                return el.pvObject.pvType == WireframeElementType.edge;
            }).length >= 3;
        };

        this.createPlane = function () {
            let edges: PV.Edge[] = [];
            app.selectedElements.forEach(function (el) {
                if (el.pvObject.pvType == WireframeElementType.edge) edges.push(el.pvObject as PV.Edge);
            });

            if (edges.length < 3) {
                app.log("Not enough edges selected", LogLevel.DANGER);
                return;
            }
            let plane = app.wireframe.createPlaneFromEdges(edges);
            if (!plane) {
                app.log("Unable to create plane from selected edges", LogLevel.DANGER);
                return;
            }
            if (app.wireframe.findPlaneWithEdgeIds(plane.edgeIds)) {
                app.log("Plane already exists", LogLevel.DANGER);
                return;
            }
            app.planeDetectionMode = PlaneDetectionMode.OFF;
            app.wireframe.addPlane(plane);
            console.log("added plane ", plane);
            app.wireframeLayer.addPlane(plane);
            app.wireframeLayer.redrawWireframe();
        };

        this.toggleElementMode = function (mode) {
            (app.activeTool as GeometryTool).setElementMode(mode);
        };

        this.getElementMode = function () {
            return (app.activeTool as GeometryTool).elementMode;
        };

        this.toggleActionMode = function (mode) {
            (app.activeTool as GeometryTool).setActionMode(mode);
        };

        this.getActionMode = function () {
            return (app.activeTool as GeometryTool).actionMode;
        };

        this.deletePlane = function () {
            let planes = app.selectedElements.filter(function (el) {
                return (el.pvObject.pvType == WireframeElementType.plane);
            }) as PlaneElement[];
            if (planes.length < 1) return;
            app.planeDetectionMode = PlaneDetectionMode.OFF;
            app.startUndoEntry();
            planes.forEach(function (el) {
                app.wireframeLayer.deleteWireframeElement(el, false);
            });
            app.wireframeTopologyChanged();
        };

        this.isPlaneSelected = function () {
            if (app.selectedElements.length < 1) return false;
            return app.selectedElements.find((el) => {
                return el.pvObject.pvType == WireframeElementType.plane
            });
        };

        this.selectChildPlanes = function () {
            sceneService.wireframeApplication.wireframeLayer.selectChildPlanes();
        };

        this.setPlanePrimaryEdge = function () {
            sceneService.wireframeApplication.wireframeLayer.pickPlanePrimaryEdge();
        };

        this.attachSurface = function () {
            sceneService.wireframeApplication.wireframeLayer.attachSurface();
        };

        this.detachSurface = function () {
            app.selectedElements.forEach(function (el) {
                if (el instanceof PlaneElement) el.pvObject.parentId = null;
            });
            app.wireframeLayer.redrawWireframe()
            app.wireframeTopologyChanged();
        };

        this.flipNormal = function () {
            let planes = app.selectedElements.filter(function (el) {
                return (el.pvObject.pvType == WireframeElementType.plane);
            }) as PlaneElement[];
            planes.forEach(function (p: PlaneElement) {
                app.wireframe.flipNormal(p.pvObject);
                p.updateGeometry()
            });
        };

        this.unifyNormals = function () {
            let planes = app.selectedElements.reduce((planes, el) => {
                if (el.pvObject.pvType == WireframeElementType.plane) {
                    planes.push(el.pvObject);
                }
                return planes;
            }, []);
            app.wireframeLayer.unifyNormals(planes, null);
            app.wireframeLayer.redrawWireframe()
        };

        this.detectEdgeTypes = function () {
            apiService.classifyEdges(app.projectId, app.wireframe.toJson()).then(function (o) {
                //console.log("detect edges response", o);
                let wf = new PV.Wireframe();
                wf.fromJson(o.data.data);
                app.wireframe.properties = wf.properties;
                let edgeProp = wf.findProperty(EdgeTypeProperty, false);
                Object.values(wf.edges).forEach(function (edge) {
                    let existingEdge = app.wireframe.edges[edge.id];
                    existingEdge.properties = edge.properties;
                });
                app.propertiesChanged();
                app.wireframeLayer.redrawWireframe();
                if (o.data.success) {

                    app.log("Edge Classification successful");
                } else {
                    app.log("Edge Classification failed", LogLevel.DANGER);
                }
                //wfOps.updateWireframeMaterials();
            });
        };

        this.getSketchParams = function () {
            return {
                enforceSymmetry: !!$scope.sketch_enforceSymmetry,
                clearExistingProperties: !!$scope.sketch_clearExistingProperties
            }
        }
        this.applyMeasurements = function () {
            apiService.applyMeasurements(app.projectId, app.wireframe.toJson(), ctrl.getSketchParams()).then(function (o) {
                //console.log("detect edges response", o);
                app.planeDetectionMode = PlaneDetectionMode.OFF;
                app.setWireframeFromJson(o.data.data);
                if (o.data.success) {
                    app.log("applyMeasurements successful");
                } else {
                    app.log("applyMeasurements failed", LogLevel.DANGER);
                }
            });
        };

        this.generateModelFromSketch = function () {
            apiService.generateModelFromSketch(app.projectId, app.wireframe.toJson(), ctrl.getSketchParams()).then(function (o) {
                //console.log("detect edges response", o);
                app.planeDetectionMode = PlaneDetectionMode.OFF;
                app.setWireframeFromJson(o.data.data);
                if (o.data.success) {
                    app.log("generateModelFromSketch successful");
                } else {
                    app.log("generateModelFromSketch failed", LogLevel.DANGER);
                }
            });
        };

        this.extractPolygons = function () {
            apiService.extractPolygons(app.projectId, app.wireframe.toJson(), ctrl.getSketchParams()).then(function (o) {
                //console.log("detect edges response", o);
                app.planeDetectionMode = PlaneDetectionMode.OFF;
                app.setWireframeFromJson(o.data.data);
                if (o.data.success) {
                    app.log("extractPolygons successful");
                } else {
                    app.log("extractPolygons failed", LogLevel.DANGER);
                }
            });
        };

        this.detectCutouts = function () {
            apiService.detectCutouts(app.projectId, app.wireframe.toJson()).then(function (o) {
                //console.log("detect edges response", o);
                app.planeDetectionMode = PlaneDetectionMode.OFF;
                sceneService.wireframeApplication.setWireframeFromJson(o.data.data);
                sceneService.wireframeApplication.wireframeLayer.alignAllWithGravity();
                /*
                let wf = new PV.Wireframe();
                wf.fromJson(o.data.data);
                app.wireframe.properties = wf.properties;
                let edgeProp = wf.findProperty(PV.EdgeTypeProperty);
                Object.values(wf.edges).forEach(function(edge) {
                    let existingEdge = app.wireframe.edges[edge.id];
                    existingEdge.properties = edge.properties;
                });
                app.propertiesChanged();
                wfOps.redrawWireframe();
                //wfOps.updateWireframeMaterials();
                */
                if (o.data.success) {
                    app.log("Cutout Detection successful");

                } else {
                    app.log("Cutout Detection failed", LogLevel.DANGER);
                }
            });
        };

        this.getValidationResults = function () {
            return app.validationResults.filter(function (result) {
                return result.elements.length > 0
            });
        };

        this.validateWireframe = function () {
            sceneService.wireframeApplication.validateWireframe();
        };

        this.selectElements = function (elements) {
            app.selectWireframeElement(null);
            app.selectWireframeElement(elements, true);
            app.lookAtElements(elements, true);
        };

        this.lastValidationResultIndex = 0;
        this.cycleValidationResult = function (res, direction) {
            if (direction) {
                this.lastValidationResultIndex++;
            } else {
                this.lastValidationResultIndex--;
            }
            if (this.lastValidationResultIndex < 0) this.lastValidationResultIndex = res.elements.length - 1;
            if (this.lastValidationResultIndex >= res.elements.length) this.lastValidationResultIndex = 0;
            if (!this.lastValidationResultIndex) this.lastValidationResultIndex = 0;
            //console.log("result index " + this.lastValidationResultIndex + "/" + res.elements.length);
            this.selectElements([res.elements[this.lastValidationResultIndex]]);
        };

        this.finalize = function () {
            apiService.finalize(app.projectId, app.wireframe.toJson()).then(function (o) {
                //console.log("detect edges response", o);
                app.planeDetectionMode = PlaneDetectionMode.OFF;
                let wf = new PV.Wireframe();
                wf.fromJson(o.data.data);
                app.setWireframeFromJson(wf);
                app.propertiesChanged();
                app.wireframeLayer.redrawWireframe();
                if (o.data.success) {
                    app.log("Finalize successful");
                } else {
                    app.log("Finalize failed", LogLevel.DANGER);
                }
                //wfOps.updateWireframeMaterials();
            });
        };

        this.registerWireframe = function () {
            (app.activeTool as GeometryTool).performWireframeRegistration();
        };

        this.eraseAll = function () {
            app.startUndoEntry();
            let verts = app.wireFrameElements.filter(function (el) {
                return el.pvObject.pvType == WireframeElementType.vertex
            }) as VertexElement[];
            verts.forEach(function (el) {
                app.wireframeLayer.deleteVertex(el, false);
            });
            app.wireframeLayer.redrawWireframe();
        };

        this.resumeFrom = async (stepType: string, mode: string = null) => {
            let workOrderRequest = await apiService.getWorkOrderByProjectId(app.projectId)
            let workOrderId = workOrderRequest.data.data.id;
            let stepsRequest = await apiService.getStepsByProjectId(app.projectId)
            let steps = stepsRequest.data.data
            let step = steps.filter(step => step.properties.core.step.step_type === stepType)[0]
            apiService.restartProcessingAtStep(workOrderId, step.id)
            if(mode){
                this.buttonModes[mode].disabled = true
                setTimeout(() => {
                    this.buttonModes[mode].disabled = false
                }, 30_000)
            }
        }

        this.resumeFromFirstFailedStep = async () => {
            let workOrderRequest = await apiService.getWorkOrderByProjectId(app.projectId)
            let workOrderId = workOrderRequest.data.data.id;
            apiService.resumeFromFirstFailedStep(workOrderId)
            let mode = this.buttonModes.resumeFromFirstFailedStep;
            mode.disabled = true
            setTimeout(() => {
                mode.disabled = false
            }, 30_000)
        }

        this.updateWireframeActionStatus = function () {
            ctrl.wireframeActionStatus = {};
            if (!app.projectData.requests) return;
            Object.values(app.projectData.requests).forEach(function (rs: any) {
                //let rs = app.projectData.requests[rsKey];
                if (!rs.request) return;
                if (rs.request.consumerType == 23) {
                    let existing = ctrl.wireframeActionStatus['plane'];
                    if (!existing || (rs.request.time > existing.request.time)) {
                        ctrl.wireframeActionStatus['plane'] = rs;
                    }
                }
            });
        };

        this.wireframeActionStatus = {};

        this.buttonModes = {
            resumeFromPlaneRefine: {
                disabled: false,
                display: 'Resume Refine'
            },
            resumeFromPenetrations: {
                disabled: false,
                display: 'Resume Penetrations'
            },
            resumeFromGenerateOutputs: {
                disabled: false,
                display: 'Resume Outputs'
            },
            resumeFromPublish: {
                disabled: false,
                display: 'Resume Publish'
            },
            resumeFromFirstFailedStep: {
                disabled: false,
                display: 'Resume Failed'
            },
        }

        this.getModeDisplayString = (mode) => (this.buttonModes[mode] || {}).display || `${mode} refine`

        this.getWireframeActionStatusString = function (mode) {
            this.updateWireframeActionStatus();
            let str = this.getModeDisplayString(mode);
            let rs = this.wireframeActionStatus[mode];
            if (rs) {
                if (rs.status) {
                    let symbol = "";
                    if (rs.status.isComplete) {
                        if (rs.status.isSuccess) {
                            symbol = '<i class="material-icons materialIconInline">done</i>';
                        } else if (rs.status.isCanceled) {
                            symbol = '<i class="material-icons materialIconInline">clear</i>';
                        } else {
                            symbol = '<i class="material-icons materialIconInline">error</i>';
                        }
                    } else {
                        symbol = '<i class="material-icons materialIconInline">hourglass_empty</i>';
                    }
                    str += " <br /> (" + this.formatPercent(rs.status.percentComplete) + "% " + symbol + ")";
                } else {
                    str += " (0%)";
                }
            } else {

            }
            return str;
        };

        this.wireframeActionButtonDisabled = mode => {
            if (mode in this.buttonModes) {
                return this.buttonModes[mode].disabled
            }
            let rs = this.wireframeActionStatus[mode];
            if (!rs) return false;
            if (rs.request && !rs.status) return true;
            if (rs.request && !rs.status.isComplete) return true;
            return false;
        };

        this.formatPercent = function (p) {
            return PVUtils.formatPercent(p, 0);
        };

    }],
    template: tpl
}

