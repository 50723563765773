import FrameMetadata = PV.FrameMetadata;
import {PV} from "../../wireframe";
import {WireframeElementType} from "../../application/wireframeElementType";
import * as THREE from 'three';
import {CameraRegistration, Resource} from "../../resourceManager";
import {Vector3} from "three";

export class CameraView extends PV.Component {
    globalTransform:THREE.Matrix4;
    frameMetadata:FrameMetadata;
    pvType = WireframeElementType.camera;
    xFactor: number;
    yFactor: number;
    cameraCenter: Vector3 = new THREE.Vector3();
    projectionType:String;
    frameId:number;
    distortion:number[] = [0,0,0,0,0,0,0,0];
    translation:number[] = [0,0,0];
    rotation:number[] = [0,0,0];
    fx:number;
    fy:number;
    cx:number;
    cy:number;
    skew:number;

    constructor(public registration:CameraRegistration, public imageResource:Resource) {
        super();
        if (registration) {
            this.globalTransform = registration.getMat4();

            let reg = registration as any;
            if(reg.distortion) {
                this.distortion = reg.distortion;
            }
            if(reg.cxy){
                this.cameraCenter = new Vector3(reg.cxy[0], reg.cxy[1], 0);
                this.cx = reg.cxy[0];
                this.cy = reg.cxy[1];
            }
            if(reg.fxy){
                this.fx = reg.fxy[0];
                this.fy = reg.fxy[1];
            }
            if(reg.rotation){
                this.rotation = reg.rotation;
            }
            if(reg.translation){
                this.translation = reg.translation;
            }
            this.skew = registration.skew;
            this.projectionType = registration.projectionType;
        }
        if (imageResource) {
            this.frameMetadata = imageResource.metadata["imageMetadata"];
            this.frameId = this.frameMetadata.frameId
        }
    }

    computeSSD(vert: THREE.Vector3): number {
        let center: THREE.Vector3 = new THREE.Vector3().copy(this.cameraCenter);
        let distanceFromCamera: number = vert.distanceTo(center);
        let ssd = distanceFromCamera / this.fx;
        return ssd;
    }

    computeDistance(vert: THREE.Vector3): number {
        let distanceFromCamera: number = vert.distanceTo(this.cameraCenter);
        return distanceFromCamera;
    }
}

