import {WireframeElement} from "./wireframeElement";
import {PV} from "../wireframe";
import {DefaultMaterialProps} from "./defaultMaterialProps";
import {WireframeElementType} from "./wireframeElementType";
import {Mesh} from "./mesh";
import * as THREE from 'three';
import {Vector3} from 'three';
import {DoubleProperty} from "../models/property/doubleProperty";
import GeometryCache from "./geometryCache";
import {EventEmitter} from "@angular/core";

export class VertexElement extends WireframeElement {
    pvObject: PV.Vertex;
    isEditable = true;
    private hitGeomScaleRatio = 2;
    public updated = new EventEmitter<VertexElement>();

    constructor(_pvObject: PV.Component) {
        super(_pvObject);
        this.baseGeometryFunc = () => {
            return GeometryCache.getOrCache("vertexGeometry", () => {
                return new THREE.SphereBufferGeometry(1, 8, 8)
            })
        };
        this.highlightGeometryFunc = this.baseGeometryFunc
    }

    createGeometry() {
        // vertex sphere
        let vertSphere = new THREE.Mesh() as Mesh;
        this.add(vertSphere);
        this.baseGeometry = [vertSphere];
        vertSphere.geometry = this.baseGeometryFunc();

        vertSphere.isPickable = true;
        vertSphere.material = this.material;
        vertSphere.name = this.name;
        vertSphere.renderOrder = 100

        let outlineMesh = new THREE.Mesh(vertSphere.geometry) as Mesh;
        outlineMesh.material = DefaultMaterialProps[WireframeElementType.vertex].selectMaterial.clone();
        outlineMesh.isPickable = true;
        outlineMesh.name = this.name + "-pickSphere";
        outlineMesh.wireframeElement = this;
        this.hitGeometry = [outlineMesh];
        this.highlightGeometry = this.hitGeometry;

        this.createLabelSprite();

        this.storeBaseMaterials()
    }

    updateGeometry() {
        super.updateGeometry();
        if (this.baseGeometry.length < 1) {
            console.log("empty base geom ")
        }
        this.position.copy(this.wireframeLayer.wireframe.getVert3(this.pvObject.id));
        this.baseGeometry[0].scale.set(this.app.vertexSize, this.app.vertexSize, this.app.vertexSize);
        this.baseGeometry[0].scale.multiplyScalar(this.wireframeLayer.linearWorldScale);
        this.hitGeometry[0].scale.copy(this.baseGeometry[0].scale.clone());
        this.hitGeometry[0].scale.multiplyScalar(this.hitGeomScaleRatio);
        let labelPos = this.app.getProjectGroundPlane().normal;
        labelPos.multiplyScalar(-.25);
        this.labelSprite.position.copy(labelPos);
        this.updateMatrixWorld(true)
        this.updateDerivedProperties();
        this.updateHitGeometry();
        this.triggerUpdate();
    }

    updateMaterials() {
        super.updateMaterials()
    }

    getVert3(inWorldCRS: boolean = false): THREE.Vector3 {
        let pos = this.wireframeLayer.wireframe.getVert3(this.pvObject.id);
        if (inWorldCRS) return this.wireframeLayer.toWorldCRS(pos);
        return pos
    }

    setPosition(worldPos: Vector3) {
        let localPos = this.wireframeLayer.toWireframeCRS(worldPos);
        this.matrix.setPosition(localPos);
        this.updateMatrixWorld(true)
        this.wireframeLayer.wireframe.setVert3(this.pvObject.id, localPos)
        this.triggerUpdate();
    }

    getCenter(inWorldCRS: boolean = false): Vector3 {
        return this.getVert3(inWorldCRS)
    }

    updateDerivedProperties() {
        let gp: THREE.Plane = this.app.getRepresentativeGroundPlane();
        let vc: THREE.Vector3 = this.wireframeLayer.wireframe.getVert3(this.pvObject.id);
        this.wireframeLayer.wireframe.findProperty(DoubleProperty, true, "HeightAboveGroundPlane")
            .setValue(this, -gp.distanceToPoint(vc));
    }

    getDependentGeometry(): WireframeElement[] {
        let elements:WireframeElement[] = []
        this.wireframeLayer.wireframe.findEdgesForVertex(this.pvObject.id).forEach((edgeId) => {
            elements.push(this.wireframeLayer.findWireframeElement(WireframeElementType.edge, edgeId))
        });
        this.wireframeLayer.wireframe.findPlanesForVertex(this.pvObject.id).forEach((planeId) => {
            elements.push(this.wireframeLayer.findWireframeElement(WireframeElementType.plane, planeId))
        })
        return elements
    }

    triggerUpdate(){
        this.updated.emit(this);
    }

    getOptimumViewingPlane():THREE.Plane {
        let that = this
        let planeIds = this.wireframeLayer.wireframe.findPlanesForVertex(this.pvObject.id)
        if (planeIds.length > 0) {
            let planes = planeIds.map((planeId) => that.wireframeLayer.findWireframeElement(WireframeElementType.plane, planeId))
            return this.wireframeLayer.getOptimiumViewingPlaneForElements(planes)
        }
        return super.getOptimumViewingPlane()
    }
}

