import {Observable} from "rxjs";
import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {OrganizationModel} from "../../models/organizations/organization.model";
import {map} from "rxjs/operators";

@Injectable()
export class OrganizationService {

    organizations:Observable<OrganizationModel[]>;

    constructor(
        private http: HttpClient
    ) {
        this.organizations = this.loadOrganizations();
    }

    loadOrganizations(): Observable<OrganizationModel[]> {
        return this.http.get<OrganizationModel[]>("./organizations/organizations.json").pipe(
            map(
                res => {
                    let organizationsArray:OrganizationModel[] = [];

                    res.forEach((organization) => {
                       organizationsArray.push(new OrganizationModel().deserialize(organization));
                    });

                    return organizationsArray;
                }
            )
        );
    }

    getOrganization(name:string):Observable<OrganizationModel>{
        let organizationModel = null;
        if(!this.organizations) return organizationModel;
        organizationModel = this.organizations.pipe(
            map(
                organizationModelArray => organizationModelArray.find(organizationModel => organizationModel.name == name)
            )
        );
        return organizationModel;
    }

    detectOrganization(): Observable<OrganizationModel>{
        let organizationModel = null;
        if(!this.organizations) return organizationModel;
        organizationModel = this.organizations.pipe(
            map(
                organizationModelArray => organizationModelArray.find(organizationModel => organizationModel.organizationUrlDetected())
            )
        );
        return organizationModel;
    }

}