import {Observable} from "rxjs";
import {ModuleData} from '../../models/modules/module.model';
import {Injectable, Compiler} from '@angular/core';
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {RouterService} from "../routers/router.service";

// peer dependencies
import * as AngularCore from '@angular/core';
import * as AngularCompiler from '@angular/compiler';
import * as AngularCommon from '@angular/common';
import * as AngularRouter from '@angular/router';
import * as AngularForms from "@angular/forms";
import * as AngularAnimations from "@angular/animations";
import * as AngularCDK from '@angular/cdk';
import * as AngularMaterial from '@angular/material';
import * as PlatformBrowser from '@angular/platform-browser';
import * as THREE from 'three';
import * as ThreePotreeLoader from '@pix4d/three-potree-loader';
import * as MaterialDesignIcons from "material-design-icons";
import * as CoreJS from 'core-js';
import * as RXJS from 'rxjs';

const Zone = require('zone.js');

@Injectable()
export class ModuleService {
    source = `${window.location.protocol}//${window.location.host}${window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'))}`;
    private debug = false;

    constructor(
        private compiler: Compiler,
        private http: HttpClient,
        private routerService: RouterService
    ) {
        if (this.debug) console.log(compiler);
    }

    loadModules(): Observable<ModuleData[]> {
        return this.http.get<ModuleData[]>("./modules/modules.json");
    }

    loadModule(moduleInfo: ModuleData): Observable<any> {
        let url = this.source + '/modules/' + moduleInfo.filePath;
        let sourceMapUrl = url + ".map";

        return this.http.get(url, {responseType: 'text'})
            .pipe(map(data => {

                // create a module export structure
                let exports = {};
                let module = {exports: exports};

                // peer dependencies
                const modules = {
                    '@angular/animations': AngularAnimations,
                    '@angular/cdk': AngularCDK,
                    '@angular/common': AngularCommon,
                    '@angular/compiler': AngularCompiler,
                    '@angular/core': AngularCore,
                    '@angular/forms': AngularForms,
                    '@angular/router': AngularRouter,
                    '@angular/material': AngularMaterial,
                    '@angular/platform-browser': PlatformBrowser,
                    'core-js': CoreJS,
                    'material-design-icons': MaterialDesignIcons,
                    'rxjs': RXJS,
                    'three': THREE,
                    'zone.js': Zone,
                    '@pix4d/three-potree-loader': ThreePotreeLoader
                };

                // shim 'require' and eval
                const require: any = (module) => {
                    if (this.debug) console.log("requiring", module);
                    return modules[module];
                };

                try {
                    let sourceMappingURL = '# sourceMappingURL=index.js.map';
                    let sourceMappingURLAbsolute = '# sourceMappingURL=' + sourceMapUrl;
                    let lastIndexOfSourceMappingURL = data.lastIndexOf(sourceMappingURL);
                    data = data.slice(0, lastIndexOfSourceMappingURL) + data.slice(lastIndexOfSourceMappingURL).replace(sourceMappingURL, sourceMappingURLAbsolute);
                    // data = data.replace('# sourceMappingURL=index.js.map', '# sourceMappingURL=' + mapUrl);

                    let result = eval(data);
                    if (typeof result === "object") {
                        exports = result;
                    } else if (typeof module.exports === "object") {
                        exports = module.exports;
                    }

                    // Need to check if there is another solution for eval as this is described as 'Evil'
                    let compiled = this.compiler.compileModuleAndAllComponentsSync(exports[`${moduleInfo.moduleName}`]);
                } catch (e) {
                    console.error(e);
                }

                return exports;
            }));
    }

    enableModule(moduleData: ModuleData): Promise<void> {
        return new Promise<void>(((resolve, reject) => {
            // enable or disable module
            if (this.isRegistered(moduleData)) {
                this.routerService.unRegisterRoute(moduleData.routePath);
                moduleData.registered = false;
                resolve();
            } else {
                this.registerRoute(moduleData).then(() => {
                    moduleData.registered = true;
                    resolve();
                }, (err) => {
                    reject(err);
                });
            }
        }));

    }

    isRegistered(moduleData: ModuleData): boolean {
        return this.routerService.routeIsRegistered(moduleData.routePath);
    }

    private registerRoute(moduleData: ModuleData): Promise<void> {
        let self = this;
        return new Promise<void>(((resolve, reject) => {
            self.routerService.createAndRegisterRoute(moduleData, self.loadModule.bind(self));
            resolve();
        }));
    }
}