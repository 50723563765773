import {WireframeApplication} from "../../application/wireframeApplication";
import {Property} from "./property";
import {PropertyMetadata} from "./propertyMetadata";
import {WireframeElementType} from "../../application/wireframeElementType";
import {WireframeElement} from "../../application/wireframeElement";
import {PropertyValue} from "./propertyValue";
import WireframeElementCollection from "../../collections/wireframeElements/wireframeElement.collection";
import {PV} from "../../wireframe";
import Wireframe = PV.Wireframe;
import {TextProperty} from "./textProperty";
import {DoubleProperty} from "./doubleProperty";
import {IntegerProperty} from "./integerProperty";
import * as PVProperty from "../../models/property/propertyLoaders"

export class PropertyManager {
    constructor(private app:WireframeApplication) {}
    rewritePropertyList = [
        ".DistanceProperty",
        ".PolygonShapeProperty",
        ".LabelProperty"
    ]
    lastUpdate: number;
    properties: Property[];
    propertyValues: PropertyValue[][];
    propertyMetadata: PropertyMetadata[];
    elementsByType = {};

    rewriteProperties(wf:Wireframe) {
        // rewrite certain props since they will change over time
        let self = this
        Object.values(wf.properties).forEach((p:Property) => {
            if (self.rewritePropertyList.includes(p.type)) {
                let newProp = new PVProperty[p.type.substr(1)]
                newProp.id = p.id
                wf.properties[p.id] = newProp
            }
        })
    }


    get wireframe(): Wireframe {
        if (!this.app) return null;
        return this.app.wireframe;
    }

    get propertyMap(): any {
        if (!this.wireframe) return null;
        return this.wireframe.properties;
    }

    getProperty(id: number) {
        let property: Property = null;
        if (!this.propertyMap) return property;
        property = this.propertyMap[id];
        return property;
    }

    getPropertiesByType(propertyType: String): Property[] {
        let propertiesByType: Property[] = [];
        if (!this.propertyMap) return propertiesByType;
        for (let propertyKey in this.propertyMap) {
            if (this.propertyMap.hasOwnProperty(propertyKey)) {
                let currentProperty = this.propertyMap[propertyKey];
                if (currentProperty.type == propertyType) {
                    propertiesByType.push(currentProperty);
                }
            }
        }
        return propertiesByType;
    }

    getAllProperties(): Property[] {
        let propertiesByType: Property[] = [];
        if (!this.propertyMap) return propertiesByType;
        for (let propertyKey in this.propertyMap) {
            if (this.propertyMap.hasOwnProperty(propertyKey)) {
                let currentProperty = this.propertyMap[propertyKey];
                propertiesByType.push(currentProperty);
            }
        }
        return propertiesByType;
    }

    addProperty(property: Property, selectedElements: WireframeElementCollection) {
        let propertyId = property.id;
        if (propertyId == null) {
            propertyId = Number(this.wireframe.addProperty(property));
        }
        selectedElements.forEach((selectedWireframeElement:WireframeElement) => {
            if (property.allowableElementTypes.length > 0 && !property.allowableElementTypes.includes(selectedWireframeElement.pvObject.pvType)) return;
            let existing = selectedWireframeElement.pvObject.properties.filter(function (propertyValue) {
                return propertyValue.id == propertyId
            });
            if (existing.length > 0) return;
            let propertyValue = new PropertyValue(propertyId, property.getDefaultValue());
            selectedWireframeElement.pvObject.properties.push(propertyValue);
            property.onAddToElement(selectedWireframeElement);
        });
        let selectedElementArray = [];
        selectedElements.forEach((selectedElement) => {
            selectedElementArray.push(selectedElement);
        });
        this.aggregateProperties(selectedElementArray);
        this.triggerPropertiesChanged();
    }

    updateProperty(property: Property, wireframeElements: WireframeElementCollection, value: any) {
        let self = this;
        if (!property) return;
        if (!wireframeElements || wireframeElements.size() == 0) return;
        if (value == undefined) return;

        // update
        wireframeElements.forEach(function (wireframeElement) {

            // check allowed type
            if (!property.allowableElementTypes.includes(wireframeElement.pvObject.pvType)) return;

            wireframeElement.pvObject.properties.forEach((currentProperty) => {
                if (currentProperty.id !== property.id) return;

                // find metadata
                let propertyMetadata = null;
                self.propertyMetadata.forEach((currentPropertyMetadata) => {
                    if (currentPropertyMetadata.id !== property.id) return;
                    propertyMetadata = currentPropertyMetadata;
                });
                if (!propertyMetadata) return;

                // update values
                self.propertyValues.forEach((currentPropertyValueArray) => {
                    currentPropertyValueArray.forEach((currentPropertyValue) => {
                        if (currentPropertyValue.id !== property.id) return;
                        // todo: child properties
                        currentPropertyValue.value = value;
                    })
                });
            });
        });

        this.triggerPropertiesChanged();
    }

    deleteProperty(property: Property, wireframeElements: WireframeElementCollection) {
        // find metadata
        let propertyMetadata = null;
        this.propertyMetadata.forEach((currentPropertyMetadata) => {
            if (currentPropertyMetadata.id !== property.id) return;
            propertyMetadata = currentPropertyMetadata;
        });
        if (!propertyMetadata) return;
        if (propertyMetadata.property.isReadonly) return;

        wireframeElements.forEach(function (wireframeElement) {
            if (!wireframeElement.pvObject.properties) {
                return;
            }
            for (let i = wireframeElement.pvObject.properties.length - 1; i >= 0; i--) {
                let p = wireframeElement.pvObject.properties[i];
                if (p.id == property.id) {
                    propertyMetadata.property.removeValue(wireframeElement);
                }
            }
        });

        let selectedElementArray = [];
        wireframeElements.forEach((selectedElement) => {
            selectedElementArray.push(selectedElement);
        });
        this.aggregateProperties(selectedElementArray);
        this.triggerPropertiesChanged();
    }

    triggerPropertiesChanged() {
        this.app.propertiesChanged();
        this.app.wireframeLayer.redrawWireframe();
    }

    getAvailableProperties(wireframeElements: WireframeElementCollection): Property[] {
        let availableProperties: Property[] = [];
        let encounteredTypes: WireframeElementType[] = [];
        wireframeElements.forEach(function (wireframeElement) {
            let type = wireframeElement.pvObject.pvType;
            if (encounteredTypes.indexOf(type) >= 0) return;
            encounteredTypes.push(type);
        });

        let allProperties = this.getAllProperties();

        let integerProperty = new IntegerProperty();
        integerProperty.name = "Custom Integer";
        allProperties.push(integerProperty);
        let doubleProperty = new DoubleProperty();
        doubleProperty.name = "Custom Double";
        allProperties.push(doubleProperty);
        let textProperty = new TextProperty();
        textProperty.name = "Custom Text";
        allProperties.push(textProperty);

        allProperties.forEach((property) => {
            if (property.isReadonly || !property.isVisible) return;
            if (property.allowableElementTypes.length > 0) {
                property.allowableElementTypes.forEach((allowableElementType) => {
                    if (encounteredTypes.indexOf(allowableElementType) >= 0) {
                        if (availableProperties.indexOf(property) >= 0) return;
                        availableProperties.push(property);
                    }
                })
            } else {
                if (availableProperties.indexOf(property) < 0) availableProperties.push(property);
            }
        });

        return availableProperties;
    }

    aggregateProperties(elements: WireframeElement[]) {
        this.properties = [];
        this.propertyValues = [];
        this.elementsByType = {};
        this.propertyMetadata = [];
        for (let type in WireframeElementType) {
            if (!isNaN(Number(type))) {
                this.elementsByType[type] = []
            }
        }
        let self = this;
        elements.forEach(function (el) {
            if (!self.elementsByType[el.pvObject.pvType]) return;
            self.elementsByType[el.pvObject.pvType].push(el.pvObject);
            if (!el.pvObject.properties) return;
            el.pvObject.properties.forEach(function (pv: PropertyValue) {
                let prop = self.wireframe.properties[pv.id];
                if (!prop) return;
                let propIdx = self.properties.indexOf(prop);
                if (propIdx == -1) {
                    self.properties.push(prop);
                    self.propertyValues.push([]);
                    let metadata = new PropertyMetadata();
                    self.propertyMetadata.push(metadata);
                    propIdx = self.properties.length - 1;
                    metadata.id = pv.id;
                    metadata.index = propIdx;
                    metadata.property = prop;
                    metadata.value = prop.getDefaultValue();
                    if (self.isObject(metadata.value)) {
                        metadata.multipleValues = prop.getDefaultValue();
                        for (let k in metadata.value) {
                            metadata.value[k] = pv.value[k];
                            metadata.multipleValues[k] = false;
                        }
                    } else {
                        metadata.value = pv.value;
                        metadata.multipleValues = false;
                    }

                } else {
                    let metadata = self.propertyMetadata[propIdx];
                    self.compareValues(metadata, pv.value);
                }
                self.propertyMetadata[propIdx].count++;
                self.propertyValues[propIdx].push(pv);
            });
        });
    }

    compareValues(pmd: PropertyMetadata, value: any) {
        if (this.isObject(pmd.value)) {
            for (let k in pmd.value) {
                if (value[k] !== pmd.value[k]) {
                    pmd.value[k] = null;
                    if (!pmd.multipleValues) pmd.multipleValues = {};
                    pmd.multipleValues[k] = true;
                }
            }
        } else {
            if (pmd.value === value) {

            } else {
                pmd.multipleValues = true;
                pmd.value = null;
            }
        }
    }

    isObject(obj) {
        return obj === Object(obj);
    }

    getLabelForElement(element) {
        let str = this.app.getLabelForElement(element);
        return str;
    }

    highlightSelection(elements) {
        if(!elements) return;
        let that = this
        elements.forEach(function (el) {
            that.app.highlightWireframeElement(el);
        });

        if (elements.length > 0) {
            this.app.lookAtElements(elements);
            this.app.wireframeLayer.updateWireframeMaterials();
        }

    }
}
