import {WireframeElement} from "./wireframeElement";
import {UndoActionType} from "./undoActionType";

export class UndoAction {
    element: WireframeElement;
    state: any;
    actionType: UndoActionType;

    constructor(actionType:UndoActionType, el:WireframeElement) {
        this.actionType = actionType
        this.element = el
        this.state = el.getState()
    }
}