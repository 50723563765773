import * as Cesium from 'cesium/Source/Cesium';
// borrowed from : https://github.com/TerriaJS/terriajs/blob/master/lib/Map/EarthGravityModel1996.js

/*global require,Int16Array,Uint8Array,Int16Array*/
import defined from 'cesium/Source/Core/defined'
//import loadArrayBuffer from 'cesium/Core/loadArrayBuffer'
import when from 'cesium/Source/ThirdParty/when'


/**
 * The Earth Gravity Model 1996 (EGM96) geoid.
 * @param {String} gridFileUrl The URL of the WW15MGH.DAC file.
 */
export default class EarthGravityModel1996 {
    gridFileUrl: string = "lib/WW15MGH.DAC"
    data: any
    isLoading: boolean = false
    // These values were determined by inspecting the WW15MGH.DAC file.  We hard-code them here because
    // we need them available before that file finishes loading.
    minimumHeight: number = -106.99;
    maximumHeight: number = 85.39;

    init(gridFileUrl) {
        this.gridFileUrl = gridFileUrl;
        this.data = undefined;
    };


    /**
     * Determines if this class will work in the current environment.  It will return false on older browsers without support
     * for typed arrays.
     * @return {Boolean} True if this class may be used in this environment; otherwise, false.
     */
    isSupported() {
        return typeof Int16Array !== 'undefined' && typeof Uint8Array !== 'undefined';
    };

    /**
     * Gets the height of EGM96 above the surface of the ellipsoid.
     * @param {String} baseUrl The base URL for TerriaJS resources.
     * @param {Number} longitude The longitude.
     * @param {Number} latitude The latitude
     * @return {Promise|Number} A promise, that, when it results The height of mean sea level above the ellipsoid at the specified location.  Negative numbers indicate that mean sea level
     *                  is below the ellipsoid.
     */
    getHeight(longitude, latitude) {
        let that = this
        return this.getHeightData().then(function (data) {
            let height = that.getHeightFromData(data, longitude, latitude);
            return height
        });
    };

    getHeights(cartographicArray) {
        let that = this
        return this.getHeightData().then(function (data) {
            for (let i = 0; i < cartographicArray.length; ++i) {
                let cartographic = cartographicArray[i];
                cartographic.height = that.getHeightFromData(data, cartographic.longitude, cartographic.latitude);
            }
            return cartographicArray;
        });
    };

    private loadPromise
    getHeightData() {
        let that = this
        let p = new Promise(function(resolve, reject) {
            if (!that.data) {
                //if (!this.isLoading) {
                    that.isLoading = true
                    that.data = that.loadBinaryResource(that.gridFileUrl, function(req) {
                        //console.log("request callback", req)
                        // Data file is big-endian, all relevant platforms are little endian, so swap the byte order.
                        let byteView = new Uint8Array(req.response);

                        for (let k = 0; k < byteView.length; k += 2) {
                            let tmp = byteView[k];
                            byteView[k] = byteView[k + 1];
                            byteView[k + 1] = tmp;
                        }
                        that.data = new Int16Array(req.response);
                        let min = Number.MAX_VALUE
                        let max = Number.MIN_VALUE
                        for (let k = 0; k < that.data.length; k++) {
                            if (that.data[k] < min) min = that.data[k]
                            if (that.data[k] > max) max = that.data[k]
                        }
                        //console.log("min/max " + min + "/" + max)
                        that.isLoading = false
                        resolve(that.data);
                    });
                //}
            } else {
                resolve(that.data);
            }
        })
        return p
    }

    loadBinaryResource(url, callback:Function) {
        let that = this
        let req = new XMLHttpRequest();
        req.open('GET', url, true);
        req.responseType = "arraybuffer"
        req.onload = function(o) {
            callback.apply(that, [req])
        }
        req.send(null);
    }

    getHeightFromData(data, longitude, latitude) {
        let recordIndex = 720 * ((Math.PI / 2) - latitude) / Math.PI;
        if (recordIndex < 0) {
            recordIndex = 0;
        } else if (recordIndex > 720) {
            recordIndex = 720;
        }

        longitude = Cesium.Math.zeroToTwoPi(longitude);
        let heightIndex = 1440 * longitude / (Math.PI * 2);
        if (heightIndex < 0) {
            heightIndex = 0;
        } else if (heightIndex > 1440) {
            heightIndex = 1440;
        }

        let i = heightIndex | 0;
        let j = recordIndex | 0;

        let xMinusX1 = heightIndex - i;
        let yMinusY1 = recordIndex - j;
        let x2MinusX = 1.0 - xMinusX1;
        let y2MinusY = 1.0 - yMinusY1;

        let f11 = this.getHeightValue(data, j, i);
        let f21 = this.getHeightValue(data, j, i + 1);
        let f12 = this.getHeightValue(data, j + 1, i);
        let f22 = this.getHeightValue(data, j + 1, i + 1);

        return (f11 * x2MinusX * y2MinusY + f21 * xMinusX1 * y2MinusY + f12 * x2MinusX * yMinusY1 + f22 * xMinusX1 * yMinusY1) / 100.0;
    }

// Heights returned by this function are in centimeters.
    getHeightValue(data, recordIndex, heightIndex) {
        if (recordIndex > 720) {
            recordIndex = 720;
        } else if (recordIndex < 0) {
            recordIndex = 0;
        }

        if (heightIndex > 1439) {
            heightIndex -= 1440;
        } else if (heightIndex < 0) {
            heightIndex += 1440;
        }

        return data[recordIndex * 1440 + heightIndex];
    }

}
