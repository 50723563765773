import {ChangeDetectorRef, Component, NgZone, OnDestroy,} from '@angular/core';
import {WireframeApplication} from "../../application/wireframeApplication";
import {CameraPointView} from "../../models/cameras/cameraPointView";
import {ProjectImageCollectionService} from "../../services/projects/projectImageCollection.service";
import {DomSanitizer} from "@angular/platform-browser";
import {CameraProjector} from "../../application/cameraProjector";
import {WireframeElement} from "../../application/wireframeElement";
import {SceneService} from "../../services/scenes/scene.service";

@Component({
    selector: 'camera-list-panel',
    styles: [require('./cameraList.component.scss')],
    template: require('./cameraList.component.html')
})
export class CameraListComponent implements OnDestroy {

    displayedColumns = ['names', 'name', 'distance', 'angle', 'center', 'actions'];

    private thumbnails = {}
    private emptyThumb = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
    private _cameraPointViews:CameraPointView[] = []
    private app:WireframeApplication

    constructor(private zone: NgZone,
                private sceneService:SceneService,
                private sanitizer: DomSanitizer,
                private changeDetectorRef: ChangeDetectorRef) {
        let that = this;
        this.app = sceneService.wireframeApplication
        this.app.cameraProjectionService.eventEmitter.on("projectionChanged").subscribe((event) => {
            that.updateCameraPointViews()
        })

        this.app.eventEmitter.on("selectWireframeElement").subscribe((elements:WireframeElement[]) => {
            this.changeDetectorRef.detectChanges()
        })
    }

    ngOnInit() {
        this.changeDetectorRef.detach()
    }

    updateCameraPointViews() {
        this._cameraPointViews = this.app.cameraProjectionService.cameraPointViews
        this.changeDetectorRef.detectChanges()
        let activeCamera = this.app.cameraProjectionService.activeProjector.cameraElement
        let rowName = "cameraRow-" + activeCamera.name
        let el = document.getElementById(rowName)
        if (el) (el as any).scrollIntoViewIfNeeded()
    }

    getThumbnail(view:CameraPointView) {
        let thumbUrl = this.app.resourceManager.getDerivedImageUrl(view.cameraElement.pvObject.imageResource, "thumb")
        let s3Obj = this.app.resourceManager.getS3ObjectFromURL(thumbUrl)
        let that = this
        if (!this.thumbnails[thumbUrl]) {

                this.app.resourceManager.getImageData(s3Obj, (o) => {
                    that.thumbnails[thumbUrl] = this.sanitizer.bypassSecurityTrustResourceUrl(o)
                }, null)
        }
        return this.thumbnails[thumbUrl] || this.emptyThumb
    }

    isSelected(row:CameraPointView):boolean {
        if (!row || !this.app.cameraProjectionService.activeProjector) return false
        return (row.cameraElement == this.app.cameraProjectionService.activeProjector.cameraElement)
    }

    get cameraPointViews():CameraPointView[] {
        return this._cameraPointViews
    }

    ngOnDestroy(): void {
    }

    selectView(view: CameraPointView) {
        if (!view) return;
        this.app.cameraProjectionService.activateProjection(view.cameraElement.projection, view.normal, view.point)
        this.changeDetectorRef.detectChanges()
    }

    focusView(view: CameraPointView) {
        if (!view) return;
    }

    getAngle(view:CameraPointView):number {
        return Math.round((view.angle || 0) * 180 / Math.PI)
    }

    getSelectedProjector():CameraProjector {
        return this.app.cameraProjectionService.activeProjector
    }

    getTargetPoint3d():THREE.Vector3 {
        return this.app.cameraProjectionService.activeProjector.targetPoint.clone()
    }

    getVisibleElements():WireframeElement[] {
        return [...this.app.selectedElements]
    }
}