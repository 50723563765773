import {
    AfterViewInit,
    Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild
} from '@angular/core';
import {ProjectImageCollectionFilterMetadataModel} from "../../../models/projectImageCollectionFilter/projectImageCollectionFilterMetadata.model";
import {ProjectImageCollectionFilterModel} from "../../../models/projectImageCollectionFilter/projectImageCollectionFilter.model";

@Component({
    selector: 'project-image-list-filter-editor-metadata',
    styles: [require('./projectImageListFilterEditorMetadata.component.scss')],
    template: require('./projectImageListFilterEditorMetadata.component.html')
})
export default class ProjectImageListFilterEditorMetadataComponent implements AfterViewInit, OnDestroy {
    @ViewChild('metadataValueInput', {static: false}) inputElement: ElementRef;
    @Input() projectImageListFilterModel: ProjectImageCollectionFilterMetadataModel;
    @Output() filterUpdated = new EventEmitter<ProjectImageCollectionFilterModel>();

    constructor() {
    }

    ngAfterViewInit(): void {
        this.inputElement.nativeElement.focus();
    }

    ngOnDestroy(): void {
    }

    onMetadataValueBlur() {
        this.triggerFilterUpdated();
    }

    onMetadataValueChanged(metadataValue) {
        this.projectImageListFilterModel.metadataValue = metadataValue;
        this.triggerFilterUpdated();
    }

    triggerFilterUpdated() {
        this.filterUpdated.emit(this.projectImageListFilterModel);
    }
}