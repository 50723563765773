import {WireframeElement} from "./wireframeElement";
import {PV} from "../wireframe";
import {DefaultMaterialProps} from "./defaultMaterialProps";
import {WireframeElementType} from "./wireframeElementType";
import {WireframeApplication} from "./wireframeApplication";
import {Mesh} from "./mesh";
import {MeasurementUnit} from "./measurementUnit";
import {WireframeUtils} from "../wireframeUtils";
import * as THREE from 'three';
import {LengthProperty} from "../models/property/lengthProperty";
import GeometryCache from "./geometryCache";
import {NumberProperty} from "../models/property/numberProperty";
import {TextProperty} from "../models/property/textProperty";

export class EdgeElement extends WireframeElement {
    pvObject:PV.Edge
    private hitGeomScaleRatio = 4

    private srcPos:THREE.Vector3
    private dstPos:THREE.Vector3
    private length:number

    getCenter(inWorldCRS:boolean = false):THREE.Vector3 {
        let start = this.wireframeLayer.wireframe.getVert3(this.pvObject.vertex1Id);
        let end = this.wireframeLayer.wireframe.getVert3(this.pvObject.vertex2Id);
        start.add(end);
        start.divideScalar(2);
        if (inWorldCRS) start = this.wireframeLayer.toWorldCRS(start)
        return start;
    }

    createGeometry() {
        let cylinderGeom = GeometryCache.getOrCache("edgeGeometry", () => {
            return new THREE.CylinderBufferGeometry(1, 1, 1, 4, 1, false)
        })
        {
            let mesh = new THREE.Mesh(cylinderGeom) as Mesh;
            mesh.material = this.material;
            mesh.isPickable = true;
            mesh.renderOrder = 10
            this.add(mesh);
            this.baseGeometry = [ mesh ]
        }
        {
            let mesh = new THREE.Mesh(cylinderGeom, DefaultMaterialProps[WireframeElementType.edge].selectMaterial.clone()) as Mesh;
            mesh.isPickable = true;
            mesh.wireframeElement = this
            //mesh.matrixAutoUpdate = false
            //this.add(mesh);
            this.hitGeometry = [ mesh ]
            this.highlightGeometry = [ mesh ]
        }

        this.createLabelSprite()
        this.storeBaseMaterials()
    }

    updateEdgeGeometry(srcPos:THREE.Vector3, dstPos:THREE.Vector3) {
        let edgeWidth = this.wireframeLayer.linearWorldScale * this.app.edgeSize * this.app.lineVertexSizeRatio;
        WireframeUtils.updateCylinderGeometry(this.baseGeometry[0] as THREE.Mesh, srcPos, dstPos, edgeWidth);
        //this.hitGeometry[0].position.copy(this.position)
        this.hitGeometry[0].quaternion.copy(this.baseGeometry[0].quaternion);
        this.hitGeometry[0].scale.copy(this.baseGeometry[0].scale);
        this.hitGeometry[0].scale.x *= this.hitGeomScaleRatio
        this.hitGeometry[0].scale.z *= this.hitGeomScaleRatio

        let center = srcPos.clone();
        center.add(dstPos);
        center = center.multiplyScalar(.5);
        this.position.copy(center);
        this.updateMatrix()
        this.updateMatrixWorld(true)
    }

    get lengthEqualityGroupProp():TextProperty {
        return this.app.wireframe.findProperty(TextProperty, false, "lengthEqualityGroup") as TextProperty
    }

    getDimensionStrings(): string[] {
        let dim:string[] = []
        if (null != this.length) {
            let lengthStr:string
            if (this.wireframeLayer.app.currentDisplayUnit == MeasurementUnit.UNITLESS) {
                lengthStr = this.length.toFixed(this.wireframeLayer.app.dimensionViewPrecision)
            } else if (this.app.currentDisplayUnit == MeasurementUnit.M || this.app.currentDisplayUnit == MeasurementUnit.FT) {
                lengthStr = this.length.toFixed(this.app.dimensionViewPrecision) + MeasurementUnit[this.app.currentDisplayUnit].toString().toLowerCase()
            } else if (this.app.currentDisplayUnit == MeasurementUnit.FFI) {
                lengthStr = WireframeUtils.getFeetFractionalInches(this.length)
            }
            if (lengthStr && this.lengthEqualityGroupProp) {
                let legVal = this.lengthEqualityGroupProp.getValue(this.pvObject)
                if (legVal) {
                    lengthStr += " (" + legVal + ")"
                }
            }
            if (lengthStr) dim.push(lengthStr)
        }
        return dim
    }

    getLength():number {
        return this.srcPos.distanceTo(this.dstPos) * this.app.displayMeasurementScale / this.wireframeLayer.linearWorldScale
    }

    updateGeometry() {
        super.updateGeometry()
        this.srcPos = this.wireframeLayer.wireframe.getVert3(this.pvObject.vertex1Id);
        this.dstPos = this.wireframeLayer.wireframe.getVert3(this.pvObject.vertex2Id);
        this.length = this.getLength()
        this.updateEdgeGeometry(this.srcPos, this.dstPos)
        this.updateDerivedProperties()
        this.updateHitGeometry()
    }

    updateMaterials() {
        super.updateMaterials()
        //let mesh = this.baseGeometry[0] as THREE.Mesh
        //let pickMesh = this.hitGeometry[0] as THREE.Mesh;
        //pickMesh.visible = this.visible && this.isSelected;
        //(pickMesh.material as THREE.MeshBasicMaterial).opacity = (this.isSelected) ? .5 : 0;
    }

    getLine3(inWorldCRS:Boolean = false):THREE.Line3 {
        let v1 = this.wireframeLayer.wireframe.getVert3(this.pvObject.vertex1Id)
        let v2 = this.wireframeLayer.wireframe.getVert3(this.pvObject.vertex2Id)
        let l = new THREE.Line3()
        if (inWorldCRS) {
            l.set(this.wireframeLayer.toWorldCRS(v1), this.wireframeLayer.toWorldCRS(v2))
        } else {
            l.set(v1, v2)
        }
        return l
    }

    setPosition(worldPos: THREE.Vector3) {
        let center = this.getCenter(true)
        let offset = worldPos.clone().sub(center)
        let v = this.wireframeLayer.wireframe.getVert3(this.pvObject.vertex1Id).add(offset)
        this.wireframeLayer.wireframe.setVert3(this.pvObject.vertex1Id, v)
        v = this.wireframeLayer.wireframe.getVert3(this.pvObject.vertex2Id).add(offset)
        this.wireframeLayer.wireframe.setVert3(this.pvObject.vertex2Id, v)
    }


    updateDerivedProperties() {
        this.wireframeLayer.wireframe.findProperty(LengthProperty, true)
            .setValue(this, this.getLength());
    }

    getDependentGeometry(): WireframeElement[] {
        let elements:WireframeElement[] = []
        let that = this
        this.wireframeLayer.wireframe.findPlanesForEdge(this.pvObject.id).forEach((planeId) => {
            elements.push(that.wireframeLayer.findWireframeElement(WireframeElementType.plane, planeId))
        })
        return elements
    }

    getSupportingGeometry(): WireframeElement[] {
        let elements:WireframeElement[] = [
            this.wireframeLayer.findWireframeElement(WireframeElementType.vertex, this.pvObject.vertex1Id),
            this.wireframeLayer.findWireframeElement(WireframeElementType.vertex, this.pvObject.vertex2Id)
        ]
        return elements
    }

    getProjectionPlane(from:THREE.Vector3):THREE.Plane {
        let p = new THREE.Plane()
        let line = this.getLine3(true)
        let closestPoint = line.closestPointToPoint(from, false, new THREE.Vector3())
        let normal = from.clone().sub(closestPoint).normalize()
        p.setFromNormalAndCoplanarPoint(normal, closestPoint)
        return p
    }

    getOptimumViewingPlane():THREE.Plane {
        let that = this
        let planeIds = this.wireframeLayer.wireframe.findPlanesForEdge(this.pvObject.id)
        if (planeIds.length > 0) {
            let planes = planeIds.map((planeId) => that.wireframeLayer.findWireframeElement(WireframeElementType.plane, planeId))
            return this.wireframeLayer.getOptimiumViewingPlaneForElements(planes)
        }
        return super.getOptimumViewingPlane()
    }
}
