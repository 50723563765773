import {Object3DLayer} from "./object3DLayer";
import PointCloudMetadata from "../models/pointCloudMetadata";
import {PointCloudRaycastHandler} from "./pointCloudRaycastHandler";
import {WireframeApplication} from "./wireframeApplication";
import {Resource} from "../resourceManager";
import * as THREE from 'three';
import {CameraLayer} from "./cameraLayer";
import {PointCloudOctree} from "@pix4d/three-potree-loader";


export default class PointCloudLayer extends Object3DLayer {
    metadata: PointCloudMetadata

    constructor(app:WireframeApplication, name:string, label:string) {
        super(app, name, label)
        this.supportsInteraction = true
        this.supportsEditing = false
        this.raycastHandler = new PointCloudRaycastHandler(this, app.sceneService, app.viewer.renderer)
    }

    loadPointcloud(r:Resource, onLoaded:(PointCloudOctree) => void = null) {
        let that = this
        this.resource = r
        this.object = new THREE.Group()
        console.log("Loading pointcloud from", r)

        let l2g: THREE.Matrix4 = new THREE.Matrix4();
        let t: number[] = r.registrations[0].transform;
        l2g.set(t[0], t[1], t[2], t[3],
            t[4], t[5], t[6], t[7],
            t[8], t[9], t[10], t[11],
            t[12], t[13], t[14], t[15]
        );

        let cloudRoot = this.app.resourceManager.getS3ObjectForResource(r)

        try {
            this.app.sceneManager.loadPointcloud(cloudRoot, that.object, "cloud.js", (pointClouds: PointCloudOctree[]) => {
                //let mat = pointClouds[0].material as PointCloudMaterial
                //console.log("pointcloud loaded " + pointCloudIndex + "/" + pointCloudCount)
                this.app.sceneManager.setPointcloudMaterial(pointClouds[0].material)

                that.object = new THREE.Group()
                that.object.add(...pointClouds)

                that.object.matrixAutoUpdate = false
                that.object.matrix.copy(l2g)
                that.object.updateMatrixWorld(true)
                that.resource = r
                that.object.name = r.name
                //layer.metadata = pcm
                that.init()
                that.isVisible = true

                this.app.rootScene.updateMatrix()
                this.app.rootScene.updateMatrixWorld(true)
                if (onLoaded) onLoaded(pointClouds)
            })

        } catch (e) {
            console.log("Error loading pointcloud", e)
        }

    }

}