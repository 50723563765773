import { Component, OnInit, Input } from "@angular/core";
import { SceneService } from "src/app/services/scenes/scene.service";
import { EdgeTypeProperty } from "src/app/models/property/edgeTypeProperty";
import { EdgeType } from "src/app/models/property/edgeType";
import { GeoJsonFeatureCollection } from "src/app/collections/geoJsonFeatures/geoJsonFeature.collection";

@Component({
    selector: 'ptv-edge-verification-list',
    styles: [require('./edgeVerificationList.component.scss')],
    template: require('./edgeVerificationList.component.html')
})
export default class EdgeVerificationListComponent implements OnInit {

    edgeTypes: any[];
    @Input() edgeCollection: GeoJsonFeatureCollection;

    readonly edgeSortOrder = [
        EdgeType.RIDGE,
        EdgeType.HIP,
        EdgeType.VALLEY,
        EdgeType.TRANSITION,
        EdgeType.EAVE,
        EdgeType.FLASHING,
        EdgeType.RAKE,
        EdgeType.STEP_FLASHING,
        EdgeType.PARAPET,
        EdgeType.UNKNOWN
    ]

    constructor(private sceneService: SceneService) {

    }

    ngOnInit(): void {
        let self = this;
        let edgeTypeProperty = this.sceneService.wireframeApplication.wireframe.findProperty(EdgeTypeProperty, false) as EdgeTypeProperty;
        this.edgeTypes = edgeTypeProperty.values.sort(function (a, b) {
            let aSeq = self.edgeSortOrder.indexOf(a.value)
            let bSeq = self.edgeSortOrder.indexOf(b.value);
            return aSeq - bSeq;
        })
    }





}