import {
    PointCloudMaterial,
    PointCloudOctree,
    PointColorType,
    PointShape,
    PointSizeType,
    Potree
} from "@pix4d/three-potree-loader";
import {S3Object} from "./resourceManager";
import {WireframeApplication} from "./application/wireframeApplication";
import * as THREE from "three";
import OrbitControls from "./OrbitControls";
import * as TWEEN from "@tweenjs/tween.js";
import ProjectionUtils from "./projectionUtils";
import {CameraType} from "./application/cameraType";
import {CameraView} from "./models/cameras/cameraView";
import {CameraElement} from "./application/cameraElement";


export interface Transformable {
    clone():Transformable
    applyMatrix4(m:THREE.Matrix4)
}

export class SceneViewer {
    private readonly cameraFar = 10000
    public cameraTransitionActive = false

    public cameraLookatTarget:THREE.Vector3 = new THREE.Vector3()
    public cameraPositionTarget:THREE.Vector3 = new THREE.Vector3()
    public cameraUpTarget:THREE.Vector3 = new THREE.Vector3()
    public cameraDirectionLock:THREE.Vector3

    constructor(private app:WireframeApplication) {}

    /**
     * The element where we will insert our canvas.
     */
    public targetEl: HTMLElement | undefined;
    /**
     * The ThreeJS renderer used to render the scene.
     */
    public renderer = new THREE.WebGLRenderer({ antialias: localStorage.getItem("enableAntiAlias") == "true", alpha: true });

    public light:THREE.PointLight
    private lightHelper:THREE.PointLightHelper
    /**
     * The camera used to view the scene.
     */
    public perspectiveCam = new THREE.PerspectiveCamera(60, 1, 0.1, this.cameraFar);
    private orthoCam = new THREE.OrthographicCamera(-10, 10, 10, -10, -this.cameraFar, this.cameraFar)
    public camera:THREE.Camera = this.perspectiveCam
    /**
     * Controls which update the position of the camera.
     */
    public cameraControls:OrbitControls

    /**
     * The time (milliseconds) when `loop()` was last called.
     */
    private prevTime: number | undefined;
    /**
     * requestAnimationFrame handle we can use to cancel the viewer loop.
     */
    private reqAnimationFrameHandle: number | undefined;

    /**
     * Initializes the viewer into the specified element.
     *
     * @param targetEl
     *    The element into which we should add the canvas where we will render the scene.
     */
    initialize(targetEl: HTMLElement): void {
        if (this.targetEl || !targetEl) {
            return;
        }
        this.targetEl = targetEl;
        targetEl.appendChild(this.renderer.domElement);
        this.cameraControls = new OrbitControls(this.app, this.camera, this.targetEl)
        this.resize();
        window.addEventListener("resize", this.resize);

        this.light = new THREE.PointLight(0xffffff, 1)
        this.app.rootScene.add(this.light)

        this.camera.position.set(0, 10, 0)
        this.cameraLookatTarget.set(0, 0, -.001)
    }

    /*
    private onMouseMove = (e:MouseEvent) => {
        if (app.activeTool) {
            let rect = this.targetEl.getBoundingClientRect();

            app.activeTool.mouse.x = ((e.clientX - rect.left) / this.targetEl.clientWidth) * 2 - 1;
            app.activeTool.mouse.y = -((e.clientY - rect.top) / this.targetEl.clientHeight) * 2 + 1;

            app.activeTool.onMouseMove(e)
            if (app.activeTool.isHandlingMouseMove) {
                e.preventDefault()
            }
        }
        app.activeTool.onMouseMove(e)
    }

    private onMouseDown = (e:MouseEvent) => {
        app.activeTool.onMouseDown(e)
    }

    private onMouseUp = (e:MouseEvent) => {
        app.activeTool.onMouseUp(e)
    }

*/

    private onKeyDown = (e:KeyboardEvent) => {
        console.log("onKeyDown")
        this.app.activeTool.onKeyDown(e)
    }

    private onKeyUp = (e:KeyboardEvent) => {
        console.log("onKeyUp")
        this.app.activeTool.onKeyUp(e)
    }


    /**
     * Performs any cleanup necessary to destroy/remove the viewer from the page.
     */
    destroy(): void {
        this.targetEl.removeChild(this.renderer.domElement);
        this.targetEl = undefined;
        window.removeEventListener("resize", this.resize);

        // TODO: clean point clouds or other objects added to the scene.

        if (this.reqAnimationFrameHandle !== undefined) {
            cancelAnimationFrame(this.reqAnimationFrameHandle);
        }
    }

    setCamera(cam:THREE.Camera){
        this.camera = cam as THREE.PerspectiveCamera;
        this.cameraControls.object = cam;
    }


    private lastCameraMatrixWorld:THREE.Matrix4 = new THREE.Matrix4()
    /**
     * Updates the point clouds, cameras or any other objects which are in the scene.
     *
     * @param dt
     *    The time, in milliseconds, since the last update.
     */
    update(dt: number): void {
        // Alternatively, you could use Three's OrbitControls or any other
        // camera control system.
        //console.log("viewer update")
        this.cameraControls.update();

        if (!this.lastCameraMatrixWorld.equals(this.camera.matrixWorld)) {
            this.app.broadcast("sceneCameraTransformChanged")
            this.lastCameraMatrixWorld.copy(this.camera.matrixWorld)
        }
        //this.light.position.copy(this.camera.position)
        //let cameraVec = new THREE.Vector3()
        //this.camera.getWorldDirection(cameraVec)
        this.light.position.copy(this.camera.position)
        this.light.updateMatrix()
        this.light.updateMatrixWorld(true)
        //this.light.target.position.copy(this.light.position.add(this.camera.getWorldDirection(new THREE.Vector3()).negate()))
        //this.light.updateMatrix()
        if (this.lightHelper) this.lightHelper.update()
        //this.light.lookAt(this.light.position.add(cameraVec))

        if (this.app.activeTool) this.app.activeTool.update()




        if (this.camera instanceof THREE.OrthographicCamera) {
            const { width, height } = this.renderer.domElement.getBoundingClientRect();
            let aspect = width / height;
            let radius = this.camera.position.distanceTo(this.cameraControls.target)
            //let fov = 60 * Math.PI / 180;
            //let angle = (Math.PI / 2) - (fov / 2);
            //let viewWidth = (radius / Math.sin(angle)) * Math.sin(fov / 2);
            //console.log("orthoCam update ", this.object)
            //console.log("radius", radius)
            //console.log("aspect", aspect)
            //let orthoCam = this.object as THREE.OrthographicCamera;

            /*
            this.camera.left = -viewWidth * aspect;
            this.camera.right = viewWidth * aspect;
            this.camera.bottom = -viewWidth / aspect;
            this.camera.top = viewWidth / aspect;
            this.camera.aspect = aspect
            */
            this.camera.zoom = 10 / radius

            let frustumWidth = radius * aspect;
            let frustumHeight = radius;
            this.camera.left = -frustumWidth / 2
            this.camera.right = frustumWidth / 2
            this.camera.bottom = -frustumHeight / 2
            this.camera.top = frustumHeight / 2
            this.camera.updateProjectionMatrix()
            //cam = new THREE.OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 1, 1000)

            //console.log("orthoCam update ", this.object)
            //this.object.zoom -= (this.scale - 1) * this.object.zoom * progression;

        } else if (this.camera instanceof THREE.PerspectiveCamera) {
            //console.log("width/height " + clientWidth + " " + clientHeight)
            //this.object.aspect = 1 / aspect
            this.camera.updateProjectionMatrix();
        }



        // This is where most of the potree magic happens. It updates the
        // visiblily of the octree nodes based on the camera frustum and it
        // triggers any loads/unloads which are necessary to keep the number
        // of visible points in check.
        this.app.sceneManager.potree.updatePointClouds(this.app.sceneManager.pointClouds, this.camera as THREE.PerspectiveCamera, this.renderer);
    }

    printStats() {
        let numOfMeshes = 0;
        this.app.rootScene.traverse( function( child ) {
            if( child instanceof THREE.Mesh )
                numOfMeshes++;
        } );
        console.log("meshCount " + numOfMeshes)
    }
    /**
     * Renders the scene into the canvas.
     */
    render(): void {
        this.renderer.clear();
        this.renderer.render(this.app.rootScene, this.camera);
    }

    /**
     * The main loop of the viewer, called at 60FPS, if possible.
     */
    loop = (time: number): void => {

        //this.reqAnimationFrameHandle = requestAnimationFrame(this.loop);

        const prevTime = this.prevTime;
        this.prevTime = time;
        if (prevTime === undefined) {
            return;
        }

        this.update(time - prevTime);
        this.render();

    };

    /**
     * Triggered anytime the window gets resized.
     */
    resize = () => {
        const { width, height } = this.targetEl.getBoundingClientRect();
        if (this.camera instanceof THREE.PerspectiveCamera) {
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
        } else if (this.camera instanceof THREE.OrthographicCamera) {

        }
        this.renderer.setSize(width, height);
    };

    getCamera():THREE.Camera {
        return this.camera
    }

    getCameraPositionTarget():THREE.Vector3 {
        return this.camera.position.clone()
    }

    getCameraLookatTarget():THREE.Vector3 {
        return this.cameraControls.target.clone()
    }

    setCameraTargets(lookAtWorld:THREE.Vector3, positionWorld:THREE.Vector3, upWorld:THREE.Vector3 = new THREE.Vector3(0, 1, 0)) {
        let that = this
        this.cameraTransitionActive = true

        if (this.cameraDirectionLock) {
            let vec = positionWorld.clone().sub(lookAtWorld)
            let lockVec = this.cameraDirectionLock.clone().setLength(vec.length()).negate()
            positionWorld = lookAtWorld.clone().add(lockVec)
        }
        this.cameraLookatTarget.copy(lookAtWorld)

        this.cameraTransitionActive = true
        let lookAtTween = new TWEEN.Tween(this.cameraControls.target)
            .to(lookAtWorld, 500)
            .easing(TWEEN.Easing.Quartic.Out)
            .start()
            .onComplete(function() {
                that.cameraTransitionActive = false
                }
            )

        this.cameraPositionTarget.copy((positionWorld))
        this.cameraTransitionActive = true
        let positionTween = new TWEEN.Tween(this.cameraControls.object.position)
            .to(positionWorld, 500)
            .easing(TWEEN.Easing.Quartic.Out)
            .start()
            .onComplete(function() {
                    that.cameraTransitionActive = false
                    if (that.camera instanceof THREE.PerspectiveCamera) that.camera.updateProjectionMatrix()
                }
            )

        this.cameraUpTarget.copy((upWorld))
        this.cameraTransitionActive = true
        let upTween = new TWEEN.Tween(this.cameraControls.object.up)
            .to(upWorld, 500)
            .easing(TWEEN.Easing.Quartic.Out)
            .start()
            .onComplete(function() {
                    that.cameraTransitionActive = false
                }
            )
    }

    public setCameraType(cameraType:CameraType) {
        let up = this.camera.up
        let pos = this.camera.position
        let lookAt = this.camera.getWorldDirection(new THREE.Vector3())
        if (cameraType == CameraType.ORTHO) {
            this.setCamera(this.orthoCam)
        } else if (cameraType == CameraType.PERSPECTIVE) {
            this.setCamera(this.perspectiveCam)
        }
        this.camera.position.copy(pos)
        this.camera.up.copy(up)
        this.camera.lookAt(lookAt)
        this.resetCam(this.camera)
        this.app.broadcast("sceneCameraTypeChanged", this)
    }

    public matchCamera(cam:CameraElement) {
        cam.updateMatrixWorld(true)
        let pos = new THREE.Vector3(0, 0, 0).applyMatrix4(cam.matrixWorld)
        let target = new THREE.Vector3(0, 0, 1).applyMatrix4(cam.matrixWorld)
        let up = new THREE.Vector3(0, -1, 0).applyMatrix4(cam.matrixWorld).normalize()
        //this.cameraControls.cameraConstraintOverride = true
        this.setCameraTargets(target, pos)
    }

    private resetCam(cam:THREE.Camera) {
        let aspect = this.targetEl.clientWidth / this.targetEl.clientHeight;
        if (cam instanceof THREE.PerspectiveCamera) {
            cam.fov = 60
            cam.aspect = aspect
            cam.near = .1
            cam.far = this.cameraFar
            cam.zoom = 1
            cam.updateProjectionMatrix()
        } else if (cam instanceof THREE.OrthographicCamera) {
            let width = 10 * aspect;
            let height = 10;
            cam.left = width / -2
            cam.right = width / 2
            cam.top = height / 2
            cam.bottom = height / -2
            cam.near = -this.cameraFar
            cam.far = this.cameraFar
            cam.zoom = 1
            cam.updateProjectionMatrix()
        }
    }
}