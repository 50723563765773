import {WireframeElement} from "./wireframeElement";
import * as THREE from "three";
import {Layer} from "./layer";

export class RaycastHit {
    layer:Layer
    intersect:THREE.Intersection
}

export default abstract class RaycastHandler {
    abstract handleRaycast(raycast: THREE.Raycaster): RaycastHit[]
}