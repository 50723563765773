import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import ProjectImageCollection from "../../../collections/projectImages/projectImage.collection";

@Component({
    selector: 'project-image-manager-toolbar',
    styles: [require('./projectImageManagerToolbar.component.scss')],
    template: require('./projectImageManagerToolbar.component.html')
})
export default class ProjectImageManagerToolbarComponent {
    @Input() public projectImageCollection: ProjectImageCollection;
    @Input() public projectImageCollectionKeys: string[];
    @Output() viewChanged = new EventEmitter<string>();
    @Output() projectImageCollectionKeyChanged = new EventEmitter<string>();
    viewOptions = ['cards', 'thumbnails'];

    constructor() {
    }

    onViewOptionSelected(viewOption:string): void {
        this.triggerViewChanged(viewOption);
    }

    triggerViewChanged(viewOption:string){
        this.viewChanged.emit(viewOption)
    }

    onProjectImageCollectionKeySelected(projectImageCollectionKeyOption:string): void {
        this.triggerProjectImageCollectionKeyChanged(projectImageCollectionKeyOption);
    }

    triggerProjectImageCollectionKeyChanged(projectImageCollectionKeyOption:string){
        this.projectImageCollectionKeyChanged.emit(projectImageCollectionKeyOption)
    }
}