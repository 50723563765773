import {EnumProperty} from "./enumProperty";
import {WireframeElementType} from "../../application/wireframeElementType";
import {SeenState} from "./seenState";

export class SeenProperty extends EnumProperty {
    constructor() {
        super();
        this.isEditable = true;
        this.allowMultiplePerElement = false;
        this.allowMultiplePerWireframe = false;
        this.name = "Seen";
        this.type = ".SeenProperty";
        this.allowableElementTypes = [WireframeElementType.vertex];
        this.setValuesFromEnum(SeenState);
    }
}