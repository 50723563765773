import {EventEmitter} from "@angular/core";
import InteractiveFilterCollection from "../../collections/interactive/interactiveFilter.collection";
import {InteractiveFilterModel} from "./interactiveFilter.model";
import {InteractiveModel} from "./interactive.model";

export interface InteractiveFilterExpression<T extends InteractiveModel> {
    interactiveFilterCollection: InteractiveFilterCollection<InteractiveFilterModel<T>>
}

export class InteractiveFilterExpressionModel<T extends InteractiveModel> implements InteractiveFilterExpression<T> {
    interactiveFilterCollection: InteractiveFilterCollection<InteractiveFilterModel<T>> = new InteractiveFilterCollection<InteractiveFilterModel<T>>();
    filterChanged = new EventEmitter<InteractiveFilterModel<T>>();

    constructor(interactiveFilters:InteractiveFilterModel<T>[] = []) {
        interactiveFilters.forEach((interactiveFilterModel) => {
            this.interactiveFilterCollection.add(interactiveFilterModel);
        })
    }

    addFilter(interactiveFilterModel: InteractiveFilterModel<T>) {
        this.interactiveFilterCollection.add(interactiveFilterModel);
        interactiveFilterModel.filterChanged.subscribe(this.onFilterChanged.bind(this));
        this.triggerFilterChanged(interactiveFilterModel);
    }

    removeFilter(interactiveFilterModel: InteractiveFilterModel<T>) {
        this.interactiveFilterCollection.remove(interactiveFilterModel);
        this.triggerFilterChanged(interactiveFilterModel);
    }

    filter(model: T) {
        if (!model) return false;
        if (!this.interactiveFilterCollection) return false;
        let passesFilter = true;
        this.interactiveFilterCollection.forEach((interactiveFilterModel) => {
            if (!passesFilter) return;
            passesFilter = interactiveFilterModel.filter(model);
        });
        return passesFilter;
    }

    onFilterChanged(interactiveFilterModel: InteractiveFilterModel<T>) {
        this.triggerFilterChanged(interactiveFilterModel)
    }

    triggerFilterChanged(interactiveFilterModel: InteractiveFilterModel<T>) {
        this.filterChanged.emit(interactiveFilterModel);
    }
}