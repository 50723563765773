import tpl from './adjust.html';
import {AreaProperty} from "../../models/property/areaProperty";
import 'angular-ui-grid/ui-grid.min.css';
import {WireframeElementType} from "../../application/wireframeElementType";
import {ElementPickOperation} from "../../application/elementPickOperation";
import {VerifiedProperty} from "../../models/property/verifiedProperty";
import {SceneService} from "../../services/scenes/scene.service";
import {GeometryTool} from "../../application/geometryTool";
import {CameraView} from "../../models/cameras/cameraView";
import {WireframeElement} from "../../application/wireframeElement";
import {CanvasOperations} from "../../canvasOperations";
import {AdjustSection} from "./adjustSection";
import {PointVisibility} from "../../pointVisibility";
import {VertexElement} from "../../application/vertexElement";
import {WidgetElement} from "../../application/widgetElement";
import {SidebarMode} from "../../application/sidebarMode";
import {PlaneElement} from "../../application/planeElement";

export default {
    bindings: {},
    template: tpl,
    controller: ['$rootScope', '$scope', '$route', '$uibModal', 'uiGridConstants', 'sceneService', function ($rootScope, $scope, $route, $uibModal, uiGridConstants, sceneService:SceneService) {
        //init($route);
        this.scope = $scope;
        let ctrl = this;
        let app = sceneService.wireframeApplication
        //sceneService.wireframeApplication.adjustController = this;
        this.canvasOperations = new CanvasOperations(sceneService.wireframeApplication, ctrl)
        this.adjustSection = new AdjustSection(sceneService.wireframeApplication, ctrl)
        this.pointVisibility = new PointVisibility(sceneService.wireframeApplication, ctrl)
        // canvasOperations/Adjustmode state
        this.mouseDownStack = ["imageFullScreen1", "imageFullScreen2", "imageFullScreen3"];
        this.dictLines = {};
        this.dictGraphs = {};
        this.xFactor = -1;
        this.yFactor = -1;
        this.canvas1
        this.canvas2
        this.canvas3
        this.cameraObject2
        this.cameraObject1
        this.cameraObject3
        this.triangulationMode = 2;
        this.goodAngleThreshold = 5;
        this.normalAngleThreshold = 2;
        this.previousSliderValue = 50;
        this.defaultZoomValue = 5;
        this.zoomState = "dragPoint";
        this.inspectFrames;
        this.defaultSliderValue = 50;
        this.adjustVertex = null;
        this.perVertexTime = 0
        this.pointsForTriangulation = []
        this.navigationOrder = []

        this.defaultZoomLevel = 66;
        this.baseZoomLevel = 50;
        this.zoomLevel = this.defaultZoomLevel;
        this.adjustViews = []
        this.emptyImage = "img/Spinner.svg";
        this.currentFrame = null;
        this.currentImageData = this.emptyImage;
        this.currentImageHeaders = [];

        this.observations = []
        let adjustVert
        this.$onInit = function () {
            //(app.activeTool as GeometryTool).setActionMode(ActionMode.SELECT);
            let that = this

            // Initialize adjust views
            for (let i = 0; i < 3; i++) {
                that.adjustViews.push({
                    loadProgress: 0,
                    isImageLoading: true,
                    isImageLoaded: false,
                    canvas: null,
                    redrawFunc: null,
                    previousZoomLevel: 50
                });
            }
            ctrl.observations.push(sceneService.wireframeApplication.eventEmitter.on("lockWireframeElement").subscribe((locked:WireframeElement[]) => {
                let plane = locked.find((el) => el instanceof PlaneElement)
                if (plane) {
                    that.triangulationMode = 1
                } else {
                    if (that.triangulationMode == 1) that.triangulationMode = 2
                }
            }))
            ctrl.observations.push(sceneService.wireframeApplication.eventEmitter.on("elementTransformChanged").subscribe((el) => {
                if (el == that.adjustVertex) {
                    that.adjustSection.adjustWireFrameVertex()
                    that.adjustViews.forEach(function (v) {
                        if (v.redrawFunc) v.redrawFunc();
                    });
                }
            }))
            ctrl.observations.push(sceneService.wireframeApplication.eventEmitter.on("selectWireframeElement").subscribe((selected:WireframeElement[]) => {
                let el = selected.find((el) => (el instanceof VertexElement || el instanceof WidgetElement))
                if (el) {
                    that.setAdjustVertex(el)
                }
            }))

            let vertEl = sceneService.wireframeApplication.selectedElements.find((el) => (el instanceof VertexElement || el instanceof WidgetElement))
            if (vertEl) {
                that.setAdjustVertex(vertEl)
            } else if (this.gridOptions.data.length > 0) {
                let vertexEl = sceneService.wireframeApplication.findWireframeElement(WireframeElementType.vertex, that.gridOptions.data[0].id);
                that.setAdjustVertex(vertexEl);
            }
        };

        this.$onDestroy = function() {
            ctrl.observations.forEach((sub) => sub.unsubscribe())
        }

        this.$postLink = function () {
            ctrl.initZoomLevels();
            ctrl.updateVertexTable();
            /*
            if (sceneService.wireframeApplication.adjustVertex) {
                ctrl.loadAdjacentVertexImages();
                setTimeout(function() {
                    ctrl.canvasOperations.adjustWireFrameVertex()
                }, 0);
            }
            */
        };

        this.viewerMode = function() {
            return sceneService.wireframeApplication.viewerMode;
        }

        this.showImageInfo = function (canvasIdx) {
            let camIdx = this.adjustViews[canvasIdx].cameraIndex;
            let view = sceneService.wireframeApplication.projectData.metadata.reconstructed.views["view-" + camIdx];
            this.currentFrame = view.frameMetadata;
            this.currentImageData = this.emptyImage;
            this.currentImageHeaders = [{name: "Name", value: this.currentFrame.filename},
                {name: "Original Filename", value: this.currentFrame.originalFilename}
            ];
            Object.keys(this.currentFrame.exif).forEach(function (k) {
                ctrl.currentImageHeaders.push({name: k, value: ctrl.currentFrame.exif[k]})
            });
            let imgObject = sceneService.wireframeApplication.resourceManager.getFrameThumbnailKey(this.currentFrame.frameId);
            sceneService.wireframeApplication.resourceManager.getImageData(imgObject, function (data) {
                ctrl.currentImageData = data;
            }, null);
            if (this.currentFrame) {
                this.imageModal = $uibModal.open({
                    template: require('./adjust.html'),
                    keyboard: true, // disable escape key dismiss
                    scope: $scope
                });
                this.imageModal.result.then(function () {

                }, function () {

                });
            }
        };

        this.zoomIncrement = function (increment) {
            this.zoomLevel += increment;
            this.zoomChanged();
        };

        this.zoomChanged = function () {
            this.adjustViews.forEach(function (v) {
                ctrl.updateZoomForView(v);
            });
        };

        this.planeSort = function () {
            this.gridApi.grid.columns.forEach(function (col) {                
                    col.sort = {};
            });            
            this.planeSortOrder = {};
            this.updateVertexTable();
            this.loadAdjacentVertexImages();
        };

        this.sortChanged = function () {
            ctrl.enablePlaneSort(false);
            ctrl.loadAdjacentVertexImages();
        };

        this.planeSortActive = false;
        this.enablePlaneSort = function (active) {
            ctrl.planeSortActive = active;
            if (active) {
                ctrl.planeSort();
            }
            let geomTool = sceneService.wireframeApplication.getTool("GeometryTool") as GeometryTool
            geomTool.autoLockMode = active
        };

        this.isExternalVertexSelectionEvent = false;
        let gridOptions = this.gridOptions = {
            appScopeProvider: ctrl,
            onRegisterApi: function (gridApi) {
                ctrl.gridApi = gridApi;
                ctrl.gridApi.core.on.sortChanged(null, ctrl.sortChanged);
                gridApi.selection.on.rowSelectionChanged(null, function (row) {
                    if (ctrl.isExternalVertexSelectionEvent) return;
                    let vertId = row.entity.id;
                    let vertEl = sceneService.wireframeApplication.findWireframeElement(WireframeElementType.vertex, vertId);
                    sceneService.wireframeApplication.selectWireframeElement(null, false, false);
                    sceneService.wireframeApplication.selectWireframeElement([vertEl], true);
                    sceneService.wireframeApplication.lookAtElements(sceneService.wireframeApplication.selectedElements);
                    sceneService.wireframeApplication.lookAtElements(sceneService.wireframeApplication.selectedElements);
                });

                gridApi.core.on.rowsRendered(null, function () {
                    if (!ctrl.vertexTableInitialLoad && ctrl.adjustVertex && ctrl.gridOptions.data.length > 0) {
                        ctrl.vertexTableInitialLoad = ctrl.loadAdjacentVertexImages();
                    }
                });
            },
            gridMenuCustomItems: [
                {
                    title: 'Plane Sort',
                    action: function ($event) {
                        this.grid.appScope.enablePlaneSort(true);
                    },
                    order: 0
                },
            ],
            enableFiltering: false,
            enableGridMenu: true,
            exporterMenuCsv: false,
            enableRowSelection: true,
            enableFullRowSelection: true,
            enableRowHeaderSelection: false,
            enableColumnMenus: false,
            multiSelect: false,
            enableColumnResizing: true,
            enableSorting: true,
            enableRowHashing:false,
            noUnselect: false,
            enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
            columnDefs: [
                {
                    field: 'name',
                    type: 'string'
                },
                {
                    field: 'confidence',
                    type: 'number',
                    visible: true
                },
                {
                    field: 'verified',
                    visible: true,
                    cellTemplate: '<input type="checkbox" ng-model="row.entity.verified" ng-change="grid.appScope.toggleVerified(row.entity.id)">',
                    width: 20,
                    displayName: "✓"
                },
                {
                    field: 'planeArea',
                    type: 'number',
                    visible: false,
                },
            ],
            data: [],
            rowEquality:function(a,b) {
                return (Number(a['id']) == Number(b['id']))
            }

        };

        this.toggleVerified = function (vertId) {
            let vert = sceneService.wireframeApplication.wireframe.vertices[vertId];
            if (!vert) return;
            let verifiedProp = sceneService.wireframeApplication.wireframe.findProperty(VerifiedProperty, true);
            let currentVal = verifiedProp.getValue(vert);
            if (currentVal == null || currentVal == 0) {
                currentVal = 1;
            } else {
                currentVal = 0;
            }
            verifiedProp.setValue(sceneService.wireframeApplication.wireframeLayer.findWireframeElement(WireframeElementType.vertex, vertId), currentVal);
            sceneService.wireframeApplication.wireframeLayer.redrawWireframe();
        };

        this.planeSortOrder = {};
        this.updateVertexTable = function () {
            if (!sceneService.wireframeApplication.wireframe) return;
            let vertIds = Object.keys(sceneService.wireframeApplication.wireframe.filterGeometry(sceneService.wireframeApplication.wireframe.vertices));
            let verifiedProp = sceneService.wireframeApplication.wireframe.findProperty(VerifiedProperty, false);
            let confidenceProp = sceneService.wireframeApplication.wireframe.findPropertyByName("confidence");
            let areaProp = sceneService.wireframeApplication.wireframe.findProperty(AreaProperty, true);
            let rows = [];
            vertIds.forEach(function (vertId) {
                let vert = sceneService.wireframeApplication.wireframe.vertices[vertId];
                if (!vert) return
                let verified = false;
                if (verifiedProp) {
                    verified = verifiedProp.getValue(vert) == 1;
                }
                let confidence = "";
                if (confidenceProp) {
                    let confidenceValue = Number(confidenceProp.getValue(vert));
                    confidence = confidenceValue.toFixed(2);
                    if (isNaN(confidenceValue)) confidence = "-";
                }

                let plane = sceneService.wireframeApplication.wireframeLayer.getLargestPlane(vert);
                let planeArea = 0;
                let planeId = 0;
                if (plane) {
                    planeArea = areaProp.getValue(plane);
                    planeId = plane.id;
                }
                let row = {
                    id: Number(vertId),
                    name: "vertex-" + vertId,
                    confidence: confidence,
                    verified: verified,
                    planeId: planeId,
                    planeArea: planeArea
                };
                rows.push(row);
            });

            if (this.planeSortActive) {
                let self = this;
                if (Object.keys(self.planeSortOrder).length === 0) { // sort then store
                    rows.sort(function (a, b) { return b.planeArea - a.planeArea; });
                    rows.forEach(function(val, index) { self.planeSortOrder[val.id] = index + 1} )
                } else { // use stored sorting and place unsorted vertices at the end
                    rows.sort(function (a,b) { 
                        let aIndex = self.planeSortOrder[a.id] || rows.length;
                        let bIndex = self.planeSortOrder[b.id] || rows.length;
                        return aIndex - bIndex;
                    })
                }
            }

            // this.gridOptions.data = rows;
            this.gridOptions.data.length = 0;
            this.gridOptions.data.push(...rows);

            this.updateRowSelection();
            this.updateVertexCounts();
            if (this.gridApi && this.gridApi.core) {
                this.gridApi.core.refresh();
            }
        };

        $rootScope.$on('wireframeTopologyChanged', function () {
            if (sceneService.wireframeApplication.isSceneLoaded && !sceneService.wireframeApplication.isRedrawing) {
                ctrl.updateVertexTable();
                if (ctrl.planeSortActive) ctrl.planeSort();
            }
        });

        this.numVerifiedVertices = 0;
        this.totalVerticies = 1;
        this.updateVertexCounts = function () {
            let verts = Object.values(sceneService.wireframeApplication.wireframe.filterGeometry(sceneService.wireframeApplication.wireframe.vertices));
            this.totalVerticies = verts.length;
            let verifiedProp = sceneService.wireframeApplication.wireframe.findProperty(VerifiedProperty, true);
            this.numVerifiedVertices = 0;
            for (let i = 0; i < verts.length; i++) {
                let vert = verts[i];
                let verifiedVal = verifiedProp.getValue(vert);
                if (1 == verifiedVal) this.numVerifiedVertices++;
            }
        };

        this.updateRowSelection = function () {
            if (!this.gridApi) return;
            if (!ctrl.adjustVertex) return;
            this.isExternalVertexSelectionEvent = true;
            this.gridApi.selection.clearSelectedRows();            
            for (let i = 0; i < this.gridOptions.data.length; i++) {
                let row = this.gridOptions.data[i];
                if (Number(row['id']) == Number((ctrl.adjustVertex as any).pvObject.id)) {
                    this.gridApi.selection.selectRow(row);
                    break;
                }
            }
            this.gridApi.core.queueGridRefresh();
            this.isExternalVertexSelectionEvent = false;
        };

        this.setAdjustVertex = function(adjustEl:WireframeElement) {
            if (this.adjustVertex == adjustEl) return
            this.adjustVertex = adjustEl
            this.adjustSection.adjustWireFrameVertex()
            ctrl.initZoomLevels();
            ctrl.updateVertexTable();
            if (ctrl.adjustVertex) {
                ctrl.loadAdjacentVertexImages();
            }
        }

        this.loadAdjacentVertexImages = function () {
            if (!ctrl.adjustVertex) return;
            let verts = [this.getNextVertex((ctrl.adjustVertex as any).pvObject, true)];
            if (null == verts[0]) return;
            verts.push(this.getNextVertex(verts[0], true));
            for (let i = 0; i < verts.length; i++) {
                let vert = verts[i];
                if (!vert) continue;
                if (!vert.hframes) vert.hframes = [];
                let frames = ctrl.pointVisibility.getPoint3DFrames(verts[i], false);
                frames = ctrl.canvasOperations.getOptimalViewsForVertex(vert);
                for (let j = 0; j < frames.length; j++) {
                    let frame = frames[j];
                    let key = sceneService.wireframeApplication.resourceManager.getFrameKey(frame);
                    if (key) sceneService.wireframeApplication.resourceManager.enqueueTransfer(key);
                }
            }
            return true;
        };

        this.restoreCameraLayerHidden;
        this.pickingCamera;

        this.promptCameraSelect = function (camIdx) {
            console.log("promptCameraSelect " + camIdx);
            let currentViews = [];
            this.adjustViews.forEach(function (v) {
                if (v.cameraObject && Number(v.cameraObject.cameraIndex) >= 0)
                    currentViews.push(Number(v.cameraObject.cameraIndex));
            });
            console.log("currentViews", currentViews);
            console.log("hframes", (ctrl.adjustVertex as any).hframes);
            let that = this;
            let candidateViews = [];
            if (!this.pickingCamera) {
                this.restoreCameraLayerHidden = !sceneService.wireframeApplication.sceneManager.getActiveWireframeLayer().elementLayers[WireframeElementType.camera].isVisible;
            }
            sceneService.wireframeApplication.sceneManager.getActiveWireframeLayer().elementLayers[WireframeElementType.camera].isVisible = true;
            this.pickingCamera = true;
            let cameraPick = new ElementPickOperation();
            cameraPick.message = "Select a Camera, or Esc to cancel";
            cameraPick.onElementPicked.push(function (cam) {
                console.log("endCameraPick", cam);
                if (cam && cam.pvType == WireframeElementType.camera) {
                    ctrl.canvasOperations.fillCanvasWithImageAndPoint(cam.pvObject.id, camIdx + 1, ctrl.canvasOperations.canvasPoint3D, false);
                }
                if (this.restoreCameraLayerHidden) sceneService.wireframeApplication.sceneManager.getActiveWireframeLayer().elementLayers[WireframeElementType.camera].isVisible = false;
                this.pickingCamera = false;
                sceneService.wireframeApplication.endElementPick();
            });
            cameraPick.isElementPickable.push(function (el) {
                if (el.pvType == WireframeElementType.camera) {
                    if ((ctrl.adjustVertex as any).hframes.includes(el.pvObject.id)) {
                        if (!currentViews.includes(el.pvObject.id)) {
                            return true;
                        }
                    }
                }
                return false;
            });
            sceneService.wireframeApplication.startElementPick(cameraPick);

        };

        this.initZoomForView = function (v) {
            if (window.localStorage.getItem("adjustMode.autoScale") === "true" && v.optimumScale != null) {
                if (v.canvas) {
                    let scale =  v.optimumScale / v.canvas.currentScale;                    
                    v.canvas.zoomAndPointOnCenter(scale, scale);
                }
                if (v.redrawFunc) v.redrawFunc();
                ctrl.scope.$apply();
            } else {
                ctrl.updateZoomForView(v);
            }
        };

        this.updateZoomForView = function (v) {
            let delta = Number(this.zoomLevel) - Number(v.previousZoomLevel);
            let factor = Math.pow(1.08, delta);
            if (v.canvas) v.canvas.zoomAndPointOnCenter(factor, factor);
            if (v.redrawFunc) v.redrawFunc();
            v.previousZoomLevel = this.zoomLevel;
        };

        this.triangulationModeFunc = function (newMode) {
            if (arguments.length > 0) {
                ctrl.triangulationMode = Number(newMode);
            } else {
                return "" + ctrl.triangulationMode;
            }
        };

        this.applyButtonClicked = function () {
            ctrl.adjustSection.applyVertex();
        };

        this.resetButtonClicked = function () {
            ctrl.adjustSection.resetButton();
        };

        this.getCurrentVertexIndex = function () {
            let verts = sceneService.wireframeApplication.wireframe.filterGeometry(sceneService.wireframeApplication.wireframe.vertices);
            let vertexKeys = Object.keys(verts).sort();
            let index = -1;
            if (ctrl.adjustVertex) {
                for (let i = 0; i < vertexKeys.length; i++) {
                    if (vertexKeys[i] == (ctrl.adjustVertex as any).pvObject.id) {
                        index = (i + 1) % (vertexKeys.length + 1);
                        return index;
                    }
                }
            }
            if (index !== -1)
                return index;
            else
                return 1;
        };

        this.getLockedPlane = function () {
            return sceneService.wireframeApplication.getFirstLockedElement(WireframeElementType.plane);
        };

        this.getNumVertices = function () {
            let verts = sceneService.wireframeApplication.wireframe.filterGeometry(sceneService.wireframeApplication.wireframe.vertices);
            let numVerts = Object.keys(verts).length;
            return numVerts;
        };

        this.getView = function (idx) {
            return this.adjustViews[idx];
        };

        this.getNumViews = function () {
            let numViews = Object.values(sceneService.wireframeApplication.cameraDict).length - 1;
            return numViews;
        };

        this.changeView = function (canvasIdx) {
            let newViewIdx = this.adjustViews[canvasIdx].cameraIndex;
            console.log("changeView " + canvasIdx + " " + newViewIdx);
            ctrl.adjustSection.setFrame(canvasIdx + 1, newViewIdx);
        };

        this.initZoomLevels = function () {
            if (window.localStorage.getItem("adjustMode.autoScale") === "true") {
                ctrl.zoomLevel = ctrl.defaultZoomLevel;
            }
            this.adjustViews.forEach(function (v) {
                v.previousZoomLevel = ctrl.baseZoomLevel;
            })
        };

        this.getNextVertex = function (vert, direction) {
            if (!this.gridApi) return null;
            let vertIndex;
            let orderedRenderRows = ctrl.gridApi.core.getVisibleRows();
            if (orderedRenderRows.length < 1) return null;
            let rowKeys = Object.keys(orderedRenderRows);
            for (vertIndex = 0; vertIndex < rowKeys.length; vertIndex++) {
                if (orderedRenderRows[rowKeys[vertIndex]].entity.id == vert.id)
                    break;
            }

            let nextId = -1;
            if (direction) {
                let nextIndex = (vertIndex + 1) % rowKeys.length;
                nextId = orderedRenderRows[rowKeys[nextIndex]].entity.id;
            } else {                
                let nextIndex = (rowKeys.length + (vertIndex - 1)) % rowKeys.length;
                nextId = orderedRenderRows[rowKeys[nextIndex]].entity.id;
            }
            let nextVert = sceneService.wireframeApplication.wireframe.vertices[nextId];
            return nextVert;
        };

        this.viewedVertexStack = [];
        this.arrowClick = function (advance) {
            sceneService.wireframeApplication.endElementPick();
            try {
                ctrl.adjustSection.applyVertex();
            } catch (e) {
                console.log("applyVertex error", e);
            }

            let currentVert = (ctrl.adjustVertex as any).pvObject;
            let nextVert = currentVert;
            
            if (advance) {
                let verifiedProp = sceneService.wireframeApplication.wireframe.findProperty(VerifiedProperty, true);            
                do {
                    nextVert = this.getNextVertex(nextVert, advance);
                } while(currentVert.id != nextVert.id && verifiedProp.getValue(nextVert));    

                if (currentVert.id == nextVert.id) {
                    ctrl.updateVertexTable();
                    sceneService.wireframeApplication.log("All Vertices Verified.");
                    return;
                }
                this.viewedVertexStack.push(currentVert);
            } else {
                nextVert = this.viewedVertexStack.length > 0 ?
                            this.viewedVertexStack.pop() :
                            this.getNextVertex(nextVert, advance);
            }           
            
            let nextVertEl = sceneService.wireframeApplication.findWireframeElement(WireframeElementType.vertex, nextVert.id);
            sceneService.wireframeApplication.selectWireframeElement(null);
            sceneService.wireframeApplication.selectWireframeElement([nextVertEl], true);
            sceneService.wireframeApplication.lookAtElements(sceneService.wireframeApplication.selectedElements);
        };

        this.changeImage = function (canvasIdx, upDown) {
            if (upDown) {
                ctrl.adjustSection.previousFrame(canvasIdx + 1);
            } else {
                ctrl.adjustSection.nextFrame(canvasIdx + 1);
            }
        };

        this.keys = {
            A: 'A'.charCodeAt(0),
            S: 'S'.charCodeAt(0),
            N: 'N'.charCodeAt(0),
            P: 'P'.charCodeAt(0),
            Z: 'Z'.charCodeAt(0)
        };

        this.onKeyDown = function (event) {
            if(!event.ctrlKey && !event.shiftKey && !event.altKey){

                switch (event.keyCode) {
    
                    case this.keys.S:
                        let vertex = (ctrl.adjustVertex as any).pvObject;
                        let enoughImages = (vertex.hframes.length - vertex.occList.length) >= 4;

                        if (enoughImages) {
                            this.changeImage(0, true);
                            this.changeImage(1, true);
                            this.changeImage(2, true);
                        }
                        break;
    
                    case this.keys.A:
                        ctrl.adjustSection.applyVertex();
                        break;
    
                    case this.keys.N:
                        this.arrowClick(true);
                        break;
    
                    case this.keys.P:
                        this.arrowClick(false);
                        break;
                }
            }

            if (event.ctrlKey && event.keyCode === this.keys.Z) {
                sceneService.wireframeApplication.undoLastEntry();     
                this.updateVertexTable();               
            }
        };

        this.updateVertexTable();
    }]
}
