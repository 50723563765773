import {Component, NgZone, OnDestroy,} from '@angular/core';
import {WireframeApplication} from "../../application/wireframeApplication";
import {GeometryTool} from "../../application/geometryTool";
import {LockMode} from "../../application/lockMode";
import {PlaneDetectionMode} from "../../application/planeDetectionMode";
import {WireframeElementType} from "../../application/wireframeElementType";
import {ActionMode} from "../../application/actionMode";
import {SnapMode} from "../../application/snapMode";
import {SceneService} from "../../services/scenes/scene.service";

@Component({
    selector: 'geometry-tool-control',
    styles: [require('./geometryToolControl.component.scss')],
    template: require('./geometryToolControl.component.html')
})
export class GeometryToolControlComponent implements OnDestroy {
    private app:WireframeApplication
    constructor(private zone:NgZone, private sceneService:SceneService) {
        this.app = this.sceneService.wireframeApplication
    }

    get tool():GeometryTool {
        return this.app.getTool("GeometryTool") as GeometryTool
    }

    ngOnDestroy(): void {
    }

    toggleActionMode(mode) {
        this.tool.setActionMode(mode);
    }

    getActionMode():ActionMode {
        if (!this.tool) return ActionMode.MODIFY
        let actionMode = this.tool.actionMode
        return actionMode
    }

    toggleLockMode() {
        if (this.tool.lockMode == LockMode.COPLANAR) {
            this.tool.lockMode = LockMode.FORCE_TO_PLANE;
        } else {
            this.tool.lockMode = LockMode.COPLANAR;
        }
    };

    getLockMode():LockMode {
        if (!this.tool) return LockMode.FORCE_TO_PLANE
        return this.tool.lockMode;
    }

    getPlaneDetectionMode():PlaneDetectionMode {
        return this.app.planeDetectionMode;
    };

    togglePlaneDetectionMode() {
        if (this.app.planeDetectionMode == PlaneDetectionMode.ON) {
            this.app.planeDetectionMode = PlaneDetectionMode.OFF;
        } else {
            this.app.planeDetectionMode = PlaneDetectionMode.ON;
        }
        this.app.wireframeLayer.redrawWireframe();
    };

    toggleElementMode(mode) {
        this.tool.setElementMode(mode);
    }

    getElementMode():WireframeElementType {
        if (!this.tool) return WireframeElementType.vertex
        return this.tool.elementMode;
    };

    setAutoLockMode(v: boolean) {
        this.tool.autoLockMode = v;
        this.app.lockWireframeElement(null);
        if (this.tool.autoLockMode) this.app.lockLargestPlane();
    }

    get autoLockMode():boolean {
        if (!this.tool) return false
        return this.tool.autoLockMode
    }

    set autoLockMode(b:boolean) {
        this.tool.autoLockMode = b
        if (this.tool.autoLockMode) this.app.lockLargestPlane()
    }

    get snapMode():SnapMode {
        return this.app.snapMode
    }

    set snapMode(v) {
        this.app.snapMode = v
    }
}