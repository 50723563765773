import {Tool} from "./tool";
import {ContinuePolygon} from "./continuePolygon";
import {WireframeElementType} from "./wireframeElementType";

import {WireframeElement} from "./wireframeElement";
import {PlaneElement} from "./planeElement";



export default abstract class ShapeTool extends Tool {
    toolShape: ContinuePolygon

    onToolActivated() {
        this.highlightableFunc = (el:WireframeElement) => {
            if (el.pvObject.pvType != WireframeElementType.plane) return false
            return true
        }
    }

    updateActivePolygon() {

    }

    onMouseMove(event:MouseEvent) {
        super.onMouseMove(event)

        if (this.toolShape) {
            this.updateActivePolygon()
        } else {
            this.updateHighlightedElements()
        }
    }

    cancelActiveShape() {
        if (this.toolShape) {
            this.app.wireframeLayer.deletePlane(this.app.findWireframeElement(WireframeElementType.plane, this.toolShape.plane.id) as PlaneElement, true);
        }
        this.toolShape = null;
    }


}
