import {WireframeElement} from "./wireframeElement";
import {PV} from "../wireframe";
import {WireframeApplication} from "./wireframeApplication";
import {MeasurementUnit} from "./measurementUnit";
import {WireframeUtils} from "../wireframeUtils";
import * as THREE from 'three';
import {PlaneDetector} from "../planeDetector";
import GeometryCache from "./geometryCache";
import {WireframeElementType} from "./wireframeElementType";
import {TextProperty} from "../models/property/textProperty";
import {NumberProperty} from "../models/property/numberProperty";
import {CompoundProperty} from "../models/property/compoundProperty";

export class PlaneElement extends WireframeElement {
    pvObject:PV.Plane
    normalLine:THREE.Mesh

    getCenter(inWorldCRS:boolean = false):THREE.Vector3 {
        let vectorPos = new THREE.Vector3();
        let numVerts = 0;
        for (let i = 0; i < this.pvObject.vertexIds.length; i++) {
            let vert = this.wireframeLayer.wireframe.getVert3(this.pvObject.vertexIds[i]);
            if (vert) {
                numVerts++;
                vectorPos.add(vert);
            }
        }
        vectorPos.divideScalar(numVerts);
        if (inWorldCRS) vectorPos = this.wireframeLayer.toWorldCRS(vectorPos)
        return vectorPos;
    }

    getPlane3(inWorldCRS:boolean = false):THREE.Plane {
        let plane = this.wireframeLayer.wireframe.getPlane3(this.pvObject.id)
        if (inWorldCRS) plane = this.wireframeLayer.toWorldCRS(plane)
        return plane
    }

    createGeometry() {
        let mesh = new THREE.Mesh() as any;
        mesh.name = "plane-" + this.pvObject.id;
        this.add(mesh)

        let poly = this.pvObject.vertexIds;
        let ls = [];
        let center = new THREE.Vector3();
        let vertIds = [];
        for (let k = 0; k < poly.length; k++) {
            let vertex = this.wireframeLayer.wireframe.vertices[poly[k]];
            let pos = new THREE.Vector3(vertex.x, vertex.y, vertex.z);
            ls.push(pos);
            vertIds.push(poly[k]);
            center.add(pos);
        }

        center = center.divideScalar(poly.length);
        mesh.geometry = WireframeUtils.getPlaneGeometry(ls, null);
        mesh.material = this.material;
        mesh.isPickable = true;
        this.baseGeometry = [ mesh ]
        this.hitGeometry = [ mesh ]
        //mesh.wireframeElement = element;

        //mesh.labelSprite = edgeLabel;
        // normal
        let normalMaterial = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            transparent: true,
            opacity: .5,
            depthTest: false
        });

        let normalGeometry:THREE.Geometry = GeometryCache.getOrCache("planeNormalGeometry", () => {
            return new THREE.CylinderGeometry(1, 1, 1, 4, 1, false);
        })
        this.normalLine = new THREE.Mesh(normalGeometry, normalMaterial) as THREE.Mesh
        (this.normalLine as any).isPickable = true;
        this.normalLine.name = "normal"
        //normalMesh.wireframeElement = element;
        //this.add(this.normalLine);
        this.createLabelSprite()
        this.storeBaseMaterials()
    }


    get slopeEqualityGroupProp():TextProperty {
        return this.app.wireframe.findProperty(TextProperty, false, "slopeEqualityGroup") as TextProperty
    }

    get slopeMeasurementProp():NumberProperty {
        return this.app.wireframe.findProperty(NumberProperty, false, "slopeMeasurement") as NumberProperty
    }

    get anchorEdgeProp():CompoundProperty {
        return this.app.wireframe.findProperty(CompoundProperty, false, "anchorEdge") as CompoundProperty
    }

    getDimensionStrings(): string[] {
        let dim:string[] = []

        let scale = this.app.displayMeasurementScale
        let areaText = (scale * scale * this.pvObject.area / this.wireframeLayer.areaWorldScale).toFixed(this.app.dimensionViewPrecision);
        if (this.app.currentDisplayUnit != MeasurementUnit.UNITLESS) {
            if (this.app.currentDisplayUnit == MeasurementUnit.FFI) {
                areaText += MeasurementUnit[MeasurementUnit.FT].toString().toLowerCase();
            } else {
                areaText += MeasurementUnit[this.app.currentDisplayUnit].toString().toLowerCase();
            }
            areaText += "\xB2"
        }

        let perimeterText = (scale * this.pvObject.perimeter / this.wireframeLayer.linearWorldScale).toFixed(this.app.dimensionViewPrecision);
        if (this.app.currentDisplayUnit != MeasurementUnit.UNITLESS) {
            perimeterText += MeasurementUnit[this.app.currentDisplayUnit].toString().toLowerCase();
        }
        if (this.app.currentDisplayUnit == MeasurementUnit.FFI) {
            let measurement = scale * this.pvObject.perimeter / this.wireframeLayer.linearWorldScale;
            perimeterText = WireframeUtils.getFeetFractionalInches(measurement);
        }

        let slopeText = "";
        if (this.app.degreeMode) {
            slopeText = (this.pvObject.slope).toFixed(this.app.dimensionViewPrecision) + "\xB0";
        } else {
            slopeText = WireframeUtils.getPitch(this.pvObject.pitch) + "/12";
        }

        let slopeMeasurementVal
        if (this.slopeMeasurementProp) {
            slopeMeasurementVal = this.slopeMeasurementProp.getValue(this.pvObject)
        }
        if (null != slopeMeasurementVal) {
            dim.push("slopeMeasurement: " + slopeMeasurementVal.toFixed(this.app.dimensionViewPrecision) + "\xB0")
        } else {
            dim.push(areaText)
            dim.push(perimeterText)

            // we don't want any slope values shown for LN demo
            if (!this.slopeMeasurementProp) {
                dim.push(slopeText)
            }
        }

        if (this.slopeEqualityGroupProp) {
            let segVal = this.slopeEqualityGroupProp.getValue(this.pvObject)
            if (segVal) {
                dim[0] += " (" + segVal + ")"
            }
        }

        if (this.anchorEdgeProp) {
            let aeVal = this.anchorEdgeProp.getValue(this.pvObject)
            if (aeVal) dim[0] += "*"
        }
        return dim
    }

    updateGeometry() {
        super.updateGeometry()
        //this.labelSprite.scale.set(this.app.edgeLabelSize, this.app.edgeLabelSize, 1);
        let plane3: THREE.Plane = this.wireframeLayer.wireframe.getPlane3(this.pvObject.id);
        let poly = this.pvObject.vertexIds;
        let ls = [];
        //let center = new THREE.Vector3();
        let vertIds = [];
        let holes = [];
        for (let k = 0; k < poly.length; k++) {
            let pos = this.wireframeLayer.wireframe.getVert3(poly[k]);
            ls.push(pos);
            vertIds.push(poly[k]);
            //center.add(pos);
        }
        let center = this.getCenter()
        let planeMesh = this.baseGeometry[0] as THREE.Mesh
        if (planeMesh.geometry) {
            this.wireframeLayer.disposeHierarchy(planeMesh.geometry)
        }
        planeMesh.geometry = WireframeUtils.getPlaneGeometry(ls, (planeMesh.geometry as THREE.Geometry).faceVertexUvs[0]);
        this.baseGeometry[0] = planeMesh
        this.hitGeometry = this.baseGeometry
        this.highlightGeometry = this.baseGeometry
        //center = center.divideScalar(poly.length);
        this.labelSprite.position.copy(center);
        if (true || this.wireframeLayer.planeNormalLayer.isVisible) {
            let normalLength = .5;
            let normalVec = plane3.normal.clone().setLength(normalLength);
            let normalEndPos = center.clone().add(normalVec);

            let edgeWidth = this.app.edgeSize * this.app.lineVertexSizeRatio;
            WireframeUtils.updateCylinderGeometry(this.normalLine, center, normalEndPos, edgeWidth);
            let normalCenter = center.clone();
            normalCenter.add(normalEndPos);
            normalCenter.multiplyScalar(.5);
            this.normalLine.position.copy(normalCenter);
        }
        this.updateDerivedProperties()
    }

    updateMaterials() {
        super.updateMaterials()

        if (this.pvObject.parentId != null) {
            this.material.color.copy(this.materialProps.holeMaterial.color)
        }

        //this.normalLine.visible = this.visible && app.planeNormalLayer.getActive() as boolean;
        if (this.visible && this.wireframeLayer.planeNormalLayer.isVisible) {
            this.add(this.normalLine)
        } else {
            this.remove(this.normalLine)
        }
        // plane labels
        //this.labelSprite.visible = this.visible && this.labelSprite.text != null && wfOps.isLayerVisible(app.planeDimLayer);
        //this.annotationLabel.visible = this.visible && this.annotationLabel.text != null && wfOps.isLayerVisible(app.planeAnnotationLayer);
    }

    updateDerivedProperties() {
        this.wireframeLayer.wireframe.updatePlaneNormal(this.pvObject);
        PlaneDetector.updatePlaneParameters(this, this.wireframeLayer.wireframe, this.app.getGravityPlane());
    }

    getSupportingGeometry(): WireframeElement[] {
        let elements:WireframeElement[] = []
        let that = this
        this.pvObject.vertexIds.forEach((vId) => {
            elements.push(that.wireframeLayer.findWireframeElement(WireframeElementType.vertex, vId))
        })
        this.pvObject.edgeIds.forEach((eId) => {
            elements.push(that.wireframeLayer.findWireframeElement(WireframeElementType.edge, eId))
        })
        return elements
    }

    setPosition(worldPos: THREE.Vector3) {
        let center = this.getCenter(true)
        let offset = worldPos.clone().sub(center)
        for (let i = 0; i < this.pvObject.vertexIds.length; i++) {
            let vertId = this.pvObject.vertexIds[i]
            let v = this.wireframeLayer.wireframe.getVert3(vertId).add(offset)
            this.wireframeLayer.wireframe.setVert3(vertId, v)
        }
    }

    getProjectionPlane(from:THREE.Vector3):THREE.Plane {
        return this.getPlane3(true)
    }

    getOptimumViewingPlane():THREE.Plane {
        let p = this.getPlane3(true)
        if (new THREE.Vector3(0, -1, 0).dot(p.normal) > 0) {
            p.negate()
        }
        return p
    }

}
