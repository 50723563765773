import {EnumProperty} from "./enumProperty";
import {WireframeElementType} from "../../application/wireframeElementType";
import {ExcludeOptions} from "./excludeOptions";

export class ExclusionProperty extends EnumProperty {
    constructor() {
        super();
        this.isEditable = true;
        this.allowMultiplePerElement = false;
        this.allowMultiplePerWireframe = false;
        this.name = "Exclusion";
        this.type = ".ExclusionProperty";
        this.allowableElementTypes = [WireframeElementType.plane];
        this.setValuesFromEnum(ExcludeOptions);
    }
}