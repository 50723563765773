import {
    AfterViewInit, ChangeDetectorRef,
    Component,
    ComponentFactoryResolver, DoCheck,
    Input, IterableDiffers,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {WireframeElement} from "../../application/wireframeElement";
import {Property} from "../../models/property/property";
import {TextProperty} from "../../models/property/textProperty";
import {EnumProperty} from "../../models/property/enumProperty";
import NumberPropertyEditorComponent from "./number/numberPropertyEditor.component";
import TextPropertyEditorComponent from "./text/textPropertyEditor.component";
import EnumPropertyEditorComponent from "./enum/enumPropertyEditor.component";
import ElementReferencePropertyEditorComponent from './elementReference/elementReferencePropertyEditor.component';
import {PropertyManager} from "../../models/property/propertyManager";
import {PropertyValue} from "../../models/property/propertyValue";
import {NumberProperty} from "../../models/property/numberProperty";
import WireframeElementCollection from "../../collections/wireframeElements/wireframeElement.collection";
import {DictionaryProperty} from "../../models/property/dictionaryProperty";
import DictionaryPropertyEditorComponent from "./dictionary/dictionaryPropertyEditor.component";
import {ElementReferenceProperty} from "../../models/property/elementReferenceProperty";
import {CompoundProperty} from "../../models/property/compoundProperty";

@Component({
    selector: 'wireframe-selection-property-list-item',
    styles: [require('./wireframeSelectionPropertyListItem.component.scss')],
    template: require('./wireframeSelectionPropertyListItem.component.html')
})
export default class WireframeSelectionPropertyListItemComponent implements AfterViewInit, DoCheck {
    @ViewChild("propertyInputContainer", {read: ViewContainerRef, static: true}) propertyInputContainerRef: ViewContainerRef;
    @Input() wireframeElements: WireframeElementCollection;
    @Input() propertyManager: PropertyManager;
    @Input() property: Property;

    iterableDiffer;

    constructor(
        private _iterableDiffers: IterableDiffers,
        private changeDetectorRef: ChangeDetectorRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {
        this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    }

    ngDoCheck(): void {
        let changes = this.iterableDiffer.diff(this.wireframeElements);
        if (changes) {
            this.showPropertyInput();
        }
    }

    get name(): string {
        let labelText = null;
        if (!this.property) return labelText;
        labelText = this.property.getLabel();
        return labelText;
    }

    set name(name:string){
        this.property.name = name;
        this.property.label = name;
    }

    get countLabel(): string {
        let propertyCountValue = "";
        let matchingWireframeElements = this.getWireframeElementsByProperty(this.property);
        // only show if greater than 1
        if (!matchingWireframeElements || matchingWireframeElements.length <= 1) return propertyCountValue;
        propertyCountValue = "(" + matchingWireframeElements.length + ")";
        return propertyCountValue;
    }

    get isReadOnly(): boolean {
        let readOnly = true;
        if (!this.property) return readOnly;
        readOnly = this.property.isReadonly;
        return readOnly;
    }

    get isEditable(): boolean {
        let editable = false;
        if (!this.property) return editable;
        editable = this.property.isEditable;
        return editable;
    }

    get childProperties(): Property[] {
        let childProperties: Property[] = [];
        if (!this.property) return childProperties;
        if (this.property instanceof CompoundProperty) {
            let compoundProperty = this.property as CompoundProperty;
            compoundProperty.childProperties.forEach((childProperty) => {
                childProperties.push(childProperty);
            })
        }
        return childProperties;
    }

    deleteProperty() {
        if (!this.property) return;
        if (!this.wireframeElements || this.wireframeElements.size() == 0) return;
        this.propertyManager.deleteProperty(this.property, this.wireframeElements);
    }

    getWireframeElementsByProperty(targetProperty): WireframeElement[] {
        let matchingWireframeElements: WireframeElement[] = [];
        if (!this.wireframeElements) return matchingWireframeElements;
        this.wireframeElements.forEach((wireframeElement) => {
            wireframeElement.pvObject.properties.forEach((propertyValue: PropertyValue) => {
                let property = this.propertyManager.getProperty(propertyValue.id);
                if (targetProperty.id == property.id) {
                    matchingWireframeElements.push(wireframeElement)
                } else if (property instanceof CompoundProperty) {
                    let compoundProperty = property as CompoundProperty;
                    compoundProperty.childProperties.forEach((childProperty) =>{
                        if(targetProperty.name === childProperty.name){
                            matchingWireframeElements.push(wireframeElement);
                        }
                    })

                }
            });
        });
        return matchingWireframeElements;
    }

    showPropertyInput() {
        this.clearPropertyInput();

        if (this.property instanceof ElementReferenceProperty) {
            // element references
            let elementReferencePropertyEditorComponentFactory = this.componentFactoryResolver.resolveComponentFactory(ElementReferencePropertyEditorComponent);
            let elementReferencePropertyEditorComponent = this.propertyInputContainerRef.createComponent(elementReferencePropertyEditorComponentFactory);
            elementReferencePropertyEditorComponent.instance.wireframeElements = this.getWireframeElementsByProperty(this.property);
            elementReferencePropertyEditorComponent.instance.propertyManager = this.propertyManager;
            elementReferencePropertyEditorComponent.instance.property = this.property as ElementReferenceProperty;
        } else if (this.property instanceof DictionaryProperty) {
            // dictionaries
            let dictionaryPropertyEditorComponentFactory = this.componentFactoryResolver.resolveComponentFactory(DictionaryPropertyEditorComponent);
            let dictionaryPropertyEditorComponent = this.propertyInputContainerRef.createComponent(dictionaryPropertyEditorComponentFactory);
            dictionaryPropertyEditorComponent.instance.wireframeElements = this.getWireframeElementsByProperty(this.property);
            dictionaryPropertyEditorComponent.instance.propertyManager = this.propertyManager;
            dictionaryPropertyEditorComponent.instance.property = this.property as DictionaryProperty;
        } else if (this.property instanceof EnumProperty) {
            // enums
            let enumPropertyEditorComponentFactory = this.componentFactoryResolver.resolveComponentFactory(EnumPropertyEditorComponent);
            let enumPropertyEditorComponent = this.propertyInputContainerRef.createComponent(enumPropertyEditorComponentFactory);
            enumPropertyEditorComponent.instance.wireframeElements = this.getWireframeElementsByProperty(this.property);
            enumPropertyEditorComponent.instance.propertyManager = this.propertyManager;
            enumPropertyEditorComponent.instance.property = this.property as EnumProperty;
        } else if (this.property instanceof TextProperty) {
            // text
            let textPropertyEditorComponentFactory = this.componentFactoryResolver.resolveComponentFactory(TextPropertyEditorComponent);
            let textPropertyEditorComponent = this.propertyInputContainerRef.createComponent(textPropertyEditorComponentFactory);
            textPropertyEditorComponent.instance.wireframeElements = this.getWireframeElementsByProperty(this.property);
            textPropertyEditorComponent.instance.propertyManager = this.propertyManager;
            textPropertyEditorComponent.instance.property = this.property as TextProperty;
        } else if (this.property instanceof NumberProperty) {
            // number
            let numberPropertyEditorComponentFactory = this.componentFactoryResolver.resolveComponentFactory(NumberPropertyEditorComponent);
            let numberPropertyEditorComponent = this.propertyInputContainerRef.createComponent(numberPropertyEditorComponentFactory);
            numberPropertyEditorComponent.instance.wireframeElements = this.getWireframeElementsByProperty(this.property);
            numberPropertyEditorComponent.instance.propertyManager = this.propertyManager;
            numberPropertyEditorComponent.instance.property = this.property as NumberProperty;
        }
    }

    clearPropertyInput() {
        this.propertyInputContainerRef.clear();
    }

    ngAfterViewInit(): void {
        this.showPropertyInput();
    }

    onDeleteClick(event) {
        this.deleteProperty();
    }
}