import {WireframeApplication} from "./application/wireframeApplication";
import {GeometryUtilities} from "./geometryUtilities";
import {MeasurementUnit} from "./application/measurementUnit";
import {WireframeOperations} from "./wireframeOperations";
import {WireframeUtils} from "./wireframeUtils";


export class PlaneOperations {

    constructor(private app:WireframeApplication) {}

    calcPerimeterAndArea(vList, plane) {
        let vListPositions = [];
        for (let i = 0; i < vList.length; i++) {
            vListPositions.push(vList[i].position.clone());
        }
        //convert all projected point to plane coordinate system
        let startPoint = vListPositions[0];
        let middlePoint = vListPositions[Math.floor(vListPositions.length / 2)];
        let [R, C] = GeometryUtilities.CoordinateSystemOnPlane([plane.a, plane.b, plane.c, plane.d], startPoint, middlePoint);
        for (let i = 0; i < vListPositions.length; i++) {
            vListPositions[i] = GeometryUtilities.ConvertPointCoordinateSystem(vListPositions[i], R, C)
        }

        let area = GeometryUtilities.SimplePolygonArea(vListPositions);
        let perimeter = GeometryUtilities.SimplePolygonPerimeter(vListPositions);

        return [perimeter, area]
    }

    buildTable(data, unit: MeasurementUnit) {
        let table = document.createElement("table");
        table.className = "gridtable";
        let thead = document.createElement("thead");
        let tbody = document.createElement("tbody");
        let headRow = document.createElement("tr");
        ["planeId", "perimeter (" + MeasurementUnit[unit] + ")", "area (" + MeasurementUnit[unit] + "²)", "slope (°)"].forEach(function (el) {
            let th = document.createElement("th");
            th.appendChild(document.createTextNode(el));
            headRow.appendChild(th);
        });
        thead.appendChild(headRow);
        table.appendChild(thead);
        data.forEach(function (el) {
            let tr = document.createElement("tr");
            for (let o in el) {
                let td = document.createElement("td");
                td.appendChild(document.createTextNode(el[o]));
                tr.appendChild(td);
            }
            tbody.appendChild(tr);
        });
        table.appendChild(tbody);
        return table;
    }

    updateUnits(data, oldUnit: MeasurementUnit, newUnit: MeasurementUnit) {
        this.convertMeasurementsToNewUnit(data, oldUnit, newUnit);
        let table = this.buildTable(data, newUnit);
        return table;
    }

    convertMeasurementsToNewUnit(data, oldUnit: MeasurementUnit, newUnit: MeasurementUnit) {
        let newScaleFactor: number = WireframeUtils.getUnitConversionFactor(Number(oldUnit), Number(newUnit));

        for (let i = 0; i < data.length; i++) {
            data[i].perimeter *= newScaleFactor;
            data[i].area *= (newScaleFactor * newScaleFactor);
        }
    }

    planesInfo = new Object;

    //add a table report of planes to report page
    addPlaneTable() {
        let planeList = [];
        let keys = Object.keys(this.app.wireframe.planes);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];

            let plane = this.app.wireframe.planes[key];
            let obj = {"id": key, "perimeter": plane.perimeter, "area": plane.area};
            if (plane.pitch != null) {
                obj["slope"] = plane.pitch
            }
            else {
                obj["slope"] = "";
            }

            planeList.push(obj);
        }

        this.planesInfo = planeList;
        let table = this.buildTable(planeList, MeasurementUnit.M);
        let element = document.getElementById("planesTableElement");
        if (element.children.length > 0) {
            element.removeChild(element.children[0]);
        }
        element.appendChild(table);
    }
}