import {PV} from "../../wireframe";
import {WireframeElementType} from "../../application/wireframeElementType";
import {WireframeElement} from "../../application/wireframeElement";
import {UnitType} from "./unitType";
import {PropertyValue} from "./propertyValue";
import {DoubleProperty} from "./doubleProperty";
import {LayerProperty} from "./layerProperty";
import {ElementReference} from "./elementReference";
import {ElementReferenceProperty} from "./elementReferenceProperty";
import * as THREE from 'three';
import {CompoundProperty} from "./compoundProperty";
import {EnumProperty} from "./enumProperty";
import {EdgeElement} from "../../application/edgeElement";
import {PlaneElement} from "../../application/planeElement";
import GeometryCache from "../../application/geometryCache";

export class DistanceProperty extends CompoundProperty {
    targetElement: ElementReferenceProperty;
    originElement: ElementReferenceProperty;
    projectionProperty:EnumProperty;
    distanceProperty: DoubleProperty;

    constructor() {
        super();
        this.type = ".DistanceProperty";
        this.name = "Distance";

        this.originElement = new ElementReferenceProperty();
        this.originElement.name = "Origin";
        this.originElement.selectableElementTypes = [];
        this.originElement.isEditable = false;
        this.originElement.isReadonly = false;
        this.childProperties.push(this.originElement);

        this.targetElement = new ElementReferenceProperty();
        this.targetElement.name = "Target";
        this.targetElement.selectableElementTypes = [];
        this.targetElement.isEditable = false;
        this.targetElement.isReadonly = false;
        this.childProperties.push(this.targetElement);

        this.projectionProperty = new EnumProperty()
        this.projectionProperty.name = "Target Projection"
        this.projectionProperty.values = [
            {value: 0, label: "Point"},
            {value: 1, label: "Plane"},
            {value: 2, label: "Gravity"},
        ]
        this.childProperties.push(this.projectionProperty)

        this.distanceProperty = new DoubleProperty();
        this.distanceProperty.name = "Distance";
        this.distanceProperty.isEditable = false;
        this.distanceProperty.isReadonly = true;
        this.distanceProperty.unitType = UnitType.LINEAR;
        this.childProperties.push(this.distanceProperty);
    }

    fromJson(obj, allProperties: Function[]): void {
        super.fromJson(obj, allProperties);
        this.targetElement = this.childProperties[0] as ElementReferenceProperty;
        this.distanceProperty = this.childProperties[1] as DoubleProperty;
    }

    getDefaultValue(): {} {
        return new DimensionValue()
    }

    onLoad() {
        super.onLoad();
    }


    onAddToElement(e: WireframeElement) {
        super.onAddToElement(e);
        let edgeEl = e as EdgeElement
        let v1 = e.wireframeLayer.findWireframeElement(WireframeElementType.vertex, edgeEl.pvObject.vertex1Id)
        let v2 = e.wireframeLayer.findWireframeElement(WireframeElementType.vertex, edgeEl.pvObject.vertex2Id)
        if (!v1 || !v2) return
        console.log("distProp onAdd", v1)
        v1.baseGeometry[0].geometry = GeometryCache.getOrCache("measurementEndpoint", () => {
            return new THREE.CylinderBufferGeometry(1.5, 1.5, .1, 12, 1, false)
        })
        v1.highlightGeometry[0].geometry = v1.baseGeometry[0].geometry

        v2.baseGeometry[0].geometry = v1.baseGeometry[0].geometry
        v2.highlightGeometry[0].geometry = v1.baseGeometry[0].geometry

        let layerProp = e.wireframeLayer.wireframe.findProperty(LayerProperty, true)
        layerProp.setValue(v1, "measurement")
        layerProp.setValue(v2, "measurement")
    }

    apply(el: WireframeElement, pv: PropertyValue) {
        //console.log("updateDistnaceProp", el);
        let edge = el as EdgeElement
        edge.wireframeLayer.wireframe.findProperty(LayerProperty, true).setValue(el, "measurement")

        let dimensionValue: DimensionValue = pv.value;
        let originElement = ElementReferenceProperty.getElement(el.wireframeLayer, dimensionValue.Origin)
        if (null == originElement) {
            originElement = edge.wireframeLayer.findWireframeElement(WireframeElementType.vertex, edge.pvObject.vertex1Id)
        }
        if (!originElement) return
        let fromPos = originElement.getCenter(false);
        el.wireframeLayer.wireframe.setVert3(edge.pvObject.vertex1Id, fromPos)

        let targetElement = ElementReferenceProperty.getElement(el.wireframeLayer, dimensionValue.Target)
        if (null == targetElement) targetElement = edge.wireframeLayer.findWireframeElement(WireframeElementType.vertex, edge.pvObject.vertex2Id)
        if (!targetElement) return
        if (targetElement instanceof PlaneElement) {
            el.wireframeLayer.updatePlaneEquation(targetElement.pvObject as PV.Plane);
        }

        let toPos: THREE.Vector3 = targetElement.getCenter(false);

        let projection = Number(pv.value["Target Projection"])
        if (projection == 1) {
            toPos = targetElement.getPlane3(false).projectPoint(fromPos, new THREE.Vector3())
        } else if (projection == 2) {
            let plane = new THREE.Plane()
            plane.setFromNormalAndCoplanarPoint(el.wireframeLayer.getGravityVector(), targetElement.getCenter(false))
            toPos = plane.projectPoint(fromPos, new THREE.Vector3())
        }

        let distance: number = toPos.clone().sub(fromPos).length();
        pv.value["Distance"] = this.distanceProperty.formatValue(distance);
        el.wireframeLayer.wireframe.setVert3(edge.pvObject.vertex2Id, toPos)

        let v1 = edge.wireframeLayer.findWireframeElement(WireframeElementType.vertex, edge.pvObject.vertex1Id)
        let v2 = edge.wireframeLayer.findWireframeElement(WireframeElementType.vertex, edge.pvObject.vertex2Id)

        if (!v1 || !v2) return

        v1.setRotationFromQuaternion(edge.baseGeometry[0].quaternion)
        v2.setRotationFromQuaternion(edge.baseGeometry[0].quaternion)
        v1.updateGeometry()
        v2.updateGeometry()
        el.updateGeometry()
    }
}

export class DimensionValue {
    Distance: number;
    Origin: ElementReference;
    Target: ElementReference;
    Edge: ElementReference;
}
