import tpl from './settings.html';
import PVUtils from '../../pvUtils'
import {WireframeApplication} from "../../application/wireframeApplication";
import {WireframeOperations} from "../../wireframeOperations";
import angular = require('angular');
import {CameraType} from "../../application/cameraType";
import {MeasurementUnit} from "../../application/measurementUnit";
import {ElevationReferenceType} from "../../application/elevationReferenceType";
import * as THREE from 'three';
import {WireframeUtils} from "../../wireframeUtils";
import {SceneService} from "../../services/scenes/scene.service";

export default {
    bindings: {},
    template: tpl,
    controller: ['$rootScope', '$scope', 'sceneService', function ($rootScope, $scope, sceneService:SceneService) {
        let app = sceneService.wireframeApplication
        let vertexBboxKey = "vertexBoundingBoxEnabled";
        let smartZoomFeatureEnableKey = "adjustMode.autoScale";
        let eraserRaidusFeatureKey = "eraserRadius";
        let ctrl = this;

        this.showUnits = false;
        this.unitTypes = app.unitTypes;
        this.selectedUnit = app.unitTypes[0];
        this.tableUnit = app.unitTypes[0];
        this.scaleFactor = 1.0;

        this.init = function () {
            if (window.localStorage.getItem(vertexBboxKey) === null) {
                window.localStorage.setItem(vertexBboxKey, true.toString());
            }
            if (window.localStorage.getItem(smartZoomFeatureEnableKey) === null) {
                window.localStorage.setItem(smartZoomFeatureEnableKey, true.toString());
            }
            this.vertexbbox = window.localStorage.getItem(vertexBboxKey) == "true";
        };

        this.vertexbbox;

        // this.toggleVertexBbox = function() {
        //     window.localStorage.setItem(vertexBboxKey, this.vertexbbox);
        //     console.log(this.vertexbbox);
        //     wfOps.redrawWireframe();
        // }

        this.unitScaleFactor = "1.00";
        this.updateOneUnit = function () {
            let scaleNumber = Number(this.unitScaleFactor);
            app.selectedUnit = MeasurementUnit[this.selectedUnit + ""];
            app.selectedUnit = this.selectedUnit;
            app.currentDisplayUnit = app.selectedUnit;
            console.log("Updated Value = " + this.unitScaleFactor);
            if (scaleNumber !== 0) {
                app.displayMeasurementScale = scaleNumber;
            }
            app.wireframeLayer.redrawWireframe();
        };

        this.populateAutoScaleUnits = function (newValue, oldValue) {
            let oldFactor = Number(this.unitScaleFactor);
            let conversionFactor = WireframeUtils.getUnitConversionFactor(Number(MeasurementUnit[oldValue]), Number(MeasurementUnit[newValue]));
            this.unitScaleFactor = oldFactor * conversionFactor;
            //console.log("Old Value = " + oldFactor + ", New Value = " + this.unitScaleFactor + ", Changed from " + oldValue + " to  " + newValue + " (Scale Factor = " + conversionFactor + ")");
        };

        this.showVertex = 1;
        this.toggleShowVertex = function () {
            app.showVertex = this.showVertex;
            app.wireframeLayer.redrawWireframe();
        };

        this.planeAngleMode = 1;
        this.togglePlaneAngleMode = function () {
            app.degreeMode = this.planeAngleMode;
            app.wireframeLayer.redrawWireframe();
        };

        this.vertexSize = function (val) {
            if (angular.isDefined(val)) {
                console.log("setting vertex size to " + parseInt(100 * val + ""));
                app.vertexSize = Number(val);
                app.wireframeLayer.redrawWireframe();
            } else {
                return app.vertexSize;
            }
        };

        this.edgeSize = function (val) {
            if (angular.isDefined(val)) {
                console.log("setting edge size to " + parseInt(100 * val + ""));
                app.edgeSize = Number(val);
                app.wireframeLayer.redrawWireframe();
            } else {
                return app.edgeSize;
            }
        };

        this.edgeLabelSize = function (val) {
            if (angular.isDefined(val)) {
                console.log("setting edge label size to " + parseInt(100 * val + ""));
                app.edgeLabelSize = Number(val);
                app.wireframeLayer.redrawWireframe();
            } else {
                return app.edgeLabelSize;
            }
        };

        this.eraserRadius = function (val) {
            if (angular.isDefined(val)) {
                console.log("setting eraser radius to " + val);
                app.eraserRadius = Number(val);
                app.wireframeLayer.redrawWireframe();
            } else {
                return app.eraserRadius;
            }
        };

        this.pointCloudPointSize = function (val) {
            if (angular.isDefined(val)) {
                val = parseFloat(val)
                app.sceneManager.setPointSize(val);
                app.wireframeLayer.redrawWireframe();
            } else {
                return app.viewer ? app.sceneManager.getPointSize() : 1;
            }
        };

        this.getImagesLoadedPercentage = function () {
            let totalNumImages = Object.keys(app.cameraDict).length;
            let numImagesLoaded = 0;
            let imageKeys = Object.keys(app.resourceManager.imageData);
            let pattern = /^[0-9]*\/metadata\/rendered\/Frame[0-9]*\..*/;
            imageKeys.forEach(function (key) {
                if (pattern.test(key)) numImagesLoaded++;
                //console.log("image key " + key + " test " + pattern.test(key));
            });
            if (totalNumImages > 0) return PVUtils.formatPercent(numImagesLoaded / totalNumImages, 0);
            return 0;
        };

        this.preloadCameraImages = function () {
            //app.canvasOperations.downloadAllFrames();
        };

        this.preloadOptimalCameraImages = function () {
            //app.canvasOperations.downloadOptimalFrames();
        };

        this.notifyOnErrors = function (val) {
            if (angular.isDefined(val)) {
                localStorage.setItem("notifyOnErrors", (val == true).toString());
            } else {
                let n = localStorage.getItem("notifyOnErrors");
                return (null == n) || (n === "true");
            }
        };

        this.smartZoomFeatureEnable = function (val) {
            if (angular.isDefined(val)) {
                localStorage.setItem(smartZoomFeatureEnableKey, (val == true).toString());
            } else {
                let n = localStorage.getItem(smartZoomFeatureEnableKey);
                return (null == n) || (n === "true");
            }
        };

        this.cameraType = function(val) {
            if (angular.isDefined(val)) {
                app.viewer.setCameraType(val)
            } else {
                if (sceneService.potreeCamera instanceof THREE.PerspectiveCamera) {
                    return CameraType.PERSPECTIVE + ""
                } else {
                    return CameraType.ORTHO + ""
                }
            }
        }

        this.elevationReference = function (val) {
            if (angular.isDefined(val)) {
                val = Number(val)
                let updates = { "metadata/reconstructed/potreeCloudMetadata/elevationReference": val }
                app.projectRef.update(updates)
            } else {
                let elevationReference = ElevationReferenceType.AUTO
                try {
                    elevationReference = app.projectData.metadata.reconstructed.potreeCloudMetadata.elevationReference
                } catch (e) {}
                return "" + (elevationReference || 0);
            }
        };

        this.elevationCorrection = function (val) {
            if (angular.isDefined(val)) {
                val = Number(val)
                let updates = { "metadata/reconstructed/potreeCloudMetadata/elevationCorrection": val }
                app.projectRef.update(updates)
            } else {
                let elevationCorrection = 0
                try {
                    elevationCorrection = Number(app.projectData.metadata.reconstructed.potreeCloudMetadata.elevationCorrection)
                } catch (e) {}
                return elevationCorrection || 0;
            }
        };

        this.antiAlias = function (val) {
            if (angular.isDefined(val)) {
                localStorage.setItem("enableAntiAlias", (val == true).toString());
            } else {
                let n = localStorage.getItem("enableAntiAlias");
                return (null == n) || (n === "true");
            }
        };
    }]
}
