import {Layer} from "./layer";
import {WireframeApplication} from "./wireframeApplication";

export class LabelLayer extends Layer {

    nameLayer:Layer
    dimensionLayer:Layer
    annotationLayer:Layer
    constructor(app:WireframeApplication, name:string, label:string) {
        super(app, name, label)
        this.supportsInteraction = false
        this.supportsEditing = false

        this.nameLayer = new Layer(this.app, "name", "Name")
        this.dimensionLayer = new Layer(this.app, "dimension", "Dimensions")
        this.annotationLayer = new Layer(this.app, "annotation", "Annotations")

        this.addChildLayer(this.nameLayer)
        this.addChildLayer(this.dimensionLayer)
        this.addChildLayer(this.annotationLayer)
    }
}