import {AnnotationModel} from "../annotations/annotation.model";
import {InteractiveModel} from "../interactive/interactive.model";
import {EventEmitter} from "@angular/core";
import {CameraView} from "../cameras/cameraView";
import {CameraPointView} from "../cameras/cameraPointView";

export interface ProjectImage {
    url: string;
    mediaUrl: string;
    thumbnailUrl: string;
    metadata: any;
    annotations: Array<any>;
    classes: Array<string>;
    tags: Array<string>;
}

export class ProjectImageModel extends InteractiveModel implements ProjectImage {
    metadata: any;
    annotations: Array<AnnotationModel>;
    private _classes: Array<string>;
    tags: Array<string> = [];
    url: string;

    mediaRequestedEvent = new EventEmitter<ProjectImageModel>();
    private _mediaPercentLoaded: number = 0;
    mediaLoadedEvent = new EventEmitter<ProjectImageModel>();
    mediaUrl: string;

    thumbnailRequestedEvent = new EventEmitter<ProjectImageModel>();
    private _thumbnailPercentLoaded: number = 0;
    thumbnailLoadedEvent = new EventEmitter<ProjectImageModel>();
    thumbnailUrl: string;

    printRequestedEvent = new EventEmitter<ProjectImageModel>();
    private _printPercentLoaded: number = 0;
    printLoadedEvent = new EventEmitter<ProjectImageModel>();
    printUrl: string;

    cameraFocusRequestedEvent = new EventEmitter<ProjectImageModel>();
    private _cameraView: CameraView = null;
    private _cameraPointView: CameraPointView = null;

    tiledImageHref: string;
    tiledImageMetadata:any;

    constructor(mediaUrl: string = null, thumbnailUrl: string = null){
        super();
        this.mediaUrl = mediaUrl;
        this.thumbnailUrl = thumbnailUrl;
    }

    // file properties
    get filename(): number {
        if(!this.metadata) return null;
        return this.metadata.filename;
    }

    // date properties
    get createdDate():string {
        if(!this.metadata) return null;
        if(!this.metadata.exif) return null;
        return this.metadata.exif.DateTimeOriginal;
    }

    // image properties
    get width(): number {
        if(!this.metadata) return null;
        return this.metadata.width;
    }

    get height(): number {
        if(!this.metadata) return null;
        return this.metadata.height;
    }

    // frame properties
    get frameId(): number {
        if(!this.metadata) return null;
        return this.metadata.frameId;
    }

    get frameKey(): string {
        let frameId = this.frameId;
        return 'frame-' + frameId;
    }

    // classes
    get classes():Array<string>{
        // convert blank classes
        let cleanClasses = [];
        if(this._classes){
            this._classes.forEach((annotationClass) => {
                if(!annotationClass) annotationClass = "general";
                cleanClasses.push(annotationClass);
            })
        }
        return cleanClasses;
    }

    set classes(classes:Array<string>){
        let cleanClasses = [];
        if(classes){
            classes.forEach((annotationClass) => {
                if(!annotationClass) annotationClass = "general";
                cleanClasses.push(annotationClass);
            })
        }
        this._classes = cleanClasses;
    }

    // location properties
    private convertDMSToDD(degrees, minutes, seconds, direction): number {
        let numberDegrees = Math.abs(Number(degrees));
        let numberMinutes = Math.abs(Number(minutes));
        let numberSeconds = Math.abs(Number(seconds));

        let dd = numberDegrees + numberMinutes / 60 + numberSeconds / (60 * 60);

        if (direction == "S" || direction == "W"
        ) {
            dd = dd * -1;
        } // don't do anything for N or E
        return dd;
    }

    private _gpsLatitudeMetadataValue: string;
    private _gpsLatitudeDD: number;
    get gpsLatitude(): number {
        if (!this.metadata) return null;
        if (!this.metadata.exif) return null;
        let latitudeValue = this.metadata.exif.GPSLatitude;
        if(!latitudeValue) latitudeValue = this.metadata.exif['GPS Latitude'];
        if(!latitudeValue) latitudeValue = this.metadata.exif['gps latitude'];
        if (!latitudeValue) return null;

        if(this._gpsLatitudeMetadataValue == latitudeValue){
            return this._gpsLatitudeDD;
        }
        this._gpsLatitudeMetadataValue = latitudeValue;

        let gpsLatitudeRef = this.metadata.exif.GPSLatitudeRef;
        if(!gpsLatitudeRef) gpsLatitudeRef = this.metadata.exif['GPS Latitude Ref'];
        if(!gpsLatitudeRef) gpsLatitudeRef = this.metadata.exif['gps latitude ref'];

        let parts = latitudeValue.split(/[^-?\d\w]+/);

        if(parts.length == 6){
            if(parts[5]){
                gpsLatitudeRef = parts[5];
            }
            latitudeValue = this.convertDMSToDD(parts[0], parts[2], parts[3] + "." + parts[4], gpsLatitudeRef);
        } else if(parts.length == 5){
            if(parts[4]){
                gpsLatitudeRef = parts[4];
            }

            latitudeValue = this.convertDMSToDD(parts[0], parts[1], parts[2] + "." + parts[3], gpsLatitudeRef);
        } else if(parts.length == 4){
            if(parts[3]){
                gpsLatitudeRef = parts[3];
            }

            latitudeValue = this.convertDMSToDD(parts[0], parts[1], parts[2] + "." + 0, gpsLatitudeRef);
        } else {
            console.log('unknown gps latitude format', latitudeValue);
            latitudeValue = null;
        }

        this._gpsLatitudeDD = latitudeValue;
        return latitudeValue;
    }

    private _gpsLongitudeMetadataValue: string;
    private _gpsLongitudeDD: number;
    get gpsLongitude(): number {
        if (!this.metadata) return null;
        if (!this.metadata.exif) return null;
        let longitudeValue = this.metadata.exif.GPSLongitude;
        if(!longitudeValue) longitudeValue = this.metadata.exif['GPS Longitude'];
        if(!longitudeValue) longitudeValue = this.metadata.exif['gps longitude'];
        if (!longitudeValue) return null;

        if(this._gpsLongitudeMetadataValue == longitudeValue){
            return this._gpsLongitudeDD;
        }
        this._gpsLongitudeMetadataValue = longitudeValue;

        let gpsLongitudeRef = this.metadata.exif.GPSLongitudeRef;
        if(!gpsLongitudeRef) gpsLongitudeRef = this.metadata.exif['GPS Longitude Ref'];
        if(!gpsLongitudeRef) gpsLongitudeRef = this.metadata.exif['gps longitude ref'];

        let parts = longitudeValue.split(/[^-?\d\w]+/);

        if(parts.length == 6) {
            if(parts[5]){
                gpsLongitudeRef = parts[5];
            }
            longitudeValue = this.convertDMSToDD(parts[0], parts[2], parts[3] + "." + parts[4], gpsLongitudeRef);
        } else if(parts.length == 5){
            if(parts[4]){
                gpsLongitudeRef = parts[4];
            }
            longitudeValue = this.convertDMSToDD(parts[0], parts[1], parts[2] + "." + parts[3], gpsLongitudeRef);
        } else if(parts.length == 4){
            if(parts[3]){
                gpsLongitudeRef = parts[3];
            }
            longitudeValue = this.convertDMSToDD(parts[0], parts[1], parts[2] + "." + 0, gpsLongitudeRef);
        } else {
            console.log('unknown gps longitude format', longitudeValue);
            longitudeValue = null;
        }

        this._gpsLongitudeDD = longitudeValue;
        return longitudeValue;
    }

    // orientation properties
    // camera pitch from absolute horizon
    get gimbalPitchDegree():number {
        if(!this.metadata) return null;
        if(!this.metadata.exif) return null;
        // 0.0 = Horizon/Level; -90.0 = Down; +90.0 = Up;
        let gimbalPitchValue = this.metadata.exif.GimbalPitchDegree;
        if(!gimbalPitchValue) return null;
        return gimbalPitchValue;
    }

    // camera heading relative to true north
    // 0.0 = North; -90.0 = West; +90.0 = East; +/-180.0 = South;
    get gimbalYawDegree():number {
        if(!this.metadata) return null;
        if(!this.metadata.exif) return null;
        let gimbalYawValue = this.metadata.exif.GimbalYawDegree;
        if(!gimbalYawValue) return null;
        return gimbalYawValue;
    }

    // annotation properties
    get annotationCount(): number {
        let annotationCount = 0;
        if(!this.annotations) return annotationCount;
        annotationCount = this.annotations.length;
        return annotationCount;
    }

    get hasAnnotations(): boolean{
        return this.annotationCount > 0;
    }

    _classesUsedInAnnotation:string[] = [];
    get classesUsedInAnnotation(): string[] {
        if (!this.hasAnnotations) return this._classesUsedInAnnotation;

        // get annotation classes
        let classesUsedInAnnotation: string[] = [];
        this.annotations.forEach((annotation) => {
            let classes = annotation.classes;
            if (!classes) return;
            classes.forEach((annotationClass) => {
                if (classesUsedInAnnotation.find((element) => {
                    return annotationClass == element;
                })) return;
                classesUsedInAnnotation.push(annotationClass);
            })
        });

        // remove
        let classesToRemove = [];
        this._classesUsedInAnnotation.forEach((annotationClass) =>{
            if(classesUsedInAnnotation.indexOf(annotationClass) < 0){
                classesToRemove.push(annotationClass)
            }
        });

        classesToRemove.forEach((annotationClass) => {
            let index = this._classesUsedInAnnotation.indexOf(annotationClass);
            if (index > -1) {
                this._classesUsedInAnnotation.splice(index, 1);
            }
        });

        // add
        let classesToAdd = [];
        classesUsedInAnnotation.forEach((annotationClass) =>{
            if(this._classesUsedInAnnotation.indexOf(annotationClass) < 0){
                classesToAdd.push(annotationClass)
            }
        });

        classesToAdd.forEach((annotationClass) => {
            let index = this._classesUsedInAnnotation.indexOf(annotationClass);
            if (index < 0) {
                this._classesUsedInAnnotation.push(annotationClass);
            }
        });

        return this._classesUsedInAnnotation;
    }

    // cameras
    get hasCamera():boolean{
        return this.cameraView !== null;
    }

    get cameraFocusRequested(): EventEmitter<ProjectImageModel> {
        return this.cameraFocusRequestedEvent
    }

    public requestCameraFocus(){
        this.cameraFocusRequestedEvent.emit(this);
    }
    
    get cameraView():CameraView{
        return this._cameraView;
    }
    
    set cameraView(cameraView:CameraView){
        this._cameraView = cameraView;
    }

    get hasCameraPoint():boolean{
        return this.cameraPointView !== null;
    }

    get cameraPointView():CameraPointView{
        return this._cameraPointView;
    }

    set cameraPointView(cameraPointView:CameraPointView){
        this._cameraPointView = cameraPointView;
    }

    // loading properties
    requestMedia(){
        this.mediaRequestedEvent.emit(this);
    }

    get mediaLoading(): boolean {
        return this.mediaPercentLoaded < 100;
    }

    get mediaLoaded(): boolean {
        return this.mediaPercentLoaded === 100;
    }

    get mediaPercentLoaded(): number {
        return this._mediaPercentLoaded;
    }

    set mediaPercentLoaded(percent){
        let isLoading = this.mediaLoading;
        this._mediaPercentLoaded = percent;
        let isLoaded = this.mediaLoaded;
        if(isLoading && isLoaded){
            this.mediaLoadedEvent.emit(this);
        }
    }

    requestPrint(){
        this.printRequestedEvent.emit(this);
    }

    get printLoading(): boolean {
        return this.printPercentLoaded < 100;
    }

    get printLoaded(): boolean {
        return this.printPercentLoaded === 100;
    }

    get printPercentLoaded(): number {
        return this._printPercentLoaded;
    }

    set printPercentLoaded(percent){
        let isLoading = this.printLoading;
        this._printPercentLoaded = percent;
        let isLoaded = this.printLoaded;
        if(isLoading && isLoaded){
            this.printLoadedEvent.emit(this);
        }
    }

    requestThumbnail(){
        this.thumbnailRequestedEvent.emit(this);
    }

    get thumbnailLoading(): boolean {
        return this.thumbnailPercentLoaded < 100;
    }

    get thumbnailLoaded(): boolean {
        return this.thumbnailPercentLoaded === 100;
    }

    get thumbnailPercentLoaded(): number {
        return this._thumbnailPercentLoaded;
    }

    set thumbnailPercentLoaded(percent){
        let isLoading = this.thumbnailLoading;
        this._thumbnailPercentLoaded = percent;
        let isLoaded = this.thumbnailLoaded;
        if(isLoading && isLoaded){
            this.thumbnailLoadedEvent.emit(this);
        }
    }
}