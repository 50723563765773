// leaflet
import * as L from 'leaflet'
import 'leaflet-draw';
// minpack
import {MINPACK} from "./../../lib/minpack/minpack";
import {ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {Vector3, Plane} from "three";
import {CameraView} from "../../models/cameras/cameraView";
import {PV} from "../../wireframe";
import {EdgeTypeProperty} from "../../models/property/edgeTypeProperty";
import {EdgeType} from "../../models/property/edgeType";
import {ProjectImageModel} from "../../models/projectImages/projectImage.model";
import {VertexElementModel} from "../../models/vertexElements/vertexElement.model";
import {SceneService} from "../../services/scenes/scene.service";
import {WireframeElementType} from "../../application/wireframeElementType";
import {LatLng} from "leaflet";

if (!(window as any).L) (window as any).L = L;

const Projection = MINPACK.Projection;

@Component({
    selector: 'ptv-vertex-thumbnail-item',
    styles: [require('./vertexThumbnailItem.component.scss')],
    template: require('./vertexThumbnailItem.component.html')
})
export default class VertexThumbnailItemComponent implements OnInit, OnDestroy {
    @Input() projectImageModel: ProjectImageModel;
    @Input() vertexElementModel: VertexElementModel;

    private _zoom: number = 0;
    @Input() set zoom(zoom) {
        this._zoom = zoom;
        this.updateZoom();
    }

    get zoom(): number {
        return this._zoom;
    }

    @ViewChild('thumbnail', {read: ElementRef, static: false}) private thumbnailElementRef: ElementRef;
    @ViewChild('thumbnailOutlet', {static: true}) thumbnailOutlet: ElementRef;

    private vertexMarker: L.Circle;
    private projectedPoint;
    private vertexElementModelUpdatedSubscription;

    private vertexUpdateCheckCurrentX;
    private vertexUpdateCheckCurrentY;
    private vertexUpdateCheckCurrentZ;

    private printLoadedSubscription;
    private isMouseOver = false;
    private enableTiledImages = true;
    private verticalOffset = 0;

    private L: any;
    private bounds: Number[][];
    private map: any;
    private imageOverlay: any;
    private tileLayer: any;
    private wireframeFeatures: any;

    private minZoom = -5;
    private maxZoom = 5;
    private isMarkerDragging = false;
    isMapActive = false;

    constructor(
        private sceneService: SceneService,
        private zone: NgZone,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.L = (window as any).L;
    }

    ngOnInit(): void {
        if (this.enableTiledImages && this.projectImageModel.tiledImageHref) {

            // calculate the vertical offset caused by the extra space on the last tile
            let numberOfTilesHigh = Math.ceil(this.projectImageModel.height / this.projectImageModel.tiledImageMetadata.tileHeight);
            let tiledImageHeight = numberOfTilesHigh * this.projectImageModel.tiledImageMetadata.tileHeight;
            this.verticalOffset = this.projectImageModel.tiledImageMetadata.tileHeight - (tiledImageHeight - this.projectImageModel.height);

            this.createMap();
        } else if (this.projectImageModel.printLoaded) {
            this.createMap();
        } else {
            this.printLoadedSubscription = this.projectImageModel.printLoadedEvent.subscribe(this.onPrintLoaded.bind(this));
        }

        this.vertexElementModelUpdatedSubscription = this.vertexElementModel.updated.subscribe(this.onVertexElementModelUpdated.bind(this));
        this.saveVertexState();
    }

    ngOnDestroy(): void {
        if (this.printLoadedSubscription) this.printLoadedSubscription.unsubscribe();
        if (this.vertexElementModelUpdatedSubscription) this.vertexElementModelUpdatedSubscription.unsubscribe();
        this.removeMap();
    }

    private didVertexStateChange() {
        return !(this.vertexUpdateCheckCurrentX == this.vertexElementModel.vertexElement.pvObject.x
            && this.vertexUpdateCheckCurrentY == this.vertexElementModel.vertexElement.pvObject.y
            && this.vertexUpdateCheckCurrentZ == this.vertexElementModel.vertexElement.pvObject.z)
    }

    private saveVertexState() {
        this.vertexUpdateCheckCurrentX = this.vertexElementModel.vertexElement.pvObject.x;
        this.vertexUpdateCheckCurrentY = this.vertexElementModel.vertexElement.pvObject.y;
        this.vertexUpdateCheckCurrentZ = this.vertexElementModel.vertexElement.pvObject.z;
    }

    private updateOnVertexStateChange() {
        if (this.didVertexStateChange()) {
            this.vertexUpdateCheckCurrentX = this.vertexElementModel.vertexElement.pvObject.x;
            this.vertexUpdateCheckCurrentY = this.vertexElementModel.vertexElement.pvObject.y;
            this.vertexUpdateCheckCurrentZ = this.vertexElementModel.vertexElement.pvObject.z;
            this.updateWireframeFeatures();

            if (this.map) {
                this.map.panTo(this.vertexMarker.getLatLng());
            }
        }
    }

    onVertexElementModelUpdated() {
        this.updateOnVertexStateChange();
    }

    onPrintLoaded() {
        this.createMap();
    }

    updateVertexProjectionPoint() {
        let cameraObject = this.projectImageModel.cameraPointView.cameraView;
        let vertexPvObject = this.vertexElementModel.vertexElement.pvObject as any;
        let point3d = [vertexPvObject.x, vertexPvObject.y, vertexPvObject.z];
        this.projectedPoint = Projection.Project_Point_With_Distortion(point3d, cameraObject.rotation, cameraObject.translation, cameraObject.skew, cameraObject.fx, cameraObject.fy, cameraObject.cx, cameraObject.cy, cameraObject.distortion, cameraObject.projectionType);
    }

    createMap() {
        let self = this;

        // Create the map outside of angular so the various map events don't trigger change detection
        self.zone.runOutsideAngular(() => {

            // bounds
            let imageWidth = self.projectImageModel.width;
            let imageHeight = self.projectImageModel.height;
            self.bounds = [[0, 0], [imageHeight, imageWidth]];

            // map
            self.map = self.L.map(self.thumbnailOutlet.nativeElement, {
                crs: self.L.CRS.Simple,
                attributionControl: false,
                bounds: self.bounds,
                maxNativeZoom: 0,
                minNativeZoom: 0,
                minZoom: self.minZoom,
                maxZoom: self.maxZoom,
                zoomSnap: 0.1,
                zoomControl: false,
                scrollWheelZoom: false
            });
            self.map.on('focus', () => {
                this.isMapActive = true;
                this.map.scrollWheelZoom.enable();
            });
            self.map.on('blur', () => {
                this.isMapActive = false;
                this.map.scrollWheelZoom.disable();
                this.disableAllLayerEditing();
                this.disableMarkerDrag();
            });

            // image
            if (self.enableTiledImages && self.projectImageModel.tiledImageHref) {

                let tiledImageHref = self.projectImageModel.tiledImageHref;
                let tiledImageMetadata = self.projectImageModel.tiledImageMetadata;

                // create an ortho tile layer
                let PointivoVertexTileLayer = self.L.TileLayer.extend({
                    getTileUrl: function (coords) {

                        // original coord.y is negative (top down)
                        let numberOfTilesHigh = Math.ceil(imageHeight / tiledImageMetadata.tileHeight);
                        let tileImageCoordsY = numberOfTilesHigh + coords.y - 1;
                        let tileImageCoordsX = coords.x;

                        coords.x = tileImageCoordsX;
                        coords.y = tileImageCoordsY;

                        let url = self.L.TileLayer.prototype.getTileUrl.call(this, coords);
                        let authorizedTileUrl = tiledImageMetadata.getAuthorizedUrl(url);
                        return authorizedTileUrl;
                    }
                });

                let pointivoVertexTileLayer = function (templateUrl, options) {
                    return new PointivoVertexTileLayer(templateUrl, options);
                };

                let url = tiledImageHref + "/" + tiledImageMetadata.pattern.toLowerCase();
                self.tileLayer = pointivoVertexTileLayer(url, {
                    crs: self.L.CRS.Simple,
                    tms: true,
                    tileSize: tiledImageMetadata.tileWidth,
                    bounds: self.bounds,
                    maxNativeZoom: 0,
                    minNativeZoom: 0,
                    minZoom: self.minZoom,
                    maxZoom: self.maxZoom,
                    zoomSnap: 0.1,
                    zoomControl: false
                }).addTo(self.map);

                self.tileLayer.on('click', (e) => {
                    // when you click on the background image, disable all open editors
                    self.disableAllLayerEditing();
                });
            } else {

                self.imageOverlay = self.L.imageOverlay(self.projectImageModel.printUrl, self.bounds, {
                    interactive: true
                }).addTo(self.map);

                self.imageOverlay.on('click', (e) => {
                    // when you click on the background image, disable all open editors
                    self.disableAllLayerEditing();
                });
            }

            // wireframe features
            self.wireframeFeatures = new self.L.FeatureGroup();
            self.map.addLayer(self.wireframeFeatures);

            // add draw controls
            let drawControl = new self.L.Control.Draw({
                draw: false, // disable draw toolbar
                edit: {
                    featureGroup: self.wireframeFeatures,
                    remove: false,
                    edit: false
                }
            });
            self.map.addControl(drawControl);

            // update wireframe features
            self.updateWireframeFeatures();

            // set initial zoom
            self.updateZoom();

            // pan to the vertex
            self.map.panTo(self.vertexMarker.getLatLng());
        });
    }

    updateZoom() {
        let self = this;
        if (!self.map) return;

        // optimal camera zoom scale
        let optimalScale = self.getOptimumCameraScale(self.projectImageModel.cameraPointView.cameraView, self.vertexElementModel.vertexElement.pvObject);

        let percentageScale = optimalScale
            ? optimalScale / 100
            : .25; // some reasonable default

        let percentageWidth = self.projectImageModel.width * (percentageScale * ((100 - self.zoom) / 100));
        let halfWidth = self.projectImageModel.width / 2;
        let optimalZoomLevel = 0;
        while (halfWidth > percentageWidth) {
            optimalZoomLevel = optimalZoomLevel + 1;
            halfWidth = halfWidth / 2;
        }

        let calculatedZoom = optimalZoomLevel + self.minZoom;
        self.map.setZoom(calculatedZoom);
    }

    getOptimumCameraScale(cam: CameraView, vert: PV.Vertex): number {
        let vertPos = new Vector3(vert.x, vert.y, vert.z);
        let ssd = cam.computeSSD(vertPos);
        let distanceFromCamera = cam.computeDistance(vertPos);
        let edgeTypeProp = this.vertexElementModel.wireframe.findProperty(EdgeTypeProperty, false);
        let scale: number = 25;
        if (edgeTypeProp) {
            let edgeTypeCounts: Map<EdgeType, number> = new Map();
            let edgeTypeCoef: Map<EdgeType, number> = new Map();

            for (let et in EdgeType) {
                if (!isNaN(parseInt(et))) {
                    edgeTypeCounts[et] = 0;
                    edgeTypeCoef[et] = 0;
                }
            }
            edgeTypeCoef.set(EdgeType.EAVE, 4.93764);
            edgeTypeCoef.set(EdgeType.FLASHING, 0);
            edgeTypeCoef.set(EdgeType.HIP, -0.47703);
            edgeTypeCoef.set(EdgeType.PARAPET, 0);
            edgeTypeCoef.set(EdgeType.RAKE, 3.53440);
            edgeTypeCoef.set(EdgeType.RIDGE, 0.94357);
            edgeTypeCoef.set(EdgeType.VALLEY, 0);
            edgeTypeCoef.set(EdgeType.STEP_FLASHING, -1.51724);
            let scaleConstant: number = 19.31638;
            let edgeCountCoef: number = -3.58719;
            let ssdCoef: number = 4101.09292;
            let distanceCoef: number = -0.15785;

            let edges: number[] = this.vertexElementModel.wireframe.findEdgesForVertex(vert.id);
            for (let edgeId of edges) {
                let et: number = edgeTypeProp.getValue(this.vertexElementModel.wireframe.edges[edgeId]);
                if (null != et) {
                    edgeTypeCounts[et]++
                }
            }

            scale = scaleConstant + (edgeCountCoef * edges.length) + (ssdCoef * ssd) + (distanceCoef * distanceFromCamera);
            for (let et in edgeTypeCoef) {
                scale += edgeTypeCoef[et] * edgeTypeCounts[et];
            }
        } else {
            let scaleConstant: number = 22.57345;
            let edgeCountCoef: number = -3.09635;
            let ssdCoef: number = 3861.41451;
            let distanceCoef: number = -0.13833;
            let edges: number[] = this.vertexElementModel.wireframe.findEdgesForVertex(vert.id);
            scale = scaleConstant + (edgeCountCoef * edges.length) + (ssdCoef * ssd) + (distanceCoef * distanceFromCamera);
        }
        return scale;
    }

    removeMap() {
        if (!this.map) return;
        this.map.remove();
    }

    protected updateWireframeFeatures() {
        this.updateVertexProjectionPoint();
        this.clearWireframeFeatures();
        this.createWireframeFeatures();
    }

    protected clearWireframeFeatures() {
        if (this.wireframeFeatures) this.wireframeFeatures.clearLayers();
    }

    protected createWireframeFeatures() {
        let self = this;

        // all wireframe features
        let wireframeFeatures = [];

        // vertex
        let vertexLatLng = self.L.latLng(self.projectImageModel.height - self.projectionPointY - self.verticalOffset, self.projectionPointX);

        let vertexPointFeature = {
            type: "Feature",
            geometry: {
                "type": "Point",
                "coordinates": [vertexLatLng.lng, vertexLatLng.lat]
            },
            properties: {}
        };
        wireframeFeatures.push(vertexPointFeature);

        let wireframeGeoJsonLayers = new self.L.GeoJSON(wireframeFeatures, {
            style: function (feature) {
                let className = self.getFeatureStyle(feature);
                if (!className) return null;
                return {
                    className: className,
                    color: '#14ff00',
                    fillColor: '#14ff00',
                    fillOpacity: 0.5,
                    radius: self.cameraViewPointRadius
                };
            },
            pointToLayer: function (feature, latlng) {
                let className = self.getFeatureStyle(feature);
                if (!className) return null;
                let geojsonMarkerOptions = {
                    className: className
                };

                // remove previous subscription
                if (self.vertexMarker) {
                    self.vertexMarker.off('mousedown', self.onDrawEditMouseDown.bind(self));
                    self.vertexMarker.off('mouseup', self.onDrawEditMouseUp.bind(self));
                    self.vertexMarker.off('dragstart', self.onDrawEditDragStart.bind(self));
                    self.vertexMarker.off('drag', self.onDrawEditDrag.bind(self));
                    self.vertexMarker.off('dragend', self.onDrawEditDragEnd.bind(self));
                }

                self.vertexMarker = self.L.circle(latlng, geojsonMarkerOptions);

                // respond to draw events
                self.vertexMarker.on('mousedown', self.onDrawEditMouseDown.bind(self));
                self.vertexMarker.on('mouseup', self.onDrawEditMouseUp.bind(self));
                self.vertexMarker.on('dragstart', self.onDrawEditDragStart.bind(self));
                self.vertexMarker.on('drag', self.onDrawEditDrag.bind(self));
                self.vertexMarker.on('dragend', self.onDrawEditDragEnd.bind(self));

                return self.vertexMarker;
            }
        });

        wireframeGeoJsonLayers.eachLayer(
            function (layer) {
                if (!self.wireframeFeatures) return;

                // add the layer
                self.wireframeFeatures.addLayer(layer);

                layer.on('click', (e) => {
                    self.L.DomEvent.stopPropagation(e);
                    self.disableAllLayerEditing();
                    layer.options.editing || (layer.options.editing = {});
                    if (layer.editing) layer.editing.enable();
                });

                layer.on('dblclick', (e) => {
                    self.L.DomEvent.stopPropagation(e);
                });
            }
        );
    }

    protected disableAllLayerEditing() {
        let edited = false;
        this.map.eachLayer((layer) => {
            if (layer.editing) {
                edited = true;
                layer.editing.disable();
            }
        });
    }

    protected getFeatureTypeClass(feature: any) {
        if (!feature) return null;
        let geometry = feature.geometry;
        if (!geometry) return null;
        let geometryType = geometry.type;
        if (!geometryType) return null;
        return 'ptv-feature-' + geometryType.toLowerCase();
    }

    protected getFeatureStyle(feature: any) {
        let featureTypeClass = this.getFeatureTypeClass(feature);
        let featureStyle = "";
        if (featureTypeClass) {
            featureStyle += featureTypeClass;
        }
        return featureStyle;
    }

    getImagePosition(latLng: LatLng): Vector3 {
        let self = this;
        let imagePoint3d = new Vector3(latLng.lng, self.projectImageModel.height - latLng.lat - this.verticalOffset, self.vertexElementModel.vertexElement.pvObject.z);
        return imagePoint3d;
    }

    getLockedPlane(): Plane {
        let lockedPlane = this.sceneService.wireframeApplication.getFirstLockedElement(WireframeElementType.plane);
        if (!lockedPlane) return null;
        let plane3 = this.sceneService.wireframeApplication.wireframe.getPlane3(lockedPlane.pvObject.id);
        return plane3;
    }

    moveVertexTo(point3d: Vector3) {
        this.vertexElementModel.vertexElement.pvObject.x = point3d.x;
        this.vertexElementModel.vertexElement.pvObject.y = point3d.y;
        this.vertexElementModel.vertexElement.pvObject.z = point3d.z;
        this.saveVertexState();
        this.sceneService.wireframeApplication.wireframeLayer.moveVertex(this.vertexElementModel.vertexElement, point3d, true, false);
    }

    updateVertexPositionFromMarker(){
        let latLng = this.vertexMarker.getLatLng();
        let imagePoint3d = this.getImagePosition(latLng);
        let lockedPlane = this.getLockedPlane();
        if(!lockedPlane) return;
        let planeArr = [lockedPlane.normal.x, lockedPlane.normal.y, lockedPlane.normal.z, lockedPlane.constant];

        // project a point from the 2D image canvas to the 3D plane given the perspective of the active camera
        let pointArray3d = Projection.Find3DPointOnPlaneFrom2DPointWithCameraView([imagePoint3d.x, imagePoint3d.y], this.projectImageModel.cameraView, planeArr);
        let point3d: Vector3 = new Vector3(pointArray3d[0], pointArray3d[1], pointArray3d[2]);
        this.moveVertexTo(point3d);
    }

    protected onDrawEditMouseDown(e): void {
        if(!this.vertexElementModel) return;
        if(!this.vertexElementModel.focused || !this.getLockedPlane()) {
            this.disableMarkerDrag();
        } else {
            this.enableMarkerDrag();
        }
    }

    protected enableMarkerDrag(){
        let vertexMarkerObject = (this.vertexMarker as any);
        if(!vertexMarkerObject) return;
        if(!vertexMarkerObject.dragging) return;
        vertexMarkerObject.dragging.enable();
        this.setMarkerClassName('ptv-marker-drag');
        this.isMarkerDragging = true;
    }

    protected disableMarkerDrag(){
        let vertexMarkerObject = (this.vertexMarker as any);
        if(!vertexMarkerObject) return;
        if(!vertexMarkerObject.dragging) return;
        vertexMarkerObject.dragging.disable();
        this.removeMarkerClassName('ptv-marker-drag');
        this.isMarkerDragging = false;
    }

    protected setMarkerClassName(className:string){
        this.vertexMarker.getElement().classList.add(className);
    }

    protected removeMarkerClassName(className:string){
        this.vertexMarker.getElement().classList.remove(className);
    }

    protected onDrawEditMouseUp(e): void {
        this.isMarkerDragging = false;
    }

    protected onDrawEditDragStart(e): void {
        console.log('onDrawEditDragStart');
    }

    protected onDrawEditDrag(e): void {
        this.updateVertexPositionFromMarker();
    }

    protected onDrawEditDragEnd(e): void {
        console.log('onDrawEditDragEnd');
        // this.disableMarkerDrag();
    }

    onProjectImageCardClick(mouseEvent) {
        if (!this.projectImageModel) return;
        if (!mouseEvent.ctrlKey || !mouseEvent.shiftKey) return;
        this.projectImageModel.selected = !this.projectImageModel.selected;
        mouseEvent.preventDefault();
        mouseEvent.stopPropagation();
    }

    onProjectImageCardDoubleClick() {
        if (!this.projectImageModel) return;
        this.projectImageModel.focused = true;
    }

    onProjectImageCardMouseEnter() {
        this.isMouseOver = true;
        this.projectImageModel.highlighted = true;
        this.detectChanges();
    }

    onProjectImageCardMouseLeave() {
        this.isMouseOver = false;
        this.projectImageModel.highlighted = false;
        this.detectChanges();
    }

    get projectionPointX(): number {
        if (this.projectedPoint) return this.projectedPoint[0];
    }

    get projectionPointY(): number {
        if (this.projectedPoint) return this.projectedPoint[1];
    }

    get cameraViewPointRadius(): number {
        return 5;
    }

    detectChanges(): void {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }
}