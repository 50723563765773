import ProjectImageListFilterCollection
    from "../../collections/projectImageListFilters/projectImageListFilter.collection";
import {ProjectImageModel} from "../projectImages/projectImage.model";
import {ProjectImageCollectionFilterModel} from "./projectImageCollectionFilter.model";
import {EventEmitter} from "@angular/core";

export interface ProjectImageListFilterExpression {
    projectImageListFilterCollection: ProjectImageListFilterCollection
}

export class ProjectImageCollectionFilterExpressionModel implements ProjectImageListFilterExpression {
    projectImageCollectionFilterChanged = new EventEmitter<ProjectImageCollectionFilterModel>();
    projectImageListFilterCollection: ProjectImageListFilterCollection = new ProjectImageListFilterCollection();

    constructor() {
    }

    addFilter(projectImageListFilterModel: ProjectImageCollectionFilterModel) {
        this.projectImageListFilterCollection.add(projectImageListFilterModel);
        projectImageListFilterModel.filterChanged.subscribe(this.onFilterChanged.bind(this));
        this.triggerProjectImageCollectionFilterChanged(projectImageListFilterModel);
    }

    removeFilter(projectImageListFilterModel: ProjectImageCollectionFilterModel) {
        this.projectImageListFilterCollection.remove(projectImageListFilterModel);
        this.triggerProjectImageCollectionFilterChanged(projectImageListFilterModel);
    }

    filter(projectImageModel: ProjectImageModel) {
        if (!projectImageModel) return false;
        if (!this.projectImageListFilterCollection) return false;
        let passesFilter = true;
        this.projectImageListFilterCollection.forEach((projectImageListFilterModel) => {
            if (!passesFilter) return;
            passesFilter = projectImageListFilterModel.filter(projectImageModel);
        });
        return passesFilter;
    }

    onFilterChanged(projectImageListFilterModel: ProjectImageCollectionFilterModel) {
        this.triggerProjectImageCollectionFilterChanged(projectImageListFilterModel)
    }

    triggerProjectImageCollectionFilterChanged(projectImageListFilterModel: ProjectImageCollectionFilterModel){
        this.projectImageCollectionFilterChanged.emit(projectImageListFilterModel);
    }
}