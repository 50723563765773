import {Component, ElementRef, NgZone, OnDestroy, ViewChild,} from '@angular/core';
import {WireframeApplication} from "../../application/wireframeApplication";
import {WireframeOperations} from "../../wireframeOperations";
import {SceneService} from "../../services/scenes/scene.service";

@Component({
    selector: 'transform-controls',
    styles: [require('./transformControls.component.scss')],
    template: require('./transformControls.component.html')
})
export default class TransformControlsComponent implements OnDestroy {

    private app:WireframeApplication
    constructor(private zone:NgZone, private sceneService:SceneService) {
        this.app = sceneService.wireframeApplication
        this.app.activeTool.getTransformTool()
    }


    getTransformType() {
        let ttool = this.app.activeTool.getTransformTool()
        if (ttool) {
            return ttool.getMode()
        }
    }

    setTransformType(mode) {
        let ttool = this.app.activeTool.getTransformTool()
        if (ttool) {
            ttool.setMode(mode)
        }
    }

    get transformSpace() {
        let ttool = this.app.activeTool.getTransformTool()
        if (ttool) {
            return ttool.space == "local"
        }
    }

    set transformSpace(b:boolean) {
        let ttool = this.app.activeTool.getTransformTool()
        if (ttool) {
            ttool.setSpace(b ? "local" : "world")
        }
    }


    ngOnDestroy(): void {

    }
}