import {InteractiveModel} from "../interactive/interactive.model";
import {ProjectImageModel} from "../projectImages/projectImage.model";

export interface ProjectImageInventory {
    name: string;
    classes: Array<string>;
    images: Array<ProjectImageModel>;
}

export class ProjectImageInventoryModel extends InteractiveModel implements ProjectImageInventory {
    name: string;
    classes: Array<string> = ["general"];
    images: Array<ProjectImageModel>;

    constructor() {
        super();
    }

    get allTags(): Array<string> {
        let tags: Array<string> = [];
        this.images.forEach((projectImageModel) => {
            if (!projectImageModel) return;
            let projectImageModelTags = projectImageModel.tags;
            projectImageModelTags.forEach((tag) => {
                if (tags.indexOf(tag) >= 0) return;
                tags.push(tag);
            })
        });
        return tags;
    }

    get allAnnotationClasses(): Array<string> {
        let annotationClasses: Array<string> = [];
        if(this.classes){
            this.classes.forEach((annotationClass) => {
                if (annotationClasses.indexOf(annotationClass) >= 0) return;
                annotationClasses.push(annotationClass);
            })
        }
        this.images.forEach((projectImageModel) => {
            if (!projectImageModel) return;
            let projectImageModelAnnotationClasses = projectImageModel.classesUsedInAnnotation;
            if(!projectImageModelAnnotationClasses) return;
            projectImageModelAnnotationClasses.forEach((annotationClass) => {
                if (annotationClasses.indexOf(annotationClass) >= 0) return;
                annotationClasses.push(annotationClass);
            })
        });
        return annotationClasses;
    }
}