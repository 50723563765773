import * as util from "typescript-collections/dist/lib/util";
import {EventEmitter} from "@angular/core";
import {InteractiveModel} from "../../models/interactive/interactive.model";
import {GenericCollection} from "../generic/generic.collection";
import {InteractiveSelectEventModel} from "../../models/interactive/interactiveSelectEvent.model";
import {InteractiveOperationEventModel} from "../../models/interactive/interactiveOperationEvent.model";

export class InteractiveCollection<T extends InteractiveModel> extends GenericCollection<T> {

    // public events
    public focusedChanging: EventEmitter<T> = new EventEmitter<T>();
    public focusedChanged: EventEmitter<T> = new EventEmitter<T>();
    public selectedChanging: EventEmitter<InteractiveSelectEventModel<T>> = new EventEmitter<InteractiveSelectEventModel<T>>();
    public selectedChanged: EventEmitter<InteractiveSelectEventModel<T>> = new EventEmitter<InteractiveSelectEventModel<T>>();
    public highlightedChanging: EventEmitter<T> = new EventEmitter<T>();
    public highlightedChanged: EventEmitter<T> = new EventEmitter<T>();
    public operationStarted: EventEmitter<InteractiveOperationEventModel<T>> = new EventEmitter<InteractiveOperationEventModel<T>>();
    public operationEnded: EventEmitter<InteractiveOperationEventModel<T>> = new EventEmitter<InteractiveOperationEventModel<T>>();

    constructor() {
        super();
    }

    add(item: T): boolean {
        if (!item) return false;
        this.registerItem(item);
        return super.add(item);
    }

    remove(item: T, equalsFunction?: util.IEqualsFunction<T>): boolean {
        if (item) {
            this.unregisterItem(item);
        }
        return super.remove(item, equalsFunction);
    }

    removeElementAtIndex(index: number): T | undefined {
        let item = super.removeElementAtIndex(index);
        if (item) {
            this.unregisterItem(item);
        }
        return item;
    }

    clear(): void {
        this.forEach((item) => {
            this.unregisterItem(item);
        });
        super.clear();
    }

    focusedChangingSubscription;
    focusedChangedSubscription;
    selectedChangingSubscription;
    selectedChangedSubscription;
    highlightedChangingSubscription;
    highlightedChangedSubscription;
    operationStartedSubscription;
    operationEndedSubscription;

    protected registerItem(item: T): void {
        if (!item) return;
        this.focusedChangingSubscription = item.focusedChanging.subscribe(this.onItemFocusChanging.bind(this));
        this.focusedChangedSubscription = item.focusedChanged.subscribe(this.onItemFocusChanged.bind(this));
        this.selectedChangingSubscription = item.selectedChanging.subscribe(this.onItemSelectionChanging.bind(this));
        this.selectedChangedSubscription = item.selectedChanged.subscribe(this.onItemSelectionChanged.bind(this));
        this.highlightedChangingSubscription = item.highlightedChanging.subscribe(this.onItemHighlightChanging.bind(this));
        this.highlightedChangedSubscription = item.highlightedChanged.subscribe(this.onItemHighlightChanged.bind(this));
        this.operationStartedSubscription = item.operationStarted.subscribe(this.onOperationStarted.bind(this));
        this.operationEndedSubscription = item.operationEnded.subscribe(this.onOperationEnded.bind(this));
    }

    protected unregisterItem(item: T): void {
        if (!item) return;
        if (this.focusedChangingSubscription) this.focusedChangingSubscription.unsubscribe();
        if (this.focusedChangedSubscription) this.focusedChangedSubscription.unsubscribe();
        if (this.selectedChangingSubscription) this.selectedChangingSubscription.unsubscribe();
        if (this.selectedChangedSubscription) this.selectedChangedSubscription.unsubscribe();
        if (this.highlightedChangingSubscription) this.highlightedChangingSubscription.unsubscribe();
        if (this.highlightedChangedSubscription) this.highlightedChangedSubscription.unsubscribe();
        if (this.operationStartedSubscription) this.operationStartedSubscription.unsubscribe();
        if (this.operationEndedSubscription) this.operationEndedSubscription.unsubscribe();
    }

    protected onItemFocusChanging(item: T): void {
        if (!item) return;
        if (!item.focused) {
            // focus is about to change to this item
            this.forEach((currentItem) => {
                if (currentItem.focused && currentItem !== item) {
                    currentItem.focused = false;
                }
            });

        } else {
            // focus is about to leave this item
        }
        this.focusedChanging.emit(item)
    }

    protected onItemFocusChanged(item: T): void {
        if (!item) return;
        this.focusedChanged.emit(item)
    }

    protected onItemSelectionChanging(interactiveSelectEventModel: InteractiveSelectEventModel<T>): void {
        if (!interactiveSelectEventModel) return;
        this.selectedChanging.emit(interactiveSelectEventModel)
    }

    protected onItemSelectionChanged(interactiveSelectEventModel: InteractiveSelectEventModel<T>): void {
        if (!interactiveSelectEventModel) return;
        this.selectedChanged.emit(interactiveSelectEventModel)
    }

    protected onItemHighlightChanging(item: T): void {
        if (!item) return;
        if (!item.highlighted) {
            // highlight is about to change to this item
            this.forEach((currentItem) => {
                if (currentItem.highlighted && currentItem !== item) {
                    currentItem.highlighted = false;
                }
            });

        } else {
            // highlight is about to leave this item
        }
        this.highlightedChanging.emit(item)
    }

    protected onItemHighlightChanged(item: T): void {
        if (!item) return;
        this.highlightedChanged.emit(item)
    }

    protected onOperationStarted(interactiveOperationEventModel: InteractiveOperationEventModel<T>): void {
        if (!interactiveOperationEventModel) return;
        this.operationStarted.emit(interactiveOperationEventModel);
    }

    protected onOperationEnded(interactiveOperationEventModel: InteractiveOperationEventModel<T>): void {
        if (!interactiveOperationEventModel) return;
        this.operationEnded.emit(interactiveOperationEventModel);
    }

}