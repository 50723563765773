import {
    Component,
    Input,
    ViewChild,
    ElementRef,
    AfterViewInit,
    OnDestroy,
    DoCheck,
    ChangeDetectorRef,
    NgZone
} from '@angular/core';
import {debounceTime} from "rxjs/operators";
import * as L from 'leaflet';
import {ResizedEvent} from 'angular-resize-event';
import {Subject} from "rxjs";
import {ProjectImageModel} from "../../models/projectImages/projectImage.model";
import ProjectImageCollection from "../../collections/projectImages/projectImage.collection";

@Component({
    selector: 'project-image-map',
    template: require('./projectImageMap.component.html'),
    styles: [require('./projectImageMap.component.scss')]
})
export default class ProjectImageMapComponent implements AfterViewInit, DoCheck, OnDestroy {
    @ViewChild('projectImageMap', {static: false}) projectImageMapRef: ElementRef;
    @Input() projectImageCollection: ProjectImageCollection;
    private map: any;
    private L: any;

    // camera view markers
    private cameraViewMarkerLayer: any;
    private topMarkerZIndex: number;
    readonly nadirThreshold: number;
    readonly directionalMarkerProjectedLineEnabled: boolean;
    readonly directionalMarkerProjectedLineDistance: number;

    private sizeChanged: Subject<ResizedEvent> = new Subject<ResizedEvent>();
    private projectImageCollectionFilterChangedSubscription;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private zone: NgZone
    ) {
        this.L = L;
        if (window) (window as any).L = L;
        this.topMarkerZIndex = 0;
        this.nadirThreshold = -85.0;

        // undocumented: enable the following to additionally render a polyline for all non-nadir images
        this.directionalMarkerProjectedLineEnabled = false;
        this.directionalMarkerProjectedLineDistance = 20;
    }

    ngAfterViewInit(): void {
        // add the map
        this.addMap();

        // listen for updates
        this.projectImageCollectionFilterChangedSubscription = this.projectImageCollection.projectImageCollectionFilterChanged.subscribe(this.onProjectImageCollectionFilterChanged.bind(this));
        this.sizeChanged
            .pipe(debounceTime(300))
            .subscribe((event) => {
                this.refreshMap();
            });
    }

    previousWithFilterApplied;

    ngDoCheck() {
        if (!this.projectImageCollection) return;
        let withFilterApplied = this.projectImageCollection.withFilterApplied;
        if (this.previousWithFilterApplied === withFilterApplied) return;
        this.previousWithFilterApplied = withFilterApplied;
        this.updateCameraViewMarkers();
    }

    detectChanges(): void {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.removeMap();
        if (this.projectImageCollectionFilterChangedSubscription) {
            this.projectImageCollectionFilterChangedSubscription.unsubscribe();
        }
    }

    onResized(event: ResizedEvent): void {
        this.sizeChanged.next(event);
    }

    onProjectImageCollectionFilterChanged() {
        this.updateCameraViewMarkers();
    }

    refreshMap() {
        this.removeMap();
        this.addMap();
    }

    removeMap() {
        if (!this.map) return;
        this.map.remove();
    }

    addMap() {
        let self = this;

        // Create the map outside of angular so the various map events don't trigger change detection
        this.zone.runOutsideAngular(() => {

            // determine the map settings based on the available sets of tiles
            let mapMinZoom = 16;
            let mapMaxZoom = 30;

            // default coordinate system
            let crs = self.L.CRS.EPSG3857;

            // layers
            let layers = [];

            // base layers (toggled)
            let baseLayers = {};

            // near map layer
            let mapLayer = self.L.tileLayer.wms('https://wmsus.nearmap.com/wms?httpauth=false&apikey=ZDlmZTdhNGItMjcxNy00NjA5LWE4NDMtNTA1M2M4Njc2YjEz', {
                layers: 'NearMap',
                format: 'image/jpeg',
                crs: crs,
                maxZoom: mapMaxZoom
            });

            baseLayers['map'] = mapLayer;
            layers.push(mapLayer);

            // create a map
            self.map = self.L.map(self.projectImageMapRef.nativeElement, {
                attributionControl: false,
                touchExtend: true,
                layers: layers,
                crs: crs,
                zoomSnap: 0
            });
            self.zoomImageBounds();
            self.updateCameraViewMarkers();
        });
    }

    zoomImageBounds() {
        let self = this;

        // get the gps bounds
        let bounds = self.projectImageCollection.gpsBounds;
        self.map.fitBounds(bounds, {animate: true});
    }

    cameraViewMarkers: any[] = [];

    updateCameraViewMarkers() {
        let self = this;
        if (!self.map) return;

        // initialize
        if (!self.cameraViewMarkerLayer) {
            // add a marker item layer
            self.cameraViewMarkerLayer = new self.L.FeatureGroup();
            self.map.addLayer(self.cameraViewMarkerLayer);
        }

        //  remove
        let markersToRemove = [];
        self.cameraViewMarkers.forEach((cameraViewMarker) => {
            let projectImageModel = self.findProjectImageModelByCameraMarker(cameraViewMarker);
            if (!projectImageModel) {
                markersToRemove.push(cameraViewMarker);
            } else {
                let index = self.projectImageCollection.withFilterApplied.indexOf(projectImageModel);
                if (index < 0) {
                    markersToRemove.push(cameraViewMarker);
                }
            }
        });

        markersToRemove.forEach((cameraMarkerView) => {
            let index = self.cameraViewMarkers.indexOf(cameraMarkerView);
            if (index >= 0) {
                self.cameraViewMarkers.splice(index, 1);
            }
            self.cameraViewMarkerLayer.removeLayer(cameraMarkerView);
        });

        // add/update
        let markersToAdd = [];
        let markersToUpdate = [];
        self.projectImageCollection.withFilterApplied.forEach((projectImageModel) => {
            let marker = self.findCameraMarkerByProjectImageModel(projectImageModel);
            if (!marker) {
                markersToAdd.push(projectImageModel);
            } else {
                markersToUpdate.push(projectImageModel);
            }
        });

        // update
        markersToUpdate.forEach((projectImageModel) => {
            // todo: update - not necessary now because data doesn't change
        });

        //  add
        markersToAdd.forEach((projectImageModel) => {

            let marker;
            let lat = projectImageModel.gpsLatitude;
            let long = projectImageModel.gpsLongitude;
            if (typeof lat === "undefined" || lat === null) return;
            if (typeof long === "undefined" || long === null) return;
            let center = self.L.latLng([lat, long]);
            let rotation = projectImageModel.gimbalYawDegree;
            let tilt = projectImageModel.gimbalPitchDegree;
            let isNadir = (typeof tilt !== "undefined" && tilt !== null && tilt < (Math.abs(self.nadirThreshold) * -1));

            let getClassName = (projectImageModel) => {
                if (projectImageModel.focused) return 'ptv-focused';
                if (projectImageModel.selected) return 'ptv-selected';
                if (projectImageModel.highlighted) return 'ptv-highlighted';
                return 'ptv-hidden';
            };

            let updateClassName = (projectImageModel) => {
                let el = marker._icon;
                if (!el) return;
                if (self.L.DomUtil.hasClass(el, 'ptv-hidden')) self.L.DomUtil.removeClass(el, 'ptv-hidden');
                if (self.L.DomUtil.hasClass(el, 'ptv-focused')) self.L.DomUtil.removeClass(el, 'ptv-focused');
                if (self.L.DomUtil.hasClass(el, 'ptv-selected')) self.L.DomUtil.removeClass(el, 'ptv-selected');
                if (self.L.DomUtil.hasClass(el, 'ptv-highlighted')) self.L.DomUtil.removeClass(el, 'ptv-highlighted');

                let className = getClassName(projectImageModel);

                if (className != '') {
                    self.L.DomUtil.addClass(el, className);
                }
            };

            const circleSvg = '<svg width="100%" height="100%" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><g><circle cx="10" cy="10" r="8"/></g></svg>';
            const arrowSvg = '<svg width="100%" height="100%" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><g transform="rotate(' + rotation + ',10,10)"><path d="M18.48,16.49,11.22,1.55a1.36,1.36,0,0,0-2.44,0L1.52,16.49c-.8,1.17.38,3,1.62,2.68l6.54-1.91a1.16,1.16,0,0,1,.64,0l6.54,1.91C18.1,19.53,19.28,17.66,18.48,16.49Z"/></g></svg>';

            let useDirectionalMarker = false;
            if (typeof rotation !== "undefined" && rotation !== null && !isNadir) {
                useDirectionalMarker = true;

                if (self.directionalMarkerProjectedLineEnabled) {
                    self.L.polyline([center, self.destination(center, rotation, self.directionalMarkerProjectedLineDistance)], {
                        className: 'ptv-marker ' + getClassName(projectImageModel)
                    }).addTo(self.map);
                }
            }

            let svg = useDirectionalMarker ? arrowSvg : circleSvg;
            let icon = self.L.divIcon({
                html: svg,
                iconSize: [12, 12],
                iconAnchor: [6, 6],
                riseOnHover: true,
                bubblingMouseEvents: false,
                className: 'ptv-marker ' + getClassName(projectImageModel)
            });
            marker = self.L.marker(center, {icon: icon, projectImageModel: projectImageModel});
            self.cameraViewMarkers.push(marker);
            self.cameraViewMarkerLayer.addLayer(marker);

            if (projectImageModel.focused) {
                // TODO consider using LayerGroups to control visibility and z-index ordering
                self.map.setView(center, 18);
                self.topMarkerZIndex++;
                marker.setZIndexOffset(self.topMarkerZIndex);
            }

            projectImageModel.focusedChanged.subscribe(() => {
                updateClassName(projectImageModel);
            });

            projectImageModel.selectedChanged.subscribe(() => {
                updateClassName(projectImageModel);
            });

            projectImageModel.highlightedChanged.subscribe(() => {
                updateClassName(projectImageModel);
                // TODO consider using LayerGroups to control visibility and z-index or
                self.topMarkerZIndex++;
                marker.setZIndexOffset(self.topMarkerZIndex);
            });

            marker.on('click', (mouseEvent) => {
                if (typeof projectImageModel.selected !== "undefined") {
                    let append = !!mouseEvent.shiftKey;
                    let toggle = !!mouseEvent.ctrlKey || !!mouseEvent.metaKey;
                    projectImageModel.setSelected(!projectImageModel.selected, append, toggle);
                }
            });

            marker.on('dblclick', (mouseEvent) => {
                if (typeof projectImageModel.focused !== "undefined") projectImageModel.focused = true;
            });

            marker.on('mouseover', (mouseEvent) => {
                if (typeof projectImageModel.highlighted !== "undefined") projectImageModel.highlighted = true;
            });

            marker.on('mouseout', (mouseEvent) => {
                if (typeof projectImageModel.highlighted !== "undefined") projectImageModel.highlighted = false;
            });

        });
    }

    findCameraMarkerByProjectImageModel(projectImageModel: ProjectImageModel): any {
        let cameraViewMarker = null;
        this.cameraViewMarkers.forEach((currentCameraViewMarker) => {
            if (!currentCameraViewMarker) return;
            let options = currentCameraViewMarker.options;
            if (!options) return;
            let currentProjectImageModel = options.projectImageModel;
            if (currentProjectImageModel == projectImageModel) {
                cameraViewMarker = currentCameraViewMarker;
            }
        });
        return cameraViewMarker;
    }

    findProjectImageModelByCameraMarker(cameraMarker: any): ProjectImageModel {
        let projectImageModel: ProjectImageModel = null;
        if (!cameraMarker) return projectImageModel;
        let options = cameraMarker.options;
        if (!options) return projectImageModel;
        projectImageModel = options.projectImageModel;
        return projectImageModel;
    }

    destination(latlng, heading, distance) {
        heading = (heading + 360) % 360;
        let rad = Math.PI / 180,
            radInv = 180 / Math.PI,
            R = 6378137, // approximation of Earth's radius
            lon1 = latlng.lng * rad,
            lat1 = latlng.lat * rad,
            rheading = heading * rad,
            sinLat1 = Math.sin(lat1),
            cosLat1 = Math.cos(lat1),
            cosDistR = Math.cos(distance / R),
            sinDistR = Math.sin(distance / R),
            lat2 = Math.asin(sinLat1 * cosDistR + cosLat1 *
                sinDistR * Math.cos(rheading)),
            lon2 = lon1 + Math.atan2(Math.sin(rheading) * sinDistR *
                cosLat1, cosDistR - sinLat1 * Math.sin(lat2));
        lon2 = lon2 * radInv;
        lon2 = lon2 > 180 ? lon2 - 360 : lon2 < -180 ? lon2 + 360 : lon2;
        return L.latLng([lat2 * radInv, lon2]);
    }
}