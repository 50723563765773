import {InteractiveModel} from "../interactive/interactive.model";
import {WireframeElementType} from "../../application/wireframeElementType";

export interface WireframeElementStatistic {
    wireframeElementType: WireframeElementType,
    count: number
}

export class WireframeElementStatisticModel extends InteractiveModel implements WireframeElementStatistic {
    wireframeElementType: WireframeElementType;
    count: number;

    constructor(wireframeElementType:WireframeElementType, count:number = 0){
        super();
        this.wireframeElementType = wireframeElementType;
        this.count = count;
    }

    get wireframeElementTypeName():string{
        return WireframeElementType[this.wireframeElementType];
    }
}