import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, EventEmitter,
    Input, OnDestroy, Output, ViewChild
} from '@angular/core';
import {CdkDragDrop} from "@angular/cdk/drag-drop"
import {v4 as uuid} from 'uuid';
import {InteractiveModel} from "../../models/interactive/interactive.model";
import {InteractiveSelectEventModel} from "../../models/interactive/interactiveSelectEvent.model";
import {InteractiveSelectionModel} from "../../models/interactive/interactiveSelection.model";
import {InteractiveDataViewModel} from "../../models/interactive/interactiveDataView.model";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";

@Component({
    selector: '',
    styles: [require('./interactiveList.component.scss')],
    template: require('./interactiveList.component.html')
})
export default class InteractiveListComponent<T extends InteractiveModel> implements AfterViewInit, OnDestroy {
    @Input() interactiveListId: string = null;
    @Input() connectedInteractiveListIds: string[] = [];
    @Input() interactiveDataView: InteractiveDataViewModel<T>;
    @Input() scrollOnSelect:boolean = false;
    @Output() interactiveModelVisibilityChange = new EventEmitter<T[]>();

    private virtualScrollStart;
    private virtualScrollEnd;
    private interactiveCollectionFilterChangedSubscription;
    private interactiveCollectionSelectedChangingSubscription;
    private interactiveCollectionSelectedChangedSubscription;

    @ViewChild(CdkVirtualScrollViewport, {static: false}) virtualScroller: CdkVirtualScrollViewport;

    // selection
    private interactiveSelectionModel:InteractiveSelectionModel<T>;

    constructor(
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.interactiveListId = this.interactiveListId ? this.interactiveListId : "interactiveListComponent-" + uuid();
        this.interactiveSelectionModel = new InteractiveSelectionModel<T>(() => {
            if(!this.interactiveDataView) return null;
            return this.interactiveDataView.interactiveCollection.toArray();
        })
    }

    ngAfterViewInit(): void {
        this.interactiveCollectionSelectedChangingSubscription = this.interactiveDataView.interactiveCollection.selectedChanging.subscribe(this.onInteractiveCollectionSelectChanging.bind(this));
        this.interactiveCollectionSelectedChangedSubscription = this.interactiveDataView.interactiveCollection.selectedChanged.subscribe(this.onInteractiveCollectionSelectChanged.bind(this));
        this.interactiveCollectionFilterChangedSubscription = this.interactiveDataView.filterChanged.subscribe(this.onInteractiveCollectionFilterChanged.bind(this));
    }

    ngOnDestroy(): void {
        if(this.interactiveCollectionSelectedChangingSubscription) this.interactiveCollectionSelectedChangingSubscription.unsubscribe();
        if(this.interactiveCollectionSelectedChangedSubscription) this.interactiveCollectionSelectedChangedSubscription.unsubscribe();
        if(this.interactiveCollectionFilterChangedSubscription) this.interactiveCollectionFilterChangedSubscription.unsubscribe();
    }
    
    scrollToInteractiveModel(interactiveModel:T){
        if(!interactiveModel) return;
        if(!this.virtualScroller) return;
        let index = this.interactiveDataView.dataView.indexOf(interactiveModel);
        this.virtualScroller.scrollToIndex(index, 'auto');
    }

    onInteractiveCollectionSelectChanging(interactiveSelectEventModel:InteractiveSelectEventModel<T>){
        this.interactiveSelectionModel.selectionChangingHandler(interactiveSelectEventModel);
    }

    onInteractiveCollectionSelectChanged(interactiveSelectEventModel:InteractiveSelectEventModel<T>){
        this.interactiveSelectionModel.selectionChangedHandler(interactiveSelectEventModel);
        let interactiveModel = interactiveSelectEventModel.interactiveModel;
        if(!interactiveModel) return;
        if(this.scrollOnSelect) this.scrollToInteractiveModel(interactiveModel);
    }

    onInteractiveCollectionFilterChanged() {
        this.detectChanges();
    }

    onVirtualScrollChange(changeEvent: any): void {
        if (!changeEvent) return;
        let start = changeEvent.startIndex;
        let end = changeEvent.endIndex;
        if (start === -1 && end === -1) return;
        this.virtualScrollStart = start;
        this.virtualScrollEnd = end;

        let visibleInteractiveModelArray: T[] = [];
        let filteredInteractiveCollection = this.interactiveDataView.dataView;
        for (let i = start; i <= end; i++) {
            let interactiveModel = filteredInteractiveCollection[i];
            visibleInteractiveModelArray.push(interactiveModel);
        }
        this.interactiveModelVisibilityChange.emit(visibleInteractiveModelArray);
    }

    onDropListDropped(event: CdkDragDrop<T[]>){
        if(!event) return;
        console.log(event);
        if (event.previousContainer === event.container)  return;
        let item = event.item;
        if(!item) return;
        let interactiveModel:T = item.data;
        if(!interactiveModel) return;
        let existingIndex = this.interactiveDataView.interactiveCollection.indexOf(interactiveModel);
        if(existingIndex >= 0) return ;
        this.interactiveDataView.interactiveCollection.add(interactiveModel);
    }

    detectChanges(): void {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }
}