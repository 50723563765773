import WireframeLayer from "./wireframeLayer";
import {PlaneDetectionMode} from "./planeDetectionMode";
import {PV} from "../wireframe";
import * as THREE from "three";
import {LayerProperty} from "../models/property/layerProperty";
import {WireframeElement} from "./wireframeElement";
import {WireframeElementType} from "./wireframeElementType";
import {WireframeApplication} from "./wireframeApplication";

export default class WireframeLoader {
    
    constructor(private app:WireframeApplication) {}
    
    public jsonToWireFrame(ds, layer:WireframeLayer) {
        layer.isLoading = true
        let startTime = performance.now()
        this.app.isSceneLoaded = false;
        let planeDetectionMode = this.app.planeDetectionMode;
        this.app.planeDetectionMode = PlaneDetectionMode.OFF;
        this.app.undoEnabled = false;

        this.app.numRejectedPlanes = 0;
        if (layer.object) {
            layer.disposeHierarchy(layer.object);
            layer.object.parent.remove(layer.object)
        }

        // todo: this is not working
        this.app.sceneManager.geocentricFrame.add(layer.object);

        if (this.app.displayMeasurementScale == null) {
            this.app.displayMeasurementScale = 1.0;
        }

        let kk = Object.keys(layer.wireframe.vertices);
        layer.wireframeElements.length = 0
        console.log("Loading " + kk.length + " vertices");
        for (let i = 0; i < kk.length; i++) {
            let vert: PV.Vertex = layer.wireframe.vertices[kk[i]];
            layer.addVertex(vert);
        }

        let edgeIds = Object.keys(layer.wireframe.edges);

        console.log("Loading " + edgeIds.length + " edges");
        for (let i = 0; i < edgeIds.length; i++) {
            let edge: PV.Edge = layer.wireframe.edges[edgeIds[i]];
            if (edge.vertex2Id == edge.vertex1Id) continue;
            layer.addEdge(edge, null);
        }

        let planeIds = Object.keys(layer.wireframe.planes);
        console.log("Loading " + planeIds.length + " planes");
        for (let i = 0; i < planeIds.length; i++) {
            let plane: PV.Plane = layer.wireframe.planes[planeIds[i]];
            layer.addPlane(plane);
        }

        let widgetIds = Object.keys(layer.wireframe.widgets);
        console.log("Loading " + widgetIds.length + " widgets");
        let objLoader = new THREE.ObjectLoader()
        for (let i = 0; i < widgetIds.length; i++) {
            let widget: PV.Widget = layer.wireframe.widgets[widgetIds[i]];

            let widgetEl = layer.addWidget(widget)

            /*
            widget.object3D = widgetEl

            let obj = objLoader.parse((widget as any).object3D_JSON)

            let widgetGeom = obj.children[0]
            widgetEl.add(widgetGeom)
            widgetEl.widgetGeometry = widgetGeom

            widgetEl.baseGeometry = widgetEl.findMeshes()
            widgetEl.storeBaseMaterials()
            widgetEl.hitGeometry = widgetEl.baseGeometry
            widgetEl.highlightGeometry = widgetEl.baseGeometry


            obj.children.length = 0
            */

            //this.disposeHierarchy(obj)
        }


        /*
        let layerProp:LayerProperty = layer.wireframe.findProperty(LayerProperty, true);

        try {
            let i = 0
            for (let cam of Object.values(this.app.cameraDict)) {
                let camEl:WireframeElement = layer.addCamera(cam.frameId, cam);
                layerProp.setValue(camEl, layer.elementLayers[WireframeElementType.camera].name);
                i++
                //if (i > 1) break
            }
        } catch (e) {
            console.error("Error initializing camera views", e);
        }
        */


        /*
        app.viewer.cameraControls.upRotation = null
        app.viewer.cameraControls.upRotationInverse = null
        if (false && ds.transform.gravityPlane) {
            app.viewer.camera.up = new THREE.Vector3(-ds.transform.gravityPlane[0], -ds.transform.gravityPlane[1], -ds.transform.gravityPlane[2]);
            app.viewer.camera.up.normalize();
            app.viewer.cameraControls.upRotation = new THREE.Quaternion()
            app.viewer.cameraControls.upRotationInverse = new THREE.Quaternion()
            app.viewer.cameraControls.upRotation.setFromUnitVectors(new THREE.Vector3(0, 1, 0), app.viewer.camera.up);
            app.viewer.cameraControls.upRotationInverse = app.viewer.cameraControls.upRotation.clone();
            app.viewer.cameraControls.upRotationInverse.inverse();
        }
        */

        //app.gravityAlignedGeocentricTransform = wfOps.computeGravityAlignedGeocentricTransform()

        layer.updateTransform()

        this.app.undoEnabled = true;

        //app.addPointCloudLayers();
        this.app.propertiesChanged();

        layer.redrawWireframe();
        this.app.wireframeTopologyChanged();

        // select first vertex for adjust mode
        let verts = Object.values(layer.wireframe.vertices);
        if (verts.length > 0) {
            let vertEl = layer.findWireframeElement(WireframeElementType.vertex, verts[0].id);
            if (vertEl) {
                this.app.selectWireframeElement([vertEl], true);
            }
        }
        this.app.planeDetectionMode = planeDetectionMode;
        layer.isLoading = false
        this.app.isSceneLoaded = true;
        this.app.broadcast("sceneLoaded")
        let loadTime = performance.now() - startTime
        console.log("Wireframe loaded in " + loadTime + "ms")
    }
}