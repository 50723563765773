import {WireframeApplication} from "./application/wireframeApplication";
import * as THREE from 'three';
import {Vector3} from "three";
import {MeasurementUnit} from "./application/measurementUnit";

//declare let Potree: any;
declare let wfApp: WireframeApplication;


export class WireframeUtils {




    static copyMaterial(to: THREE.MeshBasicMaterial, from: THREE.MeshBasicMaterial) {
        to.color.copy(from.color);
        to.opacity = from.opacity;
        if (from.map) to.map = from.map;
        to.wireframe = from.wireframe;
        to.transparent = from.transparent;
        to.depthTest = from.depthTest;
        to.depthWrite = from.depthWrite;
    }

    static updateCylinderGeometry(cylinderMesh: THREE.Mesh, srcPos:THREE.Vector3, dstPos:Vector3, width:number) {
        let vector = dstPos.clone();
        vector.sub(srcPos);
        if (vector.length() < Number.EPSILON) {
            vector.x += Number.EPSILON
            vector.y += Number.EPSILON
            vector.z += Number.EPSILON
        }
        cylinderMesh.quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), vector.clone().normalize());
        cylinderMesh.scale.x = width;
        cylinderMesh.scale.y = vector.length();
        cylinderMesh.scale.z = width;
    }

    static getPlaneGeometry(vertices, uvs) {
        let geometry = new THREE.Geometry();
        geometry.vertices = vertices;
        let triangles = THREE.ShapeUtils.triangulateShape(vertices, []);

        //console.log("triangles ", triangles);
        for (var k = 0; k < triangles.length; k++) {
            geometry.faces.push(new THREE.Face3(triangles[k][0], triangles[k][1], triangles[k][2]));
            geometry.faces.push(new THREE.Face3(triangles[k][2], triangles[k][1], triangles[k][0]));
        }
        if (uvs) {
            geometry.faceVertexUvs = [uvs];
        } else {
            geometry.faceVertexUvs[0] = [];
            for (let i = 0; i < geometry.faces.length; i++) {
                geometry.faceVertexUvs[0].push([new THREE.Vector2(), new THREE.Vector2(), new THREE.Vector2()]);
            }
        }
        /*
        for (let i = 0; i < geometry.faces.length; i++) {
            uvs.push([new Vector2(0, 0), new Vector2(0,0), new Vector2(0,0)]);
        }
        */
        geometry.uvsNeedUpdate = true;
        return geometry;
    }



    static getPitch(slopeDegrees) {
        if (slopeDegrees > 84.29)
            return 0;

        let radian = slopeDegrees * (Math.PI / 180.0);
        let pitchVal = Math.tan(radian) * 12;
        let pitchRounded: number = parseFloat((Math.round(pitchVal * 100) / 100).toFixed(1));
        return pitchRounded;
    }



    static getFeetFractionalInches(decimalValue) {
        let feet = Math.floor(decimalValue);
        let remainder = (decimalValue - feet) * 12;
        let inches = Math.floor(remainder);
        let fractionDecimal = remainder - inches;
        let fractionInteger = parseFloat((fractionDecimal * 32).toFixed(2));
        if (Math.round(fractionInteger) == 32) {
            fractionInteger = 0;
            inches++;
        }
        if (inches == 12) {
            feet++;
            inches = 0;
        }
        let superscript: string[] = ["⁰", "¹", "²", "³", "⁴", "⁵", "⁶", "⁷", "⁸", "⁹"];
        let p1 = (fractionInteger / 10) >= 1 ? superscript[Math.floor(fractionInteger / 10)] : "";
        let p2 = (p1 != "" || Math.floor(fractionInteger % 10) >= 1) ? superscript[Math.floor(fractionInteger % 10)] : "";
        let fractionalString = (p1 + p2) == "" ? "\"" : " " + (p1 + p2) + "/₃₂\"";
        let measurementString = "";
        if (feet > 0)
            measurementString = feet + "' " + inches + fractionalString;
        else
            measurementString = inches + fractionalString;
        return measurementString;
    }

    static point2DInsidePoly(point: THREE.Vector3, vs: THREE.Vector3[]) {
        // ray-casting algorithm based on
        // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
        let x = point.x, y = point.y;
        let inside = false;
        for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
            let xi = vs[i].x, yi = vs[i].y;
            let xj = vs[j].x, yj = vs[j].y;

            let intersect = ((yi > y) != (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
        return inside;
    }

    public static getUnitConversionFactor(oldUnit: MeasurementUnit, newUnit: MeasurementUnit): number {
        let metricScaleFactor: number = 1.0;

        if (oldUnit == MeasurementUnit.UNITLESS || newUnit == MeasurementUnit.UNITLESS)
            return 1.0;
        if ((oldUnit == MeasurementUnit.FT || oldUnit == MeasurementUnit.FFI) && (newUnit == MeasurementUnit.FT || newUnit == MeasurementUnit.FFI))
            return 1.0;

        if (oldUnit == MeasurementUnit.M)
            metricScaleFactor = 1.0;
        // else if (oldUnit == MeasurementUnit.CM)
        //     metricScaleFactor = 0.01;
        // else if (oldUnit == MeasurementUnit.MM)
        //     metricScaleFactor = 0.001;
        // else if (oldUnit == MeasurementUnit.IN)
        //     metricScaleFactor = 0.0254;
        else if (oldUnit == MeasurementUnit.FT || oldUnit == MeasurementUnit.FFI)
            metricScaleFactor = 0.3048;

        let newScaleFactor: number = 1.0;
        if (newUnit == MeasurementUnit.M)
            newScaleFactor = metricScaleFactor;
        // else if (newUnit == MeasurementUnit.CM)
        //     newScaleFactor = metricScaleFactor * 100;
        // else if (newUnit == MeasurementUnit.MM)
        //     newScaleFactor = metricScaleFactor * 1000;
        // else if (newUnit == MeasurementUnit.IN)
        //     newScaleFactor = metricScaleFactor * 39.3701;
        else if (newUnit == MeasurementUnit.FT || newUnit == MeasurementUnit.FFI) {
            newScaleFactor = metricScaleFactor * 3.28084;
        }

        return newScaleFactor;
    }



}


