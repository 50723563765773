import {
    Component,
    Output,
    EventEmitter,
    Input,
    ViewChild,
    ElementRef,
    AfterViewInit
} from '@angular/core';
import {MatAutocomplete, MatOption} from "@angular/material";

@Component({
    selector: 'annotation-popup',
    template: require('./annotationPopup.component.html'),
    styles: [require('./annotationPopup.component.scss')]
})
export default class AnnotationPopupComponent implements AfterViewInit {
    @ViewChild(MatAutocomplete, {static: false}) matAutocomplete: MatAutocomplete;
    @ViewChild("annotationClassInput", {static: false}) annotationClassInput: ElementRef;

    // annotation classes
    @Input() annotationClasses: Array<string>;
    @Input() annotationClassValue: string;
    @Output() annotationClassChange = new EventEmitter<string>();

    // annotation memo
    @Input() annotationMemoValue: string = null;
    @Output() annotationMemoChange = new EventEmitter<string>();

    // annotation occluded
    @Input() annotationIsOccludedValue: boolean = false;
    @Output() annotationIsOccludedChange = new EventEmitter<boolean>();

    // events
    @Output() annotationPopupClosed = new EventEmitter<void>();
    @Output() annotationEditClicked = new EventEmitter<void>();
    @Output() annotationDeleteClicked = new EventEmitter<void>();

    constructor() {
    }

    ngAfterViewInit(): void {
        let self = this;
        if (!self.annotationClassValue) {
            setTimeout(() => {
                self.annotationClassInput.nativeElement.focus()
            }, 0);
        }
    }

    get filteredAnnotationClasses(): Array<string> {
        let filterValue = null;
        if (this.annotationClassInput && this.annotationClassInput.nativeElement && this.annotationClassInput.nativeElement.value) {
            filterValue = this.annotationClassInput.nativeElement.value;
        } else {
            filterValue = this.annotationClassValue;
        }
        let filteredClass: Array<string> = filterValue
            ? this.annotationClasses.filter(classValue => classValue.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
            : this.annotationClasses.filter(classValue => true);
        return filteredClass;
    };

    onAnnotationAutoCompleteClosed(event) {
        // ignore if something is selected
        if (this.matAutocomplete) {
            let selectedOption = this.matAutocomplete.options.find((option) => {
                if (!option) return false;
                return option.selected;
            });

            if (selectedOption) return;
        }

        // set the default value if something is not selected
        if (!this.annotationClassValue) {
            this.annotationClassValue = this.annotationClasses[0];
            this.annotationClassChange.emit(this.annotationClassValue);
        }
    }

    onAnnotationClassChanged(event) {
        let annotationClassValue = (event.option as MatOption).value;
        this.annotationClassChange.emit(annotationClassValue);
    }

    onAnnotationMemoChanged(event) {
        let annotationMemoValue = event.target.value;
        this.annotationMemoChange.emit(annotationMemoValue);
    }

    onAnnotationIsOccludedChanged(event) {
        let annotationIsOccludedValue = event.checked;
        this.annotationIsOccludedChange.emit(annotationIsOccludedValue);
    }

    onAnnotationCloseClicked(event) {
        this.emitAnnotationPopupClosed();
        return false;
    }

    onAnnotationEditClicked(event) {
        this.emitAnnotationPopupClosed();
        this.emitAnnotationEditClicked();
        return false;
    }

    onAnnotationDeleteClicked(event) {
        this.emitAnnotationDeleteClicked();
        return false;
    }

    onKeyUpEnter(event) {
        event.stopPropagation();
        event.preventDefault();

        // ignore events from annotation class input
        if (event.targetElement === this.annotationClassInput.nativeElement) {
            this.annotationClassInput.nativeElement.value = this.filteredAnnotationClasses.length > 0
                ? this.filteredAnnotationClasses[0]
                : "";
            this.annotationClassChange.emit(this.annotationClassInput.nativeElement.value);
        } else {
            // close the popup
            this.emitAnnotationPopupClosed();
        }

        return false;
    }

    emitAnnotationPopupClosed() {
        this.annotationPopupClosed.emit();
    }

    emitAnnotationDeleteClicked() {
        this.annotationDeleteClicked.emit();
    }

    emitAnnotationEditClicked() {
        this.annotationEditClicked.emit();
    }
}