import tpl from './tools.html'
import {SceneService} from "../../services/scenes/scene.service";

export default {
    bindings: {

    },
    controller: ['sceneService', function(sceneService:SceneService) {
        var ctrl = this;

        ctrl.viewerMode = function() {
            return sceneService.wireframeApplication.viewerMode
        }

    }],
    template: tpl
}

