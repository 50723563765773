/**
 * Created by Shwetank on 10-May-2017.
 */

import {PV} from "./wireframe";
import * as THREE from 'three';

export class WireframeMathUtil {
    constructor() {
    }

    public static performPointInPolygonTest(point: THREE.Vector2, basePlanePoints: THREE.Vector2[]): boolean {
        let polyCorners = basePlanePoints.length;
        let j = polyCorners - 1;
        let oddNodes: boolean = false;
        let x: number = point.x;
        let y: number = point.y;

        for (let i = 0; i < polyCorners; i++) {
            if ((basePlanePoints[i].y < y && basePlanePoints[j].y >= y || basePlanePoints[j].y < y && basePlanePoints[i].y >= y) &&
                (basePlanePoints[i].x <= x || basePlanePoints[j].x <= x)) {
                if (basePlanePoints[i].x + (y - basePlanePoints[i].y) / (basePlanePoints[j].y - basePlanePoints[i].y * basePlanePoints[j].x - basePlanePoints[i].x) < x) {
                    oddNodes = !oddNodes;
                }
            }
            j = i;
        }
        return oddNodes;
    }

    public static computePitchUsingPlaneEquation(planeEquation: PV.PlaneEquation, gravityPlane: number[]): number {
        let plane: number[] = [planeEquation.a, planeEquation.b, planeEquation.c, planeEquation.d];
        return this.computePitch(plane, gravityPlane);
    }

    public static computePitch(planeParameters: number[], gravityPlane: number[]): number {
        let planeVector: THREE.Vector3 = new THREE.Vector3(planeParameters[0], planeParameters[1], planeParameters[2]);
        let gravityVector: THREE.Vector3 = new THREE.Vector3(gravityPlane[0], gravityPlane[1], gravityPlane[2]);
        let dotProduct: number = planeVector.dot(gravityVector);
        let angleRadians: number = Math.acos(Math.min(Math.max(dotProduct, -1.0), 1.0));
        let angleDegrees: number = (angleRadians * (180.0 / Math.PI));
        return angleDegrees;
    }

    public static computePerimeter(vertices: PV.Vertex[]): number {
        let perimeter: number = 0;
        for (let i = 0; i < vertices.length; i++) {
            let dist = this.getDistance(vertices[i], vertices[(i + 1) % vertices.length]);
            perimeter += dist;
        }
        return perimeter;
    }

    public static getDistance(v1: PV.Vertex, v2: PV.Vertex): number {
        return Math.sqrt((v2.x - v1.x) * (v2.x - v1.x) + (v2.y - v1.y) * (v2.y - v1.y) + (v2.z - v1.z) * (v2.z - v1.z));
    }

    public static computeSurfaceArea(vertices: THREE.Vector2[]): number {
        let area: number = 0.0;
        let j: number = vertices.length - 1;
        for (let i = 0; i < vertices.length; i++) {
            area += (vertices[j].x + vertices[i].x) * (vertices[j].y - vertices[i].y);
            j = i;
        }
        return Math.abs(area / 2);
    }

    public static getPlaneCoordinateSystemUsingPlaneEquation(planeEquation: PV.PlaneEquation, xDirectionStart: PV.Vertex, xDirectionEnd: PV.Vertex): object {
        let plane: number[] = [planeEquation.a, planeEquation.b, planeEquation.c, planeEquation.d];
        return this.getPlaneCoordinateSystem(plane, xDirectionStart, xDirectionEnd);
    }

    public static getPlaneCoordinateSystem(plane: number[], xDirectionStart: PV.Vertex, xDirectionEnd: PV.Vertex): object {
        let RC = [] = [];
        let R: number[][] = [];
        let C: number[] = [];

        let startOnPlane: PV.Vertex = this.projectPointOnPlane(xDirectionStart, plane);
        let endOnPlane: PV.Vertex = this.projectPointOnPlane(xDirectionEnd, plane);

        let xX = endOnPlane.x - startOnPlane.x;
        let xY = endOnPlane.y - startOnPlane.y;
        let xZ = endOnPlane.z - startOnPlane.z;

        let norm: number = Math.sqrt(xX * xX + xY * xY + xZ * xZ);

        let rX: THREE.Vector3 = new THREE.Vector3(xX / norm, xY / norm, xZ / norm);
        let rZ: THREE.Vector3 = new THREE.Vector3(plane[0], plane[1], plane[2]);
        let rY: THREE.Vector3 = new THREE.Vector3(plane[0], plane[1], plane[2]);
        rY.cross(rX);

        R[0] = [];
        R[1] = [];
        R[2] = [];
        R[0].push(rX.x);
        R[0].push(rX.y);
        R[0].push(rX.z);
        R[1].push(rY.x);
        R[1].push(rY.y);
        R[1].push(rY.z);
        R[2].push(rZ.x);
        R[2].push(rZ.y);
        R[2].push(rZ.z);

        C[0] = startOnPlane.x;
        C[1] = startOnPlane.y;
        C[2] = startOnPlane.z;

        RC[0] = R;
        RC[1] = C;

        return RC;
    }

    public static projectPointOnPlaneUsingPlaneEquation(point: PV.Vertex, planeEquation: PV.PlaneEquation): PV.Vertex {
        let plane: number[] = [planeEquation.a, planeEquation.b, planeEquation.c, planeEquation.d];
        return this.projectPointOnPlane(point, plane);
    }

    public static projectPointOnPlane(point: PV.Vertex, planeParameters: number[]): PV.Vertex {
        let projectedPoint: PV.Vertex = new PV.Vertex();
        let distance = point.x * planeParameters[0] + point.y * planeParameters[1] + point.z * planeParameters[2] + planeParameters[3];
        projectedPoint.x = point.x - distance * planeParameters[0];
        projectedPoint.y = point.y - distance * planeParameters[1];
        projectedPoint.z = point.z - distance * planeParameters[2];
        return projectedPoint;
    }

    public static convertPointCoordinateSystem(point: PV.Vertex, R: number[][], C: number[]): PV.Vertex {
        let coordinates: number[] = [];
        for (let i = 0; i < 3; i++) {
            coordinates[i] = R[i][0] * (point.x - C[0]) + R[i][1] * (point.y - C[1]) + R[i][2] * (point.z - C[2]);
        }
        let transformedVertex: PV.Vertex = new PV.Vertex();
        transformedVertex.x = coordinates[0];
        transformedVertex.y = coordinates[1];
        transformedVertex.z = coordinates[2];
        return transformedVertex;
    }

    public static convertPointCoordinateSystemInverse(point: PV.Vertex, R: number[][], C: number[]): PV.Vertex {
        let coordinates: number[] = [];
        for (let i = 0; i < 3; i++) {
            coordinates[i] = R[0][i] * point.x + R[1][i] * point.y + R[2][i] * point.z + C[i];
        }
        let transformedVertex: PV.Vertex = new PV.Vertex();
        transformedVertex.x = coordinates[0];
        transformedVertex.y = coordinates[1];
        transformedVertex.z = coordinates[2];
        return transformedVertex;
    }

    public static getPlaneVertices(planeVertexIds: number[], vertexMap: PV.VertexMap): PV.Vertex[] {
        let vertices: PV.Vertex[] = [];

        for (let i = 0; i < planeVertexIds.length; i++) {
            let searchKey = planeVertexIds[i];
            let key: any;
            for (key in vertexMap) {
                if (key == searchKey) {
                    vertices.push(vertexMap[key]);
                    break;
                }
            }
        }

        return vertices;
    }


    public static calculatePlaneParameters(planeVertices: PV.Vertex[], gravityVector: Number[]): PV.PlaneEquation {
        let planeParameters: PV.PlaneEquation = new PV.PlaneEquation(0, 0, 0, 0);
        /*if (planeVertices.length > 3)
        {
            let matrix: number[][] = [];
            for (let i = 0; i < planeVertices.length; i++)
            {
                let point: PV.Vertex = planeVertices[i];
                matrix.push([point.x,point.y,point.z,1.0]);
            }

            let res = numeric.svd(matrix);
            let param=[res.V[0][3],res.V[1][3],res.V[2][3],res.V[3][3]];
            let norm = Math.sqrt((param[0] * param[0]) + (param[1] * param[1]) + (param[2] * param[2]));

            planeParameters.a = param[0]/norm;
            planeParameters.b = param[1]/norm;
            planeParameters.c = param[2]/norm;
            planeParameters.d = param[3]/norm;
        }
        else if (planeVertices.length == 3)*/
        {
            let v1: PV.Vertex = planeVertices[0];
            let v2: PV.Vertex = planeVertices[1];
            let v3: PV.Vertex = planeVertices[2];

            let a: number = (v2.y - v1.y) * (v3.z - v1.z) - (v3.y - v1.y) * (v2.z - v1.z);
            let b: number = (v2.z - v1.z) * (v3.x - v1.x) - (v3.z - v1.z) * (v2.x - v1.x);
            let c: number = (v2.x - v1.x) * (v3.y - v1.y) - (v3.x - v1.x) * (v2.y - v1.y);
            let d: number = -(a * v1.x + b * v1.y + c * v1.z);
            let norm = Math.sqrt(a * a + b * b + c * c);

            planeParameters.a = a / norm;
            planeParameters.b = b / norm;
            planeParameters.c = c / norm;
            planeParameters.d = d / norm;
        }

        return planeParameters;
    }

    public static alignPlaneParameterEquationWRTGravity(planeEquation: PV.PlaneEquation, gravityPlane: number[]): PV.PlaneEquation {
        let plane: number[] = [planeEquation.a, planeEquation.b, planeEquation.c, planeEquation.d];
        let aligned: number[] = this.alignPlaneParametersWRTGravity(plane, gravityPlane);
        return new PV.PlaneEquation(aligned[0], aligned[1], aligned[2], aligned[3]);
    }

    public static alignPlaneParametersWRTGravity(planeParameters: number[], gravityPlane: number[]): number[] {
        let alignedPlaneParameters: number[] = [];

        let dotProduct: number = planeParameters[0] * gravityPlane[0] + planeParameters[1] * gravityPlane[1] + planeParameters[2] * gravityPlane[2];

        if (dotProduct > 0) {
            alignedPlaneParameters = planeParameters;
        }
        else {
            alignedPlaneParameters[0] = (-1 * planeParameters[0]);
            alignedPlaneParameters[1] = (-1 * planeParameters[1]);
            alignedPlaneParameters[2] = (-1 * planeParameters[2]);
            alignedPlaneParameters[3] = (-1 * planeParameters[3]);
        }

        return alignedPlaneParameters;
    }

    public static pointDistanceFromPlane(point: PV.Vertex, plane: number[]): number {
        let distance: number = Math.abs(plane[0] * point.x + plane[1] * point.y + plane[2] * point.z + plane[3]);
        return distance;
    }

    public static pointDistanceFromPlaneEquation(point: PV.Vertex, planeEquation: PV.PlaneEquation): number {
        let plane: number[] = [planeEquation.a, planeEquation.b, planeEquation.c, planeEquation.d]
        let distance: number = this.pointDistanceFromPlane(point, plane);
        return distance;
    }

    public static pointAngleDistanceFromPlaneEquation(basePoint: PV.Vertex, planeVertices: PV.Vertex[], planeEquation: PV.PlaneEquation): number {
        let plane: number[] = [planeEquation.a, planeEquation.b, planeEquation.c, planeEquation.d];
        let distance: number = this.pointAngleDistanceFromPlane(basePoint, planeVertices, plane);
        return distance;
    }

    public static pointAngleDistanceFromPlane(basePoint: PV.Vertex, planeVertices: PV.Vertex[], plane: number[]): number {
        let minAngle: number = Number.MAX_SAFE_INTEGER;
        let numZero = 0;
        for (let i = 0; i < planeVertices.length; i++) {
            if (planeVertices.length === 2 && i === 1)
                break;

            let index1: number = i;
            let index2: number = i + 1;
            if (index2 == planeVertices.length)
                index2 = 0;

            let u1: number = basePoint.x - planeVertices[index1].x;
            let u2: number = basePoint.y - planeVertices[index1].y;
            let u3: number = basePoint.z - planeVertices[index1].z;

            let v1: number = basePoint.x - planeVertices[index2].x;
            let v2: number = basePoint.y - planeVertices[index2].y;
            let v3: number = basePoint.z - planeVertices[index2].z;

            if (Math.abs(u1) < 1e-6 || Math.abs(u2) < 1e-6 || Math.abs(u3) < 1e-6 ||
                Math.abs(v1) < 1e-6 || Math.abs(v2) < 1e-6 || Math.abs(v3) < 1e-6) {
                numZero++;
                continue;
            }

            let crossX: number = u2 * v3 - u3 * v2;
            let crossY: number = u3 * v1 - u1 * v3;
            let crossZ: number = u1 * v2 - u2 * v1;
            let crossNorm: number = Math.sqrt(crossX * crossX + crossY * crossY + crossZ * crossZ);
            crossX /= crossNorm;
            crossY /= crossNorm;
            crossZ /= crossNorm;

            let cosTheta: number = plane[0] * crossX + plane[1] * crossY + plane[2] * crossZ;
            let angle: number = Math.acos(Math.min(Math.max(cosTheta, -1.0), 1.0));
            angle *= 180 / Math.PI;

            if (angle >= 90)
                angle = 180 - angle;

            if (angle < minAngle)
                minAngle = angle;
        }
        if (numZero == planeVertices.length) return 0;
        return minAngle;
    }

    public static applyTransform(vertex: PV.Vertex, transformationMatrix: number[][]): PV.Vertex {
        let vxTransformed: number = transformationMatrix[0][0] * vertex.x + transformationMatrix[0][1] * vertex.y + transformationMatrix[0][2] * vertex.z + transformationMatrix[0][3];
        let vyTransformed: number = transformationMatrix[1][0] * vertex.x + transformationMatrix[1][1] * vertex.y + transformationMatrix[1][2] * vertex.z + transformationMatrix[1][3];
        let vzTransformed: number = transformationMatrix[2][0] * vertex.x + transformationMatrix[2][1] * vertex.y + transformationMatrix[2][2] * vertex.z + transformationMatrix[2][3];

        let vert: PV.Vertex = new PV.Vertex();
        vert.x = vxTransformed;
        vert.y = vyTransformed;
        vert.z = vzTransformed;
        return vert;
    }

    public static angleBetweenTwoPlanes(plane1: number[], plane2: number[]): number {
        let cosTheta: number = plane1[0] * plane2[2] + plane1[1] * plane2[1] + plane1[2] * plane2[2];
        let angle: number = Math.acos(Math.min(Math.max(cosTheta, -1.0), 1.0));
        angle *= 180 / Math.PI;
        return angle;
    }

    public static findRepetetiveVertices(vertices: THREE.Vector2[], span: number[], tolerance: number): boolean {
        for (let i = 0; i < vertices.length; i++) {
            for (let j = i + 1; j < vertices.length; j++) {
                let distance: number = Math.sqrt((vertices[i].x - vertices[j].x) * (vertices[i].x - vertices[j].x) + (vertices[i].y - vertices[j].y) * (vertices[i].y - vertices[j].y));
                if (distance < tolerance) {
                    span[0] = i;
                    span[1] = j;
                    return true;
                }
            }
        }
        return false;
    }

    public static arePointsCollinear(point1: PV.Vertex, point2: PV.Vertex, point3: PV.Vertex, toleranceAngle: number): boolean {
        let direction1: number[] = [];
        direction1[0] = point2.x - point1.x;
        direction1[1] = point2.y - point1.y;
        direction1[2] = point2.z - point1.z;

        let norm: number = Math.sqrt(direction1[0] * direction1[0] + direction1[1] * direction1[1] + direction1[2] * direction1[2]);
        direction1[0] /= norm;
        direction1[1] /= norm;
        direction1[2] /= norm;

        let direction2: number[] = [];
        direction2[0] = point3.x - point1.x;
        direction2[1] = point3.y - point1.y;
        direction2[2] = point3.z - point1.z;

        norm = Math.sqrt(direction2[0] * direction2[0] + direction2[1] * direction2[1] + direction2[2] * direction2[2]);
        direction2[0] /= norm;
        direction2[1] /= norm;
        direction2[2] /= norm;

        let angle: number = this.acuteAngleBetweenTwoLines3D(direction1, direction2);
        if (angle < toleranceAngle)
            return true;
        else
            return false;
    }

    public static acuteAngleBetweenTwoLines3D(normDirection1: number[], normDirection2: number[]): number {
        let cosTheta: number = normDirection1[0] * normDirection2[0] + normDirection1[1] * normDirection2[1] + normDirection1[2] * normDirection2[2];
        let angle: number = Math.acos(Math.min(Math.max(cosTheta, -1.0), 1.0));
        angle *= 180 / Math.PI;
        if (angle >= 90)
            angle = 180 - angle;
        return angle;
    }

    public static projectVerticesOntoPlaneUsingGravityVector(vertices: PV.Vertex[], plane: number[], gravityVector: number[]): PV.Vertex[] {
        let projectedVertices: PV.Vertex[] = [];

        for (let i = 0; i < vertices.length; i++) {
            let a: number = plane[0];
            let b: number = plane[1];
            let c: number = plane[2];
            let d: number = plane[3];

            let vertex: PV.Vertex = vertices[i];

            let t: number = -(a * vertex.x + b * vertex.y + c * vertex.z + d) / (a * gravityVector[0] + b * gravityVector[1] + c * gravityVector[2]);
            let x: number = vertex.x + gravityVector[0] * t;
            let y: number = vertex.y + gravityVector[1] * t;
            let z: number = vertex.z + gravityVector[2] * t;

            let vert: PV.Vertex = new PV.Vertex();
            vert.x = x;
            vert.y = y;
            vert.z = z;
            projectedVertices.push(vert);
        }

        return projectedVertices;
    }

    public static getTransformedVertices3D(vertices: PV.VertexMap, sceneTransform: THREE.Matrix4): PV.VertexMap {
        let transformedVertices: PV.VertexMap = {};

        /*
        let R: number[][] = [];
        let row1: number[] = [orthographicTransform.rotation[0], orthographicTransform.rotation[1], orthographicTransform.rotation[2]];
        let row2: number[] = [orthographicTransform.rotation[3], orthographicTransform.rotation[4], orthographicTransform.rotation[5]];
        let row3: number[] = [orthographicTransform.rotation[6], orthographicTransform.rotation[7], orthographicTransform.rotation[8]];
        R.push(row1);
        R.push(row2);
        R.push(row3);

        let C: number[] = [orthographicTransform.translation[0], orthographicTransform.translation[1], orthographicTransform.translation[2]];
        */
        let v = new THREE.Vector3()
        for (let key in vertices) {
            let pvVert = vertices[key]
            //let newVertex = this.convertPointCoordinateSystem(vertices[key], R, C);
            v.set(pvVert.x, pvVert.y, pvVert.z)
            v.applyMatrix4(sceneTransform)
            let transVert = new PV.Vertex()
            transVert.x = v.x
            transVert.y = v.y
            transVert.z = v.z
            transformedVertices[key] = transVert
        }
        return transformedVertices;
    }

    public static normalizeVector(vector: number[]): void {
        let norm: number = 0;
        for (let i = 0; i < vector.length; i++) {
            norm += vector[i] * vector[i];
        }
        norm = Math.sqrt(norm);
        for (let i = 0; i < vector.length; i++) {
            vector[i] /= norm;
        }
    }

    public static angleBetweenTwoUnitVectors2D(v1: number[], v2: number[]): number {
        let cosTheta: number = v1[0] * v2[0] + v1[1] * v2[1];
        let angle: number = Math.acos(Math.min(Math.max(cosTheta, -1.0), 1.0));
        angle *= (180 / Math.PI);
        return angle;
    }

    public static calculateCentroid(vertices2D: THREE.Vector2[]): THREE.Vector2 {
        let sumX: number = 0;
        let sumY: number = 0;
        for (let i = 0; i < vertices2D.length; i++) {
            sumX += vertices2D[i].x;
            sumY += vertices2D[i].y;
        }
        return new THREE.Vector2(sumX / vertices2D.length, sumY / vertices2D.length);
    }

}