import {InteractiveModel} from "../interactive/interactive.model";
import {EventEmitter} from "@angular/core";
import {ProjectImageModel} from "../projectImages/projectImage.model";

export interface ProjectImageListSort {
    sortFunction: (a:ProjectImageModel, b:ProjectImageModel) => boolean;
}

export class ProjectImageCollectionSortModel extends InteractiveModel implements ProjectImageListSort {
    sortFunction: (a:ProjectImageModel, b:ProjectImageModel) => boolean;
    sortUpdated = new EventEmitter<ProjectImageCollectionSortModel>();

    constructor() {
        super();
    }

    sort(projectImageModelA: ProjectImageModel, projectImageModelB: ProjectImageModel) {
        if (!this.sortFunction) return false;
        return this.sortFunction(projectImageModelA, projectImageModelB);
    }

    triggerSortUpdated(){
        this.sortUpdated.emit(this);
    }
}