import {
    Component, OnDestroy, ViewChild
} from '@angular/core';
import {
    SceneService
} from "../../services/scenes/scene.service";
import {WireframeElementType} from "../../application/wireframeElementType";
import {InteractiveCollection} from "../../collections/interactive/interactive.collection";
import {VertexElementModel} from "../../models/vertexElements/vertexElement.model";
import {VertexElement} from "../../application/vertexElement";
import {InteractiveDataViewModel} from "../../models/interactive/interactiveDataView.model";
import {InteractiveSortModel} from "../../models/interactive/interactiveSort.model";
import {InteractiveFilterExpressionModel} from "../../models/interactive/interactiveFilterExpression.model";
import {InteractiveOperationEventModel} from "../../models/interactive/interactiveOperationEvent.model";
import {InteractiveSelectEventModel} from "../../models/interactive/interactiveSelectEvent.model";
import {WireframeElement} from "../../application/wireframeElement";
import VertexPreviewListComponent from "../vertexPreviewList/vertexPreviewList.component";

@Component({
    selector: 'ptv-vertex-preview-manager',
    styles: [require('./vertexPreviewManager.component.scss')],
    template: require('./vertexPreviewManager.component.html')
})
export default class VertexPreviewManagerComponent implements OnDestroy {
    @ViewChild(VertexPreviewListComponent, {static: false}) vertexPreviewListComponent;
    vertexElementCollection: InteractiveCollection<VertexElementModel>;
    interactiveDataViewModel: InteractiveDataViewModel<VertexElementModel>;
    vertexElementModelHighlightedChangedSubscription;
    vertexElementModelSelectedChangedSubscription;
    vertexElementModelFocusedChangedSubscription;
    operationStartedSubscription;
    operationEndedSubscription;
    sceneServiceWireframeChangedSubscription;
    sceneServiceWireframeElementHighlightedSubscription;
    sceneServiceWireframeElementSelectedSubscription;
    sceneServiceWireframeElementFocusedSubscription;

    thumbnailType:string = 'tiles';

    constructor(
        private sceneService: SceneService
    ) {
        // vertex element collection and data view
        this.vertexElementCollection = new InteractiveCollection<VertexElementModel>();
        this.interactiveDataViewModel = new InteractiveDataViewModel<VertexElementModel>(
            this.vertexElementCollection,
            new InteractiveSortModel<VertexElementModel>(),
            new InteractiveFilterExpressionModel<VertexElementModel>([])
        );

        // events
        this.vertexElementModelHighlightedChangedSubscription = this.vertexElementCollection.highlightedChanged.subscribe(this.vertexElementModelHighlightedChanged.bind(this));
        this.vertexElementModelSelectedChangedSubscription = this.vertexElementCollection.selectedChanged.subscribe(this.vertexElementModelSelectedChanged.bind(this));
        this.vertexElementModelFocusedChangedSubscription = this.vertexElementCollection.focusedChanged.subscribe(this.vertexElementModelFocusedChanged.bind(this));
        this.operationStartedSubscription = this.vertexElementCollection.operationStarted.subscribe(this.onOperationStarted.bind(this));
        this.operationEndedSubscription = this.vertexElementCollection.operationEnded.subscribe(this.onOperationEnded.bind(this));
        this.sceneServiceWireframeChangedSubscription = this.sceneService.wireframeChanged.subscribe(this.onSceneServiceWireframeChanged.bind(this));
        this.sceneServiceWireframeElementHighlightedSubscription = this.sceneService.wireframeElementHighlighted.subscribe(this.onSceneServiceWireframeElementHighlighted.bind(this));
        this.sceneServiceWireframeElementSelectedSubscription = this.sceneService.wireframeElementSelected.subscribe(this.onSceneServiceWireframeElementSelected.bind(this));
        this.sceneServiceWireframeElementFocusedSubscription = this.sceneService.wireframeElementFocused.subscribe(this.onSceneServiceWireframeElementFocused.bind(this));

        this.updateVertexCollection();
    }

    ngOnDestroy(): void {
        if (this.vertexElementModelHighlightedChangedSubscription) this.vertexElementModelHighlightedChangedSubscription.unsubscribe();
        if (this.vertexElementModelSelectedChangedSubscription) this.vertexElementModelSelectedChangedSubscription.unsubscribe();
        if (this.vertexElementModelFocusedChangedSubscription) this.vertexElementModelFocusedChangedSubscription.unsubscribe();
        if (this.operationStartedSubscription) this.operationStartedSubscription.unsubscribe();
        if (this.operationEndedSubscription) this.operationEndedSubscription.unsubscribe();
        if (this.sceneServiceWireframeChangedSubscription) this.sceneServiceWireframeChangedSubscription.unsubscribe();
        if (this.sceneServiceWireframeElementHighlightedSubscription) this.sceneServiceWireframeElementHighlightedSubscription.unsubscribe();
        if (this.sceneServiceWireframeElementSelectedSubscription) this.sceneServiceWireframeElementSelectedSubscription.unsubscribe();
        if (this.sceneServiceWireframeElementFocusedSubscription) this.sceneServiceWireframeElementFocusedSubscription.unsubscribe();
    }

    updateVertexCollection(){
        this.vertexElementCollection.clear();
        let wireframeElements = this.sceneService.getWireframeElements();
        let wireframe = this.sceneService.wireframeApplication.wireframe;
        if (wireframeElements) {
            let confidenceProperty = this.sceneService.wireframeApplication.wireframe.findPropertyByName("confidence");
            wireframeElements.sort(function(a,b) {
                let aConfidence = Number(confidenceProperty.getValue(a.pvObject)) || 0;
                let bConfidence = Number(confidenceProperty.getValue(b.pvObject)) || 0;

                return bConfidence - aConfidence;
            });

            wireframeElements.forEach((wireframeElement) => {
                if (wireframeElement.elementType == WireframeElementType.vertex) {
                    let vertexElement = wireframeElement as VertexElement;
                    let vertexElementModel = new VertexElementModel(vertexElement, wireframe);
                    this.vertexElementCollection.add(vertexElementModel);
                }
            })
        }
    }

    findVertexElementModel(vertexElement: VertexElement): VertexElementModel {
        let vertexElementModel: VertexElementModel = null;
        if (!this.vertexElementCollection) return vertexElementModel;
        this.vertexElementCollection.forEach((currentVertexElementModel) => {
            if (!currentVertexElementModel.vertexElement) return;
            if (currentVertexElementModel.vertexElement.toString() == vertexElement.toString()) {
                vertexElementModel = currentVertexElementModel;
            }
        });
        return vertexElementModel;
    }

    vertexElementModelHighlightedChanged(vertexElementModel: VertexElementModel) {
        if (!vertexElementModel) return;
        if (!this.sceneService) return;

        this.sceneService.unHighlightAllSceneWireframeElements();

        if (vertexElementModel.highlighted) {
            this.sceneService.setSceneHighlightedWireframeElements(vertexElementModel.vertexElement);
        }
    }

    vertexElementModelSelectedChanged(selectEventModel: InteractiveSelectEventModel<VertexElementModel>) {
        if (!selectEventModel) return;
        let vertexElementModel = selectEventModel.interactiveModel;
        if (!vertexElementModel) return;
        if (!this.sceneService) return;

        this.sceneService.unselectAllSceneWireframeElements();

        if (vertexElementModel.selected) {
            this.sceneService.setSceneSelectedWireframeElements([vertexElementModel.vertexElement]);
        }
    }

    vertexElementModelFocusedChanged(vertexElementModel: VertexElementModel) {
        if (!vertexElementModel) return;
        if (!this.sceneService) return;

        this.sceneService.unselectAllSceneWireframeElements();
        this.sceneService.unfocusAllSceneWireframeElements();

        if (vertexElementModel.focused) {
            if (this.vertexPreviewListComponent) {
                this.vertexPreviewListComponent.scrollToInteractiveModel(vertexElementModel);
            }
            this.sceneService.setSceneSelectedWireframeElements([vertexElementModel.vertexElement]);
            this.sceneService.setSceneFocusedWireframeElement(vertexElementModel.vertexElement);
            this.sceneService.lookAtSceneWireframeElements([vertexElementModel.vertexElement]);
        }
    }

    onSceneServiceWireframeChanged() {
        // this.updateVertexCollection();
    }

    onSceneServiceWireframeElementHighlighted(wireframeElement: WireframeElement) {
        if (!wireframeElement) return;
        let vertexElement = wireframeElement as VertexElement;
        if (!vertexElement) return;
        let vertexElementModel = this.findVertexElementModel(vertexElement);
        if (!vertexElementModel) return;
        if (vertexElementModel.highlighted != wireframeElement.isHighlighted) vertexElementModel.highlighted = wireframeElement.isHighlighted;
    }

    onSceneServiceWireframeElementSelected(wireframeElementArray: Array<WireframeElement>) {
        if (!wireframeElementArray) return;
        wireframeElementArray.forEach((wireframeElement) =>{
            if(!wireframeElement) return;
            let vertexElement = wireframeElement as VertexElement;
            if(!vertexElement) return;
            let vertexElementModel = this.findVertexElementModel(vertexElement);
            if(!vertexElementModel) return;
            if(vertexElementModel.selected != wireframeElement.isSelected) {
                vertexElementModel.selected = wireframeElement.isSelected;
                if(vertexElementModel.selected){
                    vertexElementModel.vertexElement.isFocused = true;
                    this.onSceneServiceWireframeElementFocused(vertexElementModel.vertexElement);
                }
            }
        });
    }

    onSceneServiceWireframeElementFocused(wireframeElement: WireframeElement) {
        if (!wireframeElement) return;
        let vertexElement = wireframeElement as VertexElement;
        if (!vertexElement) return;
        let vertexElementModel = this.findVertexElementModel(vertexElement);
        if (!vertexElementModel) return;
        if (vertexElementModel.focused != wireframeElement.isFocused) {
            vertexElementModel.focused = wireframeElement.isFocused;
        }

    }

    onOperationStarted(operation: InteractiveOperationEventModel<VertexElementModel>) {

    }

    onOperationEnded(operation: InteractiveOperationEventModel<VertexElementModel>) {
        switch (operation.interactiveOperationModel.operation) {
            case 'propertyChange':
                this.interactiveDataViewModel.reapplyFilter();
                break;
        }
    }

    onViewChanged(viewType:string){
        // for now, views are only thumbnails
        switch (viewType) {
            case 'tiles':
                this.thumbnailType = viewType;
                break;
            case 'cameras':
                this.thumbnailType = viewType;
                break;
        }
    }

}