import {WireframeApplication} from "../../application/wireframeApplication";
import {PV} from '../../wireframe'
import {WireframeOperations} from "../../wireframeOperations";
import {WireframeElementType} from "../../application/wireframeElementType";
import {WireframeElement} from "../../application/wireframeElement";
import {ElementPickOperation} from "../../application/elementPickOperation";
import {PropertyValue} from "./propertyValue";
import {DoubleProperty} from "./doubleProperty";
import {IntegerProperty} from "./integerProperty";
import {ElementReferenceProperty} from "./elementReferenceProperty";
import {PolygonShapePropertyValue} from "./polygonShapePropertyValue";
import Edge = PV.Edge;
import {PlaneElement} from "../../application/planeElement";
import {EdgeElement} from "../../application/edgeElement";
import {VertexElement} from "../../application/vertexElement";
import * as THREE from 'three';
import {CompoundProperty} from "./compoundProperty";
import {ElementReference} from "./elementReference";

export class PolygonShapeProperty extends CompoundProperty {
    numSidesProperty: IntegerProperty;
    xScaleProperty: DoubleProperty;
    yScaleProperty: DoubleProperty;
    rotationProperty: DoubleProperty;
    alignEdgeProperty: ElementReferenceProperty;

    constructor() {
        super();
        this.type = ".PolygonShapeProperty";
        this.name = "Polygon Shape";
        this.allowMultiplePerElement = false;
        this.allowableElementTypes = [WireframeElementType.plane];
        this.numSidesProperty = new IntegerProperty();
        this.numSidesProperty.name = "NumSides";
        this.numSidesProperty.min = 3;
        this.numSidesProperty.defaultValue = 4;
        this.numSidesProperty.max = 128;

        this.rotationProperty = new DoubleProperty();
        this.rotationProperty.name = "Rotation";
        this.rotationProperty.min = -360;
        this.rotationProperty.max = 360;
        this.rotationProperty.defaultValue = 0;
        this.rotationProperty.step = 1;

        this.xScaleProperty = new DoubleProperty();
        this.xScaleProperty.name = "xScale";
        this.xScaleProperty.min = 0.0001;
        this.xScaleProperty.defaultValue = 1;
        this.xScaleProperty.max = Number.MAX_VALUE;

        this.yScaleProperty = new DoubleProperty();
        this.yScaleProperty.name = "yScale";
        this.yScaleProperty.min = 0.0001;
        this.yScaleProperty.defaultValue = 1;
        this.yScaleProperty.max = Number.MAX_VALUE;

        this.alignEdgeProperty = new ElementReferenceProperty();
        this.alignEdgeProperty.name = "alignEdge";
        this.alignEdgeProperty.label = "Alignment Edge";
        this.alignEdgeProperty.selectableElementTypes = [WireframeElementType.edge];

        this.childProperties.push(this.numSidesProperty);
        this.childProperties.push(this.rotationProperty);
        this.childProperties.push(this.xScaleProperty);
        this.childProperties.push(this.yScaleProperty);
        this.childProperties.push(this.alignEdgeProperty);
    }

    fromJson(obj, allProperties): void {
        super.fromJson(obj, allProperties);
        this.numSidesProperty = this.childProperties[0] as IntegerProperty;
        this.rotationProperty = this.childProperties[1] as DoubleProperty;
        this.xScaleProperty = this.childProperties[2] as DoubleProperty;
        this.yScaleProperty = this.childProperties[3] as DoubleProperty;
        this.alignEdgeProperty = this.childProperties[4] as ElementReferenceProperty;
    }

    onAddToWireframe() {
        this.alignEdgeProperty.customizePickOperation = function (po: ElementPickOperation) {
            po.message = "Please select an Edge to align with, or Escape to cancel";
            po.isElementPickable.push(function (el: WireframeElement) {
                return el.pvObject.pvType == WireframeElementType.edge;
            })
        }
    }


    getDefaultValue() {
        return new PolygonShapePropertyValue();
    }


    apply(el: PlaneElement, pv: PropertyValue) {
        //console.log("apply polygon shape", el);
        let layer = el.wireframeLayer
        let plane: PV.Plane = el.pvObject;

        //app.wireframe.orderVertexIds(plane);
        //app.wireframe.orderVertexIdsFromEdges(plane);
        let val: PolygonShapePropertyValue = pv.value as PolygonShapePropertyValue;

        // create a clone to avoid reference issues
        val = val.clone();

        let numSides = (val.NumSides);
        let scaleX = (val.xScale);
        let scaleY = (val.yScale);
        let rotation = (val.Rotation) * Math.PI / 180;
        let center: THREE.Vector3 = el.getCenter();

        //console.log("plane normal ", plane3.normal);

        //console.log("pre  vertex ids", plane.vertexIds.toString());
        if (numSides != plane.vertexIds.length) {
            let newVerts = [];
            let newEdges = [];
            for (let i = 0; i < numSides; i++) {
                if (plane.vertexIds.length > i) {
                    newVerts.push(plane.vertexIds[i]);
                } else {
                    let vert = new PV.Vertex();
                    layer.wireframe.addVertex(vert);
                    layer.addVertex(vert);
                    newVerts.push(vert.id);
                }
            }
            for (let i = 0; i < numSides; i++) {
                let edge: Edge = layer.wireframe.findEdge(newVerts[i], newVerts[(i + 1) % newVerts.length]);
                if (!edge) {
                    edge = new PV.Edge(newVerts[i], newVerts[(i + 1) % newVerts.length]);
                    layer.wireframe.addEdge(edge);
                    layer.addEdge(edge, null);
                }
                newEdges.push(edge.id);
            }

            let vertsToDelete: number[] = plane.vertexIds.filter(function (vertId) {
                return !newVerts.includes(vertId);
            });
            let edgesToDelete: number[] = plane.edgeIds.filter(function (edgeId) {
                return !newEdges.includes(edgeId);
            });
            for (let edgeId of edgesToDelete) {
                layer.deleteEdge(layer.findWireframeElement(WireframeElementType.edge, edgeId) as EdgeElement, false);
            }

            for (let vertId of vertsToDelete) {
                layer.deleteVertex(layer.findWireframeElement(WireframeElementType.vertex, vertId) as VertexElement, false);
            }

            plane.vertexIds = newVerts;
            layer.createPlaneEdgesFromVerts(plane);
            //plane.edgeIds = newEdges;
            //app.wireframe.orderVertexIdsFromEdges(plane);
        }
        //app.wireframe.orderVertexIds(plane);
        layer.updatePlaneEquation(plane);
        let plane3: THREE.Plane = layer.wireframe.getPlane3(plane.id);
        let parentPlane: PV.Plane = null;
        if (null != plane.parentId) {
            parentPlane = layer.wireframe.planes[plane.parentId];
            if (parentPlane) {
                layer.updatePlaneEquation(parentPlane);
                plane3 = layer.wireframe.getPlane3(plane.parentId);
            }
        } 
        if (!plane3) {
            layer.updatePlaneEquation(plane);
            plane3 = layer.wireframe.getPlane3(plane.id);
        }
        let planeRot: THREE.Quaternion = new THREE.Quaternion();
        planeRot.setFromUnitVectors(new THREE.Vector3(0, 0, 1), plane3.normal);

        //console.log("post vertex ids", plane.vertexIds.toString());
        let angleIncrement = 2 * Math.PI / numSides;
        let radius = 1;
        {
            //let tmp = Math.PI / numSides;
            let sideLengthHalf = Math.tan(Math.PI / numSides);
            radius = Math.sqrt(1 + (sideLengthHalf * sideLengthHalf));
            //console.log("tmp / sideLengthHalf / radius " + tmp + " / " + sideLengthHalf + " / " + radius);
        }
        for (let i = 0; i < plane.vertexIds.length; i++) {
            let v: PV.Vertex = layer.wireframe.vertices[plane.vertexIds[i]];
            let angle = (angleIncrement * i) + (angleIncrement / 2);
            let v3 = new THREE.Vector3(scaleX * radius * Math.cos(angle), scaleY * radius * Math.sin(angle), 0);
            //v3 = v3.applyMatrix4(offsetRot);
            v3.applyQuaternion(planeRot)
            layer.wireframe.setVert3(plane.vertexIds[i], v3);
        }

        // orient shape
        let alignVec: THREE.Vector3 = null;
        try {
            let alignEdge: PV.Edge = val.alignEdge.getElements(layer)[0] as PV.Edge
            let edgeVerts = [plane3.projectPoint(layer.wireframe.getVert3(alignEdge.vertex1Id), new THREE.Vector3()),
                plane3.projectPoint(layer.wireframe.getVert3(alignEdge.vertex2Id), new THREE.Vector3())
            ];
            alignVec = new THREE.Vector3().subVectors(edgeVerts[1], edgeVerts[0]);


            let parentPlane: PV.Plane = layer.wireframe.planes[plane.parentId];
            if (parentPlane) {
                if (parentPlane.edgeIds.find(function (edgeId) {
                    return edgeId == alignEdge.id;
                })) {
                    for (let i = 0; i < parentPlane.vertexIds[i]; i++) {
                        if (parentPlane.vertexIds[i] == alignEdge.vertex1Id) {
                            if (parentPlane.vertexIds[(i + 1) % parentPlane.vertexIds.length] != alignEdge.vertex2Id) {
                                alignVec.negate();
                                break
                            }
                        }
                    }
                }
            }

            alignVec.normalize();
        } catch (e) {
        }

        if (!alignVec) {
            // make consistent with SceneEditor orientation for top-level polygons
            alignVec = new THREE.Vector3(1, 0, 0)
        }
        let offsetRot: THREE.Quaternion = new THREE.Quaternion();
        offsetRot.setFromAxisAngle(plane3.normal, rotation);

        //console.log("parentAnchorVerts " + parentPlane.vertexIds[0] + "/" + parentPlane.vertexIds[1] + " => " + plane.vertexIds[0] + "/" + plane.vertexIds[1]);
        let shapeEdgeVec: THREE.Vector3 = new THREE.Vector3().subVectors(layer.wireframe.getVert3(plane.vertexIds[plane.vertexIds.length - 1]), layer.wireframe.getVert3(plane.vertexIds[0]));
        shapeEdgeVec.normalize();
        //if (shapeEdgeVec.dot(alignVec) < 0) alignVec.negate();
        let orientRot: THREE.Quaternion = new THREE.Quaternion();
        orientRot.setFromUnitVectors(shapeEdgeVec, alignVec);
        orientRot = orientRot.multiply(offsetRot);
        for (let i = 0; i < plane.vertexIds.length; i++) {
            let v3: THREE.Vector3 = layer.wireframe.getVert3(plane.vertexIds[i]);
            v3.applyQuaternion(orientRot)
            layer.wireframe.setVert3(plane.vertexIds[i], v3);
        }

        // translate shape
        for (let i = 0; i < plane.vertexIds.length; i++) {
            let v3 = layer.wireframe.getVert3(plane.vertexIds[i]);
            v3.add(center);
            layer.wireframe.setVert3(plane.vertexIds[i], v3);
        }
    }

    moveVertex(vert:VertexElement, val: PolygonShapePropertyValue, planeEl: PlaneElement, newPos: THREE.Vector3) {
        let layer = vert.wireframeLayer
        let oldPos: THREE.Vector3 = layer.wireframe.getVert3(vert.pvObject.id);
        let moveVec: THREE.Vector3 = new THREE.Vector3().subVectors(newPos, oldPos);

        //console.log("polyVerts " + poly.vertexIds.toString());
        let reposVec: THREE.Vector3 = moveVec.clone().divideScalar(2);
        let center: THREE.Vector3 = new THREE.Vector3();
        for (let vertId of planeEl.pvObject.vertexIds) {
            center.add(layer.wireframe.getVert3(vertId));
        }
        center.divideScalar(planeEl.pvObject.vertexIds.length);
        let verts: THREE.Vector3[] = [];
        for (let vertId of planeEl.pvObject.vertexIds) {
            verts.push(layer.wireframe.getVert3(vertId).sub(center));
        }

        // undo plane rotation
        let planeRot: THREE.Quaternion = new THREE.Quaternion();
        planeRot.setFromUnitVectors(layer.wireframe.getPlane3(planeEl.pvObject.id).normal, new THREE.Vector3(0, 0, 1));
        let xformMoveVec = moveVec.clone()
        xformMoveVec.applyQuaternion(planeRot)
        //planeRot.multiplyVector3(moveVec.clone());
        for (let i = 0; i < verts.length; i++) {
            verts[i].applyQuaternion(planeRot)
        }

        // undo z-axis rotation
        let yVec = new THREE.Vector3().subVectors(verts[0],
            verts[verts.length - 1]
        );
        yVec.normalize();
        let axisRot: THREE.Quaternion = new THREE.Quaternion();
        axisRot.setFromUnitVectors(yVec, new THREE.Vector3(0, 1, 0));
        xformMoveVec.applyQuaternion(axisRot)
        for (let i = 0; i < verts.length; i++) {
            verts[i].applyQuaternion(axisRot)
        }
        let moveVertPos = verts[planeEl.pvObject.vertexIds.indexOf(vert.pvObject.id)];
        val.xScale += Math.sign(moveVertPos.x) * (xformMoveVec.x / 2);
        val.yScale += Math.sign(moveVertPos.y) * (xformMoveVec.y / 2);
        val.xScale = Math.max(0.01, val.xScale);
        val.yScale = Math.max(0.01, val.yScale);

        for (let vertId of planeEl.pvObject.vertexIds) {
            let vertPos = layer.wireframe.getVert3(vertId);
            vertPos.add(reposVec);
            layer.wireframe.setVert3(vertId, vertPos);
        }

        planeEl.updateSupportingGeometry()
        /*
        this.apply(app.findWireframeElement(WireframeElementType.plane, poly.id), this.getPropertyValue(poly));
        let updatedPos = app.getVert3(moveVertId);

        let dist = newPos.distanceTo(updatedPos);
        if (dist > .001) {
            console.log("dist " + dist);
            console.log("reposVec", reposVec);
            console.log("moveVertex Target", newPos);
            console.log("moveVertex Result", updatedPos);
        }
        */
    }

    valueFromJson(json: any): any {
        let val = new PolygonShapePropertyValue()
        Object.assign(val, json)
        return val;
    }


}
