import {PropertyCriterion} from "../property/propertyCriterion";

export class DisplayRule {
    criterion: PropertyCriterion;
    color: number[] = [0, 0, 0, 0];

    constructor(c?: PropertyCriterion, col?: number[]) {
        this.criterion = c;
        this.color = col;
    }
}







