import {
    Component,
    Input,
    ChangeDetectorRef,
    ComponentFactoryResolver,
    Injector,
    ApplicationRef,
    ViewChild,
    ElementRef,
    AfterViewInit,
    OnDestroy, IterableDiffers, DoCheck
} from '@angular/core';
import * as L from 'leaflet'
if(!(window as any).L) (window as any).L = L;
import FreeDraw, {NONE, CREATE} from 'leaflet-freedraw';
import 'leaflet-draw';
import 'leaflet-easyprint/dist/bundle';
import 'leaflet-draw-drag';
import {AnnotationModel} from "../../models/annotations/annotation.model";
import {ProjectImageModel} from "../../models/projectImages/projectImage.model";
import {MatChipInputEvent, MatAutocompleteTrigger} from "@angular/material";
import AnnotationPopupComponent from "./popups/annotationPopup.component";
import ProjectImageCollection from "../../collections/projectImages/projectImage.collection";

const TAB = 9;
const ENTER = 13;

@Component({
    selector: 'annotation',
    template: require('./annotation.component.html'),
    styles: [require('./annotation.component.scss')]
})
export default class AnnotationComponent implements AfterViewInit, OnDestroy, DoCheck {
    @ViewChild("imageTagChipListInput", {static: false}) imageTagChipListInputRef: ElementRef;
    @ViewChild('annotationMap', {static: true}) annotationMapRef: ElementRef;
    @ViewChild(MatAutocompleteTrigger, {static: false}) trigger;
    @Input() projectImageModel: ProjectImageModel;
    @Input() projectImageCollection: ProjectImageCollection;
    @Input() annotationModels: Array<AnnotationModel> = [];
    annotationModelsDiffer;
    @Input() annotationClasses: Array<string> = [];
    annotationLoaded: boolean = false;
    annotationModified: boolean = false;
    separatorKeysCodes = [ENTER, TAB];
    geoJsonLayers: any = null;
    bounds: Number[][];
    imageOverlay:any;
    printImageOverlay:any;
    L: any;
    // freeDraw: FreeDraw;
    private map: any;
    private drawnItems: any;
    public annotationPopupComponentRef: any;
    private isDestroyed:boolean = false;
    displayedColumns: string[] = ['classes', 'memo', 'remove'];

    // change detection
    private changeDetectorInterval: any;
    private viewInitialized: boolean = false;

    constructor(
        private iterableDiffers: IterableDiffers,
        private changeDetectorRef: ChangeDetectorRef,
        private resolver: ComponentFactoryResolver,
        private injector: Injector,
        private applicationRef: ApplicationRef
    ) {
        this.L = (window as any).L;
        this.annotationModelsDiffer = this.iterableDiffers.find([]).create(null);
        this.changeDetectorRef.detach();
    }

    ngDoCheck(): void {
        let changes = this.annotationModelsDiffer.diff(this.annotationModels);
        if(changes){
            this.performUpdate();
        }
    }

    ngAfterViewInit(): void {
        let self = this;

        // bounds
        let imageWidth = self.projectImageModel.width;
        let imageHeight = self.projectImageModel.height;
        self.bounds = [[0, 0], [imageHeight, imageWidth]];

        // create a map with simple coordinates
        self.map = this.L.map(this.annotationMapRef.nativeElement, {
            crs: this.L.CRS.Simple,
            attributionControl: false,
            minZoom: -3,
            zoomSnap: .1
        }).fitBounds(self.bounds);

        if(this.L.easyPrint) {
            this.L.easyPrint({
                title: 'Save Image',
                filename: this.projectImageModel.filename + ".annotations",
                exportOnly: true,
                position: 'bottomleft'
            }).addTo(self.map);
        }

        // map print events
        self.map.on('easyPrint-start', ()=>{
            this.annotationMapRef.nativeElement.querySelector('.leaflet-control-container').style.display = 'none';
        });
        self.map.on('easyPrint-finished', ()=>{
            this.annotationMapRef.nativeElement.querySelector('.leaflet-control-container').style.display = 'block';
        });

        // map events
        self.map.on('keypress', (keyboardEvent) => {
            let keyValue = keyboardEvent.originalEvent.key;
            switch (keyValue) {
                case 'r':
                    self.clickDrawRectangleButton();
                    break;
                case 'p':
                    self.clickDrawPolygonButton();
                    break;
                case 'l':
                    self.clickDrawPolylineButton();
                    break;
                case 'm':
                    self.clickDrawMarkerButton();
                    break;
            }
        });

        if(!this.projectImageModel.thumbnailUrl && !this.projectImageModel.thumbnailLoading){
            this.getProjectImageModelThumbnail(this.projectImageModel);
        }

        if(!this.projectImageModel.printUrl && !this.projectImageModel.printLoading){
            this.getProjectImageModelPrint(this.projectImageModel);
        }

        // if the project image is still loading
        if(this.projectImageModel.printLoading){
            // wait for the image loaded event
            let onProjectPrintImageLoaded = () =>{
                if(self.hasPrintOverlay()) self.addPrintImageOverlay();
            };
            this.projectImageModel.printLoadedEvent.subscribe(onProjectPrintImageLoaded.bind(self));
        } else {
            // otherwise - carry on ...
            if(self.hasPrintOverlay()) self.addPrintImageOverlay();
        }

        // add an editing layer
        self.drawnItems = new this.L.FeatureGroup();

        let annotationFeatures = [];
        self.annotationModels.forEach((annotationModel) => {
            let currentAnnotationFeature = {
                type: "Feature",
                geometry: annotationModel.geometry,
                properties: {
                    id: annotationModel.id,
                    classes: annotationModel.classes[0], //todo: multiple class selection
                    memo: annotationModel.memo,
                    isOccluded: annotationModel.isOccluded ? true : false
                }
            };
            annotationFeatures.push(currentAnnotationFeature);
        });

        this.geoJsonLayers = new this.L.GeoJSON(annotationFeatures, {
            style: function (feature) {
                let className = self.getFeatureStyle(feature);
                if (!className) return null;
                return {
                    className: className
                };
            },
            pointToLayer: function (feature, latlng) {
                let className = self.getFeatureStyle(feature);
                if (!className) return null;
                let geojsonMarkerOptions = {
                    className: className
                };
                return self.L.circleMarker(latlng, geojsonMarkerOptions);
            }
        });
        this.geoJsonLayers.eachLayer(
            function (layer) {
                self.addDrawLayer(layer);
            }
        );

        self.map.addLayer(self.drawnItems);

        // add draw controls
        let drawControl = new this.L.Control.Draw({
            draw: {
                polyline: true,
                polygon: true,
                rectangle: {
                    showArea: false
                },
                circle: false,
                marker: false,
                circlemarker: true
            },
            edit: {
                featureGroup: self.drawnItems
            }
        });
        if ((window as any).L) {
            (window as any).L.drawLocal.draw.toolbar.buttons.polygon = 'Draw polygon (p)';
            (window as any).L.drawLocal.draw.toolbar.buttons.rectangle = 'Draw rectangle (r)';
            (window as any).L.drawLocal.draw.toolbar.buttons.polyline = 'Draw line (l)';
            (window as any).L.drawLocal.draw.toolbar.buttons.circlemarker = 'Draw marker (m)';
        }
        self.map.addControl(drawControl);

        // respond to draw events
        self.map.on('draw:created', self.onDrawCreated.bind(self));
        self.map.on('draw:editstart', self.onDrawEditStart.bind(self));
        self.map.on('draw:editstop', self.onDrawEditStop.bind(self));
        self.map.on('draw:deleted ', self.onDrawDeleted.bind(self));

        // free draw
        // self.freeDraw = new FreeDraw({mode: FreeDraw.NONE});
        // self.freeDraw.on('markers', event => {
        //     self.freeDraw.mode(FreeDraw.NONE);
        //     console.log(event.latLngs);
        // });
        //
        // self.L.easyButton('<i class="material-icons">settings</i>', function(btn, map){
        //     self.freeDraw.mode(FreeDraw.CREATE);
        //     return false;
        // }).addTo( self.map );
        //
        // self.map.addLayer(self.freeDraw);

        // delay load the high resolution image
        if(!this.projectImageModel.mediaUrl) {
            let timeout = this.hasPrintOverlay() ? 500 : 0;
            setTimeout(() => {
                if (this.isDestroyed) return;
                this.getProjectImageModelMedia(this.projectImageModel);

                // if the project image is still loading
                if (self.projectImageModel.mediaLoading) {
                    // wait for the image loaded event
                    let onProjectImageLoaded = () => {
                        self.addImageOverlay();
                        if(self.hasPrintOverlay()) self.sendPrintImageOverlayToBack();

                        // loaded
                        self.annotationLoaded = true;
                    };
                    self.projectImageModel.mediaLoadedEvent.subscribe(onProjectImageLoaded.bind(self));
                } else {
                    // otherwise - carry on ...
                    self.addImageOverlay();
                    if(self.hasPrintOverlay()) self.sendPrintImageOverlayToBack();

                    // loaded
                    self.annotationLoaded = true;
                }
            }, timeout);
        } else {
            // otherwise - carry on ...
            self.addImageOverlay();
            if(self.projectImageModel.printUrl) self.sendPrintImageOverlayToBack();

            // loaded
            self.annotationLoaded = true;
        }

        this.viewInitialized = true;

        // manage change detection manually
        this.changeDetectorRef.detach();
        this.changeDetectorInterval = setInterval(() => {
            this.detectChanges();
        }, 250);
        this.detectChanges();

        setTimeout(()=>{
            // focus the tag chip input
            this.imageTagChipListInputRef.nativeElement.focus();
        }, 0);
    }

    ngOnDestroy(): void {
        if (!this.trigger) return;
        this.removeMap();
        this.trigger.closePanel();
        this.isDestroyed = true;
        if (this.changeDetectorInterval) clearInterval(this.changeDetectorInterval);
    }

    removeMap() {
        if (!this.map) return;
        this.map.remove();
    }

    hasPrintOverlay():boolean{
        return !!this.projectImageModel.printUrl;
    }

    addImageOverlay() {
        let self = this;
        // add an image layer
        self.imageOverlay = this.L.imageOverlay(self.projectImageModel.mediaUrl, self.bounds, {interactive: true}).addTo(self.map);
        self.imageOverlay.on('click', (e) => {
            // when you click on the background image, disable all open editors
            self.disableAllLayerEditing();
        });
    }

    addPrintImageOverlay() {
        let self = this;
        // add an image layer
        self.printImageOverlay = this.L.imageOverlay(self.projectImageModel.printUrl, self.bounds, {interactive: true}).addTo(self.map);
        self.printImageOverlay.on('click', (e) => {
            // when you click on the background image, disable all open editors
            self.disableAllLayerEditing();
        });
    }

    sendPrintImageOverlayToBack(){
        if(!this.printImageOverlay) return;
        this.printImageOverlay.bringToBack();
    }

    getProjectImageModelMedia(projectImageModel: ProjectImageModel) {
        projectImageModel.requestMedia();
    }

    getProjectImageModelPrint(projectImageModel: ProjectImageModel) {
        projectImageModel.requestPrint();
    }

    getProjectImageModelThumbnail(projectImageModel: ProjectImageModel) {
        projectImageModel.requestThumbnail();
    }

    private _classesUsedInAnnotation:string[] = [];
    get classesUsedInAnnotation(): string[] {
        let classesUsedInAnnotation: string[] = [];
        this.annotationModels.forEach((annotation) => {
            let annotationModel = new AnnotationModel().deserialize(annotation);
            let classes = annotationModel.classes;
            if (!classes) return;
            classes.forEach((annotationClass) => {
                if (classesUsedInAnnotation.find((element) => {
                    return annotationClass == element;
                })) return;
                classesUsedInAnnotation.push(annotationClass);
            })
        });
        this._classesUsedInAnnotation.length = 0;
        this._classesUsedInAnnotation.push(...classesUsedInAnnotation);
        return this._classesUsedInAnnotation;
    }

    private _visibleAnnotationList:AnnotationModel[] = [];
    get visibleAnnotationList(): AnnotationModel[] {
        let models: AnnotationModel[] = [];
        this.annotationModels.forEach((annotation) => {
            models.push(annotation);
        });
        this._visibleAnnotationList.length = 0;
        this._visibleAnnotationList.push(...models);
        return this._visibleAnnotationList;
    }

    private refreshVisibleAnnotationList(){
        let originalVisibleAnnotationList = this._visibleAnnotationList;
        this._visibleAnnotationList = []; // create a new reference
        this._visibleAnnotationList.push(...originalVisibleAnnotationList);
    }

    protected disableAllLayerEditing() {
        let edited = false;
        this.map.eachLayer((layer) => {
            if (layer.editing) {
                edited = true;
                layer.editing.disable();
            }
        });
        if(edited){
            this.onDrawnItemsModified();
        }
    }

    protected clickDrawPolygonButton() {
        (document.querySelector(".leaflet-draw-draw-polygon") as HTMLElement).click();
    }

    protected clickDrawRectangleButton() {
        (document.querySelector(".leaflet-draw-draw-rectangle") as HTMLElement).click();
    }

    protected clickDrawPolylineButton() {
        (document.querySelector(".leaflet-draw-draw-polyline") as HTMLElement).click();
    }

    protected clickDrawMarkerButton() {
        (document.querySelector(".leaflet-draw-draw-circlemarker") as HTMLElement).click();
    }

    protected focusMap() {
        if (!this.map) return;
        let mapContainer = this.map.getContainer();
        if (!mapContainer) return;
        mapContainer.focus();
    }

    protected getFeatureAnnotationClass(feature: any) {
        if (!feature) return null;
        let properties = feature.properties;
        if (!properties) return null;
        let classValue = properties.classes;
        if (!this.annotationClasses) return null;
        if (this.annotationClasses.length === 0) return null;
        let classIndex = this.annotationClasses.indexOf(classValue);
        if (classIndex < 0) return null;
        return 'ptv-annotations-class-' + classIndex % 20;
    }

    protected getFeatureTypeClass(feature: any) {
        if (!feature) return null;
        let geometry = feature.geometry;
        if (!geometry) return null;
        let geometryType = geometry.type;
        if (!geometryType) return null;
        return 'ptv-annotations-type-' + geometryType.toLowerCase();
    }

    protected getFeatureStyle(feature: any){
        let featureTypeClass = this.getFeatureTypeClass(feature);
        let featureAnnotationClass = this.getFeatureAnnotationClass(feature);
        let featureStyle = "";
        if(featureTypeClass){
            featureStyle += featureTypeClass;
        }
        if(featureAnnotationClass){
            featureStyle += " " + featureAnnotationClass;
        }
        return featureStyle;
    }

    protected addDrawLayer(layer) {
        let self = this;

        // add the layer
        self.drawnItems.addLayer(layer);

        // register popup
        self.registerLayerPopupComponent(layer)
    }

    protected onDrawCreated(e): void {
        let self = this;
        let type = e.layerType,
            layer = e.layer,
            feature = layer.feature = layer.feature || {};

        // initialize feature
        feature.type = feature.type || "Feature";

        // initialize geometry
        let geometryType = null;
        switch(type.toLowerCase()){
            case 'rectangle':
                geometryType = 'polygon';
                break;
            case 'polyline':
                geometryType = 'linestring';
                break;
            default:
                geometryType = type;
        }
        feature.geometry = feature.geometry || {type: geometryType};

        // initialize feature properties
        let properties = feature.properties = feature.properties || {};
        properties.classes = null;

        self.addDrawLayer(layer);
        self.onDrawnItemsModified();
        self.openLayerPopup(layer);
    }

    protected onDrawEditStart(e): void {
        let self = this;
    }

    protected onDrawEditStop(e): void {
        let self = this;
        self.onDrawnItemsModified();
    }

    protected onDrawDeleted(e): void {
        let self = this;
        self.onDrawnItemsModified();
    }

    protected onDrawnItemsModified(): void {
        this.annotationModified = true;
        let geoJsonFeaturesFeatureCollection = this.getGeoJSON();
        let geoJsonFeatureArray: Array<any> = geoJsonFeaturesFeatureCollection.features;
        if (!geoJsonFeatureArray) return;

        // clear the annotations
        while (this.annotationModels.length) {
            this.annotationModels.pop();
        }

        // create new annotations
        geoJsonFeatureArray.forEach((geoJsonFeature) => {
            if (!geoJsonFeature) return;
            let annotationModel = new AnnotationModel();
            if (geoJsonFeature.properties) {
                if(geoJsonFeature.properties.id) {
                    annotationModel.id = geoJsonFeature.properties.id;
                } else {
                    geoJsonFeature.properties.id = annotationModel.id;
                }
                annotationModel.geometry = geoJsonFeature.geometry;
                annotationModel.classes = [geoJsonFeature.properties.classes]; //todo: multiple class selection
                annotationModel.memo = geoJsonFeature.properties.memo;
                annotationModel.isOccluded = geoJsonFeature.properties.isOccluded ? true : false;
            }
            this.annotationModels.push(annotationModel);
        });

        // perform update
        this.performUpdate();
    }

    protected registerLayerPopupComponent(layer): void {
        let self = this;

        layer.bindPopup(null);
        layer.on('click', (e) => {
            let properties = layer.feature.properties = layer.feature.properties || {};
            if (!properties.classes) properties.classes = null;//self.annotationClasses[0];
            // create annotation popup component
            if (this.annotationPopupComponentRef) this.annotationPopupComponentRef.dispose();
            const annotationPopupComponentFactory = this.resolver.resolveComponentFactory(AnnotationPopupComponent);
            this.annotationPopupComponentRef = annotationPopupComponentFactory.create(this.injector);

            // instance
            let annotationPopupComponent = this.annotationPopupComponentRef.instance;

            // annotation classes
            annotationPopupComponent.annotationClassValue = properties.classes;
            annotationPopupComponent.annotationClasses = self.annotationClasses;
            annotationPopupComponent.annotationClassChange.subscribe((value) => {
                properties.classes = value;
                let className = self.getFeatureStyle(layer.feature);
                layer.setStyle({
                    className: className
                });
                if (layer._path) {
                    layer._path.setAttribute('class', className + " leaflet-interactive");
                }
                layer.redraw();
                self.onDrawnItemsModified();

            });

            // annotation memo
            annotationPopupComponent.annotationMemoValue = properties.memo ? properties.memo : "";
            annotationPopupComponent.annotationMemoChange.subscribe((value) => {
                properties.memo = value;
                self.onDrawnItemsModified();
            });

            // annotation is occluded
            annotationPopupComponent.annotationIsOccludedValue = properties.isOccluded;
            annotationPopupComponent.annotationIsOccludedChange.subscribe((value) => {
                properties.isOccluded = value;
                self.onDrawnItemsModified();
            });

            // annotation closed
            annotationPopupComponent.annotationPopupClosed.subscribe(() => {
                layer.closePopup();
                self.focusMap();
            });

            // annotation edit
            annotationPopupComponent.annotationEditClicked.subscribe(() => {
                layer.options.editing || (layer.options.editing = {});
                if (layer.editing) layer.editing.enable();
            });

            // annotation deleted
            annotationPopupComponent.annotationDeleteClicked.subscribe(() => {
                self.map.removeLayer(layer);
                self.drawnItems.removeLayer(layer);
                self.onDrawnItemsModified();
            });

            this.applicationRef.attachView(this.annotationPopupComponentRef.hostView);
            this.annotationPopupComponentRef.onDestroy(() => {
                this.applicationRef.detachView(this.annotationPopupComponentRef.hostView);
            });
            let contentDiv = document.createElement('div');
            contentDiv.classList.add('ptv-popup-annotation-form');
            contentDiv.appendChild(this.annotationPopupComponentRef.location.nativeElement);
            layer.setPopupContent(contentDiv);
        });
        layer.on("popupopen", function () {
        });

        layer.on("popupclose", function () {
            //self.onDrawnItemsModified();
        });
    }

    protected openLayerPopup(layer): void {
        if (!layer) return;
        layer.fireEvent('click')
    }

    public getGeoJSON(): any {
        let self = this;
        let geoJsonData = self.drawnItems ? self.drawnItems.toGeoJSON() : {};
        return geoJsonData;
    }

    protected printGeoJSON(): void {
        let self = this;
        let geoJsonData = self.getGeoJSON();
        console.log(JSON.stringify(geoJsonData, null, 2));
    }

    addTag(event: MatChipInputEvent): void {
        this.annotationModified = true;
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            this.projectImageModel.tags.push(value.trim());
        }

        if (input) {
            input.value = '';
        }
    }

    removeTag(tag: string): void {
        this.annotationModified = true;
        const index = this.projectImageModel.tags.indexOf(tag);

        if (index >= 0) {
            this.projectImageModel.tags.splice(index, 1);
        }
    }

    onTagInput(event) {
        const input = event.target;
        if (!input) return;
        const value = event.target.value;
        input.value = this.slugify(value);
    }

    slugify(text): string {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/[\s_-]+/g, '-');
    }

    onDoubleClickAnnotationRow(annotationModel){
        this.zoomToAnnotation(annotationModel);
    }

    onRemoveAnnotationRow(annotationModel){
        this.removeAnnotation(annotationModel);
    }

    zoomToAnnotation(annotationModel){
        let annotationFeatureLayer = this.findAnnotationFeatureByModel(annotationModel);
        if(!annotationFeatureLayer) return;
        this.zoomToAnnotationFeature(annotationFeatureLayer);
    }

    zoomToAnnotationFeature(annotationFeatureLayer){
        if(!annotationFeatureLayer)return;
        let bounds = null;
        if(annotationFeatureLayer.getBounds){
            bounds = annotationFeatureLayer.getBounds();
        }

        if(!bounds){
            // use the pixel bounds of the feature to determine lat/lng bounds
            if(annotationFeatureLayer._pxBounds){
                annotationFeatureLayer._updateBounds();
                let pxBounds = annotationFeatureLayer._pxBounds;
                let p1 = this.map.layerPointToLatLng(pxBounds.min);
                let p2 = this.map.layerPointToLatLng(pxBounds.max);
                bounds = this.L.latLngBounds(p1, p2);
            }
        }

        this.map.fitBounds(bounds);
    }

    removeAnnotation(annotationModel){
        let annotationFeatureLayer = this.findAnnotationFeatureByModel(annotationModel);
        if(!annotationFeatureLayer) return;
        this.removeAnnotationFeature(annotationFeatureLayer);
    }

    removeAnnotationFeature(annotationFeatureLayer){
        let self = this;
        self.map.removeLayer(annotationFeatureLayer);
        self.drawnItems.removeLayer(annotationFeatureLayer);
        self.onDrawnItemsModified();
    }

    findAnnotationFeatureByModel(annotationModel){
        let self = this;
        if(!self.drawnItems) return;
        let annotationFeatureLayer = null;
        let featureGroup = self.drawnItems;// as L.FeatureGroup;
        featureGroup.eachLayer((currentLayer)=>{
            if(!currentLayer.feature) return;
            let feature = currentLayer.feature;
           if(!feature.properties) return;
           let properties = feature.properties;
           if(!properties.id) return;
           if(annotationModel.id === properties.id) {
               annotationFeatureLayer = currentLayer;
           }
        });
        return annotationFeatureLayer;
    }

    detectChanges(): void {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    performUpdate():void{
        this.detectChanges();
        this.refreshVisibleAnnotationList();
    }
}