import {InteractiveModel} from "./interactive.model";
import {InteractiveSortModel} from "./interactiveSort.model";
import {InteractiveFilterExpressionModel} from "./interactiveFilterExpression.model";
import {InteractiveCollection} from "../../collections/interactive/interactive.collection";
import {EventEmitter} from "@angular/core";
import {InteractiveFilterModel} from "./interactiveFilter.model";

export interface InteractiveDataView {
}

export class InteractiveDataViewModel<T extends InteractiveModel> extends InteractiveModel implements InteractiveDataView {
    interactiveCollection: InteractiveCollection<T>;
    interactiveSortModel: InteractiveSortModel<T>;
    interactiveFilterExpressionModel: InteractiveFilterExpressionModel<T>;
    private _dataView: InteractiveCollection<T> = new InteractiveCollection<T>();
    private _filterChanged:boolean = true;
    private _dataChanged: boolean = true;
    private filterChangedSubscription;
    private itemAddedSubscription;
    private itemRemovedSubscription;

    filterChanged: EventEmitter<InteractiveFilterModel<T>> = new EventEmitter<InteractiveFilterModel<T>>();

    constructor(interactiveCollection:InteractiveCollection<T>,
                interactiveSortModel:InteractiveSortModel<T>,
                interactiveFilterExpression:InteractiveFilterExpressionModel<T>) {
        super();
        this.interactiveCollection = interactiveCollection;
        this.interactiveSortModel = interactiveSortModel;
        this.interactiveFilterExpressionModel = interactiveFilterExpression;
        this.itemAddedSubscription = this.interactiveCollection.itemAdded.subscribe(this.onInteractiveCollectionItemAdded.bind(this));
        this.itemRemovedSubscription = this.interactiveCollection.itemAdded.subscribe(this.onInteractiveCollectionItemRemoved.bind(this));
        this.filterChangedSubscription = this.interactiveFilterExpressionModel.filterChanged.subscribe(this.onFilterChanged.bind(this));
    }

    onFilterChanged(interactiveFilterModel:InteractiveFilterModel<T>){
        this._filterChanged = true;
        this.triggerFilterChanged(interactiveFilterModel);
    }

    onInteractiveCollectionItemAdded(model:T){
        this._dataChanged = true;
    }

    onInteractiveCollectionItemRemoved(model:T){
        this._dataChanged = true;
    }

    triggerFilterChanged(interactiveFilterModel: InteractiveFilterModel<T>) {
        this.filterChanged.emit(interactiveFilterModel);
    }

    get dataView(): InteractiveCollection<T> {
        if (!this.interactiveFilterExpressionModel) return this._dataView;
        if(this._filterChanged || this._dataChanged) {
            // filter
            let filteredCollection: T[] = this.filter(this.interactiveFilterExpressionModel.filter.bind(this.interactiveFilterExpressionModel));

            // sort
            filteredCollection = filteredCollection.sort(this.interactiveSortModel.sort.bind(this.interactiveSortModel));
            this._dataView.clear();
            filteredCollection.forEach((filteredModel) => {
                this._dataView.add(filteredModel);
            });
            this._filterChanged = false;
            this._dataChanged = false;
        }
        return this._dataView;
    }

    reapplyFilter() {
        // let originalWithFilterApplied = this.dataView;
        // this._dataView = new InteractiveCollection<T>(); // create a new reference
        // originalWithFilterApplied.forEach((filteredModel) => {
        //     this._dataView.add(filteredModel);
        // });
        this._dataChanged = true;
        this._filterChanged = true;
        this.dataView;
    }

    public filter(filterFunction: (T) => boolean) {
        if(!this.interactiveCollection) return;
        return this.interactiveCollection.toArray().filter(filterFunction)
    }
}