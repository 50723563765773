import {
    Component,
    Input
} from '@angular/core';
import WireframeElementCollection from "../../collections/wireframeElements/wireframeElement.collection";
import WireframeElementStatisticCollection
    from "../../collections/wireframeElementStatistics/wireframeElementStatistic.collection";

@Component({
    selector: 'wireframe-selection-information',
    styles: [require('./wireframeSelectionInformation.component.scss')],
    template: require('./wireframeSelectionInformation.component.html')
})
export default class WireframeSelectionInformationComponent {
    @Input() selectedWireframeElements: WireframeElementCollection = new WireframeElementCollection();

    constructor() {
    }

    get selectedWireframeElementStatisticCollection(): WireframeElementStatisticCollection{
        let wireframeElementStatisticCollection = new WireframeElementStatisticCollection();

        if(this.selectedWireframeElements){
            wireframeElementStatisticCollection = this.selectedWireframeElements.getWireframeElementStatistics();
        }

        return wireframeElementStatisticCollection;
    }
}