import {WireframeElement} from "./wireframeElement";
import {PV} from "../wireframe";
import {DefaultMaterialProps} from "./defaultMaterialProps";
import {WireframeElementType} from "./wireframeElementType";
import * as THREE from "three";
import {Object3D} from "three";

export class WidgetElement extends WireframeElement {
    pvObject:PV.Widget

    widgetGeometry:THREE.Object3D

    material = DefaultMaterialProps[WireframeElementType.widget].baseMaterial.clone()
    baseMaterial = DefaultMaterialProps[WireframeElementType.widget].baseMaterial.clone()


    createGeometry() {
        //this.add(this.pvObject.object3d)
        //this.hitGeometry = this
        this.pvObject.object3D = this
        this.userData.pvObject = this
        let loader = new THREE.ObjectLoader()

        let object3D:THREE.Mesh = loader.parse(this.pvObject.object3D_JSON)

        this.position.copy(object3D.position)
        this.quaternion.copy(object3D.quaternion)
        this.scale.copy(object3D.scale)

        //child.material = new THREE.MeshLambertMaterial( { transparent: true, depthTest: true, color: 0x00ff00, opacity: .7 } )
        //widgetEl.material = child.material
        let child = object3D.children[0]

        this.add(child)
        this.widgetGeometry = child
        this.baseGeometry = this.findMeshes()
        this.hitGeometry = this.baseGeometry
        this.highlightGeometry = this.baseGeometry
        this.storeBaseMaterials()
        this.createLabelSprite()
    }

    updateGeometry() {
        super.updateGeometry();
    }

    findMeshes():THREE.Mesh[] {
        let meshes = []
        this.traverse((o) => {
            if (o instanceof THREE.Mesh && o.material) meshes.push(o)
        })
        return meshes
    }

    toSerializableClone():Object3D {
        let that = this
        let o = new Object3D()
        o.matrix.copy(this.matrix)

        function clone(obj:Object3D) {
            let userData = obj.userData
            obj.userData = {}
            let objClone = obj.clone(false)
            obj.userData = userData
            return objClone
        }
        function recurseClone(parent:Object3D, parentClone:Object3D) {

            for (let child of parent.children) {
                //if ((that.baseGeometry as any).includes(child)) {
                    let childClone = clone(child)
                    parentClone.add(childClone)
                    recurseClone(child, childClone)
                //}
            }
        }
        let widgetGeomClone = clone(this.widgetGeometry)
        o.add(widgetGeomClone)
        recurseClone(this.widgetGeometry, widgetGeomClone)
        return o;
    }

    updateDerivedProperties() {

    }

    setPosition(worldPos: THREE.Vector3) {
        this.matrix.setPosition(this.worldToLocal(worldPos))
    }

}