import {Directive, OnInit, OnDestroy, ElementRef, Inject, Input} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {Theme, ThemeService} from "../../services/themes/theme.service";
import {DOCUMENT} from "@angular/common";

@Directive({
    selector: '[ptvTheme]'
})
export class ThemeDirective implements OnInit, OnDestroy {

    @Input() themeTarget: string = null;
    private _destroy$ = new Subject();

    constructor(
        private _elementRef: ElementRef,
        private _themeService: ThemeService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit() {
        const active = this._themeService.getActiveTheme();
        if (active) {
            this.updateTheme(active);
        }

        this._themeService.themeChange
            .pipe(takeUntil(this._destroy$))
            .subscribe((theme: Theme) => this.updateTheme(theme));
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    updateTheme(theme: Theme) {

        let target = this._elementRef.nativeElement;

        if(this.themeTarget){
            target = this.themeTarget == "body"
                ? this.document.body
                : this.document.querySelectorAll(this.themeTarget);
        }

        // project properties onto the element
        for (const key in theme.properties) {
            target.style.setProperty(key, theme.properties[key])
        }

        // remove old theme
        for (const name of this._themeService.theme) {
            target.classList.remove(`${name}-theme`);
        }

        // alias element with theme name
        target.classList.add(`${theme.name}-theme`);
    }

}
