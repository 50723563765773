import tpl from './login.html'
import {WireframeApplication} from "../../application/wireframeApplication";

declare var wfApp:WireframeApplication

export default {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    controller: ['apiService', '$http', function (apiService, $http) {
        let $ctrl = this;

        this.setStatusMessage = function (level, msg) {
            $ctrl.statusMessage = msg;
            if (level == "info") {
                $ctrl.statusMessageClass = "labelSprite-info";
            } else if (level == "warning") {
                $ctrl.statusMessageClass = "labelSprite-warning";
            } else if (level == "danger") {
                $ctrl.statusMessageClass = "labelSprite-danger";
            }
        };

        this.ok = function () {
            console.log("ok click");
            $ctrl.statusMessage = "Authenticating";
            $http({
                method: 'POST',
                url: wfApp.apiService.apiUrl + 'auth/login',
                data: JSON.stringify({username: $ctrl.email, password: $ctrl.password}),
                headers: {'Content-Type': 'application/json'}
            }).then(function (data) {
                if (data.status == 200) {
                    $ctrl.setStatusMessage("info", "Login Successful");
                    // total hack, can't figure out how to inject apiService here
                    (window as any).apiService.authToken = data.data.token;
                    wfApp.apiService.authToken = data.data.token;
                    //controller.scope().currentUser = data.data;
                    localStorage.setItem("authorization", data.data.token);
                    $ctrl.close();
                }
            }).catch(function (data) {
                console.error("login error", data);
                if (data.status == 401 || data.status == 403) {
                    $ctrl.setStatusMessage("danger", "Invalid Login");
                } else {
                    $ctrl.setStatusMessage("danger", "An error occurred.  Please contact support");
                }

            });

        };


        this.cancel = function () {
            console.log("cancel click");
        };
    }],
    template: tpl
}

