export var GeometryUtilities = new Object as any;
import numeric from 'numeric';

GeometryUtilities.FitPlaneToThree3DPoints = function (p1, p2, p3)
{
    let a = (p2.y - p1.y) * (p3.z - p1.z) - (p3.y - p1.y) * (p2.z - p1.z);
    let b = (p2.z - p1.z) * (p3.x - p1.x) - (p3.z - p1.z) * (p2.x - p1.x);
    let c = (p2.x - p1.x) * (p3.y - p1.y) - (p3.x - p1.x) * (p2.y - p1.y);
    let d = -(a * p1.x + b * p1.y + c * p1.z);
    let norm = Math.sqrt((a * a) + (b * b) + (c * c));
    let plane = [a / norm, b / norm, c / norm, d / norm];
    return plane;
};

function donotuse_test_FitPlaneToThree3DPoints()
{
    /*
    P = [1, 1, 1], Q = [1, 2, 0], R = [-1, 2, 1];
    [P.x, P.y, P.z] = P;
    [Q.x, Q.y, Q.z] = Q;
    [R.x, R.y, R.z] = R;
    plane = GeometryUtilities.FitPlaneToThree3DPoints(P, Q, R);
    //should be [0.30151134457776363, 0.6030226891555273, 0.6030226891555273, -1.507556722888818]
    */
}

GeometryUtilities.AngleBetweenTwoUnitVectors3D=function(vec1, vec2)
{
    let cosTheta = vec1[0] * vec2[0] + vec1[1] * vec2[1] + vec1[2] * vec2[2];
    let angle = Math.acos(Math.min(Math.max(cosTheta, -1.0), 1.0));
    angle *= 180 / Math.PI;
    return angle;
};

GeometryUtilities.FitPlane3D=function(points3D)
{
    if(points3D.length === 3)
    {
        let param=GeometryUtilities.FitPlaneToThree3DPoints(points3D[0],points3D[1],points3D[2]);
        return param;
    }

     let G = [];
     for (let i = 0; i < points3D.length; i++)
     {
        let point=points3D[i];
        G.push([point.x,point.y,point.z,1]);
     }

    let res = numeric.svd(G);
    let param = [res.V[0][3],res.V[1][3],res.V[2][3],res.V[3][3]] as any;
    let norm = Math.sqrt((param[0] * param[0]) + (param[1] * param[1]) + (param[2] * param[2]));
    param[0] /= norm;
    param[1] /= norm;
    param[2] /= norm;
    param[3] /= norm;

    return param;
};


GeometryUtilities.FitPlaneTo3DPointsRANSAC = function (points3D, numIterations, distanceThreshold)
{
    let bestPlane;

    //maximum allowed number for double type
    let minError = 9007199254741002;
    let maxInliers = 0;
    for (let i = 0; i < numIterations; i++)
    {
        let indices = [];
        while (indices.length != 3)
        {
            let index = Math.floor(Math.random() * points3D.length);

            if (indices.indexOf(index) == -1)
            {
                indices.push(index);
            }
        }

        let plane = GeometryUtilities.FitPlaneToThree3DPoints(points3D[indices[0]], points3D[indices[1]], points3D[indices[2]]);

        let inliers = 0;
        let error = 0;
        for (let j = 0; j < points3D.length ; j++)
        {
            let distance = Math.abs(
             plane[0] * points3D[j].x +
             plane[1] * points3D[j].y +
             plane[2] * points3D[j].z +
             plane[3]);
             if (Math.abs(distance) <= distanceThreshold)
                 inliers++;
            error += distance;
        }
        if ((inliers > maxInliers) || (inliers == maxInliers && error < minError))
        {
            bestPlane = plane;
            maxInliers = inliers;
            minError = error;
        }
    }

    return bestPlane;
};

GeometryUtilities.normalizePlane = function (plane)
{
    let normalValue = Math.sqrt(plane[0] * plane[0] + plane[1] * plane[1] + plane[2] * plane[2]);
    plane[0] = plane[0] / normalValue;
    plane[1]= plane[1] / normalValue;
    plane[2] = plane[2] / normalValue;
    plane[3] = plane[3] / normalValue;
};

//tested
GeometryUtilities.ProjectPointOnPlane = function (point, plane)
{
    GeometryUtilities.normalizePlane(plane);
    let dist = point.x * plane[0] + point.y * plane[1] + point.z * plane[2] + plane[3];
    let projectedPoint=new Object as any;
    let newPoint = [(point.x - dist * plane[0]) , (point.y - dist * plane[1]) ,
        (point.z - dist * plane[2])];
    projectedPoint.x = newPoint[0];
    projectedPoint.y = newPoint[1];
    projectedPoint.z = newPoint[2];
    //[projectedPoint.x, projectedPoint.y, projectedPoint.z] = [(point.x - dist * plane[0]) , (point.y - dist * plane[1]) , (point.z - dist * plane[2])];

    return projectedPoint;
};

//tested
GeometryUtilities.pointToPlaneDistance = function (point, plane)
{
    //plane assumed to be normolized
    GeometryUtilities.normalizePlane(plane);

    let dist = point.x * plane[0] + point.y * plane[1] + point.z * plane[2] + plane[3];
    let normalValue=Math.sqrt(plane[0] * plane[0] + plane[1] * plane[1] + plane[2] * plane[2]);
    return dist;

    //[4, -4, 3]
    //[2,-2,5,8]
    //distance 6.789028582272215
};

GeometryUtilities.add_v3v3=function (v0, v1)
{
    return [ v0[0] + v1[0], v0[1] + v1[1], v0[2] + v1[2] ];
};

GeometryUtilities.sub_v3v3=function(v0, v1)
{
    return ( [ v0[0] - v1[0], v0[1] - v1[1], v0[2] - v1[2]] );
};

GeometryUtilities.dot_v3v3=function (v0, v1)
{
    let result=(v0[0] * v1[0]) + (v0[1] * v1[1]) + (v0[2] * v1[2])
    return result;
};

GeometryUtilities.cross_v3v3=function (v0, v1)
{
    return [ v0[1] * v1[2] - v0[2] * v1[1], v0[2] * v1[0] - v0[0] * v1[2], v0[0] * v1[1] - v0[1] * v1[0] ];
};

GeometryUtilities.len_squared_v3=function (v0)
{
    return GeometryUtilities.dot_v3v3(v0, v0);
};

GeometryUtilities.mul_v3_fl=function (v0, f)
{
    return [ v0[0] * f, v0[1] * f, v0[2] * f ];
};

//tested
/**
GeometryUtilities.intersectLineToPlane({x:0,y:2,z:0},{x:1,y:5,z:1},[2,1,-4,-4])
expected :[2,8,2]

output: [1.9999999999999996, 7.999999999999998, 1.9999999999999996]

**/
GeometryUtilities.intersectLineToPlane = function (p0, p1, plane)
{
    debugger;
    GeometryUtilities.normalizePlane(plane);

    let epsilon = 1e-6;
    let u = GeometryUtilities.sub_v3v3([p1.x,p1.y,p1.z], [p0.x,p0.y,p0.z]);
    let dot = GeometryUtilities.dot_v3v3(plane, u)

    if (Math.abs(dot) > epsilon)
    {
        // get a point on the plane
        let d = -plane[3] / GeometryUtilities.len_squared_v3(plane)
        let p_co = [plane[0] * d, plane[1] * d, plane[2] * d]

        let w = GeometryUtilities.sub_v3v3([p0.x, p0.y, p0.z], p_co)
        let fac = -GeometryUtilities.dot_v3v3(plane, w) / dot
        u = GeometryUtilities.mul_v3_fl(u, fac)
        return GeometryUtilities.add_v3v3([p0.x, p0.y, p0.z], u)
    }
    else
    {
        return null;
    }
};

GeometryUtilities.ProjectPointOnLine3D=function(pt,line)
{
     //Refer to the following link for math http://stackoverflow.com/questions/9368436/3d-perpendicular-point-on-line-from-3d-point
     // Vec6f represents (vx, vy, vz, x0, y0, z0), where (vx, vy, vz) is a normalized vector colinear to the line and
     // (x0, y0, z0) is a point on the line.
     let u0 = line[0];
     let u1 = line[1];
     let u2 = line[2];

     let pq0 = pt.x - line[3];
     let pq1 = pt.y - line[4];
     let pq2 = pt.z - line[5];

     let uLengthSq = u0 *u0 + u1* u1 + u2 * u2;
     let pqDotu = pq0*u0 + pq1*u1 + pq2 * u2;
     let coeff = pqDotu / uLengthSq;

     let w20 = pq0 - coeff * u0;
     let w21 = pq1 - coeff * u1;
     let w22 = pq2 - coeff * u2;

     let projected = new Object as any;
     let point = [(pt.x - w20), (pt.y - w21), (pt.z - w22)];
     projected.x = point[0];
     projected.y = point[1];
     projected.z = point[2];
     //[projected.x,projected.y,projected.z]=[(pt.x - w20), (pt.y - w21), (pt.z - w22)];
     return projected;
};

 GeometryUtilities.PlanePlaneAngle=function(plane1,plane2)
 {
     let norm1=[plane1[0],plane1[1],plane1[2]];
     let norm2=[plane2[0],plane2[1],plane2[2]];

     let dotP=GeometryUtilities.dot_v3v3(norm1,norm2);
     let a1=Math.sqrt(norm1[0]*norm1[0]+norm1[1]*norm1[1]+norm1[2]*norm1[2]);
     let a2=Math.sqrt(norm2[0]*norm2[0]+norm2[1]*norm2[1]+norm2[2]*norm2[2]);

     return Math.acos(dotP/(a1*a2));

 };

 GeometryUtilities.PlanePlaneIntersection=function(plane1,plane2)
{
     let normal1=[plane1[0], plane1[1], plane1[2]];
     let normal2=[plane2[0], plane2[1], plane2[2]];
     let direction=GeometryUtilities.cross_v3v3(normal1,normal2);

     let det = direction[0]*direction[0] + direction[1]*direction[1] + direction[2] * direction[2];
     if (det > Math.pow(2,-20))
     {
        let n1=GeometryUtilities.cross_v3v3(direction,normal2);
        let n2=GeometryUtilities.cross_v3v3(normal1,direction);

        n1[0] *= plane1[3];
        n2[0] *= plane2[3];

        n1[1] *= plane1[3];
        n2[1] *= plane2[3];

        n1[2] *= plane1[3];
        n2[2] *= plane2[3];

        let point = new Object as any;
        point.x = (n1[0] + n2[0]) / det;
        point.y = (n1[1] + n2[1]) / det;
        point.z = (n1[2] + n2[2]) / det;

        //[point.x,point.y,point.z]=[(n1[0] + n2[0]) / det,(n1[1] + n2[1]) / det,(n1[2] + n2[2]) / det]

        let intersectionLine =[direction[0], direction[1], direction[2], point.x, point.y, point.z];

        return intersectionLine;
     }
     else
        return null;
};


// using http://stackoverflow.com/questions/6408670/line-of-intersection-between-two-planes
//tested
/*
x+y+z+1=0
x+2y+3z+4=0

expected :

*/
GeometryUtilities.isect_plane_plane_to_normal_ray = function (p1, p2)
{
    GeometryUtilities.normalizePlane(p1);
    GeometryUtilities.normalizePlane(p2);


    let r_point,r_normal;

    let p1_normal=[p1[0],p1[1],p1[2]];
    let p2_normal=[p2[0],p2[1],p2[2]];

    // logically the 3rd plane, but we only use the normal component.

    let p3_normal=GeometryUtilities.cross_v3v3(p1_normal,p2_normal); 
    let det = GeometryUtilities.len_squared_v3(p3_normal) ;

    // If the determinant is 0, that means parallel planes, no intersection.
    // note: you may want to check against an epsilon value here.
    if (det != 0.0)
    {
        // calculate the final (point, normal)
        let p3p2=GeometryUtilities.cross_v3v3(p3_normal,p2_normal); 
        let temp1=GeometryUtilities.mul_v3_fl(p3p2,p1[3]);

        let p1p3=GeometryUtilities.cross_v3v3(p1_normal,p3_normal); 
        let temp2=GeometryUtilities.mul_v3_fl(p1p3,p2[3]);


        let sum=GeometryUtilities.add_v3v3(temp1,temp2);

        r_point =GeometryUtilities.mul_v3_fl(sum,1/det);  ;
        r_normal = p3_normal;
        return [r_point,r_normal];
    }
    else
    {
        return null;
    }
};


GeometryUtilities.Cross=function( a,b)
{
    let res=new Object as any;
    res.x = a.y*b.z - a.z*b.y;
    res.y = a.z*b.x - a.x*b.z;
    res.z = a.x*b.y - a.y*b.x;
    return res;
};

GeometryUtilities.PointDistanceFromLine=function(pt,pA,pB)
{
    let v1=new Object as any;
    v1.x=pt.x - pA.x;
    v1.y=pt.y - pA.y;
    v1.z=pt.z - pA.z;

    let v2=new Object as any;
    v2.x=pt.x - pB.x;
    v2.y=pt.y - pB.y;
    v2.z=pt.z - pB.z;


    let cross = GeometryUtilities.Cross(v1, v2);
    return Math.sqrt(cross.x*cross.x + cross.y*cross.y + cross.z * cross.z) /
    Math.sqrt(Math.pow(pB.x - pA.x, 2) + Math.pow(pB.y - pA.y, 2) + Math.pow(pB.z - pA.z, 2));
};


GeometryUtilities.LineLineIntersect=function(p1,p2,p3,p4)
{
    let p13=[];
    let p43=[];
    let p21=[];
    let pa=[];
    let pb=[];
    let eps=0.0000000000001;

    p13[0] = p1[0] - p3[0];
    p13[1] = p1[1] - p3[1];
    p13[2] = p1[2] - p3[2];

    p43[0] = p4[0] - p3[0];
    p43[1] = p4[1] - p3[1];
    p43[2] = p4[2] - p3[2];

    if ((Math.abs(p43[0])  < eps) &&
        (Math.abs(p43[1])  < eps) &&
        (Math.abs(p43[2])  < eps))
      console.warn('Could not compute LineLineIntersect!');
    

    p21[0] = p2[0] - p1[0];
    p21[1] = p2[1] - p1[1];
    p21[2] = p2[2] - p1[2];

    if ((Math.abs(p21[0])  < eps) &&
        (Math.abs(p21[1])  < eps) &&
        (Math.abs(p21[2])  < eps))
      console.warn('Could not compute LineLineIntersect!');


    let d1343 = p13[0] * p43[0] + p13[1] * p43[1] + p13[2] * p43[2];
    let d4321 = p43[0] * p21[0] + p43[1] * p21[1] + p43[2] * p21[2];
    let d1321 = p13[0] * p21[0] + p13[1] * p21[1] + p13[2] * p21[2];
    let d4343 = p43[0] * p43[0] + p43[1] * p43[1] + p43[2] * p43[2];
    let d2121 = p21[0] * p21[0] + p21[1] * p21[1] + p21[2] * p21[2];

    let denom = d2121 * d4343 - d4321 * d4321;

    if (Math.abs(denom) < eps)
      console.warn('Could not compute LineLineIntersect!');

    let numer = d1343 * d4321 - d1321 * d4343;
    let mua = numer / denom;
    let mub = (d1343 + d4321 * mua) / d4343;

    pa[0] = p1[0] + mua * p21[0];
    pa[1] = p1[1] + mua * p21[1];
    pa[2] = p1[2] + mua * p21[2];
    pb[0] = p3[0] + mub * p43[0];
    pb[1] = p3[1] + mub * p43[1];
    pb[2] = p3[2] + mub * p43[2];

    let mean=[];
    mean[0]=(pa[0]+pb[0])/2;
    mean[1]=(pa[1]+pb[1])/2;
    mean[2]=(pa[2]+pb[2])/2;

    return [pa,pb,mean];
};


GeometryUtilities.SimplePolygonArea=function(vertices)
{
    let area = 0.0;

    // Calculate value of shoelace formula
    let j= vertices.length - 1;
    for (let i = 0; i < vertices.length; i++)
    {
        area += (vertices[j].x + vertices[i].x) * (vertices[j].y - vertices[i].y);
        j = i;  // j is previous vertex to i
    }

    // Return absolute value
    return Math.abs(area / 2.0);
};

GeometryUtilities.SimplePolygonPerimeter=function(vertices)
{
    let perimeter = 0;
    for (let i = 0; i < vertices.length; i++)
    {
        let idx1 = i;
        let idx2 = i + 1;
        if (idx2 == vertices.length)
        idx2 = 0;
        perimeter += Math.sqrt(Math.pow(vertices[idx1].x - vertices[idx2].x, 2) + Math.pow(vertices[idx1].y - vertices[idx2].y, 2));
    }
    return perimeter;
};

GeometryUtilities.CoordinateSystemOnPlane=function(plane,xDirectionStart,xDirectionEnd)
{
    let startOnPlane = GeometryUtilities.ProjectPointOnPlane(xDirectionStart, plane);
    let endOnPlane = GeometryUtilities.ProjectPointOnPlane(xDirectionEnd, plane);
    let Xx = endOnPlane.x - startOnPlane.x;
    let Xy = endOnPlane.y - startOnPlane.y;
    let Xz = endOnPlane.z - startOnPlane.z;
    let norm = Math.sqrt(Xx * Xx + Xy * Xy + Xz * Xz);

    let Rx=[Xx / norm, Xy / norm, Xz / norm];
    let Rz=[plane[0], plane[1], plane[2]];
    let Ry=GeometryUtilities.cross_v3v3(Rz,Rx);
    let R=[Rx,Ry,Rz];

    let C = [0,0,0];
    C[0] = startOnPlane.x;
    C[1] = startOnPlane.y;
    C[2] = startOnPlane.z;

    return [R,C]
};

GeometryUtilities.ConvertPointCoordinateSystem=function(point,R,C)
{
    //Xnew = R * (X - C)
    let coordinates=[];
    for (let i = 0; i < 3; i++)
    {
        coordinates.push(
            R[i][0] * (point.x - C[0]) +
            R[i][1] * (point.y - C[1]) +
            R[i][2] * (point.z - C[2]));
    }

    let converted=new Object as any;
    converted.x = coordinates[0];
    converted.y = coordinates[1];
    converted.z = coordinates[2];
    //[converted.x,converted.y,converted.z]=coordinates;

    return converted;
};
