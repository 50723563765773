import {IntegerProperty} from "./integerProperty";

export class EnumProperty extends IntegerProperty {

    values = [];

    constructor() {
        super();
        this.type = ".EnumProperty";
        this.name = "EnumProperty";
    }

    setValuesFromEnum(enumType) {
        this.values = [];
        let that = this;
        const objValues = Object.keys(enumType).map(k => enumType[k]);
        const names = objValues.filter(v => typeof v === "string") as string[];
        const values = objValues.filter(v => typeof v === "number") as number[];
        for (let i = 0; i < values.length; i++) {
            let ord = values[i];
            that.values.push({value: ord, label: names[ord]});
        }
    }

    fromJson(obj, allProperties): void {
        super.fromJson(obj, allProperties);
        this.values = obj.values;
    }
}