import {Route} from '@angular/router';
import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';
import {RouterService} from '../../services/routers/router.service';
import {ModuleService} from "../../services/modules/module.service";
import {ModuleData} from "../../models/modules/module.model";
import {tap} from "rxjs/operators";
import {DomSanitizer} from "@angular/platform-browser";
import {MatIconRegistry} from "@angular/material";
import {LoginComponent} from "../login/login.component";
import {ModalService} from "../../services/modals/modal.service";
import {ApplicationService} from "../../services/applications/application.service";
import {ThemeService} from "../../services/themes/theme.service";
import {OrganizationService} from "../../services/organizations/organization.service";
import {Logo, LogoService} from "../../services/logos/logo.service";
import {BrandingModel} from "../../models/brandings/branding.model";
import * as Symbols from 'src/symbols.json';
import {OrganizationModel} from "../../models/organizations/organization.model";
import {UserModel} from "../../models/users/user.model";
import {CustomerModel} from "../../models/customers/customer.model";

@Component({
    selector: 'app-root',
    template: require('./appRoot.component.html'),
    styles: [require('./appRoot.component.scss')],
    encapsulation: ViewEncapsulation.None
})
export class AppRootComponent implements OnInit {
    routes: Observable<Route[]>;
    modules: Observable<ModuleData[]>;
    loadedModules: ModuleData[];
    defaultHash = '/wireframe';
    moduleCount: number;
    userModel: UserModel;
    customerModel: CustomerModel;
    organizationModel: OrganizationModel;
    brandingModel: BrandingModel;

    isDefaultHash(): boolean {
        let hash = window.location.hash;
        if (!hash) {
            hash = this.defaultHash;
        } else {
            hash = window.location.hash.slice(1);
        }
        return hash == this.defaultHash;
    }

    @ViewChild('loginComponent', {static: false}) loginComponent: LoginComponent;
    private loginModalId: string = 'login-modal';
    private loginError: string = null;

    constructor(
        private routerService: RouterService,
        private moduleService: ModuleService,
        private modalService: ModalService,
        private applicationService: ApplicationService,
        private organizationService: OrganizationService,
        private themeService: ThemeService,
        private logoService: LogoService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        // extend icons
        let sym = typeof Symbols === "object" ? Symbols["default"] : Symbols;
        sym.forEach(symbolName => {
            this.matIconRegistry.addSvgIconLiteral(
                symbolName,
                this.domSanitizer.bypassSecurityTrustHtml(require(`svg/symbols/${symbolName}.svg`))
            );
        });
    }

    ngOnInit(): void {
        let self = this;
        self.organizationService.detectOrganization().subscribe((detectedOrganization) => {
            if (detectedOrganization) {
                console.log("organization", detectedOrganization);
                let brandingModel = detectedOrganization.branding;
                if (brandingModel) {
                    self.updateBranding(brandingModel);
                }
            }

            self.obtainUserSession().then(() => {
            });
        });

    }

    obtainUserSession(): Promise<void> {
        let self = this;
        let projectId = this.getQueryVariable('projectId');
        let obtainUserSessionPromise = new Promise<void>((resolve, reject) => {
            self.applicationService.resumeUserSession().then(
                (userModel) => {
                    if (userModel) {
                        // resume successful
                        resolve()
                    } else {
                        // no user, login
                        return self.login();
                    }
                },
                (errorResponse) => {
                    if (errorResponse) {
                        let error = errorResponse.error;
                        if (error) {
                            // login error, try again
                            console.log(error);
                            if (error.message) {
                                this.loginError = error.message;
                            }
                        }
                    }

                    return self.login();
                });
        });

        return obtainUserSessionPromise
            .then(() => {
                return self.applicationService.initializeApplication()
            })
            .then(() => {
                if (projectId) return self.applicationService.authorizeProject(projectId);
                return self.applicationService.authorizeProjects();
            })
            .then(() => {
                self.userModel = self.applicationService.userModel;
                self.customerModel = self.applicationService.customerModel;
                console.log('user', self.userModel);
                console.log('customer', self.customerModel);
                self.organizationService.getOrganization(self.customerModel.name).subscribe((organizationModel) => {
                    self.organizationModel = organizationModel;
                    self.brandingModel = organizationModel.branding;
                    console.log('organization', self.organizationModel);
                    if (self.brandingModel) {
                        console.log('branding', self.brandingModel);
                        self.updateBranding(self.brandingModel);
                    }
                })
            })
            .then(() => {
                self.loadModules();
            });
    }

    login(): Promise<void> {
        let self = this;
        self.openLoginModal();

        let loginPromise = new Promise<void>((resolve, reject) => {
            let loginSubscription = self.loginComponent.loginRequested.subscribe((loginInformation) => {
                self.applicationService.login(loginInformation.username, loginInformation.password).then(
                    (userModel) => {
                        // login successful
                        if (loginSubscription) loginSubscription.unsubscribe();
                        self.closeLoginModal();
                        return self.obtainUserSession();
                    },
                    (errorResponse) => {
                        if (errorResponse) {
                            let error = errorResponse.error;
                            if (error) {
                                // login error, try again
                                console.log(error);
                                if (error.message) {
                                    this.loginError = error.message;
                                }
                            }
                        }

                        if (loginSubscription) loginSubscription.unsubscribe();
                        self.closeLoginModal();
                        return self.login();
                    });
            });
        });

        return loginPromise;
    }

    loadModules() {
        let self = this;
        this.routes = this.routerService.existingRoutes;
        this.modules = this.moduleService.loadModules().pipe(tap(res => {
                let unhandledModules = [...res];
                let allModulesHandled = () => {
                    if (!self.isDefaultHash()) self.navigateWindowLocationHash();
                };
                let moduleHandled = (moduleData) => {
                    let index = unhandledModules.indexOf(moduleData);
                    if (index > -1) {
                        unhandledModules.splice(index, 1);
                    }
                    if (unhandledModules.length === 0) {
                        allModulesHandled();
                    }
                };
                res.forEach(moduleData => {

                    let isEnabled = moduleData.registered;

                    if (self.organizationModel) {
                        // todo: apply user app rights system
                        isEnabled = self.organizationModel.isRouteEnabled(moduleData.routePath);
                    }

                    if (isEnabled) {
                        self.moduleService.enableModule(moduleData).then(
                            () => {
                                moduleHandled(moduleData);
                                console.log('loaded module', moduleData)
                            },
                            (err) => {
                                console.log(`${moduleData.moduleName} could not be found, did you copy the umd file to ${moduleData.filePath}?  ${err}`, err);
                                moduleHandled(moduleData);
                            });
                    } else {
                        // no need to register
                        moduleHandled(moduleData);
                    }
                });
            }
        ));
        this.modules.subscribe(this.onModulesLoaded.bind(this));
    }

    onModulesLoaded(modules: ModuleData[]) {
        this.loadedModules = modules;
        this.moduleCount = modules.length;
        if (this.isDefaultHash()) {
            this.navigate(this.defaultHash);
        } else if (this.moduleCount === 1) {
            // navigate to the single module
            this.navigate(this.loadedModules[0].routePath);
        }
    }

    getRouteName(routePath: string) {
        let name = routePath;
        let module = this.getModuleByRoutePath(routePath);
        if (!module) return name;
        name = module.title;
        return name;
    }

    getRouteSymbol(routePath: string) {
        let symbol = 'bubble_chart';
        let module = this.getModuleByRoutePath(routePath);
        if (module) {
            symbol = module.symbol;
            return symbol;
        }
        switch (routePath) {
            case 'apps':
                symbol = 'appManagement';
                break;
            case 'wireframe':
                symbol = 'wireframeTool';
                break;
            case 'measure':
                symbol = 'measureTool';
                break;
        }
        return symbol;
    }

    isRouteActive(routePath: string): boolean {
        return this.routerService.isRouteActive(routePath);
    }

    isRouteEnabled(routePath: string) {
        let enabled = true;
        if (!this.organizationModel) return enabled;
        enabled = this.organizationModel.isRouteEnabled(routePath);
        return enabled;
    }

    getModuleByRoutePath(routePath: string): ModuleData {
        let module: ModuleData = null;
        if (!this.loadedModules) return module;
        this.loadedModules.forEach(installedModule => {
            if (installedModule.routePath == routePath) {
                module = installedModule;
            }
        });
        return module;
    }

    navigateWindowLocationHash() {
        this.routerService.navigateWindowLocationHash();
    }

    navigate(hash: string) {
        this.routerService.navigate(hash);
    }

    openLoginModal() {
        this.openModal(this.loginModalId);
    }

    closeLoginModal() {
        this.closeModal(this.loginModalId);
    }

    openModal(id: string) {
        this.modalService.open(id);
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    updateBranding(brandingModel: BrandingModel) {
        this.updateLogo(brandingModel);
        this.updateTheme(brandingModel);
    }

    updateTheme(brandingModel: BrandingModel) {
        let self = this;
        let themeName = brandingModel.theme;
        let activeTheme = self.themeService.getActiveTheme();
        let update = false;
        if (activeTheme) {
            if (activeTheme.name != themeName) {
                update = true;
            }
        } else {
            update = true;
        }

        if (update) {
            self.themeService.setTheme(themeName);
        }
    }

    updateLogo(brandingModel: BrandingModel) {
        let self = this;
        let logoPath = brandingModel.logo;
        let activeLogo = this.logoService.getLogo();
        let update = false;
        if (activeLogo) {
            if (activeLogo.path != logoPath) {
                update = true;
            }
        } else {
            update = true;
        }

        if (update) {
            let logo: Logo = {
                name: brandingModel.name,
                path: brandingModel.logo,
                properties: {}
            };
            self.logoService.setLogo(logo);
        }
    }

    protected getQueryVariable(variable) {
        let query = window.location.search.substring(1);
        let vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        return null;
    }
}