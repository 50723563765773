import {ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Vector3} from "three";
import {PV} from "../../wireframe";
import {EdgeTypeProperty} from "../../models/property/edgeTypeProperty";
import {EdgeType} from "../../models/property/edgeType";
import {ProjectImageModel} from "../../models/projectImages/projectImage.model";
import {VertexElementModel} from "../../models/vertexElements/vertexElement.model";
import {SceneService} from "../../services/scenes/scene.service";
import {CameraView} from "../../models/cameras/cameraView";
import {WireframeElement} from "../../application/wireframeElement";
import {CameraElement} from "../../application/cameraElement";

@Component({
    selector: 'ptv-vertex-thumbnail-3d-item',
    styles: [require('./vertexThumbnail3dItem.component.scss')],
    template: require('./vertexThumbnail3dItem.component.html')
})
export default class VertexThumbnail3dItemComponent implements OnInit, OnDestroy {
    @Input() projectImageModel: ProjectImageModel;
    @Input() vertexElementModel: VertexElementModel;

    visibleElements:WireframeElement[] = [];

    private _zoom: number = 0;
    @Input() set zoom(zoom) {
        this._zoom = zoom;
        this.updateZoom();
    }

    get zoom(): number {
        return this._zoom;
    }

    private vertexElementModelUpdatedSubscription;
    private vertexUpdateCheckCurrentX;
    private vertexUpdateCheckCurrentY;
    private vertexUpdateCheckCurrentZ;
    private isMouseOver = false;

    cameraElement: CameraElement;

    constructor(
        private sceneService: SceneService,
        private zone: NgZone,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.updateWireframeFeatures();
        this.vertexElementModelUpdatedSubscription = this.vertexElementModel.updated.subscribe(this.onVertexElementModelUpdated.bind(this));
        this.saveVertexState();
    }

    ngOnDestroy(): void {
        if (this.vertexElementModelUpdatedSubscription) this.vertexElementModelUpdatedSubscription.unsubscribe();
    }

    private didVertexStateChange() {
        return !(this.vertexUpdateCheckCurrentX == this.vertexElementModel.vertexElement.pvObject.x
            && this.vertexUpdateCheckCurrentY == this.vertexElementModel.vertexElement.pvObject.y
            && this.vertexUpdateCheckCurrentZ == this.vertexElementModel.vertexElement.pvObject.z)
    }

    private saveVertexState() {
        this.vertexUpdateCheckCurrentX = this.vertexElementModel.vertexElement.pvObject.x;
        this.vertexUpdateCheckCurrentY = this.vertexElementModel.vertexElement.pvObject.y;
        this.vertexUpdateCheckCurrentZ = this.vertexElementModel.vertexElement.pvObject.z;
    }

    private updateOnVertexStateChange() {
        if (this.didVertexStateChange()) {
            this.vertexUpdateCheckCurrentX = this.vertexElementModel.vertexElement.pvObject.x;
            this.vertexUpdateCheckCurrentY = this.vertexElementModel.vertexElement.pvObject.y;
            this.vertexUpdateCheckCurrentZ = this.vertexElementModel.vertexElement.pvObject.z;
            // this.updateWireframeFeatures();
        }
    }

    onVertexElementModelUpdated() {
        // this.updateOnVertexStateChange();

        this.updateWireframeFeatures();
    }

    getOptimumCameraScale(cam: CameraView, vert: PV.Vertex): number {
        let vertPos = new Vector3(vert.x, vert.y, vert.z);
        let ssd = cam.computeSSD(vertPos);
        let distanceFromCamera = cam.computeDistance(vertPos);
        let edgeTypeProp = this.vertexElementModel.wireframe.findProperty(EdgeTypeProperty, false);
        let scale: number = 25;
        if (edgeTypeProp) {
            let edgeTypeCounts: Map<EdgeType, number> = new Map();
            let edgeTypeCoef: Map<EdgeType, number> = new Map();

            for (let et in EdgeType) {
                if (!isNaN(parseInt(et))) {
                    edgeTypeCounts[et] = 0;
                    edgeTypeCoef[et] = 0;
                }
            }
            edgeTypeCoef.set(EdgeType.EAVE, 4.93764);
            edgeTypeCoef.set(EdgeType.FLASHING, 0);
            edgeTypeCoef.set(EdgeType.HIP, -0.47703);
            edgeTypeCoef.set(EdgeType.PARAPET, 0);
            edgeTypeCoef.set(EdgeType.RAKE, 3.53440);
            edgeTypeCoef.set(EdgeType.RIDGE, 0.94357);
            edgeTypeCoef.set(EdgeType.VALLEY, 0);
            edgeTypeCoef.set(EdgeType.STEP_FLASHING, -1.51724);
            let scaleConstant: number = 19.31638;
            let edgeCountCoef: number = -3.58719;
            let ssdCoef: number = 4101.09292;
            let distanceCoef: number = -0.15785;

            let edges: number[] = this.vertexElementModel.wireframe.findEdgesForVertex(vert.id);
            for (let edgeId of edges) {
                let et: number = edgeTypeProp.getValue(this.vertexElementModel.wireframe.edges[edgeId]);
                if (null != et) {
                    edgeTypeCounts[et]++
                }
            }

            scale = scaleConstant + (edgeCountCoef * edges.length) + (ssdCoef * ssd) + (distanceCoef * distanceFromCamera);
            for (let et in edgeTypeCoef) {
                scale += edgeTypeCoef[et] * edgeTypeCounts[et];
            }
        } else {
            let scaleConstant: number = 22.57345;
            let edgeCountCoef: number = -3.09635;
            let ssdCoef: number = 3861.41451;
            let distanceCoef: number = -0.13833;
            let edges: number[] = this.vertexElementModel.wireframe.findEdgesForVertex(vert.id);
            scale = scaleConstant + (edgeCountCoef * edges.length) + (ssdCoef * ssd) + (distanceCoef * distanceFromCamera);
        }
        return scale;
    }


    protected updateWireframeFeatures() {
        // show the vertex
        let visibleElements = [];
        visibleElements.push(this.vertexElementModel.vertexElement);

        // show elements dependent on the vertex
        let dependentElements = this.vertexElementModel.vertexElement.getDependentGeometry();
        if(dependentElements && dependentElements.length > 0){
            visibleElements.push(...dependentElements);
        }
        this.visibleElements = visibleElements;
        this.updateVertexProjectionPoint();
    }

    updateVertexProjectionPoint() {
        this.cameraElement = this.projectImageModel.cameraPointView.cameraElement;
    }

    updateZoom() {
        let self = this;

        // optimal camera zoom scale
        let optimalScale = self.getOptimumCameraScale(self.projectImageModel.cameraPointView.cameraView, self.vertexElementModel.vertexElement.pvObject);

        let percentageScale = optimalScale
            ? optimalScale / 100
            : .25; // some reasonable default

        let percentageWidth = self.projectImageModel.width * (percentageScale * ((100 - self.zoom) / 100));
        // todo
    }

    onProjectImageCardClick(mouseEvent) {
        if (!this.projectImageModel) return;
        if (!mouseEvent.ctrlKey || !mouseEvent.shiftKey) return;
        this.projectImageModel.selected = !this.projectImageModel.selected;
        mouseEvent.preventDefault();
        mouseEvent.stopPropagation();
    }

    onProjectImageCardDoubleClick() {
        if (!this.projectImageModel) return;
        this.projectImageModel.focused = true;
    }

    onProjectImageCardMouseEnter() {
        this.isMouseOver = true;
        this.projectImageModel.highlighted = true;
        this.detectChanges();
    }

    onProjectImageCardMouseLeave() {
        this.isMouseOver = false;
        this.projectImageModel.highlighted = false;
        this.detectChanges();
    }

    detectChanges(): void {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }
}