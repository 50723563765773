import {
    ChangeDetectorRef,
    Component,
    Input
} from '@angular/core';
import {ProjectImageModel} from "../../models/projectImages/projectImage.model";

@Component({
    selector: 'project-image-item',
    styles: [require('./projectImageItem.component.scss')],
    template: require('./projectImageItem.component.html')
})
export default class ProjectImageItemComponent {
    @Input() projectImageModel: ProjectImageModel;
    @Input() annotationClasses: Array<string>;

    isMouseOver = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    onProjectImageCardClick(mouseEvent) {
        if (!this.projectImageModel) return;
        if(!mouseEvent.ctrlKey || !mouseEvent.shiftKey) return;
        this.projectImageModel.selected = !this.projectImageModel.selected;
    }

    onProjectImageCardDoubleClick() {
        if (!this.projectImageModel) return;
        this.projectImageModel.focused = true;
    }

    onProjectImageCardMouseEnter() {
        this.isMouseOver = true;
        this.projectImageModel.highlighted = true;
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    onProjectImageCardMouseLeave() {
        this.isMouseOver = false;
        this.projectImageModel.highlighted = false;
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    get projectImageOverlayViewBox():string{
        return '0 0 ' + this.projectImageModel.width + ' ' + this.projectImageModel.height
    }

    get cameraViewPointX():number{
        let x = 0;
        if(!this.projectImageModel) return x;
        let cameraPointView = this.projectImageModel.cameraPointView;
        if(!cameraPointView) return x;
        let imageCoordinates = cameraPointView.imageCoordinates;
        if(!imageCoordinates) return x;
        x = imageCoordinates.x * this.projectImageModel.width;
        return x;
    }

    get cameraViewPointY():number{
        let y = 0;
        if(!this.projectImageModel) return y;
        let cameraPointView = this.projectImageModel.cameraPointView;
        if(!cameraPointView) return y;
        let imageCoordinates = cameraPointView.imageCoordinates;
        if(!imageCoordinates) return y;
        y = imageCoordinates.y * this.projectImageModel.height;
        return y;
    }

    get cameraViewPointRadius():number{
        return 100;
    }
}