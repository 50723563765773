import AWS = require('aws-sdk');
import * as THREE from 'three';
import {PV} from "./wireframe";
import FrameMetadata = PV.FrameMetadata;
import {WireframeApplication} from "./application/wireframeApplication";
import AmazonS3URI = require("amazon-s3-uri/lib/amazon-s3-uri")
import {PointivoS3Singleton} from "./s3Singleton";


export interface Credentials {
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken: string;
    expiration: Date;
}

export class S3Object {
    bucket: string;
    key: string;
    onProgress: Function[] = [];
    onComplete: Function[] = [];

    public toS3URL() {
        return "s3://" + this.bucket + "/" + this.key
    }

    public toURL() {
        return "https://s3.amazonaws.com/" + this.bucket + "/" + this.key
    }
}

export interface ResourceType {
    id: number;
    name: string;
}

export class Registration {
    static readonly TYPE_NATIVE = "native"
    static readonly TYPE_RECONSTRUCTION = "reconstruction"
    static readonly TYPE_GPS = "gps"

    id:number
    type:string
    name:string
    transform:number[]
    contextResourceId:number

    fromJson(json:any) {
        Object.assign(this, json)
    }

    getMat4():THREE.Matrix4 {
        return new THREE.Matrix4().set(this.transform[0], this.transform[1], this.transform[2], this.transform[3],
            this.transform[4], this.transform[5], this.transform[6], this.transform[7],
            this.transform[8], this.transform[9], this.transform[10], this.transform[11],
            this.transform[12], this.transform[13], this.transform[14], this.transform[15]
        )
    }
}

export class CameraRegistration extends Registration {
    projectionType:string
    distortion:number[]
    fxy:number[]
    cxy:number[]
    skew:number
}

export interface Resource {
    id:number
    projectId:number
    name: string
    url: string
    metadata: any
    resourceType: ResourceType
    registrations: Registration[]
    children:number[]
    links:{}
}

export class TransferConsumer {
    private resourceManager: ResourceManager;

    constructor(resManager: ResourceManager) {
        this.resourceManager = resManager;
    }

    public start() {
        let that = this;
        let transfer: S3Object = this.resourceManager.queuedTransfers.shift();
        if (!transfer) return;
        this.resourceManager.numCurrentTransfers++;
        console.log("TransferConsumer starting transfer " + transfer.key);
        this.resourceManager.getImageData(transfer, function () {
                console.log("TransferConsumer finished transfer " + transfer.key);
                that.resourceManager.numCurrentTransfers--;
                that.start();
            },
            null
        );
    }

    public stop() {
    }
}

export class ResourceManager {
    public projectData: any;
    public s3Bucket: string;
    public projectKey: string;
    public credentials: Credentials;
    public db;
    public numCurrentTransfers = 0;
    public maxNumConcurrentTransfers = 1;

    constructor(private app:WireframeApplication) {}

    public setCredentials(creds: Credentials) {
        this.credentials = creds;
        AWS.config.update({
            credentials: this.credentials,
        });
    }

    public imageData: Map<string, string> = new Map<string, string>();
    public activeTransfers: Map<string, S3Object> = new Map<string, S3Object>();
    public queuedTransfers: S3Object[] = [];

    public getS3ObjectForResource(resource: Resource): S3Object {
        let xfer = new S3Object();
        xfer.key = this.projectKey + "/" + resource.url;
        xfer.bucket = this.s3Bucket;
        return xfer;
    }

    public getS3ObjectFromURL(url: String) {
        let s3Uri = AmazonS3URI(url);
        let s3Obj = new S3Object();
        s3Obj.key = s3Uri.key;
        s3Obj.bucket = s3Uri.bucket;
        return s3Obj
    }

    public generatePresignedUrl(s3Object: S3Object) {
        let url = PointivoS3Singleton.newAWSS3Instance().getSignedUrl("getObject", {
            Bucket: s3Object.bucket,
            Key: s3Object.key,
            Expires: 86400 // 24 hours
        });
        return url
    }

    public getFrameKey(frameId: number): S3Object {
        let fmd: FrameMetadata = this.projectData.metadata.rendered["frame-" + frameId];
        if (!fmd) return null;
        let key = this.projectKey + "/metadata/rendered/" + fmd.filename;
        let s3Object = new S3Object()
        s3Object.bucket = this.s3Bucket
        s3Object.key = key
        return s3Object;
    }

    public hasFrameThumbnailKey(frameId:number){
        let fmd: FrameMetadata = this.projectData.metadata.rendered["frame-" + frameId];
        let derivedThumbnailFrame = fmd.derivedFrames["thumb"];
        return !!derivedThumbnailFrame;
    }

    public getFrameThumbnailKey(frameId: number): S3Object {
        let fmd: FrameMetadata = this.projectData.metadata.rendered["frame-" + frameId];
        let key = this.projectKey + "/metadata/rendered/" + fmd.derivedFrames["thumb"].filename;
        let s3Object = new S3Object()
        s3Object.bucket = this.s3Bucket
        s3Object.key = key
        return s3Object;
    }

    public hasFramePrintKey(frameId:number){
        let fmd: FrameMetadata = this.projectData.metadata.rendered["frame-" + frameId];
        let derivedThumbnailFrame = fmd.derivedFrames["print"];
        return !!derivedThumbnailFrame;
    }

    public getFramePrintKey(frameId: number): S3Object {
        let fmd: FrameMetadata = this.projectData.metadata.rendered["frame-" + frameId];
        let key = this.projectKey + "/metadata/rendered/" + fmd.derivedFrames["print"].filename;
        let s3Object = new S3Object()
        s3Object.bucket = this.s3Bucket
        s3Object.key = key
        return s3Object;
    }

    public getDerivedImageUrl(imgResource:Resource, key):string {
        let url:string = imgResource.links["href"]
        url = url.substr(0, url.lastIndexOf("\.")) + "-" + key + "." + url.substr(url.lastIndexOf(".") + 1, url.length)
        return url
    }

    public hasTiledImage(tiledImageId:number){
        let tileImageResource:Resource = this.getTiledImageResource(tiledImageId);
        return !!tileImageResource;
    }

    public getTiledImageResource(tiledImageId:number):Resource{
        let tileImageResource:Resource = null;
        Object.values(this.projectData.resources).forEach((res:Resource) => {
            if (res.resourceType.name != "TILED_IMAGE") return;
            let metadata = res.metadata;
            if (!metadata) return;
            let imageMetadata = metadata.imageMetadata;
            if (!imageMetadata) return;
            let frameId = imageMetadata.frameId;
            if(frameId == tiledImageId){
                tileImageResource = res;
            }
        });
        return tileImageResource;
    }

    public getTiledImageMetadata(tiledImageId:number):any{
        let self = this;
        let tileMetadata = null;
        Object.values(this.projectData.resources).forEach((res:Resource) => {
            if (res.resourceType.name != "TILED_IMAGE") return;
            let metadata = res.metadata;
            if (!metadata) return;
            let imageMetadata = metadata.imageMetadata;
            if (!imageMetadata) return;
            let frameId = imageMetadata.frameId;
            if(frameId == tiledImageId){
                tileMetadata = metadata.tileMetadata;
                if (!tileMetadata) return tileMetadata;
                tileMetadata.getAuthorizedUrl = (url) => {
                    let presignedUrl = null;
                    let s3Object = self.getS3ObjectFromURL(url);
                    if (!s3Object) return presignedUrl;
                    presignedUrl = self.generatePresignedUrl(s3Object);
                    return presignedUrl;
                }
            }
        });
        return tileMetadata;
    }

    public getTiledImageHref(tiledImageId: number): string {
        let tileImageResource:Resource = this.getTiledImageResource(tiledImageId);
        if(!tileImageResource) return null;
        let links = (tileImageResource as any).links;
        if(!links) return;
        let href = links.href;
        return href;
    }

    public getObjectListing(s3Object:S3Object, callback:Function) {
        let that = this;
        let params = {
            Delimiter: "/",
            Prefix: s3Object.key + "/",
            Bucket: s3Object.bucket
        };
        let req = PointivoS3Singleton.newAWSS3Instance().listObjects(params, function (err, data) {
            console.log("listObjects " + s3Object.key, data);
            console.log("err", err)

        });
    }

    public getImageData(s3Object: S3Object, onComplete: Function, onProgress: Function) {
        let that = this;
        let existingImg = this.imageData[s3Object.key];
        if (existingImg) {
            onComplete.apply(this, [existingImg]);
        } else {
            let existingTransfer: S3Object = this.activeTransfers[s3Object.key];
            if (existingTransfer) {
                //console.log("found active transfer for " + s3Object.key);
                if (onProgress) existingTransfer.onProgress.push(onProgress);
                if (onComplete) existingTransfer.onComplete.push(function () {
                    onComplete.apply(this, [that.imageData[s3Object.key]]);
                });
            } else {
                s3Object.onComplete.unshift(function (err, data) {
                    if (data && data.Body) {
                        let blob = new Blob([data.Body], {type: data.ContentType});
                        that.imageData[s3Object.key] = window.URL.createObjectURL(blob);
                        /*
                        let link = document.createElement('link');
                        link.rel = "prefetch";
                        link.href = that.imageData[s3Object.key];
                        document.body.appendChild(link);
                        */
                    }
                    //if (onComplete) onComplete.apply(this, [that.imageData[s3Object.key]]);
                });
                if (onComplete) s3Object.onComplete.push(function () {
                    onComplete.apply(this, [that.imageData[s3Object.key]])
                });
                if (onProgress) s3Object.onProgress.push(function (o) {
                    onProgress.apply(this, [o]);
                });
                this.downloadS3Key(s3Object);
            }
        }
    }

    public downloadS3Key(s3Object: S3Object) {
        this.activeTransfers[s3Object.key] = s3Object;
        let that = this;
        let params = {
            Key: s3Object.key,
            Bucket: s3Object.bucket
        };
        let req = PointivoS3Singleton.newAWSS3Instance().getObject(params, function (err, data) {
            //console.log("download " + s3Object.key, data);
            if (s3Object.onComplete) s3Object.onComplete.forEach(function (fn) {
                fn.apply(this, [err, data])
            });
            delete that.activeTransfers[s3Object.key];
            that.app.eventEmitter.broadcast("transferComplete", s3Object);
        });
        req.on('httpDownloadProgress', function (progress, response) {
            //if (s3Object.onProgress) s3Object.onProgress.apply(this, [progress, response]);
            if (s3Object.onProgress) s3Object.onProgress.forEach(function (fn) {
                fn.apply(this, [progress, response])
            });
        })
    }

    public enqueueTransfer(transfer: S3Object) {
        this.queuedTransfers.push(transfer);
        this.consumeQueuedTransfers();
    }

    public consumeQueuedTransfers() {
        let numToStart = this.maxNumConcurrentTransfers - this.numCurrentTransfers;
        if (numToStart > 0) {
            for (let i = 0; i < numToStart; i++) {
                let that = this;
                let transfer: S3Object = this.queuedTransfers.shift();
                if (!transfer) return;
                this.numCurrentTransfers++;
                //console.log("TransferConsumer starting transfer " + transfer.key);
                this.getImageData(transfer, function () {
                        //console.log("TransferConsumer finished transfer " + transfer.key);
                        that.numCurrentTransfers--;
                        that.consumeQueuedTransfers();
                    },
                    null
                );
            }
        }
    }

}
