import $ from "jquery";
import {WireframeApplication} from "./application/wireframeApplication";
import {CameraView} from "./models/cameras/cameraView";
import {WireframeElementType} from "./application/wireframeElementType";
import * as THREE from 'three';
import {WireframeOperations} from "./wireframeOperations";
import PVUtils from "./pvUtils";
import {WireframeElement} from "./application/wireframeElement";
import {VerifiedProperty} from "./models/property/verifiedProperty";
import {VerifiedState} from "./models/property/verifiedState";
import {S3Object} from "./resourceManager";
import {PV} from "./wireframe";
import ComponentMap = PV.ComponentMap;
import FrameMetadata = PV.FrameMetadata;
import {EdgeTypeProperty} from "./models/property/edgeTypeProperty";
import {EdgeType} from "./models/property/edgeType";

declare var viewer: any;
declare var MWPR: any;
declare var Projection: any;

export class CanvasOperations {

    private app:WireframeApplication
    private adjustCtrl:any
    constructor(app:WireframeApplication, adjustCtrl:any) {
        this.app = app
        this.adjustCtrl = adjustCtrl
    }


    public canvasPoint3D

    markVertexVerified(el: WireframeElement, isVerified) {
        let verifyProp: VerifiedProperty = this.app.wireframe.findProperty(VerifiedProperty, true) as VerifiedProperty;
        if (verifyProp) {
            verifyProp.setValue(el, isVerified ? VerifiedState.VERIFIED : VerifiedState.UNVERIFIED);
            this.app.propertiesChanged();
        }
    }


    /** Downloads optimal images for each vertex */
    public downloadOptimalFrames() {
        let verts: ComponentMap = this.app.wireframe.filterGeometry(this.app.wireframe.vertices);
        let frames: number[] = [];
        for (let vert of Object.values(verts)) {
            let vertFrames = this.getOptimalViewsForVertex(vert).map((o) => Number(o));
            frames.push(...vertFrames);
        }
        frames = frames.filter(function (o, pos) {
            return frames.indexOf(o) == pos
        });
        for (let frameId of frames) {
            let key: S3Object = this.app.resourceManager.getFrameKey(frameId);
            if (this.app.resourceManager.imageData[key.key]) continue;
            this.app.resourceManager.enqueueTransfer(key);
        }
    }

    public getOptimalViewsForVertex(vert: PV.Vertex, numViews: number = 3): number[] {
        let frames = this.adjustCtrl.pointVisibility.getPoint3DFrames(vert, false);
        return this.adjustCtrl.canvasOperations.getViewsForPoint(vert, frames);
    }

    public downloadAllFrames() {
        let frames: Map<string, FrameMetadata> = this.app.projectData.metadata.rendered;
        for (let frameId of Object.keys(frames)) {
            let fmd: FrameMetadata = frames[frameId];
            let key: S3Object = this.app.resourceManager.getFrameKey(fmd.frameId);
            if (this.app.resourceManager.imageData[key.key]) continue;
            this.app.resourceManager.enqueueTransfer(key);
        }

    }


    getViewByIndex(cameraIndex: number) {
        let cameraIndexNumber = Number(cameraIndex);
        return this.app.projectData.metadata.reconstructed.views["view-" + cameraIndexNumber];
    }

    getNextCameraIndex(cameraIndex) {
        let hframes = (this.adjustCtrl.adjustVertex as any).hframes;
        for (let i = 0; i < hframes.length; i++) {
            if (hframes[i] == Number(cameraIndex)) {
                if (i == hframes.length - 1) {
                    return hframes[0];
                }
                return hframes[i + 1];
            }
        }
        return hframes[0];

    }

    getPreviousCameraIndex(cameraIndex) {
        let hframes = (this.adjustCtrl.adjustVertex as any).hframes;
        for (let i = 0; i < hframes.length; i++) {
            if (hframes[i] == Number(cameraIndex)) {
                if (i == 0) {
                    return hframes[hframes.length - 1];
                }
                return hframes[i - 1];
            }
        }
    }



    //fill id'th canvas element with it's frame and draggable lines and points
    //cameraIndex: the cameraIndex of the frame
    //id : canvas id
    //linePoints : lines that sould be drawn on image (a line defined with [src,des] )
    //point : the source of all lines that will be drawn on canvas
    fillAdjustCanvas(cameraIndex, id, point, linePoints) {
        this.adjustCtrl.canvasOperations.addImageAndLinesToCanvasElement(id, point.x, point.y, linePoints, cameraIndex);
    }

    // add dragable lines and points with zoom capability to canvas
    //canvas: the canvas that will be drawn on it
    //image : tha image tht will be drawn on canvas
    //id : canvas id
    //linePoints : lines that sould be drawn on image (a line defined with [src,des] )
    //xFactor,yFactor : that factor determined canvaswidth/realImageWidth
    //cameraObject: cotain camera parameters like focal length
    addLinesToCanvas(canvasIndex, canvas, image, id, linePoints, xFactor, yFactor, w, h, cameraObject: CameraView) {
        let that = this
        this.adjustCtrl.adjustViews[canvasIndex].canvas = canvas;
        this.adjustCtrl.adjustViews[canvasIndex].redrawFunc = function() {
            //canvas.zoomAndPointOnCenter(canvas.preferedScale, canvas.preferedScale)
            redraw();
        }
        this.adjustCtrl.adjustViews[canvasIndex].cameraObject = cameraObject;

        if (id == "imageFullScreen1") {
            this.adjustCtrl.cameraObject1 = cameraObject;
            this.adjustCtrl.canvas1 = canvas;
        }

        if (id == "imageFullScreen2") {
            this.adjustCtrl.cameraObject2 = cameraObject;
            this.adjustCtrl.canvas2 = canvas;
        }

        if (id == "imageFullScreen3") {
            this.adjustCtrl.cameraObject3 = cameraObject;
            this.adjustCtrl.canvas3 = canvas;
        }

        let ctx = canvas.getContext('2d');
        ctx.resetTransform();
        canvas.currentScale = 1;

        unBindDragLines();
        unBindZoom();
        unBindDragPoint();
        bindDragLines();
        bindZoom();

        let addpoint = [];
        let lines = [];
        this.adjustCtrl.dictLines[id] = lines;

        for (let i = 0; i < linePoints.length; i++) {
            let line = linePoints[i];
            lines.push({
                x0: line[0].x * xFactor,
                y0: line[0].y * yFactor,
                x1: line[1].x * xFactor,
                y1: line[1].y * yFactor
            });
        }

        let cw = canvas.width;
        let ch = canvas.height;
        let offsetX, offsetY;
        function reOffset() {
            let BB = canvas.getBoundingClientRect();
            offsetX = BB.left;
            offsetY = BB.top;
        }
        reOffset();
        window.onscroll = function (e) {
            reOffset();
        };
        window.onresize = function (e) {
            reOffset();
        };

        // dragging vars
        var isDown = false;
        var startX, startY;

        // line vars
        var nearest;
        var point = new THREE.Vector2(w, h);
        var graph = {} as any;
        this.adjustCtrl.dictGraphs[id] = graph;
        this.adjustCtrl.adjustViews[canvasIndex].graph = graph;

        graph['vertices'] = [];
        var origin = {};
        origin['x'] = point.x * xFactor;
        origin['y'] = point.y * yFactor;
        origin['edges'] = [];
        for (var i = 1; i <= linePoints.length; i++) {
            origin['edges'].push(i);
        }
        graph['vertices'].push(origin);

        for (var i = 0; i < linePoints.length; i++) {
            var line = linePoints[i];
            if (line[0].x != w || line[0].y != h) {
                var v = {};
                v['x'] = line[0].x * xFactor;
                v['y'] = line[0].y * yFactor;
                v['edges'] = [0];
                graph['vertices'].push(v);
            }
            if (line[1].x != w || line[1].y != h) {

                var v = {};
                v['x'] = line[1].x * xFactor;
                v['y'] = line[1].y * yFactor;
                v['edges'] = [0];
                graph['vertices'].push(v);
            }
        }

        graph.edgelist = (function (G) {
            var i, j, edges = [],
                e;
            var contains = function (a, e) {
                var i;
                for (i = 0; i < a.length; i += 1) {
                    if (a[i].n === e.n && a[i].m === e.m) {
                        return true;
                    }
                    if (a[i].m === e.n && a[i].n === e.m) {
                        return true;
                    }
                }
                return false;
            };
            for (i = 0; i < G.vertices.length; i += 1) {
                for (j = 0; j < G.vertices[i].edges.length; j += 1) {
                    e = {
                        n: i,
                        m: G.vertices[i].edges[j]
                    };
                    if (!contains(edges, {
                        n: i,
                        m: G.vertices[i].edges[j]
                    })) {
                        edges.push(e);
                    }
                }
            }
            return edges;
        })(graph);

        graph.draw = function (ctx) {
            var i;
            var n, m;

            ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, canvas.width, canvas.height);
            for (i = 0; i < this.edgelist.length; i += 1) {
                n = this.vertices[this.edgelist[i].n];
                m = this.vertices[this.edgelist[i].m];
                drawEdge(ctx, n.x, n.y, m.x, m.y);
            }
            for (i = 0; i < this.vertices.length; i += 1) {
                n = this.vertices[i];

                if (n.x == origin['x'] && n.y == origin['y']) {
                    var length = that.adjustCtrl.mouseDownStack.length;

                    //if (app.elementMode == WireframeElementType.vertex) {
                    if (true) {
                        if (viewer.reconstructMode == "plane") {
                            drawNode(ctx, n.x, n.y, n.isGrabbed ? 'red' : 'yellow');
                        }
                        if (viewer.reconstructMode == "triangulation") {
                            if (that.adjustCtrl.mouseDownStack[length - 1] == id || that.adjustCtrl.mouseDownStack[length - 2] == id) {
                                drawNode(ctx, n.x, n.y, n.isGrabbed ? 'red' : 'red');
                            }
                            else {
                                drawNode(ctx, n.x, n.y, n.isGrabbed ? 'red' : 'yellow');
                            }
                        }
                    }
                    else {
                        drawNode(ctx, n.x, n.y, n.isGrabbed ? 'white' : 'red');
                    }
                }
            }
        };

        var drawNode = function (ctx, x, y, color) {
            ctx.fillStyle = color;
            ctx.beginPath();
            ctx.arc(x, y, 3 / canvas.currentScale, 0, Math.PI * 2, true);
            ctx.fill();
            ctx.strokeStyle = "green";
            ctx.beginPath();
            ctx.arc(x, y, 3 * 3 / canvas.currentScale, 0, Math.PI * 2, true);
            ctx.stroke();
        };

        var drawEdge = function (ctx, x0, y0, x1, y1) {
            ctx.beginPath();
            ctx.moveTo(x0, y0);
            ctx.lineTo(x1, y1);
            ctx.lineWidth = 3 / canvas.currentScale;

            if (viewer.adjustAngle < that.adjustCtrl.goodAngleThreshold && viewer.adjustAngle > that.adjustCtrl.normalAngleThreshold) {
                ctx.strokeStyle = 'yellow';
            }
            else if (viewer.adjustAngle < that.adjustCtrl.normalAngleThreshold) {
                ctx.strokeStyle = 'red';
            }
            else if (viewer.adjustAngle > that.adjustCtrl.goodAngleThreshold) {
                ctx.strokeStyle = 'green';
            }

            ctx.stroke();
        };

        graph.getTarget = function (x, y) {
            var i;
            for (i = 0; i < this.vertices.length; i += 1) {
                if (contains(this.vertices[i], x, y)) {
                    return this.vertices[i];
                }
            }

            return undefined;
        };

        var contains = function (vertex, x, y) {
            var dx, dy;
            dx = x - vertex.x;
            dy = y - vertex.y;
            return (Math.sqrt(dx * dx + dy * dy) < 10);
        };

        var x_grabOffset, y_grabOffset;

        function graphMousedown(event) {
            that.app.vertexPositionAdjusted = true;

            if (that.adjustCtrl.mouseDownStack.length > 0) {
                if (that.adjustCtrl.mouseDownStack[that.adjustCtrl.mouseDownStack.length - 1] != id) {
                    that.adjustCtrl.mouseDownStack.push(id);
                }

            }
            else {
                that.adjustCtrl.mouseDownStack.push(id);

            }

            var e = event;
            var isRightMB;
            e = e || window.event;

            if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
                isRightMB = e.which == 3;
            else if ("button" in e)  // IE, Opera
                isRightMB = e.button == 2;

            if (isRightMB) {
                return;
            } else if (event.ctrlKey) {
                if (this.adjustCtrl.canvasOperations.canvasPoint3D.hframes.length > 3) {
                    that.adjustCtrl.adjustSection.nextFrame(Number(id.substr(15)));
                }
                return;
            } else if (event.shiftKey) {
                if (this.adjustCtrl.canvasOperations.canvasPoint3D.hframes.length > 3) {
                    that.adjustCtrl.adjustSection.previousFrame(Number(id.substr(15)));
                }
                return;
            }

            var ctx = canvas.getContext('2d');

            var offset, x, y, circle, target, search, ctx;
            offset = $(this).offset();

            x = event.pageX - offset.left;
            y = event.pageY - offset.top;
            var temp = ctx.transformedPoint(x, y);

            //if (app.hasMode(ActionMode.CREATE, WireframeElementType.vertex)) {
            if (true) {
                v = graph.vertices[0];
                (v as any).x = temp['x'];
                (v as any).y = temp['y'];

                var newObject = {cameraObject: cameraObject, point: [temp['x'] / xFactor, temp['y'] / yFactor]};
                if (that.adjustCtrl.pointsForTriangulation.length < 2) {
                    that.adjustCtrl.pointsForTriangulation.push(newObject);
                }
                else {
                    that.adjustCtrl.pointsForTriangulation[0] = that.adjustCtrl.pointsForTriangulation[1];
                    that.adjustCtrl.pointsForTriangulation[1] = newObject;
                }
                if (that.adjustCtrl.pointsForTriangulation.length == 2) {
                    var angle_ = {} as any;
                    angle_.angle = 0;
                    var camera_views = [];
                    var match_points = [];
                    camera_views.push(that.adjustCtrl.pointsForTriangulation[0].cameraObject);
                    camera_views.push(that.adjustCtrl.pointsForTriangulation[1].cameraObject);

                    match_points.push(that.adjustCtrl.pointsForTriangulation[0].point);
                    match_points.push(that.adjustCtrl.pointsForTriangulation[1].point);

                    var point3D = MWPR.Multi_View_Point_Reconstruction_Out_Angle(match_points, camera_views, true, angle_);
                }

                addpoint = [temp['x'], temp['y']];
            }

            target = graph.getTarget(temp['x'], temp['y']);

            if (target !== undefined) {
                target.isGrabbed = true;
                x_grabOffset = target.x - temp['x'];
                y_grabOffset = target.y - temp['y'];
            }

            ctx = ($('#' + id)[0] as any).getContext("2d");
            ctx.clearRect(0, 0, 800, 500);
            graph.draw(ctx);
        }

        function graphMouseup(event) {
            if (canvas.startPos == null) {
                canvas.startPos = [that.adjustCtrl.canvasOperations.canvasPoint3D.x, that.adjustCtrl.canvasOperations.canvasPoint3D.y, that.adjustCtrl.canvasOperations.canvasPoint3D.z];
            }

            PVUtils.clearSelection();

            let move = graphMousemove.bind(this);
            move(event);

            var e = event;
            var isRightMB;
            e = e || window.event;

            if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
                isRightMB = e.which == 3;
            else if ("button" in e)  // IE, Opera
                isRightMB = e.button == 2;

            if (isRightMB) {
                return;
            }
            var i;
            for (i = 0; i < graph.vertices.length; i += 1) {
                graph.vertices[i].isGrabbed = false;
            }
            ctx = ($('#' + id)[0] as any).getContext("2d");
            ctx.clearRect(0, 0, 800, 500);
            graph.draw(ctx);
            canvas.startPos = viewer.reconstructedPoint;
        }


        function getTwoActiveGraphVertex() {
            var graph1 = this.adjustCtrl.dictGraphs["imageFullScreen1"];
            var graph2 = this.adjustCtrl.dictGraphs["imageFullScreen2"];
            var graph3 = this.adjustCtrl.dictGraphs["imageFullScreen3"];
            var v1 = graph1.vertices[i];
            var v2 = graph2.vertices[i];
            var v3 = graph3.vertices[i];
            var secondId;

            if (this.adjustCtrl.mouseDownStack.length < 2) {
                secondId = "imageFullScreen" + ((Number(id.substr(15))) % 3 + 1);
            }
            else {
                secondId = this.adjustCtrl.mouseDownStack[this.adjustCtrl.mouseDownStack.length - 2];
            }

            var firstVertex, secondVertex, thirdVertex;
            var firstCamera, secondCamera, thirdCamera;
            if (secondId == "imageFullScreen1") {
                secondCamera = this.adjustCtrl.cameraObject1;
                secondVertex = v1;
                if (id == "imageFullScreen2") {
                    thirdVertex = v3;
                    thirdCamera = this.adjustCtrl.cameraObject3;
                }
                else {
                    thirdVertex = v2;
                    thirdCamera = this.adjustCtrl.cameraObject2;
                }
            }
            else if (secondId == "imageFullScreen2") {
                secondCamera = this.adjustCtrl.cameraObject2;
                secondVertex = v2;
                if (id == "imageFullScreen3") {
                    thirdVertex = v1;
                    thirdCamera = this.adjustCtrl.cameraObject1;
                }
                else {
                    thirdVertex = v3;
                    thirdCamera = this.adjustCtrl.cameraObject3;

                }
            }
            else if (secondId == "imageFullScreen3") {
                secondCamera = this.adjustCtrl.cameraObject3;
                secondVertex = v3;
                if (id == "imageFullScreen2") {
                    thirdVertex = v1;
                    thirdCamera = this.adjustCtrl.cameraObject1;

                }
                else {
                    thirdVertex = v2;
                    thirdCamera = this.adjustCtrl.cameraObject2;
                }
            }
        }

        function graphMouseDblClick(event) {
            isDown = true;
            graph.vertices[0].isGrabbed = true;
            let mouseUp = graphMouseup.bind(this);
            mouseUp(event);
        }

        function graphMousemove(event) {
            if (!that.adjustCtrl.adjustVertex) return

            if (that.adjustCtrl.mouseDownStack.length > 1) {
                viewer.reconstructMode = "triangulation";
            }
            var e = event;
            var isRightMB;
            e = e || window.event;

            if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
                isRightMB = e.which == 3;
            else if ("button" in e)  // IE, Opera
                isRightMB = e.button == 2;

            if (isRightMB) {
                return;
            }

            var graph1 = that.adjustCtrl.dictGraphs["imageFullScreen1"];
            var graph2 = that.adjustCtrl.dictGraphs["imageFullScreen2"];
            var graph3 = that.adjustCtrl.dictGraphs["imageFullScreen3"];

            var v, grabbed, offset, x, y;
            var frameCount = (that.adjustCtrl.adjustVertex as any).hframes.length;

            for (var i = 0; i < graph.vertices.length; i++) {
                v = graph.vertices[i];

                if (v.isGrabbed === true) {

                    that.app.vertexPositionAdjusted = true;

                    grabbed = v;

                    offset = $(this).offset();
                    x = event.pageX - offset.left;
                    y = event.pageY - offset.top;

                    var temp = ctx.transformedPoint(x, y);

                    var v1, v2, v3;

                    if (that.adjustCtrl.canvas1 != null)
                        v1 = graph1.vertices[i];
                    if (that.adjustCtrl.canvas2 != null)
                        v2 = graph2.vertices[i];
                    if (that.adjustCtrl.canvas3 != null)
                        v3 = graph3.vertices[i];

                    var dx = temp['x'] - x_grabOffset - grabbed.x;
                    var dy = temp['y'] - y_grabOffset - grabbed.y;
                    var newX = (dx + v.x) / xFactor;
                    var newY = (dy + v.y) / yFactor;
                    var newVertex = [newX, newY];
                    var point3D;

                    if (true) {

                        var lockedPlane = that.app.getFirstLockedElement(WireframeElementType.plane);

                        var triangulationMode = that.adjustCtrl.triangulationMode;
                        var triangulationTechnique = "default";

                        if(!lockedPlane) {
                            if(frameCount >= 2) {
                                triangulationTechnique = "twoImage";
                            }
                        } else if (lockedPlane){
                            if(triangulationMode == 1) {
                                triangulationTechnique = "lockedPlane"
                            } else {
                                if(frameCount >= 2) {
                                    triangulationTechnique = "twoImage";
                                }
                            }
                        }

                        switch(triangulationTechnique){
                            case 'twoImage':
                            {
                                var secondId;
                                if (that.adjustCtrl.mouseDownStack.length < 2) {
                                    secondId = "imageFullScreen" + ((Number(id.substr(15))) % 3 + 1);
                                }
                                else {
                                    secondId = that.adjustCtrl.mouseDownStack[that.adjustCtrl.mouseDownStack.length - 2];
                                }

                                var firstVertex, secondVertex, thirdVertex;
                                var firstCamera: CameraView, secondCamera: CameraView, thirdCamera: CameraView;

                                if (secondId == "imageFullScreen1") {
                                    secondCamera = that.adjustCtrl.cameraObject1;
                                    secondVertex = v1;

                                    if (id == "imageFullScreen2") {
                                        thirdVertex = v3;
                                        thirdCamera = that.adjustCtrl.cameraObject3;
                                        firstVertex = v2;
                                        firstCamera = that.adjustCtrl.cameraObject2;
                                    }
                                    else {
                                        thirdVertex = v2;
                                        thirdCamera = that.adjustCtrl.cameraObject2;
                                        firstVertex = v3;
                                        firstCamera = that.adjustCtrl.cameraObject3;
                                    }
                                }
                                else if (secondId == "imageFullScreen2") {
                                    secondCamera = that.adjustCtrl.cameraObject2;
                                    secondVertex = v2;

                                    if (id == "imageFullScreen3") {
                                        thirdVertex = v1;
                                        thirdCamera = that.adjustCtrl.cameraObject1;
                                        firstVertex = v3;
                                        firstCamera = that.adjustCtrl.cameraObject3;
                                    }
                                    else {
                                        thirdVertex = v3;
                                        thirdCamera = that.adjustCtrl.cameraObject3;
                                        firstVertex = v1;
                                        firstCamera = that.adjustCtrl.cameraObject1;
                                    }
                                }
                                else if (secondId == "imageFullScreen3") {
                                    secondCamera = that.adjustCtrl.cameraObject3;
                                    secondVertex = v3;
                                    if (id == "imageFullScreen2") {
                                        thirdVertex = v1;
                                        thirdCamera = that.adjustCtrl.cameraObject1;
                                        firstVertex = v2;
                                        firstCamera = that.adjustCtrl.cameraObject2;
                                    }
                                    else {
                                        thirdVertex = v2;
                                        thirdCamera = that.adjustCtrl.cameraObject2;
                                        firstVertex = v1;
                                        firstCamera = that.adjustCtrl.cameraObject1;
                                    }
                                }

                                var cameras = [];
                                var points = [];

                                if (that.adjustCtrl.triangulationMode == 2) {
                                    cameras = [secondCamera, cameraObject];
                                    points = [
                                        [secondVertex.x / secondCamera.xFactor, secondVertex.y / secondCamera.yFactor],
                                        [newX, newY]
                                    ];
                                } else {
                                    cameras = [thirdCamera, secondCamera, cameraObject];
                                    points = [
                                        [thirdVertex.x / thirdCamera.xFactor, thirdVertex.y / thirdCamera.yFactor],
                                        [secondVertex.x / secondCamera.xFactor, secondVertex.y / secondCamera.yFactor],
                                        [newX, newY]
                                    ];
                                }

                                point3D = MWPR.Multi_View_Point_Reconstruction_Out_Angle(
                                    points,
                                    cameras,
                                    true,
                                    {});

                                viewer.reconstructedPoint = point3D;

                                var angle = {} as any;
                                MWPR.Check_Point_Set_Degeneracy_Out_Angle(
                                    points,
                                    cameras,
                                    2,
                                    angle
                                );
                                viewer.adjustAngle = angle.angle;
                                var projected = Projection.Project_Point_With_Distortion(point3D, thirdCamera.rotation, thirdCamera.translation, thirdCamera.skew, thirdCamera.fx, thirdCamera.fy, thirdCamera.cx, thirdCamera.cy, thirdCamera.distortion, thirdCamera.projectionType);


                                graph.vertices[0].x = (dx + v.x);
                                graph.vertices[0].y = (dy + v.y);

                                if (that.adjustCtrl.triangulationMode == 2) {
                                    thirdVertex.x = projected[0] * thirdCamera.xFactor;
                                    thirdVertex.y = projected[1] * thirdCamera.yFactor;
                                }
                            }
                            break;

                            case 'lockedPlane':
                            {
                                // if enabled, the mouse point is locked as one of the plane points
                                var lockMousePoint = false;

                                // given the locked plane
                                var plane3 = that.app.wireframe.getPlane3(lockedPlane.pvObject.id);
                                var planeArr = [plane3.normal.x, plane3.normal.y, plane3.normal.z, plane3.constant];

                                // project a point from the 2D image canvas to the 3D plane given the perspective of the active camera
                                point3D = Projection.Find3DPointOnPlaneFrom2DPointWithCameraView([newX, newY], cameraObject, planeArr);
                                viewer.reconstructedPoint = point3D;

                                // project the 3d point back to the 2D image canvas from each camera's perspective
                                var p1 = Projection.Project_Point_With_Distortion(point3D, that.adjustCtrl.cameraObject1.rotation, that.adjustCtrl.cameraObject1.translation, that.adjustCtrl.cameraObject1.skew, that.adjustCtrl.cameraObject1.fx, that.adjustCtrl.cameraObject1.fy, that.adjustCtrl.cameraObject1.cx, that.adjustCtrl.cameraObject1.cy, that.adjustCtrl.cameraObject1.distortion, that.adjustCtrl.cameraObject1.projectionType);
                                var p2 = Projection.Project_Point_With_Distortion(point3D, that.adjustCtrl.cameraObject2.rotation, that.adjustCtrl.cameraObject2.translation, that.adjustCtrl.cameraObject2.skew, that.adjustCtrl.cameraObject2.fx, that.adjustCtrl.cameraObject2.fy, that.adjustCtrl.cameraObject2.cx, that.adjustCtrl.cameraObject2.cy, that.adjustCtrl.cameraObject2.distortion, that.adjustCtrl.cameraObject2.projectionType);
                                var p3 = Projection.Project_Point_With_Distortion(point3D, that.adjustCtrl.cameraObject3.rotation, that.adjustCtrl.cameraObject3.translation, that.adjustCtrl.cameraObject3.skew, that.adjustCtrl.cameraObject3.fx, that.adjustCtrl.cameraObject3.fy, that.adjustCtrl.cameraObject3.cx, that.adjustCtrl.cameraObject3.cy, that.adjustCtrl.cameraObject3.distortion, that.adjustCtrl.cameraObject3.projectionType);

                                let calculateProjectionError = (expectedX, expectedY, projectionPoint, projectionCameraObject) =>{
                                    let scaledProjectionPointX = projectionPoint[0] * projectionCameraObject.xFactor;
                                    let scaledProjectionPointY = projectionPoint[1] * projectionCameraObject.yFactor;
                                    let scaledExpectedPointX = expectedX * projectionCameraObject.xFactor;
                                    let scaledExpectedPointY = expectedY * projectionCameraObject.yFactor;
                                    let projectionErrorPointX = scaledExpectedPointX - scaledProjectionPointX;
                                    let projectionErrorPointY = scaledExpectedPointY - scaledProjectionPointY;
                                    return {
                                        projectionErrorX:projectionErrorPointX,
                                        projectionErrorY: projectionErrorPointY,
                                        projectionCameraObject:projectionCameraObject
                                    };
                                };

                                let logProjectionError = (projectionError) =>{
                                    console.log('Projection Error', 'x:', projectionError.projectionErrorX, 'y:', projectionError.projectionErrorY);
                                    console.log(JSON.stringify(projectionError.projectionCameraObject, null, 2));
                                };

                                let projectionErrorWarningLevel = 1;

                                if (that.adjustCtrl.canvas1 != null) {
                                    if (lockMousePoint && v1.isGrabbed) {
                                        v1.x = newX * that.adjustCtrl.cameraObject1.xFactor;
                                        v1.y = newY * that.adjustCtrl.cameraObject1.yFactor;

                                        let projectionError = calculateProjectionError(newX, newY, p1, that.adjustCtrl.cameraObject1);
                                        if(projectionError.projectionErrorX > projectionErrorWarningLevel
                                            || projectionError.projectionErrorY > projectionErrorWarningLevel) {
                                            logProjectionError(projectionError);
                                        }

                                    } else {
                                        v1.x = p1[0] * that.adjustCtrl.cameraObject1.xFactor;
                                        v1.y = p1[1] * that.adjustCtrl.cameraObject1.yFactor;
                                    }
                                }
                                if (that.adjustCtrl.canvas2 != null) {
                                    if (lockMousePoint && v2.isGrabbed) {
                                        v2.x = newX * that.adjustCtrl.cameraObject2.xFactor;
                                        v2.y = newY * that.adjustCtrl.cameraObject2.yFactor;

                                        let projectionError = calculateProjectionError(newX, newY, p2, that.adjustCtrl.cameraObject2);
                                        if(projectionError.projectionErrorX > projectionErrorWarningLevel
                                            || projectionError.projectionErrorY > projectionErrorWarningLevel) {
                                            logProjectionError(projectionError);
                                        }

                                    } else {
                                        v2.x = p2[0] * that.adjustCtrl.cameraObject2.xFactor;
                                        v2.y = p2[1] * that.adjustCtrl.cameraObject2.yFactor;
                                    }
                                }
                                if (that.adjustCtrl.canvas3 != null) {
                                    if (lockMousePoint && v3.isGrabbed) {
                                        v3.x = newX * that.adjustCtrl.cameraObject3.xFactor;
                                        v3.y = newY * that.adjustCtrl.cameraObject3.yFactor;

                                        let projectionError = calculateProjectionError(newX, newY, p3, that.adjustCtrl.cameraObject3);
                                        if(projectionError.projectionErrorX > projectionErrorWarningLevel
                                            || projectionError.projectionErrorY > projectionErrorWarningLevel) {
                                            logProjectionError(projectionError);
                                        }

                                    } else {
                                        v3.x = p3[0] * that.adjustCtrl.cameraObject3.xFactor;
                                        v3.y = p3[1] * that.adjustCtrl.cameraObject3.yFactor;
                                    }
                                }
                            }
                            break;

                            case 'default':
                            default:
                            {
                                // todo: default triangulation technique
                            }
                            break;
                        }
                    }
                    else {
                        var planes = that.adjustCtrl.adjustVertex;
                        var planeId = (planes as any).bestPlaneIdx;
                        var planeObj = planes[planeId];
                        planeArr = [planeObj.a, planeObj.b, planeObj.c, planeObj.d];
                        point3D = Projection.Find3DPointOnPlaneFrom2DPointWithCameraView([(dx + v.x) / xFactor, (dy + v.y) / yFactor], cameraObject, planeArr);
                        var p1 = Projection.Project_Point_With_Distortion(point3D, that.adjustCtrl.cameraObject1.rotation, that.adjustCtrl.cameraObject1.translation, that.adjustCtrl.cameraObject1.skew, that.adjustCtrl.cameraObject1.fx, that.adjustCtrl.cameraObject1.fy, that.adjustCtrl.cameraObject1.cx, that.adjustCtrl.cameraObject1.cy, that.adjustCtrl.cameraObject1.distortion, that.adjustCtrl.cameraObject1.projectionType);
                        var p2 = Projection.Project_Point_With_Distortion(point3D, that.adjustCtrl.cameraObject2.rotation, that.adjustCtrl.cameraObject2.translation, that.adjustCtrl.cameraObject2.skew, that.adjustCtrl.cameraObject2.fx, that.adjustCtrl.cameraObject2.fy, that.adjustCtrl.cameraObject2.cx, that.adjustCtrl.cameraObject2.cy, that.adjustCtrl.cameraObject2.distortion, that.adjustCtrl.cameraObject2.projectionType);
                        var p3 = Projection.Project_Point_With_Distortion(point3D, that.adjustCtrl.cameraObject3.rotation, that.adjustCtrl.cameraObject3.translation, that.adjustCtrl.cameraObject3.skew, that.adjustCtrl.cameraObject3.fx, that.adjustCtrl.cameraObject3.fy, that.adjustCtrl.cameraObject3.cx, that.adjustCtrl.cameraObject3.cy, that.adjustCtrl.cameraObject3.distortion, that.adjustCtrl.cameraObject3.projectionType);

                        v1.x = p1[0] * xFactor;
                        v1.y = p1[1] * yFactor;

                        v2.x = p2[0] * xFactor;
                        v2.y = p2[1] * yFactor;

                        v3.x = p3[0] * xFactor;
                        v3.y = p3[1] * yFactor;
                    }
                }

                ctx = ($('#' + id)[0] as any).getContext("2d");
                ctx.clearRect(0, 0, 800, 500);
                graph.draw(ctx);

                that.adjustCtrl.adjustViews.forEach(function (view) {
                    if (view.redrawFunc) view.redrawFunc();
                });
            }
        }

        ctx.imageSmoothingEnabled = false;
        ctx.webkitImageSmoothingEnabled = false;
        ctx.mozImageSmoothingEnabled = false;
        ctx.msImageSmoothingEnabled = false;
        ctx.oImageSmoothingEnabled = false;
        trackTransforms(ctx);

        function unBindZoom() {
            $("#" + id).unbind('mousedown', zoomMouseDown);
            $("#" + id).unbind('mousemove', zoomMouseMove);
            $("#" + id).unbind('mouseup', zoomMouseUp);
            canvas.removeEventListener('mousewheel', zoomHandleScroll);
            canvas.removeEventListener('DOMMouseScroll', zoomHandleScroll);
        }

        function unBindDragLines() {
            $("#" + id).unbind('mousedown', handleMouseDown);
            $("#" + id).unbind('mousemove', handleMouseMove);
            $("#" + id).unbind('mouseup', handleMouseUpOut);
            $("#" + id).unbind('mouseout', handleMouseUpOut);
        }

        function unBindDragPoint() {
            $('#' + id).unbind('mousedown', graphMousedown);
            $('#' + id).unbind('mouseup', graphMouseup);
            $('#' + id).unbind('mousemove', graphMousemove);
            $('#' + id).unbind('dblclick', graphMouseDblClick);
        }


        function bindZoom() {
            $("#" + id).bind('mousedown', zoomMouseDown);
            $("#" + id).bind('mousemove', zoomMouseMove);
            $("#" + id).bind('mouseup', zoomMouseUp);
            canvas.addEventListener('mousewheel', zoomHandleScroll);
            canvas.addEventListener('DOMMouseScroll', zoomHandleScroll);
        }

        function bindDragLines() {
            $("#" + id).bind('mousedown', handleMouseDown);
            $("#" + id).bind('mousemove', handleMouseMove);
            $("#" + id).bind('mouseup', handleMouseUpOut);
            $("#" + id).bind('mouseout', handleMouseUpOut);
        }

        function bindDragPoint() {
            unBindDragPoint();
            $('#' + id).bind('mousedown', graphMousedown);
            $('#' + id).bind('mouseup', graphMouseup);
            $('#' + id).bind('mousemove', graphMousemove);
            $('#' + id).bind('dblclick', graphMouseDblClick);
        }

        function redraw() {

            if (!ctx.getTransform) {
                console.log("redraw no getTransform " + canvas.id);
                return;
            }

            if (canvas.stateChanged == true) {

                if (that.adjustCtrl.zoomState == "zoom") {
                    unBindDragLines();
                    unBindDragPoint();
                    unBindZoom();
                    bindZoom();
                }
                else if (that.adjustCtrl.zoomState == "dragPoint") {
                    unBindDragLines();
                    bindDragPoint();
                }
                else if (that.adjustCtrl.zoomState == "dragLines") {

                    unBindDragPoint();
                    bindDragLines();
                }

                canvas.stateChanged = false;

            }

            // Clear the entire canvas
            if (ctx.transformedPoint) {
                var p1 = ctx.transformedPoint(0, 0);
                var p2 = ctx.transformedPoint(canvas.width, canvas.height);
                ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
            }

            ctx.save();
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.restore();

            ctx.drawImage(image, 0, 0, parseInt(image.naturalWidth), parseInt(image.naturalHeight), 0, 0, parseInt(canvas.width), parseInt(canvas.height));

            canvas.currentScale = ctx.getTransform()['a'];
            if (canvas.currentScale < 1) {
                ctx.setTransform(1, 0, 0, 1, 0, 0);
                canvas.currentScale = 1;
            }

            if (that.adjustCtrl.zoomState == "dragLines") {
                draw();
            }
            else if (that.adjustCtrl.zoomState == "dragPoint") {
                graph.draw(ctx);
            }
            else {
                graph.draw(ctx);
            }
        }

        var lastX = canvas.width / 2, lastY = canvas.height / 2;
        var dragStart, dragged;

        canvas.oncontextmenu = function (e) {
            e.preventDefault();
        };

        var scaleFactor = 1.03;

        var zoom = function (clicks) {
            var pt = ctx.transformedPoint(lastX, lastY);
            ctx.translate(pt.x, pt.y);
            var factor = Math.pow(scaleFactor, clicks);
            canvas.zoomAndPointOnCenter(factor, factor);
            redraw();
        };

        var zoomHandleScroll = function (e) {
            var evt = e.originalEvent;
            var delta = evt.wheelDelta ? evt.wheelDelta / 40 : evt.detail ? -evt.detail : 0;
            if (delta) zoom(delta);
            return evt.preventDefault() && false;
        };


        var zoomMouseDown = function (evt) {
            var e = evt;
            var isRightMB;
            e = e || window.event;
            if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
                isRightMB = e.which == 3;
            else if ("button" in e)  // IE, Opera
                isRightMB = e.button == 2;

            if (isRightMB) {
                (document as any).body.style.mozUserSelect = document.body.style.webkitUserSelect = document.body.style.userSelect = 'none';
                lastX = evt.offsetX || (evt.pageX - canvas.offsetLeft);
                lastY = evt.offsetY || (evt.pageY - canvas.offsetTop);
                dragStart = ctx.transformedPoint(lastX, lastY);
                dragged = false;
            }
        };

        canvas.zoomAndPointOnCenter = function (scaleX, scaleY) {
            var graph = that.adjustCtrl.dictGraphs[id];
            var v = graph.vertices[0];
            var center = [ctx.canvas.width / 2, ctx.canvas.height / 2];
            var originalPoint = [v.x, v.y];
            if (w == -100 && h == -100) {
                originalPoint = [ctx.canvas.width / 2, ctx.canvas.height / 2];
            }

            ctx.translate(originalPoint[0], originalPoint[1]);
            ctx.scale(scaleX, scaleY);
            ctx.translate(-1 * originalPoint[0], -1 * originalPoint[1]);
            var newCenter = ctx.transformedPoint(center[0], center[1]);

            ctx.translate(newCenter['x'] - originalPoint[0], newCenter['y'] - originalPoint[1]);

        };

        var zoomMouseMove = function (evt) {
            var e = evt;
            var isRightMB;
            e = e || window.event;

            if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
                isRightMB = e.which == 3;
            else if ("button" in e)  // IE, Opera
                isRightMB = e.button == 2;

            if (isRightMB) {
                lastX = evt.offsetX || (evt.pageX - canvas.offsetLeft);
                lastY = evt.offsetY || (evt.pageY - canvas.offsetTop);
                dragged = true;
                if (dragStart) {
                    var pt = ctx.transformedPoint(lastX, lastY);
                    ctx.translate(pt.x - dragStart.x, pt.y - dragStart.y);
                    redraw();
                }
            }
        };

        var mouseMove = function (evt) {
            redraw();
        };

        var zoomMouseUp = function (evt) {
            var e = evt;
            var isRightMB;
            e = e || window.event;

            if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
                isRightMB = e.which == 3;
            else if ("button" in e)  // IE, Opera
                isRightMB = e.button == 2;

            if (isRightMB) {
                dragStart = null;
                return;
                if (!dragged) zoom(evt.shiftKey ? -1 : 1);
            }
        };

        $("#" + id).off().on('click', function () {
            // function body
        });

        $("#" + id).bind('mousedown', zoomMouseDown);
        $("#" + id).bind('mousemove', zoomMouseMove);
        $("#" + id).bind('mousemove', mouseMove);
        $("#" + id).bind('mouseup', zoomMouseUp);
        $("#" + id).unbind('mousewheel');
        $("#" + id).unbind('DOMMouseScroll');
        $("#" + id).unbind('mousewheel', zoomHandleScroll);
        $("#" + id).unbind('DOMMouseScroll', zoomHandleScroll);
        $("#" + id).bind('mousewheel', zoomHandleScroll);
        $("#" + id).bind('DOMMouseScroll', zoomHandleScroll);

        // Adds ctx.getTransform() - returns an SVGMatrix
        // Adds ctx.transformedPoint(x,y) - returns an SVGPoint
        function trackTransforms(ctx) {
            var svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            var xform:any = svg.createSVGMatrix();
            ctx.getTransform = function () {
                return xform;
            };

            var savedTransforms = [];
            var save = ctx.save;
            ctx.save = function () {
                savedTransforms.push(xform.translate(0, 0));
                return save.call(ctx);
            };

            var restore = ctx.restore;
            ctx.restore = function () {
                xform = savedTransforms.pop();
                return restore.call(ctx);
            };

            var scale = ctx.scale;
            ctx.scale = function (sx, sy) {
                xform = (xform as any).scaleNonUniform(sx, sy);
                return scale.call(ctx, sx, sy);
            };

            var rotate = ctx.rotate;
            ctx.rotate = function (radians) {
                xform = xform.rotate(radians * 180 / Math.PI);
                return rotate.call(ctx, radians);
            };

            var translate = ctx.translate;
            ctx.translate = function (dx, dy) {
                xform = xform.translate(dx, dy);
                return translate.call(ctx, dx, dy);
            };

            var transform = ctx.transform;
            ctx.transform = function (a, b, c, d, e, f) {
                var m2 = svg.createSVGMatrix();
                m2.a = a;
                m2.b = b;
                m2.c = c;
                m2.d = d;
                m2.e = e;
                m2.f = f;
                xform = xform.multiply(m2);
                return transform.call(ctx, a, b, c, d, e, f);
            };

            var setTransform = ctx.setTransform;
            ctx.setTransform = function (a, b, c, d, e, f) {
                xform.a = a;
                xform.b = b;
                xform.c = c;
                xform.d = d;
                xform.e = e;
                xform.f = f;
                return setTransform.call(ctx, a, b, c, d, e, f);
            };

            var pt = svg.createSVGPoint();
            ctx.transformedPoint = function (x, y) {
                var pt = svg.createSVGPoint();

                try {
                    pt.x = x;
                    pt.y = y;
                } catch (e) {
                    throw e;
                }
                return pt.matrixTransform(xform.inverse());
            }
        }

        this.adjustCtrl.dictLines[id] = [];

        for (var i = 0; i < linePoints.length; i++) {
            var line = linePoints[i];
            this.adjustCtrl.dictLines[id].push({
                x0: line[0].x * xFactor,
                y0: line[0].y * yFactor,
                x1: line[1].x * xFactor,
                y1: line[1].y * yFactor
            });
        }

        cw = canvas.width;
        ch = canvas.height;
        reOffset();
        window.onscroll = function (e) {
            reOffset();
        };
        window.onresize = function (e) {
            reOffset();
        };

        // dragging vars
        var isDown = false;
        var startX, startY;

        // line vars
        var nearest;

        // select the nearest line to the mouse
        function closestLine(mx, my) {
            var dist = 100000000;
            var index, pt;
            for (var i = 0; i < that.adjustCtrl.dictLines[id].length; i++) {
                //
                var xy = closestXY(that.adjustCtrl.dictLines[id][i], mx, my);
                //
                var dx = mx - xy.x;
                var dy = my - xy.y;
                var thisDist = dx * dx + dy * dy;
                if (thisDist < dist) {
                    dist = thisDist;
                    pt = xy;
                    index = i;
                }
            }
            var line = that.adjustCtrl.dictLines[id][index];
            return ({
                pt: pt,
                line: line,
                index: index,
                originalLine: {x0: line.x0, y0: line.y0, x1: line.x1, y1: line.y1}
            });
        }

        // linear interpolation -- needed in setClosestLine()
        function lerp(a, b, x) {
            return (a + x * (b - a));
        }

        // find closest XY on line to mouse XY
        function closestXY(line, mx, my) {
            var x0 = line.x0;
            var y0 = line.y0;
            var x1 = line.x1;
            var y1 = line.y1;
            var dx = x1 - x0;
            var dy = y1 - y0;
            var t = ((mx - x0) * dx + (my - y0) * dy) / (dx * dx + dy * dy);
            t = Math.max(0, Math.min(1, t));
            var x = lerp(x0, x1, t);
            var y = lerp(y0, y1, t);
            return ({x: x, y: y});
        }

        // draw the scene
        function draw() {
            // draw all lines at their current positions
            ctx.clearRect(0, 0, cw, ch);
            ctx.drawImage(image, 0, 0, parseInt(image.naturalWidth), parseInt(image.naturalHeight), 0, 0, parseInt(canvas.width), parseInt(canvas.height));
            for (var i = 0; i < that.adjustCtrl.dictLines[id].length; i++) {
                drawLine(that.adjustCtrl.dictLines[id][i], 'green');
            }

            // draw markers if a line is being dragged
            if (nearest) {
                // point on line nearest to mouse
                ctx.beginPath();
                ctx.arc(nearest.pt.x, nearest.pt.y, 5 / canvas.currentScale, 0, Math.PI * 2);
                ctx.strokeStyle = 'red';
                ctx.stroke();

                // marker for original line before dragging
                drawLine(nearest.originalLine, 'red');

                // hightlight the line as its dragged
                drawLine(nearest.line, 'red');
            }


            drawNode(ctx, w * xFactor, h * yFactor, 'red');

        }

        function drawLine(line, color) {
            ctx.beginPath();
            ctx.moveTo(line.x0, line.y0);
            ctx.lineTo(line.x1, line.y1);
            ctx.strokeStyle = color;
            ctx.lineWidth = 3 / canvas.currentScale;
            ctx.stroke();
        }

        function handleMouseDown(e) {
            var isRightMB;
            e = e || window.event;

            if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
                isRightMB = e.which == 3;
            else if ("button" in e)  // IE, Opera
                isRightMB = e.button == 2;

            if (isRightMB) {
                return;
            }

            // tell the browser we're handling this event
            e.preventDefault();
            e.stopPropagation();

            // mouse position
            startX = parseInt(e.clientX - offsetX + "");
            startY = parseInt(e.clientY - offsetY + "");
            var temp = ctx.transformedPoint(startX, startY);
            nearest = closestLine(temp['x'], temp['y']);
            draw();

            // set dragging flag
            isDown = true;
        }

        function handleMouseUpOut(e) {
            var isRightMB;
            e = e || window.event;

            if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
                isRightMB = e.which == 3;
            else if ("button" in e)  // IE, Opera
                isRightMB = e.button == 2;

            if (isRightMB) {
                return;
            }

            // tell the browser we're handling this event
            e.preventDefault();
            e.stopPropagation();

            // clear dragging flag
            isDown = false;
            nearest = null;
        }

        function handleMouseMove(e) {
            var isRightMB;
            e = e || window.event;

            if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
                isRightMB = e.which == 3;
            else if ("button" in e)  // IE, Opera
                isRightMB = e.button == 2;

            if (isRightMB) {
                return;
            }


            if (!isDown) {
                return;
            }

            // tell the browser we're handling this event
            e.preventDefault();
            e.stopPropagation();

            // mouse position
            var mouseX = parseInt(e.clientX - offsetX + "");
            var mouseY = parseInt(e.clientY - offsetY + "");

            // calc how far mouse has moved since last mousemove event
            var dx = mouseX - startX;
            var dy = mouseY - startY;
            startX = mouseX;
            startY = mouseY;

            // change nearest line vertices by distance moved
            var line = nearest.line;
            var index = nearest.index;

            var line1 = that.adjustCtrl.dictLines["imageFullScreen1"][index];
            var line2 = that.adjustCtrl.dictLines["imageFullScreen2"][index];
            var line3 = that.adjustCtrl.dictLines["imageFullScreen3"][index];

            var point3DSrc = Projection.Find3DPointOnPlaneFrom2DPointWithCameraView([(dx / canvas.currentScale + line.x0) / xFactor, (dy / canvas.currentScale + line.y0) / yFactor], cameraObject, that.app.plane);
            var point3DDes = Projection.Find3DPointOnPlaneFrom2DPointWithCameraView([(dx / canvas.currentScale + line.x1) / xFactor, (dy / canvas.currentScale + line.y1) / yFactor], cameraObject, that.app.plane);

            var p1s = Projection.Project_Point_With_Distortion(point3DSrc, that.adjustCtrl.cameraObject1.rotation, that.adjustCtrl.cameraObject1.translation, that.adjustCtrl.cameraObject1.skew, that.adjustCtrl.cameraObject1.fx, that.adjustCtrl.cameraObject1.fy, that.adjustCtrl.cameraObject1.cx, that.adjustCtrl.cameraObject1.cy, that.adjustCtrl.cameraObject1.distortion, that.adjustCtrl.cameraObject1.projectionType);
            var p1d = Projection.Project_Point_With_Distortion(point3DDes, that.adjustCtrl.cameraObject1.rotation, that.adjustCtrl.cameraObject1.translation, that.adjustCtrl.cameraObject1.skew, that.adjustCtrl.cameraObject1.fx, that.adjustCtrl.cameraObject1.fy, that.adjustCtrl.cameraObject1.cx, that.adjustCtrl.cameraObject1.cy, that.adjustCtrl.cameraObject1.distortion, that.adjustCtrl.cameraObject1.projectionType);

            var p2s = Projection.Project_Point_With_Distortion(point3DSrc, that.adjustCtrl.cameraObject2.rotation, that.adjustCtrl.cameraObject2.translation, that.adjustCtrl.cameraObject2.skew, that.adjustCtrl.cameraObject2.fx, that.adjustCtrl.cameraObject2.fy, that.adjustCtrl.cameraObject2.cx, that.adjustCtrl.cameraObject2.cy, that.adjustCtrl.cameraObject2.distortion, that.adjustCtrl.cameraObject2.projectionType);
            var p2d = Projection.Project_Point_With_Distortion(point3DDes, that.adjustCtrl.cameraObject2.rotation, that.adjustCtrl.cameraObject2.translation, that.adjustCtrl.cameraObject2.skew, that.adjustCtrl.cameraObject2.fx, that.adjustCtrl.cameraObject2.fy, that.adjustCtrl.cameraObject2.cx, that.adjustCtrl.cameraObject2.cy, that.adjustCtrl.cameraObject2.distortion, that.adjustCtrl.cameraObject2.projectionType);

            var p3s = Projection.Project_Point_With_Distortion(point3DSrc, that.adjustCtrl.cameraObject3.rotation, that.adjustCtrl.cameraObject3.translation, that.adjustCtrl.cameraObject3.skew, that.adjustCtrl.cameraObject3.fx, that.adjustCtrl.cameraObject3.fy, that.adjustCtrl.cameraObject3.cx, that.adjustCtrl.cameraObject3.cy, that.adjustCtrl.cameraObject3.distortion, that.adjustCtrl.cameraObject3.projectionType);
            var p3d = Projection.Project_Point_With_Distortion(point3DDes, that.adjustCtrl.cameraObject3.rotation, that.adjustCtrl.cameraObject3.translation, that.adjustCtrl.cameraObject3.skew, that.adjustCtrl.cameraObject3.fx, that.adjustCtrl.cameraObject3.fy, that.adjustCtrl.cameraObject3.cx, that.adjustCtrl.cameraObject3.cy, that.adjustCtrl.cameraObject3.distortion, that.adjustCtrl.cameraObject3.projectionType);

            line1.x0 = p1s[0] * xFactor;
            line1.y0 = p1s[1] * yFactor;
            line1.x1 = p1d[0] * xFactor;
            line1.y1 = p1d[1] * yFactor;

            line2.x0 = p2s[0] * xFactor;
            line2.y0 = p2s[1] * yFactor;
            line2.x1 = p2d[0] * xFactor;
            line2.y1 = p2d[1] * yFactor;

            line3.x0 = p3s[0] * xFactor;
            line3.y0 = p3s[1] * yFactor;
            line3.x1 = p3d[0] * xFactor;
            line3.y1 = p3d[1] * yFactor;

            // redraw
            draw();
        }

        if (that.adjustCtrl.zoomState == "dragLines") {
            draw();

        }
        else if (that.adjustCtrl.zoomState == "dragPoint") {
            graph.draw(ctx);

        }
        //if (app.viewMode == "verify"
        //    || app.hasMode(ActionMode.CREATE, WireframeElementType.vertex)
        //    || app.hasMode(ActionMode.MODIFY, WireframeElementType.vertex)) {

        if (true) {

            if (canvas.preferedScale != null) {
                canvas.zoomAndPointOnCenter(canvas.preferedScale, canvas.preferedScale);
            }
            else {
                canvas.zoomAndPointOnCenter(that.adjustCtrl.defaultZoomValue, that.adjustCtrl.defaultZoomValue);

            }
            canvas.preferedScale = null;
        }
        redraw();
    }

    //add image just with zoom capability to canvas
    fillCanvasWithImageAndPoint(cameraIndex, i, point3D, backward) {
        if (cameraIndex == null) {
            return;
        }
        this.adjustCtrl.canvasOperations.canvasPoint3D = point3D;
        if (point3D.occParam == null) {
            point3D.occParam = 10;
        }
        var newCameraIndex = this.adjustCtrl.pointVisibility.getFirstNonOccFrame(i, cameraIndex, point3D, point3D.occParam, backward);

        while (newCameraIndex == null && point3D.hframes.length >= 3 && point3D.occParam <= 200) {
            point3D.occList.length = 0;
            newCameraIndex = this.adjustCtrl.pointVisibility.getFirstNonOccFrame(i, cameraIndex, point3D, point3D.occParam);
            point3D.occParam = point3D.occParam + 100;
        }

        this.adjustCtrl.adjustSection.hideOrShowControls(point3D);

        if (newCameraIndex == null) {
            $("#adjustablePointControls" + i).hide();
            viewer["cameraIndex" + i] = null;

            return;
        }
        else {
            this.adjustCtrl.canvasOperations.fillCanvasWithImageAndPointWithoutOCC(newCameraIndex, i, point3D);
        }
    }

    //add image just with zoom capability to canvas without testing occlusion
    fillCanvasWithImageAndPointWithoutOCC(cameraIndex, i, point3D) {
        viewer["cameraIndex" + i] = cameraIndex;
        $('#perspectivePointText' + i).text(cameraIndex);
        var point = {} as any;
        var cameraObject: CameraView = this.app.cameraDict[cameraIndex];
        var p = Projection.Project_Point_With_Distortion([point3D.x, point3D.y, point3D.z], cameraObject.rotation, cameraObject.translation, cameraObject.skew, cameraObject.fx, cameraObject.fy, cameraObject.cx, cameraObject.cy, cameraObject.distortion, cameraObject.projectionType);

        point.x = p[0];
        point.y = p[1];

        var lines;
        if (this.app.wireframeLayer.isVisible) {
            lines = this.adjustCtrl.pointVisibility.getPointEdgesOnImageByEstimation(cameraIndex, point3D);
        }
        else {
            lines = [];
            point.x = -100;
            point.y = -100;
        }

        this.adjustCtrl.canvasOperations.fillAdjustCanvas(cameraIndex, i, point, lines);

        return;

    }

    setHFrames(vertex) {
        vertex.hframes = this.adjustCtrl.pointVisibility.getPoint3DFrames(vertex, false);
        vertex.occList = [];
        if (vertex.hframes.length < 3) {
            vertex.hframes = this.adjustCtrl.pointVisibility.getPoint3DFrames((vertex), true);
        }
    }

    getViewsForPoint(point, hFrames) {
        var frames = [-1, -1, -1];

        if (typeof hFrames == 'undefined') {
            console.log("cannot find hframes :");
            return;
        }

        if (hFrames.length < 1) {
            console.log("hframes empty");
        }


        viewer.defualtPoint = point;

        var frames: number[] = this.adjustCtrl.pointVisibility.obtainTwoImportantFrame(hFrames, point);
        var h1 = frames[0];
        var h2 = frames[1];


        var thirdFilled = false;


        for (var i = 0; i < hFrames.length; i++) {
            var cameraIndex = hFrames[i].toString();
            if (h1 == i) {
                frames[0] = cameraIndex;
            }
            else if (h2 == i) {
                frames[1] = cameraIndex;
            }
            else if (!thirdFilled) {
                frames[2] = cameraIndex;
                thirdFilled = true;
            }

        }
        var result = [];
        for (var i = 0; i < frames.length; i++) {
            var id = Number(frames[i]);
            if (!isNaN(id)) result.push(id);
        }
        return result;
    }

    //fill three canvases with best three frames
    fillThreeCanvasesWithBestFrames(point, hframes) {

        point.hframes = hframes;
        point.occList = [];
        var frames = this.getViewsForPoint(point, hframes);

        viewer.cameraIndex1 = frames[0];
        viewer.cameraIndex2 = frames[1];
        viewer.cameraIndex3 = frames[2];

        this.adjustCtrl.canvasOperations.fillCanvasWithImageAndPoint(viewer.cameraIndex1, 1, point, false);
        this.adjustCtrl.canvasOperations.fillCanvasWithImageAndPoint(viewer.cameraIndex2, 2, point, false);
        this.adjustCtrl.canvasOperations.fillCanvasWithImageAndPoint(viewer.cameraIndex3, 3, point, false);

        this.adjustCtrl.adjustSection.hideOrShowControls(point);

    }

    //add image and lines with drag and zoom capability to canvas
    addImageAndLinesToCanvasElement(id, w, h, linePoints, cameraIndex) {
        var canvasIndex = id - 1;
        this.adjustCtrl.adjustViews[canvasIndex].isImageLoaded = false;
        this.adjustCtrl.adjustViews[canvasIndex].isImageLoading = true;
        var canvasId = "imageFullScreen" + id;
        var image = document.createElement("img") as any;
        // add cache-aware and progress reporting
        image.load = function (params) {
            var thisImg = this;
            thisImg.completedPercentage = 0;
            var s3Obj = that.app.resourceManager.getFrameKey(cameraIndex);
            that.app.resourceManager.getImageData(
                s3Obj,
                function (o) {
                    if (!("" + o).startsWith("blob")) {
                        console.log("non blob return on " + s3Obj.key, o);
                        return;
                    }
                    thisImg.completedPercentage = 100;
                    thisImg.setAttribute("src", o);
                    if (params.onLoad) {
                        params.onLoad(this);
                    }
                },
                function (o) {
                    thisImg.completedPercentage = 100 * (o.loaded / o.total);
                    if (params.onProgress) params.onProgress(thisImg.completedPercentage);
                }
            );
        };

        var canvas = document.getElementById(canvasId) as any;
        var ctx = canvas.getContext("2d");
        canvas.src = null;
        canvas.cameraIndex = cameraIndex;
        canvas.w = w;
        canvas.h = h;
        canvas.linePoints = linePoints;

        var that = this
        var cameraObject = this.app.cameraDict[cameraIndex];
        this.adjustCtrl.adjustViews[canvasIndex].cameraIndex = Number(cameraIndex);
        var onLoadFunc = function () {
            // no idea why but this only works if run twice
            for (var i = 0; i < 2; i++) {
                var canvasId = canvas.id;
                var w = canvas.w;
                var h = canvas.h;
                var linePoints = canvas.linePoints;

                //this condition prevent load image from older callback (and so fix quick next)
                if (canvas.cameraIndex != cameraIndex) {
                    return;
                }

                var width = $("#" + canvasId).width();
                canvas.width = $("#" + canvasId).width();
                canvas.height = $("#" + canvasId).height();

                var realWidth = image.naturalWidth;
                var realHeight = image.naturalHeight;
                canvas.src = image.src;

                var xFactor = canvas.width / realWidth;
                var yFactor = canvas.height / realHeight;

                that.adjustCtrl.xFactor = xFactor;
                that.adjustCtrl.yFactor = yFactor;


                cameraObject.cameraIndex = cameraIndex;
                cameraObject.xFactor = xFactor;
                cameraObject.yFactor = yFactor;

                that.adjustCtrl.canvasOperations.addLinesToCanvas(canvasIndex, canvas, image, canvasId, linePoints, xFactor, yFactor, w, h, cameraObject);

                that.adjustCtrl.adjustViews[canvasIndex].isImageLoaded = true;
                that.adjustCtrl.adjustViews[canvasIndex].isImageLoading = false;
                canvas.stateChanged = true;
                that.adjustCtrl.scope.$apply();
            }
            var adjustView = that.adjustCtrl.adjustViews[canvasIndex];
            adjustView.previousZoomLevel = that.adjustCtrl.baseZoomLevel;
            that.adjustCtrl.initZoomForView(that.adjustCtrl.adjustViews[canvasIndex]);
        };

        var params = {
            onProgress: function (newProgress) {
                if (that.adjustCtrl.adjustViews[canvasIndex].loadProgress < newProgress) {
                    that.adjustCtrl.adjustViews[canvasIndex].loadProgress = newProgress;
                }
            }
        };

        this.adjustCtrl.adjustViews[canvasIndex].loadProgress = 0;
        this.adjustCtrl.adjustViews[canvasIndex].optimumScale = that.adjustCtrl.canvasOperations.getOptimumCameraScale(cameraObject, (this.adjustCtrl.adjustVertex as any).pvObject);
        image.canvasIndex = canvasIndex;
        image.onload = onLoadFunc;
        image.load(params);
    }




    getOptimumCameraScale(cam: CameraView, vert: PV.Vertex): number {
        let vertPos = new THREE.Vector3(vert.x, vert.y, vert.z);
        let ssd = cam.computeSSD(vertPos);
        let distanceFromCamera = cam.computeDistance(vertPos);
        let edgeTypeProp = this.app.wireframe.findProperty(EdgeTypeProperty, false);
        let scale: number = 25;
        // if(edgeTypeProp) {
        //     let edgeTypeCounts: Map<EdgeType, number> = new Map();
        //     let edgeTypeCoef: Map<EdgeType, number> = new Map();
        //
        //     for (let et in EdgeType) {
        //         if (!isNaN(parseInt(et))) {
        //             edgeTypeCounts[et] = 0;
        //             edgeTypeCoef[et] = 0;
        //         }
        //     }
        //     edgeTypeCoef.set(EdgeType.EAVE, 3.3366)
        //     edgeTypeCoef.set(EdgeType.FLASHING, 0)
        //     edgeTypeCoef.set(EdgeType.HIP, 0)
        //     edgeTypeCoef.set(EdgeType.PARAPET, -2.2339)
        //     edgeTypeCoef.set(EdgeType.RAKE, 1.7959)
        //     edgeTypeCoef.set(EdgeType.RIDGE, 1.5741)
        //     edgeTypeCoef.set(EdgeType.VALLEY, 0)
        //     edgeTypeCoef.set(EdgeType.STEP_FLASHING, -0.5729)
        //     let scaleConstant: number = 27.4708;
        //     let edgeCountCoef: number = -2.3814;
        //     let ssdCoef: number =  1574.3947;
        //
        //     let edges: number[] = app.wireframe.findEdgesForVertex(vert.id);
        //     for (let edgeId of edges) {
        //         let et: number = edgeTypeProp.getValue(app.wireframe.edges[edgeId]);
        //         if (null != et) {
        //             edgeTypeCounts[et]++
        //         }
        //     }
        //
        //     scale = scaleConstant + (edgeCountCoef * edges.length) + (ssdCoef * ssd);
        //     for (let et in edgeTypeCoef) {
        //         scale += edgeTypeCoef[et] * edgeTypeCounts[et];
        //     }
        // } else {
        //     let scaleConstant: number = 30.23716919;
        //     let edgeCountCoef: number = -1.69580226;
        //     let ssdCoef: number = 1506.67549280;
        //     let distanceCoef: number = -0.07033587;
        //     let edges: number[] = app.wireframe.findEdgesForVertex(vert.id);
        //     scale = scaleConstant + (edgeCountCoef * edges.length) + (ssdCoef * ssd) + (distanceCoef * distanceFromCamera);
        // }
        if (edgeTypeProp) {
            let edgeTypeCounts: Map<EdgeType, number> = new Map();
            let edgeTypeCoef: Map<EdgeType, number> = new Map();

            for (let et in EdgeType) {
                if (!isNaN(parseInt(et))) {
                    edgeTypeCounts[et] = 0;
                    edgeTypeCoef[et] = 0;
                }
            }
            edgeTypeCoef.set(EdgeType.EAVE, 4.93764);
            edgeTypeCoef.set(EdgeType.FLASHING, 0);
            edgeTypeCoef.set(EdgeType.HIP, -0.47703);
            edgeTypeCoef.set(EdgeType.PARAPET, 0);
            edgeTypeCoef.set(EdgeType.RAKE, 3.53440);
            edgeTypeCoef.set(EdgeType.RIDGE, 0.94357);
            edgeTypeCoef.set(EdgeType.VALLEY, 0);
            edgeTypeCoef.set(EdgeType.STEP_FLASHING, -1.51724);
            let scaleConstant: number = 19.31638;
            let edgeCountCoef: number = -3.58719;
            let ssdCoef: number = 4101.09292;
            let distanceCoef: number = -0.15785;

            let edges: number[] = this.app.wireframe.findEdgesForVertex(vert.id);
            for (let edgeId of edges) {
                let et: number = edgeTypeProp.getValue(this.app.wireframe.edges[edgeId]);
                if (null != et) {
                    edgeTypeCounts[et]++
                }
            }

            scale = scaleConstant + (edgeCountCoef * edges.length) + (ssdCoef * ssd) + (distanceCoef * distanceFromCamera);
            for (let et in edgeTypeCoef) {
                scale += edgeTypeCoef[et] * edgeTypeCounts[et];
            }
        } else {
            let scaleConstant: number = 22.57345;
            let edgeCountCoef: number = -3.09635;
            let ssdCoef: number = 3861.41451;
            let distanceCoef: number = -0.13833;
            let edges: number[] = this.app.wireframe.findEdgesForVertex(vert.id);
            scale = scaleConstant + (edgeCountCoef * edges.length) + (ssdCoef * ssd) + (distanceCoef * distanceFromCamera);
        }
        return scale;
    }
}