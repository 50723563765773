import { Component, OnInit } from "@angular/core";
import { SceneService } from "src/app/services/scenes/scene.service";

@Component({
    selector: 'ptv-project-management-validations',
    styles: [require('./projectManagementValidations.component.scss')],
    template: require('./projectManagementValidations.component.html')
})
export default class ProjectManagementValidationsComponent implements OnInit {
    
    constructor(private sceneService: SceneService) {
    }

    ngOnInit(): void {
        this.validate();
    }

    get validationResults() {
        return this.sceneService.wireframeApplication.validationResults.filter(r => r.elements.length > 0);
    }

    selectElements(elements) {
        this.sceneService.wireframeApplication.selectWireframeElement(null);
        this.sceneService.wireframeApplication.selectWireframeElement(elements, true);
        this.sceneService.wireframeApplication.lookAtElements(elements, true);
    }

    private lastValidationResultIndex = 0;
    cycleValidationResult(result, direction) {
        if (direction) {
            this.lastValidationResultIndex++;
        } else {
            this.lastValidationResultIndex--;
        }
        if (this.lastValidationResultIndex < 0) this.lastValidationResultIndex = result.elements.length - 1;
        if (this.lastValidationResultIndex >= result.elements.length) this.lastValidationResultIndex = 0;
        if (!this.lastValidationResultIndex) this.lastValidationResultIndex = 0;
        this.selectElements([result.elements[this.lastValidationResultIndex]]);
    }

    validate() {
        this.sceneService.wireframeApplication.validateWireframe();
    }
    
}