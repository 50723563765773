import {
    AfterViewInit,
    Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, Output, ViewChild, ViewContainerRef
} from '@angular/core';
import {ProjectImageCollectionFilterModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilter.model";
import {ProjectImageCollectionFilterAnnotationModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterAnnotation.model";
import {ProjectImageCollectionFilterMetadataModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterMetadata.model";
import {ProjectImageCollectionFilterTagModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterTag.model";
import ProjectImageListFilterEditorAnnotationComponent from "./annotations/projectImageListFilterEditorAnnotation.component";
import ProjectImageListFilterEditorMetadataComponent from "./metadata/projectImageListFilterEditorMetadata.component";
import ProjectImageListFilterEditorTagComponent from "./tags/projectImageListFilterEditorTag.component";
import {ProjectImageCollectionFilterCameraPointViewModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterCameraPointView.model";
import ProjectImageListFilterEditorPointVisibilityComponent
    from "./pointVisibility/projectImageListFilterEditorPointVisibility.component";

@Component({
    selector: 'project-image-list-filter-editor',
    styles: [require('./projectImageListFilterEditor.component.scss')],
    template: require('./projectImageListFilterEditor.component.html')
})
export default class ProjectImageListFilterEditorComponent implements AfterViewInit, OnDestroy {
    @ViewChild("projectImageListFilterEditorContainer", {read: ViewContainerRef, static: false}) projectImageListFilterEditorContainerRef: ViewContainerRef;
    @Input() projectImageListFilterModel: ProjectImageCollectionFilterModel;
    @Input() allAnnotationClasses: Array<string> = [];
    @Input() allTags: Array<string>  = [];
    @Output() filterUpdated = new EventEmitter<ProjectImageCollectionFilterModel>();
    private projectImageCollectionFilterChangedSubscription;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver
    ) {
    }

    ngAfterViewInit(): void {
        this.showProjectImageListFilterEditor();
    }

    ngOnDestroy(): void {
        if(this.projectImageCollectionFilterChangedSubscription) this.projectImageCollectionFilterChangedSubscription.unsubscribe();
        this.clearProjectImageListFilterEditorContainerRef();
    }

    get filterSymbol(): string{
        let symbol = '';
        if (!this.projectImageListFilterModel) return symbol;

        if(this.projectImageListFilterModel instanceof ProjectImageCollectionFilterAnnotationModel){
            symbol = 'chat_bubble';
        } else if(this.projectImageListFilterModel instanceof ProjectImageCollectionFilterMetadataModel){
            symbol = 'list';
        } else if(this.projectImageListFilterModel instanceof ProjectImageCollectionFilterCameraPointViewModel){
            symbol = 'gps_fixed';
        }  else if(this.projectImageListFilterModel instanceof ProjectImageCollectionFilterTagModel){
            symbol = 'note';
        }

        return symbol;
    }

    showProjectImageListFilterEditor(){
        this.clearProjectImageListFilterEditorContainerRef();

        if(this.projectImageListFilterModel instanceof ProjectImageCollectionFilterAnnotationModel){
            // annotation editor
            let projectImageListFilterEditorComponentFactory = this.componentFactoryResolver.resolveComponentFactory(ProjectImageListFilterEditorAnnotationComponent);
            let projectImageListFilterEditorComponent = this.projectImageListFilterEditorContainerRef.createComponent(projectImageListFilterEditorComponentFactory);
            projectImageListFilterEditorComponent.instance.projectImageListFilterModel = this.projectImageListFilterModel as ProjectImageCollectionFilterAnnotationModel;
            projectImageListFilterEditorComponent.instance.allAnnotationClasses = this.allAnnotationClasses;
            this.projectImageCollectionFilterChangedSubscription = projectImageListFilterEditorComponent.instance.filterUpdated.subscribe(this.onFilterUpdated.bind(this));
        } else if(this.projectImageListFilterModel instanceof ProjectImageCollectionFilterMetadataModel){
            // metadata editor
            let projectImageListFilterEditorComponentFactory = this.componentFactoryResolver.resolveComponentFactory(ProjectImageListFilterEditorMetadataComponent);
            let projectImageListFilterEditorComponent = this.projectImageListFilterEditorContainerRef.createComponent(projectImageListFilterEditorComponentFactory);
            projectImageListFilterEditorComponent.instance.projectImageListFilterModel = this.projectImageListFilterModel as ProjectImageCollectionFilterMetadataModel;
            this.projectImageCollectionFilterChangedSubscription = projectImageListFilterEditorComponent.instance.filterUpdated.subscribe(this.onFilterUpdated.bind(this));
        }  else if(this.projectImageListFilterModel instanceof ProjectImageCollectionFilterCameraPointViewModel){
            // point visibility editor
            let projectImageListFilterEditorComponentFactory = this.componentFactoryResolver.resolveComponentFactory(ProjectImageListFilterEditorPointVisibilityComponent);
            let projectImageListFilterEditorComponent = this.projectImageListFilterEditorContainerRef.createComponent(projectImageListFilterEditorComponentFactory);
            projectImageListFilterEditorComponent.instance.projectImageListFilterModel = this.projectImageListFilterModel as ProjectImageCollectionFilterCameraPointViewModel;
            this.projectImageCollectionFilterChangedSubscription = projectImageListFilterEditorComponent.instance.filterUpdated.subscribe(this.onFilterUpdated.bind(this));
        } else if(this.projectImageListFilterModel instanceof ProjectImageCollectionFilterTagModel){
            // tag editor
            let projectImageListFilterEditorComponentFactory = this.componentFactoryResolver.resolveComponentFactory(ProjectImageListFilterEditorTagComponent);
            let projectImageListFilterEditorComponent = this.projectImageListFilterEditorContainerRef.createComponent(projectImageListFilterEditorComponentFactory);
            projectImageListFilterEditorComponent.instance.projectImageListFilterModel = this.projectImageListFilterModel as ProjectImageCollectionFilterTagModel;
            projectImageListFilterEditorComponent.instance.allTags = this.allTags;
            this.projectImageCollectionFilterChangedSubscription = projectImageListFilterEditorComponent.instance.filterUpdated.subscribe(this.onFilterUpdated.bind(this));
        }
    }

    clearProjectImageListFilterEditorContainerRef(){
        this.projectImageListFilterEditorContainerRef.clear();
    }

    onFilterUpdated(projectImageListFilterModel:ProjectImageCollectionFilterModel){
        this.triggerFilterUpdated();
    }

    triggerFilterUpdated(){
        this.filterUpdated.emit(this.projectImageListFilterModel);
    }
}