import {Tool} from "./tool";
import {WireframeApplication} from "./wireframeApplication";
import {WireframeElementType} from "./wireframeElementType";
import {VertexElement} from "./vertexElement";
import * as THREE from 'three';

export default class EraserTool extends Tool {
    private eraserGeometry: THREE.BufferGeometry;
    private eraserMaterial: THREE.Material;
    private eraseRedraw: boolean = false;
    private eraserMesh: THREE.Mesh;
    private eraserOriginalCursor;

    constructor(wfApp: WireframeApplication) {
        super(wfApp);
        this.toolName = "EraserTool";
    }

    private get canvas(): HTMLCanvasElement {
        let canvasElement = document.querySelector("div#viewPort > canvas") as HTMLCanvasElement;
        return canvasElement;
    }

    private currentEraserRadius: number;
    private get eraserRadius(): number {
        let eraserRadius = 15;
        if (this.app) {
            eraserRadius = this.app.eraserRadius;
        }
        return eraserRadius;
    }

    protected createEraserMesh() {
        // convert to canvas coordinates
        let scaleSize = Math.min(this.canvas.clientWidth * 2, this.canvas.clientHeight * 2);
        let scaledRadius = this.eraserRadius / scaleSize;

        this.eraserGeometry = new THREE.RingBufferGeometry(scaledRadius * 0.85, scaledRadius, 30, 30);
        this.eraserMaterial = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            transparent: true,
            opacity: 0.70,
            side: THREE.DoubleSide
        });

        this.eraserMesh = new THREE.Mesh(this.eraserGeometry, this.eraserMaterial);
    }

    protected destroyEraserMesh() {
        this.app.rootScene.remove(this.eraserMesh);
        this.eraserMesh = null;
    }

    public onMouseDown(event: MouseEvent) {
        if (!super.onMouseDown(event)) return false
        this.eraserOriginalCursor = document.body.style.cursor;
        if (event.buttons == 1) {
            this.app.startUndoEntry();

            // hide cursor
            document.body.style.cursor = 'none';

            if (this.currentEraserRadius != this.eraserRadius) {
                this.currentEraserRadius = this.eraserRadius;
                this.destroyEraserMesh();
            }

            // eraser mesh
            if (!this.eraserMesh) {
                this.createEraserMesh();
            }
            this.app.rootScene.add(this.eraserMesh);
            this.setPositionOnScreenPlane(this.mouse, this.eraserMesh);
        }
    }

    public onMouseMove(event: MouseEvent) {
        super.onMouseMove(event);
        if (event.buttons == 1) {
            this.eraseRedraw = true;
            this.isHandlingMouseMove = true;

            // convert from canvas coordinates
            let screenWidthHalf = this.canvas.clientWidth / 2;
            let screenHeightHalf = this.canvas.clientHeight / 2;
            let mouseVector = new THREE.Vector2();
            mouseVector.x = Math.round((this.mouse.x + 1) * screenWidthHalf);
            mouseVector.y = Math.round((-this.mouse.y + 1) * screenHeightHalf);

            // move eraser
            this.setPositionOnScreenPlane(this.mouse, this.eraserMesh);

            let numDeleted = 0;
            let deleteCandidates: VertexElement[] = [];

            for (let id in this.app.wireFrameElements) {
                let idNum = Number(id);
                if (!isNaN(idNum)) {
                    //console.log("ID = " + idNum);
                    if (this.app.wireFrameElements[idNum].pvObject.pvType != WireframeElementType.vertex)
                        continue;

                    let element = this.app.wireFrameElements[idNum] as VertexElement;
                    let position3D: THREE.Vector3 = new THREE.Vector3(element.pvObject.x, element.pvObject.y, element.pvObject.z);
                    position3D = position3D.setFromMatrixPosition(element.matrixWorld);
                    position3D.project(this.app.viewer.camera);

                    if (position3D.x < -1 || position3D.x > 1 || position3D.y < -1 || position3D.y > 1)
                        continue;

                    let pixelX = Math.round((position3D.x + 1) * screenWidthHalf);
                    let pixelY = Math.round((-position3D.y + 1) * screenHeightHalf);
                    let vertexVector: THREE.Vector2 = new THREE.Vector2(pixelX, pixelY);
                    let distanceToMouse = vertexVector.distanceTo(mouseVector);
                    if (distanceToMouse < this.eraserRadius) {
                        numDeleted++;
                        deleteCandidates.push(element);
                    }
                }
            }

            this.app.selectWireframeElement(null);

            if (deleteCandidates.length > 0) {
                for (let id in deleteCandidates) {
                    let element = deleteCandidates[id];
                    this.app.wireframeLayer.deleteWireframeElement(element, true);
                    numDeleted++;
                }
                this.app.wireframeLayer.redrawWireframe();
            }

            event.stopImmediatePropagation();
        }
    }

    public onMouseUp(event: MouseEvent) {
        if (this.eraseRedraw) {
            this.eraseRedraw = false;
            this.app.wireframeLayer.redrawWireframe();
        }
        this.isHandlingMouseMove = false;
        document.body.style.cursor = 'default';
        this.destroyEraserMesh();
    }

}