import {TextProperty} from "./textProperty";

export class DictionaryProperty extends TextProperty {

    values = [];

    private _knownValues:any = {};

    get knownValueOptions():any[]{
        return this.getValuesFromObject(this._knownValues);
    }

    get knownValues():any{
        return this._knownValues;
    }

    set knownValues(knownValues:any){
        this._knownValues = knownValues;
    }

    constructor() {
        super();
        this.type = ".DictionaryProperty";
        this.name = "DictionaryProperty";
    }

    setValuesFromObject(valueObject) {
        let self = this;
        self.values = this.getValuesFromObject(valueObject);
    }

    getValuesFromObject(valueObject):any[]{
        let values = [];
        if(valueObject) {
            for (let key in valueObject) {
                if (valueObject.hasOwnProperty(key)) {
                    let label = (typeof valueObject[key] == "object") ? valueObject[key].label : valueObject[key]
                    values.push({value: key, label: label})
                }
            }
        }
        return values;
    }

    getLabelForValue(value):string{
        let label = null;
        if(!this.knownValueOptions) return label;
        this.knownValueOptions.forEach((valuePair) =>{
           if(valuePair.value == value) {
               label = valuePair.label;
           }
        });
        return label;
    }

    fromJson(obj, allProperties): void {
        super.fromJson(obj, allProperties);
        if (obj.knownValues) {
            this.knownValues = JSON.parse(JSON.stringify(obj.knownValues))
            this.setValuesFromObject(this.knownValues)
        }
    }
}
