/**
 * Created by Shwetank on 10-May-2017.
 */
import {PV} from "./wireframe";

declare let numeric: any;

export class RegistrationTransform {
    rotationMatrix: number[][] = [];
    translationVector: number[] = [];
    scaleFactor: number = 1.0;
    currentVertices: PV.Vertex[] = [];
    importedAdjustedVertices: PV.Vertex[] = [];

    calculateTransform(importedWireframeCorrespondences: PV.Vertex[], currentWireframePoints: PV.Vertex[]): boolean {
        if (currentWireframePoints.length !== importedWireframeCorrespondences.length)
            return false;

        this.currentVertices = currentWireframePoints;
        this.importedAdjustedVertices = importedWireframeCorrespondences;
        let scale: number[] = [];

        for (let i = 0; i < currentWireframePoints.length - 1; i++) {
            for (let j = i + 1; j < currentWireframePoints.length; j++) {
                let viCurrent = currentWireframePoints[i];
                let vjCurrent = currentWireframePoints[j];

                let viImported = importedWireframeCorrespondences[i];
                let vjImported = importedWireframeCorrespondences[j];

                let d1 = Math.sqrt((viCurrent.x - vjCurrent.x) * (viCurrent.x - vjCurrent.x) +
                    (viCurrent.y - vjCurrent.y) * (viCurrent.y - vjCurrent.y) +
                    (viCurrent.z - vjCurrent.z) * (viCurrent.z - vjCurrent.z));

                let d2 = Math.sqrt((viImported.x - vjImported.x) * (viImported.x - vjImported.x) +
                    (viImported.y - vjImported.y) * (viImported.y - vjImported.y) +
                    (viImported.z - vjImported.z) * (viImported.z - vjImported.z));

                scale.push((d2 / d1));
            }
        }

        let scaleSum = 0;
        for (let i = 0; i < scale.length; i++)
            scaleSum += scale[i];
        this.scaleFactor = scaleSum / scale.length;

        let centroidSFM: number[] = [0, 0, 0];
        let centroidLaser: number[] = [0, 0, 0];

        for (let i = 0; i < currentWireframePoints.length; i++) {
            centroidSFM[0] += currentWireframePoints[i].x * this.scaleFactor;
            centroidSFM[1] += currentWireframePoints[i].y * this.scaleFactor;
            centroidSFM[2] += currentWireframePoints[i].z * this.scaleFactor;

            centroidLaser[0] += importedWireframeCorrespondences[i].x;
            centroidLaser[1] += importedWireframeCorrespondences[i].y;
            centroidLaser[2] += importedWireframeCorrespondences[i].z;
        }

        centroidSFM[0] /= currentWireframePoints.length;
        centroidSFM[1] /= currentWireframePoints.length;
        centroidSFM[2] /= currentWireframePoints.length;

        centroidLaser[0] /= currentWireframePoints.length;
        centroidLaser[1] /= currentWireframePoints.length;
        centroidLaser[2] /= currentWireframePoints.length;

        let matrixA: number[][] = [];
        matrixA.push([]);
        matrixA.push([]);
        matrixA.push([]);
        let matrixB: number[][] = [];

        for (let i = 0; i < currentWireframePoints.length; i++) {
            matrixA[0].push(currentWireframePoints[i].x * this.scaleFactor - centroidSFM[0]);
            matrixA[1].push(currentWireframePoints[i].y * this.scaleFactor - centroidSFM[1]);
            matrixA[2].push(currentWireframePoints[i].z * this.scaleFactor - centroidSFM[2]);

            let row: number[] = [];
            row.push(importedWireframeCorrespondences[i].x - centroidLaser[0]);
            row.push(importedWireframeCorrespondences[i].y - centroidLaser[1]);
            row.push(importedWireframeCorrespondences[i].z - centroidLaser[2]);
            matrixB.push(row);
        }

        let matrixH: number[][] = this.multiplyMatrices(matrixA, matrixB);
        let res = numeric.svd(matrixH);
        let uTranspose = numeric.transpose(res.U);

        this.rotationMatrix = this.multiplyMatrices(res.V, uTranspose);
        let debugVar: number = numeric.det(this.rotationMatrix);
        if (numeric.det(this.rotationMatrix) < 0) {
            res.V[0][2] *= -1;
            res.V[1][2] *= -1;
            res.V[2][2] *= -1;
            this.rotationMatrix = this.multiplyMatrices(res.V, uTranspose);
        }

        this.translationVector[0] = -(this.rotationMatrix[0][0] * centroidSFM[0] + this.rotationMatrix[0][1] * centroidSFM[1] + this.rotationMatrix[0][2] * centroidSFM[2]) + centroidLaser[0];
        this.translationVector[1] = -(this.rotationMatrix[1][0] * centroidSFM[0] + this.rotationMatrix[1][1] * centroidSFM[1] + this.rotationMatrix[1][2] * centroidSFM[2]) + centroidLaser[1];
        this.translationVector[2] = -(this.rotationMatrix[2][0] * centroidSFM[0] + this.rotationMatrix[2][1] * centroidSFM[1] + this.rotationMatrix[2][2] * centroidSFM[2]) + centroidLaser[2];

        return true;
    }


    getTransformedVertices(vertices: PV.VertexMap): PV.VertexMap {
        let result: PV.VertexMap = this.transformVertices(vertices, this.rotationMatrix, this.translationVector, this.scaleFactor);
        return result;
    }

    transformVertices(vertices: PV.VertexMap, rotation: number[][], translation: number[], scale: number): PV.VertexMap {
        let transformedVertices: PV.VertexMap = {};
        for (let key in vertices) {
            let keyInt: number = Number(key);
            let newVertex: PV.Vertex = this.applyTransform(vertices[keyInt], rotation, translation, scale);
            transformedVertices[keyInt] = newVertex;
        }
        return transformedVertices;
    }

    applyTransform(vertex: PV.Vertex, rotation: number[][], translation: number[], scale: number): PV.Vertex {
        let newVertex: PV.Vertex = new PV.Vertex();

        let xScaled: number = vertex.x * scale;
        let yScaled: number = vertex.y * scale;
        let zScaled: number = vertex.z * scale;

        newVertex.x = (rotation[0][0] * xScaled + rotation[0][1] * yScaled + rotation[0][2] * zScaled) + translation[0];
        newVertex.y = (rotation[1][0] * xScaled + rotation[1][1] * yScaled + rotation[1][2] * zScaled) + translation[1];
        newVertex.z = (rotation[2][0] * xScaled + rotation[2][1] * yScaled + rotation[2][2] * zScaled) + translation[2];

        return newVertex;
    }


    multiplyMatrices(m1, m2): number[][] {
        let result = [];
        for (let i = 0; i < m1.length; i++) {
            result[i] = [];
            for (let j = 0; j < m2[0].length; j++) {
                let sum = 0;
                for (let k = 0; k < m1[0].length; k++) {
                    sum += m1[i][k] * m2[k][j];
                }
                result[i][j] = sum;
            }
        }
        return result;
    }

}