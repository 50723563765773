import MWPR from "./mwpr";

export default class Projection {
    //Find 3D Point On Plane From 2DPoint.3D plane in the form of ax+by+cz+d=0, plane = [a,b,c,d]
// u and v are the original 2D pixel coordinates in the given image
    static Find3DPointOnPlaneFrom2DPoint(u, v, fx, fy, cx, cy, R, C, plane) {
        let point2DPixel = [u,v];
        let cameraView = {
            rotation:R,
            fx:fx,
            fy:fy,
            cx:cx,
            cy:cy,
            cameraCenter:C
        };
        return Projection.Find3DPointOnPlaneFrom2DPointWithCameraView(point2DPixel, cameraView, plane);
    }

    static Find3DPointOnPlaneFrom2DPointWithCameraView(point2DPixel, cameraView, plane) {
        var point2DNorm = MWPR.Normalize_Point_2D(point2DPixel, cameraView);
        point2DNorm = MWPR.Undistort_Normalized_Point_2D(point2DNorm, cameraView.distortion, cameraView.projectionType);
        var x = point2DNorm[0];
        var y = point2DNorm[1];
        var nominator = plane[0] * cameraView.cameraCenter.x + plane[1] * cameraView.cameraCenter.y + plane[2] * cameraView.cameraCenter.z + plane[3];
        var denominator = plane[0] * (cameraView.rotation[0][0] * x + cameraView.rotation[1][0] * y + cameraView.rotation[2][0]) +
            plane[1] * (cameraView.rotation[0][1] * x + cameraView.rotation[1][1] * y + cameraView.rotation[2][1]) +
            plane[2] * (cameraView.rotation[0][2] * x + cameraView.rotation[1][2] * y + cameraView.rotation[2][2]);
        var t = -nominator / denominator;
        var X = cameraView.cameraCenter.x + t * (cameraView.rotation    [0][0] * x + cameraView.rotation[1][0] * y + cameraView.rotation[2][0]);
        var Y = cameraView.cameraCenter.y + t * (cameraView.rotation[0][1] * x + cameraView.rotation[1][1] * y + cameraView.rotation[2][1]);
        var Z = cameraView.cameraCenter.z + t * (cameraView.rotation[0][2] * x + cameraView.rotation[1][2] * y + cameraView.rotation[2][2]);
        var point3D = [X, Y, Z];
        return point3D;
    }

    static Project_Point_With_Distortion(p3D, R, t,skew, fx,fy, cx, cy, distortion, projectionType) {
        let cameraView = {
            rotation:R,
            translation:t,
            skew:skew,
            fx:fx,
            fy:fy,
            cx:cx,
            cy:cy,
            distortion:distortion,
            projectionType:projectionType
        };
        return Projection.Project_Point_With_Camera_Distortion(p3D, cameraView);
    }

    static Project_Point_With_Camera_Distortion(p3D, cameraView) {
        if (p3D.length == 4) {
            p3D[0] /= p3D[3];
            p3D[1] /= p3D[3];
            p3D[2] /= p3D[3];
            p3D[3] /= p3D[3];
        }
        var x = cameraView.rotation[0][0] * p3D[0] + cameraView.rotation[0][1] * p3D[1] + cameraView.rotation[0][2] * p3D[2] + cameraView.translation[0];
        var y = cameraView.rotation[1][0] * p3D[0] + cameraView.rotation[1][1] * p3D[1] + cameraView.rotation[1][2] * p3D[2] + cameraView.translation[1];
        var z = cameraView.rotation[2][0] * p3D[0] + cameraView.rotation[2][1] * p3D[1] + cameraView.rotation[2][2] * p3D[2] + cameraView.translation[2];
        var x_prime = x / z;
        var y_prime = y / z;
        var x_double_prime;
        var y_double_prime;
        if (cameraView.projectionType == "PERSPECTIVE") {
            var r2 = x_prime * x_prime + y_prime * y_prime;
            x_double_prime = x_prime * (1 + cameraView.distortion[0] * r2 + cameraView.distortion[1] * r2 * r2);
            y_double_prime = y_prime * (1 + cameraView.distortion[0] * r2 + cameraView.distortion[1] * r2 * r2);
        } else if (cameraView.projectionType == "PERSPECTIVE_5") {
            var r2 = x_prime * x_prime + y_prime * y_prime;
            var r4 = r2 * r2;
            var r6 = r4 * r2;
            x_double_prime = x_prime * (1 + cameraView.distortion[0] * r2 + cameraView.distortion[1] * r4 + cameraView.distortion[2] * r6) + (2 * cameraView.distortion[3] * x_prime * y_prime)
                + cameraView.distortion[4] * (r2 + 2 * x_prime * x_prime);
            y_double_prime = y_prime * (1 + cameraView.distortion[0] * r2 + cameraView.distortion[1] * r4 + cameraView.distortion[2] * r6)
                + cameraView.distortion[3] * (r2 + 2 * y_prime * y_prime) + (2 * cameraView.distortion[4] * x_prime * y_prime);
        } else if (cameraView.projectionType == "PERSPECTIVE_8") {
            var r2 = x_prime * x_prime + y_prime * y_prime;
            var r4 = r2 * r2;
            var r6 = r4 * r2;
            var r8 = r4 * r4;
            x_double_prime = x_prime * (1 + cameraView.distortion[0] * r2 + cameraView.distortion[1] * r4 + cameraView.distortion[2] * r6 + cameraView.distortion[3] * r8) + ((2 * cameraView.distortion[5] * x_prime * y_prime) + cameraView.distortion[4] * (r2 + 2 * x_prime * x_prime)) * (1 + cameraView.distortion[6] * r2 + cameraView.distortion[7] * r4);
            y_double_prime = y_prime * (1 + cameraView.distortion[0] * r2 + cameraView.distortion[1] * r4 + cameraView.distortion[2] * r6 + cameraView.distortion[3] * r8) + (cameraView.distortion[5] * (r2 + 2 * y_prime * y_prime) + (2 * cameraView.distortion[4] * x_prime * y_prime)) * (1 + cameraView.distortion[6] * r2 + cameraView.distortion[7] * r4);
        } else if (cameraView.projectionType == "BROWN_CAMERA") {
            var r2 = x_prime * x_prime + y_prime * y_prime;
            var coeff = 1.0 + r2 * (cameraView.distortion[0] + r2 * (cameraView.distortion[1] + r2 * (cameraView.distortion[2] + r2 * cameraView.distortion[3])));
            x_double_prime = x_prime * coeff + cameraView.distortion[4] * x_prime * y_prime * 2.0 + cameraView.distortion[5] * (r2 + x_prime * x_prime * 2.0);
            y_double_prime = y_prime * coeff + cameraView.distortion[4] * (r2 + y_prime * y_prime * 2.0) + cameraView.distortion[5] * x_prime * y_prime * 2.0;
        } else if (cameraView.projectionType == "FISHEYE") {
            var r = Math.sqrt(x_prime * x_prime + y_prime * y_prime);
            var theta = Math.atan(r);
            var theta2 = theta * theta;
            var theta4 = theta2 * theta2;
            var theta6 = theta4 * theta2;
            var theta8 = theta4 * theta4;
            var thetad = theta * (1 + cameraView.distortion[0] * theta2 + cameraView.distortion[1] * theta4 + cameraView.distortion[2] * theta6 + cameraView.distortion[3] * theta8);
            var scaling = (r > 1e-8) ? thetad / r : 1.0;
            x_double_prime = x_prime * scaling;
            y_double_prime = y_prime * scaling;
        } else {
            var p2D = [];
            return p2D;
        }

        var point2DNorm = [x_double_prime, y_double_prime];
        var point2DPixel = MWPR.Denormalize_Point_2D(point2DNorm, cameraView);
        return point2DPixel;
    }
}