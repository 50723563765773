/**
 * @author mschuetz / http://mschuetz.at/
 * @author qiao / https://github.com/qiao
 * @author mrdoob / http://mrdoob.com
 * @author alteredq / http://alteredqualia.com/
 * @author WestLangley / http://github.com/WestLangley
 * @author erich666 / http://erichaines.com
 */
/*global THREE, console */
// 
// Adapted from THREE.OrbitControls
// - Smooth movements
// - creates "proposeTransform" events
// 
// 
// This set of controls performs orbiting, dollying (zooming), and panning. It maintains
// the "up" direction as +Y, unlike the TrackballControls. Touch on tablet and phones is
// supported.
//
//    Orbit - left mouse / touch: one finger move
//    Zoom - middle mouse, or mousewheel / touch: two finger spread or squish
//    Pan - right mouse, or arrow keys / touch: three finter swipe
//
// This is a drop-in replacement for (most) TrackballControls used in examples.
// That is, include this js file and wherever you see:
//    	controls = new THREE.TrackballControls( camera );
//      controls.target.z = 150;
// Simple substitute "OrbitControls" and the control should work as-is.

import * as THREE from 'three';
import {WireframeApplication} from "./application/wireframeApplication";
import {WireframeOperations} from "./wireframeOperations";
import {CameraControlMode} from "./application/tool";


export default class OrbitControls extends THREE.EventDispatcher {

    //this.object = object;
    //this.domElement = (domElement !== undefined) ? domElement : document;

    object:any
    domElement:any
    mousePos = new THREE.Vector2()
    mouseDragStart = new THREE.Vector2()

    constructor(private app:WireframeApplication, object:any, domElement:any) {
        super()
        this.object = object
        this.domElement = domElement

        this.domElement.addEventListener('contextmenu', function (event) {
            event.preventDefault();
        }, false);
        this.domElement.addEventListener('mousedown', this.onMouseDown, false);
        this.domElement.addEventListener('mouseup', this.onMouseUp, false);
        this.domElement.addEventListener('dblclick', this.onDblClick, false);
        this.domElement.addEventListener('mousewheel', this.onMouseWheel, false);
        this.domElement.addEventListener('DOMMouseScroll', this.onMouseWheel, false); // firefox

        this.domElement.addEventListener('mousemove', this.onMouseMove, false);
        this.domElement.addEventListener('dragover', this.onMouseMove, false);

        this.domElement.addEventListener('touchstart', this.touchstart, false);
        this.domElement.addEventListener('touchend', this.touchend, false);
        this.domElement.addEventListener('touchmove', this.touchmove, false);


        this.domElement.addEventListener('keydown', this.onKeyDown);
        this.domElement.addEventListener('keyup', this.onKeyUp);
        /*
        app.cesiumComponent.zone.runOutsideAngular(() => {
            window.addEventListener('keydown', this.onKeyDown, false);
            window.addEventListener('keyup', this.onKeyUp, false);
        })
        */

        if (this.domElement.tabIndex === -1) {
            this.domElement.tabIndex = 2222;
        }

    }
    // API

    upRotation:THREE.Quaternion
    upRotationInverse:THREE.Quaternion
    // Set to false to disable this control
    enabled = true;

    public cameraConstraintOverride = false
    // "target" sets the location of focus, where the control orbits around
    // and where it pans with respect to.
    public target = new THREE.Vector3();

    // center is old, deprecated; use "target" instead
    center = this.target;

    // This option actually enables dollying in and out; left as "zoom" for
    // backwards compatibility
    noZoom = false;
    zoomSpeed = 3.5;

    // Limits to how far you can dolly in and out
    minDistance = -10;
    maxDistance = Infinity;

    // Set to true to disable this control
    noRotate = false;
    rotateSpeed = 1.0;

    // Set to true to disable this control
    noPan = false;
    keyPanSpeed = 40.0; // pixels moved per arrow key push

    // Set to true to automatically rotate around the target
    autoRotate = false;
    autoRotateSpeed = 2.0; // 30 seconds per round when fps is 60

    fadeFactor = 10;
    // How far you can orbit vertically, upper and lower limits.
    // Range is 0 to Math.PI radians.
    minPolarAngle = 0; // radians
    maxPolarAngle = Math.PI; // radians

    // Set to true to disable use of the keys
    noKeys = false;

    // The four arrow keys
    keys = {
        LEFT: 37,
        UP: 38,
        RIGHT: 39,
        BOTTOM: 40,
        NUMBER_1: 49,
        NUMBER_2: 50,
        NUMBER_3: 51,
        CTRL: 17,
        SHIFT: 16,
        ALT: 18,
        A: 'A'.charCodeAt(0),
        S: 'S'.charCodeAt(0),
        D: 'D'.charCodeAt(0),
        W: 'W'.charCodeAt(0),
        Q: 'Q'.charCodeAt(0),
        E: 'E'.charCodeAt(0),
        Z: 'Z'.charCodeAt(0)
    };

    ////////////
    // internals

    scope = this;

    EPS = 0.01;   // this needs to be high to prevent cesium camera weirdness

    rotateStart = new THREE.Vector2();
    rotateEnd = new THREE.Vector2();
    rotateDelta = new THREE.Vector2();

    panStart = new THREE.Vector2();
    panEnd = new THREE.Vector2();
    panDelta = new THREE.Vector2();
    panOffset = new THREE.Vector3();

    offset = new THREE.Vector3();

    dollyStart = new THREE.Vector2();
    dollyEnd = new THREE.Vector2();
    dollyDelta = new THREE.Vector2();

    phiDelta = 0;
    thetaDelta = 0;
    scale = 1;
    panVec = new THREE.Vector3();

    lastPosition = new THREE.Vector3();

    STATE = {
        NONE: -1,
        ROTATE: 0,
        DOLLY: 1,
        PAN: 2,
        TOUCH_ROTATE: 3,
        TOUCH_DOLLY: 4,
        TOUCH_PAN: 5,
        TOOL: 6
    };

    currentState = this.STATE.NONE;

    // for reset

    target0:THREE.Vector3 //  = this.target.clone();
    position0:THREE.Vector3 // = this.object.position.clone();

    // events

    changeEvent = {
        type: 'change'
    };
    startEvent = {
        type: 'start'
    };
    endEvent = {
        type: 'end'
    };

    rotateLeft(angle) {

        if (angle === undefined) {

            angle = this.getAutoRotationAngle();

        }
        //console.log("rotateLeft " + angle);
        this.thetaDelta -= angle;

    };

    rotateUp(angle) {

        if (angle === undefined) {

            angle = this.getAutoRotationAngle();

        }
        //console.log("rotateUp " + angle);
        this.phiDelta -= angle;

    };

    // pass in distance in world space to move left
    panLeft(distance) {

        var te = this.object.matrix.elements;

        // get X column of matrix
        this.panOffset.set(te[0], te[1], te[2]);
        this.panOffset.multiplyScalar(-distance);

        this.panVec.add(this.panOffset);

    };

    // pass in distance in world space to move up
    panUp(distance) {

        var te = this.object.matrix.elements;

        // get Y column of matrix
        this.panOffset.set(te[4], te[5], te[6]);
        this.panOffset.multiplyScalar(distance);

        this.panVec.add(this.panOffset);

    };

    // pass in distance in world space to move forward
    panForward(distance) {

        var te = this.object.matrix.elements;

        // get Z column of matrix
        this.panOffset.set(te[8], te[9], te[10]);
        this.panOffset.multiplyScalar(distance);

        this.panVec.add(this.panOffset);

    };

    // pass in x,y of change desired in pixel space,
    // right and down are positive
    pan(deltaX, deltaY) {
        this.cameraConstraintOverride = false
        var element = this.domElement === document ? this.domElement.body : this.domElement;

        //this.cameraLookatTarget = null;
        //this.cameraPositionTarget = null;

        if (this.object.fov !== undefined) {

            // perspective
            var position = this.object.position;
            var offset = position.clone().sub(this.target);
            var targetDistance = offset.length();

            // half of the fov is center to top of screen
            targetDistance *= Math.tan((this.object.fov / 2) * Math.PI / 180.0);

            // we actually don't use screenWidth, since perspective camera is fixed to screen height
            this.panLeft(2 * deltaX * targetDistance / element.clientHeight);
            this.panUp(2 * deltaY * targetDistance / element.clientHeight);

        } else if (this.object.top !== undefined) {

            // orthographic
            this.panLeft(deltaX * (this.object.right - this.object.left) / element.clientWidth);
            this.panUp(deltaY * (this.object.top - this.object.bottom) / element.clientHeight);

        } else {

            // camera neither orthographic or perspective
            console.warn('WARNING: OrbitControls.js encountered an unknown camera type - pan disabled.');

        }

    };

// pointivo start
    // this.dollyIn = function(dollyScale) {

    //     if (dollyScale === undefined) {

    //         dollyScale = getZoomScale();

    //     }

    //     scale /= dollyScale;

    // };

    dollyIn(dollyScale = null) {

        if (dollyScale === null) {

            dollyScale = this.getZoomScale();

        }

        this.scale /= dollyScale;
        //scale += .2;
        //viewer.dollyScale = viewer.dollyScale * scale;
        //console.log("dollyIn " + scale + " - " + dollyScale);

    };
// pointivo end


// pointivo start
    // this.dollyOut = function(dollyScale) {

    //     if (dollyScale === undefined) {

    //         dollyScale = getZoomScale();

    //     }

    //     scale *= dollyScale;

    // };
    dollyOut(dollyScale = null) {

        if (dollyScale === null) {

            dollyScale = this.getZoomScale();

        }

        this.scale *= dollyScale;
        //scale -= .2;
        //viewer.dollyScale = viewer.dollyScale * scale;
        //console.log("dollyOut " + scale + " - " + dollyScale);

    };

// pointivo end

    cameraLookatTarget = new THREE.Vector3()
    cameraPositionTarget = new THREE.Vector3()
    cameraDirectionLock = null
    cameraTransitionActive = false

    update(delta:number = 1) {


        //console.log("dollyScale " + viewer.dollyScale)
        //console.log("update", this.object);
        var position = this.object.position.clone();
        this.offset.copy(position).sub(this.target);

        //console.log("target get " + this.target.x + " " + this.target.y + " " + this.target.z)
        //console.log("cameraLookatTarget " + this.cameraLookatTarget.x + " " + this.cameraLookatTarget.y + " " + this.cameraLookatTarget.z)

        if (true) {
            // angle from z-axis around y-axis
            //console.log("user camera input " + thetaDelta + "/" + phiDelta);
            var theta = Math.atan2(this.offset.x, this.offset.z);

            // angle from y-axis

            var phi = Math.atan2(Math.sqrt(this.offset.x * this.offset.x + this.offset.z * this.offset.z), this.offset.y);

            if (this.autoRotate) {
                this.rotateLeft(this.getAutoRotationAngle());
            }

            var progression = Math.min(1, this.fadeFactor * delta);


            theta += progression * this.thetaDelta;
            phi += progression * this.phiDelta;

            //console.log("theta/phi " + theta + "/" + phi);
            // restrict phi to be between desired limits
            phi = Math.max(this.minPolarAngle, Math.min(this.maxPolarAngle, phi));

            // restrict phi to be betwee EPS and PI-EPS
            phi = Math.max(this.EPS, Math.min(Math.PI - this.EPS, phi));

            //var radius = offset.length() * scale;
            var radius = this.offset.length();
            radius += (this.scale - 1) * radius * progression;

            let clientWidth = this.app.viewer.targetEl.clientWidth
            let clientHeight = this.app.viewer.targetEl.clientHeight
            let aspect = clientWidth / clientHeight;
            if (this.object instanceof THREE.OrthographicCamera) {

                let fov = 60 * Math.PI / 180;
                let angle = (Math.PI / 2) - (fov / 2);
                let width = (radius / Math.sin(angle)) * Math.sin(fov / 2);
                //console.log("orthoCam update ", this.object)

                //let orthoCam = this.object as THREE.OrthographicCamera;
                this.object.left = -width * aspect;
                this.object.right = width * aspect;
                this.object.bottom = -width / aspect;
                this.object.top = width / aspect;
                //console.log("orthoCam update ", this.object)
                //this.object.zoom -= (this.scale - 1) * this.object.zoom * progression;

            } else {
                //console.log("width/height " + clientWidth + " " + clientHeight)
                //this.object.aspect = 1 / aspect
            }

            //}

            // restrict radius to be between desired limits
            radius = Math.max(this.minDistance, Math.min(this.maxDistance, radius));

            // move target to panned location
            this.target.add(this.panVec.clone().multiplyScalar(progression));

            if (!this.cameraConstraintOverride) {
                this.offset.x = radius * Math.sin(phi) * Math.sin(theta);
                this.offset.y = radius * Math.cos(phi);
                this.offset.z = radius * Math.sin(phi) * Math.cos(theta);
            }
            //if (this.upRotation) this.offset.applyQuaternion(this.upRotation);
            if (this.app.viewer.cameraDirectionLock) {
                this.offset.copy(this.app.viewer.cameraDirectionLock)
                this.offset.setLength(radius)
                this.offset.negate()
            }
            position.copy(this.target).add(this.offset);

        }

        //console.log("camtarget", this.target)

        // send transformation proposal to listeners
        var proposeTransformEvent = {
            type: "proposeTransform",
            oldPosition: this.object.position,
            newPosition: position,
            objections: 0,
            counterProposals: []
        };
        this.dispatchEvent(proposeTransformEvent);

        // check some counter proposals if transformation wasn't accepted
        if (proposeTransformEvent.objections > 0) {

            if (proposeTransformEvent.counterProposals.length > 0) {
                var cp = proposeTransformEvent.counterProposals;
                position.copy(cp[0]);

                proposeTransformEvent.objections = 0;
                proposeTransformEvent.counterProposals = [];
            }
        }


        // apply transformation, if accepted
        if (false && proposeTransformEvent.objections > 0) {
            this.thetaDelta = 0;
            this.phiDelta = 0;
            this.scale = 1;
            this.panVec.set(0, 0, 0);
        } else {
            //this.object.matrixAutoUpdate = false
            //this.object.matrix.set()
            /*
            let direction = this.target.clone().sub(position).normalize()
            let up = this.object.up.clone()
            let right = direction.clone().cross(up.clone())
            //let mat = new THREE.Matrix4();
            this.object.matrix.set(
                right.x, right.y, right.z, position.x,
                up.x, up.y, up.z, position.y,
                direction.x, direction.y, direction.z, position.z,
                0, 0, 0, 1
            )
            this.object.updateMatrixWorld(true)
            this.object.matrixAutoUpdate = false
            this.object.updateProjectionMatrix();
            */
            //this.object.updateMatrix()
            this.object.position.copy(position)
            this.object.lookAt(this.target);
            //console.log("update camera " + (this.object === viewer.camera), this.object);
            var attenuation = Math.max(0, 1 - this.fadeFactor * delta);

            this.thetaDelta *= attenuation;
            this.phiDelta *= attenuation;
            this.scale = 1 + (this.scale - 1) * attenuation;
            this.panVec.multiplyScalar(attenuation);
        }


        if (this.lastPosition.distanceTo(this.object.position) > 0) {

            this.dispatchEvent(this.changeEvent);

            this.lastPosition.copy(this.object.position);

        }

    };


    reset() {

        this.currentState = this.STATE.NONE;

        this.target.copy(this.target0);
        this.object.position.copy(this.position0);

        this.update();

    };

    getAutoRotationAngle() {

        return 2 * Math.PI / 60 / 60 * this.autoRotateSpeed;

    }

    getZoomScale() {

        return Math.pow(0.95, this.zoomSpeed);

    }

    onDblClick = (event:MouseEvent) => {
        this.app.activeTool.onMouseDoubleClick(event)
    }

    private lastMouseDownTime = 0
    onMouseDown = (event:MouseEvent) => {
        this.app.activeTool.lastMouseUpDownEvent = event
        let now = new Date().getTime()
        let delta = now - this.lastMouseDownTime
        this.lastMouseDownTime = now
        if (delta < 200) {
            this.onDblClick.call(this, event)
            return
        }



        if (this.enabled === false) return;
        event.preventDefault();

        this.app.activeTool.mouseDragStart = new THREE.Vector2(this.mousePos.x, this.mousePos.y)
        this.app.activeTool.onMouseDown(event)

        if (this.app.activeTool.cameraControlMode == CameraControlMode.LEFT_RIGHT_BUTTONS) {
            if (event.button === THREE.MOUSE.LEFT) {
                this.currentState = this.STATE.ROTATE;
            } else if (event.button === THREE.MOUSE.MIDDLE) {
                this.currentState = this.STATE.DOLLY;
            } else if (event.button === THREE.MOUSE.RIGHT) {
                this.currentState = this.STATE.PAN;
            }
        } else if (this.app.activeTool.cameraControlMode == CameraControlMode.MIDDLE) {
            if (event.button === THREE.MOUSE.MIDDLE) {
                if (event.shiftKey) {
                    this.currentState = this.STATE.ROTATE
                } else {
                    this.currentState = this.STATE.PAN;
                }
            }
        }

        if (this.currentState == this.STATE.ROTATE) {
            if (this.noRotate === true) return;

            this.currentState = this.STATE.ROTATE;

            this.rotateStart.set(event.clientX, event.clientY);

        } else if (this.currentState == this.STATE.DOLLY) {
            if (this.noZoom === true) return;

            this.currentState = this.STATE.DOLLY;

            this.dollyStart.set(event.clientX, event.clientY);

        } else if (this.currentState == this.STATE.PAN) {
            if (this.scope.noPan === true) return;

            this.currentState = this.STATE.PAN;

            this.panStart.set(event.clientX, event.clientY);

        } else {

        }


        //this.domElement.addEventListener('mousemove', this.onMouseMove, false);
        //this.domElement.addEventListener('mouseup', this.onMouseUp, false);
        this.dispatchEvent(this.startEvent);
    }

    onMouseMove = (event:MouseEvent) => {

        if (this.enabled === false) return;

        event.preventDefault();


        if (this.app.activeTool) {
            let domEl = this.app.viewer.renderer.domElement
            let rect = domEl.getBoundingClientRect();
            this.mousePos.x = ((event.clientX - rect.left) / domEl.clientWidth) * 2 - 1;
            this.mousePos.y = -((event.clientY - rect.top) / domEl.clientHeight) * 2 + 1;
            this.app.activeTool.mouse.x = this.mousePos.x;
            this.app.activeTool.mouse.y = this.mousePos.y;

            this.app.activeTool.onMouseMove(event)
            if (this.app.activeTool.isHandlingMouseMove) {
                return
            }
        }

        var element = this.domElement === document ? this.domElement.body : this.domElement;

        if (null == this.cameraDirectionLock && this.currentState === this.STATE.ROTATE) {

            if (this.noRotate === true) return;

            //scope.cameraPositionTarget = null;
            this.rotateEnd.set(event.clientX, event.clientY);
            this.rotateDelta.subVectors(this.rotateEnd, this.rotateStart);
            //console.log("target", viewer.camera);
            //console.log("rotate", rotateDelta);

            this.cameraConstraintOverride = false
            // rotating across whole screen goes 360 degrees around
            this.rotateLeft(2 * Math.PI * this.rotateDelta.x / element.clientWidth * this.rotateSpeed);

            // rotating up and down along whole screen attempts to go 360, but limited to 180
            this.rotateUp(2 * Math.PI * this.rotateDelta.y / element.clientHeight * this.rotateSpeed);

            this.rotateStart.copy(this.rotateEnd);

        } else if (this.currentState === this.STATE.DOLLY) {

            if (this.noZoom === true) return;

            this.dollyEnd.set(event.clientX, event.clientY);
            this.dollyDelta.subVectors(this.dollyEnd, this.dollyStart);

            if (this.dollyDelta.y > 0) {

                this.dollyIn();

            } else {

                this.dollyOut();

            }

            this.dollyStart.copy(this.dollyEnd);

        } else if (this.currentState === this.STATE.PAN) {

            if (this.scope.noPan === true) return;


            this.panEnd.set(event.clientX, event.clientY);
            this.panDelta.subVectors(this.panEnd, this.panStart);


            //disabled since  have some bug (when zoomed on point cloud panning diabled) 

            // var bb=viewer.getBoundingBox();
            // var screenPosMin  = bb.min.clone().project(viewer.camera);
            // screenPosMin.x = viewer.renderArea.clientWidth * (screenPosMin.x + 1) / 2;
            // screenPosMin.y = viewer.renderArea.clientHeight * (1 - (screenPosMin.y + 1) / 2);

            // var screenPosMax = bb.max.clone().project(viewer.camera);
            // screenPosMax.x = viewer.renderArea.clientWidth * (screenPosMax.x + 1) / 2;
            // screenPosMax.y = viewer.renderArea.clientHeight * (1 - (screenPosMax.y + 1) / 2);

            // if(screenPosMax.x+panDelta.x > window.innerWidth*0.95 || screenPosMin.x+panDelta.x > window.innerWidth*0.95 ||
            //     screenPosMax.y+panDelta.y > window.innerHeight*0.95 || screenPosMin.y+panDelta.y > window.innerHeight*0.95 || 
            //     screenPosMax.x+panDelta.x < window.innerWidth*0.05 || screenPosMin.x+panDelta.x < window.innerWidth*0.05 ||
            //     screenPosMax.y+panDelta.y < window.innerHeight*0.05 || screenPosMin.y+panDelta.y < window.innerHeight*0.05 )
            // {
            //     return;
            // }

            this.pan(this.panDelta.x, this.panDelta.y);


            this.panStart.copy(this.panEnd);

        }



        //scope.update();

    }

    onMouseUp = (event:MouseEvent) => {

        if (this.enabled === false) return;

        //this.domElement.removeEventListener('mousemove', this.onMouseMove, false);
        //this.domElement.removeEventListener('mouseup', this.onMouseUp, false);
        this.dispatchEvent(this.endEvent);
        this.app.activeTool.lastMouseUpDownEvent = event

        if (this.app.activeTool.isHandlingMouseMove && this.app.activeTool.isMouseDrag()) {
            this.app.activeTool.onMouseUp(event)
        } else if (!this.app.activeTool.isMouseDrag()) {
            this.app.activeTool.onMouseUp(event)
        }
        this.app.activeTool.mouseDragStart = null
        this.app.activeTool.isHandlingMouseMove = false

        this.currentState = this.STATE.NONE;

    }

// pointivo start

    // function onMouseWheel(event) {

    //     if (scope.enabled === false || scope.noZoom === true) return;

    //     event.preventDefault();

    //     var delta = 0;

    //     if (event.wheelDelta !== undefined) { // WebKit / Opera / Explorer 9

    //         delta = event.wheelDelta;

    //     } else if (event.detail !== undefined) { // Firefox

    //         delta = -event.detail;

    //     }

    //     if (delta > 0) {

    //         scope.dollyOut();

    //     } else {

    //         scope.dollyIn();

    //     }

    //     //scope.update();
    //     scope.dispatchEvent(startEvent);
    //     scope.dispatchEvent(endEvent);

    // }

    getWheelDirection = (wheelEvent: WheelEvent):number => {
        let delta = 0;
        let wheelDelta = (wheelEvent as any).wheelDelta;
        let deltaY = wheelEvent.deltaY;
        let detail = wheelEvent.detail;

        if (wheelDelta) {
            // CHROME WIN/MAC | SAFARI 7 MAC | OPERA WIN/MAC | EDGE
            delta = -wheelDelta / 120;
        } else if(deltaY) {
            // FIREFOX WIN / MAC | IE
            deltaY > 0 ? delta = 1 : delta = -1;
        } else if(detail){
            detail > 0 ? delta = 1 : delta = -1;
        }
        return delta;
    };

    onMouseWheel = (event:WheelEvent) => {

        if (this.enabled === false || this.noZoom === true) return;

        event.preventDefault();

        var wheelDirection = this.getWheelDirection(event);
        var scaleFactor = 10;
        var edgeLabelSizeIncrement = .01 * scaleFactor;
        var vertexSizeIncrement = .001 * scaleFactor;
        var minVertexSize = .00001 * scaleFactor;
        var redrawWireframe = false;

        if (wheelDirection < 0) {
            // down

            if (event.ctrlKey && event.shiftKey) {
                this.app.edgeLabelSize = this.app.edgeLabelSize + edgeLabelSizeIncrement;
                redrawWireframe = true;
            } else if (event.ctrlKey) {
                this.app.vertexSize = this.app.vertexSize + vertexSizeIncrement;
                redrawWireframe = true;
            } else if (event.shiftKey) {
                this.app.edgeSize = this.app.edgeSize + vertexSizeIncrement;
                redrawWireframe = true;
            } else if (this.app.activeTool.onWheelEvent(event)) {

            } else {
                this.dollyOut();
            }

        } else {
            // up

            if (event.ctrlKey && event.shiftKey) {
                this.app.edgeLabelSize = Math.max(0.001, this.app.edgeLabelSize - edgeLabelSizeIncrement);
                redrawWireframe = true;
            } else if (event.ctrlKey) {
                this.app.vertexSize = Math.max(minVertexSize, this.app.vertexSize - vertexSizeIncrement);
                redrawWireframe = true;
            } else if (event.shiftKey) {
                this.app.edgeSize = Math.max(minVertexSize, this.app.edgeSize - vertexSizeIncrement);
                redrawWireframe = true;
            } else if (this.app.activeTool.onWheelEvent(event)) {
            } else {
                this.dollyIn();

            }

        }

        if (redrawWireframe) {
            //console.log("Vertex size = " + Math.floor(100*app.vertexSize) + ", Edge size = " + Math.floor(100*app.edgeSize));
            this.app.wireframeLayer.updateGeometry();
        }
        //if (viewer.scale % 5 == 0) {
            //wfOps.redrawWireframe();
            // rescale labels/etc, but don't regenerate the entire scene
        //}
        //scope.update();
        this.dispatchEvent(this.startEvent);
        this.dispatchEvent(this.endEvent);

    }

    // pointivo end

    onKeyDown = (event:KeyboardEvent) => {
        if (this.enabled === false || this.noKeys === true || this.noPan === true) return;

        // only handle single key presses
        if(!event.ctrlKey && !event.shiftKey && !event.altKey){

            switch (event.keyCode) {

                case this.keys.UP:
                    this.pan(0, this.keyPanSpeed);
                    break;

                case this.keys.BOTTOM:
                    this.pan(0, -this.keyPanSpeed);
                    break;

                case this.keys.LEFT:
                    this.pan(this.keyPanSpeed, 0);
                    break;

                case this.keys.RIGHT:
                    this.pan(-this.keyPanSpeed, 0);
                    break;

                case this.keys.Q:
                    this.rotateLeft(-.01 * Math.PI);
                    break;

                case this.keys.E:
                    this.rotateLeft(.01 * Math.PI);
                    break;

                case this.keys.W:
                    this.panForward(-.1 * this.keyPanSpeed);
                    break;

                case this.keys.S:
                    this.panForward(.1 * this.keyPanSpeed);
                    break;

                case this.keys.A:
                    this.pan(this.keyPanSpeed, 0);
                    break;

                case this.keys.D:
                    this.pan(-this.keyPanSpeed, 0);
                    break;

                case this.keys.Z:
                    // todo: reset
                    break;

            }
        }

        // send event to application handler
        this.app.activeTool.onKeyDown(event);
    }

    onKeyUp = (event:KeyboardEvent) => {
        // send event to application handler
        this.app.activeTool.onKeyUp(event);
        if (this.enabled === false || this.noKeys === true || this.noPan === true) return;

    }

    touchstart(event) {

        if (this.enabled === false) return;

        switch (event.touches.length) {

            case 1: // one-fingered touch: rotate

                if (this.noRotate === true) return;

                this.currentState = this.STATE.TOUCH_ROTATE;

                this.rotateStart.set(event.touches[0].pageX, event.touches[0].pageY);
                break;

            case 2: // two-fingered touch: dolly

                if (this.noZoom === true) return;

                this.currentState = this.STATE.TOUCH_DOLLY;

                var dx = event.touches[0].pageX - event.touches[1].pageX;
                var dy = event.touches[0].pageY - event.touches[1].pageY;
                var distance = Math.sqrt(dx * dx + dy * dy);
                this.dollyStart.set(0, distance);
                break;

            case 3: // three-fingered touch: pan

                if (this.noPan === true) return;

                this.currentState = this.STATE.TOUCH_PAN;

                this.panStart.set(event.touches[0].pageX, event.touches[0].pageY);
                break;

            default:

                this.currentState = this.STATE.NONE;

        }

        this.dispatchEvent(this.startEvent);

    }

    touchmove(event) {

        if (this.enabled === false) return;

        event.preventDefault();
        event.stopPropagation();

        var element = this.domElement === document ? this.domElement.body : this.domElement;

        switch (event.touches.length) {

            case 1: // one-fingered touch: rotate

                if (this.noRotate === true) return;
                if (this.currentState !== this.STATE.TOUCH_ROTATE) return;

                this.rotateEnd.set(event.touches[0].pageX, event.touches[0].pageY);
                this.rotateDelta.subVectors(this.rotateEnd, this.rotateStart);

                // rotating across whole screen goes 360 degrees around
                this.rotateLeft(2 * Math.PI * this.rotateDelta.x / element.clientWidth * this.rotateSpeed);
                // rotating up and down along whole screen attempts to go 360, but limited to 180
                this.rotateUp(2 * Math.PI * this.rotateDelta.y / element.clientHeight * this.rotateSpeed);

                this.rotateStart.copy(this.rotateEnd);

                //scope.update();
                break;

            case 2: // two-fingered touch: dolly

                if (this.noZoom === true) return;
                if (this.currentState !== this.STATE.TOUCH_DOLLY) return;

                var dx = event.touches[0].pageX - event.touches[1].pageX;
                var dy = event.touches[0].pageY - event.touches[1].pageY;
                var distance = Math.sqrt(dx * dx + dy * dy);

                this.dollyEnd.set(0, distance);
                this.dollyDelta.subVectors(this.dollyEnd, this.dollyStart);

                var ew = element.clientWidth;
                var eh = element.clientHeight;
                var diagonal = Math.sqrt(ew * ew + eh * eh);
                var delta = this.dollyDelta.y / diagonal;

                if (this.dollyDelta.y > 0) {

                    this.dollyOut(1 - delta);

                } else {

                    this.dollyIn(1 + delta);

                }

                this.dollyStart.copy(this.dollyEnd);

                //scope.update();
                break;

            case 3: // three-fingered touch: pan

                if (this.noPan === true) return;
                if (this.currentState !== this.STATE.TOUCH_PAN) return;

                this.panEnd.set(event.touches[0].pageX, event.touches[0].pageY);
                this.panDelta.subVectors(this.panEnd, this.panStart);

                this.pan(this.panDelta.x, this.panDelta.y);

                this.panStart.copy(this.panEnd);

                //scope.update();
                break;

            default:

                this.currentState = this.STATE.NONE;

        }

    }

    touchend(/* event */) {

        if (this.enabled === false) return;

        this.dispatchEvent(this.endEvent);
        this.currentState = this.STATE.NONE;

    }



}

//PVOrbitControls.prototype = Object.create(THREE.EventDispatcher.prototype);
//window["PVOrbitControls"] = PVOrbitControls
//console.log("pvorbit", window)