import {ProjectImageCollectionSortModel} from "./projectImageCollectionSort.model";
import {ProjectImageModel} from "../projectImages/projectImage.model";

export class ProjectImageCollectionSortByFieldModel extends ProjectImageCollectionSortModel {

    private _fieldAccessor: (projectImageModel:ProjectImageModel) => any;

    constructor(fieldAccessor:(projectImageModel:ProjectImageModel) => any = null) {
        super();
        this.fieldAccessor = fieldAccessor;
        this.sortFunction = this.sortByField.bind(this);
    }

    sortByField(a, b){
        if (this.fieldAccessor(a) < this.fieldAccessor(b))
            return -1;
        if (this.fieldAccessor(a) > this.fieldAccessor(b))
            return 1;
        return 0;
    }

    get fieldAccessor(): (projectImageModel:ProjectImageModel) => any {
        return this._fieldAccessor;
    }

    set fieldAccessor(fieldAccessor:(projectImageModel:ProjectImageModel) => any){
        this._fieldAccessor = fieldAccessor;
        this.triggerSortUpdated();
    }
}