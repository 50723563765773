import {Layer} from "./layer";
import {WireframeElementType} from "./wireframeElementType";
import {LabelLayer} from "./labelLayer";
import {WireframeApplication} from "./wireframeApplication";

export default class ElementLayer extends Layer {
    type:WireframeElementType

    labelLayer:LabelLayer

    constructor(app:WireframeApplication, name:string, label:string, type:WireframeElementType) {
        super(app, name, label)
        this.type = type
        this.supportsEditing = true
        this.supportsInteraction = true
    }
}