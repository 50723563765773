import * as PVProperty from "../../models/property/propertyLoaders"
import {WireframeApplication} from "../../application/wireframeApplication";
import {WireframeOperations} from "../../wireframeOperations";
import {NumberProperty} from "../../models/property/numberProperty";
import {DoubleProperty} from "../../models/property/doubleProperty";
import {SceneService} from "../../services/scenes/scene.service";

export default {
    bindings: {
        modifyvaluefunc: '=',
        pmd: '=',
        prop: '=',
        propval: '=',
        multival: '='
    },
    controller: ['sceneService', function (sceneService:SceneService) {
        let that = this;
        let app = sceneService.wireframeApplication
        this.options = [];

        this.updateOptions = function () {

            if (this.prop) {
                let options = [];
                this.prop.values.forEach(function (o) {
                    options.push(o);
                });
                if (this.multival) {
                    options.unshift({value: null, label: "Multiple"});
                }
                this.options = options;
            }
        };

        this.isPropertyInstanceOf = function (classname) {
            return this.prop instanceof PVProperty[classname];
        };

        this.stringToFunction = function (str) {
            let arr = str.split(".");

            let fn = (window || this);
            for (let i = 0, len = arr.length; i < len; i++) {
                fn = fn[arr[i]];
            }

            if (typeof fn !== "function") {
                throw new Error("function not found");
            }

            return fn;
        };

        this.debug = function () {
            console.log('propCtrl', this);
        };

        this.changeValue = function () {
            if (this.propval == undefined) return;
            if (this.prop instanceof NumberProperty) {
                this.propval = this.prop.clamp(this.propval);
            }
            this.modifyvaluefunc(this.pmd.index, this.prop, this.propval)
        };

        this.getValue = function () {
            if (this.prop instanceof DoubleProperty) {
                return Number.parseFloat(this.propval).toFixed(this.prop.numDigits);
            }
            return this.propval;
        };

        this.pickOperation = null;

        this.startElementSelection = function () {
            let that = this;
            if (this.pickOperation) {
                app.endElementPick();
                this.pickOperation = null;
            }
            if (app.currentPickOperation) {
                app.endElementPick();
            }
            //this.propval = this.prop.getDefaultValue();
            this.pickOperation = this.prop.getPickOperation(that.propval);
            this.pickOperation.onElementPicked.push(function (el) {
                //console.log("pick callback", el);
                if (null != el) {
                    that.propval = that.pickOperation.currentValue;
                }
                if (null == el) {
                    that.propval = {};
                    app.endElementPick();
                }
            });
            this.pickOperation.onComplete.push(function () {
                that.pickOperation = null;
                that.changeValue();
            });


            app.startElementPick(this.pickOperation);

        };

        this.getSelectedElementLabel = function () {
            let elements = [];
            if (this.propval && this.propval.elements) {
                elements = this.prop.getElements(this.propval);
            }
            if (elements.length > 0) {
                let str = app.getLabelForElement(elements[0]);
                if (elements.length > 1) str += " +" + (elements.length - 1);
                return str;
            }
            return "-";
        };

        this.highlightSelection = function () {
            let elements = [];
            if (this.propval && this.propval.elements) {
                elements = this.prop.getElements(this.propval);
                elements.forEach(function (el) {
                    app.highlightWireframeElement(el);
                })
            }
            if (elements.length > 0) {
                app.lookAtElements(elements);
                app.wireframeLayer.updateWireframeMaterials();
            }
        };
    }],
    template: [
        '<input ng-click="$ctrl.debug()" ng-if="$ctrl.isPropertyInstanceOf(\'NumberProperty\') && !$ctrl.isPropertyInstanceOf(\'EnumProperty\')" class="wireframePropertyRow" style="width:100px" placeholder="{{$ctrl.multival ? \'Multiple\' : \'\'}}" ng-model="$ctrl.propval"  ng-change="$ctrl.changeValue()" ng-model-options="{allowInvalid: true}" ng-disabled="$ctrl.prop.isReadonly" type="number" min="{{ $ctrl.prop.min }}" max="{{ $ctrl.prop.max }}" step="{{ $ctrl.prop.step }}">',
        '<input ng-click="$ctrl.debug()" ng-if="$ctrl.isPropertyInstanceOf(\'TextProperty\')" class="wireframePropertyRow" style="width:100px" placeholder="{{$ctrl.multival ? \'Multiple\' : \'\'}}" ng-model="$ctrl.propval" ng-change="$ctrl.changeValue()" ng-disabled="$ctrl.prop.isReadonly" type="text">',
        '<select ng-click="$ctrl.debug()" ng-if="$ctrl.isPropertyInstanceOf(\'EnumProperty\')" class="wireframePropertyRow" style="width:100px" ng-model="$ctrl.propval" ng-init="$ctrl.updateOptions()" ng-change="$ctrl.changeValue()" ng-disabled="$ctrl.prop.isReadonly" ng-options="o.value as o.labelSprite for o in $ctrl.options"></select>',
        '<button class="propertyButton" style="width:80px" ng-click="$ctrl.highlightSelection()" ng-if="$ctrl.isPropertyInstanceOf(\'ElementReferenceProperty\')" class="wireframePropertyRow" style="width:50px" ng-disabled="$ctrl.prop.isReadonly" data-toggle="tooltip" title="{{ $ctrl.getSelectedElementLabel() }}">{{ $ctrl.getSelectedElementLabel() }}</button><button class="propertyButton" ng-if="$ctrl.isPropertyInstanceOf(\'ElementReferenceProperty\')" ng-click="$ctrl.startElementSelection()">Set</button>',

    ].join('')
}

