import {WireframeElement} from "../../application/wireframeElement";
import {GenericCollection} from "../generic/generic.collection";
import {WireframeElementType} from "../../application/wireframeElementType";
import WireframeElementStatisticCollection from "../wireframeElementStatistics/wireframeElementStatistic.collection";
import {WireframeElementStatisticModel} from "../../models/wireframeElementStatistics/wireframeElementStatistic.model";

export default class WireframeElementCollection extends GenericCollection<WireframeElement> {

    constructor() {
        super();
    }

    getWireframeElementStatistics():WireframeElementStatisticCollection{
        let wireframeElementStatistics = new WireframeElementStatisticCollection();
        let counts = {};

        this.forEach((wireframeElement) => {
           let key = WireframeElementType[wireframeElement.pvObject.pvType];
           if(!counts[key]) counts[key] = 0;
           counts[key]++;
        });

        for(let key in counts){
            let wireframeElementStatistic = new WireframeElementStatisticModel(WireframeElementType[key], counts[key]);
            wireframeElementStatistics.add(wireframeElementStatistic)
        }

        return wireframeElementStatistics;
    }
}