import {
    Component,
    Input
} from '@angular/core';
import {WireframeElement} from "../../../application/wireframeElement";
import {TextProperty} from "../../../models/property/textProperty";
import {PropertyManager} from "../../../models/property/propertyManager";
import {PropertyValue} from "../../../models/property/propertyValue";
import {CompoundProperty} from "../../../models/property/compoundProperty";

@Component({
    selector: 'text-property-editor',
    styles: [require('./textPropertyEditor.component.scss')],
    template: require('./textPropertyEditor.component.html')
})
export default class TextPropertyEditorComponent {
    @Input() wireframeElements: WireframeElement[];
    @Input() propertyManager: PropertyManager;
    @Input() property: TextProperty;

    constructor(
    ) {
    }

    getPropertyValue(wireframeComponent): string {
        let value: string = null;
        if (!wireframeComponent) return value;

        wireframeComponent.properties.forEach((currentPropertyValue) => {
            let currentProperty = this.propertyManager.getProperty(currentPropertyValue.id);
            if (this.property.id == currentProperty.id) {
                let propertyValue: PropertyValue = currentProperty.getPropertyValue(wireframeComponent);
                if(!propertyValue) return;
                value = propertyValue.value;
            } else if (currentProperty instanceof CompoundProperty) {
                // compound properties
                let compoundProperty = currentProperty as CompoundProperty;
                compoundProperty.childProperties.forEach((childProperty) => {
                    if (this.property.name === childProperty.name) {
                        let parentPropertyValue = currentProperty.getPropertyValue(wireframeComponent);
                        if(!parentPropertyValue) return;
                        value = parentPropertyValue.value[this.property.name as string];
                    }
                })
            }
        });

        return value;
    }

    get combinedPropertyValue(): string {
        let value = null;
        if (!this.property) return value;
        if (!this.wireframeElements) return value;
        let values: string[] = [];
        this.wireframeElements.forEach((wireframeElement) => {
            let wireframeComponent = wireframeElement.pvObject;
            let value = this.getPropertyValue(wireframeComponent);
            if(value == null) return;
            values.push(value);
        });

        if (values.length > 0 && this.allValuesAreTheSame) {
            value = values[0];
        }
        return value;
    }

    set combinedPropertyValue(value:string) {
        if (!this.property) return;
        this.wireframeElements.forEach((wireframeElement) => {
            let wireframeComponent = wireframeElement.pvObject;
            wireframeComponent.properties.forEach((currentPropertyValue) => {
                let currentProperty = this.propertyManager.getProperty(currentPropertyValue.id);
                if (this.property.id == currentProperty.id) {
                    currentProperty.setValue(wireframeElement, value);
                } else if (currentProperty instanceof CompoundProperty) {
                    // compound properties
                    let compoundProperty = currentProperty as CompoundProperty;
                    compoundProperty.childProperties.forEach((childProperty) => {
                        if (this.property.name === childProperty.name) {
                            let parentPropertyValue = currentProperty.getPropertyValue(wireframeComponent);
                            if(!parentPropertyValue) return;
                            parentPropertyValue.value[this.property.name as string] = value;
                        }
                    })
                }
            });
        });
        this.propertyManager.triggerPropertiesChanged();
    }

    get allValuesAreTheSame(): boolean {
        let allValuesAreTheSame = false;
        if (!this.property) return allValuesAreTheSame;
        if (!this.wireframeElements) return allValuesAreTheSame;
        let values:string[] = [];
        this.wireframeElements.forEach((wireframeElement) => {
            let wireframeComponent = wireframeElement.pvObject;
            let value = this.getPropertyValue(wireframeComponent);
            values.push(value);
        });
        allValuesAreTheSame = values.every((val, i, arr) => val === arr[0]);
        return allValuesAreTheSame;
    }

    get placeholder():string{
        let placeholderValue = "enter text";
        if(this.wireframeElements.length > 1){
            placeholderValue = "Multiple";
        }
        return placeholderValue;
    }

    get hasMultipleValues():boolean{
        let multi = this.wireframeElements.length > 0;
        return multi;
    }
    
    get isReadOnly(): boolean {
        let readOnly = true;
        if (!this.property) return readOnly;
        readOnly = this.property.isReadonly;
        return readOnly;
    }

    onChangeValue(event){
        
    }
}