import {Injectable} from '@angular/core';
import {ResourceManager, S3Object} from "../../resourceManager";
import {WireframeApplication} from "../../application/wireframeApplication";
import {PV} from "../../wireframe";
import FrameMetadata = PV.FrameMetadata;

@Injectable({
    providedIn: 'root',
})
export class FrameService {

    protected wireframeApplication: WireframeApplication;

    constructor(wireframeApplication: WireframeApplication) {
        this.wireframeApplication = wireframeApplication;
    }

    get resourceManager(): ResourceManager {
        if (!this.wireframeApplication) return null;
        return this.wireframeApplication.resourceManager;
    }

    get project(): any {
        if (!this.wireframeApplication) return null;
        if (!this.wireframeApplication.projectData) return null;
        return this.wireframeApplication.projectData;
    }

    get projectMetadata(): any {
        if (!this.project) return null;
        if (!this.project.metadata) return null;
        return this.project.metadata;
    }

    get framesUsedInRendering(): Map<string, FrameMetadata> {
        if (!this.projectMetadata) return null;
        if (!this.projectMetadata.rendered) return null;
        return this.projectMetadata.rendered;
    }

    public getFrameMetadataKeys(): string[]{
        if(!this.framesUsedInRendering) return [];
        return Object.keys(this.framesUsedInRendering);
    }

    public getFrameMetadataValues(): FrameMetadata[]{
        if(!this.framesUsedInRendering) return [];
        return Object.values(this.framesUsedInRendering);
    }

    public getFrameMetadataMap(): Map<string, FrameMetadata>{
        if(!this.framesUsedInRendering) return new Map<string, FrameMetadata>();
        return this.framesUsedInRendering;
    }

    public getFrameMetadata(frameKey: string){
        if(!this.framesUsedInRendering) return null;
        return this.framesUsedInRendering[frameKey];
    }

    public async getFrameData(frameKey: string, onProgress: any = null): Promise<any> {
        let nullResponse = new Promise<any>(resolve => {resolve(null);});
        if (!this.resourceManager) return nullResponse;

        // cache
        let cachedFrameData = this.resourceManager.imageData[frameKey];
        if (cachedFrameData) {
            return new Promise<any>(resolve => {
                resolve(cachedFrameData)
            });
        }

        // frame metadata
        let frameMetadata: FrameMetadata = this.framesUsedInRendering[frameKey];
        if (!frameMetadata) return nullResponse;

        // s3 object
        let frameS3Object: S3Object = this.resourceManager.getFrameKey(frameMetadata.frameId);
        if (!frameS3Object) return nullResponse;

        return new Promise<any>((resolve, reject) => {
            this.resourceManager.getImageData(frameS3Object,
                (frameDataUrl) => {
                    resolve(frameDataUrl);
                },
                (progress) => {
                    let percent = Math.round((progress.loaded / progress.total) * 100);
                    if(onProgress) onProgress(percent);
                })
        });
    }

    public async getFrameThumbnail(frameKey: string, onProgress: any = null): Promise<any> {
        let nullResponse = new Promise<any>(resolve => {resolve(null);});
        if (!this.resourceManager) return nullResponse;

        // cache
        let cachedFrameData = this.resourceManager.imageData[frameKey];
        if (cachedFrameData) {
            return new Promise<any>(resolve => {
                resolve(cachedFrameData)
            });
        }

        // frame metadata
        let frameMetadata: FrameMetadata = this.framesUsedInRendering[frameKey];
        if (!frameMetadata) return nullResponse;

        // s3 object
        if(!this.resourceManager.hasFrameThumbnailKey(frameMetadata.frameId)) return nullResponse;
        let frameS3Object: S3Object = this.resourceManager.getFrameThumbnailKey(frameMetadata.frameId);
        if (!frameS3Object) return nullResponse;

        return new Promise<any>((resolve, reject) => {
            this.resourceManager.getImageData(frameS3Object,
                (frameDataUrl) => {
                    resolve(frameDataUrl);
                },
                (progress) => {
                    let percent = Math.round((progress.loaded / progress.total) * 100);
                    if(onProgress) onProgress(percent);
                })
        });
    }

    public async getFramePrint(frameKey: string, onProgress: any = null): Promise<any> {
        let nullResponse = new Promise<any>(resolve => {resolve(null);});
        if (!this.resourceManager) return nullResponse;

        // cache
        let cachedFrameData = this.resourceManager.imageData[frameKey];
        if (cachedFrameData) {
            return new Promise<any>(resolve => {
                resolve(cachedFrameData)
            });
        }

        // frame metadata
        let frameMetadata: FrameMetadata = this.framesUsedInRendering[frameKey];
        if (!frameMetadata) return nullResponse;

        // s3 object
        if(!this.resourceManager.hasFramePrintKey(frameMetadata.frameId)) return nullResponse;
        let frameS3Object: S3Object = this.resourceManager.getFramePrintKey(frameMetadata.frameId);
        if (!frameS3Object) return nullResponse;

        return new Promise<any>((resolve, reject) => {
            this.resourceManager.getImageData(frameS3Object,
                (frameDataUrl) => {
                    resolve(frameDataUrl);
                },
                (progress) => {
                    let percent = Math.round((progress.loaded / progress.total) * 100);
                    if(onProgress) onProgress(percent);
                })
        });
    }
}