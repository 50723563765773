import tpl from './propertyList.html'
import * as PVProperty from "../../models/property/propertyLoaders"
import {WireframeApplication} from "../../application/wireframeApplication";
import {WireframeOperations} from "../../wireframeOperations";
import {PropertyValue} from "../../models/property/propertyValue";
import {SceneService} from "../../services/scenes/scene.service";


export default {
    bindings: {},
    template: tpl,
    controller: ['$rootScope', '$scope', function ($rootScope, $scope, sceneService:SceneService) {
        let app = sceneService.wireframeApplication
        let ctrl = this;
        this.primarySelection = null;
        this.propertyManager = app.propertyManager;
        this.properties = [];

        $rootScope.$on('propertiesChanged', function () {
            $scope.$applyAsync();
        });

        this.addProperty = function (val) {
            //console.log("addProperty changed ", this.addPropertyValue);
            if (!ctrl.addPropertyValue.type) return;
            let prop;
            let propId;
            if (null == ctrl.addPropertyValue.id) {
                prop = new PVProperty[this.addPropertyValue.type.substr(1)]();

                propId = Number(app.wireframe.addProperty(prop));

            } else {
                propId = Number(ctrl.addPropertyValue.id);
                prop = app.wireframe.properties[propId];
            }

            app.selectedElements.forEach(function (el) {
                if (!prop.allowableElementTypes.includes(el.pvObject.pvType)) return;
                let existing = el.pvObject.properties.filter(function (pv) {
                    return pv.id == propId
                });
                if (!prop.allowMultiplePerElement && existing.length > 0) return;

                let pv = new PropertyValue(propId, prop.getDefaultValue());
                pv.id = propId;
                pv.value = prop.getDefaultValue();
                el.pvObject.properties.push(pv);
                prop.onAddToElement(el);
            });
            app.propertiesChanged();

            ctrl.propertyManager.aggregateProperties(app.selectedElements);
            ctrl.addPropertyValue = ctrl.propOptions[0];
            app.wireframeLayer.redrawWireframe();
        };

        this.modifyPropertyValue = function (propIdx, prop, val) {
            //console.log("modifyproperty on propIdx/field " + propIdx + "/" + field);
            let pmd = ctrl.propertyManager.propertyMetadata[propIdx];
            if (!pmd) return;
            if (val == undefined) return;
            //console.log("modifyproperty on propIdx/field " + propIdx + " val " + pmd.value, pmd);

            ctrl.propertyManager.propertyValues[propIdx].forEach(function (p) {
                if (pmd.property.childProperties) {
                    p.value[prop.name] = val;
                } else {
                    p.value = val;
                }
            });


            app.propertiesChanged();
            app.wireframeLayer.redrawWireframe();
            //this.propertyManager.aggregateProperties(app.selectedElements);
        };

        this.deleteProperty = function ($event, propIdx) {
            let mgr = ctrl.propertyManager;
            let pmd = mgr.propertyMetadata[propIdx];
            if (pmd.property.isReadonly) return;
            let propId = pmd.id;

            if ($event.shiftKey) {
                console.log("Deleting property " + propId + " from wireframe");
                pmd.property.onRemoveFromWireframe();
                app.wireframe.deleteProperty(propId);
            } else {
                //console.log("ctrl deleteprop " + keyIdx, propKey);
                app.selectedElements.forEach(function (el) {
                    if (!el.pvObject.properties) {
                        return;
                    }
                    for (let i = el.pvObject.properties.length - 1; i >= 0; i--) {
                        let p = el.pvObject.properties[i];
                        if (p.id == propId) {
                            pmd.property.removeValue(el);
                        }
                    }
                });
            }
            app.propertiesChanged();
            ctrl.propertyManager.aggregateProperties(app.selectedElements);
            app.wireframeLayer.redrawWireframe();
            ctrl.updatePropertyOptions();
        };

        this.basePropOptions = [
            {label: "Add Property ...", type: null, id: null},
            {label: "Custom Float Property", type: ".DoubleProperty", id: null},
            {label: "Custom Integer Property", type: ".IntegerProperty", id: null},
            {label: "Custom Text", type: ".TextProperty", id: null},
            {label: "Edge Guide", type: ".EdgeGuideProperty", id: null},
            {label: "---", disabled: true}
        ];

        this.filterVisibleProperties = function (prop) {
            //console.log("filterVisibleProps " + prop.visible, prop);
            return prop.isVisible === true;
        };

        this.propOptions = [];

        this.addPropertyValue = 0;

        this.getPropertyOptions = function () {
            return this.propOptions;
        };

        $rootScope.$on('selectionChanged', function () {
            ctrl.updatePropertyOptions();
        });

        this.updatePropertyOptions = function () {
            this.propOptions = JSON.parse(JSON.stringify(this.basePropOptions));
            for (let propId in app.wireframe.properties) {
                let prop = app.wireframe.properties[propId];
                if (prop.isReadonly || !prop.isVisible) continue;
                this.propOptions.push({label: prop.getLabel(), type: prop.type, id: propId});
            }
            this.addPropertyValue = this.propOptions[0];
        };

        this.updatePropertyOptions();
    }]
}

