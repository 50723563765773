import {
    Component,
    ComponentRef,
    EventEmitter,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import InteractiveFilterEditorComponent from "../interactiveFilterEditor/interactiveFilterEditor.component";
import InteractiveFilterLabelComponent from "../interactiveFilterLabel/interactiveFilterLabel.component";
import {InteractiveModel} from "../../models/interactive/interactive.model";
import {InteractiveFilterModel} from "../../models/interactive/interactiveFilter.model";

@Component({
    selector: 'ptv-interactive-filter',
    styles: [require('./interactiveFilter.component.scss')],
    template: require('./interactiveFilter.component.html')
})
export default abstract class InteractiveFilterComponent<T extends InteractiveModel> implements OnInit, OnDestroy {
    @ViewChild("interactiveFilterContainer", {read: ViewContainerRef, static: false}) interactiveFilterContainerRef: ViewContainerRef;
    @Input() interactiveFilterModel: InteractiveFilterModel<T>;
    @Output() filterUpdated = new EventEmitter<InteractiveFilterModel<T>>();
    interactiveFilterLabelComponent: ComponentRef<InteractiveFilterLabelComponent<T>>;
    interactiveFilterEditorComponent: ComponentRef<InteractiveFilterEditorComponent<T>>;

    private interactiveCollectionFilterChangedSubscription;

    constructor(
        private injector: Injector) {
    }

    ngOnInit(): void {
        this.interactiveCollectionFilterChangedSubscription = this.interactiveFilterModel.focusedChanged.subscribe(this.onFilterFocusChanged.bind(this));
        this.showFocusedState();
    }

    ngOnDestroy(): void {
        if(this.interactiveCollectionFilterChangedSubscription) this.interactiveCollectionFilterChangedSubscription.unsubscribe();
        this.clearInteractiveFilterContainerRef();
    }

    onClick(event){
        this.interactiveFilterModel.focused = true;
    }

    onFilterFocusChanged(){
        this.showFocusedState();
    }

    onFilterUpdated(interactiveFilterModel:InteractiveFilterModel<T>){
        this.showInteractiveFilterLabel();
        this.triggerFilterUpdated();
    }

    triggerFilterUpdated(){
        this.filterUpdated.emit(this.interactiveFilterModel);
    }

    showInteractiveFilterLabel(){
        this.clearInteractiveFilterContainerRef();
        const injector = this.createInteractiveFilterInjector();
        this.interactiveFilterLabelComponent = this.createInteractiveFilterLabelComponent(injector);
    }

    showInteractiveFilterEditor(){
        this.clearInteractiveFilterContainerRef();
        const injector = this.createInteractiveFilterInjector();
        this.interactiveFilterEditorComponent = this.createInteractiveFilterEditorComponent(injector);
        this.interactiveFilterEditorComponent.instance.filterUpdated.subscribe(this.onFilterUpdated.bind(this));
    }

    abstract createInteractiveFilterLabelComponent(injector:Injector) : ComponentRef<InteractiveFilterLabelComponent<T>>;
    abstract createInteractiveFilterEditorComponent(injector:Injector) : ComponentRef<InteractiveFilterEditorComponent<T>>;

    createInteractiveFilterInjector() {
        let self = this;
        return Injector.create({
            providers: [{
                provide: 'interactiveFilterModel',
                useValue: self.interactiveFilterModel,
            }],
            parent: self.injector
        });
    }

    showFocusedState(){
        let focused = this.interactiveFilterModel.focused;
        if(focused){
            this.showInteractiveFilterEditor();
        } else {
            this.showInteractiveFilterLabel();
        }
    }

    clearInteractiveFilterContainerRef(){
        this.interactiveFilterContainerRef.clear();
    }
}