import * as THREE from 'three';
import {CameraElement} from "./cameraElement";
import {ResourceManager} from "../resourceManager";
import {PV} from "../wireframe";
import FrameMetadata = PV.FrameMetadata;
import {WireframeApplication} from "./wireframeApplication";
import * as TWEEN from "@tweenjs/tween.js";

export class CameraRay {
    constructor(public imgCoord:THREE.Vector2 = new THREE.Vector2()) {}
    direction:THREE.Vector3 = new THREE.Vector3();
    distortedCoord:THREE.Vector2 = new THREE.Vector2()
}

class CanvasVertex extends CameraRay {
    constructor(public imgCoord:THREE.Vector2 = new THREE.Vector2(), public vertIdx = -1) {
        super(imgCoord)
    }
}

export class CameraProjector extends THREE.Object3D {
    _enabled:boolean = false
    private _isInitialized:boolean = false

    targetPoint:THREE.Vector3 = new THREE.Vector3()
    targetNormal:THREE.Vector3

    _targetPlane:THREE.Plane = new THREE.Plane()
    _planeGeom:THREE.PlaneGeometry
    _planeMesh:THREE.Mesh
    _planeMat:THREE.MeshBasicMaterial = new THREE.MeshBasicMaterial({ depthTest: true, side: THREE.DoubleSide, transparent: true, opacity: 1 } )
    _planeTex:THREE.Texture

    private rays:CanvasVertex[] = []
    private geomSubDivisionDimensions = new THREE.Vector2()
    private geomSubDivisionTileCounts = new THREE.Vector2(10, 0)
    _projectionLineGeom:THREE.Geometry
    _projectionLines:THREE.LineSegments
    _projectionLineMaterial = new THREE.LineBasicMaterial({ color: 0xffffff, opacity: 0, transparent: true })

    constructor(private app:WireframeApplication, private _cameraElement:CameraElement) {
        super()
        //this._planeMat = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, transparent: true, opacity: 0 } )
        //this._planeGeom = new THREE.PlaneGeometry(1, 1, this.xSubDivideCount, 1)
        this._planeMesh = new THREE.Mesh(this._planeGeom, this._planeMat)
        this._planeMesh.renderOrder = -10
        this.add(this._planeMesh)
        this.frustumCulled = false

        this._projectionLineGeom = new THREE.Geometry()
        for (let i = 0; i < 8; i++) this._projectionLineGeom.vertices.push(new THREE.Vector3())

        this._projectionLines = new THREE.LineSegments(this._projectionLineGeom, this._projectionLineMaterial)
        this.add(this._projectionLines)
        if (null != _cameraElement) this.cameraElement = _cameraElement
    }

    private createPlaneGeometry() {
        let that = this
        if (this._planeGeom) this._planeGeom.dispose()
        let width = this._cameraElement.pvObject.frameMetadata.width
        let height = this._cameraElement.pvObject.frameMetadata.height
        this.geomSubDivisionDimensions.x = width / this.geomSubDivisionTileCounts.x
        this.geomSubDivisionTileCounts.y = Math.max(1, Math.round(height / this.geomSubDivisionDimensions.x))
        this.geomSubDivisionDimensions.y = height / this.geomSubDivisionTileCounts.y
        this._planeGeom = new THREE.PlaneGeometry(1, 1, this.geomSubDivisionTileCounts.x, this.geomSubDivisionTileCounts.y)
        this._planeMesh.geometry = this._planeGeom
        this.rays = []
        for (let y = 0; y <= this.geomSubDivisionTileCounts.y; y++) {
            for (let x = 0; x <= this.geomSubDivisionTileCounts.x; x++) {
                let geomVertIdx = (y * (this.geomSubDivisionTileCounts.x + 1)) + x
                let imgCoord = new THREE.Vector2((x / this.geomSubDivisionTileCounts.x), 1 - (y / this.geomSubDivisionTileCounts.y))
                //console.log("x/y " + x + "/" + y + " vertIdx " + geomVertIdx + " coord ",imgCoord)
                let cv = new CanvasVertex(imgCoord, geomVertIdx)
                that._cameraElement.setCameraRayFromImageCoord(cv, false)
                this.rays.push(cv)
            }
        }
    }

    public useTransitions = true
    private _transitionDuration = 200
    private planeMatTween = new TWEEN.Tween(this._planeMat)
    private lineMatTween = new TWEEN.Tween(this._projectionLineMaterial)

    set enabled(b:boolean) {
        if (b == this._enabled) return
        let that = this
        this._enabled = b
        if (!this.useTransitions) {
            if (b) {
                this.updateProjection()
                this.app.rootScene.add(this)
            } else {
                this.app.rootScene.remove(this)
            }
            return
        }

        this.planeMatTween.end()
        this.lineMatTween.end()
        if (b) {
            this.updateProjection()
            this.app.rootScene.add(this)
            //this._planeMat.transparent = true
            this.planeMatTween.to({ opacity: 1 }, this._transitionDuration)
                .start()
                .onUpdate(() => {
                    that._planeMat.needsUpdate = true
                })
            this.lineMatTween.to({ opacity: .2 }, this._transitionDuration)
                .start()
                .onUpdate(() => {
                    that._projectionLineMaterial.needsUpdate = true
                })
        } else {
            this.planeMatTween.to({ opacity: 0 }, this._transitionDuration)
                .start()
                .onUpdate(() => {
                    that._planeMat.needsUpdate = true
                })
                .onComplete(() => {
                    if (!that._enabled) that.app.rootScene.remove(this)
                })
            this.lineMatTween.to({ opacity: 0 }, this._transitionDuration)
                .start()
                .onUpdate(() => {
                    that._projectionLineMaterial.needsUpdate = true
                })
        }
    }

    get enabled():boolean {
        return this._enabled
    }


    set cameraElement(camEl:CameraElement) {
        if (this._cameraElement == camEl) return
        if (!camEl) return
        this._isInitialized = false
        this._cameraElement = camEl
        this.name = "CameraProjector-" + camEl.pvObject.registration.id

    }

    get cameraElement():CameraElement {
        return this._cameraElement
    }

    initialize() {
        this.createPlaneGeometry()
        this.initRays()
        this.targetPoint = this.cameraElement.cameraPositionWorld.clone().add(this.cameraElement.cameraDirectionWorld.clone().setLength(10))
        this.targetNormal = this.cameraElement.cameraDirectionWorld.clone().normalize().negate()
        this.loadThumbnail()
        this._isInitialized = true
    }

    private initRays() {
        let that = this
        this.rays.forEach((r) => {
            that._cameraElement.setCameraRayFromImageCoord(r, false)
        })
    }

    updateProjection() {
        if (!this._isInitialized) this.initialize()
        let ray = new THREE.Ray(this._cameraElement.cameraPositionWorld)
        let canvasVert = new THREE.Vector3()
        this._targetPlane.setFromNormalAndCoplanarPoint(this.targetNormal ? this.targetNormal : new THREE.Vector3(0, 0, 1).applyQuaternion(this._cameraElement.cameraRotation).negate(), this.targetPoint)
        for (let i = 0; i < this.rays.length; i++) {
            let cr = this.rays[i]
            ray.direction.copy(cr.direction).applyQuaternion(this._cameraElement.cameraRotation)
            ray.intersectPlane(this._targetPlane, canvasVert)
            if (null != canvasVert) this._planeGeom.vertices[cr.vertIdx].copy(canvasVert)
        }

        let corners = this.getGeomCorners()
        for (let i = 0; i < 4; i++) {
            this._projectionLineGeom.vertices[i * 2].copy(ray.origin)
            this._projectionLineGeom.vertices[(i * 2) + 1].copy(this._planeGeom.vertices[corners[i]])
        }

        this._projectionLineGeom.verticesNeedUpdate = true
        this._planeGeom.verticesNeedUpdate = true
    }

    /**
     * Returns the geometry vertex indices of the corners, clockwise starting from 0,0
     */
    getGeomCorners():number[] {
        return [
            0,
            this.geomSubDivisionTileCounts.x,
            (this.geomSubDivisionTileCounts.x + 1) * this.geomSubDivisionTileCounts.y,
            (this.geomSubDivisionTileCounts.x + 1) * (this.geomSubDivisionTileCounts.y + 1) - 1
        ]
    }

    loadThumbnail() {
        let that = this
        let cv = this.cameraElement.pvObject
        let fmd:FrameMetadata = cv.imageResource.metadata.imageMetadata
        let rm:ResourceManager = this.app.resourceManager
        /// JG - 2020-04-07 - This now loads the high-resolution/original imagery for projection instead of the "print" version. (PV-1939)
        //let thumbKey = rm.getFramePrintKey(fmd.frameId)
        let thumbKey = rm.getFrameKey(fmd.frameId)
        //console.log("thumbKey", thumbKey)
        this._planeMat.visible = false
        rm.getImageData(thumbKey, (o) => {
            //console.log("thumb", o)
            let loader:THREE.TextureLoader = new THREE.TextureLoader();
            loader.load(o, (tx) => {
                that._planeTex = tx;
                that._planeTex.magFilter = THREE.NearestFilter
                that._planeMat.map = tx;
                that._planeMat.visible = true
                that._planeMat.needsUpdate = true;
            });
        }, null)
    }

    setProjectionForPlane(normal:THREE.Vector3, point:THREE.Vector3) {
        this.targetNormal = normal
        this.targetPoint = point
        this.updateProjection()
    }
}