import $ from 'jquery';
import 'jquery-ui';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/effect';
import {
    WireframeApplication
} from "../../application/wireframeApplication";
import PVUtils from '../../pvUtils';
import PVBuildInfo from '../../build';
import firebase from 'firebase/app';
import 'firebase/database';
import { PointivoFirebaseSingleton } from '../../firebaseSingleton';
import wireframeViewerTemplate from './wireframeViewer.html';
import {WireframeOperations} from "../../wireframeOperations";
//import * as POTREE_PROFILETOOL from '../../lib/potreeChanges/modifiedByPointivo/ProfileTool';
import {PlaneOperations} from "../../planeOperations";
import {WireframeElementType} from "../../application/wireframeElementType";
import {PlaneDetectionMode} from "../../application/planeDetectionMode";
import {SidebarMode} from "../../application/sidebarMode";
import {LockMode} from "../../application/lockMode";
import {MeasurementUnit} from "../../application/measurementUnit";
import {LogLevel} from "../../application/logLevel";

import {GeometryTool} from "../../application/geometryTool";
import {InspectTool} from "../../application/inspectTool";
import {Tool} from "../../application/tool";
import EraserTool from "../../application/eraserTool";
import RectangleTool from "../../application/rectangleTool";
import PolygonTool from "../../application/polygonTool";
import WidgetTool from "../../application/widgetTool";
import MeasureTool from "../../application/measureTool";
import {PV} from "../../wireframe";
import {SceneViewer} from "../../sceneViewer";
import {environment} from "../../environments/environment";
import {EdgeElement} from "../../application/edgeElement";
import {SceneService} from "../../services/scenes/scene.service";
import {WireframeUtils} from "../../wireframeUtils";
import WireframeLayer from "../../application/wireframeLayer";
import {PointivoS3Singleton} from "../../s3Singleton";


//declare var Potree: any;
//declare var viewer: any;

//Potree.ProfileTool = POTREE_PROFILETOOL.ProfileTool;

export default {
    template: wireframeViewerTemplate,
    bindings: {
        wireframeViewerMode: '='
    },
    controller: ['$scope', '$uibModal', '$q', '$routeParams', 'apiService', '$window', '$rootScope', 'ngToast', 'frameService', 'userActivityService', 'sceneService',
        function ($scope, $uibModal, $q, $routeParams, apiService, $window, $rootScope, ngToast, frameService, userActivityService, sceneService:SceneService) {
            this.scope = $scope;
            let app = sceneService.wireframeApplication
            let ctrl = this;
            this.wireframeViewerMode = 'full';

            this.initData = {};
            this.projectIdParam = null;
            this.timeStarted = new Date();
            this.currentUser;
            this.selected = {};
            this.selected.resourceBranch = {};
            this.selected.resourceId = null;
            this.resourceTypeId = 98;
            this.app = app;
            this.ngToast = ngToast;
            this.users = [];
            this.owner = {};

            this.frameService = frameService;

            this.$onInit = function() {
                let that = this
                app.eventEmitter.on("saveRequested").subscribe((e) => that.quickSave())
                app.eventEmitter.on("saveAsRequested").subscribe((e) => that.openSaveAsDialog())
            }

            this.splitPaneProperties = {};
            this.$postLink = function () {
                ctrl.init();
            };

            this.selectionChanged = function () {
                $rootScope.$broadcast('selectionChanged');
            };

            this.isReadOnly = function () {
                return app.readOnlyMode;
            };

            this.getBranchMap = function (search) {
                let resources = this.getResources();
                if (!resources) return [];

                let b = {};
                //console.log("numResources " + this.initData.resources.length);
                for (let res of resources) {
                    //console.log("res branch " + res.branch);
                    if (!b[res.branch]) b[res.branch] = {};
                }
                if (search && !b[search]) {
                    b[search] = {};
                }
                return b;
            };

            this.getBranches = function (search) {
                let branches = Object.keys(this.getBranchMap(search));
                branches.sort();
                //console.log("branches ", branches);
                return branches;
            };

            this.getResourcesForSelectedBranch = function () {
                return this.getResources().filter(function (res) {
                    return res.branch == ctrl.selected.resourceBranch;
                });
            };

            this.getResources = function () {
                let resources = [];
                if (app.projectData && app.projectData.resources) {
                    resources = Object.values(app.projectData.resources).filter(function (res: any) {
                        return res.resourceType.id == 98;
                    });
                }
                resources.sort().reverse();
                return resources;
            };

            this.getSaveableBranches = function (search) {
                let b = this.getBranchMap(search);
                delete(b[""]);
                delete(b["autogen"]);
                b["qa"] = {};
                b["groundtruth"] = {};
                //console.log("saveable branches", b);
                return Object.keys(b);
            };

            this.formatTimeDuration = function (time) {

            };

            this.formatResource = function (res) {
                console.log("format resource", res);
                return res.url;
            };

            this.resourceSelect2Options = {
                templateResult: this.formatResource
            };


            this.init = function () {
                let that = this
                window.addEventListener('beforeunload', (event) => {
                    if (!(location.hostname === "localhost" || location.hostname === "127.0.0.1") && that.app.hasChanges)
                        event.returnValue = `Are you sure you want to leave?`;
                });
                app.viewerMode = ctrl.wireframeViewerMode

                app.apiService = apiService
                app.sceneService.apiService = apiService

                apiService.authToken = localStorage.getItem("authorization");
                app.readOnlyMode = PVUtils.getQueryVariable("ro") != null;

                this.projectIdParam = PVUtils.getQueryVariable("projectId");
                if (!this.projectIdParam) {
                    //wfOps.init("../data/init.txt");
                    //viewer.initContent.pointCloudPath = "../";
                }
                else {
                    app.projectId = this.projectIdParam
                    apiService.apiUrl = this.getApiUrl();
                }

                //console.log("myApp", $rootScope);
                this.acceptEULA(function () {
                    ctrl.authenticate();
                });
            };

            /*
            this.broadcast = function (event, ...args: any[]) {
                $rootScope.$broadcast(event, ...args);
            };

            this.on = function (event: string, listener: (event, data) => void) {
                $rootScope.$on(event, listener);
            };

            this.off = function (eventName, listener) {
                if ($rootScope.$$listeners) {
                    let eventArr = this.$$listeners[eventName];
                    if (eventArr) {
                        for (let i = 0; i < eventArr.length; i++) {
                            if (eventArr[i] === listener) {
                                eventArr.splice(i, 1);
                            }
                        }
                    }
                }
            };

             */

            this.acceptEULA = function (onComplete) {
                let eulaAccepted = localStorage.getItem("eulaAccepted");
                if (!eulaAccepted) {
                    this.eulaModal = $uibModal.open({
                        component: 'eula',
                        resolve: {},
                        backdrop: 'static', // disable dismiss by clicking outside of modal
                        keyboard: false, // disable escape key dismiss
                    });
                    this.eulaModal.result.then(function () {
                        localStorage.setItem("eulaAccepted", Date.now().toString());
                        onComplete();
                    }, function () {
                        window.location.href = "http://www.pointivo.com";
                    });
                } else {
                    onComplete();
                }
            };

            this.authenticate = function () {
                apiService.getUser(0).then(function (o) {
                    if (o.data.success) {
                        ctrl.currentUser = o.data.data;
                        ctrl.loadViewer();
                    } else {
                        console.log("authenticate error", o);
                        ctrl.openLoginModal();
                    }
                }).catch(function (e) {
                    console.log("authenticate error", e);
                    ctrl.openLoginModal();
                })
            };

            this.eulaOk = function () {
            };

            this.getApiUrl = function () {
                let env = PVUtils.getQueryVariable("env");
                let url;
                // let parser = document.createElement('a');
                // parser.href = document.location.toString();
                let apiPrefix = "/v2/"
                let apiPort = parseInt(window.location.port)
                if (window.location.protocol == "https:") {
                    apiPort = 443
                }
                if (env == "local") {
                    url = window.location.protocol + "//localhost:" + apiPort;
                } else if (env == "qa") {
                    url = window.location.protocol + "//qaintranet.pointivo.com:" + apiPort;
                } else if (env == "prod") {
                    url = window.location.protocol + "//intranet.pointivo.com:" + apiPort;
                } else {
                    url = window.location.protocol + "//" + window.location.hostname + (apiPort ? ":" + apiPort : "");
                }
                url += apiPrefix
                return url;
            };

            this.getBaseUrl = function () {
                return window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "");
            };

            this.openLoginModal = function () {
                ctrl.loginModal = $uibModal.open({
                    component: 'login',
                    resolve: {},
                    backdrop: 'static', // disable dismiss by clicking outside of modal
                    keyboard: false, // disable escape key dismiss
                });
                ctrl.loginModal.result.then(function () {
                    ctrl.authenticate();
                }, function () {

                });
            };

            this.openSaveModal = function () {
                this.selected.saveFinal = true;
                this.saveModal = $uibModal.open({
                    component: 'save',
                    keyboard: true, // disable escape key dismiss
                    resolve: {
                        branches: function () {
                            return ctrl.getBranches();
                        },
                        branch: function () {
                            return ctrl.selected.resource.branch
                        },
                        name: function () {
                            return ctrl.selected.resource.name
                        },
                        description: function () {
                            return ctrl.selected.resource.description
                        }
                    }
                });
                this.saveModal.result.then(function (o) {
                    ctrl.selected.saveBranch = o.branch;
                    ctrl.selected.resource.name = o.name;
                    ctrl.selected.resource.description = o.description;
                    ctrl.quickSave();
                }, function () {

                });
            };

            this.quickSave = function () {
                this.selected.saveFinal = true;
                this.saveWireframe();
            };

            this.saveWireframe = function () {
                let branch = this.selected.saveBranch;
                if (!branch || branch.length < 1) branch = this.selected.resourceBranch;
                let props = {
                    name: this.selected.resource.name,
                    description: this.selected.resource.description,
                    branch: branch
                };
                //toolBarSection.importButton();
                app.saveWireframeToServer(props, this.onWireframeSaved);
            };

            this.exportWireframe = function () {
                app.downloadWireFrame();
            };


            this.importFile;
            this.importWireframe = function () {
                this.importFile = {};
                let element = document.getElementById('WireFrame-input') as HTMLInputElement;
                element.value = '';
                element.click();
            };

            this.exportWireframeXls = function () {
                let wireframeData = app.wireframeLayer.wireFrameToJson();
                apiService.getWireframeXls(this.projectIdParam, wireframeData).then((wireframeXlsResponse) => {
                    if (!wireframeXlsResponse) return;
                    if (wireframeXlsResponse.status !== 200) return;
                    if (!wireframeXlsResponse.data) return;
                    this.downloadBlob(wireframeXlsResponse.data, this.projectIdParam + "-wireframe.xlsx");
                });
            };

            this.downloadBlob = function (file, filename) {
                let a = document.createElement("a");
                if (window.navigator.msSaveOrOpenBlob) // IE10+
                    window.navigator.msSaveOrOpenBlob(file, filename);
                else { // Others
                    let url = URL.createObjectURL(file);
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function () {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                }
            };

            this.showTransformControls = function() {
                return (app.activeTool && app.activeTool.enableTransformTool)
            }

            this.doImportWireframe = function () {
                if (!this.importFile) return;
                app.loadWireframeFromFile(this.importFile);
            };

            this.undoClicked = function () {
                app.undoLastEntry();
            };

            this.showReportView = false;
            this.toggleReportView = function () {
                this.showReportView = !this.showReportView;
            };

            this.showPickMessage = function () {
                return (null != app.currentPickOperation);
            };

            this.getPickMessage = function () {
                return app.pickMessage
            };

            this.showUnits = false;
            this.unitTypes = app.unitTypes;
            this.selectedUnit = app.unitTypes[0];
            this.tableUnit = app.unitTypes[0];
            this.scaleFactor = 1.0;

            this.snapMode = function (val) {
                if (arguments.length > 0) {
                    app.snapMode = Number(val);
                } else {
                    return "" + app.snapMode;
                }
            };

            this.toggleTableUnits = function (newValue: string, oldValue: string) {
                console.log("Plane Table, Old Unit = " + oldValue + ", New Unit = " + newValue);
                if (this.planeData != null) {
                    let oldMeasurementUnit = MeasurementUnit[oldValue];
                    let newMeasurementUnit = MeasurementUnit[newValue];

                    let table = app.planeOperations.updateUnits(this.planeData, oldMeasurementUnit, newMeasurementUnit);
                    let element = document.getElementById("planesTableElement");
                    if (element.children.length > 0) {
                        element.removeChild(element.children[0]);
                    }
                    element.appendChild(table);
                }
            };

            this.onWireframeSaved = function (that, result, status, xhr) {
                let resource = status.responseJSON.data;
                //this.loadResources();
                ctrl.setWireframeResource(resource);
                app.updateVersionHistory();
                //this.$apply();
            };

            this.loadViewer = function () {
                let self = this;
                let metadataUrl = apiService.apiUrl + "projects/" + app.projectId + "/wireframe/metadata";
                this.logSubscription = app.eventEmitter.on("logMessage").subscribe((logEvent) => {
                    console.log("logMessage", logEvent)
                    self.ngToast.create(logEvent)
                })
                if (this.projectIdParam) {
                    setInterval(function () {
                        apiService.getCredentials(app.projectId).then(function (o) {
                            app.resourceManager.setCredentials(o.data);
                        });
                    }, 1000 * 60 * 10);

                    apiService.getConfig().then(function (o) {
                        //console.log("getConfig", o);
                        if (!PointivoFirebaseSingleton.isInitialized()) {
                            PointivoFirebaseSingleton.initialize(o.data.firebaseConfig);
                        }
                        if (!PointivoS3Singleton.isInitialized()){
                            PointivoS3Singleton.initialize(this.amazonConstants)
                        }
                    }).then(function () {
                        // apiService.getWidgetTemplates(app.projectId).then(function(o) {
                        //     app.widgetLibraries = o
                        // })
                        apiService.createFirebaseToken(app.projectId).then(function (o) {
                            //console.log("fbtoken", o);
                            return o.data.token;
                        }).then(function (fbtoken) {
                            PointivoFirebaseSingleton.getInstance().auth.signInWithCustomToken(fbtoken).then(function (o) {
                                apiService.getViewerMetadata(app.projectId).then(function (o) {
                                    ctrl.initData = o.data;
                                    document.title = ctrl.initData.brandingInfo.name + " Viewer v" + PVBuildInfo.buildNumber;
                                    //this.setWireframeResource(this.getBestAvailableResource(this.getQueryVariable("resourceId")));
                                    //this.selected.resourceBranch = this.selected.resource.branch;
                                    //console.log("selectedResourceBranch ", this.selected.resourceBranch);
                                    //console.log("selectedResource.id ", this.selected.resourceId);
                                    //console.log("baseURl ", this.initData);
                                    //viewer.initContent.pointCloudPath = ctrl.initData.baseUrl; //..trim().replace(new RegExp("\/app\/index\.html$"), "/");
                                    //wfOps.setViewsFromUrl(metadataUrl);
                                    apiService.getUsers().then(function(o) {
                                        self.users = o.data.data
                                            .filter(u => u.customerId)
                                            .sort(function(a,b) {
                                                let aEmail = a.email != null ? a.email.toLowerCase() : ""
                                                let bEmail = b.email != null ? b.email.toLowerCase() : ""
                                                return aEmail.localeCompare(bEmail);
                                            });
                                    });

                                    let fbInit = false;

                                    let db = PointivoFirebaseSingleton.getInstance().db
                                    let ref = db.ref();

                                    app.userActivityRef = ref.child("userActivity/project-" + app.projectId);
                                    app.userActivityRef.on('value', function(snapshot) {
                                        app.userActivity = snapshot.val()
                                    })

                                    app.projectRef = ref.child("project/" + app.projectId);
                                    app.projectMeasurementUnit = MeasurementUnit[ctrl.initData.project.measurementUnit + ""];
                                    if (app.projectMeasurementUnit == null)
                                        app.projectMeasurementUnit = MeasurementUnit.M;
                                    let t0 = performance.now();

                                    setInterval(function() {
                                        ctrl.updateActiveUsers()
                                    }, 5000)

                                    window.onerror = function (msg, url, line, col, error) {
                                        app.logException(msg);
                                    };

                                    app.projectRef.on('value', function (snapshot) {
                                        //console.log("projectRef in : " + (performance.now() - t0));
                                        app.projectData = snapshot.val();
                                        apiService.projectData = app.projectData
                                        app.broadcast('projectDataChanged');
                                        self.owner = self.users.find(u => u.id === app.projectData.summary.dutyUserId);


                                        ctrl.updateActiveUsers();
                                        if (!fbInit) {
                                            fbInit = true;
                                            app.resourceManager.projectData = app.projectData;
                                            app.resourceManager.setCredentials(ctrl.initData.credentials);
                                            app.resourceManager.s3Bucket = ctrl.initData.s3Bucket;
                                            app.resourceManager.projectKey = ctrl.initData.s3Key;
                                            //app.resourceManager.init();
                                            //app.resourceManager.downloadThumbnails(app.projectData.metadata.rendered);
                                            //app.resourceManager.downloadImages(app.projectData.metadata.rendered);
                                            let resource = ctrl.getBestAvailableResource(PVUtils.getQueryVariable("resourceId"), PVUtils.getQueryVariable("branch"));

                                            if (resource) {
                                                ctrl.initWithResource(resource)
                                            } else {
                                                apiService.getWireframe(app.projectId, null).then(function (o) {
                                                    // this should cause a new wireframe to be created
                                                    // TODO : load it here, but we have to wait for firebase to propagate
                                                })
                                            }



                                        }
                                    });
                                });

                            }).catch(function (error) {
                                console.log("Firebase signin failure", error);
                            });
                        });
                    });

                }

                //document.getElementById('WireFrame-input').addEventListener('change', viewer.readSingleFile, false);
            };

            this.initWithResource = function(resource) {
                ctrl.setWireframeResource(resource);

                userActivityService.start()
                userActivityService.logActivity()
                //this.$evalAsync();
                apiService.getWireframe(ctrl.selected.resource.projectId, ctrl.selected.resource.id).then(function (o) {
                    //console.log("getWireframe", o.data);
                    app.init();
                    app.initCameras(app.projectData.metadata.reconstructed.views);
                    app.setWireframeFromJson(o.data.data);
                    app.wireframe.setWireframeProjectId(app.projectId);
                    app.sceneManager.loadPointClouds()
                    app.sceneManager.loadCameras()
                    app.notifyWireframeLoaded();
                    app.sceneManager.initOrthoLayers()
                    app.setDefaultDisplayRules()

                    if (app.viewerMode == "measure") {
                        app.sceneManager.getActiveWireframeLayer().elementLayers[WireframeElementType.edge].labelLayer.isVisible = true
                    }
                    app.rootScene.updateMatrixWorld(true)
                    app.sceneManager.getAllLayers().forEach((l) => { if (l instanceof WireframeLayer) l.redrawWireframe() })

                });
            }


            this.resourceFilter = function (o) {
                return o.resourceType.id == this.resourceTypeId;
            };

            this.resourceBranchFilter = function (o) {
                return (o.resourceType.id == this.resourceTypeId) && (o.branch == this.selected.resourceBranch);
            };

            this.getProjectResources = function() {
                return apiService.getProjectResources()
            }

            this.setWireframeResource = function (resource) {
                app.currentResource = resource;
                //console.log("current wf resource ", resource);
                this.selected.resourceId = resource.id;
                this.selected.resource = resource;
                this.selected.resourceBranch = resource.branch;
                this.selected.saveBranch = resource.branch == "autogen" ? "qa" : resource.branch;
                $scope.$evalAsync();
            };

            this.currentUserEmail;

            this.currentUsers = [];

            this.updateActiveUsers = function () {
                try {
                    this.currentUsers = [];
                    if (!app.userActivity || !app.userActivity.byUser) return;
                    if (this.selected && this.selected.resource) {
                        let now = new Date().getTime();
                        for (let userKey in app.userActivity.byUser) {
                            let userActivity = app.userActivity.byUser[userKey];
                            try {
                                if (userActivity.id == this.currentUser.id) continue;
                                if (!userActivity.id) continue;
                                if (now > userActivity.lastActivity + (1000 * 60)) continue;

                                let initials = "";

                                if (userActivity.firstName != null && userActivity.firstName.length > 0) {
                                    initials = userActivity.firstName[0];
                                }
                                if (userActivity.lastName != null && userActivity.lastName.length > 0) {
                                    initials += userActivity.lastName[0];
                                }

                                if (initials.length < 1 && userActivity.email) initials = userActivity.email[0];

                                let hue = (userActivity.id * userActivity.id * 1024) % 255;
                                if (initials.length < 1) {
                                    initials = "?";
                                }

                                let color = ($ as any).Color({hue: hue, saturation: 1, lightness: .75, alpha: 1});
                                let user = {
                                    initials: initials.toUpperCase(),
                                    firstName: userActivity.firstName,
                                    lastName: userActivity.lastName,
                                    email: userActivity.email,
                                    isIdle: now > userActivity.lastActivity + (1000 * 30),
                                    color: color.toHexString()
                                };
                                this.currentUsers.push(user);
                            } catch (e) {
                                console.error("Error parsing userActivity", userActivity);
                            }
                        }
                        //console.log("currentUsers", this.currentUsers);
                        $scope.$evalAsync();
                    }
                } catch (e) {
                    console.error("Error populating current user list", e);
                }
            };

            this.resourceSelectionChanged = function (callback) {
                //let resource = this.getBestAvailableResource(this.selected.resource.id, this.selected.resource.branch);
                console.log("resourceselectionchanged " + this.selected.resource.id);
                this.setWireframeResource(this.selected.resource);
                let that = this;
                apiService.getWireframe(this.selected.resource.projectId, this.selected.resource.id).then(function (o) {
                    //console.log("getWireframe", o.data);
                    app.setWireframeFromJson(o.data.data);
                    app.notifyWireframeLoaded();
                });

                //$.getJSON(resource.downloadUrl, function(data) {
                //    wfOps.setWireframeFromJson(data);
                //});
            };

            this.resourceBranchChanged = function (o) {
                //console.log("resourceBranchChanged", o);
            };

            this.getUsers = function () {
                return this.users;
            };

            this.resourceOwnerChanged = function () {
                let self = this;
                let project = app.projectData.summary;
                project.dutyUserId = self.owner.id;
                apiService.putProject(project).then(
                    () => {
                        app.log('Project Owner Updated: ' + self.owner.email);
                    },
                    (err) => {
                        console.log(err);
                        app.log('Unable to update Project Owner.');
                    });
            };


            this.getBestAvailableResource = function (resourceId, branch) {
                //console.log("getBestAvailableResource " + resourceId + "/" + branch, app.projectData.resources);
                let resources = {};
                for (let rId in app.projectData.resources) {
                    let r = app.projectData.resources[rId];
                    if (r.resourceType.id != this.resourceTypeId) continue;
                    resources[r.id] = r;
                }
                ;
                if (resourceId && resources[resourceId]) {
                    return resources[resourceId];
                }
                // return latest branch resource if branch is specified
                let resourceIdStrings = Object.keys(resources)
                let resourceIds:Number[] = []
                for (let i = 0; i < resourceIdStrings.length; i++) {
                    resourceIds[i] = Number(resourceIdStrings[i])
                }
                resourceIds.sort(function(a:number, b:number){return b - a})
                if (branch) {
                    for (let i = 0; i < resourceIds.length; i++) {
                        let rId = resourceIds[i];
                        if (resources["" + rId].branch == branch) {
                            return resources["" + rId];
                        }
                    }
                }
                // otherwise default to latest resource
                if (resourceIds.length > 0) {
                    return resources["" + resourceIds[0]];
                }
                app.logException("Unable to locate a wireframe to load");
                console.error("Unable to find wireframe resource in project");
            };

            this.newScaleLength = 1;
            this.newScaleValue = 1;
            this.setScaleValue = function () {
                let currentLengthString = this.getScaleCurrentLength();
                let suffixLength = 0;
                if (app.currentDisplayUnit != MeasurementUnit[MeasurementUnit.UNITLESS + ""])
                    suffixLength = app.currentDisplayUnit.toString().length;
                let currentLength = Number(currentLengthString.substring(0, currentLengthString.length - suffixLength));
                //console.log("Current length = " + currentLength);
                let factor = WireframeUtils.getUnitConversionFactor(Number(app.currentDisplayUnit), Number(MeasurementUnit[this.selectedUnit]));
                //console.log("Factor = " + factor);
                this.newScaleLength = factor * currentLength;
                //console.log("New Scale length = " + this.newScaleLength);
            };

            this.scaleEdgeChanged = function () {
                let length = this.getScaleCurrentLength();
                let suffixLength = 0;
                if (app.currentDisplayUnit != MeasurementUnit[MeasurementUnit.UNITLESS + ""])
                    suffixLength = app.currentDisplayUnit.toString().length;
                this.newScaleLength = Number(length.substring(0, length.length - suffixLength));
                this.newScaleValue = app.displayMeasurementScale;
                //console.log("New Scale Value = " + this.newScaleValue);
            };

            this.getScaleCurrentLength = function () {
                if (app.selectedElements.length < 1) return 0;
                if (app.selectedElements[0].pvObject.pvType != WireframeElementType.edge) return 0;
                let edgeLength = (app.selectedElements[0] as EdgeElement).getLength()
                edgeLength = edgeLength * app.displayMeasurementScale;
                if (app.currentDisplayUnit == MeasurementUnit[MeasurementUnit.UNITLESS + ""])
                    return Number(Number(edgeLength).toFixed(3)).toString();
                else
                    return Number(Number(edgeLength).toFixed(3)).toString() + app.currentDisplayUnit.toString().toLowerCase();
            };

            this.toggleMode = function (mode) {
                console.log("toggleMode " + mode);
                app.viewMode = mode;
                //if (app.viewMode == "surfaceMode" && !app.showPolygons) {
                //    layerSection.togglePolygons();
                //}
            };

            this.settingsVisible = false;
            this.toggleSettings = function () {
                this.settingsVisible = !this.settingsVisible;
            };

            this.getViewMode = function () {
                return app.viewMode;
            };

            this.setActiveTool = function (toolName) {
                let toolConstructor = app.getTool(toolName);
                if (toolConstructor) app.setActiveTool(toolConstructor);
            };

            this.getActiveTool = function () {
                if (!app.activeTool) return null
                return app.activeTool.toolName
            };

            this.getCameraProjectionEnabled = function() {
                return app && app.cameraProjectionService && app.cameraProjectionService.active
            }

            this.isToolAvailable= function(toolName) {
                let available = false;
                switch(ctrl.wireframeViewerMode){
                    case 'measure':
                        // only enable the following tools
                        switch(toolName){
                            case "MeasureTool":
                                available = true;
                                break;
                        }
                        break;
                    case 'full':
                    default:
                        // enable all tools
                        available = true;
                        break;
                }

                return available;
            };


            this.toggleSidebarMode = function (mode, $event) {
                mode = SidebarMode[mode];
                if ($event && $event.shiftKey) mode = SidebarMode.TOOLS;
                setTimeout(function () {
                    try {
                        let tmp = app.sidebarMode;
                        app.sidebarMode = [];
                        $scope.$apply();
                        app.sidebarMode = tmp;
                        app.setSidebarMode(mode);
                        $scope.$evalAsync();
                    } catch (e) {
                        console.log(e);
                    }
                }, 0);
            };

            this.isSidebarActive = function (mode: string) {
                return app.sidebarMode.includes(SidebarMode[mode]);
            };

            this.onKeyDown = function (event) {
                console.log("onKeyDown", event);
            };

            this.openSaveAsDialog = function () {
                this.openSaveModal();
            };

            this.handleError = function () {
            }
        }]
}
