
import tpl from './eula.html'

export default {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
        controller: [function () {
            this.parser = document.createElement('a');
            this.parser.href = window.location;

            let $ctrl = this;
            let ctrl = this;
            this.agreeConfirm = false;
            this.ok = function() {
                console.log("ok click");
                $ctrl.close();
            };

            this.cancel = function() {
                console.log("cancel click");
                let url = "//pointivo.com";
                if (this.parser.hostname.indexOf("precisionmapper") != -1) {
                    return "//www.precisionmapper.com";
                }
                window.location.assign(url);
            };

            this.getEulaURL = function() {
                let url = "http://pointivo.com/tool/eula/";
                if (this.parser.hostname.indexOf("precisionmapper") != -1) {
                    return "//www.precisionmapper.com/terms_of_service";
                }
                return url;
            };

            this.getAgreeEnabled = function() {
                return this.agreeConfirm;
            };
        }],
        template: tpl
    }

