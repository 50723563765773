import {EnumProperty} from "./enumProperty";
import {WireframeElementType} from "../../application/wireframeElementType";
import {VerifiedState} from "./verifiedState";

export class VerifiedProperty extends EnumProperty {
    constructor() {
        super();
        this.isEditable = true;
        this.allowMultiplePerElement = false;
        this.allowMultiplePerWireframe = false;
        this.name = "Verified";
        this.type = ".VerifiedProperty";
        this.allowableElementTypes = [WireframeElementType.vertex];
        this.setValuesFromEnum(VerifiedState);
    }
}