import AWS = require('aws-sdk');
import {Endpoint} from "aws-sdk";

export class PointivoS3Singleton {
    public amazonConstants

    static initialize(amazonConstants) {
        AWS.config.update({
            region: "us-east-1",
            s3ForcePathStyle: amazonConstants.pathStyleAccess
        });
        let singleton = new PointivoS3Singleton();
        singleton.amazonConstants = amazonConstants
        PointivoS3Singleton.setWindowInstance(singleton);
    }

    static isInitialized() {
        return !!PointivoS3Singleton.getWindowInstance();
    }

    static getInstance(amazonConstants?) {
        if (!PointivoS3Singleton.isInitialized) {
            if (typeof amazonConstants !== 'undefined') {
                console.error("S3 is not initialized and no config was supplied.")
            }
            PointivoS3Singleton.initialize(amazonConstants);
        }

        return PointivoS3Singleton.getWindowInstance();
    }

    static newAWSS3Instance(): AWS.S3 {
        let instance = PointivoS3Singleton.getWindowInstance()
        if (instance.amazonConstants.s3Endpoint){
            let ep = new Endpoint(instance.amazonConstants.s3Endpoint);
            return new AWS.S3({endpoint: ep.href, signatureVersion: 'v4'});
        } else {
            return new AWS.S3();
        }
    }

    private static getWindowInstance(): PointivoS3Singleton {
        return ((window as any).pointivoS3Singleton) as PointivoS3Singleton;
    }

    private static setWindowInstance(singleton: PointivoS3Singleton) {
        (window as any).pointivoS3Singleton = singleton;
    }
}