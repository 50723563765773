import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'wireframe',
  template: require('./wireframe.component.html'),
  styles: [require('./wireframe.component.scss')]
})
export class WireframeComponent implements OnInit {
  wireframeViewerMode: string;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.wireframeViewerMode = this.route.snapshot.data['wireframeViewerMode'];
  }
}
