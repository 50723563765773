import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {SceneService} from "../../services/scenes/scene.service";
import {CameraPointView} from "../../models/cameras/cameraPointView";
import {ProjectImageCollectionService} from "../../services/projects/projectImageCollection.service";
import {ProjectImageInventoryModel} from "../../models/projectImageInventories/projectImageInventory.model";
import ProjectImageCollection from "../../collections/projectImages/projectImage.collection";
import {ProjectImageCollectionFilterCameraPointViewModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterCameraPointView.model";
import {ProjectImageListFilterType} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterType.enum";
import {ProjectImageCollectionSortByCameraPointViewsModel} from "../../models/projectImageCollectionSort/projectImageCollectionSortByCameraPointViews.model";
import {ProjectImageModel} from "../../models/projectImages/projectImage.model";
import {CameraView} from "../../models/cameras/cameraView";
import {VerifiedProperty} from "../../models/property/verifiedProperty";
import {Property} from "../../models/property/property";
import {VertexElementModel} from "../../models/vertexElements/vertexElement.model";
import {InteractiveOperationModel} from "../../models/interactive/interactiveOperation.model";
import {VerifiedState} from "../../models/property/verifiedState";
import {SeenProperty} from "../../models/property/seenProperty";
import {SeenState} from "../../models/property/seenState";
import {Highlightable} from '@angular/cdk/a11y';
import {InteractiveSelectEventModel} from "../../models/interactive/interactiveSelectEvent.model";

@Component({
    selector: 'ptv-vertex-preview-item',
    styles: [require('./vertexPreviewItem.component.scss')],
    template: require('./vertexPreviewItem.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush

})
export default class VertexPreviewItemComponent implements OnInit, OnDestroy, Highlightable {
    @Input() vertexElementModel: VertexElementModel;

    private _thumbnailType:string = 'tiles';
    @Input() set thumbnailType(type:string){
        if(this._thumbnailType != type){
            this._thumbnailType = type;
            this.onThumbnailTypeChanged(type);
        }
    }

    get thumbnailType(){
        return this._thumbnailType;
    }

    @Input() zoom: number = 0;

    isMouseOver = false;
    vertexElementModelUpdatedSubscription;
    vertexElementModelFocusedChangingSubscription;
    vertexElementModelFocusedChangedSubscription;
    vertexElementModelSelectedChangingSubscription;
    vertexElementModelSelectedChangedSubscription;
    vertexElementModelHighlightedChangingSubscription;
    vertexElementModelHighlightedChangedSubscription;

    // project image collection
    private defaultImageCollectionKey: string = 'default';
    private projectImageCollectionKeys: string[] = [];
    private projectImageInventoryModel: ProjectImageInventoryModel;
    protected projectImageCollection: ProjectImageCollection = new ProjectImageCollection();

    private _verifiedProperty: Property;
    private _seenProperty: Property;
    private _confidenceProperty: Property;

    get verified(): boolean {
        let verified = false;
        if (this._verifiedProperty) {
            let verifiedValue = this._verifiedProperty.getValue(this.vertexElementModel.vertexElement.pvObject);
            if (verifiedValue == null) {
                verified = null;
            } else if (verifiedValue == VerifiedState.UNVERIFIED) {
                verified = false;
            } else {
                verified = true;
            }
        }

        return verified;
    }

    set verified(verified: boolean) {
        if (this._verifiedProperty) {
            let operation = new InteractiveOperationModel<VertexElementModel>('propertyChange', this.vertexElementModel, {});
            this.vertexElementModel.startOperation(operation);

            if(verified){
                this._verifiedProperty.setValue(this.vertexElementModel.vertexElement, verified ? VerifiedState.VERIFIED : VerifiedState.UNVERIFIED);
            } else {
                this._verifiedProperty.removeValue(this.vertexElementModel.vertexElement)
            }

            this.vertexElementModel.endOperation(operation);
        }

        if(this.verified == null && this.unverified == null){
            this.seen = false;
        } else {
            this.seen = true;
        }
    }

    private _verifiedButtonLabel:string = null;
    get verifiedButtonLabel(): string {
        if (this.verified) {
            this._verifiedButtonLabel = 'verified';
        } else {
            this._verifiedButtonLabel = 'verify';
        }
        return this._verifiedButtonLabel;
    }

    get unverified(): boolean {
        let unverified = false;
        if (this._verifiedProperty) {
            let verifiedValue = this._verifiedProperty.getValue(this.vertexElementModel.vertexElement.pvObject);
            if (verifiedValue == null) {
                unverified = null;
            } else if(verifiedValue == VerifiedState.UNVERIFIED) {
                unverified = true;
            } else {
                unverified = false;
            }
        }

        return unverified;
    }

    set unverified(unverified: boolean) {
        if (this._verifiedProperty) {
            let operation = new InteractiveOperationModel<VertexElementModel>('propertyChange', this.vertexElementModel, {});
            this.vertexElementModel.startOperation(operation);

            if(unverified){
                this._verifiedProperty.setValue(this.vertexElementModel.vertexElement, unverified ? VerifiedState.UNVERIFIED : VerifiedState.VERIFIED);
            } else {
                this._verifiedProperty.removeValue(this.vertexElementModel.vertexElement)
            }

            this.vertexElementModel.endOperation(operation);
        }

        if(this.verified == null && this.unverified == null){
            this.seen = false;
        } else {
            this.seen = true;
        }
    }

    private _unverifiedButtonLabel:string = null;
    get unverifiedButtonLabel(): string {
        if (this.unverified) {
            this._unverifiedButtonLabel = 'unverified';
        } else {
            this._unverifiedButtonLabel = 'unverify';
        }
        return this._unverifiedButtonLabel;
    }

    get seen(): boolean {
        let seen = false;
        if (this._seenProperty) {
            let seenValue = this._seenProperty.getValue(this.vertexElementModel.vertexElement.pvObject);
            if (seenValue == null){
                seen = false;
            } else if(seenValue == SeenState.UNSEEN) {
                seen = false;
            } else {
                seen = true;
            }
        }

        return seen;
    }

    set seen(seen: boolean) {
        if (this._seenProperty) {
            let operation = new InteractiveOperationModel<VertexElementModel>('propertyChange', this.vertexElementModel, {});
            this.vertexElementModel.startOperation(operation);
            this._seenProperty.setValue(this.vertexElementModel.vertexElement, seen ? SeenState.SEEN : SeenState.UNSEEN);
            this.vertexElementModel.endOperation(operation);
        }
    }

    private _seenButtonLabel:string = null;
    get seenButtonLabel(): string {
        if (this.seen) {
            this._seenButtonLabel = 'visibility';
        } else {
            this._seenButtonLabel = 'visibility_off';
        }
        return this._seenButtonLabel;
    }

    private _confidenceValue:string = null;
    get confidence(): string {
        let confidence = null;
        if (this._confidenceProperty) {
            let confidenceValue = Number(this._confidenceProperty.getValue(this.vertexElementModel.vertexElement.pvObject));
            confidence = confidenceValue.toFixed(2);
            if (isNaN(confidenceValue)) confidence = "-";
        }
        this._confidenceValue = confidence;
        return this._confidenceValue;
    }

    constructor(
        private sceneService: SceneService,
        private projectImageCollectionService: ProjectImageCollectionService,
        private zone: NgZone,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        // get available image collections
        this.projectImageCollectionKeys = this.projectImageCollectionService.getProjectImageCollectionKeys();

        // load the image collection
        this.loadProjectImageCollection(this.defaultImageCollectionKey);

        // properties
        this._verifiedProperty = this.sceneService.wireframeApplication.wireframe.findProperty(VerifiedProperty, false);
        this._seenProperty = this.sceneService.wireframeApplication.wireframe.findProperty(SeenProperty, false);
        this._confidenceProperty = this.sceneService.wireframeApplication.wireframe.findPropertyByName("confidence");
    }

    ngOnInit(): void {
        if (!this.vertexElementModel) return;

        // repair unknown states
        if(this.verified) if(!this.seen) this.seen = true;
        if(this.unverified) if(!this.seen) this.seen = true;

        this.filterToVertexElement(this.vertexElementModel);

        this.vertexElementModelUpdatedSubscription = this.vertexElementModel.updated.subscribe(this.onVertexElementModelUpdated.bind(this));
        this.vertexElementModelFocusedChangingSubscription = this.vertexElementModel.focusedChanging.subscribe(this.onVertexElementModelFocusChanging.bind(this));
        this.vertexElementModelFocusedChangedSubscription = this.vertexElementModel.focusedChanged.subscribe(this.onVertexElementModelFocusChanged.bind(this));
        this.vertexElementModelSelectedChangingSubscription = this.vertexElementModel.selectedChanging.subscribe(this.onVertexElementModelSelectChanging.bind(this));
        this.vertexElementModelSelectedChangedSubscription = this.vertexElementModel.selectedChanged.subscribe(this.onVertexElementModelSelectChanged.bind(this));
        this.vertexElementModelHighlightedChangingSubscription = this.vertexElementModel.highlightedChanging.subscribe(this.onVertexElementModelHighlightChanging.bind(this));
        this.vertexElementModelHighlightedChangedSubscription = this.vertexElementModel.highlightedChanged.subscribe(this.onVertexElementModelHighlightChanged.bind(this));
    }

    ngOnDestroy(): void {
        if (this.vertexElementModelUpdatedSubscription) this.vertexElementModelUpdatedSubscription.unsubscribe();
        if (this.vertexElementModelFocusedChangingSubscription) this.vertexElementModelFocusedChangingSubscription.unsubscribe();
        if (this.vertexElementModelFocusedChangedSubscription) this.vertexElementModelFocusedChangedSubscription.unsubscribe();
        if (this.vertexElementModelSelectedChangingSubscription) this.vertexElementModelSelectedChangingSubscription.unsubscribe();
        if (this.vertexElementModelSelectedChangedSubscription) this.vertexElementModelSelectedChangedSubscription.unsubscribe();
        if (this.vertexElementModelHighlightedChangingSubscription) this.vertexElementModelHighlightedChangingSubscription.unsubscribe();
        if (this.vertexElementModelHighlightedChangedSubscription) this.vertexElementModelHighlightedChangedSubscription.unsubscribe();
    }

    onVertexElementModelFocusChanging(vertexElementModel: VertexElementModel) {
        this.detectChanges();
    }

    onVertexElementModelFocusChanged(vertexElementModel: VertexElementModel) {
        this.detectChanges();
    }

    onVertexElementModelSelectChanging(interactiveSelectEventModel: InteractiveSelectEventModel<VertexElementModel>) {
        this.detectChanges();
    }

    onVertexElementModelSelectChanged(interactiveSelectEventModel: InteractiveSelectEventModel<VertexElementModel>) {
        this.detectChanges();
    }

    onVertexElementModelHighlightChanging(vertexElementModel: VertexElementModel) {
        this.detectChanges();
    }

    onVertexElementModelHighlightChanged(vertexElementModel: VertexElementModel) {
        this.detectChanges();
    }

    onVerifiedButtonClick() {
        // toggle verified
        this.verified = !this.verified;
        this.detectChanges();
    }

    onUnverifiedButtonClick() {
        // toggle verified
        this.unverified = !this.unverified;
        this.detectChanges();
    }

    onSeenButtonClick() {
        // toggle seen
        this.seen = !this.seen;

        if(!this.seen){
            this.verified = false;
            this.unverified = false;
        }
        this.detectChanges();
    }

    onThumbnailTypeChanged(thumbnailType:string){
        this.filterToVertexElement(this.vertexElementModel);
    }

    onVertexElementModelUpdated() {
        if (!this.vertexElementModel) return;
        // todo: this will enable new views to follow the vertex, but it causes problems when dragging in the thumbnail view
        // this.filterToVertexElement(this.vertexElementModel);
        this.detectChanges();
    }

    onVertexPreviewCardClick(mouseEvent) {
        if (!this.vertexElementModel) return;
        // if (!mouseEvent.ctrlKey || !mouseEvent.shiftKey) return;
        this.vertexElementModel.selected = !this.vertexElementModel.selected;
        this.detectChanges();
    }

    onVertexPreviewCardDoubleClick() {
        if (!this.vertexElementModel) return;
        this.vertexElementModel.focused = true;
        this.detectChanges();
    }

    onVertexPreviewCardMouseEnter() {
        this.isMouseOver = true;
        this.vertexElementModel.highlighted = true;
        this.detectChanges();
    }

    onVertexPreviewCardMouseLeave() {
        this.isMouseOver = false;
        this.vertexElementModel.highlighted = false;
        this.detectChanges();
    }

    onProjectImageModelVisibilityChange(visibleProjectImageModels: ProjectImageModel[]): void {
        let self = this;
        if (!visibleProjectImageModels) return;
        visibleProjectImageModels.forEach((projectImageModel) => {
            if (projectImageModel.tiledImageHref) return; // prefer tiled images
            self.getProjectImageModelPrint(projectImageModel)
        });
    }

    loadProjectImageCollection(imageCollectionKey: string): void {
        this.projectImageInventoryModel = this.projectImageCollectionService.getProjectImageInventoryModel(imageCollectionKey);
        let imageKeys = this.projectImageCollectionService.getProjectImageKeys(imageCollectionKey);
        if (!imageKeys.length) return;

        // add project image models
        this.projectImageCollection.clear();
        imageKeys.forEach((imageKey) => {
            // add the project image
            let projectImageModel = this.projectImageCollectionService.getProjectImageModel(imageCollectionKey, imageKey,);
            this.projectImageCollection.add(projectImageModel);
        });
    }

    private _camerasEnabled:boolean = false;
    get camerasEnabled():boolean{
        let enabled = this.thumbnailType == "cameras";
        if(this._camerasEnabled != enabled){
            this._camerasEnabled = enabled;
        }
        return this._camerasEnabled;
    }

    filterToVertexElement(vertexElementModel: VertexElementModel) {
        let vertexElement = vertexElementModel.vertexElement;
        let vertexPointVector = vertexElement.getVert3(true);
        let cameraPointViews: CameraPointView[] = this.sceneService.getCamerasThatCanSeePoint(vertexPointVector, this.camerasEnabled);

        // update the filter
        let existingProjectImageFilterModel: ProjectImageCollectionFilterCameraPointViewModel = null;
        this.projectImageCollection.projectImageCollectionFilterExpressionModel.projectImageListFilterCollection.forEach((projectImageListFilterModel) => {
            if (projectImageListFilterModel.filterType !== ProjectImageListFilterType.PointVisibility) return;
            existingProjectImageFilterModel = projectImageListFilterModel as ProjectImageCollectionFilterCameraPointViewModel;
        });
        if (existingProjectImageFilterModel) {
            existingProjectImageFilterModel.cameraPointViews = cameraPointViews;
        } else {
            let projectImageListFilterPointVisibilityModel = new ProjectImageCollectionFilterCameraPointViewModel();
            projectImageListFilterPointVisibilityModel.cameraPointViews = cameraPointViews;
            this.projectImageCollection.projectImageCollectionFilterExpressionModel.addFilter(projectImageListFilterPointVisibilityModel);
        }

        // update the sort
        this.projectImageCollection.projectImageCollectionSortModel = new ProjectImageCollectionSortByCameraPointViewsModel(cameraPointViews);

        // update cameras
        this.setProjectImageModelCameraPointViews(cameraPointViews);

        this.projectImageCollection.reapplyFilter();

    }

    setProjectImageModelCameraPointViews(cameraPointViews: CameraPointView[]) {
        if (!cameraPointViews) return;
        cameraPointViews.forEach((cameraPointView) => {
            let cameraView = cameraPointView.cameraView;
            if (!cameraView) return;
            let projectImageModel = this.findProjectImageModelBySceneCameraView(cameraView);
            if (!projectImageModel) return;
            projectImageModel.cameraPointView = cameraPointView;
        });
    }

    findProjectImageModelBySceneCameraView(cameraView: CameraView): ProjectImageModel {
        if (!cameraView) return null;
        let frameId = cameraView.frameId;
        if (typeof frameId === 'undefined' || frameId === null) return null;
        let matchingProjectImageModel = null;
        this.projectImageCollection.forEach((projectImageModel) => {
            if (projectImageModel.frameId === frameId) {
                matchingProjectImageModel = projectImageModel;
            }
        });

        return matchingProjectImageModel;
    }

    getProjectImageModelPrint(projectImageModel: ProjectImageModel, onFullfilled: (ProjectImageModel) => void = null) {
        let self = this;
        let imageKey = projectImageModel.url;

        // retrieve the image data from storage
        let onProgress = (percent) => {
            // the final handler will set 100% after all properties have been assigned
            if (percent === 100) return;
            projectImageModel.printPercentLoaded = percent;
        };

        this.zone.runOutsideAngular(() => {
            self.projectImageCollectionService.getProjectImageModelPrint(self.projectImageInventoryModel.name, imageKey, onProgress).then(currentProjectImageModel => {
                projectImageModel.printUrl = currentProjectImageModel.printUrl;
                projectImageModel.printPercentLoaded = 100;
                if (onFullfilled) onFullfilled(projectImageModel);
            })
        });
    }

    setActiveStyles() {
        this.vertexElementModel.focused = true;
    }

    setInactiveStyles() {
        this.vertexElementModel.focused = false;
    }

    detectChanges(){
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }
}