// variables to be hoisted into the global scope
var globalVariables = global as any;
globalVariables.urlParser = document.createElement("a");

// customized local libraries

// min pack
import {MINPACK} from "./lib/minpack/minpack";

globalVariables.MP = MINPACK.MP;
globalVariables.MWPR = MINPACK.MWPR;
globalVariables.Projection = MINPACK.Projection;

// tween
import TWEEN from '@tweenjs/tween.js';

globalVariables.TWEEN = TWEEN;
require('three-orbitcontrols');

// es6 libraries
import "core-js/stable";
import "core-js/features/reflect";
import "regenerator-runtime/runtime";
import "symbol-observable";
import 'hammerjs/hammer';
import angular from 'angular';
import 'angular-route/angular-route.min.js';
import 'angular-sanitize/angular-sanitize.min.js';
import 'angular-ui-bootstrap';
import 'angular-ui-bootstrap/dist/ui-bootstrap-tpls.js';
import 'angular-ui-bootstrap/dist/ui-bootstrap-csp.css';
import 'angular-bootstrap-colorpicker';
import 'angular-bootstrap-colorpicker/js/bootstrap-colorpicker-module.min';
import 'angular-bootstrap-colorpicker/css/colorpicker.min.css';
import 'angular-ui-grid';
import 'angular-ui-layout/src/ui-layout.js';
import 'angular-animate/angular-animate.min.js'
import 'ui-select/dist/select.min.js'
import 'ui-select/dist/select.min.css';
import 'ng-toast/dist/ngToast.min.js'
import 'ng-toast/dist/ngToast.min.css';
import 'cesium/Build/Cesium/Widgets/widgets.css'

import {JitCompilerFactory, platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {BrowserModule} from "@angular/platform-browser";
import {
    Compiler,
    COMPILER_OPTIONS, CompilerFactory,
    enableProdMode, ModuleWithProviders,
    NgModule,
    StaticProvider
} from "@angular/core";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {downgradeComponent, downgradeModule, UpgradeModule} from "@angular/upgrade/static";
import {environment} from "./environments/environment";

if (environment.production) {
    enableProdMode();
}
import {Router, RouteReuseStrategy, RouterModule} from "@angular/router";
import {HttpClientModule} from '@angular/common/http';

// angular forms
import {FormsModule} from '@angular/forms';

// angular material
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTreeModule
} from '@angular/material';

import {DragDropModule} from '@angular/cdk/drag-drop';
import {CdkTableModule} from '@angular/cdk/table';
import {AngularResizedEventModule} from 'angular-resize-event';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import {NgxJsonViewerModule} from 'ngx-json-viewer';

// application style
import 'sass/styles.scss';

import {AppRootComponent} from "./components/appRoot/appRoot.component";
import {ModuleService} from './services/modules/module.service';
import {RouterService} from './services/routers/router.service';

// application global imports
import {WireframeApplication} from './application/wireframeApplication'

// application module imports
import wireframeViewerComponent from './components/wireframeViewer/wireframeViewer'
import loginComponent from './components/login/login';
import eulaComponent from './components/eula/eula';
import settingsComponent from './components/settings/settings';
import layersComponent from './components/layers/layers';
import resourceBrowser from './components/resources/resourceBrowser';
import selectionInfoComponent from './components/selectionInfo/selectionInfo';
import propertiesComponent from './components/properties/properties';
import propertyListComponent from './components/properties/propertyList';
import propertyComponent from './components/properties/property';
import propertyInputComponent from './components/properties/propertyInput';
import wireframeActionsComponent from './components/wireframeActions/wireframeActions';
import toolsComponent from './components/tools/tools';
import displayRulesComponent from './components/displayRules/displayRules';
import displayRuleComponent from './components/displayRules/displayRule';
import adjustComponent from './components/adjust/adjust';
import saveComponent from './components/save/save';
import AnnotationComponent from './components/annotations/annotation.component';
import AnnotationPopupComponent from './components/annotations/popups/annotationPopup.component';
import ProjectImageItemComponent from "./components/projectImageItem/projectImageItem.component";
import ProjectImageListComponent from "./components/projectImageList/projectImageList.component";
import ProjectImageManagerComponent from "./components/projectImageManager/projectImageManager.component";
import ModalComponent from "./components/modals/modal.component";
import {ModalService} from "./services/modals/modal.service";
import {FrameService} from "./services/frames/frame.service";
import {SceneService} from "./services/scenes/scene.service";
import {UserActivityService} from "./services/userActivity/userActivity.service";
import {PropertyService} from "./services/properties/property.service";
import {ProjectImageCollectionService} from "./services/projects/projectImageCollection.service";
import ProjectImageManagerToolbarComponent
    from "./components/projectImageManager/toolbar/projectImageManagerToolbar.component";
import ProjectImageMapComponent from "./components/projectImageMap/projectImageMap.component";
import ProjectImageListFilterExpressionComponent
    from "./components/projectImageListFilterExpression/projectImageListFilterExpression.component"
import ProjectImageListFilterComponent from './components/projectImageListFilter/projectImageListFilter.component';
import ProjectImageListFilterLabelComponent
    from './components/projectImageListFilterLabel/projectImageListFilterLabel.component';
import ProjectImageListFilterEditorComponent
    from './components/projectImageListFilterEditor/projectImageListFilterEditor.component';

import JsonComponent from "./components/json/json.component";
import ProjectImageListFilterEditorAnnotationComponent
    from "./components/projectImageListFilterEditor/annotations/projectImageListFilterEditorAnnotation.component";
import ProjectImageListFilterEditorMetadataComponent
    from "./components/projectImageListFilterEditor/metadata/projectImageListFilterEditorMetadata.component";
import ProjectImageListFilterEditorTagComponent
    from "./components/projectImageListFilterEditor/tags/projectImageListFilterEditorTag.component";
import ProjectImageListFilterEditorPointVisibilityComponent
    from "./components/projectImageListFilterEditor/pointVisibility/projectImageListFilterEditorPointVisibility.component";
import ProjectImageThumbnailItemComponent
    from "./components/projectImageThumbnailItem/projectImageThumbnailItem.component";
import ProjectImageThumbnailListComponent
    from "./components/projectImageThumbnailList/projectImageThumbnailList.component";
import ViewportControlComponent from "./components/viewportControl/viewportControl.component";
import SceneInspectorComponent from "./components/sceneInspector/sceneInspector.component";
import WireframeFocusInformationComponent
    from "./components/wireframeFocusInformation/wireframeFocusInformation.component";
import WireframeSelectionInformationComponent
    from "./components/wireframeSelectionInformation/wireframeSelectionInformation.component";
import WireframeSelectionPaletteComponent
    from "./components/wireframeSelectionPalette/wireframeSelectionPalette.component";
import WireframeSelectionPropertiesComponent
    from "./components/wireframeSelectionProperties/wireframeSelectionProperties.component";
import WireframeSelectionPropertyListComponent
    from "./components/wireframeSelectionPropertyList/wireframeSelectionPropertyList.component";
import WireframeSelectionPropertyListItemComponent
    from "./components/wireframeSelectionPropertyListItem/wireframeSelectionPropertyListItem.component";
import WireframeSearchComponent
    from "./components/wireframeSearch/wireframeSearch.component";
import TextPropertyEditorComponent
    from "./components/wireframeSelectionPropertyListItem/text/textPropertyEditor.component";
import ElementReferencePropertyEditorComponent
    from "./components/wireframeSelectionPropertyListItem/elementReference/elementReferencePropertyEditor.component";
import NumberPropertyEditorComponent
    from "./components/wireframeSelectionPropertyListItem/number/numberPropertyEditor.component";
import EnumPropertyEditorComponent
    from "./components/wireframeSelectionPropertyListItem/enum/enumPropertyEditor.component";
import DictionaryPropertyEditorComponent
    from "./components/wireframeSelectionPropertyListItem/dictionary/dictionaryPropertyEditor.component";
import EdgeSelectionPaletteComponent from "./components/wireframeSelectionPalette/edge/edgeSelectionPalette.component";
import PlaneSelectionPaletteComponent
    from "./components/wireframeSelectionPalette/plane/planeSelectionPalette.component";
import VertexSelectionPaletteComponent
    from "./components/wireframeSelectionPalette/vertex/vertexSelectionPalette.component";
import CesiumViewComponent from "./components/cesium/cesiumView.component";
import {AppManagerComponent} from "./components/appManager/appManager.component";
import {WireframeComponent} from "./components/wireframeViewer/wireframe.component";
import {WireframeViewerDirective} from "./components/wireframeViewer/wireframeViewer.directive";
import WidgetPanelComponent from "./components/widgets/widgetPanel.component";
import TransformControlsComponent from "./components/transformControls/transformControls.component";
import {MeasurePanelComponent} from "./components/measurement/measurePanel.component";
import {MeasurementService} from "./services/measurement/measurement.service";
import {GeometryToolControlComponent} from "./components/geometryTool/geometryToolControl.component";
import VertexPreviewItemComponent from "./components/vertexPreviewItem/vertexPreviewItem.component";
import VertexPreviewListComponent from "./components/vertexPreviewList/vertexPreviewList.component";
import VertexPreviewManagerComponent from "./components/vertexPreviewManager/vertexPreviewManager.component";
import VertexPreviewManagerToolbarComponent
    from "./components/vertexPreviewManager/toolbar/vertexPreviewManagerToolbar.component";
import VertexThumbnailItemComponent from "./components/vertexThumbnailItem/vertexThumbnailItem.component";
import VertexThumbnailListComponent from "./components/vertexThumbnailList/vertexThumbnailList.component";
import VertexFilterVerifiedEditorComponent
    from "./components/vertexFilterVerifiedEditor/vertexFilterVerifiedEditor.component";
import VertexFilterEditorComponent from "./components/vertexFilterEditor/vertexFilterEditor.component";
import VertexFilterLabelComponent from "./components/vertexFilterLabel/vertexFilterLabel.component";
import VertexFilterComponent from "./components/vertexFilter/vertexFilter.component";
import VertexFilterExpressionComponent from "./components/vertexFilterExpression/vertexFilterExpression.component";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {ScrollingModule as ExperimentalScrollingModule} from '@angular/cdk-experimental/scrolling';
import {CacheRouteReuseStrategy} from "./cacheRouteReuse.strategy";
import VertexFilterSeenEditorComponent
    from "./components/vertexFilterSeenEditor/vertexFilterSeenEditor.component";
import EdgeVerificationManagerComponent from "./components/edgeVerificationManager/edgeVerificationManager.component";
import EdgeVerificationListComponent from "./components/edgeVerificationList/edgeVerificationList.component";
import EdgeVerificationItemComponent from "./components/edgeVerificationItem/edgeVerificationItem.component";
import EdgeVerificationManagerToolbarComponent
    from "./components/edgeVerificationManager/toolbar/edgeVerificationManagerToolbar.component";
import {CameraListComponent} from "./components/cameraProjection/cameraList.component";
import {CameraViewComponent} from "./components/cameraProjection/cameraView.component";
import {LoginComponent} from "./components/login/login.component";
import {CommonModule} from "@angular/common";
import {ACTIVE_THEME, ThemeOptions, THEMES, ThemeService} from "./services/themes/theme.service";
import {ThemeDirective} from "./directives/themes/theme.directive";
import {BlueTheme} from "./themes/blue.theme";
import {RedTheme} from "./themes/red.theme";
import {OrganizationService} from "./services/organizations/organization.service";
import {LogoService} from "./services/logos/logo.service";
import {LogoDirective} from "./directives/logos/logo.directive";
import VertexThumbnail3dItemComponent from "./components/vertexThumbnail3dItem/vertexThumbnail3dItem.component";
import VertexThumbnail3dListComponent from "./components/vertexThumbnail3dList/vertexThumbnail3dList.component";
import ProjectManagementComponent from "./components/projectManagment/projectManagement.component";
import ProjectManagementIssuesComponent from "./components/projectManagementIssues/projectManagementIssues.component";
import ProjectManagementResourcesComponent
    from "./components/projectManagementResources/projectManagementResources.component";
import ProjectManagementValidationsComponent
    from "./components/projectManagementValidations/projectManagementValidations.component";
import apiService from "./services/api/apiService";

// zone.js MUST be imported AFTER AppModule/AppModuleNgFactory, otherwise it will throw error "ZoneAware promise has been overridden" during bootstrapping
import 'zone.js/dist/zone';

// globals
var viewer = {
    toLocal: function (v: THREE.Vector3) {
        return v.clone()
    },
    toGeo: function (v: THREE.Vector3) {
        return v.clone()
    }
};
var measurementService = new MeasurementService();
var sceneService = new SceneService(null, measurementService);
var wfApp = sceneService.wireframeApplication;

globalVariables.viewer = viewer;
globalVariables.wfApp = wfApp;

// hybrid angular application
// https://angular.io/guide/upgrade#bootstrapping-hybrid-applications
// downgrade module
// https://angular.cn/guide/upgrade-performance

function createCompiler(compilerFactory: CompilerFactory) {
    return compilerFactory.createCompiler();
}

// shared services


var frameService = new FrameService(wfApp);
var userActivity = new UserActivityService(wfApp);
var projectImageCollectionService = new ProjectImageCollectionService(
    frameService,
    sceneService,
    wfApp);
var modalService = new ModalService();

// angular themes
@NgModule({
    imports: [CommonModule],
    providers: [ThemeService, LogoService],
    declarations: [ThemeDirective, LogoDirective],
    exports: [ThemeDirective, LogoDirective]
})
export class ThemeModule {
    static forRoot(options: ThemeOptions): ModuleWithProviders {
        return {
            ngModule: ThemeModule,
            providers: [
                {
                    provide: THEMES,
                    useValue: options.themes
                },
                {
                    provide: ACTIVE_THEME,
                    useValue: options.active
                }
            ]
        };
    }
}


// angular
@NgModule({
    imports: [
        UpgradeModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AngularResizedEventModule,
        ScrollingModule,
        ExperimentalScrollingModule,
        DragDropModule,
        CdkTableModule,
        FormsModule,
        MatTreeModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatChipsModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatTabsModule,
        MatTableModule,
        NgxJsonViewerModule,
        VirtualScrollerModule,
        ThemeModule.forRoot({
            themes: [BlueTheme, RedTheme],
            active: 'blue'
        }),
        RouterModule.forRoot([
                {
                    path: 'apps',
                    component: AppManagerComponent
                },
                {
                    path: 'wireframe',
                    component: WireframeComponent,
                    data: {
                        wireframeViewerMode: 'full'
                    }
                },
                {
                    path: 'measure',
                    component: WireframeComponent,
                    data: {
                        wireframeViewerMode: 'measure'
                    }
                }
            ],
            {
                useHash: true,
                initialNavigation: false,
                enableTracing: false
            })
    ],
    declarations: [
        AppRootComponent,
        AppManagerComponent,
        WireframeComponent,
        WireframeViewerDirective,
        AnnotationComponent,
        AnnotationPopupComponent,
        JsonComponent,
        ModalComponent,
        LoginComponent,
        ProjectImageItemComponent,
        ProjectImageListComponent,
        ProjectImageListFilterExpressionComponent,
        ProjectImageListFilterComponent,
        ProjectImageListFilterLabelComponent,
        ProjectImageListFilterEditorComponent,
        ProjectImageListFilterEditorAnnotationComponent,
        ProjectImageListFilterEditorMetadataComponent,
        ProjectImageListFilterEditorPointVisibilityComponent,
        ProjectImageListFilterEditorTagComponent,
        ProjectImageManagerComponent,
        ProjectImageManagerToolbarComponent,
        ProjectImageMapComponent,
        ProjectImageThumbnailItemComponent,
        ProjectImageThumbnailListComponent,
        VertexPreviewItemComponent,
        VertexPreviewListComponent,
        VertexPreviewManagerToolbarComponent,
        VertexPreviewManagerComponent,
        VertexThumbnailItemComponent,
        VertexThumbnail3dItemComponent,
        VertexThumbnailListComponent,
        VertexThumbnail3dListComponent,
        SceneInspectorComponent,
        ViewportControlComponent,
        WidgetPanelComponent,
        MeasurePanelComponent,
        CameraListComponent,
        GeometryToolControlComponent,
        TransformControlsComponent,
        CesiumViewComponent,
        WireframeFocusInformationComponent,
        WireframeSelectionInformationComponent,
        WireframeSelectionPaletteComponent,
        WireframeSelectionPropertiesComponent,
        WireframeSelectionPropertyListComponent,
        WireframeSelectionPropertyListItemComponent,
        WireframeSearchComponent,
        NumberPropertyEditorComponent,
        TextPropertyEditorComponent,
        ElementReferencePropertyEditorComponent,
        EnumPropertyEditorComponent,
        DictionaryPropertyEditorComponent,
        EdgeSelectionPaletteComponent,
        PlaneSelectionPaletteComponent,
        EdgeVerificationManagerComponent,
        EdgeVerificationManagerToolbarComponent,
        EdgeVerificationListComponent,
        EdgeVerificationItemComponent,
        ProjectManagementComponent,
        ProjectManagementIssuesComponent,
        ProjectManagementResourcesComponent,
        ProjectManagementValidationsComponent,
        VertexSelectionPaletteComponent,
        VertexFilterExpressionComponent,
        VertexFilterComponent,
        VertexFilterLabelComponent,
        VertexFilterEditorComponent,
        VertexFilterVerifiedEditorComponent,
        VertexFilterSeenEditorComponent,
        CameraViewComponent
    ],
    entryComponents: [
        AppRootComponent,
        AppManagerComponent,
        WireframeComponent,
        AnnotationComponent,
        AnnotationPopupComponent,
        JsonComponent,
        ModalComponent,
        LoginComponent,
        ProjectImageListFilterExpressionComponent,
        ProjectImageListFilterComponent,
        ProjectImageListFilterLabelComponent,
        ProjectImageListFilterEditorComponent,
        ProjectImageListFilterEditorAnnotationComponent,
        ProjectImageListFilterEditorMetadataComponent,
        ProjectImageListFilterEditorPointVisibilityComponent,
        ProjectImageListFilterEditorTagComponent,
        ProjectImageManagerComponent,
        ProjectImageMapComponent,
        ProjectImageThumbnailItemComponent,
        ProjectImageThumbnailListComponent,
        VertexPreviewItemComponent,
        VertexPreviewListComponent,
        VertexPreviewManagerToolbarComponent,
        VertexPreviewManagerComponent,
        VertexThumbnailItemComponent,
        VertexThumbnail3dItemComponent,
        VertexThumbnailListComponent,
        VertexThumbnail3dListComponent,
        SceneInspectorComponent,
        ViewportControlComponent,
        TransformControlsComponent,
        WidgetPanelComponent,
        MeasurePanelComponent,
        CameraListComponent,
        GeometryToolControlComponent,
        CesiumViewComponent,
        WireframeFocusInformationComponent,
        WireframeSelectionInformationComponent,
        WireframeSelectionPaletteComponent,
        WireframeSelectionPropertiesComponent,
        WireframeSelectionPropertyListComponent,
        WireframeSelectionPropertyListItemComponent,
        WireframeSearchComponent,
        NumberPropertyEditorComponent,
        TextPropertyEditorComponent,
        ElementReferencePropertyEditorComponent,
        EnumPropertyEditorComponent,
        DictionaryPropertyEditorComponent,
        EdgeSelectionPaletteComponent,
        PlaneSelectionPaletteComponent,
        EdgeVerificationManagerComponent,
        EdgeVerificationManagerToolbarComponent,
        EdgeVerificationListComponent,
        EdgeVerificationItemComponent,
        ProjectManagementComponent,
        ProjectManagementIssuesComponent,
        ProjectManagementResourcesComponent,
        ProjectManagementValidationsComponent,
        VertexSelectionPaletteComponent,
        VertexFilterExpressionComponent,
        VertexFilterComponent,
        VertexFilterLabelComponent,
        VertexFilterEditorComponent,
        VertexFilterVerifiedEditorComponent,
        VertexFilterSeenEditorComponent
    ],
    providers: [
        RouterService,
        {
            provide: RouteReuseStrategy,
            useClass: CacheRouteReuseStrategy
        },
        OrganizationService,
        MeasurementService,
        ModuleService,
        {
            provide: COMPILER_OPTIONS,
            useValue: {},
            multi: true
        },
        {
            provide: CompilerFactory,
            useClass: JitCompilerFactory,
            deps: [COMPILER_OPTIONS]
        },
        {
            provide: Compiler,
            useFactory: createCompiler,
            deps: [CompilerFactory]
        },
        {
            provide: WireframeApplication,
            useValue: wfApp
        },
        {
            provide: SceneService,
            useValue: sceneService
        },
        {
            provide: MeasurementService,
            useValue: measurementService
        },
        {
            provide: FrameService,
            useValue: frameService
        },
        {
            provide: UserActivityService,
            useValue: userActivity
        },
        {
            provide: ProjectImageCollectionService,
            useValue: projectImageCollectionService
        },
        {
            provide: ModalService,
            useValue: modalService
        }
    ]
})
export class AppModule {
    constructor(private upgrade: UpgradeModule) {
    }

    ngDoBootstrap() {
        this.upgrade.bootstrap(document.body, ['myApp'], {strictDi: true});
    }
}

// bootstrap hybrid
const bootstrapFn = (extraProviders: StaticProvider[]) => {
    const platformRef = platformBrowserDynamic(extraProviders);
    return platformRef.bootstrapModule(AppModule);
};
const downgradedModule = downgradeModule(bootstrapFn);

// angular js
let angularJsAppModule = angular.module('myApp', [
    'ngRoute',
    'ngSanitize',
    'ui.select',
    'ui.grid',
    'ui.grid.selection',
    'ui.bootstrap',
    'ui.layout',
    'colorpicker.module',
    'ngToast',
    downgradedModule
]).config(['$locationProvider', function ($locationProvider) {
    $locationProvider.hashPrefix('');
}]);

angularJsAppModule.service('apiService', apiService);

angularJsAppModule.service('propertyService', function () {
    let propertyService = new PropertyService(wfApp);
    return propertyService;
});

angularJsAppModule.service('frameService', function () {
    return frameService;
});

angularJsAppModule.service('userActivityService', function () {
    return userActivity;
});

angularJsAppModule.service('sceneService', function () {
    return sceneService;
});

angularJsAppModule.service('projectImageCollectionService', function () {
    return projectImageCollectionService;
});

angularJsAppModule.service('modalService', function () {
    return modalService;
});

// angular js
angularJsAppModule.component('wireframeViewer', wireframeViewerComponent);
angularJsAppModule.component('login', loginComponent);
angularJsAppModule.component('eula', eulaComponent);
angularJsAppModule.component('settings', settingsComponent);
angularJsAppModule.component('layerList', layersComponent);
angularJsAppModule.component('resourceBrowser', resourceBrowser);
angularJsAppModule.component('selectionInfo', selectionInfoComponent);
angularJsAppModule.component('properties', propertiesComponent);
angularJsAppModule.component('propertyList', propertyListComponent);
angularJsAppModule.component('property', propertyComponent);
angularJsAppModule.component('propertyInput', propertyInputComponent);
angularJsAppModule.component('wireframeActions', wireframeActionsComponent);
angularJsAppModule.component('tools', toolsComponent);
angularJsAppModule.component('displayRules', displayRulesComponent);
angularJsAppModule.component('displayrule', displayRuleComponent);
angularJsAppModule.component('adjustView', adjustComponent);
angularJsAppModule.component('save', saveComponent);

// angular 6 directives used in angularjs
angularJsAppModule.directive('projectImageManager', downgradeComponent({
    component: ProjectImageManagerComponent,
    propagateDigest: false
}));
angularJsAppModule.directive('sceneInspector', downgradeComponent({
    component: SceneInspectorComponent,
    propagateDigest: false
}));
angularJsAppModule.directive('viewportControl', downgradeComponent({
    component: ViewportControlComponent,
    propagateDigest: false
}));
angularJsAppModule.directive('transformControls', downgradeComponent({
    component: TransformControlsComponent,
    propagateDigest: false
}));
angularJsAppModule.directive('widgetPanel', downgradeComponent({
    component: WidgetPanelComponent,
    propagateDigest: false
}));
angularJsAppModule.directive('cesiumView', downgradeComponent({
    component: CesiumViewComponent,
    propagateDigest: false
}));
angularJsAppModule.directive('measurePanel', downgradeComponent({
    component: MeasurePanelComponent,
    propagateDigest: false
}));

angularJsAppModule.directive('cameraListPanel', downgradeComponent({
    component: CameraListComponent,
    propagateDigest: false
}));

angularJsAppModule.directive('cameraView', downgradeComponent({
    component: CameraViewComponent,
    propagateDigest: false
}));

angularJsAppModule.directive('geometryToolControl', downgradeComponent({
    component: GeometryToolControlComponent,
    propagateDigest: false
}));

angularJsAppModule.directive('ptvVertexPreviewManager', downgradeComponent({
    component: VertexPreviewManagerComponent,
    propagateDigest: false
}));

angularJsAppModule.directive('ptvEdgeVerificationManager', downgradeComponent({
    component: EdgeVerificationManagerComponent,
    propagateDigest: false
}));

angularJsAppModule.directive('ptvProjectManagement', downgradeComponent({
    component: ProjectManagementComponent,
    propagateDigest: false
}));

angularJsAppModule.directive('appRoot', downgradeComponent({component: AppRootComponent, propagateDigest: false}));

// files
angularJsAppModule.directive('file', function () {
    return {
        require: "ngModel",
        restrict: 'A',
        link: function ($scope, el, attrs, ngModel) {
            el.bind('change', function (event: any) {
                console.log("directive change");
                let files = event.target.files;
                let file = files[0];

                (ngModel as any).$setViewValue(file);
                $scope.$apply();
            });
        }
    };
});

angularJsAppModule.config(['ngToastProvider', function (ngToastProvider) {
    ngToastProvider.configure({
        animation: 'fade',
        verticalPosition: 'bottom',
        horizontalPosition: 'left',
        newestOnTop: false,
        combineDuplications: true,
        maxNumber: 15
    });
}]);

angularJsAppModule.filter('unsafe', ['$sce', function ($sce) {
    return function (val) {
        return $sce.trustAsHtml(val);
    };
}]);


// downgrade path
// angular.bootstrap(document.body, ['myApp']);

// upgrade path
export function setUpLocationSync(ngUpgrade: UpgradeModule) {
    if (!ngUpgrade.$injector) {
        throw new Error(`
        RouterUpgradeInitializer can be used only after UpgradeModule.bootstrap has been called.
        Remove RouterUpgradeInitializer and call setUpLocationSync after UpgradeModule.bootstrap.
      `);
    }

    const router: Router = ngUpgrade.injector.get(Router);
    const url = document.createElement('a');

    ngUpgrade.$injector.get('$rootScope')
        .$on('$locationChangeStart', (_: any, next: string, __: string) => {
            url.href = next;
            let navToUrl = url.hash.substr(1);
            console.log(navToUrl);
            //router.navigateByUrl(navToUrl);
        });
}


platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
    const upgrade = (<any>ref.instance).upgrade;
    setUpLocationSync(upgrade);
});