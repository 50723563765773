import {WireframeApplication} from "./application/wireframeApplication";
import {Property} from "./models/property/property";
import * as PVProperty from "./models/property/propertyLoaders"
import {WireframeElementType} from "./application/wireframeElementType";
import {MeasurementUnit} from "./application/measurementUnit";
import {LogLevel} from "./application/logLevel";
import {PropertyValue} from "./models/property/propertyValue";
import {LayerProperty} from "./models/property/layerProperty";
import * as THREE from 'three';
import {WireframeElement} from "./application/wireframeElement";
import {WidgetElement} from "./application/widgetElement";
import {ElementReferenceMap} from "./models/property/elementReferenceMap";
import {WireframeElementMappings} from "./application/WireframeElementMappings";

declare var wfApp: WireframeApplication;

/**
 * Created by Shwetank on 03-May-2017.
 */

export namespace PV {

    export class Wireframe {
        private projectId: number;
        public name: string;
        public description: string;
        public versionHistory: number[] = [];
        public referenceMeasurementScale: number = 1.0;
        public optimumResolution: number = 1.0;
        public measurementUnit: MeasurementUnit = MeasurementUnit.M;

        private vertexIdx: number = 0;
        public vertices: VertexMap = {};
        private edgeIdx: number = 0;
        public edges: EdgeMap = {};
        private planeIdx: number = 0;
        public planes: PlaneMap = {};
        public surfaces: SurfaceMap = {};

        private layerId: number = 0;
        public layers: LayerMap = {};

        private widgetIdx: number = 0
        public widgets: ComponentMap = {}

        public elementIdx:number = 0
        public elements:[] = []

        public properties: PropertyMap = {};
        private propertyId: number = 0;

        public transform: Transform;
        public orthoTransform: OrthographicTransform;

        public setWireframeProjectId(projectId: number): void {
            this.projectId = projectId;
        }

        public getNextVertexId(): number {
            return this.vertexIdx++;
        }

        public getNextWidgetId():number {
            return this.widgetIdx++;
        }

        public getNextEdgeId(): number {
            return this.edgeIdx++;
        }

        public getNextPlaneId(): number {
            return this.planeIdx++;
        }

        public getNextLayerId(): number {
            return this.layerId++;
        }

        public getNextElementId(): number {
            return this.elementIdx++
        }

        public addProperty(prop: Property): number {
            this.propertyId++;
            prop.id = this.propertyId;
            this.properties[prop.id] = prop;
            prop.onAddToWireframe();
            return prop.id;
        }

        public deleteProperty(propId: number) {
            let elements = [...Object.values(this.vertices), ...Object.values(this.edges), ...Object.values(this.planes)];
            let prop: Property = this.properties[propId];
            for (let el of elements) {
                prop.removeValue(el);
            }
            delete this.properties[propId];
        }

        //Vertex
        public addVertex(vertex: Vertex): number {
            vertex.id = this.getNextVertexId();
            this.vertices[vertex.id] = vertex;
            return vertex.id;
        }

        public addWidget(widget: Widget): number {
            widget.id = this.getNextWidgetId();
            this.widgets[widget.id] = widget;
            return widget.id;
        }

        public removeWidget(widgetId:number) {
            delete this.widgets[widgetId]
        }

        private removeProps(el: WireframeElement) {
            if (null == el) return;
            let that = this;
            el.pvObject.properties.forEach(function (pv: PropertyValue) {
                let prop = that.properties[pv.id];
                if (null == prop) return;
                prop.onRemoveFromElement(el, pv);
            })
        }

        public removeVertex(vertexId: number): void {
            delete this.vertices[vertexId];
            var edgeIds = Object.keys(this.edges);
            for (var i = 0; i < edgeIds.length; i++) {
                var edge: Edge = this.edges[edgeIds[i]];
                if (edge.vertex1Id == vertexId || edge.vertex2Id == vertexId) {
                    this.removeEdge(edge.id);
                }
            }
        }

        public isValid(c:Component) {
            if (c instanceof PV.Edge) {
                if (!this.vertices[c.vertex1Id] || !this.vertices[c.vertex2Id]) return false
                if (c.vertex2Id == c.vertex1Id) return false
            } else if (c instanceof PV.Plane) {
                for (let vertId of c.vertexIds) {
                    if (!c.vertexIds[vertId]) return false
                }
                for (let edgeId of c.edgeIds) {
                    if (!c.edgeIds[edgeId]) return false
                }
            }
            return true
        }

        //Edge
        public addEdge(edge: Edge): number {
            if (edge.vertex1Id == edge.vertex2Id) return null;
            if (!this.vertices[edge.vertex1Id] || !this.vertices[edge.vertex2Id]) return null
            edge.id = this.getNextEdgeId();
            this.edges[edge.id] = edge;
            return edge.id;
        }

        public removeEdge(edgeId: number): void {
            delete this.edges[edgeId];
        }

        public findEdge(vert1Id: number, vert2Id: number): Edge {
            var sortedIds = [Number(vert1Id), Number(vert2Id)];
            sortedIds.sort(function (a, b) {
                return a - b;
            });
            for (var id in this.edges) {
                //console.log("testing edge " + sortedIds[0] + " - " + sortedIds[1], this.edges[id]);
                if (this.edges[id].vertex1Id == sortedIds[0] && this.edges[id].vertex2Id == sortedIds[1]) return this.edges[id];
            }
            return null;
        }

        //Plane
        public addPlane(plane: Plane): number {
            //this.orderVertexIds(plane);
            plane.id = this.getNextPlaneId();
            this.planes[plane.id] = plane;
            return plane.id;
        }

        public removePlane(planeId: number): void {
            delete this.planes[planeId];
        }

        public findChildPlanes(parentPlane: Plane): Plane[] {
            var children = [];
            var childId;
            for (childId in this.planes) {
                var child: Plane = this.planes[childId];
                if (child.parentId == parentPlane.id) {
                    children.push(child);
                }
            }
            return children;
        }

        public findEdgesForVertex(vertexId: number): number[] {
            var edgeIds: number[] = [];
            for (var edgeId in this.edges) {
                var edge: Edge = this.edges[edgeId];
                if (edge.vertex1Id == vertexId || edge.vertex2Id == vertexId) {
                    edgeIds.push(edge.id);
                }
            }
            return edgeIds;
        }

        public findAdjacentVerts(vertexId: number): number[] {
            var vertIds: number[] = [];
            for (var edgeId in this.edges) {
                var edge: Edge = this.edges[edgeId];
                if (edge.vertex1Id == vertexId) {
                    vertIds.push(edge.vertex2Id);
                } else if (edge.vertex2Id == vertexId) {
                    vertIds.push(edge.vertex1Id);
                }
            }
            return vertIds;
        }

        public findPlanesForVertex(vertexId: number): number[] {
            var planeIds: number[] = [];
            for (var planeId in this.planes) {
                var plane: Plane = this.planes[planeId];
                if (plane.vertexIds.includes(vertexId)) {
                    planeIds.push(plane.id);
                }
            }
            return planeIds;
        }

        public findAdjacentPlanes(plane: PV.Plane): number[] {
            let planes: number[] = [];
            for (let edgeId of plane.edgeIds) {
                this.findPlanesForEdge(edgeId).forEach((adjPlaneId) => {
                    if (adjPlaneId == plane.id) return;
                    if (!planes.includes(adjPlaneId)) planes.push(adjPlaneId);
                });
            }
            return planes;
        }

        public findPlanesForEdge(edgeId: number): number[] {
            var planeIds: number[] = [];
            for (var planeId in this.planes) {
                var plane: Plane = this.planes[planeId];
                if (plane.edgeIds.includes(edgeId)) {
                    planeIds.push(plane.id);
                }
            }
            return planeIds;
        }

        public addVertexToPlane(plane: PV.Plane, vert: PV.Vertex, betweenVert1: number, betweenVert2: number) {
            let index1 = plane.vertexIds.indexOf(betweenVert1);
            let index2 = plane.vertexIds.indexOf(betweenVert2);
            let upperIndex = Math.max(index1, index2);
            let lowerIndex = Math.min(index1, index2);
            //console.log("adding new vert " + vert.id + " at index " + upperIndex + "/" + plane.vertexIds.toString())
            if (lowerIndex == 0 && upperIndex == plane.vertexIds.length - 1) {
                plane.vertexIds.push(vert.id);
            } else {
                plane.vertexIds.splice(upperIndex, 0, vert.id);
            }
        }

        getLocalToGlobalTransform(): THREE.Matrix4 {
            let l2g: THREE.Matrix4 = new THREE.Matrix4();
            let t: number[] = this.transform.localToGlobal;
            l2g.set(t[0], t[1], t[2], t[3],
                t[4], t[5], t[6], t[7],
                t[8], t[9], t[10], t[11],
                t[12], t[13], t[14], t[15]
            );
            return l2g;
        }

        getVert3(vertId: Number) {
            // return THREE.js Vector3 for vertId
            let id = "" + vertId;
            if (!(id in this.vertices)) return null;
            let v = new THREE.Vector3().copy(this.vertices[id]);
            return v;
        }

        setVert3(vertId: Number, vec: THREE.Vector3) {
            let id = "" + vertId;
            let vert: PV.Vertex = this.vertices[id];
            vert.x = vec.x;
            vert.y = vec.y;
            vert.z = vec.z;
        }

        getPlane3(planeId: Number) {
            let planeIdStr = planeId + "";
            if (!(planeIdStr in this.planes)) return null;
            let p = new THREE.Plane();
            let wfPlane: PV.Plane = this.planes[planeIdStr];
            p.set(new THREE.Vector3(wfPlane.bestFitPlane.a, wfPlane.bestFitPlane.b, wfPlane.bestFitPlane.c), wfPlane.bestFitPlane.d);
            return p;
        }

        public getPrimaryEdgeId(plane: Plane): number {
            if (!plane.edgeIds.includes(plane.primaryEdgeId)) {
                // find edge best aligned with gravity plane
                let groundPlane: THREE.Plane = wfApp.getGravityPlane();
                interface EdgeInfo {
                    edge: PV.Edge,
                    vec: THREE.Vector3,
                    gravityDP: number
                }
                let edges:{[key: number]: EdgeInfo} = { }
                for (let edgeId of plane.edgeIds) {
                    let edge: Edge = this.edges[edgeId];
                    let edgeVec: THREE.Vector3 = new THREE.Vector3().subVectors(this.getVert3(edge.vertex2Id), this.getVert3(edge.vertex1Id));
                    edges[edge.id] = {
                        edge: edge,
                        vec: edgeVec,
                        gravityDP: Math.abs(edgeVec.clone().normalize().dot(groundPlane.normal))
                    }
                    //edgeAlignmentList.push({edgeId: edgeId, vec: edgeVec, gravityDP: Math.abs(edgeVec.clone().normalize().dot(groundPlane.normal))});
                }
                let edgeAlignmentList = Object.values(edges)
                edgeAlignmentList.sort((a, b) => {
                    return a.gravityDP - b.gravityDP;
                });
                // filter those within 5 degrees of the best-aligned edge
                let alignedEdges = edgeAlignmentList.filter((edge) => {
                    return (edge.gravityDP < edgeAlignmentList[0].gravityDP + (5 * Math.PI / 180))
                })

                let edgesByDirection = []
                // cluster based on edge direction
                alignedEdges.forEach((edge) => {
                    let matched = false
                    for (let i = 0; i < edgesByDirection.length; i++) {
                        let direction = edgesByDirection[i]
                        // cluster based on < 10 degree edge orientation
                        if (Math.abs(direction.vec.dot(edge.vec.clone().normalize())) > .985) {
                            direction.edgeIds.push(edge.edge.id)
                            direction.totalLength += edge.vec.length()
                            matched = true
                        }
                    }
                    if (!matched) {
                        edgesByDirection.push({ vec: edge.vec.clone().normalize(), edgeIds: [ edge.edge.id ], totalLength: edge.vec.length() })
                    }
                })
                edgesByDirection.sort((d1, d2) => {
                    return d2.totalLength - d1.totalLength
                })

                edgesByDirection[0].edgeIds.sort((edgeId1, edgeId2) => {
                    return edges[edgeId2].vec.length() - edges[edgeId1].vec.length()
                })
                return edgesByDirection[0].edgeIds[0];
            }
            return plane.primaryEdgeId;
        }

        public updatePlaneNormal(plane: PV.Plane) {
            if (plane.vertexIds.length > 3) {
                let normal = new THREE.Vector3();
                let center = new THREE.Vector3();
                for (let i = 0; i < plane.vertexIds.length; i++) {
                    let v1: THREE.Vector3 = this.getVert3(plane.vertexIds[i]);
                    let v2: THREE.Vector3 = this.getVert3(plane.vertexIds[(i + 1) % plane.vertexIds.length]);
                    center.add(v1);
                    normal.x += (v1.y - v2.y) * (v1.z + v2.z);
                    normal.y += (v1.z - v2.z) * (v1.x + v2.x);
                    normal.z += (v1.x - v2.x) * (v1.y + v2.y);
                }
                center.divideScalar(plane.vertexIds.length);
                normal.normalize();
                let bestFit = new THREE.Plane();
                bestFit.setFromNormalAndCoplanarPoint(normal, center);
                plane.bestFitPlane.a = bestFit.normal.x;
                plane.bestFitPlane.b = bestFit.normal.y;
                plane.bestFitPlane.c = bestFit.normal.z;
                plane.bestFitPlane.d = bestFit.constant;
            } else if (plane.vertexIds.length == 3) {
                var v1: PV.Vertex = this.vertices[plane.vertexIds[0]];
                var v2: PV.Vertex = this.vertices[plane.vertexIds[1]];
                var v3: PV.Vertex = this.vertices[plane.vertexIds[2]];
                var a: number = (v2.y - v1.y) * (v3.z - v1.z) - (v3.y - v1.y) * (v2.z - v1.z);
                var b: number = (v2.z - v1.z) * (v3.x - v1.x) - (v3.z - v1.z) * (v2.x - v1.x);
                var c: number = (v2.x - v1.x) * (v3.y - v1.y) - (v3.x - v1.x) * (v2.y - v1.y);
                var d: number = -(a * v1.x + b * v1.y + c * v1.z);
                var norm = Math.sqrt(a * a + b * b + c * c);

                if (!plane.bestFitPlane) plane.bestFitPlane = new PV.PlaneEquation(0, 0, 0, 0);
                plane.bestFitPlane.a = a / norm;
                plane.bestFitPlane.b = b / norm;
                plane.bestFitPlane.c = c / norm;
                plane.bestFitPlane.d = d / norm;
            } else {

            }
        }

        public areNormalsConsistent(plane1: PV.Plane, plane2: PV.Plane, sharedEdge: PV.Edge): boolean {
            if (!sharedEdge) {
                //TODO find shared edge
            }
            let p1v1Idx = plane1.vertexIds.indexOf(sharedEdge.vertex1Id);
            let p1Direction = plane1.vertexIds[(p1v1Idx + 1) % plane1.vertexIds.length] == sharedEdge.vertex2Id;
            let p2v1Idx = plane2.vertexIds.indexOf(sharedEdge.vertex1Id);
            let p2Direction = plane2.vertexIds[(p2v1Idx + 1) % plane2.vertexIds.length] == sharedEdge.vertex2Id;
            return p1Direction != p2Direction;
        }

        public flipNormal(plane: Plane) {
            plane.vertexIds.reverse();
            plane.edgeIds.reverse();
            this.updatePlaneNormal(plane);
        }

        /*
        public orderVertexIds(plane:Plane) {
            let sortedVertIds = plane.vertexIds.slice().sort();
            let lowestIndex = plane.vertexIds.indexOf(sortedVertIds[0]);
            arrayRotate(plane.vertexIds, lowestIndex);
            if (plane.vertexIds[1] > plane.vertexIds[plane.vertexIds.length - 1]) {
                plane.vertexIds.reverse();
                plane.edgeIds.reverse();
            }
        }


        public orderVertexIdsFromEdges(plane:Plane) {
            // remove duplicates
            plane.vertexIds = plane.vertexIds.filter(function(value, index, self) {
                return self.indexOf(value) === index;
            });

            let vertIds:number[] = [];
            let edgeIds:number[] = [];
            vertIds.push(plane.vertexIds[0]);
            let that = this;
            while (true) {
                let currentVertId = vertIds[vertIds.length - 1];
                let vertEdgeIds:number[] = this.findEdgesForVertex(currentVertId).filter(function(edgeId:Number) {
                    let edge:Edge = that.edges[edgeId];
                    if (!(plane.vertexIds.includes(edge.vertex1Id) && plane.vertexIds.includes(edge.vertex2Id))) return false;
                    if (vertIds.includes(edge.vertex1Id) && vertIds.includes(edge.vertex2Id)) return false;
                    return true;
                })
                if (vertIds.length > 1 && vertEdgeIds.length > 1) {
                    console.error("Error reordering plane verts");
                    //break;
                }
                let nextEdge:Edge = this.edges[vertEdgeIds[0]];
                if (null != nextEdge) {
                    edgeIds.push(nextEdge.id);
                    if (currentVertId == nextEdge.vertex1Id) {
                        vertIds.push(nextEdge.vertex2Id);
                    } else {
                        vertIds.push(nextEdge.vertex1Id);
                    }
                } else {
                    console.log("Invalid plane, missing edge");
                    break;
                }
                if (vertIds.length >= plane.vertexIds.length) break;
            }
            plane.vertexIds = vertIds;
            plane.edgeIds = edgeIds;
            this.orderVertexIds(plane);
        }
        */

        private arraysEqual(a: any[], b: any[], compareOrder: boolean = true) {
            if (a === b) return true;
            if (a == null || b == null) return false;
            if (a.length != b.length) return false;
            if (!compareOrder) {
                a = a.slice().sort();
                b = b.slice().sort();
            }
            for (var i = 0; i < a.length; ++i) {
                if (a[i] !== b[i]) return false;
            }
            return true;
        }

        public findPlaneWithEdgeIds(edgeIds: number[]): PV.Plane {
            for (var planeId in this.planes) {
                var p: PV.Plane = this.planes[planeId];
                if (this.arraysEqual(edgeIds, p.edgeIds, false)) return p;
            }
            return null;
        }

        public findPlaneWithVertIds(vertIds: number[]): PV.Plane {
            for (var planeId in this.planes) {
                var p: PV.Plane = this.planes[planeId];
                if (this.arraysEqual(vertIds, p.vertexIds, false)) return p;
            }
            return null;
        }

        public createPlaneFromEdges(planeEdges: PV.Edge[]): PV.Plane {
            var startVertexId: number = planeEdges[0].vertex1Id;
            var loopSize: number = planeEdges.length;
            var edgeLoop: PV.Edge[] = [];
            edgeLoop.push(planeEdges[0]);

            var lastEdgeFound: boolean = false;
            for (var i = 1; i < planeEdges.length; i++) {
                if (planeEdges[i].vertex1Id === startVertexId || planeEdges[i].vertex2Id === startVertexId) {
                    lastEdgeFound = true;
                    break;
                }
            }

            if (!lastEdgeFound)
                return;

            var secondIndex: number = planeEdges[0].vertex2Id;
            var counter: number = 0;
            while (secondIndex !== startVertexId && edgeLoop.length <= loopSize && counter <= loopSize) {
                var nextIndex: number = -1;
                for (var i = 1; i < planeEdges.length; i++) {
                    if (planeEdges[i].vertex1Id === secondIndex) {
                        secondIndex = planeEdges[i].vertex2Id;
                        nextIndex = i;
                        break;
                    } else if (planeEdges[i].vertex2Id === secondIndex) {
                        secondIndex = planeEdges[i].vertex1Id;
                        nextIndex = i;
                        break;
                    }
                }
                if (nextIndex != -1) {
                    edgeLoop.push(planeEdges[nextIndex]);
                    planeEdges.splice(nextIndex, 1);
                }
                counter++;
            }

            if (edgeLoop.length < loopSize && secondIndex === startVertexId) {
                console.log("Plane created using subset of selected edges", LogLevel.WARNING);
            } else if (edgeLoop.length === loopSize && secondIndex === startVertexId) {
                console.log("Plane created using all edges");
            } else {
                return;
            }

            var plane: PV.Plane = new PV.Plane();
            edgeLoop.forEach(function (edge: Edge) {
                plane.edgeIds.push(edge.id);
                if (!plane.vertexIds.includes(edge.vertex1Id)) plane.vertexIds.push(edge.vertex1Id);
                if (!plane.vertexIds.includes(edge.vertex2Id)) plane.vertexIds.push(edge.vertex2Id);
            })

            return plane;
        }

        public getCommonVertices(e1: Edge, e2: Edge): number[] {
            var verts: number[] = [];
            if (e1.vertex1Id == e2.vertex1Id || e1.vertex1Id == e2.vertex2Id) verts.push(e1.vertex1Id);
            if (e1.vertex2Id == e2.vertex1Id || e1.vertex2Id == e2.vertex2Id) verts.push(e1.vertex2Id);
            return verts;
        }

        public getNextId(map: any): number {
            var keys = Object.keys(map);
            if (keys.length < 1) return 0;
            var highest: number = Number(Object.keys(map)[0]);
            for (var k in map) {
                var numk = Number(k);
                if (numk > highest) highest = numk;
            }
            return highest + 1;
        }

        public findProperty(classFunction, createIfNotFound: boolean, name?: string): Property {
            var prop: Property = Object.values(this.properties).find(function (prop) {
                if (name == null) {
                    return prop instanceof classFunction;
                } else {
                    return (prop instanceof classFunction) && (prop.name == name);
                }
            });
            if (!prop && createIfNotFound) {
                prop = new classFunction();
                if (null != name) prop.name = name;
                prop.id = this.getNextId(this.properties);
                this.properties[prop.id] = prop;
            }
            return prop;
        }

        public findPropertyByName(propName: string): Property {
            return Object.values(this.properties).find(function (prop) {
                return prop.name == propName;
            });
        }

        public findPropertyByLabel(label: string): Property {
            return Object.values(this.properties).find(function (prop) {
                return prop.label == label;
            });
        }

        public fromJson(json: object): void {
            //console.log("fromJson vert[0].confidence " + json["vertices"][0].confidence, json);
            var that = this;
            this.properties = {};
            var unknownProps = [];
            if ("properties" in json) {
                for (var id in json["properties"]) {
                    var propJson = json["properties"][id];
                    if (propJson === null) {
                        // properties with classes unknown to jobservice (e.g. removed classes present
                        // in old wireframes) get mapped to a null value, which we need to skip
                        continue
                    }
                    var propClass = propJson.type.substr(1);
                    if (!PVProperty[propClass]) {
                        unknownProps.push(propJson);
                        continue;
                    }
                    var prop: Property = Property.deserialize(propClass, propJson, PVProperty as any);
                    prop.id = Number(id);
                    this.properties[prop.id] = prop;
                }
                this.propertyId = this.getNextId(this.properties);

                if (unknownProps.length > 0) {
                    console.log("Wireframe contains " + unknownProps.length + " unknown properties", LogLevel.WARNING);
                }
                unknownProps.forEach(function (prop) {
                    console.error("Unknown property found : ", prop);
                })
            }

            this.vertices = {};
            for (var id in json["vertices"]) {
                this.vertices[id] = new Vertex();
                Object.assign(this.vertices[id], json["vertices"][id]);
                this.vertices[id].id = Number(id);

                this.vertices[id].fromJson(json["vertices"][id]);
            }
            this.vertexIdx = json["vertexIdx"];
            if (!this.vertexIdx) {
                this.vertexIdx = this.getNextId(this.vertices);
            }

            this.edges = {};
            for (var id in json["edges"]) {
                this.edges[id] = new Edge(json["edges"][id].vertex1Id, json["edges"][id].vertex2Id);
                Object.assign(this.edges[id], json["edges"][id]);
                this.edges[id].id = Number(id);
                // enforce vertexId1 < vertexId2
                if (this.edges[id].vertex1Id > this.edges[id].vertex2Id) {
                    var tmp = this.edges[id].vertex2Id;
                    this.edges[id].vertex2Id = this.edges[id].vertex1Id;
                    this.edges[id].vertex1Id = tmp;
                }
                this.edges[id].fromJson(json["edges"][id]);
            }
            this.edgeIdx = json["edgeIdx"];
            if (!this.edgeIdx) {
                this.edgeIdx = this.getNextId(this.edges);
            }

            this.planes = {};
            for (var id in json["planes"]) {
                this.planes[id] = new Plane();
                Object.assign(this.planes[id], json["planes"][id]);
                this.planes[id].id = Number(id);
                this.planes[id].fromJson(json["planes"][id]);
            }
            this.planeIdx = json["planeIdx"];
            this.planeIdx = this.getNextId(this.planes);

            this.widgets = {}
            for (var id in json["widgets"]) {
                this.widgets[id] = new Widget();
                Object.assign(this.widgets[id], json["widgets"][id]);
                this.widgets[id].id = Number(id);
                this.widgets[id].fromJson(json["widgets"][id]);
            }
            this.widgetIdx = json["widgetIdx"];
            this.widgetIdx = this.getNextId(this.widgets);

            let wireframeElementMappings = new WireframeElementMappings()
            this.elements = json["elements"]
            /*
            this.elementIdx = 0
            if (!json["elements"]) json["elements"] = []
            for (let componentJson of json["elements"]) {
                try {
                    let componentConstructor: any = wireframeElementMappings.getDataClass(componentJson["pvType"])
                    if (null == componentConstructor) continue;
                    let component = new componentConstructor()

                    component.fromJson(componentJson)
                    this.elements.push(component)
                    if (component.id > this.elementIdx) this.elementIdx = component.id
                } catch (e) {
                    console.warn("Error loading element", componentJson, e)
                }
            }

             */

            this.layers = json["layers"];
            for (var id in this.layers) this.layers[id].id = Number(id);
            this.layerId = this.getNextId(this.layers);
            if (Object.keys(this.layers).length == 0)
                this.layerId = 0;

            this.transform = json["transform"];
            this.orthoTransform = new OrthographicTransform()
            Object.assign(this.orthoTransform, json["orthoTransform"]);

            this.name = json["name"];
            this.description = json["description"];
            this.optimumResolution = json["optimumResolution"];
            this.referenceMeasurementScale = json["referenceMeasurementScale"];

            var unit = json["measurementUnit"];
            //if (typeof unit === 'number')
            unit = MeasurementUnit[unit];
            this.measurementUnit = unit;
            if (this.measurementUnit == null) {
                this.measurementUnit = MeasurementUnit.M;
            }
            this.versionHistory = json["versionHistory"];
            if (!this.versionHistory) this.versionHistory = [];

            for (let prop of Object.values(this.properties)) {
                prop.onLoad();
                prop.onAddToWireframe();
            }
            let components:PV.Component[] = [...Object.values(this.vertices), ...Object.values(this.edges), ...Object.values(this.planes)];

            components.forEach((c) => {
                // filter out property values that reference non-existent properties
                c.properties = c.properties.filter((pv) => {
                    return (null != that.properties[pv.id])
                })
                c.properties.forEach((pv) => {
                    let prop = this.properties[pv.id];
                    pv.value = prop.valueFromJson(pv.value)
                })
            })
        }

        public validate() {
            var numErrors = 0;
            for (var planeId in this.planes) {
                var plane: Plane = this.planes[planeId];
                if (null == plane.vertexIds) continue; // autogen only writes edge ids, not vertex ids
                for (var i = 0; i < plane.vertexIds.length; i++) {
                    var vertId = plane.vertexIds[i];
                    if (!this.vertices[vertId]) {
                        console.warn("Dropping plane " + plane.id + " with orphan vertex " + vertId);
                        this.removePlane(plane.id);
                        numErrors++;
                        break;
                    }
                }
            }
            for (var edgeId in this.edges) {
                var edge: Edge = this.edges[edgeId];
                if (!this.vertices[edge.vertex1Id] || !this.vertices[edge.vertex2Id]) {
                    console.warn("Dropping edge " + edge.id + " with orphan vertices " + edge.vertex1Id + "-" + edge.vertex2Id);
                    this.removeEdge(edge.id);
                    numErrors++;
                }
            }
            return numErrors;
        }

        public filterGeometry(map: ComponentMap): ComponentMap {
            let layerProp = this.findProperty(LayerProperty, true);
            let wireframeFilter = function (c: Component) {
                let layer = layerProp.getValue(c);
                return layer == null;
            }
            let reduceFunc = function (source: ComponentMap) {
                return Object.values(source).reduce(function (map: ComponentMap, obj: PV.Component) {
                    if (wireframeFilter(obj)) {
                        map[obj.id] = obj;
                    }
                    return map;
                }, {});
            }
            return reduceFunc(map);
        }

        public toJson(): object {

            var wireframe: object = {};
            wireframe["projectId"] = this.projectId;
            wireframe["properties"] = this.properties;
            wireframe["vertices"] = this.vertices;
            wireframe["edges"] = this.edges;
            wireframe["planes"] = this.planes;
            wireframe["elements"] = this.elements;

            let widgets = this.widgets
            wireframe["widgets"] = {}

            Object.values(widgets).forEach((w:PV.Widget) => {
                wireframe["widgets"][w.id] = w.getSerializableClone()
            })

            wireframe["vertexIdx"] = this.vertexIdx;
            wireframe["edgeIdx"] = this.edgeIdx;
            wireframe["planeIdx"] = this.planeIdx;
            wireframe["surfaces"] = this.surfaces;
            wireframe["transform"] = this.transform;
            wireframe["orthoTransform"] = this.orthoTransform;
            wireframe["layers"] = this.layers;
            wireframe["name"] = this.name;
            wireframe["description"] = this.description;
            wireframe["referenceMeasurementScale"] = this.referenceMeasurementScale;
            wireframe["measurementUnit"] = MeasurementUnit[this.measurementUnit];
            wireframe["optimumResolution"] = this.optimumResolution;
            wireframe["versionHistory"] = this.versionHistory;
            return wireframe;
        }

        public cloneVertex(v: Vertex) {
            var cv = new Vertex();
            cv.x = v.x;
            cv.y = v.y;
            cv.z = v.z;

            v.properties.forEach(function (pv: PropertyValue) {
                cv.properties.push(pv.clone());
            });
            this.addVertex(cv);
            return cv;
        }

        public cloneEdge(e: Edge, vertIdMap: Map<number, number>): Edge {
            if ((!(e.vertex1Id in vertIdMap)) || (!(e.vertex2Id in vertIdMap))) {
                return null;
            }
            var ne = new Edge(vertIdMap[e.vertex1Id], vertIdMap[e.vertex2Id]);
            e.properties.forEach(function (pv: PropertyValue) {
                ne.properties.push(pv.clone());
            });
            this.addEdge(ne);
            return ne;
        }

        public clonePlane(p: Plane, edgeIdMap: Map<number, number>, vertIdMap: Map<number, number>) {
            var np = new Plane();
            np.perimeter = p.perimeter;
            np.slope = p.slope;
            np.area = p.area;
            np.parentId = p.parentId;
            np.bestFitPlane = p.bestFitPlane.clone();
            for (var i = 0; i < p.vertexIds.length; i++) {
                var newVertId = vertIdMap[p.vertexIds[i]];
                if (null == newVertId) return null;
                np.vertexIds.push(newVertId);
            }
            for (var i = 0; i < p.edgeIds.length; i++) {
                var newEdgeId = edgeIdMap[p.edgeIds[i]];
                if (null == newEdgeId) return null;
                np.edgeIds.push(newEdgeId);
            }
            p.properties.forEach(function (pv: PropertyValue) {
                np.properties.push(pv.clone());
            });
            this.addPlane(np);
            return np;
        }
    }

    export abstract class Component {

        public id: number;
        public pvType: WireframeElementType;
        properties: PropertyValue[] = [];

        fromJson(json: any) {
            this.id = Number(json["id"])
            this.properties = [];
            var that = this;
            if ("properties" in json) {
                json["properties"].forEach(function (p) {
                    that.properties.push(new PropertyValue(p["id"], p["value"]));
                });
            }
        }

        getSerializableClone():Component {
            return null
        }
    }

    export class Widget extends Component {
        public geometry:any
        public object3D:WidgetElement // this should be treated as transient, e.g. don't serialize
        public object3D_JSON:any

        constructor() {
            super()
            this.pvType = WireframeElementType.widget
        }
        fromJson(json:any) {

            super.fromJson(json)
        }

        getSerializableClone():Component {
            let clone = new Widget();
            clone.properties = JSON.parse(JSON.stringify(this.properties))
            clone.object3D_JSON = this.object3D.toSerializableClone()
            return clone
        }
    }

    export abstract class Volume extends Component {
        protected constructor() {
            super()
            this.pvType = WireframeElementType.volume
        }
    }

    export class Cuboid extends Volume {
        constructor() {
            super()
            this.pvType = WireframeElementType.cuboid
        }
    }

    export class Vertex extends Component {
        public x: number = 0;
        public y: number = 0;
        public z: number = 0;

        constructor() {
            super();
            this.pvType = WireframeElementType.vertex;
        }

        setPos(x, y, z) {
            this.x = x;
            this.y = y;
            this.z = z;
        }
        getSerializableClone(): PV.Component {
            let clone = new Vertex();
            clone.x = this.x;
            clone.y = this.y;
            clone.z = this.z;
            clone.properties = JSON.parse(JSON.stringify(this.properties))
            return clone;
        }

        /*
         constructor(x: number, y: number, z: number, confidence: number)
         {
         //console.log("vertex constructor");

         this.x = x;
         this.y = y;
         this.z = z;
         this.confidence = confidence;
         }
         */
    }

    export interface ComponentMap {
        [id: number]: Component;
    }

    export interface VertexMap {
        [id: number]: Vertex;
    }

    export interface PropertyMap {
        [id: number]: Property;
    }

    export class Edge extends Component {

        public vertex1Id: number;
        public vertex2Id: number;

        constructor(v1Id: number = null, v2Id: number = null) {
            super();
            this.pvType = WireframeElementType.edge;
            if (v1Id > v2Id) {
                var tmp: number = v1Id;
                v1Id = v2Id;
                v2Id = tmp;
            }
            this.vertex1Id = v1Id;
            this.vertex2Id = v2Id;
        }

        getOtherVert(vertId: number): number {
            if (vertId == this.vertex1Id) return this.vertex2Id;
            return this.vertex1Id;
        }

        getSerializableClone():Component {
            let clone = new Edge(this.vertex1Id, this.vertex2Id);
            clone.properties = JSON.parse(JSON.stringify(this.properties))
            return clone;
        }
    }

    export interface EdgeMap {
        [id: number]: Edge;
    }

    export class Plane extends Component {
        constructor() {
            super();
            this.pvType = WireframeElementType.plane;
        }

        public parentId: number;
        public bestFitPlane: PlaneEquation = new PlaneEquation(0, 0, 0, 0);

        public vertexIds: number[] = [];
        public edgeIds: number[] = [];
        public primaryEdgeId: number;
        public area: number;
        public perimeter: number;
        public slope: number;
        public pitch: number;

        fromJson(o: any) {
            super.fromJson(o);
            if (o.bestFitPlane) {
                this.bestFitPlane = new PlaneEquation(o.bestFitPlane.a, o.bestFitPlane.b, o.bestFitPlane.c, o.bestFitPlane.d);
            } else {
                this.bestFitPlane = new PlaneEquation();
            }
        }

        getSerializableClone():Component {
            let clone = new Plane()
            clone.properties = JSON.parse(JSON.stringify(this.properties))
            clone.parentId = this.parentId
            clone.area = this.area
            clone.perimeter = this.perimeter
            clone.slope = this.slope
            clone.pitch = this.pitch
            clone.bestFitPlane = clone.bestFitPlane.clone()
            clone.edgeIds = JSON.parse(JSON.stringify(this.edgeIds))
            clone.vertexIds = JSON.parse(JSON.stringify(this.vertexIds))

            return clone;
        }
    }

    export class Group extends Component {
        members:ElementReferenceMap = {}
        constructor() {
            super()
            this.pvType = WireframeElementType.group
        }
    }

    //will replace plane class fields a, b, c, d
    export class PlaneEquation {
        public a: number;
        public b: number;
        public c: number;
        public d: number;

        constructor(parameterA = 0, parameterB = 0, parameterC = 0, parameterD = 0) {
            this.a = parameterA;
            this.b = parameterB;
            this.c = parameterC;
            this.d = parameterD;
        }

        public clone(): PlaneEquation {
            return new PlaneEquation(this.a, this.b, this.c, this.d);
        }
    }

    export interface PlaneMap {
        [id: number]: Plane;
    }

    export class Surface {
        id: number;
        public outerEdgeIds: number[];
        public innerEdgeIds: number[][];

        constructor(outerRingEdgeIds: number[], innerRingEdgeIds: number[][]) {
            this.outerEdgeIds = outerRingEdgeIds;
            this.innerEdgeIds = innerRingEdgeIds;
        }
    }

    export interface SurfaceMap {
        [id: number]: Surface;
    }

    export class Layer {
        id: number;
        public description: string;
        public displayOrder: number;
        public name: string;
        private pointCloudName: string;
        public vertexIds: number[];
        public edgeIds: number[];
        public planeIds: number[];
    }

    export interface LayerMap {
        [id: number]: Layer;
    }

    export class Transform {
        localToGlobal: number[];
        gravityPlane: number[];
        representativeGroundPlane: number[];
        zone: string;
        proj4: string;
        scaleFactor: number;
        globalCoordinateType: number;
    }

    export class OrthographicTransform {
        rotation: number[] = [];
        translation: number[] = [];
        minX: number;
        minY: number;
        minZ: number;
        maxX: number;
        maxY: number;
        maxZ: number;
        width: number;
        height: number;
        aspectRatio: number = 1.0;
        newWidth: number = 1920;
        newHeight: number = 1080;
        coeffX: number = 1.0;
        coeffY: number = 1.0;

        getMat4():THREE.Matrix4 {
            let orthoMat = new THREE.Matrix4()
            orthoMat.set(
                this.rotation[0], this.rotation[1], this.rotation[2], this.translation[0],
                this.rotation[3], this.rotation[4], this.rotation[5], this.translation[1],
                this.rotation[6], this.rotation[7], this.rotation[8], this.translation[2],
                0, 0, 0, 1,
            )

            /*
            let xlate = new THREE.Vector3(ortho.translation[0], ortho.translation[1], ortho.translation[2])
            let rot = new THREE.Matrix4()
            rot.set(ortho.rotation[0], ortho.rotation[1], ortho.rotation[2], 0,
                ortho.rotation[3], ortho.rotation[4], ortho.rotation[5], 0,
                ortho.rotation[6], ortho.rotation[7], ortho.rotation[8], 0,
                    0, 0, 0, 1
            )
            let rotQ = new THREE.Quaternion()
            rotQ.setFromRotationMatrix(rot)
            let scale = new THREE.Vector3(1, 1, 1)

            orthoMat.compose(xlate, rotQ, scale)
            */
            return orthoMat
        }
    }

    export class ElementIdentifier {
        id: number;
        type: string;

        constructor(_id: number, _type: string) {
            this.id = _id;
            this.type = _type;
        }
    }

    export interface FrameMetadata {
        filename: string;
        originalFilename: string;
        frameId: number;
        width: number;
        height: number;
        derivedFrames: Map<string, FrameMetadata>;
    }

}

