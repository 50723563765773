import {Tool} from "./tool";
import {WireframeApplication} from "./wireframeApplication";
import {ActionMode} from "./actionMode";
import {WireframeElement} from "./wireframeElement";
import {WireframeElementType} from "./wireframeElementType";
import {SnapMode} from "./snapMode";
import {PV} from "../wireframe";
import {ContinuePolygon} from "./continuePolygon";
import {PolygonShapeProperty} from "../models/property/polygonShapeProperty";
import {PolygonShapePropertyValue} from "../models/property/polygonShapePropertyValue";
import {UndoAction} from "./undoAction";
import {UndoActionType} from "./undoActionType";
import {LockMode} from "./lockMode";
import {PropertyValue} from "../models/property/propertyValue";
import {ElementPickOperation} from "./elementPickOperation";
import {LogLevel} from "./logLevel";
import {RegistrationTransform} from "../registrationTransform";
import {VertexElement} from "./vertexElement";
import {PlaneElement} from "./planeElement";
import {EdgeElement} from "./edgeElement";
import * as THREE from 'three';
import PVUtils from "../pvUtils";
import {CameraElement} from "./cameraElement";
import {WidgetElement} from "./widgetElement";


class DragInfo {
    elements:WireframeElement[] = []
    plane:THREE.Plane
    centerStartPos:THREE.Vector3
    lastDragPos:THREE.Vector3
    vertices:WireframeElement[] = []
    vertexStates:any[] = []
    mousePlane:THREE.Plane
    planeStartPos:THREE.Vector3
}

export class GeometryTool extends Tool {

    elementMode: WireframeElementType = null;
    actionMode: ActionMode = null;

    lockMode: LockMode = LockMode.FORCE_TO_PLANE;
    autoLockMode: boolean = false;

    continueEdgeVertex: VertexElement = null;
    continueEdgeLine: EdgeElement = null;
    continuePolygon: ContinuePolygon = null;

    //contain edge names that selected for intersection two edges
    intersecEdge = [];

    //contain edge names that selected for registration
    registerEdge = [];


    constructor(app:WireframeApplication) {
        super(app)
        this.setElementMode(WireframeElementType.vertex)
        this.setActionMode(ActionMode.MODIFY)
        this.toolName = "GeometryTool"
        this.enableTransformTool = false
    }

    public onToolActivated() {

    }

    public on(event:string, ...args) {
        //console.log("Geomtool " + event, args)
    }

    hasMode(am: ActionMode, em: WireframeElementType) {
        return this.actionMode == am && this.elementMode == em;
    }

    setActionMode(am: ActionMode) {
        if (null == am) return
        if (this.actionMode === am) return
        //console.log("setActionMode " + this.actionMode + " => " + am)
        this.actionMode = am
        this.app.endElementPick();
        if (this.continueEdgeVertex) {
            this.continueEdgeLine.visible = this.actionMode == ActionMode.CREATE;
        }
        if (this.app.wireframeLayer) this.app.wireframeLayer.updateWireframeMaterials();

    }

    setElementMode(em: WireframeElementType) {
        if (null == em) return
        if (this.elementMode === em) return
        this.elementMode = em
        this.highlightableFunc = function(el) {
            if (em == WireframeElementType.vertex) {
                if (el instanceof VertexElement || el instanceof EdgeElement || el instanceof CameraElement || el instanceof WidgetElement) return true
            } else {
                return (el.pvObject.pvType == em)
            }
        }
        this.app.endElementPick();
    }

    private dragInfo:DragInfo

    private mouseDownEvent:MouseEvent

    public onWheelEvent(event: WheelEvent): boolean {

        if (this.keysDown["KeyV"]) {
            let nextElement = this.app.wireframeLayer.getNextElement(event.deltaY > 0)
            this.app.lookAtElements([nextElement])
            this.app.selectWireframeElement(null)
            this.app.selectWireframeElement([nextElement], true)
            if (this.app.cameraProjectionService.active) {
                this.app.cameraProjectionService.activateProjectionForElement(nextElement, nextElement.getCenter(true))
            }
            return true
        }
        return super.onWheelEvent(event);
    }

    public onMouseDoubleClick(event:MouseEvent) {
        let hits = this.getMouseOverWireframeElements()
        if (hits.length > 0 && hits[0].element instanceof CameraElement) {
            this.app.viewer.matchCamera(hits[0].element)
            return
        }
        super.onMouseDoubleClick(event)
    }

    public onKeyDown(event:KeyboardEvent) {
        //console.log("keydown", event)
        switch (event.code) {

            case "Space":
                if (event.ctrlKey) {
                    this.app.lookAtElements(this.app.selectedElements);
                } else {
                    this.app.lockWireframeElement(null);
                    if (this.app.selectedElements.length > 0) {
                        let el = this.app.selectedElements[0];
                        if (el.pvObject.pvType == WireframeElementType.plane || el.pvObject.pvType == WireframeElementType.edge) {
                            this.app.lockWireframeElement(el, true);
                        }
                    }
                }
                break;
            case "AltRight":
            case "AltLeft":
                this.setActionMode(ActionMode.CREATE);
                break;
            case "ShiftRight":
            case "ShiftLeft":
                if (event.altKey) {
                    this.setActionMode(ActionMode.DELETE);
                } else {
                    this.setActionMode(ActionMode.ALIGN);
                }
                break;
        }
        super.onKeyDown(event)
    }

    public onKeyUp(event:KeyboardEvent) {
        switch (event.code) {
            case "AltRight":
            case "AltLeft":
                // hitting ALT on a windows system will cause the input element to blur, so let's prevent that
                this.setActionMode(ActionMode.MODIFY);
                break;
            case "ShiftRight":
            case "ShiftLeft":
                this.setActionMode(ActionMode.MODIFY);
                break;
            case "KeyA":
                // select all wireframe elements
                if (event.ctrlKey) {
                    this.app.selectWireframeElement(this.getInteractiveElements(), true);
                }
                break;
            case "Digit1":
                if (event.ctrlKey) {
                    let that = this
                    let pickOp = new ElementPickOperation();
                    pickOp.message = "Select an Edge to lock, or Escape to cancel";
                    let pickFunc = function (el: WireframeElement) {
                        return el.pvObject.pvType == WireframeElementType.edge;
                    }
                    pickOp.isElementPickable.push(pickFunc);
                    pickOp.onElementPicked.push(function (el: WireframeElement) {
                        that.app.lockWireframeElement(null);
                        that.app.lockWireframeElement(el, true);
                        that.app.endElementPick();
                    });
                    this.app.startElementPick(pickOp);
                } else {2
                    this.setElementMode(WireframeElementType.vertex);
                }
                break;
            case "Digit2":
                if (event.ctrlKey) {
                    let that = this
                    let pickOp = new ElementPickOperation();
                    pickOp.message = "Select a Plane to lock, or Escape to cancel";
                    let pickFunc = function (el: WireframeElement) {
                        return (el instanceof PlaneElement && null == el.pvObject.parentId)
                    }
                    pickOp.isElementPickable.push(pickFunc);
                    pickOp.onElementPicked.push(function (el: WireframeElement) {
                        that.app.lockWireframeElement(null);
                        that.app.lockWireframeElement(el, true);
                        that.app.endElementPick();
                    });
                    this.app.startElementPick(pickOp);
                } else {
                    this.setElementMode(WireframeElementType.plane);
                }
                break;
            case "Digit3":
                this.setElementMode(WireframeElementType.plane);
                break;
            case "KeyC":
                if (event.ctrlKey) {
                    this.app.copyBuffer = [...this.app.selectedElements];
                }
                break;
            case "KeyV":
                if (event.ctrlKey) {
                    let newElements = this.app.wireframeLayer.cloneElements([...this.app.copyBuffer]);
                    this.app.selectWireframeElement(null)
                    this.app.selectWireframeElement(newElements, true)
                }
                break;
            case "Delete":
                this.app.startUndoEntry();
                this.app.blockGeometryUpdate = true;
                let elementsToDelete = [];
                this.app.selectedElements.forEach(function (el: WireframeElement) {
                    elementsToDelete.push(el);
                });
                let that = this
                elementsToDelete.forEach(function (el: WireframeElement) {
                    that.app.wireframeLayer.deleteWireframeElement(el, event.ctrlKey);
                });
                this.app.blockGeometryUpdate = false;
                break;
            case "Escape":
                this.app.endElementPick();
                this.cancelContinuePolygon();
                break;
            case "KeyD":
                if (event.ctrlKey) {
                    this.app.wireframeLayer.attachSurface();
                }
                break;
            case "KeyQ":
                if (event.ctrlKey) {
                    this.app.wireframeLayer.selectChildPlanes();
                }
                break;
        }
        super.onKeyUp(event)
    }



    cancelContinuePolygon() {
        if (this.continuePolygon) {
            this.app.wireframeLayer.deletePlane(this.app.findWireframeElement(WireframeElementType.plane, this.continuePolygon.plane.id) as PlaneElement, true);
        }
        this.continuePolygon = null;
    }

    continueEdgeFromVertex(vertex: VertexElement) {
        this.continueEdgeVertex = vertex;
        if (this.continueEdgeVertex) {
            this.continueEdgeVertex.updateMatrixWorld(true)
            //console.log("continueEdgeFromVertex " + vertex.pvObject.id, vertex);
            if (!this.continueEdgeLine) {

                let tempEdgeElement = new EdgeElement(new PV.Edge(-1, -1));
                tempEdgeElement.wireframeLayer = this.app.wireframeLayer
                tempEdgeElement.isSelected = false
                tempEdgeElement.isHighlighted = false
                tempEdgeElement.name = "continueEdge"
                tempEdgeElement.createGeometry()
                tempEdgeElement.updateMaterials()

                this.continueEdgeLine = tempEdgeElement;
                this.app.wireframeLayer.object.add(this.continueEdgeLine);
            }
            this.continueEdgeLine.visible = true;
        } else {
            //console.log("continueEdgeFromVertex ", vertex);
            if (this.continueEdgeLine) {
                this.continueEdgeLine.visible = false;
            }
        }
    }

    private updateContinueEdge() {
        //let t = performance.now()
        if (this.continueEdgeVertex) {
            // update continueEdge endpoints
            if (this.actionMode == ActionMode.CREATE) {
                this.continueEdgeLine.visible = true;
                var srcPos = this.continueEdgeVertex.getVert3(true);
                var viewPlane = new THREE.Plane();
                viewPlane.setFromNormalAndCoplanarPoint(this.getCameraVector(), srcPos);
                var dstPos = this.getMousePointOnPlane(this.mouse, viewPlane);
                if (dstPos) {
                    this.continueEdgeLine.updateEdgeGeometry(this.app.wireframeLayer.toWireframeCRS(srcPos), this.app.wireframeLayer.toWireframeCRS(dstPos));
                    //this.continueEdgeLine.updateMatrix()
                }
            } else {
                this.continueEdgeLine.visible = false;
            }
            //this.continueEdgeLine.updateGeometry()
            //wfOps.updateWireframeElementMaterials(this.continueEdgeLine as any);
            //console.log("continueEdgeVertex", srcPos, dstPos);
        }
        //console.log("updateContinueedge in " + (performance.now() - t))
    }

    updateContinuePolygon() {

        if (!this.continuePolygon) return;

        let cp: ContinuePolygon = this.continuePolygon;
        let parentPlane = this.app.wireframe.planes[cp.plane.parentId];
        if (!parentPlane) return;
        let alignEdgeId = cp.propVal.alignEdge.getElements(this.app.wireframeLayer)[0].id
        let alignEdge = this.app.findWireframeElement(WireframeElementType.edge, alignEdgeId) as EdgeElement;
        //this.app.wireframe.edges[alignEdgeId];
        let mousePlane = this.app.findWireframeElement(WireframeElementType.plane, parentPlane.id) as PlaneElement
        let newPos = this.getMousePointOnPlane(this.mouse, mousePlane.getPlane3(true));

        let midPoint: THREE.Vector3 = newPos.clone();
        midPoint.add(cp.startVert);
        midPoint.divideScalar(2);
        for (let vertId of cp.plane.vertexIds) {
            this.app.wireframe.setVert3(vertId, this.app.wireframeLayer.toWireframeCRS(midPoint))
        }

        let edgeLine = alignEdge.getLine3(true) //new THREE.Line3(this.app.getVert3(alignEdge.vertex1Id), this.app.getVert3(alignEdge.vertex2Id));
        let parallel: THREE.Line3 = new THREE.Line3(cp.startVert, cp.startVert.clone().add(edgeLine.delta(new THREE.Vector3())));

        let edgeLineNewPos = edgeLine.closestPointToPoint(newPos, false, new THREE.Vector3());
        let edgeLineStartPos = edgeLine.closestPointToPoint(cp.startVert, false, new THREE.Vector3());
        let yVec = new THREE.Vector3().subVectors(edgeLineNewPos, edgeLineStartPos);

        let parallelNewPos = parallel.closestPointToPoint(newPos, false, new THREE.Vector3());
        let xScale = parallelNewPos.distanceTo(newPos);
        cp.propVal.yScale = yVec.length() / 2;
        cp.propVal.xScale = xScale / 2;

        //cp.planeElement.wireframeLayer.snapToParentPlane(cp.planeElement)

        this.app.wireframeLayer.applyGeometryConstraints([cp.planeElement])
        cp.planeElement.updateGeometry()
        cp.planeElement.updateSupportingGeometry()
    }

    public onMouseDown(event:MouseEvent) {
        if (!super.onMouseDown(event)) return

            this.updateContinueEdge();
            let wireframeElementHits = this.getMouseOverWireframeElements();

            this.isHandlingMouseMove = false
            if (this.actionMode == ActionMode.MODIFY && event.buttons == 1) {
                let elementTypes = [];
                if (this.elementMode == WireframeElementType.vertex) {
                    elementTypes.push(WireframeElementType.vertex);
                    elementTypes.push(WireframeElementType.edge);
                } else if (this.elementMode == WireframeElementType.plane) {
                    elementTypes.push(WireframeElementType.plane);
                }
                var hits = wireframeElementHits.filter(function (hit) {
                    return elementTypes.includes(hit.element.pvObject.pvType);
                });

                if (hits.length > 0) {
                    var hitEl = hits[0].element;

                    // Capture vertex start positions of whatever is clicked on
                    let vertices = [];

                    var moveElements = [hitEl];
                    if (hitEl.isSelected) {
                        for (var i = 0; i < this.app.selectedElements.length; i++) {
                            var el = this.app.selectedElements[i];
                            if (this.elementMode == WireframeElementType.vertex && el.pvObject.pvType == WireframeElementType.plane) continue;
                            if (this.elementMode == WireframeElementType.plane && el.pvObject.pvType != WireframeElementType.plane) continue;
                            if (!moveElements.includes(el)) moveElements.push(el);
                        }
                    }

                    for (var i = 0; i < moveElements.length; i++) {
                        var el: WireframeElement = moveElements[i];
                        switch (el.pvObject.pvType) {
                            case (WireframeElementType.vertex):
                                vertices.push(el);
                                break;
                            case (WireframeElementType.edge):
                                vertices.push(this.app.findWireframeElement(WireframeElementType.vertex, (el as EdgeElement).pvObject.vertex1Id));
                                vertices.push(this.app.findWireframeElement(WireframeElementType.vertex, (el as EdgeElement).pvObject.vertex2Id));
                                break;
                            case (WireframeElementType.plane):
                                for (var j = 0; j < (el as PlaneElement).pvObject.vertexIds.length; j++) {
                                    var vertId = (el as PlaneElement).pvObject.vertexIds[j];
                                    vertices.push(this.app.findWireframeElement(WireframeElementType.vertex, vertId));
                                }
                                break;
                        }
                    }
                    this.dragInfo = new DragInfo()
                    this.dragInfo.elements = moveElements
                    for (var i = 0; i < vertices.length; i++) {
                        this.dragInfo.vertices.push(vertices[i]);
                        this.dragInfo.vertexStates.push(vertices[i].getState())
                        //vertices[i] = JSON.parse(JSON.stringify(vertices[i]));
                    }
                    console.log("moveElements ", moveElements);
                    this.isHandlingMouseMove = true
                    this.dragInfo.centerStartPos = hitEl.getCenter(true)
                    this.dragInfo.mousePlane = null
                    this.dragInfo.planeStartPos = null
                    /*
                    this.dragInfo = {
                        elements: moveElements,
                        centerStartPos: hitEl.getCenter(),
                        vertices: vertexStates,
                        mousePlane: null,
                        planeStartPos: null
                    };*/
                }
            }
            this.mouseDownEvent = event;
            return true
    }


    public onMouseMove(event) {

        //this.app.userActivityMonitor.registerUserActivity(false)
        super.onMouseMove(event)

        /*
        if (event.buttons == 1 && this.mouseDownEvent) {
            //console.log("mouseDrag", this.getMouseDrag())
            if (this.getMouseDrag().length() > this.mouseDragThreshold) {
                this.isMouseDrag = true;
            }
        }
        */

        if (this.continuePolygon) {
            this.updateContinuePolygon();
            return;
        }

        this.updateContinueEdge();

        if (this.isMouseDrag() && !this.isHandlingMouseMove) {
            return
        } else {

        }
        this.updateHighlightedElements();


        PVUtils.clearSelection()

        //console.log("move drag " + this.isMouseDrag, this.dragInfo)
        if (this.actionMode == ActionMode.MODIFY && event.buttons == 1 && this.dragInfo) {
            if (this.isMouseDrag()) {
                var point = new THREE.Vector3();
                var lockedElement = this.app.getFirstLockedElement(null);

                // special case for child polygons -- always move vertices on parent plane
                let isChildPoly = false;
                let parentPlane: PlaneElement = null;
                if (this.dragInfo.vertices.length == 1) {
                    let planes: number[] = this.app.wireframe.findPlanesForVertex(this.dragInfo.vertices[0].pvObject.id);
                    if (planes.length > 0) {
                        // don't do point cloud intersection if we're adjusting a child polygon
                        parentPlane = this.app.findWireframeElement(WireframeElementType.plane, planes[0]) as PlaneElement
                        isChildPoly = this.app.wireframe.planes[planes[0]].parentId != null;
                    }
                }

                if (!this.dragInfo.mousePlane) {
                    var planePoint = this.dragInfo.elements[0].getCenter(true);
                    this.dragInfo.mousePlane = new THREE.Plane();
                    this.dragInfo.mousePlane.setFromNormalAndCoplanarPoint(this.getCameraVector(), planePoint);

                    if (isChildPoly) {
                        this.dragInfo.mousePlane = parentPlane.getPlane3(true)// this.app.getPlane3(parentPlane.parentId);
                    } else if (lockedElement && this.app.snapMode == SnapMode.LOCKED_GEOMETRY) {
                        //console.log("mousedrag lockedElement", lockedElement);
                        this.dragInfo.mousePlane = new THREE.Plane();
                        this.dragInfo.mousePlane.setFromNormalAndCoplanarPoint(this.getCameraVector(), planePoint);
                        if (lockedElement.pvObject.pvType == WireframeElementType.plane) {
                            let plane = lockedElement as PlaneElement
                            /*
                            var planeNormal = new THREE.Vector3(
                                plane.pvObject.bestFitPlane.a,
                                plane.pvObject.bestFitPlane.b,
                                plane.pvObject.bestFitPlane.c
                            );
                            var vertId = plane.pvObject.vertexIds[0];
                            var vertEl = this.app.getVert3(vertId);
                            */
                            this.dragInfo.mousePlane = plane.getPlane3(true)

                        } else if (lockedElement instanceof EdgeElement) {
                            var line = lockedElement.getLine3(true)
                            var pointOnPlane = line.closestPointToPoint(this.app.viewer.camera.position, false, new THREE.Vector3());
                            var planeNormal = new THREE.Vector3().copy(this.app.viewer.camera.position).sub(pointOnPlane);
                            planeNormal.normalize();
                            this.dragInfo.mousePlane = new THREE.Plane();
                            this.dragInfo.mousePlane.setFromNormalAndCoplanarPoint(planeNormal, pointOnPlane);
                        }
                    } else if (this.app.snapMode == SnapMode.NONE) {
                        var planePoint = this.dragInfo.elements[0].getCenter(true);
                        this.dragInfo.mousePlane = new THREE.Plane();
                        this.dragInfo.mousePlane.setFromNormalAndCoplanarPoint(this.getCameraVector(), planePoint);
                    }
                }

                // determine mouse point
                if (!isChildPoly && this.dragInfo.vertices.length == 1 && this.app.snapMode == SnapMode.POINT_CLOUD) {
                    if (!this.dragInfo.planeStartPos) this.dragInfo.planeStartPos = this.dragInfo.vertices[0].getCenter(true)
                    point = this.getMousePointCloudIntersection();
                } else if (lockedElement && this.app.snapMode == SnapMode.LOCKED_GEOMETRY) {

                    if (lockedElement.pvObject.pvType == WireframeElementType.plane) {
                        point = this.getMousePointOnPlane(this.mouse, this.dragInfo.mousePlane);
                    } else if (lockedElement instanceof EdgeElement) {
                        var mousePoint = this.getMousePointOnPlane(this.mouse, this.dragInfo.mousePlane);
                        var line = lockedElement.getLine3(true)
                        point = line.closestPointToPoint(mousePoint, false, new THREE.Vector3());
                    }
                } else {
                    point = this.getMousePointOnPlane(this.mouse, this.dragInfo.mousePlane);
                }

                if (null != point) {
                    if (!this.dragInfo.planeStartPos) this.dragInfo.planeStartPos = point;
                    this.moveDraggedElement(this.dragInfo, point);
                }

                let previousParentPlaneId = null
                let childPlanes:PlaneElement[] = this.dragInfo.elements.filter((el) => {
                    return (el instanceof PlaneElement && null != el.pvObject.parentId)
                }) as PlaneElement[]
                if (childPlanes.length > 0) previousParentPlaneId = childPlanes[0].pvObject.parentId
                childPlanes = childPlanes.filter((el) => {
                    return el.pvObject.parentId == previousParentPlaneId
                })
                if (childPlanes.length != this.dragInfo.elements.length) childPlanes = []

                // reattach child polygons when dragged
                let newParentPlaneHit = this.raycastElements(this.app.wireframeLayer.wireframeElements.filter((el) => {
                    return el instanceof PlaneElement && null == el.pvObject.parentId && el.pvObject.id != previousParentPlaneId && el.isEditable
                })).find(() => { return true })

                if (childPlanes.length > 0 && newParentPlaneHit) {
                        let that = this
                        let newParentPlane = newParentPlaneHit.element as PlaneElement
                        this.dragInfo.elements.filter((el:WireframeElement) => {
                            return (el instanceof PlaneElement && null != el.pvObject.parentId)
                        }).forEach(function (el: PlaneElement) {
                            let plane: PV.Plane = el.pvObject as PV.Plane
                            let center: THREE.Vector3 = el.getCenter()
                            // rotate on to new plane, otherwise shape is projected onto new plane and distorted
                            let rot: THREE.Quaternion = new THREE.Quaternion();
                            let newParentPlane3: THREE.Plane = that.app.wireframe.getPlane3(newParentPlane.pvObject.id);
                            rot.setFromUnitVectors(that.app.wireframe.getPlane3(plane.id).normal, newParentPlane3.normal);

                            for (let vertId of plane.vertexIds) {
                                let vert: THREE.Vector3 = that.app.wireframe.getVert3(vertId).sub(center).applyQuaternion(rot).add(center)
                                that.app.wireframe.setVert3(vertId, vert);
                            }

                            plane.parentId = newParentPlane.pvObject.id;
                            that.app.wireframeLayer.updatePlaneEquation(plane);



                            /*
                            for (var i = 0; i < vertices.length; i++) {
                                this.dragInfo.vertices.push(vertices[i]);
                                this.dragInfo.vertexStates.push(vertices[i].getState())
                                //vertices[i] = JSON.parse(JSON.stringify(vertices[i]));
                            }
                            console.log("moveElements ", moveElements);
                            this.isHandlingMouseMove = true
                            this.dragInfo.centerStartPos = hitEl.getCenter(true)
                            this.dragInfo.mousePlane = null
                            this.dragInfo.planeStartPos = null
                            */
                        })
                        // update the start position for the drag operation, otherwise it will snap back
                        for (let i = 0; i < that.dragInfo.vertices.length; i++) {
                            let vertEl: WireframeElement = that.dragInfo.vertices[i];
                            that.dragInfo.vertexStates[i] = vertEl.getState()
                            /*
                            if (plane.vertexIds.includes(vertEl.pvObject.id)) {
                                let currentEl: WireframeElement = that.app.findWireframeElement(WireframeElementType.vertex, vertEl.pvObject.id);
                                that.dragInfo.vertices[i] = currentEl;
                                that.dragInfo.vertexStates[i] = currentEl.getState()
                            }
                            */
                        }
                        this.dragInfo.centerStartPos = newParentPlaneHit.intersect.point
                        this.dragInfo.planeStartPos = newParentPlaneHit.intersect.point

                }

            }
            event.stopImmediatePropagation();
        }

        if (this.hasMode(ActionMode.CREATE, WireframeElementType.vertex) || this.hasMode(ActionMode.CREATE, WireframeElementType.edge)) {
        }

        //wfOps.updateWireframeMaterials();
    }


    public onMouseUp(event:MouseEvent) {
        let that = this
        if (event.which === 3) {
            this.continueEdgeFromVertex(null);
        }

        // drawing edges
        if (event.button == 2) {
            this.continueEdgeVertex = null;
            return;
        }

        // drawing polygons
        if (this.continuePolygon) {
            if (event.button == 2) {
                this.cancelContinuePolygon();
            } else {
                this.continuePolygon = null;
            }
            return;
        }

        var el: WireframeElement = this.highlightIntersect ? this.highlightIntersect.element : null;

        if (!this.isMouseDrag() && this.actionMode != ActionMode.CREATE) {

            // Handle pick operation
            if (this.actionMode == ActionMode.ALIGN && !event.ctrlKey) {
                if (this.app.selectedElements.length > 0) {
                    if (el && el.pvObject.pvType == WireframeElementType.edge) {
                        console.log("Edge->Edge align");
                        this.app.startUndoEntry();
                        this.app.selectedElements.forEach(function (sel) {
                            if (sel.pvObject.pvType == WireframeElementType.edge && el != sel) {
                                that.app.wireframeLayer.align(sel as EdgeElement,
                                    el as EdgeElement,
                                    that.app.getFirstLockedElement(WireframeElementType.plane) as PlaneElement);
                            }
                        });
                    }
                }
            } else {

                if (event.button == 0) {
                    if (el) {
                        if (!event.ctrlKey) this.app.selectWireframeElement(null);
                        this.app.selectWireframeElement([el], !el.isSelected);
                    } else {
                        if (!event.ctrlKey)
                            this.app.selectWireframeElement(null);
                    }
                }
            }
        }

        var lockedElement = this.app.getFirstLockedElement(null);
        //let geoCoord = new THREE.Vector3()
        let drawingPlane = this.getDrawingPlane()
        let pos:THREE.Vector3 = drawingPlane.point;

        if (this.app.snapMode == SnapMode.LOCKED_GEOMETRY && lockedElement) {
            if (lockedElement instanceof PlaneElement) {
                pos = this.getMousePointOnPlane(this.mouse, lockedElement.getPlane3(true));
            }
        } else {
            // intersect point cloud
            pos = this.getMousePointCloudIntersection();
            if (pos != null) {
                //geoCoord = pos;
                //geoCoord.pointIndex = pos.pointIndex;
                //geoCoord.hframes = pos.hframes;
                //viewer.clickedPoint3D = geoCoord;
            }
            // try geometry raycastElements
            if (pos == null) {

                let planeHits = this.raycastElements(this.app.wireframeLayer.wireframeElements.filter((el) => {
                    return (el instanceof PlaneElement)
                }))
                if (planeHits.length > 0) pos = planeHits[0].intersect.point
                if (pos == null && this.app.sceneManager.orthoImageLayer.isInteractionEnabled) {
                    pos = drawingPlane.point
                }
            }
        }

        if (null != pos) {
            this.printPointDebug(pos)
        }
        /*
        if (this.app.actionMode == ActionMode.INSPECT) {

            // the user is inspecting this point
            if (geoCoord && !this.isEmptyObject(geoCoord)) {
                this.createInspectionPoint.bind(this)();
                this.moveInspectionPoint.bind(this)(pos);
                this.app.pointInspected(geoCoord);
            }
        }
        */

        //if (geoCoord && !geoCoord.hframes) geoCoord.hframes = [];

        if (this.hasMode(ActionMode.CREATE, WireframeElementType.vertex) && !this.isMouseDrag()) {
            //var pos: any = new THREE.Vector3();
            this.app.startUndoEntry();
            if (el && el.pvObject.pvType == WireframeElementType.vertex) {
                // connect vertex and continue

                if (this.continueEdgeVertex) {
                    if (el == this.continueEdgeVertex) return;
                    var edge = new PV.Edge(this.continueEdgeVertex.pvObject.id, el.pvObject.id);
                    if (!this.app.wireframe.findEdge(edge.vertex1Id, edge.vertex2Id) && !(edge.vertex1Id == edge.vertex2Id)) {
                        this.app.wireframe.addEdge(edge);
                        this.app.wireframeLayer.addEdge(edge, null);

                        this.app.undoEnabled = false
                        let planeEl = that.app.wireframeLayer.createPlaneFromVert(edge.vertex1Id);
                        this.app.undoEnabled = true

                        let planeRaycastHits = this.raycastElements(this.app.wireframeLayer.wireframeElements.filter((el) => {
                            return (el instanceof PlaneElement && el.pvObject.parentId == null)
                        }))

                        if (planeEl && planeRaycastHits.length > 0) {
                            let planeAttachAlignmentThreshold = .965
                            let planeAttachDistanceThreshold = 3.33
                            that.app.wireframeLayer.alignWithGravity(planeEl.pvObject);

                            this.app.wireframeLayer.updatePlaneEquation(planeEl.pvObject);
                            console.log("created plane from loop, mouseover", planeRaycastHits);
                            let vertDistance = planeRaycastHits[0].intersect.distance;
                            let planeHits = [];

                            for (let i = 0; i < planeRaycastHits.length; i++) {
                                let distance = Math.abs(planeRaycastHits[i].intersect.distance - vertDistance);
                                if (distance < planeAttachDistanceThreshold) {
                                    planeHits.push({
                                        distance: distance,
                                        element: planeRaycastHits[i].element
                                    });
                                }
                            }

                            planeHits.sort(function (a, b) {
                                return a.distance - b.distance;
                            })

                            for (let i = 0; i < planeHits.length; i++) {
                                let hit = planeHits[i];
                                if (!hit.element.isEditable) continue;
                                let pp: PV.Plane = this.app.wireframe.planes[hit.element.pvObject.id];
                                let parentPlane: THREE.Plane = this.app.wireframe.getPlane3(pp.id);
                                let childPlane: THREE.Plane = this.app.wireframe.getPlane3(planeEl.pvObject.id);
                                let dp = Math.abs(childPlane.normal.dot(parentPlane.normal));
                                //console.log("parent/child/dp", [parentPlane, childPlane, dp]);
                                if (dp > planeAttachAlignmentThreshold) {
                                    let vertsInsidePoly: boolean[] = that.app.wireframeLayer.areVertsInPolygon(planeEl.pvObject.vertexIds, pp);
                                    if (vertsInsidePoly.filter(function (inside) {
                                        return !inside;
                                    }).length < 1) {
                                        console.log("Autoattaching child->parent ", [childPlane, parentPlane]);
                                        planeEl.pvObject.parentId = hit.element.pvObject.id;
                                        break;
                                    }
                                }
                            }
                        }
                        if (planeEl) {
                            planeEl.updateGeometry()
                            planeEl.updateMaterials()
                        }
                        this.continueEdgeFromVertex(el as VertexElement);
                    }
                } else {
                    this.continueEdgeFromVertex(el as VertexElement);
                }
                this.updateContinueEdge();
                this.app.selectWireframeElement(null);
            } else if (el && el instanceof EdgeElement && el.isEditable && !el.isDisabled) {
                // split edge and continue
                let edge: PV.Edge = el.pvObject;
                let line = el.getLine3(true)
                let point = line.closestPointToPoint(this.highlightIntersect.intersect.point, true, new THREE.Vector3());
                let vert = new PV.Vertex()
                this.app.wireframe.addVertex(vert)
                this.app.wireframe.setVert3(vert.id, this.app.wireframeLayer.toWireframeCRS(point))
                let vertElement = this.app.wireframeLayer.addVertex(vert)

                let edge1 = new PV.Edge(vert.id, edge.vertex1Id)
                this.app.wireframe.addEdge(edge1)
                this.app.wireframeLayer.addEdge(edge1, null)

                let edge2 = new PV.Edge(vert.id, edge.vertex2Id)
                this.app.wireframe.addEdge(edge2)
                this.app.wireframeLayer.addEdge(edge2, null)

                let planeIds: number[] = this.app.wireframe.findPlanesForEdge(edge.id);
                for (let i = 0; i < planeIds.length; i++) {
                    let plane: PV.Plane = this.app.wireframe.planes[planeIds[i] + ""];
                    this.app.wireframe.addVertexToPlane(plane, vert, edge.vertex1Id, edge.vertex2Id);
                    //console.log("split edge plane", plane.vertexIds.toString());
                    this.app.wireframeLayer.createPlaneEdgesFromVerts(plane);
                }
                //console.log("split-edge planes", planeIds);
                this.app.wireframeLayer.deleteWireframeElement(el, false);
                if (this.continueEdgeVertex) {
                    //console.log("continue split edge verts " + this.app.continueEdgeVertex.pvObject.id + " " + vertElement.pvObject.id);
                    var edge3 = new PV.Edge(this.continueEdgeVertex.pvObject.id, vertElement.pvObject.id);
                    this.app.wireframe.addEdge(edge3);
                    this.app.wireframeLayer.addEdge(edge3, null);
                }
                this.continueEdgeFromVertex(vertElement);
                this.app.selectWireframeElement(null);
            } else if (event.ctrlKey) {
                let planeHits = this.raycastElements(this.app.wireframeLayer.wireframeElements.filter((el) => {
                    return (el instanceof PlaneElement && el.pvObject.parentId == null)
                }))
                if (planeHits.length > 0) {
                    let planeHit = planeHits[0]
                    console.log("planeHit", planeHit);
                    let plane: PV.Plane = planeHit.element.pvObject as PV.Plane;
                    if (!this.continuePolygon) {
                        this.continuePolygon = new ContinuePolygon();
                        let cp: ContinuePolygon = this.continuePolygon;

                        cp.plane = new PV.Plane();
                        if (planeHit.element.isEditable) {
                            cp.plane.parentId = plane.id;
                        }

                        cp.startVert = planeHit.intersect.point

                        let vertPlane = planeHit.element as PlaneElement
                        let planeQuat = new THREE.Quaternion()
                        planeQuat.setFromUnitVectors(new THREE.Vector3(0, 1, 0), vertPlane.getPlane3(true).normal)
                        for (let i = 0; i < 4; i++) {
                            let vert = new PV.Vertex();
                            this.app.wireframe.addVertex(vert);
                            let v = new THREE.Vector3(Math.cos(Math.PI * 2 * i / 4), 0, Math.sin(Math.PI * 2 * i / 4))
                            v.applyQuaternion(planeQuat)
                            v.add(cp.startVert)
                            this.app.wireframe.setVert3(vert.id, this.app.wireframeLayer.toWireframeCRS(v));
                            let vertEl = this.app.wireframeLayer.addVertex(vert);
                            //vertEl.setPosition(this.continuePolygon.startVert, true)
                            cp.plane.vertexIds.push(vert.id);
                            //console.log("added vertex", vert);
                        }
                        //wfOps.redrawWireframe()

                        this.app.wireframe.addPlane(cp.plane);
                        this.app.wireframeLayer.createPlaneEdgesFromVerts(cp.plane);
                        cp.planeElement = this.app.wireframeLayer.addPlane(cp.plane)
                        that.app.wireframeLayer.alignWithGravity(cp.plane);
                        cp.plane.bestFitPlane = plane.bestFitPlane.clone();

                        let prop = this.app.wireframe.findProperty(PolygonShapeProperty, true);
                        let val: PolygonShapePropertyValue = prop.getDefaultValue();
                        val.NumSides = 4;
                        val.alignEdge.setElement(this.app.wireframe.edges[this.app.wireframe.getPrimaryEdgeId(plane)])
                        val.xScale = 1;
                        val.yScale = 1;
                        prop.setValue(cp.planeElement, val);
                        (cp as any).prop = prop;
                        cp.propVal = val;

                        //that.app.wireframeLayer.redrawWireframe()
                        this.updateContinuePolygon();
                    }
                }
            } else if (pos) {
                let vert = new PV.Vertex();
                this.app.wireframe.addVertex(vert);
                this.app.wireframe.setVert3(vert.id, this.app.wireframeLayer.toWireframeCRS(pos))
                let vertexElement = this.app.wireframeLayer.addVertex(vert);
                //vertexElement.setPosition(geoCoord, true)
                if (this.continueEdgeVertex) {
                    var edge = new PV.Edge(this.continueEdgeVertex.pvObject.id, vertexElement.pvObject.id);
                    if (!this.app.wireframe.findEdge(edge.vertex1Id, edge.vertex2Id)) {
                        this.app.wireframe.addEdge(edge);
                        this.app.wireframeLayer.addEdge(edge, null);
                    }
                }
                this.continueEdgeFromVertex(vertexElement);
                this.updateContinueEdge();
            }
        } else if (this.actionMode == ActionMode.MODIFY) {
            if (this.dragInfo && this.isMouseDrag()) {
                // create undo record
                this.app.startUndoEntry();

                for (let i = 0; i < this.dragInfo.vertices.length; i++) {
                    let undo = new UndoAction(UndoActionType.MODIFY, this.dragInfo.vertices[i]);
                    undo.state = this.dragInfo.vertexStates[i];
                    this.app.appendUndoAction(undo);
                }
                this.dragInfo = null;
            } else {
                this.dragInfo = null;
            }
        }

        this.mouseDownEvent = null;

        this.app.wireframeLayer.recomputeSurfaces()
        this.onClick(event)
    }

    onClick(event:MouseEvent) {
        let that = this
        this.app.activeTool.onMouseClick(event)
        var el: WireframeElement = this.app.highlightedElements[0];
        if (el) console.log("onClick " + el.name, el.pvObject);
        var elementName;

        if (el && el.pvObject.pvType == WireframeElementType.edge && this.actionMode == ActionMode.SCALE) {
            that.app.wireframeLayer.redrawWireframe();
            (window as any).showScaleDialog();
            return;
        }

        if (el && el.pvObject.pvType == WireframeElementType.edge && this.actionMode == ActionMode.INTERSECT) {
            this.intersecEdge.push(el);
            if (this.intersecEdge.length == 2) {
                this.app.startUndoEntry();
                that.app.wireframeLayer.addVertexByTwoEdgeIntersection(this.intersecEdge[0], this.intersecEdge[1]);
                this.intersecEdge = [];
                this.actionMode = ActionMode.MODIFY;
                that.app.wireframeLayer.redrawWireframe();
            }
            if (this.intersecEdge.length > 2)
                this.intersecEdge = [];
            return;
        }

        if (el && (this.actionMode == ActionMode.DELETE)) {
            elementName = el.name;
            if (el.pvObject.pvType == WireframeElementType.edge) {
                this.app.startUndoEntry();
                this.app.wireframeLayer.deleteWireframeElement(el, true);
            } else if (el.pvObject.pvType == WireframeElementType.vertex) //it is vertex
            {
                this.app.startUndoEntry();
                this.app.wireframeLayer.deleteWireframeElement(el);
            } else if (el.pvObject.pvType == WireframeElementType.plane) {
                this.app.startUndoEntry();
                this.app.wireframeLayer.deleteWireframeElement(el, event.ctrlKey);
            } else if (this.app.viewMode == "removeMeasurement" && ((el as any).extraVertex == true || (el as any).extraEdge == true)) {

                this.app.wireframeLayer.deleteWireframeElement(el);
            }
            this.app.wireframeTopologyChanged();
        } else if (el && this.hasMode(ActionMode.DELETE, WireframeElementType.vertex) && el.pvObject.pvType == WireframeElementType.vertex) {
            this.app.startUndoEntry();
            this.app.wireframeLayer.deleteWireframeElement(el);
        }
    }

    private moveDraggedElement(dragInfo:DragInfo, newPos:THREE.Vector3) {
        if (!dragInfo.lastDragPos) dragInfo.lastDragPos = newPos;
        let t = performance.now()
        dragInfo.lastDragPos = newPos;
        let totalDelta = newPos.clone();
        totalDelta.sub(dragInfo.planeStartPos);
        //console.log("totalDelta", totalDelta.length())
        var lockedElement = this.app.lockedElements.length > 0 ? this.app.lockedElements[0] : null;
        let lockPlane:THREE.Plane;
        let lockLine: THREE.Line3;
        if (this.app.snapMode == SnapMode.LOCKED_GEOMETRY && lockedElement && this.lockMode == LockMode.FORCE_TO_PLANE) {
            if (lockedElement instanceof PlaneElement) {
                lockPlane = lockedElement.getPlane3(true)
            } else if (lockedElement instanceof EdgeElement) {
                lockLine = lockedElement.getLine3(true)
            }
        }

        let changedWireframeElements:WireframeElement[] = [];

        for (var i = 0; i < dragInfo.vertices.length; i++) {
            //var vertEl = this.app.findWireframeElement(WireframeElementType.vertex, dragInfo.vertices[i].pvObject.id) as VertexElement;
            let vertEl = dragInfo.vertices[i] as VertexElement
            if (!vertEl) continue;
            changedWireframeElements.push(vertEl);
            let newVertPos = this.app.wireframeLayer.toWorldCRS(new THREE.Vector3().copy(dragInfo.vertexStates[i].pvObject as any));
            newVertPos.add(totalDelta);
            if (this.app.snapMode == SnapMode.LOCKED_GEOMETRY) {
                if (lockPlane) {
                    newVertPos = lockPlane.projectPoint(newVertPos, new THREE.Vector3());
                } else if (lockLine) {
                    newVertPos = lockLine.closestPointToPoint(newVertPos, false, new THREE.Vector3());
                }
            }
            let isPolygonVert = false;
            if (this.elementMode != WireframeElementType.plane) {
                let planeIds: number[] = this.app.wireframe.findPlanesForVertex(vertEl.pvObject.id);
                if (planeIds.length == 1) {
                    let plane: PV.Plane = this.app.wireframe.planes[planeIds[0]];
                    let prop: PolygonShapeProperty = this.app.wireframe.findProperty(PolygonShapeProperty, true) as PolygonShapeProperty;
                    let pv: PropertyValue = prop.getPropertyValue(plane);
                    if (pv) {
                        isPolygonVert = true;
                        let planeEl = this.app.findWireframeElement(WireframeElementType.plane, plane.id) as PlaneElement
                        changedWireframeElements.push(planeEl);
                        let plane3: THREE.Plane = planeEl.getPlane3(true)
                        newVertPos = plane3.projectPoint(newVertPos, new THREE.Vector3());
                        prop.moveVertex(vertEl, pv.value, planeEl, this.app.wireframeLayer.toWireframeCRS(newVertPos));
                    }
                }
            }
            if (isPolygonVert) continue;
            this.app.wireframeLayer.moveVertex(vertEl, this.app.wireframeLayer.toWireframeCRS(newVertPos), false, false);
        }
        let affectedElements = [ ...changedWireframeElements ]
        changedWireframeElements.forEach((el) => affectedElements.push(...el.getDependentGeometry()))
        this.app.wireframeLayer.applyGeometryConstraints(affectedElements);
        changedWireframeElements.forEach((el) => el.updateDependentGeometry())
        this.app.wireframeChanged();
        try {
            try {
                //this.app.adjustSection.resetButton();
            } catch (e) {
                console.log("adjustmode error", e);
            }
        } catch (e) {
            console.log("adjustmode error", e);
        }
    }

    performWireframeRegistration() {
        let that = this
        for (let i = 0; i < this.app.selectedElements.length; i++) {
            if (this.app.selectedElements[i].pvObject.pvType == WireframeElementType.edge)
                this.registerEdge.push(this.app.selectedElements[i]);
        }

        if (this.registerEdge.length >= 4) {
            this.app.startUndoEntry();
            this.registerWireframeUsingRelatedEdges(this.registerEdge);
            //this.app.actionMode = ActionMode.MODIFY;
            that.app.wireframeLayer.redrawWireframe();
        } else {
            this.app.log("Please select at least 4 edges", LogLevel.DANGER);
        }
        this.registerEdge = [];
    }

    registerWireframeUsingRelatedEdges(registerEdges: EdgeElement[]) {
        let that = this
        let currentCoordinateVertices: PV.Vertex[] = [];
        let currentCoordinateVertexIds: number[] = [];
        let importedVertices: PV.Vertex[] = [];
        let structureVertices: PV.VertexMap = {};
        let verticesToRemove: PV.VertexMap = {};

        for (let i = 0; i < registerEdges.length; i++) {
            let v1Id = registerEdges[i].pvObject.vertex1Id;
            let v2Id = registerEdges[i].pvObject.vertex2Id;

            if (v1Id < v2Id) {
                importedVertices.push(this.app.wireframe.vertices[v1Id]);
                currentCoordinateVertices.push(this.app.wireframe.vertices[v2Id]);
                currentCoordinateVertexIds.push(v2Id);
            } else {
                importedVertices.push(this.app.wireframe.vertices[v2Id]);
                currentCoordinateVertices.push(this.app.wireframe.vertices[v1Id]);
                currentCoordinateVertexIds.push(v1Id);
            }
        }

        for (let key in this.app.wireframe.vertices) {
            let keyInt: number = Number(key);
            if (currentCoordinateVertexIds.includes(keyInt)) {
                verticesToRemove[keyInt] = this.app.wireframe.vertices[keyInt];
            } else {
                structureVertices[keyInt] = this.app.wireframe.vertices[keyInt];
            }
        }

        let registrationTransform: RegistrationTransform = new RegistrationTransform();
        let success: boolean = registrationTransform.calculateTransform(currentCoordinateVertices, importedVertices);
        let transformedVertices: PV.VertexMap = {};

        if (success) {
            transformedVertices = registrationTransform.getTransformedVertices(structureVertices);
            for (let key in transformedVertices) {
                let keyInt: number = Number(key);
                this.app.wireframe.vertices[keyInt].x = transformedVertices[keyInt].x;
                this.app.wireframe.vertices[keyInt].y = transformedVertices[keyInt].y;
                this.app.wireframe.vertices[keyInt].z = transformedVertices[keyInt].z;
            }
            for (let i = 0; i < registerEdges.length; i++) {
                this.app.wireframeLayer.deleteWireframeElement(registerEdges[i]);
            }
            for (let key in verticesToRemove) {
                let keyInt: number = Number(key);
                let vertexElement: WireframeElement = this.app.findWireframeElement(WireframeElementType.vertex, verticesToRemove[keyInt].id);
                this.app.wireframeLayer.deleteWireframeElement(vertexElement);
            }

            that.app.wireframeLayer.redrawWireframe();
        } else {
            this.app.log("Wireframe registration failed", LogLevel.DANGER);
        }
        this.app.selectedElements = [];
    }
}
