import {
    Component
} from '@angular/core';
import {VertexFilterVerifiedModel} from "../../models/vertexFilters/vertexFilterVerified.model";
import InteractiveFilterExpressionComponent from "../interactiveFilterExpression/interactiveFilterExpression.component";
import {InteractiveFilterTypeModel} from "../../models/interactive/interactiveFilterType.model";
import {VertexElementModel} from "../../models/vertexElements/vertexElement.model";
import {VertexFilterTypes} from "../../models/vertexFilters/vertexFilterTypes.enum";
import {VertexFilterSeenModel} from "../../models/vertexFilters/vertexFilterSeen.model";

@Component({
    selector: 'ptv-vertex-filter-expression',
    styles: [require('./vertexFilterExpression.component.scss')],
    template: require('./vertexFilterExpression.component.html')
})
export default class VertexFilterExpressionComponent extends InteractiveFilterExpressionComponent<VertexElementModel> {
    onAddFilterClicked(event, filterType: InteractiveFilterTypeModel) {
        if(!filterType) return;
        let filterTypeName = filterType.name;
        switch(filterTypeName){
            case VertexFilterTypes.VertexVerification: {
                let vertexFilterVerifiedModel = new VertexFilterVerifiedModel();
                this.addFilter(vertexFilterVerifiedModel);
                vertexFilterVerifiedModel.focused = true;
            }
            break;
            
            case VertexFilterTypes.VertexSeen: {
                let vertexFilterSeenModel = new VertexFilterSeenModel();
                this.addFilter(vertexFilterSeenModel);
                vertexFilterSeenModel.focused = true;
            }
            break;
        }
    }
}