import WireframeLayer from "./wireframeLayer";
import ProjectionUtils from "../projectionUtils";
import {WireframeElement} from "./wireframeElement";
import {PV} from "../wireframe";
import {WireframeElementType} from "./wireframeElementType";
import {LabelProperty} from "../models/property/labelProperty";
import {LayerProperty} from "../models/property/layerProperty";
import * as THREE from 'three';
import {WireframeApplication} from "./wireframeApplication";

export class Poly {
    outerRing:THREE.Vector3[] = []
    innerRings:THREE.Vector3[][] = []
}

export class ROILayer extends WireframeLayer {

    public polygons:Poly[] = []

    lowerVerts:PV.Vertex[] = []
    upperVerts:PV.Vertex[] = []
    constructor(app:WireframeApplication, name:string, label:string) {
        super(app, name, label)
        this.planeDetectionEnabled = false
        this.supportsEditing = false
        this.supportsInteraction = false
        this.isExpandable = false
    }

    extractPolygons(roiGeoJSON:any) {
        this.polygons = []
        for (let geoPolyId = 0; geoPolyId < roiGeoJSON.length; geoPolyId++) {
            let geoPoly: any = roiGeoJSON[geoPolyId];
            if (geoPoly.length < 1) break
            let poly = new Poly()
            this.polygons.push(poly)
            let outerGeoJson:[] = geoPoly[0]
            for (let vertId = 0; vertId < outerGeoJson.length; vertId++) {
                poly.outerRing.push(new THREE.Vector3(outerGeoJson[vertId][1], outerGeoJson[vertId][0], outerGeoJson[vertId][2]))
            }
            if (geoPoly.length > 1) {
                for (let polyId = 1; polyId < geoPoly.length; polyId++) {
                    let innerRing = []
                    poly.innerRings.push(innerRing)
                    let innerGeoJson = geoPoly[polyId]
                    for (let vertId = 0; vertId < innerGeoJson.length; vertId++) {
                        poly.outerRing.push(new THREE.Vector3(innerGeoJson[vertId][1], innerGeoJson[vertId][0], innerGeoJson[vertId][2]))
                    }
                }
            }

        }
        console.log("extracted roi polys", this.polygons)
    }

    setVerticalExtents(bottomECEF:THREE.Vector3, topECEF:THREE.Vector3) {
        let centerECEF = ProjectionUtils.toGeocentric(this.getBoundingBox().getCenter(new THREE.Vector3()))
        let gravity = centerECEF.clone().normalize()
        let lowerBound: THREE.Plane = new THREE.Plane().setFromNormalAndCoplanarPoint(gravity, bottomECEF);
        let upperBound: THREE.Plane = new THREE.Plane().setFromNormalAndCoplanarPoint(gravity, topECEF);
        let that = this
        this.upperVerts.forEach((vert) => {
            let v = that.wireframe.getVert3(vert.id)
            that.wireframe.setVert3(vert.id, upperBound.projectPoint(v, new THREE.Vector3()))
        })
        this.lowerVerts.forEach((vert) => {
            let v = that.wireframe.getVert3(vert.id)
            that.wireframe.setVert3(vert.id, lowerBound.projectPoint(v, new THREE.Vector3()))
        })
    }

    getBoundingBox():THREE.Box3 {
        let bbox = new THREE.Box3()
        this.polygons.forEach((poly) => {
            poly.outerRing.forEach((v) => { bbox.expandByPoint(v)} )
        })
        return bbox
    }

    createGeometry() {
        let centerECEF = ProjectionUtils.toGeocentric(this.getBoundingBox().getCenter(new THREE.Vector3()))
        let gravity = centerECEF.clone().normalize()

        let lowerBound: THREE.Plane = new THREE.Plane().setFromNormalAndCoplanarPoint(gravity, centerECEF);
        let upperBound: THREE.Plane = new THREE.Plane().setFromNormalAndCoplanarPoint(gravity, centerECEF.clone().add(gravity.clone().setLength(1)));
        let elements: WireframeElement[] = []
        let hiddenElements: WireframeElement[] = []

        let vertParams = {isVisible: false}

        this.polygons.forEach((poly) => {
            let lowerRing:PV.Vertex[] = []
            let upperRing:PV.Vertex[] = []
            for (let vertId = 0; vertId < poly.outerRing.length; vertId++) {
                let ecefVert = ProjectionUtils.toGeocentric(poly.outerRing[vertId])
                let vertPos: THREE.Vector3 = lowerBound.projectPoint(ecefVert, new THREE.Vector3());

                // geojson polys should have same start/end verts
                if (vertId == poly.outerRing.length - 1) {
                    let dist: number = this.wireframe.getVert3(lowerRing[0].id).distanceTo(vertPos);
                    if (dist < .001) break;
                }
                let lowerVert: PV.Vertex = new PV.Vertex();
                this.wireframe.addVertex(lowerVert);
                this.wireframe.setVert3(lowerVert.id, vertPos);
                elements.push(this.addVertex(lowerVert, vertParams));
                lowerRing.push(lowerVert);

                let upperVert: PV.Vertex = new PV.Vertex();
                this.wireframe.addVertex(upperVert);
                this.wireframe.setVert3(upperVert.id, upperBound.projectPoint(vertPos, new THREE.Vector3()));
                elements.push(this.addVertex(upperVert, vertParams));
                upperRing.push(upperVert);
            }
            this.lowerVerts.push(...lowerRing)
            this.upperVerts.push(...upperRing)


            let upperLoop: number[] = [];
            let lowerLoop: number[] = [];
            for (let i = 0; i < lowerRing.length; i++) {
                upperLoop.push(upperRing[i].id);
                lowerLoop.push(lowerRing[i].id);
                let plane: PV.Plane = new PV.Plane();
                plane.vertexIds = [lowerRing[i].id,
                    lowerRing[(i + 1) % lowerRing.length].id,
                    upperRing[(i + 1) % upperRing.length].id,
                    upperRing[i].id
                ];

                this.createPlaneEdgesFromVerts(plane);
                this.wireframe.addPlane(plane);

                elements.push(this.addPlane(plane));
                for (let edgeId of plane.edgeIds) {
                    elements.push(this.findWireframeElement(WireframeElementType.edge, edgeId));
                }
            }

            let upperPlane: PV.Plane = new PV.Plane();
            upperPlane.vertexIds = upperLoop;
            this.createPlaneEdgesFromVerts(upperPlane);
            this.wireframe.addPlane(upperPlane);
            hiddenElements.push(this.addPlane(upperPlane));

            let lowerPlane: PV.Plane = new PV.Plane();
            lowerPlane.vertexIds = lowerLoop;
            this.createPlaneEdgesFromVerts(lowerPlane);
            this.wireframe.addPlane(lowerPlane);
            hiddenElements.push(this.addPlane(lowerPlane));

        })

        let labelProp: LabelProperty = this.wireframe.findProperty(LabelProperty, true);
        let layerProp: LayerProperty = this.wireframe.findProperty(LayerProperty, true);
        hiddenElements.forEach(function (el: WireframeElement) {
            el.isPickable = false;
            el.visible = false;
            layerProp.setValue(el, "roi");
            //labelProp.setValue(el, null);
        });
        elements.forEach(function (el: WireframeElement) {
            el.isDisabled = true
            el.isEditable = false;
            el.isPickable = false;
            layerProp.setValue(el, "roi");
            //labelProp.setValue(el, null);

        })
    }

    loadFromGeoJSON(roiGeoJSON:any) {
        if (null == roiGeoJSON) return
        try {
            this.extractPolygons(roiGeoJSON)
            this.createGeometry()
        } catch (e) {
            console.error("Error creating ROI", e);
        }

    }
}