import {WireframeElement} from "./wireframeElement";
import {ElementReference} from "../models/property/elementReference";

export class ElementPickOperation {
    testPickable(el: WireframeElement) {
        for (let func of this.isElementPickable) {
            if (!func(el)) return false;
        }
        return true;
    }

    elementPicked(el: WireframeElement) {
        for (let func of this.onElementPicked) {
            try {
                func(el);
            } catch (e) {
            }
        }
    }

    complete() {
        for (let func of this.onComplete) {
            try {
                func();
            } catch (e) {
            }
        }
        this.isComplete = true;
    }

    message: string;
    isElementPickable: Function[] = [];
    onElementPicked: Function[] = [];
    onComplete: Function[] = [];
    isComplete: Boolean = false;
    currentValue: ElementReference;
    restorePickableFunc:Function
}