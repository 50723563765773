import {Property} from "./property";

export class PropertyMetadata {
    id: number;
    index: number;
    property: Property;
    count: number = 0;
    showSettings: boolean = false;
    value: any;
    multipleValues: any = null;
}