export class PropertyValue {
    id: number;
    value: any;

    constructor(id: number, value: any) {
        this.id = id;
        this.value = value;
    }

    public clone(): PropertyValue {
        let val = this.value;
        if (this.value.clone) {
            val = this.value.clone()
        } else if (typeof val === 'object' || Array.isArray(val)) {
            val = JSON.parse(JSON.stringify(val));
        }  else {
            val = this.value
        }
        let pv = new PropertyValue(this.id, val);
        return pv;
    }
}