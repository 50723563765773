import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import WireframeElementCollection from "../../../collections/wireframeElements/wireframeElement.collection";

@Component({
    selector: 'ptv-vertex-preview-manager-toolbar',
    styles: [require('./vertexPreviewManagerToolbar.component.scss')],
    template: require('./vertexPreviewManagerToolbar.component.html')
})
export default class VertexPreviewManagerToolbarComponent {
    @Input() public vertexElementCollection: WireframeElementCollection;
    @Output() viewChanged = new EventEmitter<string>();
    viewOptions = ['tiles', 'cameras'];

    constructor() {
    }

    onViewOptionSelected(viewOption:string): void {
        this.triggerViewChanged(viewOption);
    }

    triggerViewChanged(viewOption:string){
        this.viewChanged.emit(viewOption)
    }
}