import {InteractiveModel} from "../interactive/interactive.model";
import {BrandingModel} from "../brandings/branding.model";

export interface Organization {
    name: string;
    urlRules: string[];
    routeRules: string[];
    branding: BrandingModel;
}

export class OrganizationModel extends InteractiveModel implements Organization {
    name: string;
    urlRules: string[];
    routeRules: string[];
    branding: BrandingModel;

    constructor() {
        super();
    }

    organizationUrlDetected():boolean{
        let detected = false;
        if(!this.urlRules) return detected;
        let href = window.location.href;
        this.urlRules.forEach((urlRule)=>{
            if(detected) return;
            let regex = new RegExp(urlRule);
            detected = regex.test(href);
        });

        return detected;
    }

    isRouteEnabled(route:string):boolean{
        let enabled = false;
        if(!this.routeRules) return enabled;
        this.routeRules.forEach((appRule)=>{
            if(enabled) return;
            let regex = new RegExp(appRule);
            enabled = regex.test(route);
        });
        return enabled;
    }
}