import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, EventEmitter,
    Input, OnDestroy, Output
} from '@angular/core';
import {ProjectImageModel} from "../../models/projectImages/projectImage.model";
import ProjectImageCollection from "../../collections/projectImages/projectImage.collection";
import {VertexElementModel} from "../../models/vertexElements/vertexElement.model";

@Component({
    selector: 'ptv-vertex-thumbnail-3d-list',
    styles: [require('./vertexThumbnail3dList.component.scss')],
    template: require('./vertexThumbnail3dList.component.html')
})
export default class VertexThumbnail3dListComponent implements AfterViewInit, OnDestroy {
    @Input() projectImageCollection: ProjectImageCollection;
    @Input() vertexElementModel: VertexElementModel;
    @Output() projectImageModelVisibilityChange = new EventEmitter<ProjectImageModel[]>();
    @Input() zoom: number = 0;
    enable3dThumbnails = true;

    private virtualScrollStart;
    private virtualScrollEnd;
    private projectImageCollectionFilterChangedSubscription;

    constructor(
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngAfterViewInit(): void {
        this.projectImageCollectionFilterChangedSubscription = this.projectImageCollection.projectImageCollectionFilterChanged.subscribe(this.onProjectImageCollectionFilterChanged.bind(this));
    }

    ngOnDestroy(): void {
        if(this.projectImageCollectionFilterChangedSubscription) this.projectImageCollectionFilterChangedSubscription.unsubscribe();
    }

    onProjectImageCollectionFilterChanged() {
        this.detectChanges();
    }

    onVirtualScrollChange(changeEvent:any):void{
        if (!changeEvent) return;
        let start = changeEvent.startIndex;
        let end = changeEvent.endIndex;
        if(start === -1 && end === -1) return;
        this.virtualScrollStart = start;
        this.virtualScrollEnd = end;

        let visibleProjectImageModelArray: ProjectImageModel[] = [];
        let filteredProjectImageCollection = this.projectImageCollection.withFilterApplied;
        for (let i = start; i <= end; i++) {
            let projectImageModel = filteredProjectImageCollection[i];
            visibleProjectImageModelArray.push(projectImageModel);
        }
        this.projectImageModelVisibilityChange.emit(visibleProjectImageModelArray);
    }

    detectChanges(): void {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }
}