import tpl from './save.html';

export default {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    controller: ['$scope', function ($scope) {
        let $ctrl = this;
        let branch;
        let name;
        let description;

        $ctrl.$onInit = function () {
            $ctrl.branches = $ctrl.resolve.branches;
            $ctrl.branch = $ctrl.resolve.branch;
            $ctrl.name = $ctrl.resolve.name;
            $ctrl.description = $ctrl.resolve.description;
        };

        $ctrl.getBranches = function (search) {
            let newBranches = $ctrl.branches.slice();
            if (search && newBranches.indexOf(search) === -1) {
                newBranches.unshift(search);
            }
            return newBranches;
        };

        this.save = function () {
            let output = {name: $ctrl.name, description: $ctrl.description, branch: $ctrl.branch};
            $ctrl.close({$value: output});
        };

        this.cancel = function () {
            $ctrl.dismiss();
        };

    }],
    template: tpl
}