import {WireframeElement} from "./wireframeElement";
import {CameraView} from "../models/cameras/cameraView";
import {WireframeApplication} from "./wireframeApplication";
import * as THREE from 'three';
import TextSprite from "../TextSprite";
import GeometryCache from "./geometryCache";
import {Mesh} from "./mesh";
import {DefaultMaterialProps} from "./defaultMaterialProps";
import {WireframeElementType} from "./wireframeElementType";
import {PV} from "../wireframe";
import FrameMetadata = PV.FrameMetadata;
import {ResourceManager} from "../resourceManager";
import {WireframeUtils} from "../wireframeUtils";
import {CameraProjector, CameraRay} from "./cameraProjector";
import Projection from "../projection";


export class CameraElement extends WireframeElement {
    pvObject:CameraView
    isFocused = false
    isSelected = false
    isHighlighted = false
    visible = true
    drawable = null
    isEditable = true
    isPickable = true
    isLocked = false
    isDisabled = false

    private cameraIntrinsics

    // since the existing matrixWorld is only computed if this element is part of the scene hierarchy, let's compute a copy and maintain it here
    public cameraMatrixWorld = new THREE.Matrix4()
    public cameraMatrixWorldInverse = new THREE.Matrix4()
    public cameraPositionWorld = new THREE.Vector3()
    public cameraDirectionWorld = new THREE.Vector3()
    public cameraRotation = new THREE.Quaternion()

    public projection:CameraProjector

    cone:THREE.Mesh
    imageDim:THREE.Vector2
    thumbPlane:THREE.Mesh
    thumbMat:THREE.MeshBasicMaterial
    thumbTex:THREE.Texture

    constructor(pvObject:PV.Component) {
        super(pvObject)

        this.cameraIntrinsics = {
            rotation: [ [1, 0, 0], [0, 1, 0], [ 0, 0, 1] ],
            translation: [0, 0, 0],
            skew: this.pvObject.registration.skew,
            fx: this.pvObject.registration.fxy[0],
            fy: this.pvObject.registration.fxy[1],
            cx: this.pvObject.registration.cxy[0],
            cy: this.pvObject.registration.cxy[1],
            cameraCenter: new THREE.Vector3(),
            projectionType: this.pvObject.registration.projectionType,
            distortion: this.pvObject.registration.distortion
        }

    }

    /**
     * Apply this camera's position, orientation, and aspect ratio to the provided THREE.PerspectiveCamera
     * @param cam
     */
    applyCameraParameters(cam:THREE.PerspectiveCamera) {
        cam.aspect = this.pvObject.frameMetadata.width / this.pvObject.frameMetadata.height
        cam.matrixAutoUpdate = false

        let fov = 2 * Math.atan(.5 * this.pvObject.frameMetadata.height / this.pvObject.registration.fxy[1])
        cam.fov = fov * 180 / Math.PI
        cam.matrix.copy(this.cameraMatrixWorld)
        cam.matrix.elements[4] *= -1
        cam.matrix.elements[5] *= -1
        cam.matrix.elements[6] *= -1
        cam.matrix.elements[8] *= -1
        cam.matrix.elements[9] *= -1
        cam.matrix.elements[10] *= -1
        cam.matrixWorld.copy(cam.matrix)
        cam.matrixWorldInverse.getInverse(cam.matrixWorld)
        cam.updateProjectionMatrix()
    }

    getHashCode() {
        return this.elementType + "-" + this.pvObject.registration.id
    }

    computeCameraMatrixWorld() {
        this.cameraMatrixWorld.multiplyMatrices(this.wireframeLayer.app.sceneManager.geocentricFrame.matrixWorld, this.matrix)
        this.cameraMatrixWorldInverse.getInverse(this.cameraMatrixWorld)
        this.cameraMatrixWorld.decompose(this.cameraPositionWorld, this.cameraRotation, new THREE.Vector3())
        this.cameraDirectionWorld.set(0, 0, 1).applyQuaternion(this.cameraRotation)
    }

    createCameraGeometry() {
        let height = .5;
        let radius = .375
        let geometry = GeometryCache.getOrCache("cameraGeometry", function () {
            return new THREE.CylinderBufferGeometry(radius, 0, height, 4, 1, true)
        });
        let elMat = new THREE.Matrix4();
        let rot = new THREE.Quaternion().setFromEuler(new THREE.Euler(Math.PI / 2, Math.PI / 4, 0))
        elMat.compose(new THREE.Vector3(0, 0, height / 2), rot, new THREE.Vector3(1, 1, 1))
        //geometry.applyMatrix(elMat);
        this.cone = new THREE.Mesh(geometry) as Mesh;
        this.cone.applyMatrix(elMat)
        //cone.material = element.material;
        //this.cone.isPickable = false;
        this.cone.material = DefaultMaterialProps[WireframeElementType.camera].baseMaterial.clone()
        //cone.wireframeElement = element;

        // pick
        let pickGeometry = GeometryCache.getOrCache("cameraPickGeometry", function () {
            return new THREE.CylinderBufferGeometry(radius, 0, height, 4, 1, false)
        });
        //pickGeometry.applyMatrix(elMat);
        let pickMat = DefaultMaterialProps[WireframeElementType.vertex].baseMaterial.clone()
        let pickCone = new THREE.Mesh(pickGeometry, pickMat) as Mesh;
        pickCone.applyMatrix(elMat)
        pickCone.isPickable = true;
        pickCone.visible = true
        pickCone.name = "cameraPickGeometry"
        pickCone.wireframeElement = this
        this.hitGeometry = [ pickCone ]
        //pickCone.wireframeElement = element;
        this.baseGeometry = [ this.cone ]
        this.highlightGeometry = this.baseGeometry

        this.cone.name = this.name

        //this.add(pickCone)
    }

    createGeometry() {
        this.matrixAutoUpdate = false
        this.createCameraGeometry()
        //let axes = new THREE.AxesHelper(.5)
        //axes.position.z = -.001
        //this.baseGeometry.push(axes as any)
        // labelSprite
        this.labelSprite = new TextSprite()
        this.labelSprite.setBorderColor({r: 0, g: 0, b: 0, a: 0.8})
        this.labelSprite.setBackgroundColor({r: 0, g: 0, b: 0, a: 0.3})
        this.labelSprite.material.depthTest = false
        this.labelSprite.visible = true
        this.labelSprite.setText("" + this.pvObject.frameId);
        this.labelSprite.parent = this
        this.add(this.labelSprite)
        this.matrix.copy(this.pvObject.globalTransform)

        this.thumbMat = new THREE.MeshBasicMaterial()

        this.thumbMat.depthTest = true
        this.thumbMat.transparent = true
        this.thumbMat.opacity = 0
        this.thumbMat.side = THREE.DoubleSide

        this.imageDim = new THREE.Vector2(this.pvObject.frameMetadata.width, this.pvObject.frameMetadata.height)
        let aspect = this.imageDim.x / this.imageDim.y
        this.thumbPlane = new THREE.Mesh(new THREE.PlaneBufferGeometry(1, 1 / aspect, 1, 1), this.thumbMat)
        this.thumbPlane.name = "thumbPlane-" + this.pvObject.registration.id
        this.thumbPlane.rotateY(Math.PI)
        this.thumbPlane.rotateZ(Math.PI)
        //this.add(this.thumbPlane)
        this.baseGeometry.push(this.thumbPlane)
        //this.hitGeometry.push(this.thumbPlane)
        this.projection = new CameraProjector(this.wireframeLayer.app, this)

        this.storeBaseMaterials()
    }


    updateGeometry() {
        super.updateGeometry()
        this.matrix.copy(this.pvObject.globalTransform)
        this.updateMatrixWorld(true)
        this.updateHitGeometry()
        this.computeCameraMatrixWorld()
    }

    updateMaterials() {
        super.updateMaterials();
        //this.hitGeometry.forEach((o) => {
        //    ((o as THREE.Mesh).material as THREE.MeshBasicMaterial).opacity = (this.isSelected) ? .5 : 0;
        //})
    }

    loadThumbnail() {
        if (this.thumbTex) return
        let that = this
        let cv = this.pvObject
        let fmd:FrameMetadata = cv.imageResource.metadata.imageMetadata
        let rm:ResourceManager = this.wireframeLayer.app.resourceManager
        let thumbKey = rm.getFrameThumbnailKey(fmd.frameId)
        //console.log("thumbKey", thumbKey)
        rm.getImageData(thumbKey, (o) => {
            //console.log("thumb", o)
            let loader:THREE.TextureLoader = new THREE.TextureLoader();
            loader.load(o, (tx) => {
                that.thumbTex = tx;
                that.thumbMat.map = tx;
                that.thumbMat.opacity = 1
                that.thumbMat.needsUpdate = true;
            });
        }, null)
    }

    updateMatrixWorld(force: boolean): void {
        super.updateMatrixWorld(force)
        //this.cam.matrixWorld.copy(this.matrixWorld)
        //this.cam.updateProjectionMatrix()
    }

    protected setSelectedAppearance() {
        this.cone.material = this.materialProps.selectMaterial
    }

    protected setHighlightedAppearance() {
        this.loadThumbnail()
        this.cone.material = this.materialProps.highlightMaterial
    }

    public getDistortedImageCoordForPoint3d(pos:THREE.Vector3, target:THREE.Vector2, inWorldCRS = true) {
        let _pos = pos.clone()
        if (inWorldCRS) {
            _pos.applyMatrix4(this.cameraMatrixWorldInverse)
        }
        let imgPoint = Projection.Project_Point_With_Camera_Distortion([ _pos.x, _pos.y, _pos.z ], this.cameraIntrinsics);
        target.set(imgPoint[0] / this.pvObject.frameMetadata.width, 1 - (imgPoint[1] / this.pvObject.frameMetadata.height))
    }

    public getRayDirectionForImageCoord(imgCoord:THREE.Vector2, target:THREE.Vector3) {
        let coord = [ imgCoord.x * this.pvObject.frameMetadata.width, (1 - imgCoord.y) * this.pvObject.frameMetadata.height]
        let plane = [ 0, 0, 1, -1]

        let ptAr = Projection.Find3DPointOnPlaneFrom2DPointWithCameraView(coord, this.cameraIntrinsics, plane)
        target.set(ptAr[0], ptAr[1], ptAr[2])
        target.normalize()
    }

    public setCameraRayFromImageCoord(ray:CameraRay, inWorldCRS = true) {
        this.getRayDirectionForImageCoord(ray.imgCoord, ray.direction)
        //this.getDistortedImageCoordForPoint3d(ray.direction, ray.distortedCoord, inWorldCRS)
        if (inWorldCRS) {
            ray.direction.applyQuaternion(this.cameraRotation)
        }
    }
}