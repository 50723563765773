import {
    Component,
} from '@angular/core';
import InteractiveFilterLabelComponent from "../interactiveFilterLabel/interactiveFilterLabel.component";
import {VertexElementModel} from "../../models/vertexElements/vertexElement.model";
@Component({
    selector: 'ptv-vertex-filter-label',
    styles: [require('./vertexFilterLabel.component.scss')],
    template: require('./vertexFilterLabel.component.html')
})
export default class VertexFilterLabelComponent extends InteractiveFilterLabelComponent<VertexElementModel> {
}