import {Component, ElementRef, HostListener, NgZone, OnDestroy} from '@angular/core';
import {WireframeApplication} from "../../application/wireframeApplication";
import {SceneService} from "../../services/scenes/scene.service";
import {Resource} from "../../resourceManager";
import * as THREE from 'three';
import * as Cesium from 'cesium/Source/Cesium';
import {Layer} from "../../application/layer";
import EarthGravityModel1996 from "./EarthGravityModel1996";
import {ElevationReferenceType} from "../../application/elevationReferenceType";
import {BroadcastEvent} from "../../Broadcaster";
import ProjectionUtils from "../../projectionUtils";

@Component({
    selector: 'cesium-view',
    styles: [require('./cesiumView.component.scss')],
    template: require('./cesiumView.component.html')
})
export default class CesiumViewComponent implements OnDestroy {

    private cesiumCanvasCollection:HTMLCollection

    private app:WireframeApplication
    private cesiumViewer:any
    private isInit:boolean = false
    private egm96:EarthGravityModel1996 = new EarthGravityModel1996();
    private terrainProvider:any
    private autoElevationCorrection:number = null
    private egm96Correction = 0

    private sceneCenterElevation:Cesium.Cartographic = null

    public zone:NgZone
    constructor(private _zone:NgZone, private sceneService:SceneService, private el:ElementRef) {
        this.zone = this._zone;
        this.app = sceneService.wireframeApplication
    }

    ngOnInit(): void {
        console.log("cesium ngOnInit")
        this.sceneService.wireframeApplication.cesiumComponent = this
        this.cesiumCanvasCollection = this.el.nativeElement.getElementsByTagName('canvas')
    }

    setVisible(visible:boolean) {
        this.el.nativeElement.style.display = visible ? "block" : "none"
    }

    ngOnDestroy(): void {
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {

    }

    resize() {
        //console.log("cesium resize " + event.target.innerWidth + "  " + event.target.innerHeight, this.cesiumCanvasCollection);
        if (this.cesiumCanvasCollection.length > 0) {
            let cesCanvas = this.cesiumCanvasCollection.item(0) as HTMLDivElement
            //console.log("cesCanvas", cesCanvas)
            cesCanvas.style.width = this.app.viewer.renderer.domElement.clientWidth + "px"
            cesCanvas.style.height = this.app.viewer.renderer.domElement.clientHeight + "px"
            this.cesiumViewer.forceResize()
        }
    }

    isInitialized():boolean { return this.isInit }

    initCesium() {
        if (this.isInit) return
        let that = this
        //this.localToGlobal = app.getLocalToGlobalTransform()

        this.terrainProvider = new Cesium.createWorldTerrain()

        this.cesiumViewer = new Cesium.Viewer(this.el.nativeElement.id, {
            sceneMode: Cesium.SceneMode.SCENE3D,
            useDefaultRenderLoop: false,
            animation: false,
            baseLayerPicker : false,
            fullscreenButton: false,
            geocoder: false,
            homeButton: false,
            infoBox: false,
            sceneModePicker: false,
            selectionIndicator: false,
            timeline: false,
            navigationHelpButton: false,
            //imageryProvider : Cesium.createOpenStreetMapImageryProvider('https://a.tile.openstreetmap.org/'),
            terrainProvider: this.terrainProvider,
            terrainShadows: Cesium.ShadowMode.DISABLED,
        });

        this.cesiumViewer.scene.globe.baseColor = new Cesium.Color(0, 0, 0)


        let satelliteLayer = new Layer(this.app, "satelliteMap", "Satellite")
        let cesSatelliteLayer = this.cesiumViewer.scene.imageryLayers._layers[0]
        satelliteLayer.eventEmitter.on<BroadcastEvent>("visibilityChanged").subscribe(() => {
            cesSatelliteLayer.show = satelliteLayer.isVisible
        })
        this.sceneService.sceneManager.cesiumLayer.addChildLayer(satelliteLayer)
        satelliteLayer.isVisible = false


        let streetMapLayer = new Layer(this.app, "streetMap", "Street Map")
        streetMapLayer.isVisible = true
        let cesiumStreetMapLayer = this.cesiumViewer.scene.imageryLayers.addImageryProvider(Cesium.createOpenStreetMapImageryProvider('https://a.tile.openstreetmap.org/'))
        streetMapLayer.eventEmitter.on<BroadcastEvent>("visibilityChanged").subscribe(() => {
            cesiumStreetMapLayer.show = streetMapLayer.isVisible
        })
        this.sceneService.sceneManager.cesiumLayer.addChildLayer(streetMapLayer)
        streetMapLayer.isVisible = true

        Object.values(this.app.projectData.resources).forEach((res:Resource) => {
            try {
                if (res.resourceType.name == "ORTHO_TILE") {
                    let s3BaseObj = that.app.resourceManager.getS3ObjectForResource(res)
                    let layers = this.cesiumViewer.scene.imageryLayers
                    let cesRes = new Cesium.Resource({
                        url: s3BaseObj.toURL(),
                        proxy: {
                            getURL: function (res) {
                                //console.log("proxy.getURL " + res)
                                let s3Object = that.app.resourceManager.getS3ObjectFromURL(res)
                                return that.app.resourceManager.generatePresignedUrl(s3Object)
                            }
                        }
                    })
                    let csOrthoLayer = layers.addImageryProvider(new Cesium.createTileMapServiceImageryProvider({
                        url: cesRes
                    }))
                    let orthoLayer = new Layer(that.app, "ortho-" + res.name, "OrthoTile " + res.name)
                    orthoLayer.eventEmitter.on<BroadcastEvent>("visibilityChanged").subscribe(() => { csOrthoLayer.show = orthoLayer.isVisible })
                    orthoLayer.isVisible = false
                    orthoLayer.resource = res
                    this.sceneService.sceneManager.cesiumLayer.addChildLayer(orthoLayer)
                }
            } catch (e) {
                console.error("Error creating cesium ortho_layer", e)
            }
        })

        if (true) {
            let cesRes = new Cesium.Resource({
                url: "https://api.nearmap.com/tiles/v3/Vert",
                proxy: {
                    getURL: function (res) {
                        console.log("nearmap proxy.getURL " + res)
                    }
                }
            })
            let layers = this.cesiumViewer.scene.imageryLayers
            let csOrthoLayer = layers.addImageryProvider(new Cesium.WebMapTileServiceImageryProvider({
                url: "https://us0.nearmap.com/maps/z={TileMatrix}&x={TileCol}&y={TileRow}&version=2&nml=Vert&client=wmts_integration&httpauth=false&apikey=ZDlmZTdhNGItMjcxNy00NjA5LWE4NDMtNTA1M2M4Njc2YjEz",
                layer: 'latest',
                style : 'default',
                format : 'image/jpeg',
                tileMatrixSetID : 'default028mm',
                maximumLevel: 24,
            }))

            let orthoLayer = new Layer(that.app, "nearmap", "Manned Aerial")

            orthoLayer.eventEmitter.on<BroadcastEvent>("visibilityChanged").subscribe(() => { csOrthoLayer.show = orthoLayer.isVisible })
            orthoLayer.isVisible = false
            this.app.sceneManager.cesiumLayer.addChildLayer(orthoLayer)
        }

        let cp = new Cesium.Cartesian3(4303414.154026048, 552161.235598733, 4660771.704035539);
        this.cesiumViewer.camera.setView({
            destination : cp,
            orientation: {
                heading : 10,
                pitch : -Cesium.Math.PI_OVER_TWO * 0.5,
                roll : 0.0
            }
        });

        let center = ProjectionUtils.toGeocentric(this.sceneService.getProjectGeodetic())
        //let center = this.toCes((app.getPointcloudBoundingBox().getCenter(new THREE.Vector3())))
        let centerCarto = Cesium.Cartographic.fromCartesian(center, Cesium.Ellipsoid.WGS84, new Cesium.Cartographic())
        let promise = Cesium.sampleTerrain(this.terrainProvider, 11, centerCarto)

        Cesium.when(promise, function(centerGround) {
            //console.log("pointcloud center carto", centerGround)
            that.sceneCenterElevation = centerGround
            that.autoElevationCorrection = that.sceneCenterElevation.height;
        })

        let egm96ResultPromise = this.egm96.getHeight(centerCarto.longitude, centerCarto.latitude)
        egm96ResultPromise.then(function(delta) {
            //console.log("egm96 delta " + centerCarto.height + " delta : " + delta)
            that.egm96Correction = delta
        })

        //let mapProjection = Proj4.defs("WGS84")
        //let pointcloudProj4 = "+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +vunits=m +no_defs"
        //Proj4.defs("EPSG:4936", "+proj=geocent +datum=WGS84 +units=m +no_defs")
        //let pointcloudProj4 = Proj4.defs("EPSG:4936")
        try {
            //app.cesiumViewer.toMap = Proj4(pointcloudProj4, mapProjection)
            //this.cesiumViewer.toScene = Proj4(mapProjection, pointcloudProj4)
        } catch (e) {
            console.error("Error creating projection", e)
        }
        this.isInit = true
    }

    private toCes(pos:THREE.Vector3):THREE.Vector3 {
        let gPos = pos.clone().applyMatrix4(this.sceneService.wireframeLayer.object.matrixWorld)
        //console.log("pos", pos)
        //console.log("gPos", gPos)
        //let xy = [gPos.x, gPos.y];
        //let height = gPos.z;
        //let deg = this.cesiumViewer.toMap.forward(xy);
        //let cPos = Cesium.Cartesian3.fromDegrees(...deg, height);
        return gPos;
    }

    updateCesium() {
        let that = this
        if(this.app.sceneManager.cesiumLayer.isVisible && this.cesiumViewer){
            let camera:THREE.Camera = this.sceneService.potreeCamera;
            camera.updateMatrix()
            camera.updateMatrixWorld(true)
            let mat = new THREE.Matrix4().getInverse(this.app.sceneManager.geocentricFrame.matrixWorld)
            let cPos		= new THREE.Vector3(0, 0, 0).applyMatrix4(camera.matrix).applyMatrix4(mat);
            let cUp		 = new THREE.Vector3(0, 600, 0).applyMatrix4(camera.matrix).applyMatrix4(mat).normalize();

            let cTarget = this.app.viewer.getCameraLookatTarget().applyMatrix4(mat) //viewer.scene.view.getPivot();

            let cameraCarto = Cesium.Cartographic.fromCartesian(cPos, Cesium.Ellipsoid.WGS84, new Cesium.Cartographic())
            cameraCarto.height += this.egm96Correction
            //console.log("cameraCarto height", cameraCarto.height)

            let elevationReference = that.getElevationReference()

            if (elevationReference == ElevationReferenceType.ELLIPSOIDAL) {
                this.setCesiumCamera(cPos, cUp, cTarget,0)
            } else if (elevationReference == ElevationReferenceType.ORTHOMETRIC) {
                //let egm96ResultPromise = this.egm96.getHeight(cameraCarto.longitude, cameraCarto.latitude)
                that.setCesiumCamera(cPos, cUp, cTarget, that.egm96Correction)
                //egm96ResultPromise.then(function(delta) {
                    //console.log("egm96 delta " + cameraCarto.height + " delta : " + delta)
                //})
            } else if (elevationReference == ElevationReferenceType.AGL) {
                this.setCesiumCamera(cPos, cUp, cTarget, that.sceneCenterElevation.height)
            } else if (elevationReference == ElevationReferenceType.AUTO) {
                //console.log("auto elevation correction", that.autoElevationCorrection)
                this.setCesiumCamera(cPos, cUp, cTarget, that.egm96Correction - (that.autoElevationCorrection))
            }
            //this.cesiumViewer.forceResize()
            this.resize()
            this.cesiumViewer.render()
        }
    }

    private setCesiumCamera(cPos:THREE.Vector3, cUp:THREE.Vector3, cTarget:THREE.Vector3, elevationReferenceCorrection:number) {

        //console.log("cPos", cPos)
        //console.log("cTarget", cTarget)

        let cesCamera = this.cesiumViewer.scene.camera

        let direction = cTarget.clone().sub(cPos).normalize()

        //console.log("direction", direction)
        let elevationCorrection = this.getElevationCorrection()
        let correctionVec = cPos.clone().setLength( elevationCorrection + elevationReferenceCorrection)
        let correctedPos = cPos.clone().add(correctionVec)


        cesCamera.direction = new Cesium.Cartesian3(direction.x, direction.y, direction.z)
        cesCamera.up = new Cesium.Cartesian3(cUp.x, cUp.y, cUp.z)

        /*
        this.cesiumViewer.camera.setView({
            destination : new Cesium.Cartesian3(correctedPos.x, correctedPos.y, correctedPos.z),
            orientation : {
                direction : new Cesium.Cartesian3(direction.x, direction.y, direction.z),
                up : new Cesium.Cartesian3(cUp.x, cUp.y, cUp.z)
            }
        });
        */

        //console.log("cesCam", correctedPos)
        let camera = this.app.viewer.camera;
        if (camera instanceof THREE.PerspectiveCamera) {
            if (!(cesCamera.frustum instanceof Cesium.PerspectiveFrustum)) {
                cesCamera.switchToPerspectiveFrustum()
            }
            cesCamera.position = new Cesium.Cartesian3(correctedPos.x, correctedPos.y, correctedPos.z)
            let aspect = camera.aspect;
            if(aspect < 1){
                let fovy = Math.PI * (camera.fov / 180);
                cesCamera.frustum.fov = fovy;
            }else{
                let fovy = Math.PI * (camera.fov / 180);
                let fovx = Math.atan(Math.tan(0.5 * fovy) * aspect) * 2
                cesCamera.frustum.fov = fovx;
            }
        } else {
            let orthoCam = camera as THREE.OrthographicCamera
            if (!(cesCamera.frustum instanceof Cesium.OrthographicFrustum)) {
                cesCamera.switchToOrthographicFrustum()
            }
            let orthoCamPos = correctedPos.clone().add(direction.clone().setLength(7000000).negate())
            cesCamera.position = new Cesium.Cartesian3(orthoCamPos.x, orthoCamPos.y, orthoCamPos.z)
            cesCamera.frustum.aspectRatio = orthoCam.right / orthoCam.top
            cesCamera.frustum.width = (-orthoCam.left + orthoCam.right) / orthoCam.zoom

        }


    }


    private getElevationCorrection():number {
        let elevationCorrection = 0
        try {
            elevationCorrection = Number(this.app.projectData.metadata.reconstructed.potreeCloudMetadata.elevationCorrection)
        } catch (e) {}
        return elevationCorrection || 0
    }

    private getElevationReference():ElevationReferenceType {
        let elevationReference = ElevationReferenceType.AUTO
        try {
            elevationReference = Number(this.app.projectData.metadata.reconstructed.potreeCloudMetadata.elevationReference)
        } catch (e) {}
        return elevationReference
    }
}