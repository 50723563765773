import {CameraControlMode, Tool} from "./tool";
import {PV} from "../wireframe";
import {WireframeOperations} from "../wireframeOperations";
import {WireframeElement} from "./wireframeElement";
import {WireframeElementType} from "./wireframeElementType";
import * as THREE from 'three';
import {WidgetElement} from "./widgetElement";
import WidgetTemplate from "./widgetTemplate";
import {UndoAction} from "./undoAction";
import {UndoActionType} from "./undoActionType";
import {RaycastHit} from "./raycastHandler";
import {WireframeApplication} from "./wireframeApplication";
import {WireframeRaycastHit} from "./wireframeRaycastHandler";


class WidgetDrag {
    hit:RaycastHit
    widget:WidgetElement
    offset:THREE.Vector3
}

export default class WidgetTool extends Tool {
    undoAction:UndoAction
    widgetDrag:WidgetDrag = null

    constructor(app:WireframeApplication) {
        super(app)
        this.toolName = "WidgetTool"
        this.cameraControlMode = CameraControlMode.LEFT_RIGHT_BUTTONS
        this.enableTransformTool = true
    }

    onToolActivated() {
        this.highlightableFunc = (el:WireframeElement) => {
            return el.pvObject.pvType == WireframeElementType.widget
        }
    }

    onToolDectivated() {

    }

    createWidget(widgetTemplate:WidgetTemplate) {
        console.log("createWidget", widgetTemplate)
        let widget = new PV.Widget()
        let loader = new THREE.ObjectLoader()
        let widgetGeom = loader.parse(widgetTemplate.object3D_JSON)
        let widgetContainer = new THREE.Object3D()
        widgetContainer.add(widgetGeom)

        widget.object3D_JSON = widgetContainer.toJSON()
        this.app.wireframe.addWidget(widget)

        let widgetEl = this.app.wireframeLayer.addWidget(widget)

        let pos = this.app.wireframeLayer.toWireframeCRS(this.getMousePointOnPlane(this.mouse, this.getCameraPlane()))
        let rot = new THREE.Quaternion()
        new THREE.Matrix4().getInverse(this.app.wireframeLayer.object.matrixWorld).decompose(new THREE.Vector3(), rot, new THREE.Vector3())

        widgetEl.quaternion.copy(rot)
        widgetEl.position.copy(pos)
        //widgetEl.scale.set(1, 1, 1)
        widgetEl.updateMatrix()
        widgetEl.updateMatrixWorld(true)
        widgetEl.updateGeometry()
        widgetEl.updateMaterials()
        console.log("created widget", widgetEl)
    }

    onMouseMove(event:MouseEvent) {
        super.onMouseMove(event)
        this.updateHighlightedElements();
        if (this.isHandlingMouseMove) {

            if (this.widgetDrag) {
                let cloudIntersect = this.getMousePointCloudIntersection()
                if (null != cloudIntersect) {
                    this.widgetDrag.widget.position.copy(this.app.wireframeLayer.toWireframeCRS(cloudIntersect.sub(this.widgetDrag.offset)))
                }
            }


            if (!this.undoAction) {
                let currentWidget = this.app.selectedElements.find((el) => {
                    return el instanceof WidgetElement
                })
                if (currentWidget) {
                    this.app.startUndoEntry()
                    this.undoAction = new UndoAction(UndoActionType.MODIFY, currentWidget)
                }
            }

            this.app.wireframeLayer.updateDerivedProperties()
            this.app.wireframeLayer.updateGeometry()
        } else {
            return
        }
    }


    onMouseDown(event:MouseEvent) {
        if (!super.onMouseDown(event)) return false
        if ((this.getTransformTool() as any).axis != null) {
            this.isHandlingMouseMove = true
        } else {
            let hits:WireframeRaycastHit[] = this.getMouseOverWireframeElements().filter((hit) => { return hit.element instanceof WidgetElement })
            if (hits.length > 0) {
                this.isHandlingMouseMove = true
                let hit = hits[0]
                this.widgetDrag = new WidgetDrag()
                this.widgetDrag.hit = hit
                this.widgetDrag.widget = hit.element as WidgetElement
                this.widgetDrag.offset = hit.intersect.point.clone().sub(this.app.wireframeLayer.toWorldCRS(this.widgetDrag.widget.position))
            }
        }
        return true
    }

    onMouseUp(event: MouseEvent) {
        if (event.button == 0) {
            if (this.widgetDrag) {
                this.widgetDrag = null
            }

            if (this.undoAction) {
                this.app.appendUndoAction(this.undoAction)
                this.undoAction = null
                return
            }

            let hits = this.getMouseOverWireframeElements().filter((hit) => { return hit.element instanceof WidgetElement })
            //this.app.selectWireframeElement(null)
                if (event.ctrlKey) {
                    if (hits.length > 0) this.app.selectWireframeElement([hits[0].element], true)
                } else {
                    this.app.selectWireframeElement(null, false);
                    if (hits.length > 0) this.app.selectWireframeElement([hits[0].element], true)
                }
            } else {
                this.app.selectWireframeElement(null)
            }

    }

    onKeyUp(event: KeyboardEvent) {
        let that = this
        switch (event.key) {
            case "Delete":
                let elementsToDelete = [];
                this.app.selectedElements.forEach(function (el: WireframeElement) {
                    elementsToDelete.push(el);
                });
                elementsToDelete.forEach(function (el: WireframeElement) {
                    that.app.wireframeLayer.deleteWireframeElement(el, event.ctrlKey);
                });
                break
        }
        super.onKeyUp(event)
    }
}