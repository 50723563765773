import {Component, Input} from '@angular/core';
import WireframeElementCollection from "../../collections/wireframeElements/wireframeElement.collection";
import {WireframeElementType} from "../../application/wireframeElementType";

@Component({
    selector: 'wireframe-selection-palette',
    styles: [require('./wireframeSelectionPalette.component.scss')],
    template: require('./wireframeSelectionPalette.component.html')
})
export default class WireframeSelectionPaletteComponent {
    @Input() selectedWireframeElements: WireframeElementCollection = new WireframeElementCollection();

    constructor() {
    }

    private getSelectedWireframeElementsByType(wireframeElementType:WireframeElementType){
        let wireframeElementCollection = new WireframeElementCollection();
        if(this.selectedWireframeElements && this.selectedWireframeElements.size() > 0){
            this.selectedWireframeElements.forEach((selectedWireframeElement) => {
                if(selectedWireframeElement.pvObject.pvType == wireframeElementType){
                    wireframeElementCollection.add(selectedWireframeElement);
                }
            })
        }
        return wireframeElementCollection;
    }

    get vertices():WireframeElementCollection{
        let vertexCollection = this.getSelectedWireframeElementsByType(WireframeElementType.vertex);
        return vertexCollection;
    }

    get hasVertices():boolean{
        return this.vertices.size() > 0;
    }

    get edges():WireframeElementCollection{
        let edgeCollection = this.getSelectedWireframeElementsByType(WireframeElementType.edge);
        return edgeCollection;
    }

    get hasEdges():boolean{
        return this.edges.size() > 0;
    }

    get planes():WireframeElementCollection{
        let planeCollection = this.getSelectedWireframeElementsByType(WireframeElementType.plane);
        return planeCollection;
    }

    get hasPlanes():boolean{
        return this.planes.size() > 0;
    }
}