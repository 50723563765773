import {ProjectImageListFilterType} from "./projectImageCollectionFilterType.enum";
import {ProjectImageCollectionFilterModel} from "./projectImageCollectionFilter.model";

export class ProjectImageCollectionFilterTagModel extends ProjectImageCollectionFilterModel {

    private _tag:string = '';

    constructor() {
        super(ProjectImageListFilterType.Tag);
        this.filterFunction = this.filterTag.bind(this);
    }

    filterTag(projectImageModel){
        if(!projectImageModel) return false;
        return  projectImageModel.tags
            ? projectImageModel.tags.indexOf(this.tag) >= 0
            : false;
    }

    get tag(): string {
        return this._tag;
    }

    set tag(value){
        this._tag = value;
        this.triggerFilterChanged();
    }

    get filterName(): string {
        return "tagged with " + this.tag;
    }
}