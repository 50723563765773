import {InteractiveModel} from "./interactive.model";
import {InteractiveOperationModel} from "./interactiveOperation.model";

export class InteractiveOperationEventModel<T extends InteractiveModel> {
    interactiveModel: T;
    interactiveOperationModel: InteractiveOperationModel<T>;

    constructor(
        interactiveModel: T,
        interactiveOperationModel: InteractiveOperationModel<T>
    ) {
        this.interactiveModel = interactiveModel;
        this.interactiveOperationModel = interactiveOperationModel;
    }
}