import {Object3DLayer} from "./object3DLayer";
import {PV} from "../wireframe";
import {WireframeElementType} from "./wireframeElementType";
import ElementLayer from "./elementLayer";
import {Layer} from "./layer";
import * as THREE from 'three';
import {WireframeApplication} from "./wireframeApplication";
import {WireframeElement} from "./wireframeElement";
import {BroadcastEvent} from "../Broadcaster";
import {VertexElement} from "./vertexElement";
import {UndoAction} from "./undoAction";
import {UndoActionType} from "./undoActionType";
import {WidgetElement} from "./widgetElement";
import {PlaneElement} from "./planeElement";
import {PropertyValue} from "../models/property/propertyValue";
import {DistanceProperty} from "../models/property/distanceProperty";
import {Property} from "../models/property/property";
import {PlaneDetectionMode} from "./planeDetectionMode";
import {PlaneDetector, PolygonDetectionResult} from "../planeDetector";
import {LogLevel} from "./logLevel";
import {LayerProperty} from "../models/property/layerProperty";
import {PolygonShapeProperty} from "../models/property/polygonShapeProperty";
import {EdgeElement} from "./edgeElement";
import {CameraElement} from "./cameraElement";
import {WireframeMathUtil} from "../wireframeMathUtil";
import {GeometryUtilities} from "../geometryUtilities";
import {AreaProperty} from "../models/property/areaProperty";
import {ElementPickOperation} from "./elementPickOperation";
import {WireframeUtils} from "../wireframeUtils";
import {Mesh} from "./mesh";
import * as $ from "jquery";
import {ValidationResult} from "./validationResult";
import PVUtils from "../pvUtils";
import {RaycastHit} from "./raycastHandler";
import {WireframeRaycastHandler} from "./wireframeRaycastHandler";
import GeometryCache from "./geometryCache";
import {LabelLayer} from "./labelLayer";
import {CameraView} from "../models/cameras/cameraView";
import Wireframe = PV.Wireframe;

declare let planeDetector: PlaneDetector;

interface ElementLayerMap { [elementType:number]: ElementLayer }

interface ElementMap { [elementKey:string]:WireframeElement }

export default class WireframeLayer extends Object3DLayer {
    public wireframe:Wireframe = new Wireframe()

    isLoading = false
    private isRedrawing = false
    wireframeElements:WireframeElement[] = []
    elementMap:ElementMap = {}

    boundingBoxLayer:Object3DLayer
    elementLayers:ElementLayerMap = {}
    planeNormalLayer:Layer
    planeDetectionEnabled:boolean = true
    planeDetector:PlaneDetector = new PlaneDetector()
    elementTypes:WireframeElementType[] = [ WireframeElementType.vertex, WireframeElementType.edge, WireframeElementType.plane, WireframeElementType.widget ]

    constructor(app:WireframeApplication, name:string, label:string) {
        super(app, name, label)
        this.supportsEditing = true
        this.supportsInteraction = true
        this.isEditable = true
        this.object = new THREE.Group()
        this.object.renderOrder = 100
        this.object.name = name
        this.raycastHandler = new WireframeRaycastHandler(this)
        this.boundingBoxLayer = new Object3DLayer(this.app, "boundingBoxes", "Bounding Boxes")
        this.planeNormalLayer = new Layer(this.app, "planeNormals", "Plane Normals");
    }

    init() {
        let that = this

        let axesHelper = new THREE.AxesHelper(10)
        this.planeNormalLayer.isVisible = false
        this.boundingBoxLayer.object = new THREE.Group()
        this.boundingBoxLayer.object.name = "bounding boxes"
        this.boundingBoxLayer.parent = this.app.rootScene
        this.boundingBoxLayer.isVisible = false
        this.addChildLayer(this.boundingBoxLayer)

        for (let elementType of this.elementTypes) {
                let label = WireframeElementType[elementType] as string
                label = label.charAt(0).toUpperCase() + label.substr(1)
                let elLayer = new ElementLayer(that.app, WireframeElementType[elementType], label, elementType);
                this.elementLayers[elementType] = elLayer

                elLayer.labelLayer = new LabelLayer(that.app, "label", "Labels")

                this.addChildLayer(elLayer)
                elLayer.addChildLayer(elLayer.labelLayer)
                elLayer.supportsInteraction = false
                elLayer.supportsEditing = false

                elLayer.isVisible = true
                elLayer.labelLayer.isVisible = false

            if (elementType == WireframeElementType.vertex) elLayer.labelLayer.dimensionLayer.isLayerVisible = false
            if (elementType == WireframeElementType.widget) elLayer.labelLayer.dimensionLayer.isLayerVisible = false
            if (elementType == WireframeElementType.plane) elLayer.addChildLayer(that.planeNormalLayer)
        }

        this.traverseChildren().forEach((l) => {
            l.eventEmitter.on<BroadcastEvent>("visibilityChanged").subscribe(() => {
                that.redrawWireframe()
            })
        })
    }

    raycastElements(raycaster:THREE.Raycaster, elements:WireframeElement[]):RaycastHit[] {
        let rch = (this.raycastHandler as WireframeRaycastHandler)
        return rch.handleRaycastUsingCandidates(raycaster, elements)
    }

    addElement(el:WireframeElement) {
        el.wireframeLayer = this
        if (!this.wireframeElements.includes(el))this.wireframeElements.push(el)
        this.elementMap[el.getHashCode()] = el
        this.object.add(el)
    }


    updateTransform() {
        let l2g = this.wireframe.getLocalToGlobalTransform()
        this.object.matrixAutoUpdate = false
        this.object.matrix.copy(l2g)
        this.object.updateMatrixWorld(true)
        this.object.matrix.decompose(new THREE.Vector3(), new THREE.Quaternion(), this.scale)
    }

    public toWireframeCRS(v:any):any {
        let lv = v.clone()
        lv.applyMatrix4(new THREE.Matrix4().getInverse(this.object.matrixWorld))
        return lv
    }

    public toWorldCRS(v:any):any {
        let wfv = v.clone()
        wfv.applyMatrix4(this.object.matrixWorld)
        return wfv
    }

    findWireframeElement(type: WireframeElementType, id: Number):WireframeElement {
        for (let i = 0; i < this.wireframeElements.length; i++) {
            let el: WireframeElement = this.wireframeElements[i];
            if (el.pvObject.pvType == type && el.pvObject.id == id) return el;
        }
        return null;
    }

    findElement(o: PV.Component) {
        for (let i = 0; i < this.wireframeElements.length; i++) {
            let el: WireframeElement = this.wireframeElements[i];
            if (el.pvObject.pvType == o.pvType && el.pvObject.id == o.id) return el;
        }
        return null;
    }

    addWireframeElement(el:WireframeElement) {
        this.addElement(el)

        let that = this
        el.pvObject.properties.forEach((pv) => {
            let prop = that.wireframe.properties[pv.id]
            if (prop) prop.onAddToElement(el)
        })

        el.createGeometry()
        el.updateGeometry()
        el.updateMaterials()
        if (this.app.undoEnabled) {
            let undo: UndoAction = new UndoAction(UndoActionType.CREATE, el);
            this.app.appendUndoAction(undo);
        }
        this.app.broadcast("wireframeElemendded", el)
    }


    //add new vertex to wireFrame
    addVertex(vert: PV.Vertex = new PV.Vertex(), params: any = null): VertexElement {
        if (null == vert.id || !this.wireframe.vertices[vert.id]) {
            this.wireframe.addVertex(vert);
        }
        let element = new VertexElement(vert)
        if (params) Object.assign(element, params);
        this.addWireframeElement(element)
        return element;
    }

    moveVertex(vert: VertexElement, pos, withUndo, withConstraintUpdate) {
        //let el = app.findWireframeElement(WireframeElementType.vertex, vert.id);

        if (!vert.isEditable) return;
        if (this.app.undoEnabled && withUndo) {
            let undo = new UndoAction(UndoActionType.MODIFY, vert);
            this.app.appendUndoAction(undo);
        }
        vert.pvObject.x = pos.x;
        vert.pvObject.y = pos.y;
        vert.pvObject.z = pos.z;
        this.updateElement(vert);

        let lines = this.getConnectedLines(vert);
        let that = this
        lines.forEach(function (line: WireframeElement) {
            that.updateElement(line);
        });

        this.app.broadcast("elementTransformChanged", vert)

        vert.getDependentGeometry().forEach((el) => {
            el.applyGeometryConstraints()
        })
        if (withConstraintUpdate) {
            this.applyGeometryConstraints();

            this.app.wireframeChanged();
        }

    }

    addWidget(widget:PV.Widget, params:any = null):WidgetElement {
        let element = new WidgetElement(widget)
        if (params) Object.assign(element, params);
        this.addWireframeElement(element)
        return element;
    }

    addPlane(plane: PV.Plane, planeElement: PlaneElement = null, params: any = null):PlaneElement {
        if (!planeElement) {
            planeElement = new PlaneElement(plane)
        }
        if (null == plane.id || !this.wireframe.planes[plane.id]) {
            this.wireframe.addPlane(plane);
        }
        if (params) Object.assign(planeElement, params);
        for (let vertId of plane.vertexIds) {
            if (!this.wireframe.vertices[vertId]) {
                console.log("adding plane with missing vert")
            }
        }
        for (let edgeId of plane.edgeIds) {
            if (!this.wireframe.edges[edgeId]) {
                console.log("adding plane with missing edge")
            }
        }
        //wfOps.createDrawable(planeElement, drawableParams);
        this.addWireframeElement(planeElement)
        return planeElement;
    }

    snapToParentPlane(el: PlaneElement) {
        if (null == el.pvObject.parentId) return;
        let planeObj: PV.Plane = this.wireframe.planes[el.pvObject.parentId];
        if (null == planeObj) return;
        //let parentPlane = app.wireframe.planes[el.pvObject.parentId];
        this.updatePlaneEquation(planeObj);
        let parentPlane: THREE.Plane = this.wireframe.getPlane3(el.pvObject.parentId);
        //console.log("snap to parent " + el.pvObject.id + " => " + el.pvObject.parentId);
        if (null == parentPlane) return;
        let that = this;
        el.pvObject.vertexIds.forEach(function (vertexId) {
            let vertPos: THREE.Vector3 = that.wireframe.getVert3(vertexId);
            if (null == vertPos) return;
            let newPos: THREE.Vector3 = parentPlane.projectPoint(vertPos, new THREE.Vector3());
            let dist = newPos.distanceTo(vertPos)
            if (dist > 0.001) {
                let vertEl = that.findWireframeElement(WireframeElementType.vertex, vertexId) as VertexElement;
                that.moveVertex(vertEl, newPos, false, false);
            }
        })
    }

    updatePlaneEquation(plane: PV.Plane) {
        this.wireframe.updatePlaneNormal(plane);
    }

    updateDerivedProperties() {
        let that = this
        this.wireframeElements.forEach(function (el: WireframeElement) {
            if (null == el.pvObject) return;
            el.pvObject.properties.forEach(function (pv: PropertyValue) {
                let prop = that.wireframe.properties[pv.id];
                if (null == prop) return;
                if (prop instanceof DistanceProperty) {
                    prop.apply(el, pv);
                }
            });

        });
    }


    applyGeometryConstraints(wireframeElements:WireframeElement[] = null) {
        let t = performance.now()
        if(!wireframeElements) wireframeElements = this.wireframeElements;
        this.app.blockGeometryUpdate = true;
        this.app.undoEnabled = false;
        try {
            let that = this;
            wireframeElements.forEach(function (el: WireframeElement) {
                try {
                    if (el.pvObject.pvType == WireframeElementType.plane) {
                        that.snapToParentPlane(el as PlaneElement);
                    }
                } catch (e) {
                    console.log("Error applying geometry constraints", e);
                }
            })

            let planeElements:WireframeElement[] = [];
            wireframeElements.forEach(function (el: WireframeElement) {
                planeElements.push(el);
            });
            for (let el of planeElements) {
                try {
                    if (el.pvObject.pvType == WireframeElementType.plane) {
                        for (let i = 0; i < el.pvObject.properties.length; i++) {
                            let pv: PropertyValue = el.pvObject.properties[i];
                            let prop: Property = that.wireframe.properties[pv.id];
                            //console.log(el.name + " prop " + pv.id, prop);
                            if (!prop) continue;
                            prop.apply(el, pv);
                        }

                    }
                } catch (e) {
                    console.log("Error applying geometry constraints", e);
                }
            }

            this.updateDerivedProperties()

        } catch (e) {
            console.error("applyGeometryConstraints error", e);
        }

        this.updateGeometry(wireframeElements);
        this.app.undoEnabled = true;
        this.app.blockGeometryUpdate = false;

        // this is needed to force the property tab to update.  there is probably a better way.
        this.app.propertyManager.aggregateProperties(this.app.selectedElements);
        this.app.broadcast("propertiesChanged")
        if (this.app.debug) console.log("applyGeometryConstraints in " + (performance.now() - t))
    }

    updateGeometry(wireframeElements:WireframeElement[] = null) {
        if(!wireframeElements) wireframeElements = this.wireframeElements;
        wireframeElements.forEach(function (el) {
            try {
                el.updateGeometry()
                //wfOps.updateWireframeElementGeometry(el);
            } catch (e) {
                console.warn("Error updating geometry", e);
            }
        });
    }

    redrawWireframe() {
        let t0 = performance.now();
        this.isRedrawing = true;
        this.applyGeometryConstraints();
        if (this.app.planeDetectionMode == PlaneDetectionMode.ON && this.wireframe && this.wireframe.transform) {
            try {
                this.recomputeSurfaces();
            } catch (e) {
                console.warn("Error in recomputeSurfaces()", e);
                throw e;
            }
        }
        //this.app.rootScene.updateMatrixWorld(true)
        this.updateGeometry();
        this.updateWireframeMaterials();

        this.updateGroundPlaneElement()

        this.isRedrawing = false;

        console.debug("redrawWireframe " + parseInt((performance.now() - t0) + "") + "ms");
    }

    private updateGroundPlaneElement() {
        let gp = this.groundPlaneElement
        if (gp instanceof PlaneElement) {
            [gp, ...gp.getSupportingGeometry()].forEach(el => {
                el.visible = false
                el.isPickable = false
            })
        }
    }

    private _groundPlaneElement: WireframeElement
    get groundPlaneElement(): WireframeElement {
        if (!this._groundPlaneElement) {
            this._groundPlaneElement = this.wireframeElements.find(el => el.label == "groundPlane")
        }
        return this._groundPlaneElement
    }

    private _groundPlaneElements: WireframeElement[]

    get groundPlaneElements(): WireframeElement[] {
        let gp = this.groundPlaneElement
        if (gp) {
            this._groundPlaneElements = [ gp, ...gp.getSupportingGeometry() ]
        }
        return this._groundPlaneElements || []
    }

    updateElement(el: WireframeElement) {
        el.updateGeometry()
        el.updateMaterials()
    }

    updateWireframeMaterials() {
        //console.log("updateWireframeMaterials");
        let that = this;
        this.wireframeElements.forEach(function (el) {
            el.updateMaterials()
            //that.updateWireframeElementMaterials(el);
        })
    }

    recomputeSurfaces() {

        //if (!this.wireframe.orthoTransform) return
        if (!this.planeDetectionEnabled || this.app.planeDetectionMode == PlaneDetectionMode.OFF) return
        let t0 = performance.now();
        this.app.undoEnabled = false
        let autoDetectPlanes: WireframeElement[];
        let that = this;

        let allPlanes: PolygonDetectionResult = this.planeDetector.detectPlanes(this.wireframe, this.object.matrixWorld);
        let newPlanes: PV.Plane[] = allPlanes.acceptedPolygons;
        let rejected: PV.Plane[] = allPlanes.rejectedPolygons;
        //if (rejected.length > 0) {
        //    console.log("rejected planes ", allPlanes.rejectedPolygons);
        //}
        rejected.forEach(function (plane: PV.Plane) {
            for (let pv of plane.properties) {
                let prop = this.wireframe.properties[pv.id];
                if (!prop.isReadonly) {
                    newPlanes.push(plane);
                    return;
                }
            }
        });

        if (false && rejected.length != this.app.numRejectedPlanes) {
            let msg = rejected.length + "/" + (rejected.length + newPlanes.length) + " planes rejected";
            if (rejected.length > 0) {
                this.app.log(msg, LogLevel.WARNING);
            } else {
                this.app.log(msg, LogLevel.SUCCESS);
            }
            this.app.numRejectedPlanes = rejected.length;
        }
        //console.log("newPlanes", newPlanes);
        let layerProp: LayerProperty = this.wireframe.findProperty(LayerProperty, true);
        let polygonShapeProp:Property = this.wireframe.findProperty(PolygonShapeProperty, true);

        // Build a list of planes that are subject to autodetection/autopurging.
        autoDetectPlanes = this.wireframeElements.filter((el: WireframeElement) => {
            if (el.pvObject.pvType != WireframeElementType.plane) return false
            // Always preserve planes with polygonShapeProps
            if (polygonShapeProp.getPropertyValue(el.pvObject)) return false
            return true
        })

        let unmappedNewPlanes: PV.Plane[] = [];
        let unmappedOldPlanes: PV.Plane[] = [];

        //let preservedPlaneElements: WireframeElement[] = [];

        function removePlaneElement(planeElements: WireframeElement[], comparator: Function, returnOnMatch: boolean) {
            for (let i = planeElements.length - 1; i >= 0; i--) {
                let el: WireframeElement = planeElements[i];
                if (comparator.call(this, el.pvObject)) {
                    //console.log("removing matched plane " + el.pvObject.vertexIds.toString());
                    planeElements.splice(i, 1);
                    if (returnOnMatch) return el;
                }
            }
        }

        newPlanes.forEach(function (newPlane: PV.Plane) {
            //console.log("newPlane " + newPlane.vertexIds.toString());
            //let existingPlaneElement:WireframeElement = that.matchPlaneElement(existingPlaneElements, newPlane);
            let autoDetectPlaneElement = removePlaneElement(autoDetectPlanes, function (p: PV.Plane) {
                let matches = PVUtils.arrayContentsEquals(p.vertexIds, newPlane.vertexIds);
                //console.log(p.vertexIds.toString() + " == " + newPlane.vertexIds.toString() + " : " + matches);
                return matches;
            }, true);

            if (autoDetectPlaneElement) {
                //preservedPlaneElements.push(existingPlaneElement);
                that.wireframe.planes[autoDetectPlaneElement.pvObject.id] = autoDetectPlaneElement.pvObject as PV.Plane;
            } else {
                let existing = that.wireframeElements.find((el:WireframeElement) => {
                    if (el.pvObject.pvType != WireframeElementType.plane) return false
                    if (PVUtils.arrayContentsEquals((el.pvObject as PV.Plane).vertexIds, newPlane.vertexIds)) return true
                    return false
                })
                if (!existing) {
                    console.log("Adding newly detected plane", newPlane);
                    unmappedNewPlanes.push(newPlane);
                    that.wireframe.addPlane(newPlane);
                    that.alignWithGravity(newPlane);
                    that.addPlane(newPlane)
                }
            }
        })

        autoDetectPlanes.forEach(function (el: PlaneElement) {
            console.log("removing unmatched plane " + el.pvObject.vertexIds.toString());
            that.deleteWireframeElement(el);
        })
        //console.log("existingPlaneElements/unmapped " + existingPlaneElements.length + " " + unmappedNewPlanes.length);
        // cleanup orphaned parentId references
        Object.values(this.wireframe.planes).forEach(function (plane: PV.Plane) {
            if (plane.parentId != null) {
                if (!that.wireframe.planes[plane.parentId]) {
                    plane.parentId = null;
                    console.log("Removing parent/child relationship for plane " + plane.parentId + "/" + plane.id + ", missing parent plane");
                }
            }
        })

        this.app.undoEnabled = true

        let lockedPlane: WireframeElement = this.app.getFirstLockedElement(WireframeElementType.plane);
        if (lockedPlane) {
            if (!this.wireframe.planes[lockedPlane.pvObject.id]) {
                this.app.lockWireframeElement(null);
            }
        }
        //console.log("recomputeSurfaces " + parseInt(performance.now() - t0) + "ms");
    }

    deletePlane(el: PlaneElement, removeGeometry: boolean = false) {
        if (!el || !el.pvObject) return;
        let that = this;
        let plane: PV.Plane = el.pvObject;
        let vertIdsToDelete = [];
        if (this.app.undoEnabled) this.app.appendUndoAction(new UndoAction(UndoActionType.DELETE, el))
        if (removeGeometry) {
            plane.vertexIds.forEach(function (vertId) {
                let edgeIds: number[] = that.wireframe.findEdgesForVertex(vertId);
                if (edgeIds.length < 3) {
                    vertIdsToDelete.push(vertId);
                }
            })
        }
        this.wireframe.removePlane(el.pvObject.id);
        this.cleanUpReferences(el);
        this.disposeHierarchy(el);

        if (removeGeometry) {
            vertIdsToDelete.forEach(function (vertId) {
                let vertEl = that.findWireframeElement(WireframeElementType.vertex, vertId) as VertexElement;
                that.deleteWireframeElement(vertEl, false);
            })
        }
    }

    deleteWidget(el:WidgetElement) {
        if (this.app.undoEnabled) this.app.appendUndoAction(new UndoAction(UndoActionType.DELETE, el))
        this.wireframe.removeWidget(el.pvObject.id)
        this.cleanUpReferences(el)
    }

    deleteVertex(el: VertexElement, reconnectEdges: boolean = true) {
        if (!el || !el.pvObject) return;
        let that = this;

        this.app.broadcast("elementDeleted", [el])

        let neighborVerts = [];
        let edgesToRemove = [];
        let getOtherVert = function (vertId: Number, edge: PV.Edge) {
            if (edge.vertex1Id == vertId) return edge.vertex2Id;
            return edge.vertex1Id;
        };

        this.wireframeElements.forEach(function (o: WireframeElement) {
            if (o.pvObject.pvType != WireframeElementType.edge) return;
            let edge = o as EdgeElement
            if (edge.pvObject.vertex1Id == el.pvObject.id || edge.pvObject.vertex2Id == el.pvObject.id) {
                neighborVerts.push(getOtherVert(el.pvObject.id, edge.pvObject));
                edgesToRemove.push(o);
            }
        });

        edgesToRemove.forEach(function (el) {
            that.deleteWireframeElement(el, false);
        });

        let planeIds: number[] = this.wireframe.findPlanesForVertex(el.pvObject.id);
        for (let planeId of planeIds) {
            let plane = this.wireframe.planes[planeId];
            if (!plane) continue;
            plane.vertexIds.splice(plane.vertexIds.indexOf(el.pvObject.id), 1);
            this.createPlaneEdgesFromVerts(plane);
            //app.wireframe.orderVertexIdsFromEdges(plane);
            if (plane.vertexIds.length < 3) {
                that.deleteWireframeElement(this.findElement(plane) as PlaneElement, false);
            }
        }

        if (this.app.undoEnabled) this.app.appendUndoAction(new UndoAction(UndoActionType.DELETE, el))

        this.wireframe.removeVertex(el.pvObject.id);
        this.cleanUpReferences(el);
        this.disposeHierarchy(el);

        if (reconnectEdges && neighborVerts.length == 2) {
            let edge: PV.Edge = this.wireframe.findEdge(neighborVerts[0], neighborVerts[1]);
            if (!edge) {
                edge = new PV.Edge(neighborVerts[0], neighborVerts[1]);
                this.wireframe.addEdge(edge);
                that.addEdge(edge, null);
            }
        }
    }

    public createPlaneEdgesFromVerts(plane: PV.Plane) {
        plane.edgeIds = [];
        for (let i = 0; i < plane.vertexIds.length; i++) {
            let vert1Id = plane.vertexIds[i];
            let vert2Id = plane.vertexIds[(i + 1) % plane.vertexIds.length];
            let edge = this.wireframe.findEdge(vert1Id, vert2Id);
            if (!edge) {
                edge = new PV.Edge(vert1Id, vert2Id);
                this.wireframe.addEdge(edge);
                this.addEdge(edge, null);
            }
            plane.edgeIds.push(edge.id);
        }
    }

    addEdge(newEdge: PV.Edge, params = null) {
        if (!newEdge) return;
        if (!this.wireframe.edges[newEdge.id]) {
            this.wireframe.addEdge(newEdge);
        }
        let element = new EdgeElement(newEdge)
        if (params) Object.assign(element, params);
        this.addWireframeElement(element)
        return element;
    }


    deleteEdge(el: EdgeElement, removeGeometry: boolean) {
        if (!el || !el.pvObject) return;
        let edge: PV.Edge = el.pvObject;

        let that = this
        el.getDependentGeometry().forEach((el) => {
            that.deleteWireframeElement(el)
        })
        if (this.app.undoEnabled) this.app.appendUndoAction(new UndoAction(UndoActionType.DELETE, el))
        this.wireframe.removeEdge(el.pvObject.id);
        this.cleanUpReferences(el);
        this.disposeHierarchy(el);
    }

    deleteWireframeElement(el: WireframeElement, removeGeometry: boolean = false) {
        if (null == el || null == el.pvObject) return;
        if (!el.isEditable) return;
        if (el.pvObject.pvType == WireframeElementType.camera) return;
        if (el.isDeleted) return
        el.isDeleted = true

        let that = this
        el.pvObject.properties.forEach(function (pv: PropertyValue) {
            let prop = that.wireframe.properties[pv.id];
            if (null == prop) return;
            prop.onRemoveFromElement(el, pv);
        })

        let relatedElements = [ ...el.getSupportingGeometry(), ...el.getDependentGeometry() ]
        if (el.pvObject.pvType == WireframeElementType.vertex) {
            this.deleteVertex(el as VertexElement, true);
        } else if (el.pvObject.pvType == WireframeElementType.edge) {
            this.deleteEdge(el as EdgeElement, removeGeometry);
        } else if (el.pvObject.pvType == WireframeElementType.plane) {
            this.deletePlane(el as PlaneElement, removeGeometry);
        } else if (el instanceof WidgetElement) {
            this.deleteWidget(el)
        }
        relatedElements.forEach((relEl) => {
            if (!relEl) return
            if (relEl.isDeleted) return
            relEl.updateGeometry()
            relEl.updateMaterials()
        })
        this.app.broadcast("wireframeElementDeleted", el)
        this.recomputeSurfaces()
    }

    cloneElements(src: WireframeElement[]):WireframeElement[] {
        let that = this;
        let vertIdMap = new Map<number, number>();
        let edgeIdMap = new Map<number, number>();
        let planeIdMap = new Map<number, number>();
        let wf: PV.Wireframe = this.wireframe;

        let newElements:WireframeElement[] = []

        src.forEach(function (el: PlaneElement) {
            if (el.pvObject.pvType == WireframeElementType.plane) {
                el.pvObject.vertexIds.forEach(function (vertId: number) {
                    if (!(vertId in vertIdMap)) {
                        let nv = wf.cloneVertex(wf.vertices[vertId]);
                        vertIdMap[vertId] = nv.id;
                        that.addVertex(nv);
                    }
                })

                el.pvObject.edgeIds.forEach(function (edgeId: number) {
                    if (!(edgeId in edgeIdMap)) {
                        let ne = wf.cloneEdge(wf.edges[edgeId], vertIdMap);
                        edgeIdMap[edgeId] = ne.id;
                        that.addEdge(ne, null)
                    }
                })

                let newPlane = wf.clonePlane(el.pvObject, edgeIdMap, vertIdMap);
                newElements.push(that.addPlane(newPlane, null))
            }
        });

        let srcVerts: PV.Vertex[] = [];
        for (let dstVertId in vertIdMap) {
            let srcVertId = vertIdMap[dstVertId];
            srcVerts.push(wf.vertices[srcVertId]);
        }
        let offset: THREE.Vector3 = new THREE.Vector3(1, 0, 0);
        if (srcVerts.length > 1) {
            offset = this.wireframe.getVert3(srcVerts[0].id).sub(this.wireframe.getVert3(srcVerts[srcVerts.length - 1].id));
            offset = offset.multiplyScalar(1.25);
        }

        for (let oldId in vertIdMap) {
            let newId = vertIdMap[oldId];
            let newVert = wf.vertices[newId];
            newVert.x += offset.x;
            newVert.y += offset.y;
            newVert.z += offset.z;
        }

        this.redrawWireframe();
        return newElements
    }

    align(target: EdgeElement, ref: EdgeElement, plane: PlaneElement) {
        if (target.pvObject.pvType == WireframeElementType.edge && ref.pvObject.pvType == WireframeElementType.edge) {
            let refSrcVert = this.wireframe.getVert3(ref.pvObject.vertex1Id);
            let refDstVert = this.wireframe.getVert3(ref.pvObject.vertex2Id);
            let refVec = refDstVert.clone().sub(refSrcVert);

            let targetSrcVert = this.wireframe.getVert3(target.pvObject.vertex1Id);
            let targetDstVert = this.wireframe.getVert3(target.pvObject.vertex2Id);
            let targetVec = targetDstVert.clone().sub(targetSrcVert);
            let targetLength = targetDstVert.clone().sub(targetSrcVert).length();
            let targetMidpoint = targetSrcVert.clone().add(targetDstVert).multiplyScalar(.5);

            if (plane) {
                let p = this.wireframe.getPlane3(plane.pvObject.id);
                targetMidpoint = p.projectPoint(targetMidpoint, new THREE.Vector3());
            }

            let newSrc = targetMidpoint.clone().add(refVec.clone().setLength(targetLength / 2));
            let newDst = targetMidpoint.clone().sub(refVec.clone().setLength(targetLength / 2));

            if (refVec.dot(targetVec) > 0) {
                let tmp = newSrc;
                newSrc = newDst;
                newDst = tmp;
            }

            let srcVertElement:VertexElement = this.findWireframeElement(WireframeElementType.vertex, target.pvObject.vertex1Id) as VertexElement;
            this.moveVertex(srcVertElement, newSrc, true, false);
            let dstVertElement = this.findWireframeElement(WireframeElementType.vertex, target.pvObject.vertex2Id) as VertexElement;
            this.moveVertex(dstVertElement, newDst, true, false);
            target.updateGeometry()
        }
    }

    addCamera(camDef:CameraView) {
        let element = new CameraElement(camDef)
        this.addWireframeElement(element)
        return element;
    }

    createPlaneFromVert(vertId: number):PlaneElement {
        let loop: number[] = this.findVertLoop(vertId);
        if (null == loop) return;
        let plane: PV.Plane = this.wireframe.findPlaneWithVertIds(loop);
        let planeEl:PlaneElement
        if (null == plane) {
            plane = new PV.Plane();
            plane.vertexIds = loop;
            for (let i = 0; i < loop.length; i++) {
                let edge = this.wireframe.findEdge(loop[i], loop[(i + 1) % loop.length]);
                plane.edgeIds.push(edge.id);
            }
            this.wireframe.addPlane(plane);
            planeEl = this.addPlane(plane, null);
            console.log("Added plane from vertloop ", plane);
        }
        return planeEl;
    }

    findVertLoop(vertId: number): number[] {
        let loop: number[] = [vertId];
        let currentVert = vertId;
        let isolated: boolean = true;
        let neighbors: number[] = this.wireframe.findAdjacentVerts(vertId);
        while (true) {
            if (neighbors.length != 2) {
                isolated = false;
                return null;
            }
            if (loop.includes(neighbors[0]) && loop.includes(neighbors[1])) break;
            let nextVert = loop.includes(neighbors[0]) ? neighbors[1] : neighbors[0];
            loop.push(nextVert);
            neighbors = this.wireframe.findAdjacentVerts(nextVert);
        }
        return loop;
    }

    //add intersection point of two selected edges to wireframe
    addVertexByTwoEdgeIntersection(edge1: EdgeElement, edge2: EdgeElement) {
        let edgeObject1: PV.Edge = edge1.pvObject;
        let edgeObject2: PV.Edge = edge2.pvObject;

        let v1: PV.Vertex = this.wireframe.vertices[edgeObject1.vertex1Id];
        let v2: PV.Vertex = this.wireframe.vertices[edgeObject1.vertex2Id];
        let v3: PV.Vertex = this.wireframe.vertices[edgeObject2.vertex1Id];
        let v4: PV.Vertex = this.wireframe.vertices[edgeObject2.vertex2Id];

        let p1: number[] = [v1.x, v1.y, v1.z];
        let p2: number[] = [v2.x, v2.y, v2.z];
        let p3: number[] = [v3.x, v3.y, v3.z];
        let p4: number[] = [v4.x, v4.y, v4.z];

        let intersect = GeometryUtilities.LineLineIntersect(p1, p2, p3, p4);
        let a = intersect[0];
        let b = intersect[1];
        let mean = intersect[2]
        let vert: PV.Vertex = new PV.Vertex();
        vert.x = mean[0];
        vert.y = mean[1];
        vert.z = mean[2];
        this.wireframe.addVertex(vert);
        this.addVertex(vert);

        let distanceEdge1Pt1: number = WireframeMathUtil.getDistance(vert, v1);
        let distanceEdge1Pt2: number = WireframeMathUtil.getDistance(vert, v2);
        let distanceEdge2Pt1: number = WireframeMathUtil.getDistance(vert, v3);
        let distanceEdge2Pt2: number = WireframeMathUtil.getDistance(vert, v4);

        let newEdge1: PV.Edge;
        let newEdge2: PV.Edge;
        let vertexRemove1: PV.Vertex;
        let vertexRemove2: PV.Vertex;
        if (distanceEdge1Pt1 < distanceEdge1Pt2) {
            newEdge1 = new PV.Edge(vert.id, v2.id);
            vertexRemove1 = v1;
        }
        else {
            newEdge1 = new PV.Edge(vert.id, v1.id);
            vertexRemove1 = v2;
        }

        if (distanceEdge2Pt1 < distanceEdge2Pt2) {
            newEdge2 = new PV.Edge(vert.id, v4.id);
            vertexRemove2 = v3;
        }
        else {
            newEdge2 = new PV.Edge(vert.id, v3.id);
            vertexRemove2 = v4;
        }
        if (newEdge1.vertex1Id == newEdge1.vertex2Id) return;
        if (newEdge2.vertex1Id == newEdge2.vertex2Id) return;

        let vertexElement1: WireframeElement = this.findWireframeElement(WireframeElementType.vertex, vertexRemove1.id);
        let vertexElement2: WireframeElement = this.findWireframeElement(WireframeElementType.vertex, vertexRemove2.id);
        this.deleteWireframeElement(edge1);
        this.deleteWireframeElement(edge2);
        this.deleteWireframeElement(vertexElement1);
        this.deleteWireframeElement(vertexElement2);
        this.addEdge(newEdge1);
        this.wireframe.addEdge(newEdge2);
        this.addEdge(newEdge1, null);
        this.addEdge(newEdge2, null);

        //this.app.selectedElements = [];
        //this.app.mainController.toggleActionMode(1);
    }

    getLargestPlane(vertex: PV.Vertex) {
        let planes: number[] = this.wireframe.findPlanesForVertex(vertex.id);
        if (planes.length < 1) return null;
        let areaProp: AreaProperty = this.wireframe.findProperty(AreaProperty, true) as AreaProperty;
        planes.sort((plane1Id, plane2Id) => {
            let area1 = areaProp.getValue(this.wireframe.planes[plane1Id]);
            let area2 = areaProp.getValue(this.wireframe.planes[plane2Id]);
            return area1 - area2;
        }).reverse();
        return this.wireframe.planes[planes[0]];
    }

    alignWithGravity(plane: PV.Plane) {
        this.wireframe.updatePlaneNormal(plane);
        let planeNormal: THREE.Vector3 = this.wireframe.getPlane3(plane.id).normal;
        let gravVec: THREE.Vector3 = this.app.getGravityPlane().normal;
        if (gravVec.dot(planeNormal) < 0) {
            this.wireframe.flipNormal(plane);
        }
    }

    alignAllWithGravity() {
        console.log("alignAllWithGravity");
        Object.values(this.wireframe.filterGeometry(this.wireframe.planes)).forEach((p: PV.Plane) => {
            this.alignWithGravity(p)
        });
    }

    getGravityVector():THREE.Vector3 {
        let m = new THREE.Matrix4().getInverse(this.object.matrixWorld)
        let pos = new THREE.Vector3().applyMatrix4(m)
        let vec = new THREE.Vector3(0, -1, 0).applyMatrix4(m)
        let grav = vec.sub(pos)
        return grav
    }

    getGroundPlane():THREE.Plane {
        return this.app.getProjectGroundPlane().applyMatrix4(new THREE.Matrix4().getInverse(this.object.matrix))
    }

    attachSurface() {
        let that = this
        let pick = new ElementPickOperation();
        pick.message = "Select a parent plane to attach to, or Escape to cancel";
        pick.isElementPickable.push(function (plane) {
            if (!(plane instanceof PlaneElement)) return false;
            if (plane.pvObject.parentId != null) return false;

            // don't allow pick on already selected surface
            for (let i in that.app.selectedElements) {
                let selectedElement = that.app.selectedElements[i];
                if (selectedElement == plane) {
                    return false;
                }
            }
            return true;
        });
        pick.onElementPicked.push(function (el) {
            if (!el) return;
            if (!(el instanceof PlaneElement)) return;
            if (el.pvObject.parentId != null) return;
            that.app.selectedElements.forEach(function (selectedElement) {
                if (!(selectedElement instanceof PlaneElement)) return;
                console.log("attaching plane " + selectedElement.pvObject.id + " to parent " + el.pvObject.id);
                selectedElement.pvObject.parentId = el.pvObject.id;
            });
            that.redrawWireframe();
            that.app.endElementPick();
        });
        this.app.startElementPick(pick);
    }

    pickPlanePrimaryEdge() {
        let that = this
        let pick = new ElementPickOperation();
        pick.message = "Select a plane primary edge, or Escape to cancel";
        let planes: PlaneElement[] = this.app.selectedElements.filter(function (el) {
            return el.pvObject.pvType == WireframeElementType.plane;
        }) as PlaneElement[];
        pick.isElementPickable.push(function (el:WireframeElement) {
            if (el.pvObject.pvType != WireframeElementType.edge) return false;
            let isPlaneEdge = false;
            for (let plane of planes) {
                if (plane.pvObject.edgeIds.includes(el.pvObject.id)) {
                    isPlaneEdge = true;
                    break;
                }
            }
            return isPlaneEdge;

        });
        pick.onElementPicked.push(function (el) {
            for (let plane of planes) {
                if (plane.pvObject.edgeIds.includes(el.pvObject.id)) {
                    plane.pvObject.primaryEdgeId = el.pvObject.id;
                }
            }
            that.redrawWireframe();
            that.app.endElementPick();
        });
        this.app.startElementPick(pick);
    }

    selectChildPlanes() {
        let planes = this.app.selectedElements.filter(function (el) {
            return el.pvObject.pvType == WireframeElementType.plane;
        }) as PlaneElement[];
        let parentPlaneIds = [];
        planes.forEach(function (el) {
            let parentId = el.pvObject.parentId;
            if (null == parentId) parentId = el.pvObject.id;
            if (null != parentId && !parentPlaneIds.includes(parentId)) parentPlaneIds.push(parentId);
        });
        console.log("parentIds", parentPlaneIds);
        this.app.selectWireframeElement(null);
        let planesToSelect = this.wireframeElements.filter(function (el) {
            if (el.pvObject.pvType == WireframeElementType.plane && parentPlaneIds.includes((el as PlaneElement).pvObject.parentId)) return true;
        }) as PlaneElement[];
        this.app.highlightWireframeElement(null);
        this.app.selectWireframeElement(planesToSelect, true);
    }

    areVertsInPolygon(testVertIds: number[], plane: PV.Plane): boolean[] {
        let result: boolean[] = [];
        let rot: THREE.Quaternion = new THREE.Quaternion();
        rot.setFromUnitVectors(this.wireframe.getPlane3(plane.id).normal, new THREE.Vector3(0, 0, 1));
        let planeVerts: THREE.Vector3[] = [];
        let testVerts: THREE.Vector3[] = [];
        for (let planeVertId of plane.vertexIds) {
            let v: THREE.Vector3 = this.wireframe.getVert3(planeVertId);
            v.applyQuaternion(rot)
            planeVerts.push(v);
        }
        for (let testVertId of testVertIds) {
            let v: THREE.Vector3 = this.wireframe.getVert3(testVertId);
            v.applyQuaternion(rot)
            testVerts.push(v);
        }
        for (let i = 0; i < testVerts.length; i++) {
            result.push(WireframeUtils.point2DInsidePoly(testVerts[i], planeVerts));
        }
        return result;
    }

    unifyNormals(planes: PV.Plane[], currentPlane: PV.Plane) {
        let that = this;
        while (planes.length > 1) {
            if (null == currentPlane) currentPlane = planes.pop();
            that.recurseUnify(planes, currentPlane);
            currentPlane = null;
        }
    }

    recurseUnify(planes: PV.Plane[], currentPlane: PV.Plane) {
        let that = this;
        if (null != currentPlane.parentId) {
            let dp = this.wireframe.getPlane3(currentPlane.id).normal.dot(this.wireframe.getPlane3(currentPlane.parentId).normal);
            if (dp < 0) this.wireframe.flipNormal(currentPlane);
        }
        currentPlane.edgeIds.forEach((edgeId: number) => {
            this.wireframe.findPlanesForEdge(edgeId).forEach((planeId: number) => {
                if (currentPlane.id == planeId) return;
                let idx = planes.findIndex((p: PV.Plane) => {
                    return p.id == planeId
                });
                if (idx < 0) return;
                let otherPlane = planes[idx];
                //console.log("testing unify pair " + currentPlane.id + ":" + planeId);
                planes.splice(idx, 1);
                if (!this.wireframe.areNormalsConsistent(currentPlane, otherPlane, this.wireframe.edges[edgeId])) {
                    this.wireframe.flipNormal(otherPlane);
                }
                that.recurseUnify(planes, otherPlane);

            })
        })
    }

    disposeNode(node) {
        if (node instanceof THREE.Mesh) {
            node = node as Mesh;
            if (node.geometry) {

                // don't dispose reused geometries
                if (Object.values(GeometryCache.cache).indexOf(node.geometry) != -1) {

                } else {
                    node.geometry.dispose();
                }
            }

            if (node.material) {
                {
                    if (node.material.map) node.material.map.dispose();
                    if (node.material.lightMap) node.material.lightMap.dispose();
                    if (node.material.bumpMap) node.material.bumpMap.dispose();
                    if (node.material.normalMap) node.material.normalMap.dispose();
                    if (node.material.specularMap) node.material.specularMap.dispose();
                    if (node.material.envMap) node.material.envMap.dispose();

                    node.material.dispose();   // disposes any programs associated with the material
                }
            }
        }
    }

    disposeHierarchy(node) {
        if (!node || !node.children) return;
        try {
            for (let i = node.children.length - 1; i >= 0; i--) {
                let child = node.children[i];
                if (Object.values(GeometryCache.cache).indexOf(child) != -1) continue

                this.disposeHierarchy(child);
                //console.log("dispose ", child);
                this.disposeNode(child);
                node.remove(node.children[i]);
                //callback (child);
            }
        } catch (e) {
            console.error("Error disposing node " + node, e);
        }
    }

    getConnectedLines(el: WireframeElement) {
        let lineElements = [];
        let mergeDedupe = function (elements, newElements) {
            newElements.forEach(function (_el) {
                if (!elements.includes(_el)) elements.push(_el);
            });
        };

        switch (el.pvObject.pvType) {
            case (WireframeElementType.vertex):
                lineElements = this.wireframeElements.filter(function (_el) {
                    if (_el.pvObject.pvType != WireframeElementType.edge) return false
                    let edge = _el as EdgeElement
                    return (el.pvObject.id == edge.pvObject.vertex1Id || el.pvObject.id == edge.pvObject.vertex2Id);
                });
                break;
            case (WireframeElementType.edge):
                let vert1El: VertexElement = this.findWireframeElement(WireframeElementType.vertex, (el as EdgeElement).pvObject.vertex1Id) as VertexElement;
                let vert2El: VertexElement = this.findWireframeElement(WireframeElementType.vertex, (el as EdgeElement).pvObject.vertex2Id) as VertexElement;
                lineElements = this.getConnectedLines(vert1El);
                mergeDedupe(lineElements, this.getConnectedLines(vert2El));
                break;
            case (WireframeElementType.plane):
                (el as PlaneElement).pvObject.vertexIds.forEach(function (vertId) {
                    let vertEl: WireframeElement = this.findWireframeElement(WireframeElementType.vertex, vertId);
                    mergeDedupe(lineElements, this.getConnectedLines(vertEl));
                });
                break;
        }
        return lineElements;
    }

    cleanUpReferences(el: WireframeElement) {
        let elementLists = [
            this.wireframeElements,
            this.app.selectedElements,
            this.app.lockedElements,
            this.app.highlightedElements,
            this.app.copyBuffer
        ];
        this.app.validationResults.forEach(function (result: ValidationResult) {
            elementLists.push(result.elements);
        });
        elementLists.forEach(function (list: WireframeElement[]) {
            for (let i = list.length - 1; i >= 0; i--) {
                let o = list[i];
                if (o == el) {
                    list.splice(i, 1);
                }
            }
        });
        delete this.elementMap[el.getHashCode()]
        el.aboutToBeRemoved()
        this.object.remove(el)
    }

    wireFrameToJson() {
        let bboxProp = this.wireframe.findProperty(Property, true, "boundingBox")
        bboxProp.isVisible = false
        this.wireframeElements.forEach((el:WireframeElement) => {
            if (el instanceof WidgetElement) {
                let bbox = new THREE.Box3()
                bbox.setFromObject(el)
                bbox = this.toWireframeCRS(bbox)
                bboxProp.setValue(el, bbox)
                //console.log(el.toString() + " bbox", bbox)
            }
        })
        let jsonObject = this.wireframe.toJson();
        return jsonObject;
    }

    getNextElement(forward:boolean = true):WireframeElement {
        let current = this.app.selectedElements.find((e) => e instanceof VertexElement )
        if (!current) current = this.wireframeElements.find((e) => e instanceof VertexElement )
        if (!current) return
        let vertId = current.pvObject.id
        let vertIds = (this.wireframeElements.filter((e) => e instanceof VertexElement).map((e) => e.pvObject.id )).sort()
        let vertIdx = Number(vertIds.find((id) => Number(id) == vertId))
        let newVertIdx = forward ? vertIdx + 1 : vertIdx - 1
        if (newVertIdx < 0) {
            newVertIdx = vertIds.length - 1
        }  else if (newVertIdx >= vertIds.length) {
            newVertIdx = 0
        }
        let newVertId = Number(vertIds[newVertIdx])
        return this.findWireframeElement(WireframeElementType.vertex, newVertId)
    }

    getOptimiumViewingPlaneForElements(elements:WireframeElement[]):THREE.Plane {
        let planes = elements.map((el) => el.getOptimumViewingPlane()).filter((p) => p != null)

        let normal = new THREE.Vector3()
        let constant = 0
        if (planes.length > 0) {
            planes.forEach((p) => {
                normal.add(p.normal.divideScalar(planes.length))
                constant += p.constant
            })
        } else {
            normal.set(0, 1, -.1)
            constant = 1
        }
        normal.normalize()
        return new THREE.Plane(normal, constant)
    }
}