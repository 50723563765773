import {
    ChangeDetectorRef,
    Component, ElementRef,
    Input, ViewChild
} from '@angular/core';
import WireframeElementCollection from "../../../collections/wireframeElements/wireframeElement.collection";
import {PlaneAnnotationProperty} from "../../../models/property/planeAnnotationProperty";
import {PropertyService} from "../../../services/properties/property.service";
import {MatAutocomplete, MatOption} from "@angular/material";

@Component({
    selector: 'plane-selection-palette',
    styles: [require('./planeSelectionPalette.component.scss')],
    template: require('./planeSelectionPalette.component.html')
})
export default class PlaneSelectionPaletteComponent {
    @ViewChild(MatAutocomplete, {static: false}) matAutocomplete: MatAutocomplete;
    @ViewChild("planeAnnotationAutocompleteInput", {static: false}) annotationClassInput: ElementRef;
    @Input() wireframeElements: WireframeElementCollection;
    planeAnnotationPropertyValues: any[] = [];
    private _annotationClassLabel: string = "";

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private propertyService: PropertyService
    ) {
        // populate annotation values
        let planeAnnotationProperty = new PlaneAnnotationProperty();
        planeAnnotationProperty.values.forEach((planeAnnotationPropertyValue) => {
            this.planeAnnotationPropertyValues.push(planeAnnotationPropertyValue);
        });
    }

    get annotationClassLabel(): string {
        return this._annotationClassLabel;
    }

    set annotationClassLabel(annotationClassLabel: string) {
        this._annotationClassLabel = annotationClassLabel;
    }

    get filteredAnnotationClasses(): Array<any> {
        let filterValue = null;
        if (this.annotationClassInput && this.annotationClassInput.nativeElement && this.annotationClassInput.nativeElement.value !== null) {
            filterValue = this.annotationClassInput.nativeElement.value;
        } else {
            filterValue = this.annotationClassLabel;
        }
        let filteredClass: Array<any> = filterValue
            ? this.planeAnnotationPropertyValues.filter(propertyValue => propertyValue.label.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
            : this.planeAnnotationPropertyValues.filter(propertyValue => true);
        return filteredClass;
    }

    findAnnotationPropertyValue(annotationClassLabel:string):any {
        let annotationPropertyValue = this.planeAnnotationPropertyValues.find((propertyValue) => {
            return propertyValue.label == annotationClassLabel;
        });
        return annotationPropertyValue;
    }

    onAnnotationClassChanged(event) {
        let annotationClassLabel = (event.option as MatOption).value;
        let annotationPropertyValue = this.findAnnotationPropertyValue(annotationClassLabel);
        if (!annotationPropertyValue) return;
        this.annotationClassLabel = annotationPropertyValue.label;
        this.onAddAnnotation(annotationPropertyValue.value);
        this.annotationClassLabel = ""; //reset
        this.detectChanges();
    }

    onAddAnnotation(annotationKey: string) {
        let planeAnnotationProperty = new PlaneAnnotationProperty();
        let existingPlaneAnnotationProperties = this.propertyService.getPropertiesByType(planeAnnotationProperty.type);
        if (existingPlaneAnnotationProperties && existingPlaneAnnotationProperties.length > 0) {
            // get the existing property
            planeAnnotationProperty = existingPlaneAnnotationProperties[0] as PlaneAnnotationProperty;
        }

        // add the property to the wireframe elements
        this.propertyService.addProperty(planeAnnotationProperty, this.wireframeElements);

        // update the property value
        this.propertyService.updateProperty(planeAnnotationProperty, this.wireframeElements, annotationKey);
    }

    onAnnotationAutoCompleteClosed(event) {
        // ignore if something is selected
        if (this.matAutocomplete) {
            let selectedOption = this.matAutocomplete.options.find((option) => {
                if (!option) return false;
                return option.selected;
            });

            if (selectedOption) return;
        }
    }

    onInputKeyUp(){
        if (this.annotationClassInput && this.annotationClassInput.nativeElement && this.annotationClassInput.nativeElement.value !== null) {
            this.annotationClassLabel = this.annotationClassInput.nativeElement.value;
        }
        this.detectChanges();
    }

    detectChanges(){
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

}