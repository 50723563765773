import {Broadcaster} from "../Broadcaster";
import {Resource} from "../resourceManager";
import {WireframeApplication} from "./wireframeApplication";

export class Layer {
    app:WireframeApplication
    name: String;
    label: String;
    resource:Resource
    public supportsEditing = false
    public supportsInteraction = false

    parentLayer:Layer
    children:Layer[] = []

    public isLayerVisible:boolean = true
    public isExpandable:boolean = true

    private _isVisible = true
    private _isEditable = true
    private _isInteractionEnabled = true

    public eventEmitter:Broadcaster = new Broadcaster()

    get isInteractionEnabled() {
        return this.supportsInteraction && this._isInteractionEnabled
    }

    set isInteractionEnabled(e) {
        if (!this.supportsInteraction) return
        let changed = e != this._isInteractionEnabled
        this._isInteractionEnabled = e
        if (changed) this.eventEmitter.broadcast("interactionChanged", this)
    }

    get isEditable() {
        return this.supportsEditing && this._isEditable
    }

    set isEditable(e) {
        let changed = e != this._isEditable
        this._isEditable = e
        if (changed) this.eventEmitter.broadcast("editabilityChanged", this)
    }

    get isVisible() {
        return this._isVisible
    }

    set isVisible(v) {
        let changed = v != this._isVisible
        this._isVisible = v
        if (changed) {
            this.eventEmitter.broadcast("visibilityChanged", this)
        }
    }

    addChildLayer(child:Layer) {
        if (this == child) return
        if (child.parentLayer) {
            child.parentLayer.children = child.parentLayer.children.filter((layer:Layer) => layer != child)
        }
        if (!this.children.includes(child)) {
            this.children.push(child)
        }
        child.parentLayer = this
        this.app.sceneManager.eventEmitter.broadcast("layersChanged")
    }

    getParents() {
        var parents: Layer[] = [];
        var currentLayer: Layer = this;
        while (currentLayer.parentLayer != null) {
            parents.push(currentLayer);
            currentLayer = currentLayer.parentLayer;
        }
        return parents;
    }

    traverseChildren():Layer[] {
        let allChildren = []
        function recurse(layers:Layer[], l:Layer) {
            layers.push(l)
            l.children.forEach((child) => { recurse(layers, child)})
        }
        recurse(allChildren, this)
        return allChildren
    }

    constructor(app:WireframeApplication, name: string, label: string) {
        this.app = app;
        this.name = name;
        this.label = label;
    }
    init() {}
}