import {
    Component, Input
} from '@angular/core';
import {InteractiveFilterExpressionModel} from "../../models/interactive/interactiveFilterExpression.model";
import {InteractiveFilterTypeModel} from "../../models/interactive/interactiveFilterType.model";
import {InteractiveFilterModel} from "../../models/interactive/interactiveFilter.model";
import {InteractiveModel} from "../../models/interactive/interactive.model";

@Component({
    selector: 'ptv-interactive-filter-expression',
    styles: [require('./interactiveFilterExpression.component.scss')],
    template: require('./interactiveFilterExpression.component.html')
})
export default abstract class InteractiveFilterExpressionComponent<T extends InteractiveModel> {
    @Input() interactiveFilterExpressionModel: InteractiveFilterExpressionModel<T> = new InteractiveFilterExpressionModel<T>();
    @Input() interactiveFilterTypes: Array<InteractiveFilterTypeModel>;

    constructor() {
    }

    addFilter(interactiveFilterModel: InteractiveFilterModel<T>) {
        this.interactiveFilterExpressionModel.addFilter(interactiveFilterModel);
    }

    removeFilter(interactiveFilterModel: InteractiveFilterModel<T>) {
        this.interactiveFilterExpressionModel.removeFilter(interactiveFilterModel);
    }

    abstract onAddFilterClicked(event, filterType: InteractiveFilterTypeModel);
}