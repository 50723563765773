import {Theme} from "../services/themes/theme.service";

export const RedTheme: Theme = {
    name: 'red',
    properties: {
        '--ptv-theme-primary-color': '#dc3545',
        '--ptv-theme-error-color': '#dc3545',
        '--ptv-theme-host-background': '#000',
        '--ptv-theme-host-text-foreground': '#FEFEFE',
        '--ptv-theme-host-text-focused-foreground': '#FFFFFF',
        '--ptv-theme-host-form-accent': '#c1272c',
        '--ptv-theme-host-form-label-foreground': '#cecac8',
        '--ptv-theme-host-dialog-foreground': '#f7f5f4',
        '--ptv-theme-host-dialog-background': '#343a40',
        '--ptv-theme-host-header-background': '#212121',
        '--ptv-theme-host-header-logo-foreground': '#dc3545',
        '--ptv-theme-host-header-nav-active-accent': '#c1272c',
        '--ptv-theme-host-header-nav-icon-foreground': '#FFFFFF',
        '--ptv-theme-app-background': '#000',
        '--ptv-theme-app-foreground': '#FEFEFE',
        '--ptv-theme-app-primary-action-foreground': '#FFFFFF',
        '--ptv-theme-app-primary-action-background': '#c1272c',
        '--ptv-theme-app-header-background': '#000',
        '--ptv-theme-app-header-foreground': '#FEFEFE',
        '--ptv-theme-list-container-background': '#3d3c3c',
        '--ptv-theme-list-item-background': '#403f3f',
        '--ptv-theme-list-item-header-foreground': '#FFFFFF',
        '--ptv-theme-list-item-paragraph-foreground': '#78797b',
        '--ptv-theme-list-item-icon-foreground': '#FFFFFF',
        '--ptv-theme-swatch-0-background-0': '#101317',
        '--ptv-theme-swatch-0-foreground-0': '#92949a',
        '--ptv-theme-swatch-0-foreground-1': '#222324',
        '--ptv-theme-swatch-1-background-0': '#2e2f30',
        '--ptv-theme-swatch-1-foreground-0': '#9b9fa3',
        '--ptv-theme-swatch-1-foreground-1': '#353638',
        '--ptv-theme-swatch-2-background-0': '#3f4042',
        '--ptv-theme-swatch-2-foreground-0': '#ced3d9',
        '--ptv-theme-swatch-2-foreground-1': '#9b9fa3',
        '--ptv-theme-swatch-3-background-0': '#e1e3e6',
        '--ptv-theme-swatch-3-foreground-0': '#464952',
        '--ptv-theme-swatch-3-foreground-1': '#9d9fa4'
    }
};


/*
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #c1272c;
    --secondary: #212121;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --ui-1: #95292b;
    --ui-2: #000;
    --ui-3: #3d3c3c;
    --ui-4: #78797b;
    --ui-5: #cecac8;
    --ui-6: #f7f5f4;
    --ui-7: #403f3f;
    --ui-8: #fff;
    --ui-9: #fff;
    --ui-10: #fff;
 */