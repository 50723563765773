import {WireframeApplication} from "./wireframeApplication";
import {WireframeElement} from "./wireframeElement";
import {WireframeElementType} from "./wireframeElementType";
import {Tool} from "./tool";
import {EdgeElement} from "./edgeElement";
import {VertexElement} from "./vertexElement";
import {ScenePicker} from "./scenePicker";
import PointCloudLayer from "./pointCloudLayer";
import WireframeLayer from "./wireframeLayer";
import {PV} from "../wireframe";
import {DistanceProperty} from "../models/property/distanceProperty";
import {Object3DLayer} from "./object3DLayer";
import {WireframeRaycastHit} from "./wireframeRaycastHandler";
import {PointCloudRaycastHit} from "./pointCloudRaycastHandler";

class CreateMeasurementState {
    vert1:VertexElement
    isVert1Placed:boolean = false
    vert2:VertexElement
    edge:EdgeElement
}

class ElementDrag {
    elements:WireframeElement[] = []
    startPos:THREE.Vector3
}

export default class MeasureTool extends Tool {

    cmState:CreateMeasurementState
    elementDrag:ElementDrag
    picker:ScenePicker = new ScenePicker()

    constructor(app: WireframeApplication) {
        super(app);
        this.toolName = "MeasureTool";
    }

    private setPickLayers(layerType) {
        this.picker.layers = this.app.sceneManager.getAllLayers().filter((layer) => {
            return (layer instanceof layerType) && layer.isInteractionEnabled
        }) as Object3DLayer[]
    }

    private pickMeasureElements():WireframeElement[] {
        let that = this
        this.setPickLayers(WireframeLayer)
        let measureElements = that.app.wireframeLayer.wireframeElements.filter((el) => {
            return el instanceof VertexElement && that.getMeasurementEdge(el) != null
        })
        return this.picker.pickElements(this.getRaycaster(), measureElements).map((hit) => {
            return (hit as WireframeRaycastHit).element
        })
    }

    private pickPointCloud():THREE.Vector3 {
        let that = this
        this.setPickLayers(PointCloudLayer)
        let hits = this.picker.pick(this.getRaycaster())
        if (hits.length > 0) return hits[0].intersect.point
        return null
    }

    onToolActivated() {
        this.setPickLayers(WireframeLayer)
        this.highlightableFunc = (el:WireframeElement) => {
            if (!(el instanceof EdgeElement || el instanceof VertexElement)) return false
            return true
        }
    }

    onMouseMove(event: MouseEvent) {
        super.onMouseMove(event);
        this.app.highlightWireframeElement(null)
        if (this.cmState) {
            let point = this.pickPointCloud()
            if (point) {
                if (!this.cmState.vert2) {
                    this.cmState.vert1.setPosition(point)
                    this.cmState.vert1.updateGeometry()
                } else {
                    this.cmState.vert2.setPosition(point)
                    this.cmState.vert2.updateGeometry()
                    this.cmState.vert2.updateDependentGeometry()
                }

            }
        } else if (this.elementDrag) {
            this.isHandlingMouseMove = true
            let point = this.pickPointCloud()
            if (point) {
                this.elementDrag.elements.forEach((el) => {
                    el.setPosition(point)
                    el.updateGeometry()
                    el.updateDependentGeometry()
                })
            }
        } else {
            let elements = this.pickMeasureElements()
            this.app.highlightWireframeElement(null)
            if (elements.length > 0) this.app.highlightWireframeElement(elements[0])
        }
        this.app.wireframeChanged()
        this.app.wireframeLayer.redrawWireframe()
    }

    onKeyUp(event: KeyboardEvent) {
        super.onKeyUp(event)
        switch (event.code) {
            case "Escape":
                this.cancelCreateMeasurement()
                break
        }
    }

    onMouseDown(event:MouseEvent) {
        if (!super.onMouseDown(event)) return false
        super.onMouseDown(event)
        if (this.cmState) {

        } else {
            let elements = this.pickMeasureElements()
            if (elements.length > 0) {
                this.elementDrag = new ElementDrag()
                this.elementDrag.elements.push(elements[0])
            }
        }
    }

    onMouseUp(event: MouseEvent) {
        super.onMouseUp(event)
        this.elementDrag = null
        if (this.isMouseDrag()) return
        if (event.button == 2) {
            this.cancelCreateMeasurement()
            return
        }
        if (event.button != 0) return

        let that = this

            if (this.cmState) {
                let point = this.pickPointCloud()
                if (point && !this.cmState.isVert1Placed) {
                    this.cmState.vert1.setPosition(point)
                    this.cmState.isVert1Placed = true
                    this.cmState.vert2 = this.app.wireframeLayer.addVertex()
                    this.cmState.vert2.setPosition(point.clone().addScalar(.0001))
                    this.cmState.edge = this.app.wireframeLayer.addEdge(new PV.Edge(this.cmState.vert1.pvObject.id, this.cmState.vert2.pvObject.id))
                    this.app.sceneService.measurementService.createMeasurement(this.cmState.edge)
                    this.app.pickMessage = "Place the second measurement endpoint, or Escape to cancel"
                    this.cmState.vert2.updateGeometry()
                    this.cmState.vert2.updateDependentGeometry()
                } else {
                    this.cmState = null
                    this.app.pickMessage = null
                }
                this.app.wireframeChanged()
                this.app.wireframeLayer.redrawWireframe()
            } else {
                this.app.selectWireframeElement(null)
                let elements = that.pickMeasureElements()
                if (elements.length > 0) {
                    this.app.selectWireframeElement([elements[0]], true)
                }
            }
    }

    getMeasurementEdge(el:WireframeElement):EdgeElement {
        let distanceProp = this.app.wireframeLayer.wireframe.findProperty(DistanceProperty, true)
        if (el instanceof EdgeElement) {
            if (distanceProp.getValue(el.pvObject)) return el
        } else if (el instanceof VertexElement) {
            let edges = this.app.wireframeLayer.wireframe.findEdgesForVertex(el.pvObject.id)
            for (let i = 0; i < edges.length; i++) {
                let edge = this.app.wireframeLayer.findWireframeElement(WireframeElementType.edge, edges[i]) as EdgeElement
                if (distanceProp.getValue(edge.pvObject)) return edge
            }
        }
    }

    startCreateMeasurement() {
        if (this.cmState) {
            this.cancelCreateMeasurement()
        }
        this.cmState = new CreateMeasurementState()
        this.cmState.vert1 = this.app.wireframeLayer.addVertex()
        this.app.wireframeChanged()
        this.app.pickMessage = "Place the first measurement endpoint, or Escape to cancel"
    }

    cancelCreateMeasurement() {
        if (this.cmState) {
            this.app.pickMessage = null
            if (this.cmState.edge) this.app.wireframeLayer.deleteEdge(this.cmState.edge, false)
            if (this.cmState.vert1) this.app.wireframeLayer.deleteVertex(this.cmState.vert1)
            if (this.cmState.vert2) this.app.wireframeLayer.deleteVertex(this.cmState.vert2)
            this.cmState = null
            this.app.wireframeChanged()
        }
    }
}