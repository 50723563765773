import {InteractiveModel} from "./interactive.model";
import {InteractiveSelectEventModel} from "./interactiveSelectEvent.model";

export class InteractiveSelectionModel<T extends InteractiveModel> {

    private readonly getSelectableItems:() => T[];

    constructor(getSelectableItems:() => T[]){
        this.getSelectableItems = getSelectableItems;
    }

    private getSelectionIndexOf(targetItem: T): number {
        return this.getSelectableItems().indexOf(targetItem)
    }

    private getSelectionAt(index: number): T {
        return this.getSelectableItems()[index];
    }

    private getSelectedItems(): T[]{
        let selectedItems:T[] = [];
        let selectableItems = this.getSelectableItems();
        if(!selectableItems) return selectedItems;
        selectableItems.forEach((item) => {
            if(item.selected) selectedItems.push(item);
        });
        return selectedItems;
    }

    private getPreSelectionState(targetItem: T) {
        let selectedRange = {
            currentIndex: null,
            currentModel: targetItem,
            minSelectedIndex: null,
            minSelectedModel: null,
            maxSelectedIndex: null,
            maxSelectedModel: null
        };
        selectedRange.currentIndex = this.getSelectionIndexOf(targetItem);
        let selectedItems = this.getSelectedItems();

        // remove the target
        if (targetItem.selected) {
            let selectedIndex = selectedItems.indexOf(targetItem);
            if (selectedIndex > -1) {
                selectedItems.splice(selectedIndex, 1);
            }
        }

        if (!selectedItems.length) return selectedRange;
        selectedRange.minSelectedIndex = Math.min(...selectedItems.map(item => this.getSelectionIndexOf(item)));
        selectedRange.minSelectedModel = selectedRange.minSelectedIndex > -1 ? this.getSelectionAt(selectedRange.minSelectedIndex) : null;
        selectedRange.maxSelectedIndex = Math.max(...selectedItems.map(item => this.getSelectionIndexOf(item)));
        selectedRange.maxSelectedModel = selectedRange.maxSelectedIndex > -1 ? this.getSelectionAt(selectedRange.maxSelectedIndex) : null;
        return selectedRange;
    }

    private extendSelectionTo(targetItem: T) {
        let selectionSpace = this.getPreSelectionState(targetItem);
        if (selectionSpace.currentIndex < selectionSpace.minSelectedIndex) {
            for (let i = selectionSpace.currentIndex; i <= selectionSpace.minSelectedIndex; i++) {
                let currentItem = this.getSelectionAt(i);
                if (currentItem == targetItem) continue;
                if (!currentItem.selected) currentItem.setSelected(true, true, false);
            }
        } else if (selectionSpace.currentIndex > selectionSpace.maxSelectedIndex) {
            for (let i = selectionSpace.maxSelectedIndex; i <= selectionSpace.currentIndex; i++) {
                let currentItem = this.getSelectionAt(i);
                if (currentItem == targetItem) continue;
                if (!currentItem.selected) currentItem.setSelected(true, true, false);
            }
        } else {
            for (let i = selectionSpace.minSelectedIndex; i <= selectionSpace.currentIndex; i++) {
                let currentItem = this.getSelectionAt(i);
                if (currentItem == targetItem) continue;
                if (!currentItem.selected) currentItem.setSelected(true, true, false);
            }
        }
    }

    public selectionChangingHandler(interactiveSelectEventModel: InteractiveSelectEventModel<T>): void {
        if (!interactiveSelectEventModel) return;
        let append = interactiveSelectEventModel.append;
        let toggle = interactiveSelectEventModel.toggle;
        let item = interactiveSelectEventModel.interactiveModel;
        if (!item) return;
        let selected = item.selected;
        if (!selected) {
            // item is about to change to selected
            if (append) {
                // items are being appended above the top of the selection
                let selectionRange = this.getPreSelectionState(item);
                if (selectionRange.currentIndex < selectionRange.minSelectedIndex) {
                    // clear below the top of the selection
                    this.getSelectableItems().forEach((currentItem) => {
                        let itemIndex = this.getSelectionIndexOf(currentItem);
                        if (itemIndex <= selectionRange.minSelectedIndex) return;
                        if (currentItem.selected && currentItem !== item) {
                            currentItem.setSelected(false, append, toggle);
                        }
                    });
                }
            } else if (!append && !toggle) {
                // item is not appended or toggled
                this.getSelectableItems().forEach((currentItem) => {
                    // clear other items
                    if (currentItem.selected && currentItem !== item) {
                        currentItem.setSelected(false, append, toggle);
                    }
                });
            }
        }
    }

    public selectionChangedHandler(interactiveSelectEventModel: InteractiveSelectEventModel<T>): void {
        if (!interactiveSelectEventModel) return;
        let append = interactiveSelectEventModel.append;
        let toggle = interactiveSelectEventModel.toggle;
        let item = interactiveSelectEventModel.interactiveModel;
        if (!item) return;
        let selected = item.selected;
        if (selected) {
            // item is selected
            if (append) {
                // extend selection to item
                this.extendSelectionTo(item);
            }
        } else {
            if (!append && !toggle) {
                // item is not appended or toggled
                this.getSelectableItems().forEach((currentItem) => {
                    // clear other items
                    if (currentItem.selected && currentItem !== item) {
                        currentItem.setSelected(false, append, toggle);
                    }
                });
            }
        }
    }
}