import RaycastHandler, {RaycastHit} from "./raycastHandler";
import {Raycaster} from "three";
import * as THREE from "three";
import {WireframeElement} from "./wireframeElement";
import WireframeLayer from "./wireframeLayer";
import {WireframeElementType} from "./wireframeElementType";
import {PlaneElement} from "./planeElement";

export class WireframeRaycastHit extends RaycastHit {
    element:WireframeElement
}

export class WireframeRaycastHandler extends RaycastHandler {

    constructor(private layer:WireframeLayer) {
        super()
    }

    handleRaycast(raycaster:Raycaster) {
        return this.handleRaycastUsingCandidates(raycaster, this.layer.wireframeElements)
    }

    handleRaycastUsingCandidates(raycaster: Raycaster, candidateElements:WireframeElement[]) {
        let that = this
        let results: WireframeRaycastHit[] = [];
        let elements = [];

        if (!this.layer.object) return results;
        let intersectCandidates:THREE.Object3D[] = []
        candidateElements.forEach((el:WireframeElement) => {
            if (!el.visible) return
            if (el.hitGeometry) intersectCandidates.push(...el.hitGeometry)
        })

        let t = performance.now()
        let intersects = raycaster.intersectObjects(intersectCandidates, true);
        //if (app.debug) console.log("intersectObjects in " + (performance.now() - t));

        intersects.forEach(function (intersect) {
            let el: WireframeElement = that.findParentWireframeElement(intersect.object);

            if (el) {
                if (!el.isPickable) return;
                if (el.visible && el.isPickable && !elements.includes(el)) {
                    let hit = new WireframeRaycastHit();
                    hit.element = el.wireframeLayer.findWireframeElement(el.pvObject.pvType, el.pvObject.id);
                    hit.intersect = intersect;
                    results.push(hit)
                    elements.push(el)
                }
            }
        });
        results = this.clusterHitsByDistance(results, .5);
        return results
    }

    private clusterHitsByDistance(hits: WireframeRaycastHit[], clusterDistance):WireframeRaycastHit[] {
        if (hits.length < 1) return hits;
        let currentCluster: WireframeRaycastHit[] = [hits[0]];
        let clusters: WireframeRaycastHit[][] = [currentCluster];
        let currentClusterStartDistance: number = hits[0].intersect.distance;
        for (let i = 1; i < hits.length; i++) {
            let hit: WireframeRaycastHit = hits[i];
            if (hit.intersect.distance - currentClusterStartDistance > clusterDistance) {
                currentClusterStartDistance = hit.intersect.distance;
                currentCluster = [hit];
                clusters.push(currentCluster);
            } else {
                currentCluster.push(hit);
            }
        }
        //console.log("clusters", clusters);
        let result: WireframeRaycastHit[] = [];
        clusters.forEach(function (cluster: RaycastHit[]) {
            cluster.sort(function (a: WireframeRaycastHit, b: WireframeRaycastHit) {
                if (a.element.pvObject.pvType != b.element.pvObject.pvType) {
                    // This will prioritize vertex > edge > plane
                    return a.element.pvObject.pvType > b.element.pvObject.pvType ? 1 : -1;
                }
                if (a.element.pvObject.pvType == WireframeElementType.vertex || a.element.pvObject.pvType == WireframeElementType.edge) {
                    return a.intersect.distance > b.intersect.distance ? 1 : -1;
                } else {
                    // sort clustered planes by area
                    return (a.element as PlaneElement).pvObject.area > (b.element as PlaneElement).pvObject.area ? 1 : -1;
                }
            })
            cluster.forEach(function (hit: WireframeRaycastHit) {
                result.push(hit);
            })
        })
        //console.log("sorted clusters", result);
        return result;
    }

    private findParentWireframeElement(o:THREE.Object3D):WireframeElement {
        if (!o) {
            return null;
        } else if (o instanceof WireframeElement) {
            return o;
        } else if (o.hasOwnProperty('wireframeElement')) {
            return o["wireframeElement"]
        } else if (o.hasOwnProperty('parent')) {
            return this.findParentWireframeElement(o.parent);
        } else {
            return null;
        }
    }
}