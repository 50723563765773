import {WireframeApplication} from "../../application/wireframeApplication";
import {LogLevel} from "../../application/logLevel";

declare var wfApp: WireframeApplication;

export default ['$q', '$http', function ($q, $http) {
    this.authToken = null;
    this.apiUrl = null;
    this.projectData = {}

    this.getProjectResources = function () {
        return Object.values(this.projectData.resources)
    }

    this.getConfig = function () {
        let deferred = $q.defer();
        let apiUrl = this.apiUrl + 'config';
        $http({
            method: 'GET',
            url: apiUrl,
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
        }).then(function (data) {
            deferred.resolve(data);
        }).catch(function (data) {
            console.error("get config error", data);
        });

        return deferred.promise;
    };

    this.getStepsByProjectId = async (projectId: number) => $http({
        method: 'GET',
        url: `${this.apiUrl}projects/${projectId}/steps`,
        headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
    }).catch(data => {
        console.error("get steps error", data);
    })

    this.getWorkOrderByProjectId = async (projectId: number) => $http({
        method: 'GET',
        url: `${this.apiUrl}projects/${projectId}/work_order`,
        headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
    }).catch(data => {
        console.error("get steps error", data);
    })

    this.stepAction = async (workOrderId: string, stepId: string, action: string, data: any = {}) => $http({
        method: 'POST',
        url: `${this.apiUrl}work_orders/${workOrderId}/steps/${stepId}/${action}`,
        data,
        headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
    }).catch(data => {
        console.error(`${action} step error`, data);
    })

    this.restartProcessingAtStep = (workOrderId: string, stepId: string) =>
        this.stepAction(workOrderId, stepId, 'restartProcessingAtStep')

    this.resumeFromFirstFailedStep = async (workOrderId) => $http({
        method: 'POST',
        url: `${this.apiUrl}work_orders/${workOrderId}/steps/resumeFromFirstFailedStep`,
        data: {},
        headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
    }).catch(data => {
        console.error(`resumeFromFirstFailedStep error`, data);
    })

    this.getCredentials = function (projectId) {
        let deferred = $q.defer();
        let apiUrl = this.apiUrl + 'projects/' + projectId + '/credentials?type=viewer';
        $http({
            method: 'GET',
            url: apiUrl,
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
        }).then(function (data) {
            deferred.resolve(data);
        }).catch(function (data) {
            console.error("get credentials error", data);
        });

        return deferred.promise;
    };

    this.getResources = function (projectId, resourceTypeId) {
        let deferred = $q.defer();

        let apiUrl = this.apiUrl + 'projects/' + projectId + "/resources";
        if (resourceTypeId)
            apiUrl += "?resourceTypeId=" + resourceTypeId;

        $http({
            method: 'GET',
            url: apiUrl,
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
        }).then(function (data) {
            deferred.resolve(data);
        }).catch(function (data) {
            console.error("get resource error", data);
        });

        return deferred.promise;
    };

    this.getWireframe = function (projectId, resourceId) {
        let deferred = $q.defer();
        let apiUrl = this.apiUrl + 'projects/' + projectId + "/wireframe";
        if (null != resourceId) apiUrl += "/" + resourceId;
        $http({
            method: 'GET',
            url: apiUrl,
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
        }).then(function (data) {
            deferred.resolve(data);
        }).catch(function (data) {
            wfApp.log("Error loading wireframe resource " + resourceId + " : " + data.data.message, LogLevel.DANGER);
            console.error("getWireframe error", data);
        });

        return deferred.promise;
    };

    this.getWireframeXls = function (projectId, wireframeData) {
        let deferred = $q.defer();
        let apiUrl = this.apiUrl + 'projects/' + projectId + "/wireframexls";
        //let apiUrl = 'http://localhost:11443/v2/' + 'projects/' + projectId + "/wireframexls";
        $http({
            method: 'POST',
            url: apiUrl,
            data: wireframeData,
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")},
            responseType: "blob"
        }).then(function (data) {
            deferred.resolve(data);
        }).catch(function (data) {
            wfApp.log("Error loading wireframexls resource " + " : " + data.data.message, LogLevel.DANGER);
            console.error("getWireframeXls error", data);
        });

        return deferred.promise;
    };

    this.getProjectAsync = async (projectId) => $http({
        method: 'GET',
        url: this.apiUrl + 'projects/' + projectId,
        headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
    }).catch(function (data) {
        wfApp.log("Error loading project data", LogLevel.DANGER);
        console.error("get project error", data);
    });

    this.getProject = function (projectId) {
        let deferred = $q.defer();
        this.getProjectAsync(projectId)
            .then((data) => deferred.resolve(data))
        return deferred.promise;
    };

    this.putProjectAsync = async (project) =>
        $http({
            method: 'PUT',
            url: this.apiUrl + 'projects/' + project.id,
            data: project,
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
        }).catch(function (data) {
            wfApp.log("Error updating project data", LogLevel.DANGER);
            console.error("put project error", data);
        });

    this.putProject = (project) => {
        let deferred = $q.defer();
        this.putProjectAsync(project).then(data => deferred.resolve(data));
        return deferred.promise;
    };

    // this.getWidgetTemplates = function (projectId) {
    //     let deferred = $q.defer();
    //     $http({
    //         method: 'GET',
    //         url: this.apiUrl + 'projects/' + projectId + '/wireframe/widgets',
    //         headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
    //     }).then(function (data) {
    //         // this can all go away with angular 6 HttpClient/Observable
    //         let wls: WidgetTemplateLibrary[] = []
    //         for (let row of Object.values(data.data.data)) {
    //             let wl = new WidgetTemplateLibrary();
    //             Object.assign(wl, row);
    //             wl.templates = []
    //
    //             for (let subRow of Object.values(row['templates'])) {
    //                 let wt = new WidgetTemplate()
    //                 Object.assign(wt, subRow)
    //                 wl.templates.push(wt)
    //             }
    //
    //             wls.push(wl)
    //         }
    //
    //         deferred.resolve(wls);
    //     }).catch(function (data) {
    //         //wfApp.log("Error loading widget data", LogLevel.DANGER);
    //         //console.error("Error loading widget data", data);
    //     });
    //
    //     return deferred.promise;
    // };


    this.getViewerMetadata = function (id) {
        let deferred = $q.defer();
        $http({
            method: 'GET',
            url: this.apiUrl + 'projects/' + id + '/viewerMetadata',
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
        }).then(function (data) {
            deferred.resolve(data);
        }).catch(function (data) {
            wfApp.log("Error loading viewer metadata", LogLevel.DANGER);
            console.error("get viewerMetadata error", data);
        });

        return deferred.promise;
    };

    this.classifyEdges = function (projectId, wireframe) {
        let deferred = $q.defer();
        $http({
            method: 'POST',
            url: this.apiUrl + 'projects/' + projectId + '/classifyEdges',
            data: wireframe,
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
        }).then(function (data) {
            deferred.resolve(data);
        }).catch(function (data) {
            wfApp.log("Edge Classification error", LogLevel.DANGER);
            console.error("classifyEdges", data);
        });

        return deferred.promise;
    };

    this.applyMeasurements = function (projectId, wireframe, params) {
        let self = this;
        console.log("applyMeasurements", params)
        let deferred = $q.defer();
        $http({
            method: 'POST',
            url: this.apiUrl + 'projects/' + projectId + '/sketch/applyMeasurements?enforceSymmetry=' + params.enforceSymmetry + '&clearExistingProperties=' + params.clearExistingProperties,
            data: wireframe,
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
        }).then(function (data) {
            deferred.resolve(data);
        }).catch(function (data) {
            wfApp.log("Apply Measurements error", LogLevel.DANGER);
            console.error("applyMeasurements", data);
        });
        return deferred.promise;
    };

    this.generateModelFromSketch = function (projectId, wireframe, params) {
        let deferred = $q.defer();
        $http({
            method: 'POST',
            url: this.apiUrl + 'projects/' + projectId + '/sketch/generate3dModel?enforceSymmetry=' + params.enforceSymmetry + '&clearExistingProperties=' + params.clearExistingProperties,
            data: wireframe,
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
        }).then(function (data) {
            deferred.resolve(data);
        }).catch(function (data) {
            wfApp.log("generate3dModel", LogLevel.DANGER);
            console.error("generate3dModel", data);
        });
        return deferred.promise;
    };

    this.extractPolygons = function (projectId, wireframe, params) {
        let deferred = $q.defer();
        $http({
            method: 'POST',
            url: this.apiUrl + 'projects/' + projectId + '/sketch/polygons?enforceSymmetry=' + params.enforceSymmetry + '&clearExistingProperties=' + params.clearExistingProperties,
            data: wireframe,
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
        }).then(function (data) {
            deferred.resolve(data);
        }).catch(function (data) {
            wfApp.log("extractPolygons", LogLevel.DANGER);
            console.error("extractPolygons", data);
        });
        return deferred.promise;
    };

    this.detectCutouts = function (projectId, wireframe) {
        let deferred = $q.defer();
        $http({
            method: 'POST',
            url: this.apiUrl + 'projects/' + projectId + '/detectCutouts',
            data: wireframe,
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
        }).then(function (data) {
            deferred.resolve(data);
        }).catch(function (data) {
            wfApp.log("Cutout Detection Error", LogLevel.DANGER);
            console.error("detectCutouts", data);
        });

        return deferred.promise;
    };

    this.finalize = function (projectId, wireframe) {
        let deferred = $q.defer();
        $http({
            method: 'POST',
            url: this.apiUrl + 'projects/' + projectId + '/finalize',
            data: wireframe,
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
        }).then(function (data) {
            deferred.resolve(data);
        }).catch(function (data) {
            wfApp.log("Finalize Error", LogLevel.DANGER);
            console.error("finalize", data);
        });

        return deferred.promise;
    };

    this.getUsers = function () {
        let deferred = $q.defer();
        $http({
            method: 'GET',
            url: this.apiUrl + 'users',
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
        }).then(function (data) {
            deferred.resolve(data);
        }).catch(function (data) {
            console.error("get users error", data);
            deferred.resolve(data);
        });

        return deferred.promise;
    };

    this.getUser = function (id) {
        let deferred = $q.defer();
        $http({
            method: 'GET',
            url: this.apiUrl + 'users/' + id,
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
        }).then(function (data) {
            deferred.resolve(data);
        }).catch(function (data) {
            console.error("get user error", data);
            deferred.resolve(data);
        });

        return deferred.promise;
    };

    this.createFirebaseToken = function (projectId) {
        let deferred = $q.defer();
        $http({
            method: 'GET',
            url: this.apiUrl + 'projects/' + projectId + '/createFirebaseToken',
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("authorization")}
        })
            .then(function (data) {
                deferred.resolve(data)
            });
        return deferred.promise;
    };

}];

