import {InteractiveModel} from "../interactive/interactive.model";
import { v4 as uuid } from 'uuid';

export interface Annotation {
    classes: Array<string>;
    geometry: any;
    memo: string;
    isOccluded: boolean;
}

export class AnnotationModel extends InteractiveModel implements Annotation {
    private _classes: Array<string>;
    geometry: any;
    memo: string;
    isOccluded: boolean = false;
    id: string = uuid();

    constructor(classes: Array<any> = [], geometry: any = null, memo:string = null, isOccluded: boolean = false){
        super();
        this.classes = classes;
        this.geometry = geometry;
        this.memo = memo;
        this.isOccluded = isOccluded;
    }

    get classes(): string[] {
        // get annotation classes
        let classesUsedInAnnotation: string[] = [];
        let currentClasses = this.cleanClasses(this._classes);
        currentClasses.forEach((annotationClass) => {
            if (classesUsedInAnnotation.find((element) => {
                return annotationClass == element;
            })) return;
            classesUsedInAnnotation.push(annotationClass);
        });

        // remove
        let classesToRemove = [];
        this._classes.forEach((annotationClass) => {
            if (classesUsedInAnnotation.indexOf(annotationClass) < 0) {
                classesToRemove.push(annotationClass)
            }
        });

        classesToRemove.forEach((annotationClass) => {
            let index = this._classes.indexOf(annotationClass);
            if (index > -1) {
                this._classes.splice(index, 1);
            }
        });

        // add
        let classesToAdd = [];
        classesUsedInAnnotation.forEach((annotationClass) => {
            if (this._classes.indexOf(annotationClass) < 0) {
                classesToAdd.push(annotationClass)
            }
        });

        classesToAdd.forEach((annotationClass) => {
            let index = this._classes.indexOf(annotationClass);
            if (index < 0) {
                this._classes.push(annotationClass);
            }
        });

        return this._classes;
    }

    set classes(classes:Array<string>){
        this._classes = this.cleanClasses(classes);
    }

    cleanClasses(classes:Array<string>):Array<string>{
        let cleanClasses = [];
        if(classes){
            classes.forEach((annotationClass) => {
                if(!annotationClass) annotationClass = "general";
                if(cleanClasses.indexOf(annotationClass) === -1){
                    cleanClasses.push(annotationClass);
                }
            })
        }
        return cleanClasses;
    }
}