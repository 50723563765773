import { Component, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import { WireframeApplication } from "src/app/application/wireframeApplication";
import { PV } from "src/app/wireframe";
import { LogLevel } from "src/app/application/logLevel";
import { WireframeElement } from "src/app/application/wireframeElement";
import { EdgeType } from "src/app/models/property/edgeType";
import { SceneService } from "src/app/services/scenes/scene.service";
import { EdgeTypeProperty } from "src/app/models/property/propertyLoaders";
import WireframeElementCollection from "src/app/collections/wireframeElements/wireframeElement.collection";

@Component({
    selector: 'ptv-edge-verification-manager-toolbar',
    styles: [require('./edgeVerificationManagerToolbar.component.scss')],
    template: require('./edgeVerificationManagerToolbar.component.html')
})
export default class EdgeVerificationManagerToolbarComponent implements OnInit, OnChanges {

    @Input() edgeCount: number;
    @Input() selectedEdges: WireframeElement[]
    

    selectedEdgeType: number = -1;
    edgeTypes;

    constructor(private sceneService: SceneService){
        this.edgeTypes = (this.sceneService.wireframeApplication.wireframe.findProperty(EdgeTypeProperty, false) as EdgeTypeProperty).values;
    }

    ngOnInit(): void {        
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['selectedEdges']) {
          this.updatedCurrentEdgeType();
        }
    }
    updatedCurrentEdgeType(): void {
        let edgeProp = this.sceneService.wireframeApplication.wireframe.findProperty(EdgeTypeProperty, true);                
        let typesInSelection = [...new Set(this.selectedEdges.map(e => edgeProp.getValue(e.pvObject)))];

        this.selectedEdgeType = typesInSelection.length === 1 ? typesInSelection[0] : -1;
    }

    onEdgeTypeChange(value) {
        if (this.selectedEdges.length === 0 || !value) return;
        let edgeProp = this.sceneService.wireframeApplication.wireframe.findProperty(EdgeTypeProperty, true);                
        let edgeCollection = new WireframeElementCollection();
        this.selectedEdges.forEach(e => edgeCollection.add(e));
        this.sceneService.wireframeApplication.propertyManager.addProperty(edgeProp, edgeCollection);
        this.sceneService.wireframeApplication.propertyManager.updateProperty(edgeProp, edgeCollection, Number(value));        
    }

    detectEdgeTypes(): void {
        let self = this;
        self.sceneService.wireframeApplication.apiService.classifyEdges(self.sceneService.wireframeApplication.projectId, self.sceneService.wireframeApplication.wireframe.toJson()).then(function (o) {
            let wf = new PV.Wireframe();
            wf.fromJson(o.data.data);
            self.sceneService.wireframeApplication.wireframe.properties = wf.properties;
            
            Object.values(wf.edges).forEach(function (edge) {
                let existingEdge = self.sceneService.wireframeApplication.wireframe.edges[edge.id];
                existingEdge.properties = edge.properties;
            });
            self.sceneService.wireframeApplication.propertiesChanged();
            self.sceneService.wireframeApplication.wireframeLayer.redrawWireframe();
            if (o.data.success) {
                self.sceneService.wireframeApplication.log("Edge Classification successful");
            } else {
                self.sceneService.wireframeApplication.log("Edge Classification failed", LogLevel.DANGER);
            }            
        });
    }
}