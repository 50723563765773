import { Component, OnInit } from "@angular/core";
import { SceneService } from "src/app/services/scenes/scene.service";

@Component({
    selector: 'ptv-project-management',
    styles: [require('./projectManagement.component.scss')],
    template: require('./projectManagement.component.html')
})
export default class ProjectManagementComponent implements OnInit {
    
    applicationConfig: any;

    constructor(private sceneService: SceneService) {        
    }

    ngOnInit(): void {
        let self = this;
        self.sceneService.wireframeApplication.apiService.getConfig().then((applicationConfig: any) => {
            self.applicationConfig = applicationConfig.data;
        });
    }    

    get currentStatus() {
        let projectData = this.sceneService.wireframeApplication.projectData;
        if (!projectData || !projectData.summary|| !projectData.summary.internalStatus) return '';
        return projectData.summary.internalStatus;
    }

    readonly disabledStatuses = ['CREATED', 'PROCESSING'];

    private _availableStatuses: any[];
    get availableStatuses() {
        if (this._availableStatuses) return this._availableStatuses;
        if (!this.applicationConfig) return [];
        if (!this.applicationConfig.internalConstants) return [];
        if (!this.applicationConfig.internalConstants.dictionaries) return [];
        if (!this.applicationConfig.internalConstants.dictionaries.ProjectStatus) return []

        let statuses: any[] = Object.values(this.applicationConfig.internalConstants.dictionaries.ProjectStatus);
        this._availableStatuses = statuses.sort(function(a:any, b:any) { return a.order - b.order; })
        return this._availableStatuses;
    }

    onStatusChange(newStatus: string) {
        let self = this;
        let project = self.sceneService.wireframeApplication.projectData.summary;
        if (!project) return;
        project.internalStatus = newStatus;
        this.sceneService.wireframeApplication.apiService.putProject(project)
            .then(
                () => {
                    self.sceneService.wireframeApplication.log('Project Status Updated: ' + newStatus);
                },
                (err) => {
                    console.log(err);
                    self.sceneService.wireframeApplication.log('Unable to update Project Status.');
                });
        
    }

    get issueCount() {
        let projectData = this.sceneService.wireframeApplication.projectData;
        if (!projectData || !projectData.summary || !projectData.summary.projectIssues) return 0;
        return projectData.summary.projectIssues.length;
    }

    get validationCount() {
        return this.sceneService.wireframeApplication.validationResults.filter(r => r.elements.length > 0).length;
    }
    
}