import {Mesh} from "./mesh";

import {WireframeElementType} from "./wireframeElementType";
import {DefaultMaterialProps} from "./defaultMaterialProps";
import * as THREE from 'three';

export default class GeometryCache {

    constructor() {
        this["edgeGeometry"] = new THREE.CylinderBufferGeometry(1, 1, 1, 4, 1, false)
        this["edgePickGeometry"] = new THREE.CylinderBufferGeometry(6, 6, 1, 4, 1, false)
        this["vertexGeometry"] = new THREE.SphereBufferGeometry(1, 8, 8)
        this["vertexPickGeometry"] = new THREE.SphereBufferGeometry(2.5, 8, 8)
        this["planeNormalGeometry"] = new THREE.CylinderGeometry(1, 1, 1, 4, 1, false);

        this["measureEndpointGeometry"] = new THREE.CylinderBufferGeometry(3, 3, 1, 4, 1, false)
    }

    public static cache:Object = {}

    static getOrCache(name, cachedGeomFunc) {
        if (!(name in this)) GeometryCache.cache[name] = cachedGeomFunc.apply(this)
        return GeometryCache.cache[name]
    }

}
