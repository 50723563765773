import {
    Component, DoCheck,
    Input, IterableDiffers
} from '@angular/core';
import WireframeElementCollection from "../../collections/wireframeElements/wireframeElement.collection";

@Component({
    selector: 'wireframe-selection-properties',
    styles: [require('./wireframeSelectionProperties.component.scss')],
    template: require('./wireframeSelectionProperties.component.html'),
})
export default class WireframeSelectionPropertiesComponent implements DoCheck {
    @Input() selectedWireframeElements: WireframeElementCollection;

    iterableDiffer;
    constructor(
        private _iterableDiffers: IterableDiffers
    ) {
        this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    }

    ngDoCheck(): void {
        let changes = this.iterableDiffer.diff(this.selectedWireframeElements);
    }
}