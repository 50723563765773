import {InteractiveModel} from "./interactive.model";
import {EventEmitter} from "@angular/core";

export interface InteractiveFilter {
    filterType: string;
    filterFunction: (model) => boolean;
}

export class InteractiveFilterModel<T extends InteractiveModel> extends InteractiveModel implements InteractiveFilter {
    filterType: string;
    filterFunction: (T) => boolean;
    filterChanged = new EventEmitter<InteractiveFilterModel<T>>();

    constructor(filterType: string) {
        super();
        this.filterType = filterType;
    }

    filter(model: T) {
        if (!this.filterFunction) return false;
        return this.filterFunction(model);
    }

    get filterName(): string {
        return this.filterType;
    }

    get filterLabel(): string {
        return this.filterType;
    }

    get filterEditLabel(): string {
        return this.filterType;
    }

    get filterSymbol(): string {
        return this.filterType;
    }

    triggerFilterChanged(){
        this.filterChanged.emit(this);
    }
}