import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef, HostListener,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {WireframeApplication} from "../../application/wireframeApplication";
import * as THREE from 'three';
import {CameraProjector} from "../../application/cameraProjector";
import {WireframeElement} from "../../application/wireframeElement";
import {SceneService} from "../../services/scenes/scene.service";
import {CameraElement} from "../../application/cameraElement";

@Component({
    selector: 'camera-view',
    styles: [require('./cameraView.component.scss')],
    template: require('./cameraView.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CameraViewComponent implements OnInit, OnDestroy{
    private static componentCounter = 0
    private readonly componentIdx = CameraViewComponent.componentCounter++
    private app:WireframeApplication
    private isDestroyed = false
    private _layerNum = 1
    private aspectRatio = 1
    private zoomRate = .2
    @ViewChild('renderCanvas', {static: false}) renderCanvas:ElementRef;
    private canvasContext
    private static renderer:THREE.WebGLRenderer

    private _light:THREE.PointLight = new THREE.PointLight(new THREE.Color(0xffffff), 1)
    //private _lightHelper = new THREE.PointLightHelper(this._light, 1)

    private _projector:CameraProjector
    private _targetPlane:THREE.Plane
    private _targetPoint3d:THREE.Vector3
    private _targetPoint2d:THREE.Vector2
    private _elements:WireframeElement[] = []

    private _computedPoint3d = new THREE.Vector3()
    private _computedPoint2d = new THREE.Vector2()

    private _viewportWidthMeters = 3
    private _xScaleMeters:number = 10
    private _scaleFactor:number = 1
    private _camera = new THREE.PerspectiveCamera()
    private _viewPortDim = new THREE.Vector4()

    @Input()
    set camera(camera:CameraElement) {
        this._projector.cameraElement = camera
        this._projector.initialize()
        this._projector.cameraElement.applyCameraParameters(this._camera)
        this._light.matrix.copy(this._projector.cameraElement.cameraMatrixWorld)
        this._light.matrixWorld.copy(this._projector.cameraElement.cameraMatrixWorld)
        //this._lightHelper.updateMatrixWorld(true)

        this._camera.name = this._projector.cameraElement.name + "-params"
        this.aspectRatio = this._projector.cameraElement.pvObject.frameMetadata.width / this._projector.cameraElement.pvObject.frameMetadata.height
        this.resize()
        this.updateTargetPoints()
    }

    @Input()
    set targetPoint3d(point:THREE.Vector3) {
        if (!point) {
            this._targetPoint3d = null
            return
        }
        this._targetPoint2d = null
        this._targetPoint3d = point.clone()
        this._computedPoint3d.copy(point)
        if (!this._targetPlane) {
            this._targetPlane = new THREE.Plane()
        }
        this._targetPlane.setFromNormalAndCoplanarPoint(this._targetPoint3d.clone().sub(this._projector.cameraElement.cameraPositionWorld).normalize(), this._targetPoint3d)
        this.updateTargetPoints()
    }

    /**
     * Set the view center from image coordinates, ranged 0..1 with 0,0 as lower left
     * @param point
     */
    @Input()
    set targetPoint2d(point:THREE.Vector2) {
        if (!point) {
            this._targetPoint2d = null
            return
        }
        point.clampScalar(0, 1)
        this._targetPoint2d = point.clone()
        this._computedPoint2d.copy(point)
        let planePoint = this._projector.cameraElement.cameraPositionWorld.clone().add(this._projector.cameraElement.cameraDirectionWorld.clone().setLength(10))
        if (this._targetPoint3d)  planePoint.copy(this._targetPoint3d)
        this._targetPlane.setFromNormalAndCoplanarPoint(planePoint.clone().sub(this._projector.cameraElement.cameraPositionWorld).normalize(), planePoint)
        this._targetPoint3d = null
        this.updateTargetPoints()
    }

    @Input()
    set viewportWidthMeters(_width:number) {
        this._viewportWidthMeters = _width || 3
        this._viewportWidthMeters = Math.max(.1, this._viewportWidthMeters);
        this._viewportWidthMeters = Math.min(this._xScaleMeters * 1.5, this._viewportWidthMeters);
        //this.updateViewport()
    }

    @Input()
    set visibleElements(elements:WireframeElement[]) {
        if (!elements) return
        let that = this
        this._elements = elements
    }

    private _zoom: number = 100;
    @Input() set zoom(zoom) {
        this._zoom = zoom;
        this.updateZoom();
    }

    get zoom(): number {
        return this._zoom;
    }

    private updateZoom(){
        this.updateViewport();
    }

    private updateTargetPoints() {
        if (!this._projector) return
        if (this._targetPoint3d) {
            this.computePoint2d()
        } else if (this._targetPoint2d) {
            this.computePoint3d()
        }
        this._projector.targetPoint = this._computedPoint3d
        this._projector.updateProjection()
    }

    constructor(private el:ElementRef,
                private zone: NgZone,
                private sceneService:SceneService,
                private changeDetectorRef: ChangeDetectorRef) {
        let that = this;
        this.app = sceneService.wireframeApplication
        if (!CameraViewComponent.renderer) {
            CameraViewComponent.renderer = new THREE.WebGLRenderer({ alpha: false, antialias: true })
            CameraViewComponent.renderer.setClearColor(0x000000)
        }

        //this.camera.add(new THREE.AxesHelper(2))
        this._camera.layers.set(this._layerNum)
        this.app.rootScene.add(this._camera)
        this.app.cameraProjectionService.eventEmitter.on("projectionChanged").subscribe((event) => {
        })
        this._projector = new CameraProjector(this.app, null)
        this._projector.useTransitions = false
        this._light.matrixAutoUpdate = false
        this.app.rootScene.add(this._light)


        this._light.layers.disable(0)
        this._projector.traverse((o) => {
            o.layers.disable(0)
        })
    }

    ngOnInit():void {
        this.canvasContext = this.renderCanvas.nativeElement.getContext('2d')
        this._projector.enabled = true
        this.animate()
    }

    private computePoint2d() {
        let projected = this._computedPoint3d.clone().project(this._camera)
        projected.x = (projected.x + 1) / 2
        projected.y = (projected.y + 1) / 2
        this._computedPoint2d.set(projected.x, projected.y)
    }

    private computePoint3d() {
        let unprojected = new THREE.Vector3(this._targetPoint2d.x * 2 - 1, this._targetPoint2d.y * 2 - 1, 0).unproject(this._camera)
        let rayDirection = unprojected.clone().sub(this._projector.cameraElement.cameraPositionWorld).normalize()
        let ray = new THREE.Ray(this._projector.cameraElement.cameraPositionWorld, rayDirection)
        this._computedPoint3d = ray.intersectPlane(this._targetPlane, new THREE.Vector3()) || new THREE.Vector3()
    }

    private updateViewport() {
        if (!this._projector) return
        if (!this._computedPoint3d) return

        if (!this._projector.targetPoint)
        this._xScaleMeters = this._projector.targetPoint.distanceTo(this._projector.cameraElement.cameraPositionWorld) / (this._projector.cameraElement.pvObject.registration.fxy[0] / this._projector.cameraElement.pvObject.frameMetadata.width)

        this._scaleFactor = this._viewportWidthMeters / this._xScaleMeters
        let zoom = (1  / this._scaleFactor) * this.zoom / 100;
        let dim = CameraViewComponent.renderer.getSize()
        let elDim = new THREE.Vector2(dim.width, dim.height)
        let canvasDim = new THREE.Vector2(dim.width * zoom, dim.height * zoom)
        let canvasCenter = new THREE.Vector2((this._computedPoint2d.x) * -canvasDim.x, (1 - this._computedPoint2d.y) * -canvasDim.y)
        this._viewPortDim.set(canvasCenter.x + (elDim.x / 2),
            (canvasCenter.y + (elDim.y / 2)), //projected.y * size.height,p
            dim.width * zoom,
            dim.height * zoom)
    }

    enableLayer() {
        let that = this
        let objects = [this._camera, this._projector, this._light, ...this._elements]
        objects.forEach((o) => o.traverse((o2) => {
            o2.layers.enable(that._layerNum)
        }))
    }

    disableLayer() {
        let that = this
        let objects = [this._camera, this._projector, this._light, ...this._elements]
        objects.forEach((o) => o.traverse((o2) => {
            o2.layers.disable(that._layerNum)
        }))
    }

    animate() {
        let that = this
        if (this.isDestroyed) return

        this.zone.runOutsideAngular(()=> {
            this.updateViewport()

            this.render()

            setTimeout(() => {
                window.requestAnimationFrame(() => that.animate());
            }, 100)
        });
    }

    render() {
        this.resize()
        this.enableLayer()
        CameraViewComponent.renderer.setViewport(this._viewPortDim.x, this._viewPortDim.y, this._viewPortDim.z, this._viewPortDim.w)
        CameraViewComponent.renderer.render(this.app.rootScene, this._camera)
        this.disableLayer()
        this.canvasContext.drawImage(CameraViewComponent.renderer.domElement, 0, 0)
    }

    resize() {
        if (!this.renderCanvas) return
        let width = this.renderCanvas.nativeElement.clientWidth
        //let height = this.renderCanvas.nativeElement.clientHeight
        CameraViewComponent.renderer.setSize(width, width / this.aspectRatio)
    }


    ngOnDestroy(): void {
        this.app.rootScene.remove(this._camera);
        this._projector.enabled = false
        this.app.rootScene.remove(this._projector)
        this.app.rootScene.remove(this._light)
        if (false && CameraViewComponent.renderer) {
            CameraViewComponent.renderer.forceContextLoss();
            CameraViewComponent.renderer.context = null;
            CameraViewComponent.renderer.domElement = null;
            CameraViewComponent.renderer.dispose();
            CameraViewComponent.renderer = null;
        }
        this.isDestroyed = true
    }

    @HostListener('mouseover', ['$event'])
    onMouseOver(e: MouseEvent) {
        this.render()
    }

    @HostListener('mousewheel', ['$event'])
    onMouseWheel(e: WheelEvent) {
        let delta = this._viewportWidthMeters * this.zoomRate;
        delta *= e.deltaY > 0 ? 1 : -1;
        this.viewportWidthMeters = this._viewportWidthMeters + delta
        this.updateViewport();
        this.render();
        e.stopPropagation();
        e.preventDefault();
    }

    _isPanning = false
    @HostListener('mousedown', ['$event'])
    onMouseDown(e:MouseEvent) {
        if (e.button == 2) {
            this._isPanning = true
        }
        e.preventDefault()
    }

    @HostListener('mouseup', ['$event'])
    onMouseUp(e:MouseEvent) {
        this._isPanning = false
        e.preventDefault()
    }

    @HostListener('mousemove', ['$event'])
    onMouseMove(e:MouseEvent) {
        if (this._isPanning) {
            let pos2d = this._computedPoint2d.clone()
            let dim = CameraViewComponent.renderer.getSize()
            pos2d.x -= e.movementX / (dim.width / this._scaleFactor)
            pos2d.y += e.movementY / (dim.height / this._scaleFactor)
            pos2d.clampScalar(0, 1)
            this.targetPoint2d = pos2d
            this.render()
        }
    }
}
