import {
    Component, ComponentFactoryResolver, ComponentRef, Inject,
} from '@angular/core';
import VertexFilterVerifiedEditorComponent from "../vertexFilterVerifiedEditor/vertexFilterVerifiedEditor.component";
import {VertexFilterVerifiedModel} from "../../models/vertexFilters/vertexFilterVerified.model";
import InteractiveFilterEditorComponent from "../interactiveFilterEditor/interactiveFilterEditor.component";
import {VertexElementModel} from "../../models/vertexElements/vertexElement.model";
import {VertexFilterTypes} from "../../models/vertexFilters/vertexFilterTypes.enum";
import VertexFilterSeenEditorComponent from "../vertexFilterSeenEditor/vertexFilterSeenEditor.component";
import {VertexFilterSeenModel} from "../../models/vertexFilters/vertexFilterSeen.model";

@Component({
    selector: 'ptv-vertex-filter-editor',
    styles: [require('./vertexFilterEditor.component.scss')],
    template: require('./vertexFilterEditor.component.html')
})
export default class VertexFilterEditorComponent extends InteractiveFilterEditorComponent<VertexElementModel> {
    constructor(private vertexFilterComponentFactoryResolver:ComponentFactoryResolver,
                @Inject('interactiveFilterModel') private _vertexFilterInteractiveFilterModel = null){
        super(vertexFilterComponentFactoryResolver, _vertexFilterInteractiveFilterModel);
    }
    createInteractiveFilterEditorComponentRef(): ComponentRef<any> {
        if(!this.interactiveFilterModel) return null;
        let filterType = this.interactiveFilterModel.filterType;

        switch(filterType){
            case VertexFilterTypes.VertexVerification: {
                let interactiveListFilterEditorComponentFactory = this.vertexFilterComponentFactoryResolver.resolveComponentFactory(VertexFilterVerifiedEditorComponent);
                let metadataFilterEditorComponenRef = this.interactiveFilterEditorContainerRef.createComponent(interactiveListFilterEditorComponentFactory);
                metadataFilterEditorComponenRef.instance.interactiveFilterModel = this.interactiveFilterModel as VertexFilterVerifiedModel;
                return metadataFilterEditorComponenRef;
            }
            
            case VertexFilterTypes.VertexSeen: {
                let interactiveListFilterEditorComponentFactory = this.vertexFilterComponentFactoryResolver.resolveComponentFactory(VertexFilterSeenEditorComponent);
                let metadataFilterEditorComponenRef = this.interactiveFilterEditorContainerRef.createComponent(interactiveListFilterEditorComponentFactory);
                metadataFilterEditorComponenRef.instance.interactiveFilterModel = this.interactiveFilterModel as VertexFilterSeenModel;
                return metadataFilterEditorComponenRef;
            }
        }
    }

}