import {NumberProperty} from "./numberProperty";

export class IntegerProperty extends NumberProperty {
    constructor() {
        super();
        this.type = ".IntegerProperty";
        this.name = "IntegerProperty";
        this.isInteger = true;
        this.step = 1;
    }
}