import $ from "jquery";
import {VertexAdjustState} from "../../models/property/vertexAdjustState";
import {PolygonShapeProperty} from "../../models/property/polygonShapeProperty";
import {WireframeApplication} from "../../application/wireframeApplication";
import {WireframeElement} from "../../application/wireframeElement";
import {VertexAdjustStateProperty} from "../../models/property/vertexAdjustStateProperty";
import {VertexAdjustViewState} from "../../models/property/vertexAdjustViewState";
import {VertexElement} from "../../application/vertexElement";
import * as THREE from 'three';
import {WidgetElement} from "../../application/widgetElement";
import PVUtils from "../../pvUtils";
import {WireframeElementType} from "../../application/wireframeElementType";
import {PlaneElement} from "../../application/planeElement";

declare var Projection: any;
declare var viewer: any;

export class AdjustSection {
    reset = false;

    private adjustCtrl:any
    constructor(private app:WireframeApplication, adjustCtrl) {
        this.adjustCtrl = adjustCtrl
    }

    resetButton() {
        let graph1 = this.adjustCtrl.dictGraphs["imageFullScreen1"];
        let graph2 = this.adjustCtrl.dictGraphs["imageFullScreen2"];
        let graph3 = this.adjustCtrl.dictGraphs["imageFullScreen3"];

        // in case the adjust view hasn't loaded properly yet
        if (!graph1 || !graph2 || !graph3) return;
        let v1 = graph1.vertices[0];
        let v2 = graph2.vertices[0];
        let v3 = graph3.vertices[0];

        let point3D;

        let vertex = this.adjustCtrl.adjustVertex as any;
        point3D = [vertex.pvObject.x, vertex.pvObject.y, vertex.pvObject.z];

        let p1 = Projection.Project_Point_With_Distortion(point3D, this.adjustCtrl.cameraObject1.rotation, this.adjustCtrl.cameraObject1.translation, this.adjustCtrl.cameraObject1.skew, this.adjustCtrl.cameraObject1.fx, this.adjustCtrl.cameraObject1.fy, this.adjustCtrl.cameraObject1.cx, this.adjustCtrl.cameraObject1.cy, this.adjustCtrl.cameraObject1.distortion, this.adjustCtrl.cameraObject1.projectionType)
        let p2 = Projection.Project_Point_With_Distortion(point3D, this.adjustCtrl.cameraObject2.rotation, this.adjustCtrl.cameraObject2.translation, this.adjustCtrl.cameraObject2.skew, this.adjustCtrl.cameraObject2.fx, this.adjustCtrl.cameraObject2.fy, this.adjustCtrl.cameraObject2.cx, this.adjustCtrl.cameraObject2.cy, this.adjustCtrl.cameraObject2.distortion, this.adjustCtrl.cameraObject2.projectionType)
        let p3 = Projection.Project_Point_With_Distortion(point3D, this.adjustCtrl.cameraObject3.rotation, this.adjustCtrl.cameraObject3.translation, this.adjustCtrl.cameraObject3.skew, this.adjustCtrl.cameraObject3.fx, this.adjustCtrl.cameraObject3.fy, this.adjustCtrl.cameraObject3.cx, this.adjustCtrl.cameraObject3.cy, this.adjustCtrl.cameraObject3.distortion, this.adjustCtrl.cameraObject3.projectionType)

        v1.x = p1[0] * this.adjustCtrl.xFactor;
        v1.y = p1[1] * this.adjustCtrl.yFactor;

        v2.x = p2[0] * this.adjustCtrl.xFactor;
        v2.y = p2[1] * this.adjustCtrl.yFactor;

        v3.x = p3[0] * this.adjustCtrl.xFactor;
        v3.y = p3[1] * this.adjustCtrl.yFactor;
    };


    applyVertex() {
        let element: WireframeElement = this.adjustCtrl.adjustVertex;
        if (element == null) {
            return;
        }

        let va = new VertexAdjustState();
        va.mode = this.adjustCtrl.triangulationMode;
        va.lockedPlane = this.app.lockedElements.length > 0 ? this.app.lockedElements[0].pvObject.id : null;
        for (let i = 0; i < 3; i++) {
            let adjustView = this.adjustCtrl.adjustViews[i];
            let viewState = new VertexAdjustViewState();
            viewState.viewId = Number(adjustView.cameraObject.cameraIndex);
            viewState.x = adjustView.graph.vertices[0].x / adjustView.cameraObject.xFactor;
            viewState.y = adjustView.graph.vertices[0].y / adjustView.cameraObject.yFactor;

            let view = this.adjustCtrl.canvasOperations.getViewByIndex(viewState.viewId);
            viewState.frameId = view.frameId;
            viewState.zoom = adjustView.canvas.currentScale;

            va.views.push(viewState);

        }

        let adjustProp = this.app.wireframe.findProperty(VertexAdjustStateProperty, true);
        adjustProp.setValue(element, va);
        console.log("vert adjust", va);

        if (this.app.vertexPositionAdjusted && viewer.reconstructedPoint) {
            let newPos = new THREE.Vector3(viewer.reconstructedPoint[0], viewer.reconstructedPoint[1], viewer.reconstructedPoint[2]);
            let currentPos = element.getCenter(false)
            if (newPos.distanceTo(currentPos) == 0) {
                return;
            }
            this.app.startUndoEntry();

            let isPolygonVert = false;
            if (true) {
            //if (app.elementMode != WireframeElementType.plane) {
                let planeIds = this.app.wireframe.findPlanesForVertex(element.pvObject.id);
                if (planeIds.length == 1) {
                    let plane = this.app.wireframe.planes[planeIds[0]];
                    let planeEl = this.app.wireframeLayer.findWireframeElement(WireframeElementType.plane, plane.id) as PlaneElement
                    let polyProp = this.app.wireframe.findProperty(PolygonShapeProperty, true) as PolygonShapeProperty;
                    let pv = polyProp.getPropertyValue(plane);
                    if (pv) {
                        isPolygonVert = true;
                        this.app.wireframeLayer.updatePlaneEquation(plane);
                        let polyPlane = this.app.wireframe.getPlane3(plane.id);
                        let planePos = polyPlane.projectPoint(newPos, new THREE.Vector3());
                        polyProp.moveVertex(element as VertexElement, pv.value, planeEl, planePos);
                    }
                }
            }
            if (!isPolygonVert) {
                if (element instanceof VertexElement) {
                    this.app.wireframeLayer.moveVertex(element, newPos, true, false);
                } else if (element instanceof WidgetElement) {
                    element.position.copy(newPos)
                }
            }
        }

        this.adjustCtrl.canvasOperations.markVertexVerified(element, true);

        this.app.wireframeLayer.redrawWireframe();

        // it is temporary to run viewer
        return;
    };



    //hide up/down photo controls if photo count is less than 4
    hideOrShowControls(vertex) {
        if (viewer.cameraIndex1 == null) {
            $("#adjustablePointControls1").hide()
        }
        else {
            $("#adjustablePointControls1").show()
        }

        if (viewer.cameraIndex2 == null) {
            $("#adjustablePointControls2").hide()
        }
        else {
            $("#adjustablePointControls2").show()
        }

        if (viewer.cameraIndex3 == null) {
            $("#adjustablePointControls3").hide()
        }
        else {
            $("#adjustablePointControls3").show()
        }

        if ((vertex.hframes.length - vertex.occList.length) >= 4) {
            $("#photoDown1").show();
            $("#photoUp1").show();

            $("#photoDown2").show();
            $("#photoUp2").show();

            $("#photoDown3").show();
            $("#photoUp3").show();
        }
        else {
            $("#photoDown1").hide();
            $("#photoUp1").hide();

            $("#photoDown2").hide();
            $("#photoUp2").hide();

            $("#photoDown3").hide();
            $("#photoUp3").hide();
        }
    };

    resetSliderZoomValue() {
        $("#zoomRange").val(this.adjustCtrl.defaultSliderValue);
        this.adjustCtrl.previousSliderValue = this.adjustCtrl.defaultSliderValue;
    };

    setFrame(canvasId, viewId) {
        // update canvas scale
        let adjustView = this.adjustCtrl.adjustViews[canvasId];
        if (adjustView && adjustView.canvas) {
            adjustView.canvas.preferedScale = adjustView.canvas.currentScale;
        }

        // fill the canvas
        let point3D = this.adjustCtrl.canvasOperations.canvasPoint3D;
        this.adjustCtrl.canvasOperations.fillCanvasWithImageAndPoint(viewId, Number(canvasId), point3D, false);
    };

    nextFrame(id) {
        PVUtils.clearSelection();
        let cameraIndex = viewer["cameraIndex" + id];

        let counter = 0;
        let nextCameraIndex = this.adjustCtrl.canvasOperations.getNextCameraIndex(cameraIndex);
        while (Number(nextCameraIndex) == Number(viewer.cameraIndex1) ||
        Number(nextCameraIndex) == Number(viewer.cameraIndex2) ||
        Number(nextCameraIndex) == Number(viewer.cameraIndex3)) {
            nextCameraIndex = this.adjustCtrl.canvasOperations.getNextCameraIndex(nextCameraIndex);
            counter++;
            if (counter > 100) {
                window.alert("nextFrame");
            }
        }

        let point3D = this.adjustCtrl.canvasOperations.canvasPoint3D;

        // update canvas scale
        let adjustView = this.adjustCtrl.adjustViews[id];
        if (adjustView && adjustView.canvas) {
            adjustView.canvas.preferedScale = adjustView.canvas.currentScale;
        }

        // fill the canvas
        this.adjustCtrl.canvasOperations.fillCanvasWithImageAndPoint(nextCameraIndex, Number(id), point3D, false);

        return nextCameraIndex;
    };


    previousFrame(id) {
        PVUtils.clearSelection();
        let cameraIndex = viewer["cameraIndex" + id];

        let counter = 0;
        let previousCameraIndex = this.adjustCtrl.canvasOperations.getPreviousCameraIndex(cameraIndex);

        while (Number(previousCameraIndex) == Number((this.adjustCtrl.cameraObject1 as any).cameraIndex) ||
        Number(previousCameraIndex) == Number((this.adjustCtrl.cameraObject2 as any).cameraIndex) ||
        Number(previousCameraIndex) == Number((this.adjustCtrl.cameraObject3 as any).cameraIndex)) {
            previousCameraIndex = this.adjustCtrl.canvasOperations.getPreviousCameraIndex(previousCameraIndex);
            counter++;
            if (counter > 100) {
                window.alert("nextFrame");
            }
        }

        let point3D = this.adjustCtrl.canvasOperations.canvasPoint3D;

        // update canvas scale
        let adjustView = this.adjustCtrl.adjustViews[id];
        if (adjustView && adjustView.canvas) {
            adjustView.canvas.preferedScale = adjustView.canvas.currentScale;
        }

        // fill the canvas
        this.adjustCtrl.canvasOperations.fillCanvasWithImageAndPoint(previousCameraIndex, Number(id), point3D, false);
        return previousCameraIndex;
    };

    addPhotosToInspect() {
        this.app.photoLabels = [];
        this.app.photos = [];

        class PhotoLabel {
            private id: any;
            private name: any;
            private note: any;

            constructor(id, name, note) {
                this.id = id;
                this.name = name;
                this.note = note;
            }
        };

        class Photo {
            private id: any;
            private name: any;
            private url: any;

            constructor(id, name, url) {
                this.id = id;
                this.name = name;
                this.url = url;
            }
        }
    };

    //adjust given vertex
    adjustWireFrameVertex() {
        if (!this.adjustCtrl.adjustVertex) return
        let vertex = this.adjustCtrl.adjustVertex as any;

        if (!vertex) return;
        let element = document.getElementById('drawPlaneCheckBox') as HTMLInputElement;
        if (element != null)
            element.checked = false;

        vertex.pvObject.occList = [];
        let hframes = this.adjustCtrl.pointVisibility.getPoint3DFrames(vertex.getCenter(), false);
        if (hframes.length < 3) {
            hframes = this.adjustCtrl.pointVisibility.getPoint3DFrames(vertex.getCenter(), true);
        }
        vertex.pvObject.hframes = hframes;
        vertex.hframes = hframes;

        if ($("#inspectButton").hasClass("activeLight")) {
            this.adjustCtrl.inspectFrames = vertex.hframes;
            viewer.addPhotosToInspect();
            return;
        }

        let canvas1 = document.getElementById("imageFullScreen1") as any;
        let canvas2 = document.getElementById("imageFullScreen2") as any;
        let canvas3 = document.getElementById("imageFullScreen3") as any;

        // WTF view not initialized??
        if (!canvas1) return;
        canvas1.src = null;
        canvas2.src = null;
        canvas3.src = null;

        PVUtils.clearSelection();

        this.adjustCtrl.perVertexTime = 0;
        vertex.metrics = {};
        vertex.metrics.viewedInAdjustMode = true;

        viewer.reconstructMode = "triangulation";
        this.adjustCtrl.mouseDownStack = [];
        this.app.vertexPositionAdjusted = false;

        let point3D = vertex.getCenter();
        point3D.hframes = vertex.hframes;
        point3D.occList = vertex.occList;

        this.makeAdjustUiVisible(point3D, hframes);
    };


    makeAdjustUiVisible(point, hframes) {
        this.app.addPointModeHistory = [];
        this.app.addPointModeIndex = 0;
        this.adjustCtrl.canvasOperations.fillThreeCanvasesWithBestFrames(point, hframes);
    }

}