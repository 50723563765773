import LinkedList from "typescript-collections/dist/lib/LinkedList";
import * as util from "typescript-collections/dist/lib/util";
import {EventEmitter} from "@angular/core";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable} from "rxjs";

export class GenericCollection<T> extends DataSource<T> implements Iterable<T> {

    // events
    public itemAdded: EventEmitter<T> = new EventEmitter<T>();
    public itemRemoved: EventEmitter<T> = new EventEmitter<T>();

    // internal data structure
    private list: LinkedList<T>;

    constructor() {
        super();
        this.list = new LinkedList<T>();
        this.updateCollectionSubject();
    }

    add(item: T): boolean {
        let success = this.list.add(item);
        if(success){
            this.updateCollectionSubject();
            this.itemAdded.emit(item);
        }
        return success;
    }

    size(): number {
        return this.list.size();
    }

    get length(): number {
        return this.size();
    }

    at(index:number){
        return this.list.elementAtIndex(index);
    }

    indexOf(item: T, equalsFunction?: util.IEqualsFunction<T>): number {
        return this.list.indexOf(item, equalsFunction);
    }

    contains(item: T, equalsFunction?: util.IEqualsFunction<T>): boolean {
        return this.list.contains(item, equalsFunction);
    }

    isEmpty(): boolean {
        return this.list.size() <= 0;
    }

    remove(item: T, equalsFunction?: util.IEqualsFunction<T>): boolean {
        let success = this.list.remove(item, equalsFunction);
        if(success){
            this.updateCollectionSubject();
            this.itemRemoved.emit(item);
        }
        return success;
    }

    removeElementAtIndex(index: number): T | undefined {
        let item = this.list.elementAtIndex(index);
        let success = this.list.removeElementAtIndex(index);
        if(success){
            this.updateCollectionSubject();
            this.itemRemoved.emit(item);
        }
        return success;
    }

    clear(): void {
        this.forEach((item) => {
            this.remove(item);
        });
    }

    forEach(callback: util.ILoopFunction<T>) {
        this.list.forEach(callback);
    }

    [Symbol.iterator](): Iterator<T> {
        let self = this;
        let pointer = 0;

        return {
            next(): IteratorResult<T> {
                if (pointer < self.list.size()) {
                    return {
                        done: false,
                        value: self.list.elementAtIndex(pointer++)
                    }
                } else {
                    return {
                        done: true,
                        value: null
                    }
                }
            }
        }
    }

    public toArray(): T[] {
        let itemArray: T[] = [];
        this.forEach((currentItem) => {
            itemArray.push(currentItem)
        });
        return itemArray;
    }

    public filter(filterFunction: (T) => boolean) {
        return this.toArray().filter(filterFunction)
    }

    private collectionSubject = new BehaviorSubject<T[]>([]);

    connect(collectionViewer: CollectionViewer): Observable<T[] | ReadonlyArray<T>> {
        return this.collectionSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.collectionSubject.complete();
    }

    updateCollectionSubject(){
        this.collectionSubject.next(this.toArray())
    }
}