import {WireframeElementType} from "../../application/wireframeElementType";
import {ElementReferenceMap} from "./elementReferenceMap";
import {PV} from "../../wireframe";
import WireframeLayer from "../../application/wireframeLayer";

export class ElementReference {
    elements: ElementReferenceMap = {};

    setElement(c:PV.Component) {
        this.elements = {}
        this.addElement(c)
    }

    getElements(layer:WireframeLayer):PV.Component[] {
        let components = []
        let that = this
        Object.keys(this.elements).forEach((elementTypeKey) => {
            let elementType:WireframeElementType = Number(elementTypeKey)
            let typeIds:number[] = that.elements[elementType]
            typeIds.forEach((id) => {
                let el = layer.findWireframeElement(elementType, id)
                if (el) components.push(el.pvObject)
            })
        })
        return components
    }

    addElement(c:PV.Component) {
        if (!this.elements[c.pvType]) this.elements[c.pvType] = []
        if (!this.elements[c.pvType].includes(c.id)) this.elements[c.pvType].push(c.id)
    }

    hasElement(c:PV.Component):boolean {
        if (this.elements[c.pvType] && this.elements[c.pvType].includes(c.id)) return true
        return false
    }
    removeElement(c:PV.Component) {
        if (this.elements[c.pvType]) {
            this.elements[c.pvType] = this.elements[c.pvType].filter((id:number) => id != c.id)
        }
    }
}
