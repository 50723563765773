import {InteractiveModel} from "./interactive.model";
import {EventEmitter} from "@angular/core";

export interface InteractiveSort<T extends InteractiveModel> {
    sortFunction: (a:T, b:T) => boolean;
}

export class InteractiveSortModel<T extends InteractiveModel> extends InteractiveModel implements InteractiveSort<T> {
    sortFunction: (a:T, b:T) => boolean;
    sortUpdated = new EventEmitter<InteractiveSortModel<T>>();

    constructor() {
        super();
    }

    sort(a: T, b: T) {
        if (!this.sortFunction) return false;
        return this.sortFunction(a, b);
    }

    triggerSortUpdated(){
        this.sortUpdated.emit(this);
    }
}