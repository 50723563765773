export enum SidebarMode {
    NONE,
    TOOLS,
    LAYERS,
    REFINE,
    MEDIA,
    INSPECTOR,
    VERTEX,
    EDGES,
    MGMT
}