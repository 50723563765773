import WireframeLayer from "./wireframeLayer";
import {Resource} from "../resourceManager";
import OrthoMapQuad from "../orthoMapQuad";
import * as THREE from 'three';
import {WireframeElementType} from "./wireframeElementType";
import {WireframeApplication} from "./wireframeApplication";

export default class OrthoLayer extends WireframeLayer {

    orthoQuad:OrthoMapQuad
    constructor(app:WireframeApplication, name:string, label:string) {
        super(app, name, label)
        this.isExpandable = false
        this.supportsEditing = false
        this.supportsInteraction = false
    }

    init() {
        super.init()
        let that = this
        this.object.name = "orthoImage-" + this.resource.id
        for (let elLayer of Object.values(this.elementLayers)) elLayer.isVisible = false
        this.elementLayers[WireframeElementType.plane].isVisible = true

        this.orthoQuad = new OrthoMapQuad(this.app)
        this.orthoQuad.orthoResource = this.resource
        this.orthoQuad.layer = this
        this.orthoQuad.location.lat = this.resource.metadata["orthoParameters"]["centerCoordinateGeodetic"]["lat"]
        this.orthoQuad.location.lon = this.resource.metadata["orthoParameters"]["centerCoordinateGeodetic"]["lon"]
        this.orthoQuad.pixelSize = this.resource.metadata["orthoParameters"]["pixelSize"] || .019
        this.orthoQuad.zoomLevel = this.resource.metadata["orthoParameters"]["zoomLevel"] || 20
        this.orthoQuad.init()
        this.eventEmitter.on("visibilityChanged").subscribe(() => {
            console.log("ortho vis changed")
            that.app.rootScene.updateMatrixWorld(true)
        })
    }

}