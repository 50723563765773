import {
    AfterViewInit,
    Component
} from '@angular/core';
import {VertexFilterSeenModel} from "../../models/vertexFilters/vertexFilterSeen.model";
import InteractiveFilterTypeEditorComponent from "../interactiveFilterTypeEditor/interactiveFilterTypeEditor.component";
import {VertexElementModel} from "../../models/vertexElements/vertexElement.model";

@Component({
    selector: 'ptv-vertex-filter-seen-editor',
    styles: [require('./vertexFilterSeenEditor.component.scss')],
    template: require('./vertexFilterSeenEditor.component.html')
})
export default class VertexFilterSeenEditorComponent extends InteractiveFilterTypeEditorComponent<VertexElementModel> implements AfterViewInit {

    constructor() {
        super();
    }

    get vertexFilterSeenModel(): VertexFilterSeenModel{
        return this.interactiveFilterModel as VertexFilterSeenModel;
    }

    ngAfterViewInit(): void {
    }

    onSelectionBlur() {
        this.triggerFilterUpdated();
    }

    onSelectionChange(value){
        this.vertexFilterSeenModel.seenValue = value == "true" ? true : false;
        this.triggerFilterUpdated();
    }
}