import {TextProperty} from "./textProperty";

export class LabelProperty extends TextProperty {
    constructor() {
        super();
        this.type = ".LabelProperty";
        this.name = "label";
        this.defaultValue = "";
        this.isEditable = false;
    }
}