import {Layer} from "./layer";
import {Object3DLayer} from "./object3DLayer";
import {Registration, Resource, S3Object} from "../resourceManager";
import PointCloudMetadata from "../models/pointCloudMetadata";
import {
    PointCloudMaterial,
    PointCloudOctree,
    PointColorType,
    PointShape,
    PointSizeType,
    Potree
} from "@pix4d/three-potree-loader";
import {WireframeApplication} from "./wireframeApplication";
import WireframeLayer from "./wireframeLayer";
import * as THREE from 'three';
import ProjectionUtils from "../projectionUtils";
import {Broadcaster, BroadcastEvent} from "../Broadcaster";
import OrthoLayer from "./orthoLayer";
import PointCloudLayer from "./pointCloudLayer";
import {ROILayer} from "./ROILayer";
import {CameraLayer} from "./cameraLayer";
import {Camera} from "three";

class ReconstructionCollection {
    id:number
    resource:Resource
    children:Resource[] = []
}

export default class SceneManager {
    public eventEmitter:Broadcaster = new Broadcaster()

    rootScene = new THREE.Scene()
    rootLayers:Layer[] = []

    roiLayer:ROILayer
    cesiumLayer:Layer
    pointCloudLayer:Object3DLayer
    orthoImageLayer:Object3DLayer

    geocentricFrame = new THREE.Group()
    geocentricMat = new THREE.Matrix4()

    public potree = new Potree();
    public pointClouds: PointCloudOctree[] = [];

    reconstructionCollections:ReconstructionCollection[] = []

    constructor(private app:WireframeApplication) {
        this.cesiumLayer = new Layer(this.app, "cesium", "Ortho Map")
        this.pointCloudLayer = new Object3DLayer(this.app, "pointclouds", "Point Clouds")
        this.orthoImageLayer = new Object3DLayer(this.app, "orthoImages", "Ortho Layer")

        this.rootScene.autoUpdate = false

        this.potree.pointBudget = 5_000_000
        this.potree.features.SHADER_EDL = true
        this.potree.features.SHADER_SPLATS = false
    }

    getAllLayers():Layer[] {
        let allLayers:Layer[] = []
        function recurse(layerList:Layer[], layer:Layer) {
            layerList.push(layer)
            for (let child of layer.children) recurse(layerList, child)
        }
        for (let layer of this.rootLayers) recurse(allLayers, layer)
        return allLayers
    }

    getWireframeLayers():WireframeLayer[] {
        let wfLayers:WireframeLayer[] = []
        return this.getAllLayers().filter((l:Layer) => l instanceof WireframeLayer) as WireframeLayer[]
    }

    addRootLayer(layer:Layer) {
        this.rootLayers.push(layer)
        this.eventEmitter.broadcast("layersChanged")
    }

    private _wireframeLayer:WireframeLayer
    getActiveWireframeLayer():WireframeLayer {
        return this._wireframeLayer
    }

    getInteractiveLayers():Layer[] {
        return this.getAllLayers().filter((l:Layer) => {
            return l.isInteractionEnabled
        })
    }

    setGeodeticCenter(lla:THREE.Vector3) {
        let geocent = ProjectionUtils.toGeocentric(lla)
        let orthoMat = ProjectionUtils.getOrthotransformForGeocentric(geocent)
        this.geocentricFrame.matrixAutoUpdate = false
        this.geocentricFrame.matrix.copy(orthoMat)
        this.geocentricMat = new THREE.Matrix4().getInverse(orthoMat)
        this.app.rootScene.updateMatrixWorld(true)
        console.log("geocent mat", this.geocentricFrame.matrix)
    }

    initLayers() {
        let that = this;

        this.geocentricFrame.name = "geocentric"
        this.app.rootScene.add(this.geocentricFrame)
        // wireframe
        this._wireframeLayer = new WireframeLayer(this.app, "wireframe", "Wireframe");
        this._wireframeLayer.parent = this.geocentricFrame
        this._wireframeLayer.isVisible = true
        this._wireframeLayer.init()
        this.addRootLayer(this._wireframeLayer);


        this.roiLayer = new ROILayer(this.app,"roi", "Region of Interest")

        this.geocentricFrame.add(this.roiLayer.object)
        this.roiLayer.isVisible = false
        this.roiLayer.init()

        try {
            if (this.app.projectData.metadata.reconstructed.regionOfInterest.polygons.length > 0) {
                this.roiLayer.loadFromGeoJSON(this.app.projectData.metadata.reconstructed.regionOfInterest.polygons)
                //RegionOfInterestUtil.createROIGeometry(that.roiLayer, this.app.projectData.metadata.reconstructed.regionOfInterest.polygons, this.app.sceneService.getProjectGeodetic())
                that.roiLayer.redrawWireframe();
                this.addRootLayer(this.roiLayer)
            }
        } catch (e) {}

        this.cesiumLayer.eventEmitter.on<BroadcastEvent>("visibilityChanged").subscribe(() => {
            this.app.cesiumComponent.setVisible(this.cesiumLayer.isVisible)
            if (this.cesiumLayer.isVisible) this.app.cesiumComponent.initCesium()
        })

        this.cesiumLayer.isVisible = false
        this.addRootLayer(this.cesiumLayer)

        this.pointCloudLayer.parent = this.geocentricFrame

        this.pointCloudLayer.init()
        this.addRootLayer(this.pointCloudLayer)
        this.pointCloudLayer.isVisible = true

        this.getAllLayers().forEach((layer) => {
            if (!(layer instanceof Object3DLayer)) return
            layer.eventEmitter.on("visibilityChanged").subscribe(() => {
                let bbox:THREE.Box3 = new THREE.Box3()
                if (that.pointCloudLayer.children.length > 0) {
                    bbox.setFromObject(that.pointCloudLayer.object)
                } else {
                    that.getAllLayers().forEach((layer) => {
                        if (!(layer instanceof Object3DLayer)) return
                        if (layer == that.roiLayer) return
                        bbox.expandByObject(layer.object)
                    })
                }
                if (isFinite(bbox.min.length())) {
                    that.roiLayer.setVerticalExtents(bbox.min.applyMatrix4(that.geocentricMat), bbox.max.applyMatrix4(that.geocentricMat))
                }
                that.roiLayer.redrawWireframe()
                that.app.rootScene.updateMatrixWorld(true)
            })
        })

        that.app.rootScene.updateMatrixWorld(true)
    }

    initOrthoLayers() {

        let that = this
        this.orthoImageLayer.supportsInteraction = true
        this.orthoImageLayer.supportsEditing = false

        this.orthoImageLayer.parent = this.geocentricFrame
        this.addRootLayer(this.orthoImageLayer)
        this.orthoImageLayer.isVisible = true
        Object.values(this.app.apiService.getProjectResources())
            .filter((r:Resource) => r.resourceType.name == "ORTHO" )
            .forEach((r:Resource) => {
                //console.log("adding ortho resource ", r)
                if (!r.metadata || !r.metadata.orthoParameters) return
                let subLayer = new OrthoLayer(that.app, "ortho-" + r.id, r.name)
                subLayer.resource = r
                subLayer.supportsEditing = false
                subLayer.planeDetectionEnabled = false
                this.orthoImageLayer.addChildLayer(subLayer)
                subLayer.parent = this.orthoImageLayer.object
                subLayer.isVisible = true
                subLayer.init()

            })
        this.orthoImageLayer.object.updateMatrixWorld(true)
        this.orthoImageLayer.eventEmitter.on("visibilityChanged").subscribe(() => {
            that.app.rootScene.updateMatrixWorld(true)
        })
        this.orthoImageLayer.children.forEach((l) => l.isVisible = false)
        this.orthoImageLayer.isVisible = false
    }

    private getOrCreateReconstructionCollection(id:number) {
        let that = this
        let col = this.reconstructionCollections.find((r) => r.id == id)
        if (!col) {
            col = new ReconstructionCollection()
            col.id = id
            col.resource = this.app.apiService.getProjectResources().find((r:Resource) => { r.id == id})
            that.reconstructionCollections.push(col)
        }
        return col
    }

    loadCameras() {
        let that = this
        let resources = this.app.apiService.getProjectResources() as Resource[]

        resources.filter((r:Resource) => { return r.resourceType.name == "IMAGE" && r.registrations != null } )
            .forEach((r:Resource) => {
                r.registrations.forEach((reg) => {
                    if (reg.type != Registration.TYPE_RECONSTRUCTION) return
                    let col = that.getOrCreateReconstructionCollection(reg.contextResourceId)
                    col.children.push(r)
                })
            })
        that.reconstructionCollections.forEach((col) => {
            if (!col.children.find((res) => res.resourceType.name == "IMAGE")) return
            let layer = new CameraLayer(that.app, "cameras-" + col.id, "Cameras-" + col.id)
            layer.reconstructionResourceId = col.id
            that.addRootLayer(layer)
            layer.parent = that.geocentricFrame

            // TODO this needs to be done for all layers automatically
            layer.eventEmitter.on("visibilityChanged").subscribe(() => {
                that.app.rootScene.updateMatrixWorld(true)
                layer.redrawWireframe()
            })

            layer.isVisible = true
            layer.init()

            that.app.undoEnabled = false
            col.children.forEach((res) => {
                if (res.resourceType.name == "IMAGE") {
                    layer.addImageResource(res)
                }
            })
            that.app.undoEnabled = true
            layer.isVisible = false
        })
    }

    loadPointClouds() {
        let that = this
        let resources = this.app.apiService.getProjectResources() as Resource[]
        let pointCloudCount = 0
        resources.filter((r:Resource) => { return r.resourceType.name == "POTREE_POINTCLOUD" } )
            .forEach((r:Resource) => {
                if (!r.registrations) {
                    console.error("Can't load pointcloud with no registrations", r)
                    return
                }
                let reg = r.registrations.find((reg) => reg.type == Registration.TYPE_NATIVE)
                if (!reg) {
                    console.warn("PointcloudResource missing registration", r)
                    return
                }
                let layer = new PointCloudLayer(that.app, r.resourceType.name + "-" + r.id, r.name)
                layer.parent = that.pointCloudLayer.object
                that.pointCloudLayer.addChildLayer(layer)

                let col = that.getOrCreateReconstructionCollection(reg.contextResourceId)
                col.children.push(r)
                // init the view after the first pointcloud loads
                if (pointCloudCount == 0) {
                    layer.loadPointcloud(r, (pco:PointCloudOctree) => {
                        that.app.broadcast("sceneLoaded")
                        that.app.initViewport()
                        if (that.roiLayer.polygons.length > 0) setTimeout(() => { that.roiLayer.isVisible = true }, 5000)

                    })
                } else {
                    layer.loadPointcloud(r)
                }
                pointCloudCount++
        })

        // in case there are no pointclouds
        if (pointCloudCount == 0) {
            this.app.broadcast("sceneLoaded")
            this.app.initViewport()
        }
    }

    /**
     * Loads a point cloud into the viewer and returns it.
     *
     * @param fileName
     *    The name of the point cloud which is to be loaded.
     * @param baseUrl
     *    The url where the point cloud is located and from where we should load the octree nodes.
     */
    loadPointcloud(s3Object:S3Object, container:THREE.Object3D, filename, loadFunc:Function): Promise<PointCloudOctree> {
        return this.potree
            .loadPointCloud(
                // The file name of the point cloud which is to be loaded.
                filename,
                // Given the relative URL of a file, should return a full URL.
                url => {
                    let r = new S3Object()
                    r.bucket = s3Object.bucket
                    r.key = s3Object.key + "/" + url
                    return this.app.resourceManager.generatePresignedUrl(r)
                }
            )
            .then((pco: PointCloudOctree) => {
                // Add the point cloud to the scene and to our list of
                // point clouds. We will pass this list of point clouds to
                // potree to tell it to update them.
                //container.add(pco);
                this.pointClouds.push(pco);
                loadFunc.call(this, [pco])
                return pco;
            }).finally(() => {

            });
    }


    setPointSize(size:number) {
        //console.log("setting point cloud point size to " + size);
        localStorage.setItem("pointSize", size.toString());
        let that = this
        this.pointClouds.forEach((pco:PointCloudOctree) => {
            //console.log("size " + pco.material.size + " => " + size)
            that.setPointcloudMaterial(pco.material)
        })
    }

    getPointSize():number {
        return parseFloat(localStorage.getItem("pointSize")) || 3;
    }

    public setPointcloudMaterial(pcoMat:PointCloudMaterial) {
        pcoMat.size = this.getPointSize()
        pcoMat.minSize = .01
        pcoMat.maxSize = 10
        pcoMat.shape = PointShape.SQUARE
        pcoMat.pointColorType = PointColorType.RGB
        pcoMat.pointSizeType = PointSizeType.FIXED
    }
}
