import {WireframeElementType} from "../../application/wireframeElementType";
import {DictionaryProperty} from "./dictionaryProperty";

export class VertexAnnotationProperty extends DictionaryProperty {
    constructor() {
        super();
        this.isEditable = false;
        this.allowMultiplePerElement = false;
        this.allowMultiplePerWireframe = false;
        this.name = "Vertex Annotation";
        this.type = ".VertexAnnotationProperty";
        this.allowableElementTypes = [WireframeElementType.vertex];
        this.knownValues = {};
        this.setValuesFromObject(this.knownValues);
    }
}