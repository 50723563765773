import {
    Component,
    Input
} from '@angular/core';
import {WireframeElement} from "../../../application/wireframeElement";
import {PropertyManager} from "../../../models/property/propertyManager";
import {ElementReferenceProperty} from "../../../models/property/elementReferenceProperty";
import {CompoundProperty} from "../../../models/property/compoundProperty";
import {PropertyValue} from "../../../models/property/propertyValue";
import {ElementReference} from "../../../models/property/elementReference";
import {WireframeApplication} from "../../../application/wireframeApplication";

@Component({
    selector: 'element-reference-property-editor',
    styles: [require('./elementReferencePropertyEditor.component.scss')],
    template: require('./elementReferencePropertyEditor.component.html')
})
export default class ElementReferencePropertyEditorComponent {
    @Input() wireframeElements: WireframeElement[];
    @Input() propertyManager: PropertyManager;
    @Input() property: ElementReferenceProperty;

    constructor(private _wireframeApplication: WireframeApplication) {
    }

    getPropertyValue(wireframeComponent): ElementReference {
        let value: ElementReference = null;
        if (!wireframeComponent) return value;

        wireframeComponent.properties.forEach((currentPropertyValue) => {
            let currentProperty = this.propertyManager.getProperty(currentPropertyValue.id);
            if (this.property.id == currentProperty.id) {
                let propertyValue: PropertyValue = currentProperty.getPropertyValue(wireframeComponent);
                if(!propertyValue) return;
                value = propertyValue.value;
            } else if (currentProperty instanceof CompoundProperty) {
                // compound properties
                let compoundProperty = currentProperty as CompoundProperty;
                compoundProperty.childProperties.forEach((childProperty) => {
                    if (this.property.name === childProperty.name) {
                        let parentPropertyValue = currentProperty.getPropertyValue(wireframeComponent);
                        if(!parentPropertyValue) return;
                        value = parentPropertyValue.value[this.property.name as string];
                    }
                })
            }
        });

        return value;
    }

    get combinedPropertyValue(): ElementReference {
        let value = null;
        if (!this.property) return value;
        if (!this.wireframeElements) return value;
        let values: ElementReference[] = [];
        this.wireframeElements.forEach((wireframeElement) => {
            let wireframeComponent = wireframeElement.pvObject;
            let value = this.getPropertyValue(wireframeComponent);
            if(value == null) return;
            values.push(value);
        });

        if (values.length > 0 && this.allValuesAreTheSame) {
            value = values[0];
        }
        return value;
    }

    set combinedPropertyValue(value:ElementReference) {
        if (!this.property) return;
        this.wireframeElements.forEach((wireframeElement) => {
            let wireframeComponent = wireframeElement.pvObject;
            wireframeComponent.properties.forEach((currentPropertyValue) => {
                let currentProperty = this.propertyManager.getProperty(currentPropertyValue.id);
                if (this.property.id == currentProperty.id) {
                    currentProperty.setValue(wireframeElement, value);
                } else if (currentProperty instanceof CompoundProperty) {
                    // compound properties
                    let compoundProperty = currentProperty as CompoundProperty;
                    compoundProperty.childProperties.forEach((childProperty) => {
                        if (this.property.name === childProperty.name) {
                            let parentPropertyValue = currentProperty.getPropertyValue(wireframeComponent);
                            if(!parentPropertyValue) return;
                            parentPropertyValue.value[this.property.name as string] = value;
                        }
                    })
                }
            });
        });
        this.propertyManager.triggerPropertiesChanged();
    }

    get allValuesAreTheSame(): boolean {
        let allValuesAreTheSame = false;
        if (!this.property) return allValuesAreTheSame;
        if (!this.wireframeElements) return allValuesAreTheSame;
        let values:ElementReference[] = [];
        this.wireframeElements.forEach((wireframeElement) => {
            let wireframeComponent = wireframeElement.pvObject;
            let value = this.getPropertyValue(wireframeComponent);
            values.push(value);
        });
        allValuesAreTheSame = values.every((val, i, arr) => val === arr[0]);
        return allValuesAreTheSame;
    }

    get hasMultipleValues():boolean{
        let multi = this.wireframeElements.length > 0;
        return multi;
    }

    get isReadOnly(): boolean {
        let readOnly = true;
        if (!this.property) return readOnly;
        readOnly = this.property.isReadonly;
        return readOnly;
    }

    getFirstPropertyValue():ElementReference{
        let propertyValue:ElementReference = null;
        if(!this.wireframeElements) return;
        if(this.wireframeElements.length > 0){
            let firstWireframeElement = this.wireframeElements[0];
            let firstWireframeComponent = firstWireframeElement.pvObject;
            propertyValue = this.getPropertyValue(firstWireframeComponent);
        }
        return propertyValue;
    }

    startElementSelection() {
        let self = this;
        let elementReference = this.getFirstPropertyValue();
        let elementReferencePropertyValue = new PropertyValue(this.property.id, elementReference);
        this.property.startElementSelection(this._wireframeApplication, elementReferencePropertyValue, () => {
            self.combinedPropertyValue = elementReferencePropertyValue.value;
        });
    }

    getSelectedElementLabel(): string {
        let elementLabel = "-";
        let elementReferenceValue = this.getFirstPropertyValue();
        if(!elementReferenceValue) return elementLabel;
        let elements = ElementReferenceProperty.getElements(this._wireframeApplication.wireframeLayer, elementReferenceValue);
        if (elements.length > 0) {
            elementLabel = this.propertyManager.getLabelForElement(elements[0]) as string;
            if (elements.length > 1) {
                elementLabel += " +" + (elements.length - 1);
            }
        }
        return elementLabel;
    }

    highlightSelection() {
        let elementReferenceValue = this.getFirstPropertyValue();
        let elements = ElementReferenceProperty.getElements(this._wireframeApplication.wireframeLayer, elementReferenceValue);
        this.propertyManager.highlightSelection(elements);
    }

}