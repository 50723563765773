import {
    Component,
    Input
} from '@angular/core';
import {WireframeElement} from "../../application/wireframeElement";
import * as THREE from 'three';

@Component({
    selector: 'wireframe-focus-information',
    styles: [require('./wireframeFocusInformation.component.scss')],
    template: require('./wireframeFocusInformation.component.html')
})
export default class WireframeFocusInformationComponent {
    @Input() focusedWireframeElement: WireframeElement;

    constructor() {
    }

    get isFocused():boolean{
        let focused = false;
        if(this.focusedWireframeElement){
            focused = true;
        }
        return focused;
    }

    get name(): string {
        let name = null;

        if(this.focusedWireframeElement) {
            name = this.focusedWireframeElement.name;
        }

        return name;
    }

    get worldPosition(): THREE.Vector3{
        let centerPoint:THREE.Vector3 = null;

        if(this.focusedWireframeElement){
            centerPoint = this.focusedWireframeElement.getWorldPosition(new THREE.Vector3());
        }

        return centerPoint;
    }

}