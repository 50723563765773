import { Injectable, EventEmitter } from '@angular/core';

export interface Logo {
    name: string;
    path: string;
    properties: any;
}

@Injectable()
export class LogoService {
    logoChange = new EventEmitter<Logo>();
    logo: Logo;

    constructor(
    ) {
    }

    getLogo() {
        return this.logo;
    }

    setLogo(logo: Logo) {
        this.logo = logo;
        this.logoChange.emit( this.getLogo());
    }

    getProperty(propName: string) {
        return this.getLogo().properties[propName];
    }
}
