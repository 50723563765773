import {Property} from "./property";

export class CompoundProperty extends Property {
    childProperties: Property[] = [];

    constructor() {
        super();
        this.defaultValue = {};
    }

    fromJson(obj, allProperties: Function[]): void {
        super.fromJson(obj, allProperties);
        if (Array.isArray(obj.childProperties)) {
            this.childProperties = [];
            for (let i = 0; i < obj.childProperties.length; i++) {
                let propJson = obj.childProperties[i];
                let propClass = propJson.type.substr(1);
                if (!allProperties[propClass]) {
                    continue;
                }
                let prop: Property = Property.deserialize(propClass, propJson, allProperties);
                this.childProperties.push(prop);
            }
        }
    }

    getDefaultValue() {
        let ret = {};
        this.childProperties.forEach(function (p: Property) {
            ret["" + p.name] = p.getDefaultValue();
        });
        return ret;
    }

    onAddToWireframe() {
        super.onAddToWireframe();
        for (let prop of this.childProperties) {
            prop.onAddToWireframe();
        }
    }

    onLoad() {
        super.onLoad();
        for (let prop of this.childProperties) {
            prop.onLoad();
        }
    }

    onRemoveFromWireframe() {
        super.onRemoveFromWireframe();
        for (let prop of this.childProperties) {
            prop.onRemoveFromWireframe();
        }
    }

    valueFromJson(json: any): any {
        let that = this
        let val = {}
        this.childProperties.forEach((prop) => {
            val[prop.name] = prop.valueFromJson(json[prop.name])
        })
        return val
    }
}
