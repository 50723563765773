import {Deserializable, nestedObjectAssign} from "../serialization/deserialization.model";
import {EventEmitter} from "@angular/core";
import {InteractiveSelectEventModel} from "./interactiveSelectEvent.model";
import {InteractiveOperationEventModel} from "./interactiveOperationEvent.model";
import {InteractiveOperationModel} from "./interactiveOperation.model";

export class InteractiveModel implements Deserializable {

    // focus
    private isFocused: boolean = false;
    private focusedChangingEvent: EventEmitter<InteractiveModel> = new EventEmitter<InteractiveModel>();
    private focusedChangedEvent: EventEmitter<InteractiveModel> = new EventEmitter<InteractiveModel>();

    get focused(): boolean {
        return this.isFocused;
    }

    set focused(focused: boolean) {
        this.focusedChanging.emit(this);
        this.isFocused = focused;
        this.focusedChanged.emit(this);
    }

    get focusedChanging(): EventEmitter<InteractiveModel> {
        return this.focusedChangingEvent
    }

    get focusedChanged(): EventEmitter<InteractiveModel> {
        return this.focusedChangedEvent
    }

    // select
    private isSelected: boolean = false;
    private selectedChangingEvent: EventEmitter<InteractiveSelectEventModel<this>> = new EventEmitter<InteractiveSelectEventModel<this>>();
    private selectedChangedEvent: EventEmitter<InteractiveSelectEventModel<this>> = new EventEmitter<InteractiveSelectEventModel<this>>();

    get selected(): boolean {
        return this.isSelected;
    }

    set selected(selected: boolean) {
        this.setSelected(selected);
    }

    setSelected(selected: boolean, append: boolean = false, toggle: boolean = false) {
        this.selectedChanging.emit(new InteractiveSelectEventModel(this, append, toggle));
        this.isSelected = selected;
        this.selectedChanged.emit(new InteractiveSelectEventModel(this, append, toggle));
    }

    get selectedChanging(): EventEmitter<InteractiveSelectEventModel<this>> {
        return this.selectedChangingEvent
    }

    get selectedChanged(): EventEmitter<InteractiveSelectEventModel<this>> {
        return this.selectedChangedEvent
    }

    // highlight
    private isHighlighted: boolean = false;
    private highlightedChangingEvent: EventEmitter<InteractiveModel> = new EventEmitter<InteractiveModel>();
    private highlightedChangedEvent: EventEmitter<InteractiveModel> = new EventEmitter<InteractiveModel>();

    get highlighted(): boolean {
        return this.isHighlighted;
    }

    set highlighted(highlighted: boolean) {
        this.highlightedChanging.emit(this);
        this.isHighlighted = highlighted;
        this.highlightedChanged.emit(this);
    }

    get highlightedChanging(): EventEmitter<InteractiveModel> {
        return this.highlightedChangingEvent
    }

    get highlightedChanged(): EventEmitter<InteractiveModel> {
        return this.highlightedChangedEvent
    }

    // operations
    private interactiveOperationStartEvent: EventEmitter<InteractiveOperationEventModel<this>> = new EventEmitter<InteractiveOperationEventModel<this>>();
    private interactiveOperationEndEvent: EventEmitter<InteractiveOperationEventModel<this>> = new EventEmitter<InteractiveOperationEventModel<this>>();
    private currentOperations:InteractiveOperationModel<this>[] = [];
    public get operations():InteractiveOperationModel<this>[]{
        return this.currentOperations;
    }

    get operationStarted():EventEmitter<InteractiveOperationEventModel<this>>{
        return this.interactiveOperationStartEvent;
    }

    get operationEnded():EventEmitter<InteractiveOperationEventModel<this>>{
        return this.interactiveOperationEndEvent;
    }

    public startOperation(interactiveOperationModel:InteractiveOperationModel<this>){
        let interactiveOperationEventModel = new InteractiveOperationEventModel(this, interactiveOperationModel);
        if(!this.addOperation(interactiveOperationModel)) return;
        this.operationStarted.emit(interactiveOperationEventModel);
    }

    public endOperation(interactiveOperationModel:InteractiveOperationModel<this>){
        let interactiveOperationEventModel = new InteractiveOperationEventModel(this, interactiveOperationModel);
        if(!this.removeOperation(interactiveOperationModel.id)) return;
        this.operationEnded.emit(interactiveOperationEventModel);
    }

    private addOperation(interactiveOperationModel:InteractiveOperationModel<this>) : boolean {
        let success = false;
        let operationId = interactiveOperationModel.id;
        let operationIndex = this.getOperationIndex(operationId);
        if(operationIndex >= 0) return success;
        this.currentOperations.push(interactiveOperationModel);
        success = true;
        return success;
    }

    private removeOperation(operationId:string) : boolean {
        let success = false;
        let operationIndex = this.getOperationIndex(operationId);
        if (operationIndex !== -1) {
            this.currentOperations.splice(operationIndex, 1);
            success = true;
        }
        return success;
    }

    private getOperationIndex(operationId:string){
        let operationIndex = this.currentOperations.findIndex((currentInteractiveOperationModel) => {
            return currentInteractiveOperationModel.id == operationId;
        });
        return operationIndex;
    }

    // serialization
    deserialize(input: any) {
        nestedObjectAssign(this, input);
        return this;
    }
}