import {WireframeApplication} from "./wireframeApplication";
import {WireframeOperations} from "../wireframeOperations";
import {CameraControlMode} from "./tool";
import {ContinuePolygon} from "./continuePolygon";
import {PV} from "../wireframe";
import {WireframeElementType} from "./wireframeElementType";
import ShapeTool from "./shapeTool";
import {WireframeElement} from "./wireframeElement";
import {VertexElement} from "./vertexElement";
import {EdgeElement} from "./edgeElement";
import {PlaneElement} from "./planeElement";


export default class PolygonTool extends ShapeTool {
    toolShape:ContinuePolygon
    lastVertEl:VertexElement

    constructor(wfApp:WireframeApplication) {
        super(wfApp)
        this.toolName = "PolygonTool"
        //this.cameraControlMode = CameraControlMode.MIDDLE
    }

    updateActivePolygon() {
        if (this.lastVertEl) {
            let newPos = this.getMousePointOnPlane(this.mouse, this.toolShape.bestFitPlane);
            if (newPos) this.app.wireframe.setVert3(this.lastVertEl.pvObject.id, this.app.wireframeLayer.toWireframeCRS(newPos))
        }
        this.app.wireframeLayer.redrawWireframe();
    }

    onKeyUp(event:KeyboardEvent) {
        if (event.key == "Escape") {
            if (this.toolShape) {
                this.cancelActiveShape()
            }
        }
    }

    onMouseUp(event:MouseEvent) {

        let isDrag = this.getMouseDrag().length() > this.mouseDragThreshold

        if (!isDrag) {
            if (event.button == 2 && this.toolShape) {
                if (this.toolShape.plane.vertexIds.length > 3) {
                    let pvPlane = this.app.wireframe.findPlaneWithVertIds(this.toolShape.plane.vertexIds);
                    let planeEl = this.app.findWireframeElement(WireframeElementType.plane, pvPlane.id) as PlaneElement;
                    this.app.selectWireframeElement(null);
                    this.app.selectWireframeElement([planeEl], true);

                    this.app.wireframeLayer.deleteVertex(this.lastVertEl, true)
                    this.toolShape = null
                    this.lastVertEl = null
                } else {
                    this.cancelActiveShape()
                }
            } else if (event.button == 0) {
                if (!this.toolShape) {
                    this.toolShape = new ContinuePolygon();
                    let cp: ContinuePolygon = this.toolShape;
                    let drawingPlane = this.getDrawingPlane()

                    cp.plane = new PV.Plane();
                    cp.startVert = drawingPlane.point;
                    cp.bestFitPlane = drawingPlane.plane

                    let vert1 = new PV.Vertex();
                    this.app.wireframeLayer.addVertex(vert1);
                    this.app.wireframe.setVert3(vert1.id, this.app.wireframeLayer.toWireframeCRS(this.toolShape.startVert));
                    this.app.wireframe.addVertex(vert1);

                    cp.plane.vertexIds.push(vert1.id);

                    let vert2 = new PV.Vertex();
                    this.app.wireframe.addVertex(vert2);
                    this.app.wireframe.setVert3(vert2.id, this.app.wireframeLayer.toWireframeCRS(this.toolShape.startVert));

                    this.lastVertEl = this.app.wireframeLayer.addVertex(vert2);
                    cp.plane.vertexIds.push(vert2.id);

                    this.app.wireframeLayer.createPlaneEdgesFromVerts(cp.plane)

                } else {
                    let lastEdge = this.app.wireframe.findEdge(this.toolShape.plane.vertexIds[0], this.toolShape.plane.vertexIds[this.toolShape.plane.vertexIds.length - 1])
                    if (lastEdge) this.app.wireframeLayer.deleteEdge(this.app.wireframeLayer.findElement(lastEdge) as EdgeElement, false)

                    let pos = this.getMousePointOnPlane(this.mouse, this.toolShape.bestFitPlane)
                    let vert1 = new PV.Vertex();
                    this.lastVertEl = this.app.wireframeLayer.addVertex(vert1);
                    this.app.wireframe.setVert3(vert1.id, this.app.wireframeLayer.toWireframeCRS(pos));
                    this.app.wireframe.addVertex(vert1);
                    this.toolShape.plane.vertexIds.push(vert1.id);

                    this.app.wireframeLayer.createPlaneEdgesFromVerts(this.toolShape.plane)
                }
                this.app.selectWireframeElement(null);
                this.app.selectWireframeElement([this.lastVertEl], true);
            }
            this.updateActivePolygon();
        }
    }

    cancelActiveShape() {
        if (this.toolShape) {
            if (this.toolShape.plane.id) {
                // delete the plane if it's already been created
                this.app.wireframeLayer.deletePlane(this.app.findWireframeElement(WireframeElementType.plane, this.toolShape.plane.id) as PlaneElement, true);
            } else {
                // delete the verts
                this.toolShape.plane.vertexIds.forEach((vertexId:number) => {
                    this.app.wireframeLayer.deleteVertex(this.app.wireframeLayer.findElement(this.app.wireframe.vertices[vertexId]) as VertexElement, false)
                })
            }
            this.app.selectWireframeElement(null);
        }
        this.toolShape = null
        this.lastVertEl = null
    }

}