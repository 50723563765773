import {EnumProperty} from "./enumProperty";
import {WireframeElementType} from "../../application/wireframeElementType";
import {NormalOrientationOptions} from "./normalOrientationOptions";

export class NormalOrientationProperty extends EnumProperty {
    constructor() {
        super();
        this.isEditable = true;
        this.allowMultiplePerElement = false;
        this.allowMultiplePerWireframe = false;
        this.name = "Normal";
        this.type = ".NormalOrientationProperty";
        this.allowableElementTypes = [WireframeElementType.plane];
        this.setValuesFromEnum(NormalOrientationOptions);
    }
}