import {WireframeApplication} from "./application/wireframeApplication";
import {CameraView} from "./models/cameras/cameraView";
import {CameraPointView} from "./models/cameras/cameraPointView";
import * as THREE from 'three';
import {WireframeElementType} from "./application/wireframeElementType";
import {PV} from "./wireframe";
import {EdgeElement} from "./application/edgeElement";
import {PlaneElement} from "./application/planeElement";
import {CameraElement} from "./application/cameraElement";

declare var viewer: any;

declare var MWPR: any;
declare var Projection: any;

export class PointVisibility {

    private app:WireframeApplication
    private adjustCtrl:any
    constructor(app:WireframeApplication, adjustCtrl:any) {
        this.app = app
        this.adjustCtrl = adjustCtrl
    }
    //return index of two important camera using Check_Point_Set_Degeneracy_Out_Angle function
    obtainTwoImportantFrame(cameras, point) {
        let cameraList = [];
        let point2DList = [];

        for (let i = 0; i < cameras.length; i++) {
            let cameraIndex = Number(cameras[i]).toString();
            let cameraObject: CameraView = this.app.cameraDict[cameraIndex];
            cameraList.push(cameraObject);

            let point2D = Projection.Project_Point_With_Distortion([point.x, point.y, point.z], cameraObject.rotation, cameraObject.translation, cameraObject.skew, cameraObject.fx, cameraObject.fy, cameraObject.cx, cameraObject.cy, cameraObject.distortion, cameraObject.projectionType);
            point2DList.push(point2D);
            if (cameraIndex != "" && this.app.viewMode == "camera") {
                //cameraCone.makeVisibleCameraByCameraIndex(cameraIndex);
            }
        }

        let thresholdDegeneracyAnglePoint = 2;
        let angle = {} as any;
        MWPR.Check_Point_Set_Degeneracy_Out_Angle(point2DList, cameraList, thresholdDegeneracyAnglePoint, angle);
        viewer.adjustAngle = angle.angle;
        return [angle.h1, angle.h2];
    };

    findCamerasForPoint(cameras: CameraView[], point: THREE.Vector3): CameraPointView[] {
        let result: CameraPointView[] = [];
        for (let cameraObject of cameras) {

            let zeroDistortion = [];
            for (let k = 0; k < cameraObject.distortion.length; k++) {
                zeroDistortion.push(0);
            }

            let point2D = Projection.Project_Point_With_Distortion([point.x, point.y, point.z],
                cameraObject.rotation,
                cameraObject.translation,
                cameraObject.skew,
                cameraObject.fx,
                cameraObject.fy,
                cameraObject.cx,
                cameraObject.cy,
                zeroDistortion,
                cameraObject.projectionType
            );

            let minX = 0;
            let maxX = cameraObject.frameMetadata.width;
            let minY = 0;
            let maxY = cameraObject.frameMetadata.height;

            if (point2D[0] >= minX && point2D[0] <= maxX && point2D[1] >= minY && point2D[1] <= maxY) {

                    point2D = Projection.Project_Point_With_Distortion([point.x, point.y, point.z],
                    cameraObject.rotation,
                    cameraObject.translation,
                    cameraObject.skew,
                    cameraObject.fx,
                    cameraObject.fy,
                    cameraObject.cx,
                    cameraObject.cy,
                    cameraObject.distortion,
                    cameraObject.projectionType
                );

                if (point2D[0] >= minX && point2D[0] <= maxX && point2D[1] >= minY && point2D[1] <= maxY) {
                    let cpv = new CameraPointView();
                    result.push(cpv);
                    cpv.cameraView = cameraObject;
                    cpv.imageCoordinates = new THREE.Vector2(point2D[0] / cameraObject.frameMetadata.width, point2D[1] / cameraObject.frameMetadata.height);
                    let camPos = new THREE.Vector3(cpv.cameraView.cameraCenter.x, cpv.cameraView.cameraCenter.y, cpv.cameraView.cameraCenter.z);

                    cpv.vectorToPoint = point.clone().sub(camPos);
                    cpv.distance = cpv.vectorToPoint.length();

                    let xCenterFactor = 1.0 - (2 * Math.abs(cpv.imageCoordinates.x - .5));
                    let yCenterFactor = 1.0 - (2 * Math.abs(cpv.imageCoordinates.y - .5));
                    cpv.centerFactor = Math.min(xCenterFactor, yCenterFactor);
                    cpv.point = point;
                }
            }
        }
        return result
    }

    testGeometryOcclusion(views: CameraPointView[]) {
        let intersectCandidates = []
        Object.values(this.app.wireframe.filterGeometry(this.app.wireframe.planes)).forEach((plane:PV.Plane) =>{
            if (plane.parentId != null) return
            let el = this.app.wireframeLayer.findElement(plane)
            if (!el) return
            intersectCandidates.push(el)
        })

        let raycaster = new THREE.Raycaster();
        for (let cpv of views) {
            cpv.isGeometryOccluded = false;

            let camPos = this.app.wireframeLayer.toWorldCRS(new THREE.Vector3(cpv.cameraView.cameraCenter.x, cpv.cameraView.cameraCenter.y, cpv.cameraView.cameraCenter.z));
            let vectorToPoint:THREE.Vector3 = this.app.wireframeLayer.toWorldCRS(cpv.vectorToPoint);
            //console.log("cam " + cpv.cameraView.frameId + " camPos", camPos)
            //if (cpv.cameraView.frameId == 16) {
            //    console.log("camPos", camPos)
            //    console.log("vec", vectorToPoint)
            //}
            raycaster.ray.set(camPos, vectorToPoint.normalize());
            raycaster.far = Math.max(.1, cpv.distance - 1);
            let planeHits = this.app.wireframeLayer.raycastElements(raycaster, this.app.wireframeLayer.wireframeElements.filter((el) => el instanceof PlaneElement))
            if (planeHits.length > 0) {
                //console.log("view " + cpv.cameraView.frameId + " geometry occlusions", planeHits)
                cpv.isGeometryOccluded = true
            }
        }
    }

    getPoint3DFrames(point3D, moreFrames) {
        if (!point3D.hframes) point3D.hframes = [];
        let cameraPointViews = this.findCamerasForPoint(Object.values(this.app.cameraDict), new THREE.Vector3(point3D.x, point3D.y, point3D.z));
        if (!moreFrames) cameraPointViews = cameraPointViews.filter((cpv) => {
            return cpv.centerFactor >= .1
        });
        this.testGeometryOcclusion(cameraPointViews);
        //cameraPointViews.forEach((cpv) => {
        //    console.log("cpv " + cpv.cameraView.frameId + " occluded " + cpv.isGeometryOccluded, cpv)
        //})
        let frameList = cameraPointViews.filter((cpv) => !cpv.isGeometryOccluded).map((cpv) => cpv.cameraView.frameId);

        return frameList;
    };


    // test wheather the point3D is occluded on the frame(cameraIndex) with occParam sensivity or not.
    isOccluded(point3D, cameraIndex, occParam) {
        // disable all occlusion tests due to performance issues
        return false
        if (occParam == null) {
            occParam = 0;
        }
        let center = this.app.cameraDict[cameraIndex].cameraCenter;
        let cameraPosition = new THREE.Vector3(center[0], center[1], center[2]);
        cameraPosition = viewer.toLocal(cameraPosition);
        let vector;
        let localPoint3D;

        if (point3D != null) {
            point3D = new THREE.Vector3(point3D.x, point3D.y, point3D.z);
            localPoint3D = point3D.clone();
            localPoint3D = viewer.toLocal(localPoint3D);
            vector = localPoint3D.clone();
        }

        let raycaster = new THREE.Raycaster();
        raycaster.ray.set(cameraPosition, vector.sub(cameraPosition).normalize());
        //raycaster.params.PointCloud.threshold = viewer.distanceThreshold / (20 + occParam);

        let objects = [];
        let node;
        for (let i = 0; i < viewer.pointclouds.length; i++) {
            let pointcloud = viewer.pointclouds[i];
            let nodes = pointcloud.nodesOnRay(pointcloud.visibleNodes, raycaster.ray);
            for (let j = 0; j < nodes.length; j++) {
                node = nodes[j];
                objects.push(node.sceneNode);
            }

        }

        let intersects = raycaster.intersectObjects(objects, true);
        let ls = [];
        for (let i = 0; i < intersects.length; i++) {

            ls.push(intersects[i]);


        }

        if (ls.length > 0) {
            let hitted = viewer.toGeo(ls[0].point);
            let geoCameraPos = viewer.toGeo(cameraPosition);
            let hittedZ = geoCameraPos.distanceTo(hitted);
            let pointZ = geoCameraPos.distanceTo(point3D);

            if ((pointZ - hittedZ) > viewer.distanceThreshold) {
                return true;
            }
        }

        return false;
    };

    //this function return frist nonOcclused cameraIndex for a canvas
    getFirstNonOccFrame(id, cameraIndex, point3D, occParam, backward = false) {
        let cameraIndex2, cameraIndex3;
        if (Number(id) == 1) {
            cameraIndex2 = viewer.cameraIndex2;
            cameraIndex3 = viewer.cameraIndex3;
        }
        if (Number(id) == 2) {
            cameraIndex2 = viewer.cameraIndex1;
            cameraIndex3 = viewer.cameraIndex3;
        }
        if (Number(id) == 3) {
            cameraIndex2 = viewer.cameraIndex1;
            cameraIndex3 = viewer.cameraIndex2;
        }

        let nextCameraIndex = cameraIndex;
        for (let i = 0; i < point3D.hframes.length; i++) {
            //exist on other canvases
            if (Number(nextCameraIndex) == cameraIndex2 || Number(nextCameraIndex) == cameraIndex3) {
                nextCameraIndex = this.adjustCtrl.canvasOperations.getNextCameraIndex(nextCameraIndex);
                if (backward == true) {
                    nextCameraIndex = this.adjustCtrl.canvasOperations.getPreviousCameraIndex(nextCameraIndex);
                }
            }
            //exist on already computed occluded frames
            else if (point3D.occList.indexOf(nextCameraIndex) != -1) {
                nextCameraIndex = this.adjustCtrl.canvasOperations.getNextCameraIndex(nextCameraIndex);
                if (backward == true) {
                    nextCameraIndex = this.adjustCtrl.canvasOperations.getPreviousCameraIndex(nextCameraIndex);
                }
            }
            //shoud be tested
            else if (this.adjustCtrl.pointVisibility.isOccluded(point3D, nextCameraIndex, occParam)) {
                point3D.occList.push(nextCameraIndex);
                nextCameraIndex = this.adjustCtrl.canvasOperations.getNextCameraIndex(nextCameraIndex);
                if (backward == true) {
                    nextCameraIndex = this.adjustCtrl.canvasOperations.getPreviousCameraIndex(nextCameraIndex);
                }
            }
            else {
                return nextCameraIndex;
            }
        }
        return null;

        //todo: unreachable code
        if (!this.adjustCtrl.pointVisibility.isOccluded(point3D, cameraIndex, occParam)) {
            return cameraIndex;
        }

        nextCameraIndex = cameraIndex;
        if (nextCameraIndex == null) {
            return null;
        }

        while (Number(nextCameraIndex) == Number(viewer.cameraIndex1) ||
        Number(nextCameraIndex) == Number(viewer.cameraIndex2) ||
        Number(nextCameraIndex) == Number(viewer.cameraIndex3) || this.adjustCtrl.pointVisibility.isOccluded(point3D, nextCameraIndex, occParam)) {
            nextCameraIndex = this.adjustCtrl.canvasOperations.getNextCameraIndex(nextCameraIndex);
            if (nextCameraIndex == null || nextCameraIndex == cameraIndex) {
                return null;
            }
        }
        return nextCameraIndex;

    };

    //in this function we estimate destination 2d since some 3dPoint are beyond image and projection dont works good for them so we should estimate
    getPointEdgesOnImageByEstimation(cameraIndex, point3D) {
        let lines = [];
        let cameraObject: CameraView = this.app.cameraDict[cameraIndex];

        for (let i = 0; i < this.app.wireFrameElements.length; i++) {
            let edge = this.app.wireFrameElements[i] as EdgeElement;
            if (edge.pvObject.pvType == WireframeElementType.edge) {
                let src3D = this.app.wireframe.getVert3(edge.pvObject.vertex1Id);
                let des3D = this.app.wireframe.getVert3(edge.pvObject.vertex2Id);

                let s3D, d3D;

                if (point3D.x == src3D.x && point3D.y == src3D.y && point3D.z == src3D.z) {
                    s3D = src3D;
                    d3D = des3D;

                }
                else if (point3D.x == des3D.x && point3D.y == des3D.y && point3D.z == des3D.z) {
                    s3D = des3D;
                    d3D = src3D;
                }
                else {
                    continue;
                }

                let zeroDistortion = [];
                for (let k = 0; k < cameraObject.distortion.length; k++) {
                    zeroDistortion.push(0);
                }

                let s2D = Projection.Project_Point_With_Distortion([s3D.x, s3D.y, s3D.z], cameraObject.rotation, cameraObject.translation, cameraObject.skew, cameraObject.fx, cameraObject.fy, cameraObject.cx, cameraObject.cy, cameraObject.distortion, cameraObject.projectionType);
                let s2D_zeroDist = Projection.Project_Point_With_Distortion([s3D.x, s3D.y, s3D.z], cameraObject.rotation, cameraObject.translation, cameraObject.skew, cameraObject.fx, cameraObject.fy, cameraObject.cx, cameraObject.cy, zeroDistortion, cameraObject.projectionType);
                s2D_zeroDist = new THREE.Vector2(s2D_zeroDist[0], s2D_zeroDist[1]);

                let d2D = Projection.Project_Point_With_Distortion([d3D.x, d3D.y, d3D.z], cameraObject.rotation, cameraObject.translation, cameraObject.skew, cameraObject.fx, cameraObject.fy, cameraObject.cx, cameraObject.cy, cameraObject.distortion, cameraObject.projectionType);
                let d2D_zeroDist = Projection.Project_Point_With_Distortion([d3D.x, d3D.y, d3D.z], cameraObject.rotation, cameraObject.translation, cameraObject.skew, cameraObject.fx, cameraObject.fy, cameraObject.cx, cameraObject.cy, zeroDistortion, cameraObject.projectionType);
                d2D_zeroDist = new THREE.Vector2(d2D_zeroDist[0], d2D_zeroDist[1]);

                let p1 = new THREE.Vector2(s2D[0], s2D[1]);
                let p2 = new THREE.Vector2(d2D[0], d2D[1]);
                let p2_distorted;

                let counter = 0;

                let angleDeg = 90;
                while (p2.x <= 0 || p2.x >= viewer.realImageWidth || p2.y <= 0 || p2.y >= viewer.realImageHeight || Math.abs(angleDeg) > 10) {
                    counter++;
                    if (counter > 100) {
                        console.log("cannot project -------------------------------------");
                        break;
                    }
                    let direction = d3D.clone().sub(s3D);
                    direction.multiplyScalar(9 / 10);
                    d3D = s3D.clone().add(direction);
                    d2D = Projection.Project_Point_With_Distortion([d3D.x, d3D.y, d3D.z], cameraObject.rotation, cameraObject.translation, cameraObject.skew, cameraObject.fx, cameraObject.fy, cameraObject.cx, cameraObject.cy, cameraObject.distortion, cameraObject.projectionType);
                    p2 = new THREE.Vector2(d2D[0], d2D[1]);

                    d2D_zeroDist = Projection.Project_Point_With_Distortion([d3D.x, d3D.y, d3D.z], cameraObject.rotation, cameraObject.translation, cameraObject.skew, cameraObject.fx, cameraObject.fy, cameraObject.cx, cameraObject.cy, zeroDistortion, cameraObject.projectionType);
                    d2D_zeroDist = new THREE.Vector2(d2D_zeroDist[0], d2D_zeroDist[1]);


                    let dir2d = p2.clone().sub(p1);
                    let dir2d_zeroDist = d2D_zeroDist.clone().sub(s2D_zeroDist);
                    angleDeg = Math.atan2(dir2d.y, dir2d.x) - Math.atan2(dir2d_zeroDist.y, dir2d_zeroDist.x);
                    angleDeg = angleDeg * 180 / Math.PI;
                }

                lines.push([p1, p2]);
            }
        }

        return lines;
    };
}
