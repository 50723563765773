import {Component, ElementRef, NgZone, OnDestroy, ViewChild,} from '@angular/core';
import {WireframeApplication} from "../../application/wireframeApplication";
import {WireframeOperations} from "../../wireframeOperations";
import {SceneService} from "../../services/scenes/scene.service";
import {CameraType} from "../../application/cameraType";
import * as THREE from 'three';
import LineBasicMaterial = THREE.LineBasicMaterial;

@Component({
    selector: 'viewport-control',
    styles: [require('./viewportControl.component.scss')],
    template: require('./viewportControl.component.html')
})
export default class ViewportControlComponent implements OnDestroy {
    @ViewChild('viewcube', {static: true}) el:ElementRef;
    private EPS:number = .01   // this needs to be high to prevent cesium camera weirdness
    cameraTypeEnum = CameraType
    private width:number = 100
    private height:number = 100
    private frustumSize:number = 140
    private renderer:THREE.WebGLRenderer
    private camera:THREE.Camera
    private scene:THREE.Scene
    private light:THREE.DirectionalLight
    private raycaster:THREE.Raycaster
    private mouse = new THREE.Vector2(0, 0)
    private isMouseOver:boolean = false
    private intersectCandidates:THREE.Mesh[] = []
    private intersect:THREE.Intersection
    private lockedObject:THREE.Mesh

    private dragStart:THREE.Vector2

    private isSingleClick:boolean = true
    private baseMaterial:THREE.MeshBasicMaterial = new THREE.MeshLambertMaterial( { transparent: true, color: 0xcccccc, depthTest: true, side:THREE.DoubleSide } )
    private lockMaterial:THREE.MeshBasicMaterial = new THREE.MeshBasicMaterial({transparent: true, opacity: .5, color: 0xff0000, depthTest: true, side:THREE.DoubleSide })
    private highlightMaterial:THREE.MeshBasicMaterial = new THREE.MeshBasicMaterial({transparent: true, opacity: .5, color: 0xffff00, depthTest: true, side:THREE.DoubleSide })
    private highlightSolidMaterial:THREE.MeshBasicMaterial = new THREE.MeshBasicMaterial({transparent: true, opacity: 1, color: 0xffff00, depthTest: true, side:THREE.DoubleSide })
    private hiddenMaterial:THREE.MeshBasicMaterial = new THREE.MeshBasicMaterial({transparent: true, opacity: 0, depthTest: false })

    private app:WireframeApplication
    constructor(private zone:NgZone, private sceneService:SceneService) {
        this.app = sceneService.wireframeApplication
    }

    updateMousePos(e) {
        this.mouse.x = ( e.offsetX / this.el.nativeElement.offsetWidth ) * 2 - 1;
        this.mouse.y = - ( e.offsetY / this.el.nativeElement.offsetHeight ) * 2 + 1;
    }

    onMouseMove(e) {
        //e.preventDefault();
        let that = this
        this.updateMousePos(e)
        if (null != this.dragStart) {
            let dragDelta = this.mouse.clone().sub(this.dragStart)
        }
        if (this.isMouseOver) {
            this.raycaster.setFromCamera(this.mouse, this.camera)
            let intersects = this.raycaster.intersectObjects(this.intersectCandidates, true)
            //this.hideHitGeometry()
            this.intersectCandidates.forEach((o) => { o.userData.isHighlighted = false })
            if (intersects.length > 0) {
                this.intersect = intersects[0]
                this.intersect.object.userData.isHighlighted = true
            }
        }
        this.intersectCandidates.forEach((o) => { that.updateMaterials(o)})
    }

    private updateMaterials(mesh:THREE.Mesh) {
        if (mesh.userData.isHighlighted) {
            mesh.material = mesh.userData.highlightMaterial ? mesh.userData.highlightMaterial : this.highlightMaterial
        } else if (mesh.userData.isLocked) {
            mesh.material = mesh.userData.lockMaterial ? mesh.userData.lockMaterial : this.lockMaterial
        } else {
            mesh.material = mesh.userData.baseMaterial ? mesh.userData.baseMaterial : this.baseMaterial
        }
    }

    onMouseOver(e) {
        this.isMouseOver = true
    }

    onMouseOut(e) {
        this.isMouseOver = false
        let that = this
        this.intersectCandidates.forEach((o) => { o.userData.isHighlighted = false; that.updateMaterials(o)})
    }

    onMouseClick(e) {
        this.isSingleClick = true
        this.zone.runOutsideAngular(()=>{
            window.setTimeout(() => {
                if (this.isSingleClick) {
                    this.handleClick(e)
                }
            }, 250)
        })
    }

    onMouseDoubleClick(e) {
        this.isSingleClick = false
        this.handleClick(e)
    }

    handleClick(e) {
        let that = this
        this.updateMousePos(e)
        this.intersectCandidates.forEach((o) => { o.userData.isLocked = false })

        this.app.viewer.cameraDirectionLock = null
        if (null != this.intersect) {

            this.alignCameraToObject(this.intersect.object as THREE.Mesh)
            if (e.type == "dblclick") {
                this.lockCameraDirection(this.intersect.object as THREE.Mesh)
            }
        }
        this.intersectCandidates.forEach((o) => { that.updateMaterials(o)})
    }

    getCameraDirectionForObject(obj:THREE.Mesh):THREE.Vector3 {
        let cameraDirection:THREE.Vector3;
        cameraDirection = obj.userData.camVector.clone()
        //let inv = new THREE.Matrix4()
        //inv.getInverse(app.gravityAlignedGeocentricTransform)
        //cameraDirection.applyMatrix4(inv)
        return cameraDirection
    }

    alignCameraToObject(obj:THREE.Mesh) {
        let cameraDirection = this.getCameraDirectionForObject(obj)
        let cameraPos = this.app.viewer.getCameraPositionTarget()
        let cameraTarget = this.app.viewer.getCameraLookatTarget()
        let distance = cameraTarget.distanceTo(cameraPos)
        cameraDirection.setLength(distance)
        let newCameraPos = cameraTarget.clone().add(cameraDirection.negate())
        this.app.viewer.setCameraTargets(this.app.viewer.getCameraLookatTarget(), newCameraPos)
    }

    onMouseDown(e, mouseDown) {
        this.updateMousePos(e)
        if (mouseDown) {
            this.dragStart = new THREE.Vector2().copy(this.mouse)
        } else {
            this.dragStart = null
        }
    }

    ngOnInit(): void {
        this.initViewCube()
    }

    initViewCube() {
        this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true })
        this.renderer.setClearColor(0x000000, 0.2)
        let container = this.el.nativeElement
        let aspectRatio = this.width / this.height
        this.renderer.setSize(this.width, this.height)

        container.appendChild(this.renderer.domElement)
        this.camera = new THREE.OrthographicCamera(this.frustumSize * aspectRatio / -2, this.frustumSize * aspectRatio / 2, this.frustumSize / 2, this.frustumSize / - 2, .1, 2000 );
        this.camera.position.set(0, 0, 120)
        this.camera.lookAt(new THREE.Vector3(0, 0, -1))
        this.scene = new THREE.Scene()
        let cubeSize = 50
        let gridHelper = new THREE.GridHelper( cubeSize * 1, cubeSize / 2 );
        let gridMat = gridHelper.material as THREE.LineBasicMaterial
        gridMat.transparent = true
        gridMat.opacity = .7
        this.scene.add( gridHelper );
        // Cubes

        let sides = {
            top: { rot: new THREE.Euler(-Math.PI / 2, 0, 0), pos: new THREE.Vector3(0, cubeSize / 2, 0), camVector: new THREE.Vector3(0, -1, this.EPS) },
            bottom: { rot: new THREE.Euler(Math.PI / 2, 0, 0), pos: new THREE.Vector3(0, -cubeSize / 2, 0), camVector: new THREE.Vector3(0, 1, this.EPS) },
            left: { rot: new THREE.Euler(0, -Math.PI / 2, 0), pos: new THREE.Vector3(cubeSize / 2, 0, 0), camVector: new THREE.Vector3(-1, 0, 0) },
            right: { rot: new THREE.Euler(0, Math.PI / 2, 0), pos: new THREE.Vector3(-cubeSize / 2, 0, 0), camVector: new THREE.Vector3(1, 0, ) },
            front: { rot: new THREE.Euler(0, 0, -Math.PI / 2), pos: new THREE.Vector3(0, 0, cubeSize / 2), camVector: new THREE.Vector3(0, 0, -1) },
            back: { rot: new THREE.Euler(0, 0, Math.PI / 2), pos: new THREE.Vector3(0, 0, -cubeSize / 2), camVector: new THREE.Vector3(0, 0, 1) },
        }
        for (let sideName in sides) {
            let side = sides[sideName]
            let geom = new THREE.PlaneBufferGeometry(cubeSize, cubeSize, 1, 1)
            let sideMesh = new THREE.Mesh(geom, this.baseMaterial)
            sideMesh.rotation.copy(side.rot)
            sideMesh.position.copy(side.pos)
            sideMesh.userData.isHitObject = true
            sideMesh.userData.highlightMaterial = this.highlightSolidMaterial
            sideMesh.userData.camVector = side.camVector
            sideMesh.userData.name = sideName
            this.scene.add(sideMesh)
            this.intersectCandidates.push(sideMesh)
        }
        if (false) {
            let geometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize);

            let cube = new THREE.Mesh(geometry, this.baseMaterial);
            this.intersectCandidates.push(cube)
            //let edges = new THREE.EdgesHelper(cube, 0x444444)
            //let edgeMat = edges.material as LineBasicMaterial
            //edgeMat.linewidth = 5
            //this.scene.add(edges)
            cube.position.x = 0;
            cube.position.y = 0;
            cube.position.z = 0;
            this.scene.add(cube);
        }

        let innerRadius = Math.sqrt(2 * Math.pow(cubeSize / 2, 2)) * 1.1
        let outerRadius = innerRadius + 8
        let ringGeom = new THREE.RingBufferGeometry(innerRadius, outerRadius, 24, 1)
        let ringMesh = new THREE.Mesh(ringGeom, this.baseMaterial)
        ringMesh.rotateX(-Math.PI / 2)
        this.scene.add(ringMesh)

        let numSegments = 8
        let segmentSize = Math.PI * 2 / numSegments
        let names = [ "north", "northeast", "east", "southeast", "south", "southwest", "west", "northwest" ]

        for (let i = 0; i < numSegments; i++) {
            let thetaStart = (-segmentSize / 2) + segmentSize * i
            let ringHitGeom  = new THREE.CylinderBufferGeometry(outerRadius * 1.5, outerRadius * 1.5, 2, 24, 1, false, thetaStart, segmentSize)
            let ringHitMesh = new THREE.Mesh(ringHitGeom, this.hiddenMaterial)
            let angle = (Math.PI * 2) - ((i / numSegments) * Math.PI * 2) + (Math.PI / 2)
            let offsetVec = new THREE.Vector3(Math.cos(angle), 0, Math.sin(angle))
            offsetVec.setLength(this.EPS)
            ringHitMesh.userData.name = names[i]
            ringHitMesh.userData.camVector = new THREE.Vector3(0, -1, 0).add(offsetVec)
            ringHitMesh.userData.isHitObject = true
            ringHitMesh.userData.baseMaterial = this.hiddenMaterial
            this.scene.add(ringHitMesh)
            this.intersectCandidates.push(ringHitMesh)
        }

        /*
        let loader = new THREE.FontLoader();
        loader.load('fonts/helvetiker_regular.typeface.json', function(font) {
            let fontSize = 15
            let fontHeight = .5
            let northText = new THREE.TextGeometry("N", {
                size: fontSize,
                height: fontHeight,
                curveSegments: 1
            })
            let textMaterial = new THREE.MeshLambertMaterial( { color: 0xff0000, side:THREE.DoubleSide } )
            let northGeom = new THREE.Mesh(northText, textMaterial)
            northGeom.rotateX(-Math.PI / 2)
            northGeom.position.set(-fontSize / 2, -fontHeight / 2, outerRadius + (1.2 * fontSize))
            this.scene.add(northGeom)
        })
        */
        let northGeom = new THREE.CylinderBufferGeometry(cubeSize / 4, cubeSize / 4, cubeSize / 8, 3, 1, false)
        let northMesh = new THREE.Mesh(northGeom, new THREE.MeshLambertMaterial( { color: 0xff0000, side:THREE.DoubleSide } ))
        northMesh.position.set(0, 0, outerRadius)
        northMesh.rotateY(Math.PI)
        this.scene.add(northMesh)

        let ambientLight = new THREE.AmbientLight(0x222222)
        this.scene.add(ambientLight)
        this.light = new THREE.DirectionalLight(0xffffff)
        this.scene.add(this.light)

        this.raycaster = new THREE.Raycaster()

        this.camera.updateMatrix()
        this.animate()
    }

    animate() {
        let that = this
        this.zone.runOutsideAngular(()=> {
            window.requestAnimationFrame(() => this.animate());
            this.render()
        });
    }

    render() {
        //console.log("camera ", viewer.profileTool.camera)
        if (!this.app.viewer) return
        let viewportCamera:THREE.Camera = this.app.viewer.camera
        let camDirection = viewportCamera.getWorldDirection(new THREE.Vector3())
        //camDirection.applyMatrix4(app.gravityAlignedGeocentricTransform)
        let camPos = camDirection.clone().negate().setLength(100)
        this.camera.position.copy(camPos)
        this.camera.lookAt(camDirection)
        this.light.position.copy(camPos)
        this.light.lookAt(camDirection)
        this.renderer.render(this.scene, this.camera)
    }

    setCameraType(cameraType:CameraType) {
        this.app.viewer.setCameraType(cameraType)
    }

    getCameraType() {
        return this.sceneService.potreeCamera instanceof THREE.PerspectiveCamera ? CameraType.PERSPECTIVE : CameraType.ORTHO
    }

    lockCameraDirection(object:THREE.Mesh) {
        this.intersectCandidates.forEach((o) => { o.userData.isLocked = false })
        this.lockedObject = null
        this.app.viewer.cameraDirectionLock = null

        if (null != object) {
            this.lockedObject = object
            this.lockedObject.userData.isLocked = true
            this.app.viewer.cameraDirectionLock = this.getCameraDirectionForObject(object)
            this.alignCameraToObject(object)
        }
        this.intersectCandidates.forEach((o) => { this.updateMaterials(o) })
    }

    setViewPreset(preset) {
        if (preset == "2D") {
            this.setCameraType(CameraType.ORTHO)
            let northObject = this.intersectCandidates.find((o) => { return o.userData.name == "north" })
            this.lockCameraDirection(northObject)
        } else {
            this.setCameraType(CameraType.PERSPECTIVE)
            this.lockCameraDirection(null)
        }
    }

    frameCamera(event) {
        let cameraDirection = this.app.viewer.getCameraLookatTarget().sub(this.app.viewer.getCameraPositionTarget())
        this.sceneService.wireframeApplication.initViewport(cameraDirection.negate())
    }

    ngOnDestroy(): void {
    }
}