import {Property} from "./property";
import {PV} from "../../wireframe";
import Component = PV.Component;
import {WireframeElement} from "../../application/wireframeElement";

export class NumberProperty extends Property {
    min: number;
    max: number;
    step: number = .1;
    isInteger: Boolean = false;
    unitClass: string
    precision: number

    constructor() {
        super();
        this.type = ".NumberProperty";
        this.name = "NumberProperty";
        this.defaultValue = 0;
    }


    setValue(el:WireframeElement, v:any) {
        super.setValue(el, v === null ? null : Number(v))
    }

    getValue(c:Component) {
        let val = super.getValue(c)
        return val === null ? null : Number(val)
    }

    clamp(val: number): number {
        if (this.isInteger) val = Math.round(val);
        if (null != this.min) val = Math.max(this.min, val);
        if (null != this.max) val = Math.min(this.max, val);
        return val;
    }

    fromJson(obj, allProperties): void {
        super.fromJson(obj, allProperties);
        this.min = obj.min;
        this.max = obj.max;
        this.step = obj.step;
    }
}