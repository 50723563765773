import MinPack from "./minPack";
import * as numeric from "numeric"

export default class MWPR {
    // The pseudoinverse function computes the pseudoinverse of A, which solves least-squares problems.
    static pinv(A) {
        return numeric.dot(numeric.inv(numeric.dot(numeric.transpose(A), A)), numeric.transpose(A));
    }

// Another implementation of the pseudoinverse, using the SVD. May be more robust in certain cases,
// especially if one handles the near-0 entries of svd.S in a special way.
    static pinv2(A) {
        var svd = numeric.svd(A);
        return numeric.dot(numeric.dot(svd.V, numeric.diag(numeric.div(1, svd.S))), numeric.transpose(svd.U));
    }

//Function to normalize a 2D point in a camera view (this converts from pixel coordinates to point in metric camera coordinate system)
    static Normalize_Point_2D(point2DPixel, cameraView)
    {
        var point2DtNorm = [null, null];
        point2DtNorm[0] = (point2DPixel[0] / cameraView.fx) - ((point2DPixel[1] * cameraView.skew) / (cameraView.fx * cameraView.fy)) +
            ((cameraView.skew * cameraView.cy) / (cameraView.fx * cameraView.fy)) - (cameraView.cx / cameraView.fx);
        point2DtNorm[1] = (point2DPixel[1] / cameraView.fy) - (cameraView.cy / cameraView.fy);
        return point2DtNorm;
    }

// Function to denormalize a 2D point in a camera view (this converts from metric camera coordinate system to pixel coordinates)
    static Denormalize_Point_2D(point2DNorm, cameraView)
    {
        var point2DPixel = [null, null];
        point2DPixel[0] = cameraView.fx * point2DNorm[0] + cameraView.skew * point2DNorm[1] + cameraView.cx;
        point2DPixel[1] = cameraView.fy * point2DNorm[1] + cameraView.cy;
        return point2DPixel;
    }

//Undistortion function
    static Undistort_Normalized_Point_2D(point2DNorm, distortion, projectiontype) {
        var needUndistortion = false;
        for (var i = 0; i < distortion.length; i++) {
            if (distortion[i] != 0) {
                needUndistortion = true;
                break;
            }
        }
        if (needUndistortion == false)
            return point2DNorm;
        if (projectiontype == "PERSPECTIVE") {
            var x0 = point2DNorm[0];
            var y0 = point2DNorm[1];
            var x = x0;
            var y = y0;
            for (var iter = 0; iter < 10; iter++) {
                var r2 = x * x + y * y;
                var icdist = 1.0 / (1 + distortion[0] * r2 + distortion[1] * r2 * r2);
                x = x0 * icdist;
                y = y0 * icdist;
            }
            return [x, y];
        } else if (projectiontype == "PERSPECTIVE_5") {
            var x0 = point2DNorm[0];
            var y0 = point2DNorm[1];
            var x = x0;
            var y = y0;
            for (var iter = 0; iter < 10; iter++) {
                var r2 = x * x + y * y;
                var icdist = 1 / (1 + ((distortion[2] * r2 + distortion[1]) * r2 + distortion[0]) * r2);
                var deltaX = 2 * distortion[3] * x * y + distortion[4] * (r2 + 2 * x * x);
                var deltaY = distortion[3] * (r2 + 2 * y * y) + 2 * distortion[4] * x * y;
                x = (x0 - deltaX) * icdist;
                y = (y0 - deltaY) * icdist;
            }
            return [x, y];
        } else if (projectiontype == "PERSPECTIVE_8") {
            var x0 = point2DNorm[0];
            var y0 = point2DNorm[1];
            var x = x0;
            var y = y0;
            for (var iter = 0; iter < 10; iter++) {
                var r2 = x * x + y * y;
                var icdist = 1 / (1 + (((distortion[3] * r2 + distortion[2]) * r2 + distortion[1]) * r2 + distortion[0]) * r2);
                var deltaX = (2 * distortion[5] * x * y + distortion[4] * (r2 + 2 * x * x)) * (1 + distortion[6] * r2 + distortion[7] * r2 * r2);
                var deltaY = (distortion[5] * (r2 + 2 * y * y) + 2 * distortion[4] * x * y) * (1 + distortion[6] * r2 + distortion[7] * r2 * r2);
                x = (x0 - deltaX) * icdist;
                y = (y0 - deltaY) * icdist;
            }
            return [x, y]
        } else if (projectiontype == "BROWN_CAMERA") {
            var x0 = point2DNorm[0];
            var y0 = point2DNorm[1];
            var x = x0;
            var y = y0;
            for (var iter = 0; iter < 10; iter++) {
                var r2 = x * x + y * y;
                var icdist = 1 / (1.0 + r2 * (distortion[0] + r2 * (distortion[1] + r2 * (distortion[2] + r2 * distortion[3]))));
                var deltaX = distortion[4] * x * y * 2.0 + distortion[5] * (r2 + x * x * 2.0);
                var deltaY = distortion[4] * (r2 + y * y * 2.0) + distortion[5] * x * y * 2.0;
                x = (x0 - deltaX) * icdist;
                y = (y0 - deltaY) * icdist;
            }
            return [x, y]
        } else if (projectiontype == "FISHEYE") {
            var thetad = Math.sqrt(point2DNorm[0] * point2DNorm[0] + point2DNorm[1] * point2DNorm[1]);
            var theta = thetad;  // initial guess
            for (var i = 20; i > 0; i--) {
                var theta2 = theta * theta;
                var theta4 = theta2 * theta2;
                var theta6 = theta4 * theta2;
                var theta8 = theta4 * theta4;
                theta = thetad / (1 + distortion[0] * theta2 + distortion[1] * theta4 + distortion[2] * theta6 + distortion[3] * theta8);
            }
            var scaling = Math.tan(theta) / thetad;
            return [scaling * point2DNorm[0], scaling * point2DNorm[1]];
        }
    }
//triangulation (ptsNorm constains normalized 2D points)
    static Triangulate_Points(num_pts, ptsNorm, Rs, ts) {
        var num_eqs = 2 * num_pts;
        var num_vars = 3;
        var A = [];
        var b = [];
        for (var i = 0; i < num_pts; i++) {
            A.push([Rs[i][0][0] - ptsNorm[i][0] * Rs[i][2][0], Rs[i][0][1] - ptsNorm[i][0] * Rs[i][2][1], Rs[i][0][2] - ptsNorm[i][0] * Rs[i][2][2]]);
            A.push([Rs[i][1][0] - ptsNorm[i][1] * Rs[i][2][0], Rs[i][1][1] - ptsNorm[i][1] * Rs[i][2][1], Rs[i][1][2] - ptsNorm[i][1] * Rs[i][2][2]]);
            b.push(ts[i][2] * ptsNorm[i][0] - ts[i][0]);
            b.push(ts[i][2] * ptsNorm[i][1] - ts[i][1]);
        }
        var x = numeric.dot(MWPR.pinv2(A), b);

        x = MinPack.lmdif(x, num_pts, ptsNorm, Rs, ts);
        return x;
    }

    static Clamp(x, mn, mx) {
        return ((x < mn) ? mn : ((x > mx) ? mx : x));
    }

//Check Point Set Degeneracy Triangulation Angle (points contains original 2D point pixel coordinates in images)
    static Check_Point_Set_Degeneracy_Out_Angle(points, camera_views, thresholdDegeneracyAnglePoint, angle_) {
        var PI = 3.141592653589793238;
        var RT_vectors = {}//std::map<int, std::vector<double>> RT_vectors;
        var n = points.length;
        var norm;
        var success;
        var stop = false;
        var degenerate = true;
        angle_.angle = 0.0;
        var maxAngle = -1;
        for (var i = 0; i < n - 1; i++) {
            if (stop)
                break;
            var RT_pv = [];
            if (i in RT_vectors) {
                RT_pv = RT_vectors[i];
            }
            if (!(i in RT_vectors)) {
                var p_norm = MWPR.Normalize_Point_2D(points[i], camera_views[i]);
                p_norm = MWPR.Undistort_Normalized_Point_2D(p_norm, camera_views[i].distortion, camera_views[i].projectionType);
                //http://visio.googlecode.com/svn-history/r52/trunk/CV/PointCloud.cpp
                //Method: ComputeRayAngle
                // we want to compute the angle between the rays which are (X_p - c_A) and (X_q - c_B)
                // we can calculate it from the formula R(X_p - c_A) = p => X_p - c_A = Rt*p
                var R = camera_views[i].rotation;
                RT_pv = [R[0][0] * p_norm[0] + R[1][0] * p_norm[1] + R[2][0],
                    R[0][1] * p_norm[0] + R[1][1] * p_norm[1] + R[2][1],
                    R[0][2] * p_norm[0] + R[1][2] * p_norm[1] + R[2][2]];
                norm = Math.sqrt(RT_pv[0] * RT_pv[0] + RT_pv[1] * RT_pv[1] + RT_pv[2] * RT_pv[2]);
                RT_pv[0] /= norm;
                RT_pv[1] /= norm;
                RT_pv[2] /= norm;
                RT_vectors[i] = RT_pv;
            }
            for (var j = i + 1; j < n; j++) {
                var RT_qv = [];
                if (j in RT_vectors) {
                    RT_qv = RT_vectors[j];
                }
                if (!(j in RT_vectors)) {
                    var q_norm = MWPR.Normalize_Point_2D(points[j], camera_views[j]);
                    q_norm = MWPR.Undistort_Normalized_Point_2D(q_norm, camera_views[j].distortion, camera_views[j].projectionType);
                    var R = camera_views[j].rotation;
                    RT_qv = [R[0][0] * q_norm[0] + R[1][0] * q_norm[1] + R[2][0],
                        R[0][1] * q_norm[0] + R[1][1] * q_norm[1] + R[2][1],
                        R[0][2] * q_norm[0] + R[1][2] * q_norm[1] + R[2][2]];
                    norm = Math.sqrt(RT_qv[0] * RT_qv[0] + RT_qv[1] * RT_qv[1] + RT_qv[2] * RT_qv[2]);
                    RT_qv[0] /= norm;
                    RT_qv[1] /= norm;
                    RT_qv[2] /= norm;
                    RT_vectors[j] = RT_qv;
                }
                var dot = RT_pv[0] * RT_qv[0] + RT_pv[1] * RT_qv[1] + RT_pv[2] * RT_qv[2];
                var rad2deg = 180 / PI;
                var angle = Math.acos(MWPR.Clamp(dot, -1.0 + 1.0e-8, 1.0 - 1.0e-8)) * rad2deg; //Acos is between 0 and PI
                if (angle > 90)
                    angle = 180 - angle;
                angle_.angle = angle;
                if (angle > maxAngle) {
                    maxAngle = angle;
                    angle_.h1 = i;
                    angle_.h2 = j;
                }
                if (angle >= thresholdDegeneracyAnglePoint) {
                    degenerate = false;
                    //stop = true;
                    //break;
                }
            }
        }
        return degenerate;
    }
//Multi View Point Reconstruction Out Angle (match_points contains original 2D point pixel coordinates in images)
    static Multi_View_Point_Reconstruction_Out_Angle(match_points, camera_views, check_degeneracy, angle_) {
        angle_.angle = 0.0;
        var thresholdDegeneracyAnglePoint = 2;
        if (check_degeneracy == true) {
            var degenerate = MWPR.Check_Point_Set_Degeneracy_Out_Angle(match_points, camera_views, thresholdDegeneracyAnglePoint, angle_);
            // if (degenerate == true)
            //     return [NaN, NaN, NaN];
        }
        var points = new Array(camera_views.length);
        for (var i = 0; i < camera_views.length; i++) {
            points[i] = new Array(2);
        }
        for (var i = 0; i < camera_views.length; i++) {
            points[i] = MWPR.Normalize_Point_2D(match_points[i], camera_views[i]);
            points[i] = MWPR.Undistort_Normalized_Point_2D(points[i], camera_views[i].distortion, camera_views[i].projectionType);
        }
        var rotations = new Array(camera_views.length);//std::vector<std::vector<std::vector<double>>>;
        var translations = new Array(camera_views.length);
        for (var i = 0; i < camera_views.length; i++) {
            rotations[i] = camera_views[i].rotation;
            translations[i] = camera_views[i].translation;
        }
        var X = MWPR.Triangulate_Points(camera_views.length, points, rotations, translations);
        return X;
    }
}