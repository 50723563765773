import {InteractiveModel} from "../interactive/interactive.model";
import {ProjectImageListFilterType} from "./projectImageCollectionFilterType.enum"
import {ProjectImageModel} from "../projectImages/projectImage.model";
import {EventEmitter} from "@angular/core";

export interface ProjectImageListFilter {
    filterType: ProjectImageListFilterType;
    filterFunction: (ProjectImageModel) => boolean;
}

export class ProjectImageCollectionFilterModel extends InteractiveModel implements ProjectImageListFilter {
    filterType: ProjectImageListFilterType;
    filterFunction: (ProjectImageModel) => boolean;
    filterChanged = new EventEmitter<ProjectImageCollectionFilterModel>();

    constructor(filterType: ProjectImageListFilterType) {
        super();
        this.filterType = filterType;
    }

    filter(projectImageModel: ProjectImageModel) {
        if (!this.filterFunction) return false;
        return this.filterFunction(projectImageModel);
    }

    get filterName(): string {
        return this.filterType;
    }

    triggerFilterChanged(){
        this.filterChanged.emit(this);
    }
}