import {CameraControlMode} from "./tool";
import {WireframeElementType} from "./wireframeElementType";
import {PV} from "../wireframe";
import {WireframeApplication} from "./wireframeApplication";
import {ContinuePolygon} from "./continuePolygon";
import {PolygonShapeProperty} from "../models/property/polygonShapeProperty";
import {PolygonShapePropertyValue} from "../models/property/polygonShapePropertyValue";
import ShapeTool from "./shapeTool";
import * as THREE from 'three';
import {PlaneElement} from "./planeElement";
import {EdgeElement} from "./edgeElement";


export default class RectangleTool extends ShapeTool {

    toolShape:ContinuePolygon

    constructor(wfApp:WireframeApplication) {
        super(wfApp)
        this.toolName = "RectangleTool"
        //this.cameraControlMode = CameraControlMode.MIDDLE
    }


    onMouseDown(event:MouseEvent) {
        if (!super.onMouseDown(event)) return false
    }

    updateActivePolygon() {
        if (!this.toolShape) return;

        let cp: ContinuePolygon = this.toolShape;


        let newPos = this.getMousePointOnPlane(this.mouse, cp.bestFitPlane);
        let midPoint: THREE.Vector3 = newPos.clone();
        midPoint.add(cp.startVert);
        midPoint.divideScalar(2);

        let planeRot:THREE.Quaternion = new THREE.Quaternion()
        planeRot = planeRot.setFromUnitVectors(new THREE.Vector3(0, 0, 1), this.toolShape.bestFitPlane.normal)

        let radius = .1
        for (let i = 0; i < cp.plane.vertexIds.length; i++) {
            let vertId = cp.plane.vertexIds[i]
            let pos = new THREE.Vector3(radius * Math.cos(i * Math.PI * 2 / cp.plane.vertexIds.length), radius * Math.sin(i * Math.PI * 2 / cp.plane.vertexIds.length), 0)
            pos.applyQuaternion(planeRot)
            pos.add(midPoint)
            this.app.wireframe.setVert3(vertId, this.app.wireframeLayer.toWireframeCRS(pos))
        }

        let edgeLine = new THREE.Line3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 1))

        try {
            let alignEdgeId = cp.propVal.alignEdge.getElements(this.app.wireframeLayer)[0].id
            let alignEdge = this.app.findWireframeElement(WireframeElementType.edge, alignEdgeId) as EdgeElement;
            edgeLine = alignEdge.getLine3(true)
        } catch (e) {}

        let parallel: THREE.Line3 = new THREE.Line3(cp.startVert, cp.startVert.clone().add(edgeLine.delta(new THREE.Vector3())));

        let edgeLineNewPos = edgeLine.closestPointToPoint(newPos, false, new THREE.Vector3());
        let edgeLineStartPos = edgeLine.closestPointToPoint(cp.startVert, false, new THREE.Vector3());
        let yVec = new THREE.Vector3().subVectors(edgeLineNewPos, edgeLineStartPos);

        let parallelNewPos = parallel.closestPointToPoint(newPos, false, new THREE.Vector3());
        let xScale = parallelNewPos.distanceTo(newPos);
        cp.propVal.yScale = yVec.length() / 2;
        cp.propVal.xScale = xScale / 2;
        this.app.wireframeLayer.redrawWireframe();
    }

    onMouseMove(event:MouseEvent) {
        if (this.toolShape) {
            this.updateActivePolygon()
        } else {
            this.updateHighlightedElements()
        }
    }

    onMouseUp(event:MouseEvent) {

        let isDrag = this.getMouseDrag().length() > this.mouseDragThreshold

        this.updateHighlightedElements()

        if (this.toolShape && !isDrag) {
            if (event.button == 2) {
                this.cancelActiveShape();
                return
            }
            this.toolShape = null;
            return
        }

        if (event.button == 0 && !isDrag) {

            let drawingPlane = this.getDrawingPlane()

            // only allow this when drawn on existing planes
            //if (!pointPlane[2]) return

            this.toolShape = new ContinuePolygon();
            let cp: ContinuePolygon = this.toolShape;

            cp.plane = new PV.Plane();

            cp.startVert = drawingPlane.point;
            cp.bestFitPlane = drawingPlane.plane

            for (let i = 0; i < 4; i++) {
                let vert = new PV.Vertex();
                this.app.wireframe.addVertex(vert);
                this.app.wireframe.setVert3(vert.id, this.app.wireframeLayer.toWireframeCRS(this.toolShape.startVert));
                this.app.wireframeLayer.addVertex(vert);
                cp.plane.vertexIds.push(vert.id);
                //console.log("added vertex", vert);
            }
            //this.app.wireframe.addPlane(cp.plane);
            this.app.wireframeLayer.createPlaneEdgesFromVerts(cp.plane);
            let planeElement = this.app.wireframeLayer.addPlane(cp.plane);
            this.app.selectWireframeElement(null);
            this.app.selectWireframeElement([planeElement], true);
            
            this.app.wireframeLayer.alignWithGravity(cp.plane);
            cp.plane.bestFitPlane.a = cp.bestFitPlane.normal.x
            cp.plane.bestFitPlane.b = cp.bestFitPlane.normal.y
            cp.plane.bestFitPlane.c = cp.bestFitPlane.normal.z
            cp.plane.bestFitPlane.d = cp.bestFitPlane.constant


            let prop = this.app.wireframe.findProperty(PolygonShapeProperty, true);
            let val: PolygonShapePropertyValue = prop.getDefaultValue();
            val.NumSides = 4;
            if (drawingPlane.element) {
                val.alignEdge.setElement(this.app.wireframe.edges[this.app.wireframe.getPrimaryEdgeId(drawingPlane.element.pvObject)])
            }            //val.alignEdge.elements[WireframeElementType.edge] = [this.app.wireframe.getPrimaryEdgeId(cp.plane)];

            val.xScale = 0;
            val.yScale = 0;
            prop.setValue(planeElement, val);
            (cp as any).prop = prop;
            cp.propVal = val;
            this.updateActivePolygon();
        }
    }

}