import { Directive, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {Logo, LogoService} from "../../services/logos/logo.service";

@Directive({
    selector: '[ptvLogo]'
})
export class LogoDirective implements OnInit, OnDestroy {

    private isDestroyed = new Subject();
    private currentLogo:Logo = null;

    constructor(
        private _elementRef: ElementRef,
        private _logoService: LogoService
    ) {}

    ngOnInit() {
        const activeLogo = this._logoService.getLogo();
        if (activeLogo) {
            this.updateLogo(activeLogo);
        }

        this._logoService.logoChange
            .pipe(takeUntil(this.isDestroyed))
            .subscribe((logo: Logo) => this.updateLogo(logo));
    }

    ngOnDestroy() {
        this.isDestroyed.next();
        this.isDestroyed.complete();
    }

    updateLogo(logo: Logo) {
        // project properties onto the element
        for (const key in logo.properties) {
            this._elementRef.nativeElement.style.setProperty(key, logo.properties[key]);
        }

        // background image
        // this._elementRef.nativeElement.style.setProperty('background-image', 'url(' + logo.path + ')');
        this._elementRef.nativeElement.setAttribute("src", logo.path);

        // remove old logo
        if (this.currentLogo) {
            let name = this.currentLogo.name;
            this._elementRef.nativeElement.classList.remove(`${name}-logo`);
        }

        // alias element with logo name
        this._elementRef.nativeElement.classList.add(`${logo.name}-logo`);
    }

}
