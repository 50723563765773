import {ElementReference} from "./elementReference";

export class PolygonShapePropertyValue {
    NumSides: number = 4;
    xScale: number = 1;
    yScale: number = 1;
    Rotation: number = 0;
    alignEdge: ElementReference = new ElementReference();

    public clone():PolygonShapePropertyValue{
        let polygonShapePropertyValue = new PolygonShapePropertyValue();
        polygonShapePropertyValue.NumSides = this.NumSides;
        polygonShapePropertyValue.xScale = this.xScale;
        polygonShapePropertyValue.yScale = this.yScale;
        polygonShapePropertyValue.Rotation = this.Rotation;
        polygonShapePropertyValue.alignEdge = new ElementReference();
        polygonShapePropertyValue.alignEdge.elements = JSON.parse(JSON.stringify(this.alignEdge.elements));
        return polygonShapePropertyValue;
    }
}