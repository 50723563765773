import {PV} from "./wireframe";
import Plane = PV.Plane;
import Component = PV.Component;
import {LayerProperty} from "./models/property/layerProperty";
import * as THREE from 'three';
import Face3 = THREE.Face3;
import {WireframeElement} from "./application/wireframeElement";
import WireframeLayer from "./application/wireframeLayer";
import {WireframeApplication} from "./application/wireframeApplication";

export default class TexturedQuad {
    public layer:WireframeLayer
    public plane:Plane;
    protected components:Component[] = [];
    protected elements:WireframeElement[] = [];
    protected planeElement:WireframeElement;
    protected material:THREE.MeshBasicMaterial;
    protected layerName:string

    constructor(protected app:WireframeApplication) {}

    public createGeometry() {
        this.components = [];
        this.plane = new PV.Plane();
        for (let i = 0; i < 4; i++) {
            let vert = new PV.Vertex();
            this.layer.wireframe.addVertex(vert);
            this.plane.vertexIds.push(vert.id);
            this.elements.push(this.layer.addVertex(vert));
            this.components.push(vert);
        }
        for (let i = 0; i < 4; i++) {
            let edge = new PV.Edge(this.plane.vertexIds[i], this.plane.vertexIds[(i + 1) % this.plane.vertexIds.length]);
            this.layer.wireframe.addEdge(edge);
            this.plane.edgeIds.push(edge.id);
            this.elements.push(this.layer.addEdge(edge));
            this.components.push(edge);
        }
        this.layer.wireframe.vertices[this.plane.vertexIds[0]].setPos(0, 0, 0);
        this.layer.wireframe.vertices[this.plane.vertexIds[1]].setPos(10, 0, 0);
        this.layer.wireframe.vertices[this.plane.vertexIds[2]].setPos(10, 10, 0);
        this.layer.wireframe.vertices[this.plane.vertexIds[3]].setPos(0, 10, 0);

        this.layer.wireframe.addPlane(this.plane);

        this.planeElement = this.layer.addPlane(this.plane, null, { baseMaterial: this.material, material: this.material.clone() });
        this.planeElement.baseMaterial.color.setRGB(1, 1, 1)
        this.planeElement.baseMaterial.opacity = 1
        this.planeElement.updateMaterials()
        this.elements.push(this.planeElement);

        let uvs = [
            new THREE.Vector2(1, 1),
            new THREE.Vector2(0,1),
            new THREE.Vector2(0, 0),
            new THREE.Vector2(1, 0),

        ];
        let geom:THREE.Geometry = (this.planeElement.children[0] as THREE.Mesh).geometry as THREE.Geometry;
        for (let i = 0; i < geom.faces.length; i++) {
            let f:Face3 = geom.faces[i];
            geom.faceVertexUvs[0][i][0].copy(uvs[f.a]);
            geom.faceVertexUvs[0][i][1].copy(uvs[f.b]);
            geom.faceVertexUvs[0][i][2].copy(uvs[f.c]);
        }
        geom.uvsNeedUpdate = true;
        this.components.push(this.plane);
    }

    public setLayer(layerName:string) {
        this.layerName = layerName
        let layerProp = this.layer.wireframe.findProperty(LayerProperty, true);
        for (let c of this.components) {
            layerProp.setValue(this.layer.findElement(c), layerName);
        }
    }

    public setPos(center:THREE.Vector3, rot:THREE.Quaternion, width:number, height:number) {
        let verts:THREE.Vector3[] = [
            new THREE.Vector3(-width / 2, 0, height / 2),
            new THREE.Vector3(width / 2, 0, height / 2),
            new THREE.Vector3(width / 2, 0, -height / 2),
            new THREE.Vector3( -width / 2, 0, -height / 2)
        ];
        for (let i = 0; i < verts.length; i++) {
            verts[i].applyQuaternion(rot)
            verts[i].add(center)
            this.layer.wireframe.setVert3(this.plane.vertexIds[i], verts[i]);
        }
        this.planeElement.updateGeometry()
        this.planeElement.updateDependentGeometry()
    }


}
