import {
    Component, Input
} from '@angular/core';
import {ProjectImageCollectionFilterModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilter.model";
import {ProjectImageCollectionFilterAnnotationModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterAnnotation.model";
import {ProjectImageCollectionFilterMetadataModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterMetadata.model";
import {ProjectImageCollectionFilterTagModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterTag.model";
import {ProjectImageCollectionFilterCameraPointViewModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterCameraPointView.model";

@Component({
    selector: 'project-image-list-filter-labelSprite',
    styles: [require('./projectImageListFilterLabel.component.scss')],
    template: require('./projectImageListFilterLabel.component.html')
})
export default class ProjectImageListFilterLabelComponent {
    @Input() projectImageListFilterModel: ProjectImageCollectionFilterModel;

    constructor() {
    }

    get filterSymbol(): string{
        let symbol = '';
        if (!this.projectImageListFilterModel) return symbol;

        if(this.projectImageListFilterModel instanceof ProjectImageCollectionFilterAnnotationModel){
            symbol = 'chat_bubble';
        } else if(this.projectImageListFilterModel instanceof ProjectImageCollectionFilterMetadataModel){
            symbol = 'list';
        } else if(this.projectImageListFilterModel instanceof ProjectImageCollectionFilterCameraPointViewModel){
            symbol = 'gps_fixed';
        } else if(this.projectImageListFilterModel instanceof ProjectImageCollectionFilterTagModel){
            symbol = 'note';
        }

        return symbol;
    }

    get filterName(): string {
        let name = '';
        if (!this.projectImageListFilterModel) return name;
        name = this.projectImageListFilterModel.filterName;
        return name;
    }
}