import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ModuleService} from '../../services/modules/module.service';
import {ModuleData} from '../../models/modules/module.model';

@Component({
    selector: 'app-manager',
    template: require('./appManager.component.html'),
    styles: [require('./appManager.component.scss')]
})
export class AppManagerComponent implements OnInit {
    modules: any;
    errorMessage: string;
    errorVisible = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private moduleService: ModuleService
    ) {
    }

    ngOnInit() {
        this.modules = this.moduleService.loadModules();
    }

    enableModule(moduleData: ModuleData) {
        this.moduleService.enableModule(moduleData).then(
            ()=>{
                this.detectChanges();
            },
            (err)=>{
                this.showError(`${moduleData.moduleName} could not be found, did you copy the umd file to ${moduleData.filePath}?  ${err}`, err);
                this.detectChanges();
            });
    }

    showError(message: string, err) {
        this.errorMessage = message;
        this.errorVisible = true;
        console.log(err);
    }

    closeError() {
        this.errorVisible = false;
    }

    detectChanges(): void {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }
}
