import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Injector
} from '@angular/core';
import VertexFilterLabelComponent from "../vertexFilterLabel/vertexFilterLabel.component";
import VertexFilterEditorComponent from "../vertexFilterEditor/vertexFilterEditor.component";
import InteractiveFilterComponent from "../interactiveFilter/interactiveFilter.component";
import InteractiveFilterEditorComponent from "../interactiveFilterEditor/interactiveFilterEditor.component";
import InteractiveFilterLabelComponent from "../interactiveFilterLabel/interactiveFilterLabel.component";
import {VertexElementModel} from "../../models/vertexElements/vertexElement.model";

@Component({
    selector: 'ptv-vertex-filter',
    styles: [require('./vertexFilter.component.scss')],
    template: require('./vertexFilter.component.html')
})
export default class VertexFilterComponent extends InteractiveFilterComponent<VertexElementModel> {
    constructor(private vertexFilterInjector: Injector,
                private componentFactoryResolver: ComponentFactoryResolver){
        super(vertexFilterInjector);
    }

    createInteractiveFilterEditorComponent(injector: Injector): ComponentRef<InteractiveFilterEditorComponent<VertexElementModel>> {
        let interactiveFilterEditorComponentFactory = this.componentFactoryResolver.resolveComponentFactory(VertexFilterEditorComponent);
        let interactiveFilterEditorComponent = this.interactiveFilterContainerRef.createComponent(interactiveFilterEditorComponentFactory, 0, injector);
        return interactiveFilterEditorComponent;
    }

    createInteractiveFilterLabelComponent(injector: Injector): ComponentRef<InteractiveFilterLabelComponent<VertexElementModel>> {
        let interactiveFilterLabelComponentFactory = this.componentFactoryResolver.resolveComponentFactory(VertexFilterLabelComponent);
        let interactiveFilterLabelComponent = this.interactiveFilterContainerRef.createComponent(interactiveFilterLabelComponentFactory, 0, injector);
        return interactiveFilterLabelComponent;
    }
}