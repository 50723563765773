import firebase from 'firebase/app';
import 'firebase/database';

// import {database} from "firebase";
import Reference = firebase.database.Reference;
import {InteractiveModel} from "../interactive/interactive.model";
import {EventEmitter} from "@angular/core";
import {Credentials, ProjectResourceManager, Resource} from "../resources/projectResourceManager";
import {S3Object} from "../resources/s3Object";
import {PointivoFirebaseSingleton} from "../../firebaseSingleton";

export interface Project {
    projectMetadata: any;
    projectData: any;
}

export class ProjectModel extends InteractiveModel implements Project {
    projectRecord: any;
    projectData: any;
    projectDataReady = new EventEmitter<ProjectModel>();
    projectDataUpdated = new EventEmitter<ProjectModel>();
    private projectResourceManager: ProjectResourceManager;

    // annotation classes
    public annotationClasses: Array<string>;

    // tags
    public allTags: Array<string> = [];

    constructor(projectRecord: any) {
        super();
        this.projectRecord = projectRecord;
        this.projectRef.on('value', this.onProjectDataUpdate.bind(this));
    }

    // project record

    get projectInformation(): any {
        let projectInformation = null;
        if (!this.projectRecord) return projectInformation;
        projectInformation = this.projectRecord.project;
        return projectInformation;
    }

    get projectBaseUrl(): string {
        let projectBaseUrl = null;
        if (!this.projectRecord) return projectBaseUrl;
        projectBaseUrl = this.projectRecord.baseUrl;
        return projectBaseUrl;
    }

    get projectId(): number {
        let projectId = null;
        let projectInformation = this.projectInformation;
        if (!projectInformation) return projectId;
        projectId = projectInformation.id;
        return projectId;
    }

    get projectName(): string {
        let projectName = null;
        let projectInformation = this.projectInformation;
        if (!projectInformation) return projectName;
        projectName = projectInformation.name;
        return projectName;
    }

    get projectLocation(): string {
        let projectLocation = null;
        let projectInformation = this.projectInformation;
        if (!projectInformation) return projectLocation;
        if (!projectInformation.location) return projectLocation;
        if (!projectInformation.location.formattedAddress) return projectLocation;
        projectLocation = projectInformation.location.formattedAddress;
        return projectLocation;
    }

    // project data (firebase)

    onProjectDataUpdate(projectDataReference) {
        let newProjectData = projectDataReference.val();
        let firstUpdate = false;
        if (newProjectData && !this.projectData) {
            firstUpdate = true;
        }
        if (firstUpdate) {
            this.projectDataReady.emit(this);
        }
        this.projectData = newProjectData;
        this.projectDataUpdated.emit(this);
    }

    get isProjectDataReady(): boolean {
        return !!this.projectData;
    }

    get projectMetadata(): any {
        if (!this.projectData) return null;
        if (!this.projectData.metadata) return null;
        return this.projectData.metadata;
    }

    public hasTiledImage(tiledImageId:number){
        let tileImageResource:Resource = this.getTiledImageResource(tiledImageId);
        return !!tileImageResource;
    }

    public getTiledImageResource(tiledImageId:number):Resource{
        let tileImageResources:Resource[] = this.findResourcesByTypeName("TILED_IMAGE");
        let tileImageResource:Resource = null;
        tileImageResources.forEach((res:Resource) => {
            let metadata = res.metadata;
            if (!metadata) return;
            let imageMetadata = metadata.imageMetadata;
            if (!imageMetadata) return;
            let frameId = imageMetadata.frameId;
            if(frameId == tiledImageId){
                tileImageResource = res;
            }
        });
        return tileImageResource;
    }

    public getTiledImageMetadata(tiledImageId:number):any{
        let self = this;
        let tileMetadata = null;
        let tiledImageResource = this.getTiledImageResource(tiledImageId);
        if (!tiledImageResource) return tileMetadata;
        let metadata = tiledImageResource.metadata;
        if (!metadata) return tileMetadata;
        tileMetadata = metadata.tileMetadata;
        if (!tileMetadata) return tileMetadata;
        tileMetadata.getAuthorizedUrl = (url) => {
            let presignedUrl = self.getPresignedUrl(url);
            return presignedUrl;
        };
        return tileMetadata;
    }

    public getTiledImageHref(tiledImageId: number): string {
        let tileImageResource:Resource = this.getTiledImageResource(tiledImageId);
        if(!tileImageResource) return null;
        let links = (tileImageResource as any).links;
        if(!links) return;
        let href = links.href;
        return href;
    }

    // s3 resources

    get s3Bucket(): string {
        let s3Bucket = null;
        let projectRecord = this.projectRecord;
        if (!projectRecord) return s3Bucket;
        s3Bucket = projectRecord.s3Bucket;
        return s3Bucket;
    }

    get s3Key(): string {
        let s3Key = null;
        let projectRecord = this.projectRecord;
        if (!projectRecord) return s3Key;
        s3Key = projectRecord.s3Key;
        return s3Key;
    }

    get s3Credentials(): Credentials {
        let credentials = null;
        let projectRecord = this.projectRecord;
        if (!projectRecord) return credentials;
        credentials = projectRecord.credentials;
        return credentials;
    }

    get resourceManager(): ProjectResourceManager {
        if (!this.projectResourceManager) {
            this.projectResourceManager = new ProjectResourceManager();
            this.projectResourceManager.projectData = this.projectData;
            this.projectResourceManager.setCredentials(this.s3Credentials);
            this.projectResourceManager.s3Bucket = this.s3Bucket;
            this.projectResourceManager.projectKey = this.s3Key;
        }
        return this.projectResourceManager;
    }


    getPresignedUrl(url: string): string {
        let presignedUrl = null;
        let s3Object = this.resourceManager.getS3ObjectFromURL(url);
        if (!s3Object) return presignedUrl;
        presignedUrl = this.resourceManager.generatePresignedUrl(s3Object);
        return presignedUrl;
    }

    // resource records

    get resources(): Resource[] {
        let resourceArray = [];
        if (!this.projectData) return resourceArray;
        if (!this.projectData.resources) return resourceArray;
        resourceArray = Object.values(this.projectData.resources);
        return resourceArray;
    }

    findResourcesByName(resourceName: string): Resource[] {
        let foundResources: Resource[] = [];
        if (!resourceName) return foundResources;
        let resources = this.resources;
        if (!resources) return foundResources;
        foundResources = resources.filter((r: Resource) => r.name == resourceName);
        return foundResources;
    }

    findResourceByName(resourceName: string): Resource {
        let resource: Resource = null;
        let foundResources = this.findResourcesByName(resourceName);
        if (!foundResources) return resource;
        if (foundResources.length === 0) return resource;
        resource = foundResources[0];  // first
        return resource;
    }

    findResourcesByTypeName(resourceTypeName: string): Resource[] {
        let foundResources: Resource[] = [];
        if (!resourceTypeName) return foundResources;
        let resources = this.resources;
        if (!resources) return foundResources;
        foundResources = resources.filter((r: Resource) => r.resourceType.name == resourceTypeName);
        return foundResources;
    }

    findResourceByTypeName(resourceTypeName: string): Resource {
        let resource: Resource = null;
        let foundResources = this.findResourcesByTypeName(resourceTypeName);
        if (!foundResources) return resource;
        if (foundResources.length === 0) return resource;
        resource = foundResources[0];  // first
        return resource;
    }

    findResourceS3ObjectsByTypeName(resourceTypeName: string): S3Object[] {
        let foundS3Objects: S3Object[] = [];
        if (!this.resourceManager) return foundS3Objects;
        let foundResources = this.findResourcesByTypeName(resourceTypeName);
        if (!foundResources) return foundS3Objects;
        foundResources.forEach((foundResource) => {
            let foundS3Object = this.resourceManager.getS3ObjectForResource(foundResource);
            if (!foundS3Object) return;
            foundS3Objects.push(foundS3Object);
        });
        return foundS3Objects;
    }

    findResourceS3ObjectByTypeName(resourceTypeName: string): S3Object {
        let s3Object: S3Object = null;
        if (!this.resourceManager) return s3Object;
        let foundS3Objects = this.findResourceS3ObjectsByTypeName(resourceTypeName);
        if (!foundS3Objects) return s3Object;
        if (foundS3Objects.length === 0) return s3Object;
        s3Object = foundS3Objects[0];  // first
        return s3Object;
    }

    findResourceS3ObjectsByName(resourceName: string): S3Object[] {
        let foundS3Objects: S3Object[] = [];
        if (!this.resourceManager) return foundS3Objects;
        let foundResources = this.findResourcesByName(resourceName);
        if (!foundResources) return foundS3Objects;
        foundResources.forEach((foundResource) => {
            let foundS3Object = this.resourceManager.getS3ObjectForResource(foundResource);
            if (!foundS3Object) return;
            foundS3Objects.push(foundS3Object);
        });
        return foundS3Objects;
    }

    findResourceS3ObjectByName(resourceName: string): S3Object {
        let s3Object: S3Object = null;
        if (!this.resourceManager) return s3Object;
        let foundS3Objects = this.findResourceS3ObjectsByName(resourceName);
        if (!foundS3Objects) return s3Object;
        if (foundS3Objects.length === 0) return s3Object;
        s3Object = foundS3Objects[0];  // first
        return s3Object;
    }

    getResourceImageUrlBlob(resourceImageName: string, onProgress = (progress) => null): Promise<string> {
        let nullResponse = new Promise<string>(resolve => {
            resolve(null);
        });
        if (!this.resourceManager) return nullResponse;
        let projectResourceS3Object = this.findResourceS3ObjectByName(resourceImageName);
        if (!projectResourceS3Object) return nullResponse;

        return new Promise<string>(((resolve) => {
            this.resourceManager.getImageData(
                projectResourceS3Object,
                (urlBlob) => {
                    resolve(urlBlob);
                },
                (progress) => {
                    if (onProgress) onProgress(progress);
                });
        }));
    }

    getResourceStringData(projectResourceS3Object: S3Object, onProgress = (progress) => null): Promise<string> {
        let nullResponse = new Promise<string>(resolve => {
            resolve(null);
        });
        if (!this.resourceManager) return nullResponse;
        if (!projectResourceS3Object) return nullResponse;

        return new Promise<string>(((resolve) => {
            this.resourceManager.getStringData(
                projectResourceS3Object,
                (stringData) => {
                    resolve(stringData);
                },
                (progress) => {
                    if (onProgress) onProgress(progress);
                });
        }));
    }


    // firebase reference

    get projectRef(): Reference {
        let db = PointivoFirebaseSingleton.getInstance().db
        let ref = db.ref();
        return ref.child("project/" + this.projectId);
    }

}