import {InteractiveModel} from "../interactive/interactive.model";
import {v4 as uuid} from 'uuid';

export class GeoJsonFeatureModel extends InteractiveModel implements GeoJSON.Feature {
    id?: string | number;
    type;
    properties: { [name: string]: any; };
    geometry: GeoJSON.Geometry;

    constructor(geometry: any = null, properties: { [name: string]: any }) {
        super();
        this.geometry = geometry;
        if (properties && properties.id == null) 
            properties.id = uuid();
            this.properties = properties;
            this.type = "Feature";
    }
}