import tpl from './resourceBrowser.html'
import {WireframeApplication} from "../../application/wireframeApplication";
import {WireframeElementType} from "../../application/wireframeElementType";

declare var wfApp:WireframeApplication

export default {
    bindings: {},
    controller: ['uiGridConstants', 'apiService', function (uiGridConstants, apiService) {
        let ctrl = this;

        this.projectDataChanged = function() {
            console.log("projectDataChanged")
        }

        //wfApp.on("projectDataChanged", this.projectDataChanged.bind(this))

        this.$onInit = function() {
            ctrl.projectDataChanged()
        }

        let gridOptions = this.gridOptions = {
            appScopeProvider: ctrl,
            onRegisterApi: function (gridApi) {
                ctrl.gridApi = gridApi;
            },
            gridMenuCustomItems: [

            ],
            enableFiltering: false,
            enableGridMenu: true,
            exporterMenuCsv: false,
            enableRowSelection: true,
            enableFullRowSelection: true,
            enableRowHeaderSelection: false,
            enableColumnMenus: false,
            multiSelect: false,
            enableColumnResizing: true,
            enableSorting: true,
            enableRowHashing:false,
            noUnselect: false,
            enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
            columnDefs: [
                {
                    field: 'id',
                    type: 'string',
                    visible: true
                },
                {
                    field: 'name',
                    type: 'string',
                    visible: true
                },
                {
                    field: 'confidence',
                    type: 'number',
                    visible: true
                }
            ],
            data: [],
            rowEquality:function(a,b) {
                return (Number(a['id']) == Number(b['id']))
            }
        };
    }],
    template: tpl
}

