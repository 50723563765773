import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import {InteractiveFilterModel} from "../../models/interactive/interactiveFilter.model";
import {InteractiveModel} from "../../models/interactive/interactive.model";

@Component({
    selector: 'project-image-list-filter-editor-metadata',
    styles: [require('./interactiveFilterTypeEditor.component.scss')],
    template: require('./interactiveFilterTypeEditor.component.html')
})
export default class InteractiveFilterTypeEditorComponent<T extends InteractiveModel> {
    @Input() interactiveFilterModel: InteractiveFilterModel<T>;
    @Output() filterUpdated = new EventEmitter<InteractiveFilterModel<T>>();

    constructor() {
    }

    triggerFilterUpdated() {
        this.filterUpdated.emit(this.interactiveFilterModel);
    }
}