import * as THREE from 'three';
import {WireframeApplication} from "./application/wireframeApplication";
import {Subscription} from "rxjs";

export class TransformControls extends THREE.Object3D {

    private deleteElementSub: Subscription

    constructor(private app: WireframeApplication, private camera: THREE.Camera, private domElement: HTMLElement) {
        super()
        this.add(this._gizmo)
        this.add(this._plane)
        this.addEventListeners()

        this.deleteElementSub = this.app.eventEmitter.on("wireframeElementDeleted").subscribe((e) => {
            if (null != e && e == this.object) {
                this.detach()
            }
        })
    }

    public destroy() {
         this.deleteElementSub.unsubscribe()
    }

    visible = false;

    _gizmo = new TransformControlsGizmo(this);
    _plane = new TransformControlsPlane(this);


    // Define properties with getters/setter
    // Setting the defined property will automatically trigger change event
    // Defined properties are passed down to gizmo and plane

     /*
    defineProperty( "camera", camera );
    defineProperty( "object", undefined );
    defineProperty( "enabled", true );
    defineProperty( "axis", null );
    defineProperty( "mode", "translate" );
    defineProperty( "translationSnap", null );
    defineProperty( "rotationSnap", null );
    defineProperty( "space", "world" );
    defineProperty( "size", 1 );
    defineProperty( "dragging", false );
    defineProperty( "showX", true );
    defineProperty( "showY", true );
    defineProperty( "showZ", true );
      */

     object:THREE.Object3D
     enabled:boolean = true
     axis:string
     mode:string = "translate"
     translationSnap:number
     rotationSnap:number
     space:string = "world"
     size:number = 1
     dragging:boolean = false
     showX:boolean = true
     showY:boolean = true
     showZ:boolean = true

     changeEvent = { type: "change" };
     mouseDownEvent = { type: "mouseDown", mode: this.mode };
     mouseUpEvent = { type: "mouseUp", mode: this.mode };
     objectChangeEvent = { type: "objectChange" };

    // Reusable utility variables

     ray = new THREE.Raycaster();

    private _tempVector = new THREE.Vector3();
     private _tempVector2 = new THREE.Vector3();
     private _tempQuaternion = new THREE.Quaternion();
     private _unit = {
        X: new THREE.Vector3( 1, 0, 0 ),
        Y: new THREE.Vector3( 0, 1, 0 ),
        Z: new THREE.Vector3( 0, 0, 1 )
    };
     private _identityQuaternion = new THREE.Quaternion();
     private _alignVector = new THREE.Vector3();

     private pointStart = new THREE.Vector3();
     private pointEnd = new THREE.Vector3();
     private offset = new THREE.Vector3();
     rotationAxis = new THREE.Vector3();
     private startNorm = new THREE.Vector3();
     private endNorm = new THREE.Vector3();
     private rotationAngle = 0;

     cameraPosition = new THREE.Vector3();
     cameraQuaternion = new THREE.Quaternion();
     cameraScale = new THREE.Vector3();

     private parentPosition = new THREE.Vector3();
     private parentQuaternion = new THREE.Quaternion();
     private parentQuaternionInv = new THREE.Quaternion();
     private parentScale = new THREE.Vector3();

     worldPositionStart = new THREE.Vector3();
     worldQuaternionStart = new THREE.Quaternion();
     worldScaleStart = new THREE.Vector3();

     worldPosition = new THREE.Vector3();
     worldQuaternion = new THREE.Quaternion();
     private worldQuaternionInv = new THREE.Quaternion();
     private worldScale = new THREE.Vector3();

     eye = new THREE.Vector3();

     private positionStart = new THREE.Vector3();
     private quaternionStart = new THREE.Quaternion();
     private scaleStart = new THREE.Vector3();

    // TODO: remove properties unused in plane and gizmo

     /*
    defineProperty( "worldPosition", worldPosition );
    defineProperty( "worldPositionStart", worldPositionStart );
    defineProperty( "worldQuaternion", worldQuaternion );
    defineProperty( "worldQuaternionStart", worldQuaternionStart );
    defineProperty( "cameraPosition", cameraPosition );
    defineProperty( "cameraQuaternion", cameraQuaternion );
    defineProperty( "pointStart", pointStart );
    defineProperty( "pointEnd", pointEnd );
    defineProperty( "rotationAxis", rotationAxis );
    defineProperty( "rotationAngle", rotationAngle );
    defineProperty( "eye", eye );


*/

    private addEventListeners() {

        this.domElement.addEventListener( "mousedown", this.onPointerDown.bind(this) );
        this.domElement.addEventListener( "touchstart", this.onPointerDown.bind(this) );
        this.domElement.addEventListener( "mousemove", this.onPointerHover.bind(this) );
        this.domElement.addEventListener( "touchmove", this.onPointerHover.bind(this) );
        this.domElement.addEventListener( "touchmove", this.onPointerMove.bind(this) );
        this.domElement.addEventListener( "mouseup", this.onPointerUp.bind(this) );
        this.domElement.addEventListener( "touchend", this.onPointerUp.bind(this) );
        this.domElement.addEventListener( "touchcancel", this.onPointerUp.bind(this) );
        this.domElement.addEventListener( "touchleave", this.onPointerUp.bind(this) );

    }

    dispose() {

        console.log("transformControl dispose")
        this.domElement.removeEventListener( "mousedown", this.onPointerDown );
        this.domElement.removeEventListener( "touchstart", this.onPointerDown );
        this.domElement.removeEventListener( "mousemove", this.onPointerHover );
        this.domElement.removeEventListener( "touchmove", this.onPointerHover );
        this.domElement.removeEventListener( "touchmove", this.onPointerMove );
        this.domElement.removeEventListener( "mouseup", this.onPointerUp );
        this.domElement.removeEventListener( "touchend", this.onPointerUp );
        this.domElement.removeEventListener( "touchcancel", this.onPointerUp );
        this.domElement.removeEventListener( "touchleave", this.onPointerUp );

        this.traverse( function ( child:any ) {

            if ( child.geometry ) child.geometry.dispose();
            if ( child.material ) child.material.dispose();

        } );

    };

    // Set current object
    attach( object ) {

        this.object = object;
        this.visible = true;
        return this;
    };

    // Detatch from object
    detach() {

        this.object = undefined;
        this.visible = false;
        this.axis = null;

    };

    /*
    // Defined getter, setter and store for a property
    function defineProperty( propName, defaultValue ) {

        var propValue = defaultValue;

        Object.defineProperty( scope, propName, {

            get: function() {

                return propValue !== undefined ? propValue : defaultValue;

            },

            set: function( value ) {

                if ( propValue !== value ) {

                    propValue = value;
                    _plane[ propName ] = value;
                    _gizmo[ propName ] = value;

                    scope.dispatchEvent( { type: propName + "-changed", value: value } );
                    scope.dispatchEvent( changeEvent );

                }

            }

        });

        scope[ propName ] = defaultValue;
        _plane[ propName ] = defaultValue;
        _gizmo[ propName ] = defaultValue;

    }
    */


    // updateMatrixWorld  updates key transformation variables
    updateMatrixWorld(force:boolean) {

        if ( this.object !== undefined ) {

            this.object.updateMatrixWorld(force);
            this.object.parent.matrixWorld.decompose( this.parentPosition, this.parentQuaternion, this.parentScale );
            this.object.matrixWorld.decompose( this.worldPosition, this.worldQuaternion, this.worldScale );
            this.parentQuaternionInv.copy( this.parentQuaternion ).inverse();
            this.worldQuaternionInv.copy( this.worldQuaternion ).inverse();

        }

        this.camera.updateMatrixWorld(true);
        this.camera.matrixWorld.decompose( this.cameraPosition, this.cameraQuaternion, this.cameraScale );

        if ( this.camera instanceof THREE.PerspectiveCamera ) {
            this.eye.copy( this.cameraPosition ).sub( this.worldPosition ).normalize();
        } else if ( this.camera instanceof THREE.OrthographicCamera ) {
            this.eye.copy( this.cameraPosition ).normalize();
        }
        super.updateMatrixWorld(force)
    };

    pointerHover( pointer ) {

        if ( this.object === undefined || this.dragging === true || ( pointer.button !== undefined && pointer.button !== 0 ) ) return;

        this.ray.setFromCamera( pointer, this.camera );

        var intersect = this.ray.intersectObjects( this._gizmo.picker[ this.mode ].children, true )[ 0 ] || false;

        if ( intersect ) {

            this.axis = intersect.object.name;

        } else {

            this.axis = null;

        }
    };

    pointerDown( pointer ) {

        if ( this.object === undefined || this.dragging === true || ( pointer.button !== undefined && pointer.button !== 0 ) ) return;

        if ( ( pointer.button === 0 || pointer.button === undefined ) && this.axis !== null ) {

            this.ray.setFromCamera( pointer, this.camera );

            var planeIntersect = this.ray.intersectObjects( [ this._plane ], true )[ 0 ] || false;

            if ( planeIntersect ) {

                var space = this.space;

                if ( this.mode === 'scale') {

                    space = 'local';

                } else if ( this.axis === 'E' ||  this.axis === 'XYZE' ||  this.axis === 'XYZ' ) {

                    space = 'world';

                }

                if ( space === 'local' && this.mode === 'rotate' ) {

                    var snap = this.rotationSnap;

                    if ( this.axis === 'X' && snap ) this.object.rotation.x = Math.round( this.object.rotation.x / snap ) * snap;
                    if ( this.axis === 'Y' && snap ) this.object.rotation.y = Math.round( this.object.rotation.y / snap ) * snap;
                    if ( this.axis === 'Z' && snap ) this.object.rotation.z = Math.round( this.object.rotation.z / snap ) * snap;

                }

                this.object.updateMatrixWorld(true);
                this.object.parent.updateMatrixWorld(true);

                this.positionStart.copy( this.object.position );
                this.quaternionStart.copy( this.object.quaternion );
                this.scaleStart.copy( this.object.scale );

                this.object.matrixWorld.decompose( this.worldPositionStart, this.worldQuaternionStart, this.worldScaleStart );

                this.pointStart.copy( planeIntersect.point ).sub( this.worldPositionStart );

            }

            this.dragging = true;
            this.mouseDownEvent.mode = this.mode;
            this.dispatchEvent( this.mouseDownEvent );

        }

    };

    pointerMove( pointer ) {
        let axis = this.axis;
        let mode = this.mode;
        let object = this.object;
        let space = this.space;

        if ( mode === 'scale') {

            space = 'local';

        } else if ( axis === 'E' ||  axis === 'XYZE' ||  axis === 'XYZ' ) {

            space = 'world';

        }

        if ( object === undefined || axis === null || this.dragging === false || ( pointer.button !== undefined && pointer.button !== 0 ) ) return;

        this.ray.setFromCamera( pointer, this.camera );

        let planeIntersect = this.ray.intersectObjects( [ this._plane ], true )[ 0 ] || false;

        if ( planeIntersect === false ) return;

        this.pointEnd.copy( planeIntersect.point ).sub( this.worldPositionStart );

        if ( mode === 'translate' ) {

            // Apply translate

            this.offset.copy( this.pointEnd ).sub( this.pointStart );

            if ( space === 'local' && axis !== 'XYZ' ) {
                this.offset.applyQuaternion( this.worldQuaternionInv );
            }

            if ( axis.indexOf( 'X' ) === -1 ) this.offset.x = 0;
            if ( axis.indexOf( 'Y' ) === -1 ) this.offset.y = 0;
            if ( axis.indexOf( 'Z' ) === -1 ) this.offset.z = 0;

            if ( space === 'local' && axis !== 'XYZ') {
                this.offset.applyQuaternion( this.quaternionStart ).divide( this.parentScale );
            } else {
                this.offset.applyQuaternion( this.parentQuaternionInv ).divide( this.parentScale );
            }

            object.position.copy( this.offset ).add( this.positionStart );

            // Apply translation snap

            if ( this.translationSnap ) {

                if ( space === 'local' ) {

                    object.position.applyQuaternion(this._tempQuaternion.copy( this.quaternionStart ).inverse() );

                    if ( axis.search( 'X' ) !== -1 ) {
                        object.position.x = Math.round( object.position.x / this.translationSnap ) * this.translationSnap;
                    }

                    if ( axis.search( 'Y' ) !== -1 ) {
                        object.position.y = Math.round( object.position.y / this.translationSnap ) * this.translationSnap;
                    }

                    if ( axis.search( 'Z' ) !== -1 ) {
                        object.position.z = Math.round( object.position.z / this.translationSnap ) * this.translationSnap;
                    }

                    object.position.applyQuaternion( this.quaternionStart );

                }

                if ( space === 'world' ) {

                    if ( object.parent ) {
                        object.position.add( this._tempVector.setFromMatrixPosition( object.parent.matrixWorld ) );
                    }

                    if ( axis.search( 'X' ) !== -1 ) {
                        object.position.x = Math.round( object.position.x / this.translationSnap ) * this.translationSnap;
                    }

                    if ( axis.search( 'Y' ) !== -1 ) {
                        object.position.y = Math.round( object.position.y / this.translationSnap ) * this.translationSnap;
                    }

                    if ( axis.search( 'Z' ) !== -1 ) {
                        object.position.z = Math.round( object.position.z / this.translationSnap ) * this.translationSnap;
                    }

                    if ( object.parent ) {
                        object.position.sub( this._tempVector.setFromMatrixPosition( object.parent.matrixWorld ) );
                    }

                }

            }

        } else if ( mode === 'scale' ) {

            if ( axis.search( 'XYZ' ) !== -1 ) {

                var d = this.pointEnd.length() / this.pointStart.length();

                if ( this.pointEnd.dot( this.pointStart ) < 0 ) d *= -1;

                this._tempVector2.set( d, d, d );

            } else {

                this._tempVector.copy(this.pointStart);
                this._tempVector2.copy(this.pointEnd);

                this._tempVector.applyQuaternion( this.worldQuaternionInv );
                this._tempVector2.applyQuaternion( this.worldQuaternionInv );

                this._tempVector2.divide( this._tempVector );

                if ( axis.search( 'X' ) === -1 ) {
                    this._tempVector2.x = 1;
                }
                if ( axis.search( 'Y' ) === -1 ) {
                    this._tempVector2.y = 1;
                }
                if ( axis.search( 'Z' ) === -1 ) {
                    this._tempVector2.z = 1;
                }

            }

            // Apply scale

            object.scale.copy( this.scaleStart ).multiply( this._tempVector2 );

        } else if ( mode === 'rotate' ) {

            this.offset.copy( this.pointEnd ).sub( this.pointStart );

            var ROTATION_SPEED = 20 / this.worldPosition.distanceTo( this._tempVector.setFromMatrixPosition( this.camera.matrixWorld ) );

            if ( axis === 'E' ) {

                this.rotationAxis.copy( this.eye );
                this.rotationAngle = this.pointEnd.angleTo( this.pointStart );

                this.startNorm.copy( this.pointStart ).normalize();
                this.endNorm.copy( this.pointEnd ).normalize();

                this.rotationAngle *= ( this.endNorm.cross( this.startNorm ).dot( this.eye ) < 0 ? 1 : -1);

            } else if ( axis === 'XYZE' ) {

                this.rotationAxis.copy( this.offset ).cross( this.eye ).normalize(  );
                this.rotationAngle = this.offset.dot( this._tempVector.copy( this.rotationAxis ).cross( this.eye ) ) * ROTATION_SPEED;

            } else if ( axis === 'X' || axis === 'Y' || axis === 'Z' ) {

                this.rotationAxis.copy( this._unit[ axis ] );

                this._tempVector.copy( this._unit[ axis ] );

                if ( space === 'local' ) {
                    this._tempVector.applyQuaternion( this.worldQuaternion );
                }

                this.rotationAngle = this.offset.dot( this._tempVector.cross( this.eye ).normalize() ) * ROTATION_SPEED;

            }

            // Apply rotation snap

            if ( this.rotationSnap ) this.rotationAngle = Math.round( this.rotationAngle / this.rotationSnap ) * this.rotationSnap;

            this.rotationAngle = this.rotationAngle;

            // Apply rotate
            if ( space === 'local' && axis !== 'E' && axis !== 'XYZE' ) {

                object.quaternion.copy( this.quaternionStart );
                object.quaternion.multiply( this._tempQuaternion.setFromAxisAngle( this.rotationAxis, this.rotationAngle ) ).normalize();

            } else {

                this.rotationAxis.applyQuaternion( this.parentQuaternionInv );
                object.quaternion.copy( this._tempQuaternion.setFromAxisAngle( this.rotationAxis, this.rotationAngle ) );
                object.quaternion.multiply( this.quaternionStart ).normalize();

            }

        }

        this.dispatchEvent( this.changeEvent );
        this.dispatchEvent( this.objectChangeEvent );

    };

    pointerUp( pointer ) {

        if ( pointer.button !== undefined && pointer.button !== 0 ) return;

        if ( this.dragging && ( this.axis !== null ) ) {

            this.mouseUpEvent.mode = this.mode;
            this.dispatchEvent( this.mouseUpEvent );

        }

        this.dragging = false;

        if ( pointer.button === undefined ) this.axis = null;

    };

    // normalize mouse / touch pointer and remap {x,y} to view space.

    getPointer( event ) {

        var pointer = event.changedTouches ? event.changedTouches[ 0 ] : event;

        var rect = this.domElement.getBoundingClientRect();

        return {
            x: ( pointer.clientX - rect.left ) / rect.width * 2 - 1,
            y: - ( pointer.clientY - rect.top ) / rect.height * 2 + 1,
            button: event.button
        };

    }

    // mouse / touch event handlers

    onPointerHover( event ) {

        if ( !this.enabled ) return;

        this.pointerHover( this.getPointer( event ) );

    }


    private mouseMoveFunc = this.onPointerMove.bind(this)

    onPointerDown( event ) {

        if ( !this.enabled ) return;

        document.addEventListener( "mousemove", this.mouseMoveFunc, false );

        this.pointerHover( this.getPointer( event ) );
        this.pointerDown( this.getPointer( event ) );

    }

    onPointerMove( event ) {

        if ( !this.enabled ) return;

        this.pointerMove( this.getPointer( event ) );

    }

    onPointerUp( event ) {

        if ( !this.enabled ) return;

        document.removeEventListener( "mousemove", this.mouseMoveFunc, false );

        this.pointerUp( this.getPointer( event ) );

    }

    // TODO: depricate

    getMode() {

        return this.mode;

    };

    setMode = function ( mode ) {

        this.mode = mode;

    };

    setTranslationSnap( translationSnap ) {

        this.translationSnap = translationSnap;

    };

    setRotationSnap( rotationSnap ) {

        this.rotationSnap = rotationSnap;

    };

    setSize = function ( size ) {

        this.size = size;

    };

    setSpace( space ) {

        this.space = space;

    };

    update() {

        //console.warn( 'THREE.TransformControls: update function has been depricated.' );

    };

}


export class TransformControlsGizmo extends THREE.Object3D {

    constructor(private controls:TransformControls) {
        super()
        this.initMaterials()
        this.initGizmos()
    }

    type = 'TransformControlsGizmo';

    // shared materials

    gizmoMaterial = new THREE.MeshBasicMaterial({
        depthTest: false,
        depthWrite: false,
        transparent: true,
        side: THREE.DoubleSide,
        fog: false
    });

    gizmoLineMaterial = new THREE.LineBasicMaterial({
        depthTest: false,
        depthWrite: false,
        transparent: true,
        linewidth: 1,
        fog: false
    });

    // Make unique material for each axis/color

    matInvisible:THREE.MeshBasicMaterial
    matHelper:THREE.MeshBasicMaterial
    matRed:THREE.MeshBasicMaterial
    matGreen:THREE.MeshBasicMaterial
    matBlue:THREE.MeshBasicMaterial
    matWhiteTransperent:THREE.MeshBasicMaterial
    matYellowTransparent:THREE.MeshBasicMaterial
    matCyanTransparent:THREE.MeshBasicMaterial
    matMagentaTransparent:THREE.MeshBasicMaterial
    matYellow:THREE.MeshBasicMaterial

    matLineRed:THREE.LineBasicMaterial
    matLineGreen:THREE.LineBasicMaterial
    matLineBlue:THREE.LineBasicMaterial
    matLineCyan:THREE.LineBasicMaterial
    matLineMagenta:THREE.LineBasicMaterial
    matLineYellow:THREE.LineBasicMaterial
    matLineGray:THREE.LineBasicMaterial
    matLineYellowTransparent:THREE.LineBasicMaterial

    // reusable geometry

    arrowGeometry = new THREE.CylinderBufferGeometry( 0, 0.05, 0.2, 12, 1, false);
    scaleHandleGeometry = new THREE.BoxBufferGeometry( 0.125, 0.125, 0.125);
    lineGeometry = new THREE.BufferGeometry( );

    initMaterials() {
        this.matInvisible = this.gizmoMaterial.clone();
        this.matInvisible.opacity = 0.15;
        this.matHelper = this.gizmoMaterial.clone();
        this.matHelper.opacity = 0.33;
        this.matRed = this.gizmoMaterial.clone();
        this.matRed.color.set( 0xff0000 );
        this.matGreen = this.gizmoMaterial.clone();
        this.matGreen.color.set( 0x00ff00 );
        this.matBlue = this.gizmoMaterial.clone();
        this.matBlue.color.set( 0x0000ff );
        this.matWhiteTransperent = this.gizmoMaterial.clone();
        this.matWhiteTransperent.opacity = 0.45;

        this.matYellowTransparent = this.matWhiteTransperent.clone();
        this.matYellowTransparent.color.set( 0xffff00 );
        this.matCyanTransparent = this.matWhiteTransperent.clone();
        this.matCyanTransparent.color.set( 0x00ffff );
        this.matMagentaTransparent = this.matWhiteTransperent.clone();
        this.matMagentaTransparent.color.set( 0xff00ff );

        this.matYellow = this.gizmoMaterial.clone();
        this.matYellow.color.set( 0xffff00 );

        this.matLineRed = this.gizmoLineMaterial.clone();
        this.matLineRed.color.set( 0xff0000 );
        this.matLineGreen = this.gizmoLineMaterial.clone();
        this.matLineGreen.color.set( 0x00ff00 );
        this.matLineBlue = this.gizmoLineMaterial.clone();
        this.matLineBlue.color.set( 0x0000ff );
        this.matLineCyan = this.gizmoLineMaterial.clone();
        this.matLineCyan.color.set( 0x00ffff );
        this.matLineMagenta = this.gizmoLineMaterial.clone();
        this.matLineMagenta.color.set( 0xff00ff );
        this.matLineYellow = this.gizmoLineMaterial.clone();
        this.matLineYellow.color.set( 0xffff00 );
        this.matLineGray = this.gizmoLineMaterial.clone();
        this.matLineGray.color.set( 0x787878);

        this.matLineYellowTransparent = this.matLineYellow.clone();
        this.matLineYellowTransparent.opacity = 0.25;




    }

    CircleGeometry( radius, arc ) {

        let geometry = new THREE.BufferGeometry( );
        let vertices = [];

        for ( let i = 0; i <= 64 * arc; ++i ) {

            vertices.push( 0, Math.cos( i / 32 * Math.PI ) * radius, Math.sin( i / 32 * Math.PI ) * radius );

        }

        geometry.addAttribute('position', new THREE.Float32BufferAttribute( vertices, 3 ) );

        return geometry;

    };

    // Special geometry for transform helper. If scaled with position vector it spans from [0,0,0] to position

    TranslateHelperGeometry () {

        let geometry = new THREE.BufferGeometry()

        geometry.addAttribute('position', new THREE.Float32BufferAttribute( [ 0, 0, 0, 1, 1, 1 ], 3 ) );

        return geometry;
    };

    // Gizmo definitions - custom hierarchy definitions for setupGizmo() function
    gizmoTranslate = {}
    pickerTranslate = {}
    helperTranslate = {}
    gizmoRotate = {}
    helperRotate = {}
    pickerRotate = {}
    gizmoScale = {}
    pickerScale = {}

    helperScale = {}

    initGizmos() {
        this.gizmoTranslate = {
            X: [
                [new THREE.Mesh(this.arrowGeometry, this.matRed), [1, 0, 0], [0, 0, -Math.PI / 2], null, 'fwd'],
                [new THREE.Mesh(this.arrowGeometry, this.matRed), [1, 0, 0], [0, 0, Math.PI / 2], null, 'bwd'],
                [new THREE.Line(this.lineGeometry, this.matLineRed)]
            ],
            Y: [
                [new THREE.Mesh(this.arrowGeometry, this.matGreen), [0, 1, 0], null, null, 'fwd'],
                [new THREE.Mesh(this.arrowGeometry, this.matGreen), [0, 1, 0], [Math.PI, 0, 0], null, 'bwd'],
                [new THREE.Line(this.lineGeometry, this.matLineGreen), null, [0, 0, Math.PI / 2]]
            ],
            Z: [
                [new THREE.Mesh(this.arrowGeometry, this.matBlue), [0, 0, 1], [Math.PI / 2, 0, 0], null, 'fwd'],
                [new THREE.Mesh(this.arrowGeometry, this.matBlue), [0, 0, 1], [-Math.PI / 2, 0, 0], null, 'bwd'],
                [new THREE.Line(this.lineGeometry, this.matLineBlue), null, [0, -Math.PI / 2, 0]]
            ],
            XYZ: [
                [new THREE.Mesh(new THREE.OctahedronBufferGeometry(0.1, 0), this.matWhiteTransperent), [0, 0, 0], [0, 0, 0]]
            ],
            XY: [
                [new THREE.Mesh(new THREE.PlaneBufferGeometry(0.295, 0.295), this.matYellowTransparent), [0.15, 0.15, 0]],
                [new THREE.Line(this.lineGeometry, this.matLineYellow), [0.18, 0.3, 0], null, [0.125, 1, 1]],
                [new THREE.Line(this.lineGeometry, this.matLineYellow), [0.3, 0.18, 0], [0, 0, Math.PI / 2], [0.125, 1, 1]]
            ],
            YZ: [
                [new THREE.Mesh(new THREE.PlaneBufferGeometry(0.295, 0.295), this.matCyanTransparent), [0, 0.15, 0.15], [0, Math.PI / 2, 0]],
                [new THREE.Line(this.lineGeometry, this.matLineCyan), [0, 0.18, 0.3], [0, 0, Math.PI / 2], [0.125, 1, 1]],
                [new THREE.Line(this.lineGeometry, this.matLineCyan), [0, 0.3, 0.18], [0, -Math.PI / 2, 0], [0.125, 1, 1]]
            ],
            XZ: [
                [new THREE.Mesh(new THREE.PlaneBufferGeometry(0.295, 0.295), this.matMagentaTransparent), [0.15, 0, 0.15], [-Math.PI / 2, 0, 0]],
                [new THREE.Line(this.lineGeometry, this.matLineMagenta), [0.18, 0, 0.3], null, [0.125, 1, 1]],
                [new THREE.Line(this.lineGeometry, this.matLineMagenta), [0.3, 0, 0.18], [0, -Math.PI / 2, 0], [0.125, 1, 1]]
            ]
        };

        this.pickerTranslate = {
            X: [
                [new THREE.Mesh(new THREE.CylinderBufferGeometry(0.2, 0, 1, 4, 1, false), this.matInvisible), [0.6, 0, 0], [0, 0, -Math.PI / 2]]
            ],
            Y: [
                [new THREE.Mesh(new THREE.CylinderBufferGeometry(0.2, 0, 1, 4, 1, false), this.matInvisible), [0, 0.6, 0]]
            ],
            Z: [
                [new THREE.Mesh(new THREE.CylinderBufferGeometry(0.2, 0, 1, 4, 1, false), this.matInvisible), [0, 0, 0.6], [Math.PI / 2, 0, 0]]
            ],
            XYZ: [
                [new THREE.Mesh(new THREE.OctahedronBufferGeometry(0.2, 0), this.matInvisible)]
            ],
            XY: [
                [new THREE.Mesh(new THREE.PlaneBufferGeometry(0.4, 0.4), this.matInvisible), [0.2, 0.2, 0]]
            ],
            YZ: [
                [new THREE.Mesh(new THREE.PlaneBufferGeometry(0.4, 0.4), this.matInvisible), [0, 0.2, 0.2], [0, Math.PI / 2, 0]]
            ],
            XZ: [
                [new THREE.Mesh(new THREE.PlaneBufferGeometry(0.4, 0.4), this.matInvisible), [0.2, 0, 0.2], [-Math.PI / 2, 0, 0]]
            ]
        };

        this.helperTranslate = {
            START: [
                [new THREE.Mesh(new THREE.OctahedronBufferGeometry(0.01, 2), this.matHelper), null, null, null, 'helper']
            ],
            END: [
                [new THREE.Mesh(new THREE.OctahedronBufferGeometry(0.01, 2), this.matHelper), null, null, null, 'helper']
            ],
            DELTA: [
                [new THREE.Line(this.TranslateHelperGeometry(), this.matHelper), null, null, null, 'helper']
            ],
            X: [
                [new THREE.Line(this.lineGeometry, this.matHelper.clone()), [-1e3, 0, 0], null, [1e6, 1, 1], 'helper']
            ],
            Y: [
                [new THREE.Line(this.lineGeometry, this.matHelper.clone()), [0, -1e3, 0], [0, 0, Math.PI / 2], [1e6, 1, 1], 'helper']
            ],
            Z: [
                [new THREE.Line(this.lineGeometry, this.matHelper.clone()), [0, 0, -1e3], [0, -Math.PI / 2, 0], [1e6, 1, 1], 'helper']
            ]
        };

        this.gizmoRotate = {
            X: [
                [new THREE.Line(this.CircleGeometry(1, 0.5), this.matLineRed)],
                [new THREE.Mesh(new THREE.OctahedronBufferGeometry(0.04, 0), this.matRed), [0, 0, 0.99], null, [1, 3, 1]],
            ],
            Y: [
                [new THREE.Line(this.CircleGeometry(1, 0.5), this.matLineGreen), null, [0, 0, -Math.PI / 2]],
                [new THREE.Mesh(new THREE.OctahedronBufferGeometry(0.04, 0), this.matGreen), [0, 0, 0.99], null, [3, 1, 1]],
            ],
            Z: [
                [new THREE.Line(this.CircleGeometry(1, 0.5), this.matLineBlue), null, [0, Math.PI / 2, 0]],
                [new THREE.Mesh(new THREE.OctahedronBufferGeometry(0.04, 0), this.matBlue), [0.99, 0, 0], null, [1, 3, 1]],
            ],
            E: [
                [new THREE.Line(this.CircleGeometry(1.25, 1), this.matLineYellowTransparent), null, [0, Math.PI / 2, 0]],
                [new THREE.Mesh(new THREE.CylinderBufferGeometry(0.03, 0, 0.15, 4, 1, false), this.matLineYellowTransparent), [1.17, 0, 0], [0, 0, -Math.PI / 2], [1, 1, 0.001]],
                [new THREE.Mesh(new THREE.CylinderBufferGeometry(0.03, 0, 0.15, 4, 1, false), this.matLineYellowTransparent), [-1.17, 0, 0], [0, 0, Math.PI / 2], [1, 1, 0.001]],
                [new THREE.Mesh(new THREE.CylinderBufferGeometry(0.03, 0, 0.15, 4, 1, false), this.matLineYellowTransparent), [0, -1.17, 0], [Math.PI, 0, 0], [1, 1, 0.001]],
                [new THREE.Mesh(new THREE.CylinderBufferGeometry(0.03, 0, 0.15, 4, 1, false), this.matLineYellowTransparent), [0, 1.17, 0], [0, 0, 0], [1, 1, 0.001]],
            ],
            XYZE: [
                [new THREE.Line(this.CircleGeometry(1, 1), this.matLineGray), null, [0, Math.PI / 2, 0]]
            ]
        };

        this.helperRotate = {
            AXIS: [
                [new THREE.Line(this.lineGeometry, this.matHelper.clone()), [-1e3, 0, 0], null, [1e6, 1, 1], 'helper']
            ]
        };

        this.pickerRotate = {
            X: [
                [new THREE.Mesh(new THREE.TorusBufferGeometry(1, 0.1, 4, 24), this.matInvisible), [0, 0, 0], [0, -Math.PI / 2, -Math.PI / 2]],
            ],
            Y: [
                [new THREE.Mesh(new THREE.TorusBufferGeometry(1, 0.1, 4, 24), this.matInvisible), [0, 0, 0], [Math.PI / 2, 0, 0]],
            ],
            Z: [
                [new THREE.Mesh(new THREE.TorusBufferGeometry(1, 0.1, 4, 24), this.matInvisible), [0, 0, 0], [0, 0, -Math.PI / 2]],
            ],
            E: [
                [new THREE.Mesh(new THREE.TorusBufferGeometry(1.25, 0.1, 2, 24), this.matInvisible)]
            ],
            XYZE: [
                [new THREE.Mesh(new THREE.SphereBufferGeometry(0.7, 10, 8), this.matInvisible)]
            ]
        };

        this.gizmoScale = {
            X: [
                [new THREE.Mesh(this.scaleHandleGeometry, this.matRed), [0.8, 0, 0], [0, 0, -Math.PI / 2]],
                [new THREE.Line(this.lineGeometry, this.matLineRed), null, null, [0.8, 1, 1]]
            ],
            Y: [
                [new THREE.Mesh(this.scaleHandleGeometry, this.matGreen), [0, 0.8, 0]],
                [new THREE.Line(this.lineGeometry, this.matLineGreen), null, [0, 0, Math.PI / 2], [0.8, 1, 1]]
            ],
            Z: [
                [new THREE.Mesh(this.scaleHandleGeometry, this.matBlue), [0, 0, 0.8], [Math.PI / 2, 0, 0]],
                [new THREE.Line(this.lineGeometry, this.matLineBlue), null, [0, -Math.PI / 2, 0], [0.8, 1, 1]]
            ],
            XY: [
                [new THREE.Mesh(this.scaleHandleGeometry, this.matYellowTransparent), [0.85, 0.85, 0], null, [2, 2, 0.2]],
                [new THREE.Line(this.lineGeometry, this.matLineYellow), [0.855, 0.98, 0], null, [0.125, 1, 1]],
                [new THREE.Line(this.lineGeometry, this.matLineYellow), [0.98, 0.855, 0], [0, 0, Math.PI / 2], [0.125, 1, 1]]
            ],
            YZ: [
                [new THREE.Mesh(this.scaleHandleGeometry, this.matCyanTransparent), [0, 0.85, 0.85], null, [0.2, 2, 2]],
                [new THREE.Line(this.lineGeometry, this.matLineCyan), [0, 0.855, 0.98], [0, 0, Math.PI / 2], [0.125, 1, 1]],
                [new THREE.Line(this.lineGeometry, this.matLineCyan), [0, 0.98, 0.855], [0, -Math.PI / 2, 0], [0.125, 1, 1]]
            ],
            XZ: [
                [new THREE.Mesh(this.scaleHandleGeometry, this.matMagentaTransparent), [0.85, 0, 0.85], null, [2, 0.2, 2]],
                [new THREE.Line(this.lineGeometry, this.matLineMagenta), [0.855, 0, 0.98], null, [0.125, 1, 1]],
                [new THREE.Line(this.lineGeometry, this.matLineMagenta), [0.98, 0, 0.855], [0, -Math.PI / 2, 0], [0.125, 1, 1]]
            ],
            XYZX: [
                [new THREE.Mesh(new THREE.BoxBufferGeometry(0.125, 0.125, 0.125), this.matWhiteTransperent), [1.1, 0, 0]],
            ],
            XYZY: [
                [new THREE.Mesh(new THREE.BoxBufferGeometry(0.125, 0.125, 0.125), this.matWhiteTransperent), [0, 1.1, 0]],
            ],
            XYZZ: [
                [new THREE.Mesh(new THREE.BoxBufferGeometry(0.125, 0.125, 0.125), this.matWhiteTransperent), [0, 0, 1.1]],
            ]
        };

        this.pickerScale = {
            X: [
                [new THREE.Mesh(new THREE.CylinderBufferGeometry(0.2, 0, 0.8, 4, 1, false), this.matInvisible), [0.5, 0, 0], [0, 0, -Math.PI / 2]]
            ],
            Y: [
                [new THREE.Mesh(new THREE.CylinderBufferGeometry(0.2, 0, 0.8, 4, 1, false), this.matInvisible), [0, 0.5, 0]]
            ],
            Z: [
                [new THREE.Mesh(new THREE.CylinderBufferGeometry(0.2, 0, 0.8, 4, 1, false), this.matInvisible), [0, 0, 0.5], [Math.PI / 2, 0, 0]]
            ],
            XY: [
                [new THREE.Mesh(this.scaleHandleGeometry, this.matInvisible), [0.85, 0.85, 0], null, [3, 3, 0.2]],
            ],
            YZ: [
                [new THREE.Mesh(this.scaleHandleGeometry, this.matInvisible), [0, 0.85, 0.85], null, [0.2, 3, 3]],
            ],
            XZ: [
                [new THREE.Mesh(this.scaleHandleGeometry, this.matInvisible), [0.85, 0, 0.85], null, [3, 0.2, 3]],
            ],
            XYZX: [
                [new THREE.Mesh(new THREE.BoxBufferGeometry(0.2, 0.2, 0.2), this.matInvisible), [1.1, 0, 0]],
            ],
            XYZY: [
                [new THREE.Mesh(new THREE.BoxBufferGeometry(0.2, 0.2, 0.2), this.matInvisible), [0, 1.1, 0]],
            ],
            XYZZ: [
                [new THREE.Mesh(new THREE.BoxBufferGeometry(0.2, 0.2, 0.2), this.matInvisible), [0, 0, 1.1]],
            ]
        };

        this.helperScale = {
            X: [
                [new THREE.Line(this.lineGeometry, this.matHelper.clone()), [-1e3, 0, 0], null, [1e6, 1, 1], 'helper']
            ],
            Y: [
                [new THREE.Line(this.lineGeometry, this.matHelper.clone()), [0, -1e3, 0], [0, 0, Math.PI / 2], [1e6, 1, 1], 'helper']
            ],
            Z: [
                [new THREE.Line(this.lineGeometry, this.matHelper.clone()), [0, 0, -1e3], [0, -Math.PI / 2, 0], [1e6, 1, 1], 'helper']
            ]
        };

        this.lineGeometry.addAttribute('position', new THREE.Float32BufferAttribute( [ 0, 0, 0,	1, 0, 0 ], 3 ) );

        this.add( this.gizmo[ "translate" ] = this.setupGizmo( this.gizmoTranslate ) );
        this.add( this.gizmo[ "rotate" ] = this.setupGizmo( this.gizmoRotate ) );
        this.add( this.gizmo[ "scale" ] = this.setupGizmo( this.gizmoScale ) );
        this.add( this.picker[ "translate" ] = this.setupGizmo( this.pickerTranslate ) );
        this.add( this.picker[ "rotate" ] = this.setupGizmo( this.pickerRotate ) );
        this.add( this.picker[ "scale" ] = this.setupGizmo( this.pickerScale ) );
        this.add( this.helper[ "translate" ] = this.setupGizmo( this.helperTranslate ) );
        this.add( this.helper[ "rotate" ] = this.setupGizmo( this.helperRotate ) );
        this.add( this.helper[ "scale" ] = this.setupGizmo( this.helperScale ) );

        // Pickers should be hidden always

        this.picker[ "translate" ].visible = false;
        this.picker[ "rotate" ].visible = false;
        this.picker[ "scale" ].visible = false;
    }

    // Creates an Object3D with gizmos described in custom hierarchy definition.

    setupGizmo( gizmoMap ) {

        let gizmo = new THREE.Object3D();

        for ( let name in gizmoMap ) {

            for ( let i = gizmoMap[ name ].length; i --; ) {

                let object = gizmoMap[ name ][ i ][ 0 ].clone();
                let position = gizmoMap[ name ][ i ][ 1 ];
                let rotation = gizmoMap[ name ][ i ][ 2 ];
                let scale = gizmoMap[ name ][ i ][ 3 ];
                let tag = gizmoMap[ name ][ i ][ 4 ];

                // name and tag properties are essential for picking and updating logic.
                object.name = name;
                object.tag = tag;

                if (position) {
                    object.position.set(position[ 0 ], position[ 1 ], position[ 2 ]);
                }
                if (rotation) {
                    object.rotation.set(rotation[ 0 ], rotation[ 1 ], rotation[ 2 ]);
                }
                if (scale) {
                    object.scale.set(scale[ 0 ], scale[ 1 ], scale[ 2 ]);
                }

                object.updateMatrix();

                let tempGeometry = object.geometry.clone();
                tempGeometry.applyMatrix(object.matrix);
                object.geometry = tempGeometry;
                object.renderOrder = Infinity;

                object.position.set( 0, 0, 0 );
                object.rotation.set( 0, 0, 0 );
                object.scale.set(1, 1, 1);

                gizmo.add(object);

            }

        }

        return gizmo;

    };

    // Reusable utility variables

    private tempVector = new THREE.Vector3( 0, 0, 0 );
    private tempEuler = new THREE.Euler();
    private alignVector = new THREE.Vector3( 0, 1, 0 );
    private zeroVector = new THREE.Vector3( 0, 0, 0 );
    private lookAtMatrix = new THREE.Matrix4();
    private tempQuaternion = new THREE.Quaternion();
    private tempQuaternion2 = new THREE.Quaternion();
    private identityQuaternion = new THREE.Quaternion();

    private unitX = new THREE.Vector3( 1, 0, 0 );
    private unitY = new THREE.Vector3( 0, 1, 0 );
    private unitZ = new THREE.Vector3( 0, 0, 1 );

    // Gizmo creation

    gizmo = {};
    picker = {};
    helper = {};




    // updateMatrixWorld will update transformations and appearance of individual handles

    updateMatrixWorld() {

        let space = this.controls.space;

        if ( this.controls.mode === 'scale' ) space = 'local'; // scale always oriented to local rotation

        let quaternion = space === "local" ? this.controls.worldQuaternion : this.identityQuaternion;

        // Show only gizmos for current transform mode

        this.gizmo[ "translate" ].visible = this.controls.mode === "translate";
        this.gizmo[ "rotate" ].visible = this.controls.mode === "rotate";
        this.gizmo[ "scale" ].visible = this.controls.mode === "scale";

        this.helper[ "translate" ].visible = this.controls.mode === "translate";
        this.helper[ "rotate" ].visible = this.controls.mode === "rotate";
        this.helper[ "scale" ].visible = this.controls.mode === "scale";


        let handles = [];
        handles = handles.concat( this.picker[ this.controls.mode ].children );
        handles = handles.concat( this.gizmo[ this.controls.mode ].children );
        handles = handles.concat( this.helper[ this.controls.mode ].children );

        for ( let i = 0; i < handles.length; i++ ) {

            let handle = handles[i];

            // hide aligned to camera

            handle.visible = true;
            handle.rotation.set( 0, 0, 0 );
            handle.position.copy( this.controls.worldPosition );

            let eyeDistance = this.controls.worldPosition.distanceTo( this.controls.cameraPosition);
            handle.scale.set( 1, 1, 1 ).multiplyScalar( eyeDistance * this.controls.size / 7 );

            // TODO: simplify helpers and consider decoupling from gizmo

            if ( handle.tag === 'helper' ) {

                handle.visible = false;

                if ( handle.name === 'AXIS' ) {

                    handle.position.copy( this.controls.worldPositionStart );
                    handle.visible = !!this.controls.axis;

                    if ( this.controls.axis === 'X' ) {

                        this.tempQuaternion.setFromEuler( this.tempEuler.set( 0, 0, 0 ) );
                        handle.quaternion.copy( quaternion ).multiply( this.tempQuaternion );

                        if ( Math.abs( this.alignVector.copy( this.unitX ).applyQuaternion( quaternion ).dot( this.controls.eye ) ) > 0.9 ) {
                            handle.visible = false;
                        }

                    }

                    if ( this.controls.axis === 'Y' ) {

                        this.tempQuaternion.setFromEuler( this.tempEuler.set( 0, 0, Math.PI / 2 ) );
                        handle.quaternion.copy( quaternion ).multiply( this.tempQuaternion );

                        if ( Math.abs( this.alignVector.copy( this.unitY ).applyQuaternion( quaternion ).dot( this.controls.eye ) ) > 0.9 ) {
                            handle.visible = false;
                        }

                    }

                    if ( this.controls.axis === 'Z' ) {

                        this.tempQuaternion.setFromEuler( this.tempEuler.set( 0, Math.PI / 2, 0 ) );
                        handle.quaternion.copy( quaternion ).multiply( this.tempQuaternion );

                        if ( Math.abs( this.alignVector.copy( this.unitZ ).applyQuaternion( quaternion ).dot( this.controls.eye ) ) > 0.9 ) {
                            handle.visible = false;
                        }

                    }

                    if ( this.controls.axis === 'XYZE' ) {

                        this.tempQuaternion.setFromEuler( this.tempEuler.set( 0, Math.PI / 2, 0 ) );
                        this.alignVector.copy( this.controls.rotationAxis );
                        handle.quaternion.setFromRotationMatrix( this.lookAtMatrix.lookAt( this.zeroVector, this.alignVector, this.unitY ) );
                        handle.quaternion.multiply( this.tempQuaternion );
                        handle.visible = this.controls.dragging;

                    }

                    if ( this.controls.axis === 'E' ) {

                        handle.visible = false;

                    }


                } else if ( handle.name === 'START' ) {

                    handle.position.copy( this.controls.worldPositionStart );
                    handle.visible = this.controls.dragging;

                } else if ( handle.name === 'END' ) {

                    handle.position.copy( this.controls.worldPosition );
                    handle.visible = this.controls.dragging;

                } else if ( handle.name === 'DELTA' ) {

                    handle.position.copy( this.controls.worldPositionStart );
                    handle.quaternion.copy( this.controls.worldQuaternionStart );
                    this.tempVector.set( 1e-10, 1e-10, 1e-10 ).add( this.controls.worldPositionStart ).sub( this.controls.worldPosition ).multiplyScalar( -1 );
                    this.tempVector.applyQuaternion( this.controls.worldQuaternionStart.clone().inverse() );
                    handle.scale.copy( this.tempVector );
                    handle.visible = this.controls.dragging;

                } else {

                    handle.quaternion.copy( quaternion );

                    if ( this.controls.dragging ) {

                        handle.position.copy( this.controls.worldPositionStart );

                    } else {

                        handle.position.copy( this.controls.worldPosition );

                    }

                    if ( this.controls.axis ) {

                        handle.visible = this.controls.axis.search( handle.name ) !== -1;

                    }

                }

                // If updating helper, skip rest of the loop
                continue;

            }

            // Align handles to current local or world rotation

            handle.quaternion.copy( quaternion );

            if ( this.controls.mode === 'translate' || this.controls.mode === 'scale' ) {

                // Hide translate and scale axis facing the camera

                let AXIS_HIDE_TRESHOLD = 0.99;
                let PLANE_HIDE_TRESHOLD = 0.2;
                let AXIS_FLIP_TRESHOLD = 0.0;


                if ( handle.name === 'X' || handle.name === 'XYZX' ) {
                    if ( Math.abs( this.alignVector.copy( this.unitX ).applyQuaternion( quaternion ).dot( this.controls.eye ) ) > AXIS_HIDE_TRESHOLD ) {
                        handle.scale.set( 1e-10, 1e-10, 1e-10 );
                        handle.visible = false;
                    }
                }
                if ( handle.name === 'Y' || handle.name === 'XYZY' ) {
                    if ( Math.abs( this.alignVector.copy( this.unitY ).applyQuaternion( quaternion ).dot( this.controls.eye ) ) > AXIS_HIDE_TRESHOLD ) {
                        handle.scale.set( 1e-10, 1e-10, 1e-10 );
                        handle.visible = false;
                    }
                }
                if ( handle.name === 'Z' || handle.name === 'XYZZ' ) {
                    if ( Math.abs( this.alignVector.copy( this.unitZ ).applyQuaternion( quaternion ).dot( this.controls.eye ) ) > AXIS_HIDE_TRESHOLD ) {
                        handle.scale.set( 1e-10, 1e-10, 1e-10 );
                        handle.visible = false;
                    }
                }
                if ( handle.name === 'XY' ) {
                    if ( Math.abs( this.alignVector.copy( this.unitZ ).applyQuaternion( quaternion ).dot( this.controls.eye ) ) < PLANE_HIDE_TRESHOLD ) {
                        handle.scale.set( 1e-10, 1e-10, 1e-10 );
                        handle.visible = false;
                    }
                }
                if ( handle.name === 'YZ' ) {
                    if ( Math.abs( this.alignVector.copy( this.unitX ).applyQuaternion( quaternion ).dot( this.controls.eye ) ) < PLANE_HIDE_TRESHOLD ) {
                        handle.scale.set( 1e-10, 1e-10, 1e-10 );
                        handle.visible = false;
                    }
                }
                if ( handle.name === 'XZ' ) {
                    if ( Math.abs( this.alignVector.copy( this.unitY ).applyQuaternion( quaternion ).dot( this.controls.eye ) ) < PLANE_HIDE_TRESHOLD ) {
                        handle.scale.set( 1e-10, 1e-10, 1e-10 );
                        handle.visible = false;
                    }
                }

                // Flip translate and scale axis ocluded behind another axis

                if ( handle.name.search( 'X' ) !== -1 ) {
                    if ( this.alignVector.copy( this.unitX ).applyQuaternion( quaternion ).dot( this.controls.eye ) < AXIS_FLIP_TRESHOLD ) {
                        if ( handle.tag === 'fwd' ) {
                            handle.visible = false;
                        } else {
                            handle.scale.x *= -1;
                        }
                    } else if ( handle.tag === 'bwd' ) {
                        handle.visible = false;
                    }
                }

                if ( handle.name.search( 'Y' ) !== -1 ) {
                    if ( this.alignVector.copy( this.unitY ).applyQuaternion( quaternion ).dot( this.controls.eye ) < AXIS_FLIP_TRESHOLD ) {
                        if ( handle.tag === 'fwd' ) {
                            handle.visible = false;
                        } else {
                            handle.scale.y *= -1;
                        }
                    } else if ( handle.tag === 'bwd' ) {
                        handle.visible = false;
                    }
                }

                if ( handle.name.search( 'Z' ) !== -1 ) {
                    if ( this.alignVector.copy( this.unitZ ).applyQuaternion( quaternion ).dot( this.controls.eye ) < AXIS_FLIP_TRESHOLD ) {
                        if ( handle.tag === 'fwd' ) {
                            handle.visible = false;
                        } else {
                            handle.scale.z *= -1;
                        }
                    } else if ( handle.tag === 'bwd' ) {
                        handle.visible = false;
                    }
                }

            } else if ( this.controls.mode === 'rotate' ) {

                // Align handles to current local or world rotation

                this.tempQuaternion2.copy( quaternion );
                this.alignVector.copy( this.controls.eye ).applyQuaternion( this.tempQuaternion.copy( quaternion ).inverse() );

                if ( handle.name.search( "E" ) !== - 1 ) {

                    handle.quaternion.setFromRotationMatrix( this.lookAtMatrix.lookAt( this.controls.eye, this.zeroVector, this.unitY ) );

                }

                if ( handle.name === 'X' ) {

                    this.tempQuaternion.setFromAxisAngle( this.unitX, Math.atan2( -this.alignVector.y, this.alignVector.z ) );
                    this.tempQuaternion.multiplyQuaternions( this.tempQuaternion2, this.tempQuaternion );
                    handle.quaternion.copy( this.tempQuaternion );

                }

                if ( handle.name === 'Y' ) {

                    this.tempQuaternion.setFromAxisAngle( this.unitY, Math.atan2( this.alignVector.x, this.alignVector.z ) );
                    this.tempQuaternion.multiplyQuaternions( this.tempQuaternion2, this.tempQuaternion );
                    handle.quaternion.copy( this.tempQuaternion );

                }

                if ( handle.name === 'Z' ) {

                    this.tempQuaternion.setFromAxisAngle( this.unitZ, Math.atan2( this.alignVector.y, this.alignVector.x ) );
                    this.tempQuaternion.multiplyQuaternions( this.tempQuaternion2, this.tempQuaternion );
                    handle.quaternion.copy( this.tempQuaternion );

                }

            }

            // Hide disabled axes
            handle.visible = handle.visible && ( handle.name.indexOf( "X" ) === -1 || this.controls.showX );
            handle.visible = handle.visible && ( handle.name.indexOf( "Y" ) === -1 || this.controls.showY );
            handle.visible = handle.visible && ( handle.name.indexOf( "Z" ) === -1 || this.controls.showZ );
            handle.visible = handle.visible && ( handle.name.indexOf( "E" ) === -1 || ( this.controls.showX && this.controls.showY && this.controls.showZ ) );

            // highlight selected axis

            handle.material._opacity = handle.material._opacity || handle.material.opacity;
            handle.material._color = handle.material._color || handle.material.color.clone();

            handle.material.color.copy( handle.material._color );
            handle.material.opacity = handle.material._opacity;

            if ( !this.controls.enabled ) {

                handle.material.opacity *= 0.5;
                handle.material.color.lerp( new THREE.Color( 1, 1, 1 ), 0.5 );

            } else if ( this.controls.axis ) {

                if ( handle.name === this.controls.axis ) {

                    handle.material.opacity = 1.0;
                    handle.material.color.lerp( new THREE.Color( 1, 1, 1 ), 0.5 );

                } else if ( this.controls.axis.split('').some( function( a ) { return handle.name === a; } ) ) {

                    handle.material.opacity = 1.0;
                    handle.material.color.lerp( new THREE.Color( 1, 1, 1 ), 0.5 );

                } else {

                    handle.material.opacity *= 0.25;
                    handle.material.color.lerp( new THREE.Color( 1, 1, 1 ), 0.5 );

                }

            }

        }

        super.updateMatrixWorld(true)

    };

};

export class TransformControlsPlane extends THREE.Mesh {

    constructor(private controls:TransformControls) {
        super(new THREE.PlaneBufferGeometry( 100000, 100000, 2, 2 ),
            new THREE.MeshBasicMaterial( { visible: false, wireframe: true, side: THREE.DoubleSide, transparent: true, opacity: 0.1 } ))
    }


    type = 'TransformControlsPlane';

    unitX = new THREE.Vector3( 1, 0, 0 );
    unitY = new THREE.Vector3( 0, 1, 0 );
    unitZ = new THREE.Vector3( 0, 0, 1 );

    tempVector = new THREE.Vector3();
    dirVector = new THREE.Vector3();
    alignVector = new THREE.Vector3();
    tempMatrix = new THREE.Matrix4();
    identityQuaternion = new THREE.Quaternion();

    updateMatrixWorld(force:boolean) {

        let space = this.controls.space;
        this.position.copy( this.controls.worldPosition );
        if ( this.controls.mode === 'scale' ) space = 'local'; // scale always oriented to local rotation

        this.unitX.set( 1, 0, 0 ).applyQuaternion( space === "local" ? this.controls.worldQuaternion : this.identityQuaternion );
        this.unitY.set( 0, 1, 0 ).applyQuaternion( space === "local" ? this.controls.worldQuaternion : this.identityQuaternion );
        this.unitZ.set( 0, 0, 1 ).applyQuaternion( space === "local" ? this.controls.worldQuaternion : this.identityQuaternion );

        // Align the plane for current transform mode, axis and space.

        this.alignVector.copy( this.unitY );

        switch ( this.controls.mode ) {
            case 'translate':
            case 'scale':
                switch ( this.controls.axis ) {
                    case 'X':
                        this.alignVector.copy( this.controls.eye ).cross( this.unitX );
                        this.dirVector.copy( this.unitX ).cross( this.alignVector );
                        break;
                    case 'Y':
                        this.alignVector.copy( this.controls.eye ).cross( this.unitY );
                        this.dirVector.copy( this.unitY ).cross( this.alignVector );
                        break;
                    case 'Z':
                        this.alignVector.copy( this.controls.eye ).cross( this.unitZ );
                        this.dirVector.copy( this.unitZ ).cross( this.alignVector );
                        break;
                    case 'XY':
                        this.dirVector.copy( this.unitZ );
                        break;
                    case 'YZ':
                        this.dirVector.copy( this.unitX );
                        break;
                    case 'XZ':
                        this.alignVector.copy( this.unitZ );
                        this.dirVector.copy( this.unitY );
                        break;
                    case 'XYZ':
                    case 'E':
                        this.dirVector.set( 0, 0, 0 );
                        break;
                }
                break;
            case 'rotate':
            default:
                // special case for rotate
                this.dirVector.set( 0, 0, 0 );
        }

        if ( this.dirVector.length() === 0 ) {

            // If in rotate mode, make the plane parallel to camera
            this.quaternion.copy( this.controls.cameraQuaternion );

        } else {

            this.tempMatrix.lookAt( this.tempVector.set( 0, 0, 0 ), this.dirVector, this.alignVector );

            this.quaternion.setFromRotationMatrix( this.tempMatrix );

        }

        super.updateMatrixWorld(force)

    };

}