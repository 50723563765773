export enum ActionMode {
    CREATE,
    MODIFY,
    DELETE,
    SCALE,
    MEASURE,
    INTERSECT,
    ERASE,
    ALIGN,
    REGISTER,
    SELECT,
    INSPECT
}