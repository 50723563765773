import {ProjectImageListFilterType} from "./projectImageCollectionFilterType.enum";
import {ProjectImageCollectionFilterModel} from "./projectImageCollectionFilter.model";
import {CameraPointView} from "../cameras/cameraPointView";

export class ProjectImageCollectionFilterCameraPointViewModel extends ProjectImageCollectionFilterModel {

    private _cameraPointViews:CameraPointView[] = [];

    constructor() {
        super(ProjectImageListFilterType.PointVisibility);
        this.filterFunction = this.filterCameraPointView.bind(this);
    }

    filterCameraPointView(projectImageModel) {
        if (!projectImageModel) return false;

        let indexOfFrameId = this.cameraPointViews.findIndex((cameraPointView) => {
            if(!cameraPointView) return false;
            let cameraView = cameraPointView.cameraView;
            if(!cameraView) return false;
            let frameId = cameraView.frameId;
            if(typeof frameId === 'undefined' || frameId === null) return false;
            return projectImageModel.frameId == frameId;
        });

        return indexOfFrameId > -1;
    }

    get cameraPointViews(): CameraPointView[] {
        return this._cameraPointViews;
    }

    set cameraPointViews(value: CameraPointView[]) {
        this._cameraPointViews = value;
        this.triggerFilterChanged();
    }

    get filterName(): string {
        return "containing 3d point";
    }

}