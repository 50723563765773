import {EventEmitter, Injectable} from '@angular/core';
import {WireframeApplication} from "../../application/wireframeApplication";
import {PropertyManager} from "../../models/property/propertyManager";
import WireframeElementCollection from "../../collections/wireframeElements/wireframeElement.collection";
import {Property} from "../../models/property/property";
import {Subscription} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class PropertyService {

    propertiesChangedSubscription:Subscription
    propertiesChanged: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        public wireframeApplication: WireframeApplication
    ) {
        let that = this
        this.propertiesChangedSubscription = this.wireframeApplication.eventEmitter.on("propertiesChanged").subscribe(() => that.onPropertiesChanged())
    }

    get propertyManager(): PropertyManager {
        if (!this.wireframeApplication) return null;
        return this.wireframeApplication.propertyManager;
    }

    onPropertiesChanged() {
        this.triggerPropertiesChanged();
    }

    triggerPropertiesChanged() {
        this.propertiesChanged.emit();
    }

    getPropertiesByType(propertyType: String): Property[] {
        return this.propertyManager.getPropertiesByType(propertyType);
    }

    addProperty(property: Property, selectedElements: WireframeElementCollection) {
       this.propertyManager.addProperty(property, selectedElements);
    }

    updateProperty(property: Property, wireframeElements: WireframeElementCollection, value: any) {
        this.propertyManager.updateProperty(property, wireframeElements, value);
    }

    deleteProperty(property: Property, wireframeElements: WireframeElementCollection) {
        this.propertyManager.deleteProperty(property, wireframeElements);
    }
}