import {ProjectImageListFilterType} from "./projectImageCollectionFilterType.enum";
import {ProjectImageCollectionFilterModel} from "./projectImageCollectionFilter.model";

export class ProjectImageCollectionFilterMetadataModel extends ProjectImageCollectionFilterModel {

    private _metadataValue:string = '';

    constructor() {
        super(ProjectImageListFilterType.Metadata);
        this.filterFunction = this.filterMetadata.bind(this);
    }

    filterMetadata(projectImageModel){
        if(!projectImageModel) return false;
        return  projectImageModel.metadata
            ? JSON.stringify(projectImageModel.metadata).indexOf(this.metadataValue) >= 0
            : false;
    }

    get metadataValue(): string {
        return this._metadataValue;
    }

    set metadataValue(value){
        this._metadataValue = value;
        this.triggerFilterChanged();
    }

    get filterName(): string {
        return "metadata contains " + this.metadataValue;
    }

}