import * as THREE from 'three';

export default class TextSprite extends THREE.Object3D {
    texture = new THREE.Texture();
    spriteMaterial:THREE.SpriteMaterial
    material:THREE.SpriteMaterial
    sprite:THREE.Sprite
    text:string
    borderThickness = 4;
    fontface = "Arial";
    fontsize = 28;
    borderColor = { r:0, g:0, b:0, a:1.0 };
    backgroundColor = { r:255, g:255, b:255, a:1.0 };
    textColor = {r: 255, g: 255, b: 255, a: 1.0};

    constructor(text:string = null) {
        super()
        var spriteMaterial = new THREE.SpriteMaterial( {
            map: this.texture,
            //useScreenCoordinates: false,
            transparent: true,
            depthTest: false,
            depthWrite: false} );

        this.material = spriteMaterial;
        this.sprite = new THREE.Sprite(spriteMaterial);
        this.add(this.sprite);

        this.setText(text)
    }

    setText(text){
        if(this.text !== text){
            this.text = text;

            this.update();
        }
    }

    setTextColor(color) {
        this.textColor = color;
        this.update();
    }

    //THREE.Sprite.call(this, spriteMaterial);

    setBorderColor(color){
        this.borderColor = color;
        this.update();
    }

    setBackgroundColor(color){
        this.backgroundColor = color;
        this.update();
    }

    update(){

        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        context.font = "Bold " + this.fontsize + "px " + this.fontface;

        var lines = this.text ? String(this.text).split('\n') : [];
        var y = this.fontsize + this.borderThickness;
        var lineHeight = this.fontsize * 1.5;

        var textWidth = 0;
        for (var i = 0; i < lines.length; i++) {
            var metrics = context.measureText( lines[i] );
            if (metrics.width > textWidth) textWidth = metrics.width;
        }

        // get size data (height depends only on font size)
        var margin = 5;
        var spriteWidth = 2*margin + textWidth + 2 * this.borderThickness;
        var spriteHeight = (this.fontsize * 1.4 * lines.length) + 2 * this.borderThickness;

        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        context.canvas.width = spriteWidth;
        context.canvas.height = spriteHeight;
        context.font = "Bold " + this.fontsize + "px " + this.fontface;

        // background color
        context.fillStyle   = "rgba(" + this.backgroundColor.r + "," + this.backgroundColor.g + ","
            + this.backgroundColor.b + "," + this.backgroundColor.a + ")";
        // border color
        context.strokeStyle = "rgba(" + this.borderColor.r + "," + this.borderColor.g + ","
            + this.borderColor.b + "," + this.borderColor.a + ")";

        context.lineWidth = this.borderThickness;
        this.roundRect(context, this.borderThickness/2, this.borderThickness/2,
            textWidth + this.borderThickness + 2*margin, (lines.length * this.fontsize * 1.4) + this.borderThickness, 6);

        // text color
        context.strokeStyle = "rgba(0, 0, 0, 1.0)";
        for (var i = 0; i<lines.length; i++)
            context.strokeText( lines[i], this.borderThickness + margin, y + (i*lineHeight));
        //context.strokeText( this.text, this.borderThickness + margin, this.fontsize + this.borderThickness);

        context.fillStyle = "rgba(" + this.textColor.r + "," + this.textColor.g + ","
            + this.textColor.b + "," + this.textColor.a + ")";


        for (var i = 0; i<lines.length; i++)
            context.fillText(lines[i], this.borderThickness + margin, y + (i*lineHeight) );
        //context.fillText( this.text, this.borderThickness + margin, this.fontsize + this.borderThickness);


        var texture = new THREE.Texture(canvas);
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.needsUpdate = true;

        //var spriteMaterial = new THREE.SpriteMaterial(
        //	{ map: texture, useScreenCoordinates: false } );
        (this.sprite.material as THREE.SpriteMaterial).map = texture;

        this.sprite.scale.set(spriteWidth*0.01,spriteHeight*0.01,1.0);

        //this.material = spriteMaterial;
    }

    roundRect(ctx, x, y, w, h, r) {
        ctx.beginPath();
        ctx.moveTo(x+r, y);
        ctx.lineTo(x+w-r, y);
        ctx.quadraticCurveTo(x+w, y, x+w, y+r);
        ctx.lineTo(x+w, y+h-r);
        ctx.quadraticCurveTo(x+w, y+h, x+w-r, y+h);
        ctx.lineTo(x+r, y+h);
        ctx.quadraticCurveTo(x, y+h, x, y+h-r);
        ctx.lineTo(x, y+r);
        ctx.quadraticCurveTo(x, y, x+r, y);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
    };

}
