import {InteractiveFilterModel} from "../interactive/interactiveFilter.model";
import {VertexElementModel} from "../vertexElements/vertexElement.model";
import {VertexFilterTypes} from "./vertexFilterTypes.enum";

export class VertexFilterSeenModel extends InteractiveFilterModel<VertexElementModel> {

    private _seenValue: boolean = false;

    constructor() {
        super(VertexFilterTypes.VertexSeen);
        this.filterFunction = this.filterSeen.bind(this);
    }

    filterSeen(vertexElementModel:VertexElementModel) {
        if (!vertexElementModel) return false;
        return vertexElementModel.seen === this.seenValue;
    }

    get seenValue(): boolean {
        return this._seenValue;
    }

    set seenValue(value:boolean) {
        this._seenValue = value;
        this.triggerFilterChanged();
    }

    get filterName(): string {
        return "seen is " + this.seenValue;
    }

    get filterLabel(): string {
        let seenLabel = this.seenValue ? "seen" : "unseen";
        return "vertex is " + seenLabel;
    }

    get filterEditLabel(): string {
        return "vertex is ";
    }

    get filterSymbol():string {
        return "remove_red_eye";
    }

}