import {InteractiveModel} from "../interactive/interactive.model";

export interface User {
    userId:number;
    customerId:number;
    firstName:string;
    lastName:string;
    email: string;
    token:string;
    legacyToken: string;
}

export class UserModel extends InteractiveModel implements User {
    userId:number;
    customerId:number;
    firstName:string;
    lastName:string;
    email: string;
    token:string;
    legacyToken: string;

    private _projectAuthoration:any = {};

    constructor() {
        super();
    }

    hasProjectAuthorization(projectId:string):boolean{
        return this.getProjectAuthorization(projectId) !== null;
    }

    addProjectAuthorization(projectId:string, projectToken:string, projectCredentials:any){
        this._projectAuthoration[projectId] = {
            projectToken: projectToken,
            projectCredentials: projectCredentials
        }
    }

    getProjectAuthorization(projectId:string){
        let projectAuthorization = null;
        let projectAuthorizationRecord = this._projectAuthoration[projectId];
        if(!projectAuthorizationRecord) return projectAuthorization;
        projectAuthorization = projectAuthorizationRecord;
        return projectAuthorization;
    }

    getProjectToken(projectId:string){
        let projectToken = null;
        let projectAuthorization = this.getProjectAuthorization(projectId);
        if(!projectAuthorization) return projectToken;
        return projectAuthorization.projectToken;
    }

    getProjectCredentials(projectId:string){
        let projectCredentials = null;
        let projectAuthorization = this.getProjectAuthorization(projectId);
        if(!projectAuthorization) return projectCredentials;
        return projectAuthorization.projectCredentials;
    }
}