import {RaycastHit} from "./raycastHandler";
import {WireframeElement} from "./wireframeElement";
import RaycastHandler from "./raycastHandler";
import {Face3, Object3D, Raycaster, Vector2, Vector3, WebGLRenderer} from "three";
import WireframeLayer from "./wireframeLayer";
import PointCloudLayer from "./pointCloudLayer";
import {PointCloudOctree} from "@pix4d/three-potree-loader";
import {SceneService} from "../services/scenes/scene.service";

export interface PointCloudPoint {
    classification:number
    color:number[]
    normal:number[]
    position:THREE.Vector3
}

export class PointCloudRaycastHit extends RaycastHit {
    point:PointCloudPoint
}

export class PointCloudRaycastHandler extends RaycastHandler {

    constructor(private layer:PointCloudLayer, private sceneService:SceneService, private renderer:WebGLRenderer) {
        super()
    }

    handleRaycast(raycaster: Raycaster): RaycastHit[] {
        let results: PointCloudRaycastHit[] = []
        let windowSize = 17
        let pco = this.layer.object.children[0] as PointCloudOctree
        let point = pco.pick(this.renderer, this.sceneService.potreeCamera, raycaster.ray, {
            pickOutsideClipRegion: true,
            pickWindowSize: windowSize,
            // mouse: { x: this.mouse.x, y: this.mouse.y }
        }) as PointCloudPoint
        if (point) {
            let hit = new PointCloudRaycastHit()
            results.push(hit)
            hit.layer = this.layer
            hit.point = point
            hit.intersect = new class implements THREE.Intersection {
                distance: number = raycaster.ray.origin.distanceTo(point.position);
                distanceToRay: number;
                face: Face3 | null;
                faceIndex: number;
                index: number;
                object: Object3D = pco;
                point: Vector3 = point.position;
                uv: Vector2;
            }
        }
        return results;
    }

}