import {WireframeElementType} from "../../application/wireframeElementType";
import {ElementReference} from "./elementReference";
import {WireframeElement} from "../../application/wireframeElement";
import {Property} from "./property";
import {WireframeApplication} from "../../application/wireframeApplication";
import {ElementPickOperation} from "../../application/elementPickOperation";
import {PropertyValue} from "./propertyValue";
import WireframeLayer from "../../application/wireframeLayer";

export class ElementReferenceProperty extends Property {
    selectableElementTypes: WireframeElementType[] = [];
    private pickOperation: ElementPickOperation;

    constructor() {
        super();
        this.type = ".ElementReferenceProperty";
        this.name = "Element Reference";
    }

    getDefaultValue() {
        return new ElementReference();
    }

    static getElement(layer:WireframeLayer, elref: ElementReference): WireframeElement {
        let elements = ElementReferenceProperty.getElements(layer, elref)
        if (elements.length > 0) return elements[0]
        return null
    }

    static getElements(layer:WireframeLayer, elref: ElementReference): WireframeElement[] {
        let elements: WireframeElement[] = [];
        if (null == elref) return elements;
        for (let pvType in elref.elements) {
            let elementIds = elref.elements[pvType];
            for (let i = 0; i < elementIds.length; i++) {
                let el = layer.findWireframeElement(Number(pvType), elementIds[i]);
                if (el) {
                    elements.push(el);
                }
            }
        }
        return elements;
    }

    getPickOperation(val: any) {
        let po = new ElementPickOperation();
        po.message = "Please select an Element, or Escape  to cancel";
        let that = this;
        po.onElementPicked.push(function (el:WireframeElement) {
            po.currentValue = that.getDefaultValue();
            po.currentValue.addElement(el.pvObject)
            if (null != this.id) that.setValue(el, po.currentValue);
        });
        this.customizePickOperation(po);
        return po;
    }

    customizePickOperation(po: ElementPickOperation) {
    }

    fromJson(obj, allProperties): void {
        super.fromJson(obj, allProperties);
        this.selectableElementTypes = [];
        for (let elType of obj["selectableElementTypes"]) {
            this.selectableElementTypes.push(elType);
        }
    }

    startElementSelection(app:WireframeApplication, propertyValue:PropertyValue, onComplete:()=>void) {
        let self = this;
        if (this.pickOperation) {
            app.endElementPick();
            this.pickOperation = null;
        }
        if (app.currentPickOperation) {
            app.endElementPick();
        }
        this.pickOperation = this.getPickOperation(propertyValue.value);
        this.pickOperation.onElementPicked.push(function (el:WireframeElement) {
            if (null != el) {
                propertyValue.value = self.pickOperation.currentValue;
            }
            if (null == el) {
                propertyValue.value = {};
                app.endElementPick();
            }
        });
        this.pickOperation.onComplete.push(function () {
            self.pickOperation = null;
            if(onComplete)onComplete();
        });
        app.startElementPick(this.pickOperation);
    }

    valueFromJson(json: any): any {
        let val = new ElementReference()
        Object.assign(val, json)
        return val;
    }
}