import {EventEmitter, Injectable} from '@angular/core';
import {WireframeApplication} from "../../application/wireframeApplication";
import {ProjectImageModel} from "../../models/projectImages/projectImage.model";
import {CameraView} from "../../models/cameras/cameraView";
import {CameraPointView} from "../../models/cameras/cameraPointView";
import {WireframeElement} from "../../application/wireframeElement";
import {WireframeElementType} from "../../application/wireframeElementType";
import {CameraElement} from "../../application/cameraElement";
import * as THREE from 'three';
import {SceneViewer} from "../../sceneViewer";
import {PointCloudOctree} from "@pix4d/three-potree-loader";
import * as Cesium from 'cesium/Source/Cesium';
import WireframeLayer from "../../application/wireframeLayer";
import SceneManager from "../../application/sceneManager";
import {MeasurementService} from "../measurement/measurement.service";
import {PV} from "../../wireframe";
import Wireframe = PV.Wireframe;
import {Subscription} from "rxjs";
import {ProjectModel} from "../../models/projects/project.model";
import {Resource} from "../../resourceManager";
import {ApiService} from "../api/api.service";


@Injectable({
    providedIn: 'root',
})
export class SceneService {
    public scenePointInspected = new EventEmitter<THREE.Vector3>();
    public sceneCameraFocused = new EventEmitter<CameraView>();
    public sceneCameraHighlighted = new EventEmitter<CameraView>();
    public wireframeLoaded = new EventEmitter<null>();
    public wireframeChanged = new EventEmitter<Wireframe>();
    public wireframeElementSelected = new EventEmitter<Array<WireframeElement>>();
    public wireframeElementFocused = new EventEmitter<WireframeElement>();
    public wireframeElementHighlighted = new EventEmitter<WireframeElement>();
    public sceneVisibleCameraPointViewsChanged = new EventEmitter<CameraPointView[]>();
    private cesiumTerrainProvider:any;
    private _wireframeApplication:WireframeApplication;
    private _projectModel:ProjectModel;

    pointInspectedSubscription:Subscription;
    highlightWireframeElementSubscription:Subscription;
    selectWireframeElementSubscription:Subscription;
    focusWireframeElementSubscription:Subscription;
    wireframeChangedSubscription:Subscription;

    constructor(
        public apiService:ApiService,
        private _measurementService:MeasurementService
    ) {
        this._wireframeApplication = new WireframeApplication();
        this._wireframeApplication.sceneService = this;
        this.bindWireframeApplicationEvents();

        try {
            Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyZmZiOWI4OC1jOWRhLTQ3YTgtYTE3YS03MjI2Y2UwMjg4NGQiLCJpZCI6MzY3Miwic2NvcGVzIjpbImFzciIsImdjIl0sImlhdCI6MTUzODQ3ODIyOH0.itq-7lqIiGP-cwowVsGHUngbhgcfcecSmSUOe1U2tzg';
            this.cesiumTerrainProvider = new Cesium.createWorldTerrain()
        }catch(ex){
            console.error(ex);
        }
    }

    set projectModel(projectModel) {
        this._projectModel = projectModel
    }

    get projectModel():ProjectModel {
        return this._projectModel
    }

    public initScene() {
        this._wireframeApplication.useAppFramework = true;
        this._wireframeApplication.sceneService = this;
        this._wireframeApplication.apiService = this.apiService;
        this._wireframeApplication.apiService.projectModel = this.projectModel;
        this._wireframeApplication.projectData = this.projectModel.projectData;
        this._wireframeApplication.projectId = this.projectModel.projectId;
        this._wireframeApplication.resourceManager.projectData = this.projectModel.projectData;
        this._wireframeApplication.resourceManager.s3Bucket = this.projectModel.resourceManager.s3Bucket;
        this._wireframeApplication.resourceManager.projectKey = this.projectModel.resourceManager.projectKey;
        this._wireframeApplication.init()
    }

    public bindWireframeApplicationEvents() {
        let that = this;
        this.pointInspectedSubscription = this.wireframeApplication.eventEmitter.on("pointInspected").subscribe((e:THREE.Vector3) => that.onScenePointInspected(e));
        this.highlightWireframeElementSubscription = this.wireframeApplication.eventEmitter.on("highlightWireframeElement").subscribe((e:WireframeElement) => that.onSceneWireframeElementHighlighted(e));
        this.selectWireframeElementSubscription = this.wireframeApplication.eventEmitter.on("selectWireframeElement").subscribe((e:WireframeElement[]) => that.onSceneWireframeElementSelected(e));
        this.focusWireframeElementSubscription = this.wireframeApplication.eventEmitter.on("focusWireframeElement").subscribe((e:WireframeElement) => that.onSceneWireframeElementFocused(e));
        this.wireframeChangedSubscription = this.wireframeApplication.eventEmitter.on("wireframeChanged").subscribe((e) => that.onSceneWireframeChanged())
    }

    public get wireframeApplication():WireframeApplication {
        return this._wireframeApplication
    }

    public get measurementService():MeasurementService {
        return this._measurementService
    }

    public get wireframeLayer():WireframeLayer {
        if (!this.wireframeApplication) return null;
        return this.wireframeApplication.wireframeLayer
    }

    public get sceneManager():SceneManager {
        if (!this.wireframeApplication) return null;
        return this.wireframeApplication.sceneManager
    }

    private get potreeViewer(): SceneViewer {
        if (!this.wireframeApplication) return null;
        if (!this.wireframeApplication.viewer) return null;
        return this.wireframeApplication.viewer;
    }

    public get potreeCamera(): any {
        if (!this.potreeViewer) return null;
        if (!this.potreeViewer.camera) return null;
        return this.potreeViewer.camera;
    }

    private get pointClouds(): PointCloudOctree[]{
        let pointClouds = [];
        if (!this.potreeViewer) return pointClouds;
        return this.wireframeApplication.sceneManager.pointClouds
    }

    private get wireFrameElements(): any {
        if (!this.wireframeApplication) return null;
        if (!this.wireframeApplication.wireFrameElements) return null;
        return this.wireframeApplication.wireFrameElements;
    }


    private get cameraViews(): Map<number, CameraView> {
        if (!this.wireframeApplication) return null;
        if (!this.wireframeApplication.cameraDict) return null;
        return this.wireframeApplication.cameraDict;
    }

    getWireframeElements():Array<WireframeElement>{
        let wireframeElements:WireframeElement[] = [];
        this.wireFrameElements.forEach((wireframeElement) => {
            wireframeElements.push(wireframeElement)
        });
        return wireframeElements;
    }

    private onSceneVisibleCameraPointViewsChanged(cameraPointViews:CameraPointView[]){
        this.sceneVisibleCameraPointViewsChanged.emit(cameraPointViews);
    }

    private onSceneWireframeChanged(){
        this.wireframeChanged.emit(this.wireframeApplication.wireframe);
    }

    private onSceneWireframeElementHighlighted(highlightedWireframeElement:WireframeElement){
        if (!highlightedWireframeElement) return;
        if (!highlightedWireframeElement.pvObject) return;
        this.wireframeElementHighlighted.emit(highlightedWireframeElement);
        let pvType = highlightedWireframeElement.pvObject.pvType;
        switch (pvType) {
            case WireframeElementType.camera:
                let cameraView: CameraView = highlightedWireframeElement.pvObject as CameraView;
                this.sceneCameraHighlighted.emit(cameraView);
                break;
        }
    }

    getSceneHighlightedWireframeElement(): WireframeElement {
        if(!this.wireframeApplication) return null;
        if(this.wireframeApplication.highlightedElements.length == 0) return null;
        // always an array of one?
        return this.wireframeApplication.highlightedElements[0];
    }

    setSceneHighlightedWireframeElements(sceneHighlightedWireframeElement:WireframeElement){
        if(!sceneHighlightedWireframeElement) return;
        if(!this.wireframeApplication) return;
        this.wireframeApplication.highlightWireframeElement(sceneHighlightedWireframeElement)
    }

    unHighlightAllSceneWireframeElements(){
        if(!this.wireframeApplication) return;
        this.wireframeApplication.highlightWireframeElement(null)
    }

    private onSceneWireframeElementSelected(selectedWireframeElements:Array<WireframeElement>){
        this.wireframeElementSelected.emit(selectedWireframeElements);
    }

    getSceneSelectedWireframeElements(): Array<WireframeElement> {
        if(!this.wireframeApplication) return null;
        return this.wireframeApplication.selectedElements;
    }

    setSceneSelectedWireframeElements(sceneSelectedWireframeElements:Array<WireframeElement>){
        if(!sceneSelectedWireframeElements) return;
        if(!this.wireframeApplication) return;
        this.wireframeApplication.selectWireframeElement(sceneSelectedWireframeElements, true)
    }

    unselectAllSceneWireframeElements(){
        if(!this.wireframeApplication) return;
        this.wireframeApplication.selectWireframeElement(null)
    }

    private onSceneWireframeElementFocused(focusedWireframeElement:WireframeElement) {
        if (!focusedWireframeElement) return;
        if (!focusedWireframeElement.pvObject) return;
        this.wireframeElementFocused.emit(focusedWireframeElement);
        let pvType = focusedWireframeElement.pvObject.pvType;
        switch (pvType) {
            case WireframeElementType.camera:
                let cameraView: CameraView = focusedWireframeElement.pvObject as CameraView;
                this.sceneCameraFocused.emit(cameraView);
                break;
        }
    }

    getSceneFocusedWireframeElement(): WireframeElement {
        if(!this.wireframeApplication) return null;
        return this.wireframeApplication.focusedElement;
    }

    setSceneFocusedWireframeElement(wireframeElement:WireframeElement){
        if(!this.wireframeApplication) return;
        this.wireframeApplication.focusWireframeElement(wireframeElement);
    }

    unfocusAllSceneWireframeElements(){
        if(!this.wireframeApplication) return;
        this.wireframeApplication.focusWireframeElement(null)
    }

    lookAtSceneWireframeElements(wireframeElements:Array<WireframeElement>){
        if(!wireframeElements) return;
        if(!this.wireframeApplication) return;
        this.wireframeApplication.lookAtElements(wireframeElements, true)
    }

    public doesProjectImageHaveCamera(projectImageModel: ProjectImageModel): boolean {
        if (!projectImageModel) return false;
        if (!this.cameraViews) return false;
        let frameId = projectImageModel.frameId;
        return !!this.cameraViews[frameId];
    }

    public getProjectImageCameraView(projectImageModel: ProjectImageModel): CameraView {
        if (!projectImageModel) return null;
        if (!this.cameraViews) return null;
        let frameId = projectImageModel.frameId;
        let cameraView = this.cameraViews[frameId];
        if (!cameraView) return null;
        return cameraView;
    }

    public getProjectImageCameraWireframeElement(projectImageModel: ProjectImageModel): WireframeElement {
        if (!this.wireFrameElements) return null;
        let frameId = projectImageModel.frameId;
        let projectImageCameraWireframeElement: WireframeElement;
        projectImageCameraWireframeElement = this.wireFrameElements.find(function (wireframeElement: WireframeElement) {
            if (!wireframeElement.pvObject) return false;
            if (wireframeElement.pvObject.pvType != WireframeElementType.camera) return false;
            return (wireframeElement as CameraElement).pvObject.frameId == frameId;
        });
        return projectImageCameraWireframeElement;
    }

    public focusProjectImageCamera(projectImageModel: ProjectImageModel): void {
        if (!this.potreeCamera) return;

        // get the camera object
        let cameraWireframeElement = this.getProjectImageCameraWireframeElement(projectImageModel);
        if (!cameraWireframeElement) return;
        let cameraWireframeObject3D = cameraWireframeElement;
        if(!cameraWireframeObject3D) return;

        // position
        let cameraWorldPosition = cameraWireframeObject3D.getWorldPosition(new THREE.Vector3());

        // world direction
        let cameraWorldDirection = cameraWireframeObject3D.getWorldDirection(new THREE.Vector3());

        // world target
        let worldTargetPosition = new THREE.Vector3();
        let closetDistance = this.distanceToClosestPointCloudPoint(cameraWorldPosition, cameraWorldDirection);
        let targetDistance = closetDistance ? closetDistance : 10;
        worldTargetPosition.addVectors ( cameraWorldPosition, cameraWorldDirection.clone().multiplyScalar( targetDistance ) );

        // world quaternion
        let worldQuaternion = new THREE.Quaternion();
        cameraWireframeObject3D.getWorldQuaternion( worldQuaternion );

        // world camera up
        let cameraWorldUp = new THREE.Vector3();
        cameraWorldUp.copy( cameraWireframeObject3D.up ).applyQuaternion( worldQuaternion );

        this.potreeViewer.setCameraTargets(worldTargetPosition, cameraWorldPosition);
    }

    private onScenePointInspected(pointLocal:THREE.Vector3){
        // check for cameras that can see this point
        let cameraPointViewArray: CameraPointView[] = this.getCamerasThatCanSeePoint(pointLocal, true);

        if(cameraPointViewArray){
            this.onSceneVisibleCameraPointViewsChanged(cameraPointViewArray);
        }

        // emit a scene point inspected event
        this.scenePointInspected.emit(pointLocal);
    }

    public getCamerasForPoint(pointWorld:THREE.Vector3):CameraPointView[] {
        let cameraPointViews:CameraPointView[] = [];
        let cameras:CameraView[] = Object.values(this.wireframeApplication.cameraDict);

        let currentResult = this.wireframeApplication.cameraProjectionService.findCamerasForPoint(pointWorld, null);
        if (!currentResult || currentResult.length == 0) return;
        currentResult.forEach((cameraPointView) => {

            // remove duplicates
            let index = cameraPointViews.findIndex((currentCameraPointView) => {
                return cameraPointView.cameraView.frameId == currentCameraPointView.cameraView.frameId
            });
            if (index >= 0) return;

            // todo: for some reason the camera view returned with the cameraPointView doesn't contain the same information as the cameraViews in the wireframeApplication cameraDict.
            // re-associate
            let cam = cameras.find((cameraView: CameraView) => {
                return cameraPointView.cameraView.frameId == cameraView.frameId;
            });

            if (cam) {
                cameraPointView.cameraView = cam;
            }

            cameraPointViews.push(cameraPointView);
        });


        return cameraPointViews
    }

    public getCamerasThatCanSeePoint(pointWorld:THREE.Vector3, enableCameraProjections:boolean = false): CameraPointView[]{
        // check for cameras that can see this point
        let cameraPointViewArray: CameraPointView[] = [];
        let camerasThatCanSeePoint = this.getCamerasForPoint(pointWorld);
        if(camerasThatCanSeePoint && camerasThatCanSeePoint.length > 0){

            // todo: this is handled by getCamerasForPoint
            // test for occlusions by geometry
            // this.wireframeApplication.pointVisibility.testGeometryOcclusion(camerasThatCanSeePoint);

            camerasThatCanSeePoint.forEach((cameraPointView)=>{
                if(!cameraPointView) return;

                // throw out occlusion
                if(cameraPointView.isPointcloudOccluded) return;
                if(cameraPointView.isGeometryOccluded) return;
                if(cameraPointView.imageCoordinates.x < 0) return;
                if(cameraPointView.imageCoordinates.y < 0) return;
                if(cameraPointView.imageCoordinates.x > 1) return;
                if(cameraPointView.imageCoordinates.y > 1) return;

                cameraPointViewArray.push(cameraPointView);
            });
        }
        return cameraPointViewArray;
    }

    public findClosestPointCloudPoint(pointVector3: THREE.Vector3, directionVector3: THREE.Vector3, pickWindowSize = 17) : THREE.Vector3 {
        let ray = new THREE.Ray(pointVector3, directionVector3);
        let closestPoint = null;
        let closestPointDistance = null;

        let camClone = new THREE.PerspectiveCamera();
        camClone.position.copy(pointVector3);
        camClone.lookAt(directionVector3);
        camClone.near = this.potreeCamera.near;
        camClone.far = this.potreeCamera.far;
        if (this.potreeCamera.fov) camClone.fov = this.potreeCamera.fov;
        for (let i = 0; i < this.pointClouds.length; i++) {
            let pointCloud = this.pointClouds[i];
            let point = pointCloud.pick(this.potreeViewer.renderer, camClone, ray, {
                pickOutsideClipRegion: true,
                pickWindowSize: pickWindowSize,
                //mouse: { x: 0, y: 0 },
                //testAllNodes: true
            });

            if (!point) {
                continue;
            }

            let distance = camClone.position.distanceTo(point.position);

            if (!closestPoint || distance < closestPointDistance) {
                closestPoint = point;
                closestPointDistance = distance;
            }
        }

        return closestPoint ? closestPoint.position : null;
    }

    public distanceToClosestPointCloudPoint(point, direction) : number {
        let distance = null;
        let closestPoint = this.findClosestPointCloudPoint(point, direction);
        if(!closestPoint) return distance;
        distance = point.distanceTo(closestPoint);
        return distance;
    }

    /**
     * Returns a vector of x: lat, y: lon, z: elevation
     */
    public getProjectGeodetic():THREE.Vector3 {
        if (!this.wireframeApplication.projectData || !this.wireframeApplication.projectData.summary || !this.wireframeApplication.projectData.summary.location) {
            return new THREE.Vector3()
        }
        return new THREE.Vector3(this.wireframeApplication.projectData.summary.location.lat,
            this.wireframeApplication.projectData.summary.location.lon,
            this.wireframeApplication.projectData.summary.location.elevation)
    }

    loadWireframe(resource:Resource) {
        let that = this;
        that.wireframeApplication.currentResource = resource;
        this.apiService.getWireframe(resource.projectId, resource.id).then((o) => {
            that.wireframeApplication.setWireframeFromJson(o["data"] as Wireframe);
            that.wireframeApplication.sceneManager.loadPointClouds();
            that.wireframeApplication.sceneManager.loadCameras();
            that.wireframeApplication.notifyWireframeLoaded();
            that.wireframeApplication.sceneManager.initOrthoLayers();
            that.wireframeApplication.setDefaultDisplayRules()
            that.wireframeLoaded.emit();
        })
    }

}