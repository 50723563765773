import $ from 'jquery';
import 'jquery-ui';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/effect';
import * as PVProperty from "../../models/property/propertyLoaders";
import tpl from './displayRule.html';
import {WireframeApplication} from "../../application/wireframeApplication";
import {EnumProperty} from "../../models/property/enumProperty";
import {DictionaryProperty} from "../../models/property/propertyLoaders";
import {WireframeOperations} from "../../wireframeOperations";
import {SceneService} from "../../services/scenes/scene.service";


export default {
    bindings: {
        deletefunc: '=',
        modifyfunc: '=',
        rule: '=',
        index: '='
    },
    controller: ['sceneService', function (sceneService:SceneService) {
        let app = sceneService.wireframeApplication
        let ctrl = this;
        this.selectedProperty;
        this.index;
        this.options = [];
        this.buttonStyle = {width: "20px", height: "20px", "background-color": "#ffffff"};

        this.getPropertyOptions = function () {
            return Object.values(app.wireframe.properties);
        };

        this.getPropertyValues = function () {
            return Object.values(this.rule.criterion.property.values);
        };

        this.isEnumType = function () {
            let isEnum = this.rule.criterion.property instanceof EnumProperty;
            return isEnum;
        };

        this.isDictonaryType = function () {
            let isDictionary = this.rule.criterion.property instanceof DictionaryProperty;
            return isDictionary;
        };


        this.color = function (c) {
            if (c) {
                this.rule.color = ($ as any).Color(c).rgba();
                this.ruleChanged();
            } else {
                return ($ as any).Color(this.rule.color).toRgbaString()
            }
        };

        this.getButtonStyle = function () {
            this.buttonStyle["background-color"] = ($ as any).Color(this.rule.color).toHexString();
            return this.buttonStyle;
        };

        this.ruleChanged = function () {
            //console.log("ruleChanged");
            let propId = this.rule.criterion.property.id;
            let prop = app.wireframe.properties[propId];
            this.rule.criterion.property = new PVProperty[prop.type.substr(1)]();
            this.rule.criterion.property.id = prop.id;
            app.propertiesChanged();
            app.wireframeLayer.updateWireframeMaterials();
        };

        this.selectMatchingElements = function () {

            let matching = app.findElementsByPropertyValue(this.rule.criterion.property, this.rule.criterion.value);
            app.selectWireframeElement(null);
            app.selectWireframeElement(matching, true, true);
            //wfOps.updateWireframeElementMaterials(el);

        };

        this.deleteRule = function () {
            this.deletefunc(ctrl.index);
            app.wireframeLayer.updateWireframeMaterials();
        };

    }],
    template: tpl
}
