import {InteractiveModel} from "../interactive/interactive.model";
import {VertexElement} from "../../application/vertexElement";
import {PV} from "../../wireframe";
import {Property} from "../property/property";
import {VerifiedProperty} from "../property/verifiedProperty";
import {SeenProperty} from "../property/seenProperty";
import {EventEmitter} from "@angular/core";

export class VertexElementModel extends InteractiveModel {
    public vertexElement: VertexElement;
    public wireframe:PV.Wireframe;
    private readonly _verifiedProperty:Property;
    private readonly _seenProperty:Property;
    public updated = new EventEmitter<VertexElementModel>();

    constructor(vertexElement:VertexElement, wireframe:PV.Wireframe) {
        super();
        this.vertexElement = vertexElement;
        this.wireframe = wireframe;
        this._verifiedProperty = this.wireframe.findProperty(VerifiedProperty, false);
        this._seenProperty = this.wireframe.findProperty(SeenProperty, true);

        this.vertexElement.updated.subscribe(this.onVertexElementUpdated.bind(this));
    }

    get verified():boolean{
        let verified = false;
        if (this._verifiedProperty) {
            verified = this._verifiedProperty.getValue(this.vertexElement.pvObject) == 1;
        }
        return verified;
    }

    set verified(verifiedValue:boolean){
        if(this._verifiedProperty){
            this._verifiedProperty.setValue(this.vertexElement, verifiedValue);
        }
    }

    get seen():boolean{
        let seen = false;
        if (this._seenProperty) {
            seen = this._seenProperty.getValue(this.vertexElement.pvObject) == 1;
        }
        return seen;
    }

    set seen(seenValue:boolean){
        if(this._seenProperty){
            this._seenProperty.setValue(this.vertexElement, seenValue);
        }
    }

    onVertexElementUpdated(){
        this.triggerUpdated();
    }

    triggerUpdated(){
        this.updated.emit(this);
    }

}