import {PV} from "../wireframe";
import {ValidationResult} from "./validationResult";
import {WireframeElementType} from "./wireframeElementType";
import * as THREE from "three";
import {EdgeTypeProperty} from "../models/property/edgeTypeProperty";
import WireframeLayer from "./wireframeLayer";
import {EdgeElement} from "./edgeElement";
import {PlaneElement} from "./planeElement";
import ComponentMap = PV.ComponentMap;

export default class WireframeValidator {

    public static validateWireframe(layer: WireframeLayer): ValidationResult[] {

        let that = this;
        let results: ValidationResult[] = []

        let verts: ComponentMap = layer.wireframe.filterGeometry(layer.wireframe.vertices);
        let edges: ComponentMap = layer.wireframe.filterGeometry(layer.wireframe.edges);
        let planes: ComponentMap = layer.wireframe.filterGeometry(layer.wireframe.planes);

        let validators: Validator[] = [];

        validators.push(new IsolatedVertexValidator());
        validators.push(new IsolatedEdgeValidator());
        validators.push(new CoincidentVerticesValidator());
        validators.push(new ColinearAdjacentEdgesValidator());
        validators.push(new MissingEdgeTypeValidator());
        validators.push(new ParentChildPlaneSharedEdgesValidator());
        validators.push(new EdgeIntegrityValidator())
        validators.push(new PlaneIntegrityValidator())
        // TODO: Add check for WF Type to run this validation
        //validators.push(new InconsistentPlaneNormalValidator());        

        validators.forEach(v => {
            let validationResult = v.validate(layer, verts, edges, planes);
            if (validationResult)
                results.push(validationResult);
        });

        return results
    }
}

interface Validator {
    validate(layer: WireframeLayer, verts: ComponentMap, edges: ComponentMap, planes: ComponentMap): ValidationResult
}

class IsolatedVertexValidator implements Validator {

    validate(layer: WireframeLayer, verts: PV.ComponentMap, edges: PV.ComponentMap, planes: PV.ComponentMap): ValidationResult {
        let isolatedVerts: PV.Vertex[] = [];
        Object.keys(verts).forEach(function (vertId) {
            let vert = layer.wireframe.vertices[vertId];
            let edges = layer.wireframe.findEdgesForVertex(vert.id);
            if (edges.length < 1) isolatedVerts.push(vert);
        });
        if (isolatedVerts.length > 0) {
            let isolatedVertResult = new ValidationResult();
            isolatedVertResult.message = "Isolated Vertex";
            isolatedVerts.forEach(function (vert) {
                isolatedVertResult.elements.push(layer.findWireframeElement(WireframeElementType.vertex, vert.id));
            })
            return isolatedVertResult;
        }
    }
}

class IsolatedEdgeValidator implements Validator {
    validate(layer: WireframeLayer, verts: PV.ComponentMap, edges: PV.ComponentMap, planes: PV.ComponentMap): ValidationResult {
        let isolatedEdges: PV.Edge[] = [];
        Object.keys(edges).forEach(function (edgeId) {
            let edge = layer.wireframe.edges[edgeId];
            let planes = layer.wireframe.findPlanesForEdge(edge.id);
            if (planes.length < 1) {
                //console.log("edge " + edgeId + " planes", planes);
                isolatedEdges.push(edge);
            }
        });
        if (isolatedEdges.length > 0) {
            let isolatedEdgeResult = new ValidationResult();
            isolatedEdgeResult.message = "Isolated Edge";
            isolatedEdges.forEach(function (edge) {
                isolatedEdgeResult.elements.push(layer.findWireframeElement(WireframeElementType.edge, edge.id));
            })
            return isolatedEdgeResult;
        }
    }
}

class CoincidentVerticesValidator implements Validator {
    validate(layer: WireframeLayer, verts: PV.ComponentMap, edges: PV.ComponentMap, planes: PV.ComponentMap): ValidationResult {
        let duplicateVerts: PV.Vertex[] = [];
        let dupeThreshold = .1;
        Object.keys(verts).forEach(function (vert1Id) {
            let vert1 = layer.wireframe.vertices[vert1Id];

            let pos1 = layer.wireframe.getVert3(vert1.id);
            Object.keys(verts).forEach(function (vert2Id) {
                let vert2 = layer.wireframe.vertices[vert2Id];
                if (vert1.id == vert2.id) return;
                let dist = pos1.distanceTo(layer.wireframe.getVert3(vert2.id));
                if (dist > dupeThreshold) return;
                if (!duplicateVerts.includes(vert1)) duplicateVerts.push(vert1);
                if (!duplicateVerts.includes(vert2)) duplicateVerts.push(vert2);
            });
        });

        if (duplicateVerts.length > 0) {
            let dupeResult = new ValidationResult();
            dupeResult.message = "Coincident Vertices"
            duplicateVerts.forEach(function (v) {
                dupeResult.elements.push(layer.findWireframeElement(WireframeElementType.vertex, v.id));
            })
            return dupeResult;
        }
    }

}

class ColinearAdjacentEdgesValidator implements Validator {
    validate(layer: WireframeLayer, verts: PV.ComponentMap, edges: PV.ComponentMap, planes: PV.ComponentMap): ValidationResult {
        let colinearEdgeVerts: PV.Vertex[] = [];
        Object.keys(verts).forEach(function (vertIdStr) {
            let vertId = Number(vertIdStr);
            let edgeIds = layer.wireframe.findEdgesForVertex(vertId);
            if (edgeIds.length != 2) return;
            let edge1: PV.Edge = layer.wireframe.edges[edgeIds[0]];
            let edge2: PV.Edge = layer.wireframe.edges[edgeIds[1]];

            let edge1Vec: THREE.Vector3 = layer.wireframe.getVert3(edge1.vertex1Id).sub(layer.wireframe.getVert3(edge1.vertex2Id));
            edge1Vec.normalize();

            let edge2Vec: THREE.Vector3 = layer.wireframe.getVert3(edge2.vertex1Id).sub(layer.wireframe.getVert3(edge2.vertex2Id));
            edge2Vec.normalize();

            let dp = edge1Vec.dot(edge2Vec);
            if (dp < 0) edge2Vec.multiplyScalar(-1);
            let angle = edge1Vec.angleTo(edge2Vec);
            if (angle < 0.174533) { // 10 degrees
                let vert: PV.Vertex = layer.wireframe.vertices[vertId];
                if (!colinearEdgeVerts.includes(vert)) colinearEdgeVerts.push(vert);
            }
        });
        if (colinearEdgeVerts.length > 0) {
            let colinearEdgeResult = new ValidationResult();
            colinearEdgeResult.message = "Colinear Adjacent Edges"
            colinearEdgeVerts.forEach(function (edge) {
                colinearEdgeResult.elements.push(layer.findWireframeElement(WireframeElementType.vertex, edge.id));
            })
            return colinearEdgeResult;
        }
    }
}

class MissingEdgeTypeValidator implements Validator {
    validate(layer: WireframeLayer, verts: PV.ComponentMap, edges: PV.ComponentMap, planes: PV.ComponentMap): ValidationResult {
        let missingEdges: PV.Edge[] = [];
        let edgeProp = layer.wireframe.findProperty(EdgeTypeProperty, true);
        Object.keys(edges).forEach(function (edgeId) {
            let edge = layer.wireframe.edges[edgeId];
            let edgeType = edgeProp.getValue(edge);
            if (null == edgeType) missingEdges.push(edge);
        });

        if (missingEdges.length > 0) {
            let missingEdgeResult = new ValidationResult();
            missingEdgeResult.message = "Missing EdgeTypeProperty"
            missingEdges.forEach(function (edge) {
                missingEdgeResult.elements.push(layer.findWireframeElement(WireframeElementType.edge, edge.id));
            })
            return missingEdgeResult;
        }
    }

}

class InconsistentPlaneNormalValidator implements Validator {
    validate(layer: WireframeLayer, verts: PV.ComponentMap, edges: PV.ComponentMap, planes: PV.ComponentMap): ValidationResult {
        let planePairs: Map<string, boolean> = new Map();
        let inconsistentPlanes: PV.Plane[] = [];
        for (let plane of Object.values(planes)) {
            for (let edgeId of plane.edgeIds) {
                let adjacentPlanes: number[] = layer.wireframe.findPlanesForEdge(edgeId);
                for (let adjacentPlaneId of adjacentPlanes) {
                    if (adjacentPlaneId == plane.id) continue;
                    let adjacentPlane: PV.Plane = layer.wireframe.planes[adjacentPlaneId];
                    let planePairId: string = this.getPlanePairKey(plane.id, adjacentPlane.id);
                    if (!planePairs[planePairId]) {
                        planePairs[planePairId] = true;

                        let consistent = layer.wireframe.areNormalsConsistent(plane, adjacentPlane, layer.wireframe.edges[edgeId]);
                        //console.log("testing plane normal consistency for " + planePairId + " " + consistent);
                        if (!consistent) {
                            if (!inconsistentPlanes.includes(plane)) inconsistentPlanes.push(plane);
                            if (!inconsistentPlanes.includes(adjacentPlane)) inconsistentPlanes.push(adjacentPlane);
                        }
                    }
                }
            }
        }
        if (inconsistentPlanes.length > 0) {
            let inconsistentPlanesResult = new ValidationResult();
            inconsistentPlanesResult.message = "Inconsistent Plane Normals"
            inconsistentPlanes.forEach(function (plane) {
                inconsistentPlanesResult.elements.push(layer.findElement(plane));
            })
            return inconsistentPlanesResult;
        }
    }

    getPlanePairKey(planeId1: number, planeId2: number): string {
        if (planeId1 < planeId2) return planeId1 + ":" + planeId2;
        return planeId2 + ":" + planeId1;
    }
}

class ParentChildPlaneSharedEdgesValidator implements Validator {
    validate(layer: WireframeLayer, verts: PV.ComponentMap, edges: PV.ComponentMap, planes: PV.ComponentMap): ValidationResult {
        let childPlaneSharedEdges: PV.Edge[] = []
        Object.values(planes).forEach((plane: PV.Plane) => {
            if (null == plane.parentId) return;
            let parentPlane: PV.Plane = layer.wireframe.planes[plane.parentId];
            for (let edgeId of plane.edgeIds) {
                if (parentPlane.edgeIds.includes(edgeId)) {
                    let edge = layer.wireframe.edges[edgeId]
                    if (!childPlaneSharedEdges.includes(edge)) childPlaneSharedEdges.push(edge)
                }
            }
        })
        if (childPlaneSharedEdges.length > 0) {
            let childPlaneSharedEdgesResult = new ValidationResult()
            childPlaneSharedEdgesResult.message = "Parent/Child Plane Shared Edges"
            childPlaneSharedEdges.forEach(function (plane) {
                childPlaneSharedEdgesResult.elements.push(layer.findElement(plane))
            })
            return childPlaneSharedEdgesResult;
        }
    }

}

class EdgeIntegrityValidator implements  Validator {
    validate(layer: WireframeLayer, verts: PV.ComponentMap, edges: PV.ComponentMap, planes: PV.ComponentMap): ValidationResult {
        let result = new ValidationResult()
        layer.wireframeElements.filter((el) => el instanceof EdgeElement).forEach((edgeEl:EdgeElement) => {
            let vert1El = layer.findWireframeElement(WireframeElementType.vertex, edgeEl.pvObject.vertex1Id)
            let vert2El = layer.findWireframeElement(WireframeElementType.vertex, edgeEl.pvObject.vertex2Id)
            if (!vert1El || !vert2El || (vert1El == vert2El)) {
                result.elements.push(edgeEl)
            }
        })
        result.message = "Invalid vertex references"
        if (result.elements.length > 0) return result
    }
}

class PlaneIntegrityValidator implements  Validator {
    validate(layer: WireframeLayer, verts: PV.ComponentMap, edges: PV.ComponentMap, planes: PV.ComponentMap): ValidationResult {
        let result = new ValidationResult()
        layer.wireframeElements.filter((el) => el instanceof PlaneElement).forEach((planeEl:PlaneElement) => {
            planeEl.pvObject.edgeIds.forEach((edgeId) => {
                let edgeEl = layer.findWireframeElement(WireframeElementType.edge, edgeId)
                if (!edgeEl && !result.elements.includes(planeEl)) {
                    result.elements.push(planeEl)
                }
            })

            planeEl.pvObject.vertexIds.forEach((vertId) => {
                let vertEl = layer.findWireframeElement(WireframeElementType.vertex, vertId)
                if (!vertEl && !result.elements.includes(planeEl)) {
                    result.elements.push(planeEl)
                }
            })
        })
        result.message = "Invalid vertex references"
        if (result.elements.length > 0) return result
    }
}