import { Component, Input } from "@angular/core";
import { SceneService } from "src/app/services/scenes/scene.service";

@Component({
    selector: 'ptv-project-management-issues',
    styles: [require('./projectManagementIssues.component.scss')],
    template: require('./projectManagementIssues.component.html')
})
export default class ProjectManagementIssuesComponent {
    @Input() applicationConfig: any;

    constructor(private sceneService: SceneService) {
    }

    private _issuesList: any[];
    get issuesList() {
        if (this._issuesList) return this._issuesList;
        if (!this.applicationConfig) return [];
        if (!this.applicationConfig.internalConstants) return [];
        if (!this.applicationConfig.internalConstants.dictionaries) return [];
        if (!this.applicationConfig.internalConstants.dictionaries.ProjectIssues) return []

        let projectIssues = this.applicationConfig.internalConstants.dictionaries.ProjectIssues;
        this._issuesList = Object.values(projectIssues).filter((pi: any) => !pi.retired).sort(function(a: any,b: any) { return a.description.toLowerCase().localeCompare(b.description.toLowerCase())});
        return this._issuesList;
    }

    get currentIssues() {
        let projectData = this.sceneService.wireframeApplication.projectData;
        if (!projectData || !projectData.summary|| !projectData.summary.projectIssues) return [];
        return projectData.summary.projectIssues;        
    }

    toggleSelection(issue) {
        let self = this;
        let project = self.sceneService.wireframeApplication.projectData.summary;
        if (!project) return;

        let projectIssues: string[] = project.projectIssues || [];
        if (issue === -1) {
            projectIssues = [];
        } else {
            let index = projectIssues.indexOf(issue.name);
            if (index > -1)
                projectIssues.splice(index, 1);
            else
                projectIssues.push(issue.name);
        }
        
        project.projectIssues = projectIssues;

        self.sceneService.wireframeApplication.apiService.putProject(project).then(
            () => {
                self.sceneService.wireframeApplication.log('Project Issues Updated');
            },
            (err) => {
                console.log(err);
                self.sceneService.wireframeApplication.log('Unable to update Project Issues.');
            });
    }
}