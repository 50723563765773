import {WireframeElementType} from "../../application/wireframeElementType";
import {DictionaryProperty} from "./dictionaryProperty";

export class EdgeAnnotationProperty extends DictionaryProperty {
    constructor() {
        super();
        this.isEditable = false;
        this.allowMultiplePerElement = false;
        this.allowMultiplePerWireframe = false;
        this.name = "Edge Annotation";
        this.type = ".EdgeAnnotationProperty";
        this.allowableElementTypes = [WireframeElementType.edge];
        this.knownValues = {
            "0": "Roof, Gutter",
            "1": "Roof, Flashing",
            "2": "Roof, Curb Flashing",
            "3": "Roof, Step Flashing",
            "4": "Roof, Counter / Cap Flashing",
            "5": "Roof, Roof Edge, Eave",
            "6": "Roof, Roof Edge, Rake",
            "7": "Roof, Roof Edge, Hip",
            "8": "Roof, Roof Edge, Valley",
            "9": "Roof, Roof Edge, Ridge",
            "10": "Roof, Roof Edge, Parapet",
            "11": "Roof, Roof Edge, Transition"
        };
        this.setValuesFromObject(this.knownValues);
    }
}