import $ from 'jquery';
import 'jquery-ui';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/effect';
import * as PVProperty from "../../models/property/propertyLoaders"
import {PropertyCriterion} from "../../models/property/propertyCriterion";
import {DisplayRule} from "../../models/displayRules/displayRule";
import {WireframeApplication} from "../../application/wireframeApplication";
import tpl from './displayRules.html';

declare var wfApp: WireframeApplication;

export default {
    bindings: {},
    controller: [function () {
        this.addDisplayRuleModel = null;
        this.addDisplayRule = function () {
            if (Object.values(wfApp.wireframe.properties).length < 1) return;
            let hues = this.getDisplayConfig().rules.map(function (o) {
                return ($ as any).Color(o.color).hue();
            });
            hues.sort(function (a, b) {
                return a - b;
            });
            let hue = 45;
            if (hues.length > 0) {
                let maxGapId = 0;
                let maxGap = 0;
                for (let i = 0; i < hues.length; i++) {
                    let thisHue = hues[i];
                    let nextHue = hues[(i + 1) % hues.length];
                    if (i == hues.length - 1) nextHue += 360;
                    let gap = (nextHue - thisHue);
                    if (gap > maxGap) {
                        maxGapId = i;
                        maxGap = gap;
                    }
                }
                hue = ((hues[maxGapId] + (maxGap / 2)) % 360);
            }
            let colorParams = {hue: hue, saturation: 1.0, lightness: 0.5, alpha: 0.5};
            let rule = new DisplayRule();
            rule.criterion = new PropertyCriterion();
            let wfProp = Object.values(wfApp.wireframe.properties)[0];
            rule.criterion.property = new PVProperty[wfProp.type.substr(1)]();

            rule.criterion.property.id = wfProp.id;
            rule.criterion.value = null;
            rule.color = ($ as any).Color(colorParams).rgba();
            rule.color[3] = 0.75;
            //console.log("new color ", rule.color);
            this.getDisplayConfig().rules.push(rule);
        };

        this.getDisplayConfig = function () {
            return wfApp.propertyDisplayConfig;
        };

        this.deleteRule = function (index) {
            wfApp.propertyDisplayConfig.rules.splice(index, 1);
        };

    }],
    template: tpl
}

