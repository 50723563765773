/**
 * Created by Shwetank on 10-May-2017.
 */
import {PV} from "./wireframe";
import {Hull} from './hull'
import Wireframe = PV.Wireframe;
import {PerimeterProperty} from "./models/property/perimeterProperty";
import {SlopeProperty} from "./models/property/slopeProperty";
import {AreaProperty} from "./models/property/areaProperty";
import * as THREE from 'three';
import {WireframeMathUtil} from "./wireframeMathUtil";
import {PlaneElement} from "./application/planeElement";

var PolyBool: any;

export class GraphNode {
    public vertexId: number;
    public adjacentEdgeIds: number[];
    public adjacentVertexIds: number[];

    public constructor(id: number) {
        this.vertexId = id;
        this.adjacentVertexIds = [];
        this.adjacentEdgeIds = [];
    }
}

export class PolygonDetectionResult {
    public acceptedPolygons: PV.Plane[] = [];
    public rejectedPolygons: PV.Plane[] = [];
}

export class LibraryPolygon {
    public regions: number[][][] = [];
    public inverted: boolean = false;

    public constructor(vertices2D: THREE.Vector2[], inverted: boolean) {
        let coordinates: number[][] = [];
        for (let i = 0; i < vertices2D.length; i++) {
            let point: number[] = [vertices2D[i].x, vertices2D[i].y];
            coordinates.push(point);
        }
        this.regions.push(coordinates);
        this.inverted = inverted;
    }
}

interface PlaneNodesMap {
    [id: number]: GraphNode[];
}

interface PlaneEquationMap {
    [id: number]: PV.PlaneEquation;
}

interface NodeMap {
    [id: number]: GraphNode;
}

interface IntegerListMap {
    [id: number]: number[];
}

interface IntBooleanMap {
    [id: number]: boolean;
}

export class PlaneDetector {
    
    public getPerPlaneVertexIds(wireframe: PV.Wireframe, edgesRemaining: number[]): IntegerListMap {
        let key: any;
        let planes: PV.PlaneMap = wireframe.planes;
        let perPlaneVertexIds: IntegerListMap = {};

        let edgeCovered: number[] = [];
        for (key in planes) {
            perPlaneVertexIds[key] = planes[key].vertexIds;
            let edgeKey: any;
            for (edgeKey in planes[key].edgeIds) {
                if (!edgeCovered.includes(Number(planes[key].edgeIds[edgeKey]))) {
                    edgeCovered.push(Number(planes[key].edgeIds[edgeKey]));
                }
            }
        }

        let edgeKey: any;
        for (edgeKey in wireframe.edges) {
            if (!edgeCovered.includes(Number(edgeKey))) {
                edgesRemaining.push(Number(edgeKey));
            }
        }

        return perPlaneVertexIds;
    }

    public detectPlanes(wireframe: PV.Wireframe, sceneTransform:THREE.Matrix4): PolygonDetectionResult {
        let planarSurfaces: PV.PlaneMap = {};
        let planarityConstraintThreshold: number = 4.0;
        let gravityVectorConstraintThreshold: number = 80.0;

        let rejectedPolygonIds: number[] = [];
        let result: PolygonDetectionResult = new PolygonDetectionResult();                  //[all detected planes[], rejected planeIds[]]

        let transformedVertices3D: PV.VertexMap = WireframeMathUtil.getTransformedVertices3D(wireframe.vertices, sceneTransform);

        if (wireframe.transform == null)
            return result;

        let polygons: PlaneNodesMap = this.extractPolygonNodes(wireframe, transformedVertices3D, false);
        let numRejected = 0;
        for (let key in polygons) {
            let rejected = false;
            if (polygons[key].length > 3) {
                let passPlanarity: boolean = this.testPolygonForPlanarity(polygons[key], wireframe.vertices, planarityConstraintThreshold);
                if (!passPlanarity) {
                    console.log("Plane " + key + " rejected by planarity constraint " + planarityConstraintThreshold);
                    rejected = true;
                }
            }


            let surface: PV.Plane = this.populatePlanarSurfaceObject(polygons[key], wireframe.vertices);

            /*
            if (!rejected) {
                this.updatePlaneParameters(surface, wireframe);
                if (surface.slope >= gravityVectorConstraintThreshold) {
                    rejected = true;
                }
            }
            */

            if (rejected) {
                rejectedPolygonIds.push(Number(key));
            }
            planarSurfaces[Number(key)] = surface;
        }

        //let significantOverlapSurfaceRemovalIds: number[] = [];
        //let surfaceOrder: number[] = this.orderPlanarSurfacesBasedOnOverlap(planarSurfaces, transformedVertices3D, significantOverlapSurfaceRemovalIds);
        // for (let i = 0; i < surfaceOrder.length; i++) {
        //     let rejected = false;
        //     if (significantOverlapSurfaceRemovalIds.includes(surfaceOrder[i])) {
        //         console.log("Polygon " + i + " was removed due to significant overlap");
        //         rejected = true;
        //     }
        //
        //     if (rejected){
        //         rejectedPolygonIds.push()
        //     }
        // }


        for (let key in planarSurfaces) {
            let keyNumber: number = Number(key);
            if (rejectedPolygonIds.includes(keyNumber))
                result.rejectedPolygons.push(planarSurfaces[keyNumber]);
            else
                result.acceptedPolygons.push(planarSurfaces[keyNumber]);
        }

        if (result.rejectedPolygons.length > 0) console.log("Polygons Accepted Count/Polygon Rejected Count : " + result.acceptedPolygons.length + "/" + result.rejectedPolygons.length);
        return result;
    }

    private extractPolygonNodes(wireframe: PV.Wireframe, transformedVertices3D: PV.VertexMap, debug: boolean): PlaneNodesMap {
        let vertices = wireframe.vertices;
        let edges = wireframe.edges;
        let clusters: number[][] = this.clusterWireframe(vertices, edges);
        let extractedPolygons: PlaneNodesMap = {};
        let currentPlanarPolygonId: number = 0;

        for (let i: number = 0; i < clusters.length; i++) {
            let originalGraph: NodeMap = this.buildGraph(wireframe, clusters[i]);
            let currentGraph: NodeMap = this.buildGraph(wireframe, clusters[i]);
            this.cleanGraphFromOpenPolygons(originalGraph);
            this.cleanGraphFromOpenPolygons(currentGraph);

            if (Object.keys(currentGraph).length < 3)
                continue;

            let originalOuterBoundary: number[] = this.detectOuterBoundaryOfGraph(originalGraph, transformedVertices3D);
            let twoEdgeVerticesToExclude: number[] = [];
            let threeEdgeVerticesToExclude: number[] = [];

            while (Object.keys(currentGraph).length > 0) {
                this.cleanGraphFromOpenPolygons(currentGraph);
                if (Object.keys(currentGraph).length < 3)
                    break;
                let verticesWithTwoEdges: number[] = [];
                for (let nodeId in currentGraph) {
                    if (currentGraph[nodeId].adjacentVertexIds.length === 2)
                        if (!twoEdgeVerticesToExclude.includes(Number(nodeId)))
                            verticesWithTwoEdges.push(Number(nodeId));
                }
                verticesWithTwoEdges.sort(function (a, b) {
                    return a - b
                });
                //console.log("Two edge vertex count = " + verticesWithTwoEdges.length);

                if (verticesWithTwoEdges.length > 0) {
                    for (let j = 0; j < verticesWithTwoEdges.length; j++) {
                        let polygon: GraphNode[] = this.extractPlanarPolygonFromTwoEdgeVertex(verticesWithTwoEdges[j], currentGraph, originalGraph, vertices, transformedVertices3D);

                        if (polygon.length < 3) {
                            twoEdgeVerticesToExclude.push(Number(verticesWithTwoEdges[j]));
                            continue;
                        }

                        let allowedToRemove: boolean[] = this.determineRemovalOfVerticesWithTwoEdges(polygon, originalOuterBoundary);
                        if (allowedToRemove === null || allowedToRemove.length === 0) {
                            twoEdgeVerticesToExclude.push(verticesWithTwoEdges[j]);
                            continue;
                        }

                        for (let k = 0; k < allowedToRemove.length; k++) {
                            if (allowedToRemove[k])
                                this.removeNodeFromGraph(currentGraph, polygon[k].vertexId);
                        }
                        extractedPolygons[currentPlanarPolygonId] = this.polygonInOriginalGraph(polygon, originalGraph);

                        // let vertexIds: string = "";
                        // for (let l=0; l<extractedPolygons[currentPlanarPolygonId].length; l++)
                        //     vertexIds += extractedPolygons[currentPlanarPolygonId][l].vertexId + " ";
                        // console.log("Polygon " + currentPlanarPolygonId + ", size = " + extractedPolygons[currentPlanarPolygonId].length + ", vertices = " + vertexIds);

                        twoEdgeVerticesToExclude = [];
                        currentPlanarPolygonId++;
                        break;
                    }
                } else {
                    let outerBoundaryVertexIdsWithThreeEdges: number[] = [];
                    for (let key in currentGraph) {
                        if (threeEdgeVerticesToExclude.includes(Number(key)))
                            continue;
                        if (!originalOuterBoundary.includes(Number(key)))
                            continue;
                        if (currentGraph[key].adjacentVertexIds.length !== 3)
                            continue;
                        outerBoundaryVertexIdsWithThreeEdges.push(Number(key));
                    }
                    outerBoundaryVertexIdsWithThreeEdges.sort(function (a, b) {
                        return a - b
                    });

                    let found: boolean = false;
                    for (let j = 0; j < outerBoundaryVertexIdsWithThreeEdges.length; j++) {
                        let polygons: GraphNode[][] = [];
                        let response: object = this.extractPlanarPolgonsStartingFromVertexWithThreeEdges(outerBoundaryVertexIdsWithThreeEdges[j], currentGraph,
                            originalGraph, vertices, transformedVertices3D);
                        let sharedVertexId: number = response[0];
                        polygons = response[1];
                        if (polygons.length === 0) {
                            threeEdgeVerticesToExclude.push(outerBoundaryVertexIdsWithThreeEdges[j]);
                            continue;
                        }

                        let allowedToRemove2: boolean[][] = [];
                        let vertexOfEdgeToBeRemoved: number[] = [];
                        for (let k = 0; k < polygons.length; k++) {
                            let remove: boolean[] = [];
                            if (polygons[k].length < 3) {
                                allowedToRemove2.push(remove);
                                vertexOfEdgeToBeRemoved.push(-1);
                                continue;
                            }
                            for (let m = 0; m < polygons[k].length; m++) {
                                let vId: number = polygons[k][m].vertexId;
                                if (currentGraph[vId].adjacentVertexIds.length == 2 && originalOuterBoundary.includes(vId))
                                    remove.push(true);
                                else
                                    remove.push(false);
                            }
                            allowedToRemove2.push(remove);
                            if (polygons[k][0].vertexId != sharedVertexId)
                                vertexOfEdgeToBeRemoved.push(polygons[k][0].vertexId);
                            else
                                vertexOfEdgeToBeRemoved.push(polygons[k][polygons[k].length - 2].vertexId);
                        }
                        for (let k = 0; k < allowedToRemove2.length; k++) {
                            if (allowedToRemove2[k].length == 0)
                                continue;
                            for (let m = 0; m < allowedToRemove2[k].length; m++)
                                if (allowedToRemove2[k][m])
                                    this.removeNodeFromGraph(currentGraph, polygons[k][m].vertexId);
                            this.removeEdgeFromGraph(currentGraph, outerBoundaryVertexIdsWithThreeEdges[j], vertexOfEdgeToBeRemoved[k]);

                            extractedPolygons[currentPlanarPolygonId] = this.polygonInOriginalGraph(polygons[k], originalGraph);

                            // let vertexIds: string = "";
                            // for (let l=0; l<extractedPolygons[currentPlanarPolygonId].length; l++)
                            //     vertexIds += extractedPolygons[currentPlanarPolygonId][l].vertexId + " ";
                            // console.log("Polygon " + currentPlanarPolygonId + ", size = " + extractedPolygons[currentPlanarPolygonId].length + ", vertices = " + vertexIds);

                            twoEdgeVerticesToExclude = [];
                            threeEdgeVerticesToExclude = [];
                            currentPlanarPolygonId++;
                            found = true;
                        }
                        if (found)
                            break;
                    }

                    if (!found) {
                        let edgesOnConvexHull: GraphNode[][] = this.identifyEdgesOnGraphConvexHull(currentGraph, transformedVertices3D);
                        if (edgesOnConvexHull.length == 0)
                            break;

                        for (let j = 0; j < edgesOnConvexHull.length; j++) {
                            let seedId: number = edgesOnConvexHull[j][0].vertexId;
                            let targetId: number = edgesOnConvexHull[j][1].vertexId;
                            let bestPlanes: PV.PlaneEquation[] = this.bestFitPlaneToConvexHullEdge(seedId, targetId, currentGraph, vertices);
                            if (bestPlanes.length == 0)
                                continue;

                            for (let k = 0; k < bestPlanes.length; k++) {
                                let polygon: GraphNode[] = this.extractPlanarPolygonFromConvexHullEdge(edgesOnConvexHull[j], currentGraph, originalGraph, vertices, bestPlanes[k], transformedVertices3D);
                                if (polygon == null || polygon.length < 3)
                                    continue;
                                extractedPolygons[currentPlanarPolygonId] = this.polygonInOriginalGraph(polygon, originalGraph);

                                // let vertexIds: string = "";
                                // for (let l=0; l<extractedPolygons[currentPlanarPolygonId].length; l++)
                                //     vertexIds += extractedPolygons[currentPlanarPolygonId][l].vertexId + " ";
                                // console.log("Polygon " + currentPlanarPolygonId + ", size = " + extractedPolygons[currentPlanarPolygonId].length + ", vertices = " + vertexIds);

                                this.removeEdgeFromGraph(currentGraph, edgesOnConvexHull[j][0].vertexId, edgesOnConvexHull[j][1].vertexId);
                                twoEdgeVerticesToExclude = [];
                                threeEdgeVerticesToExclude = [];
                                found = true;
                                break;
                            }

                            if (found) {
                                currentPlanarPolygonId++;
                                break;
                            }
                        }
                        if (!found) {
                            this.removeEdgeFromGraph(currentGraph, edgesOnConvexHull[0][0].vertexId, edgesOnConvexHull[0][1].vertexId);
                            twoEdgeVerticesToExclude = [];
                            threeEdgeVerticesToExclude = [];
                        }
                    }
                }
            }
        }
        return extractedPolygons;
    }

    public clusterWireframe(vertices: PV.VertexMap, edges: PV.EdgeMap): number[][] {
        let vertexToEdgeIds = {};
        for (let key in vertices) {
            vertexToEdgeIds[key] = [];
        }
        for (let key in edges) {
            let vertex1Id: number = Number(edges[key].vertex1Id);
            let vertex2Id: number = Number(edges[key].vertex2Id);
            if (null !== vertex1Id)
                vertexToEdgeIds[vertex1Id].push(key);
            if (null !== vertex2Id)
                vertexToEdgeIds[vertex2Id].push(key);
        }

        let visitedVertices = {};
        let clusters: number[][] = [];

        let sortedVertexKeys = Object.keys(vertices);
        sortedVertexKeys.sort(function (a, b) {
            return Number(a) - Number(b)
        });

        while (Object.keys(visitedVertices).length < Object.keys(vertices).length) {
            let cluster: number[] = [];
            let seedVertexId: number = -1;
            for (let i = 0; i < sortedVertexKeys.length; i++) {
                let key = sortedVertexKeys[i];
                if (!visitedVertices.hasOwnProperty(Number(key))) {
                    seedVertexId = Number(key);
                    break;
                }
            }
            if (seedVertexId == -1)
                break;

            let queue: number[] = [];
            queue.push(seedVertexId);
            while (queue.length > 0) {
                let vertexId: number = Number(queue.shift());
                if (visitedVertices.hasOwnProperty(vertexId))
                    continue;
                cluster.push(vertexId);
                visitedVertices[vertexId] = true;
                let connectedEdges: number[] = vertexToEdgeIds[vertexId];
                for (let i = 0; i < connectedEdges.length; i++) {
                    let id1: number = Number(edges[connectedEdges[i]].vertex1Id);
                    let id2: number = Number(edges[connectedEdges[i]].vertex2Id);
                    if (id1 != vertexId)
                        queue.push(id1);
                    if (id2 != vertexId)
                        queue.push(id2);
                }
            }
            clusters.push(cluster);
        }
        return clusters;
    }

    private detectOuterBoundaryOfGraph(graph: NodeMap, transformedVertices3D: PV.VertexMap): number[] {
        let outerBoundary: number[] = [];

        let maxIteration: number = Math.max(1, Object.keys(graph).length / 5);
        let seedHistory = {};
        for (let i = 0; i < maxIteration; i++) {
            let seedVertexKey: number = -1;
            let maxDepth: number = -1 * Number.MAX_VALUE;
            for (let key in graph) {
                let keyNumber = Number(key);
                if (seedHistory.hasOwnProperty(keyNumber))
                    continue;
                if (transformedVertices3D[keyNumber].z > maxDepth) {
                    seedVertexKey = keyNumber;
                    maxDepth = transformedVertices3D[keyNumber].z;
                }
            }

            outerBoundary = [];
            outerBoundary.push(seedVertexKey);
            let visitedEdgeIds: number[] = [];
            let found: boolean = false;
            while (true) {
                if (outerBoundary.length == 0)
                    break;
                let currentVertexKey: number = outerBoundary[outerBoundary.length - 1];
                let potentialEdges: number[] = graph[currentVertexKey].adjacentEdgeIds;
                let maxDepthIndex: number = -1;
                let maxDepthValue: number = -1 * Number.MAX_VALUE;
                for (let j = 0; j < potentialEdges.length; j++) {
                    if (visitedEdgeIds.includes(potentialEdges[j]))
                        continue;
                    let v: PV.Vertex = transformedVertices3D[graph[currentVertexKey].adjacentVertexIds[j]];
                    if (v.z > maxDepthValue) {
                        maxDepthIndex = j;
                        maxDepthValue = v.z;
                    }
                }
                if (maxDepthIndex == -1) {
                    outerBoundary.splice(outerBoundary.length - 1, 1);
                    continue;
                }
                let vertexId: number = graph[currentVertexKey].adjacentVertexIds[maxDepthIndex];
                let edgeId: number = graph[currentVertexKey].adjacentEdgeIds[maxDepthIndex];
                outerBoundary.push(vertexId);
                visitedEdgeIds.push(edgeId);
                if (vertexId === seedVertexKey)
                    break;
            }
            if (outerBoundary.length > 0) {
                while (true) {
                    let existingIds = {};
                    let index1: number = -1;
                    let index2: number = -1;
                    for (let j = 0; j < outerBoundary.length - 1; j++) {
                        if (!existingIds.hasOwnProperty(outerBoundary[j])) {
                            existingIds[outerBoundary[j]] = j;
                            continue;
                        }
                        index1 = existingIds[outerBoundary[j]];
                        index2 = j;
                        break;
                    }
                    if (index1 == -1 && index2 == -1) {
                        if (outerBoundary.length >= 3)
                            found = true;
                        break;
                    }
                    let forward: number = index2 - index1 - 1;
                    let backward: number = outerBoundary.length - forward - 2;
                    if (forward < backward) {
                        for (let j = index2; j > index1; j--)
                            outerBoundary.splice(j, 1);
                    } else if (backward < forward) {
                        for (let j = outerBoundary.length - 1; j > index2; j--)
                            outerBoundary.splice(j, 1);
                        for (let j = index1 - 1; j >= 0; j--)
                            outerBoundary.splice(j, 1);
                    } else {
                        found = false;
                        break;
                    }
                }
            }
            if (found)
                break;
            else
                outerBoundary = [];
        }
        return outerBoundary;
    }

    private extractPlanarPolygonFromTwoEdgeVertex(seedId: number, currentGraph: NodeMap, originalGraph: NodeMap, vertices: PV.VertexMap, transformedVertices3D: PV.VertexMap): GraphNode[] {
        let polygon: GraphNode[] = [];

        let v1: PV.Vertex = vertices[seedId];
        let v2: PV.Vertex = vertices[currentGraph[seedId].adjacentVertexIds[0]];
        let v3: PV.Vertex = vertices[currentGraph[seedId].adjacentVertexIds[1]];

        if (WireframeMathUtil.arePointsCollinear(v1, v2, v3, 5.0))
            return polygon;

        let seedPlaneVertices: PV.Vertex[] = [];
        seedPlaneVertices.push(v1, v2, v3);
        let seedPlane: PV.PlaneEquation = WireframeMathUtil.calculatePlaneParameters(seedPlaneVertices, null);

        let polygon1: GraphNode[] = this.findPlanarPolygon(seedId, currentGraph, originalGraph, vertices, transformedVertices3D, seedPlane, -1, -1);
        let checkOverlap: boolean = false;
        let valid: boolean = this.isPolygonValid(polygon1, originalGraph, transformedVertices3D, checkOverlap);
        let roughlyPlanar: boolean = this.isPolygonRoughlyPlanar(polygon1, vertices, seedPlane);
        if (polygon1.length < 3 || !valid || !roughlyPlanar) {
            return polygon;
        }

        let maxVertexDistanceFromSeedPlane: number = this.getMaxVertexDistanceFromPlane(polygon1, vertices, seedPlane);

        let ignoreId1: number = -1;
        let ignoreId2: number = -1;
        for (let i = 0; i < polygon1.length; i++) {
            if (polygon1[i].adjacentVertexIds.length > 2) {
                ignoreId1 = polygon1[i + 1].vertexId;
                break;
            }
        }

        for (let i = polygon1.length - 2; i >= 0; i--) {
            if (polygon1[i].adjacentVertexIds.length > 2) {
                if (i - 1 !== -1)
                    ignoreId2 = polygon1[i - 1].vertexId;
                else
                    ignoreId2 = polygon1[polygon1.length - 1].vertexId;
                break;
            }
        }

        if (ignoreId1 === seedId || ignoreId2 === seedId || ignoreId1 === -1 || ignoreId2 === -1) {
            polygon = polygon1;
            return polygon;
        }

        let polygon2: GraphNode[] = this.findPlanarPolygon(seedId, currentGraph, originalGraph, vertices, transformedVertices3D, seedPlane, ignoreId1, ignoreId2);
        valid = this.isPolygonValid(polygon2, originalGraph, transformedVertices3D, checkOverlap);
        roughlyPlanar = this.isPolygonRoughlyPlanar(polygon2, vertices, seedPlane);

        if (polygon2.length < 3 || !valid || !roughlyPlanar) {
            polygon = polygon1;
            return polygon;
        } else {
            let maxDistance: number = this.getMaxVertexDistanceFromPlane(polygon2, vertices, seedPlane);
            if (maxDistance > 5 * maxVertexDistanceFromSeedPlane)
                polygon = polygon1;
            return polygon;
        }
    }

    private extractPlanarPolgonsStartingFromVertexWithThreeEdges(seedId: number, currentGraph: NodeMap, originalGraph: NodeMap, vertices: PV.VertexMap, transformedVertices3D: PV.VertexMap): object {
        let polygons: GraphNode[][] = [];
        let returnObject: object = [];
        returnObject[0] = -1;
        returnObject[1] = polygons;
        let id1: number = Number(currentGraph[seedId].adjacentVertexIds[0]);
        let id2: number = Number(currentGraph[seedId].adjacentVertexIds[1]);
        let id3: number = Number(currentGraph[seedId].adjacentVertexIds[2]);
        let vSeed: PV.Vertex = vertices[seedId];
        let v1: PV.Vertex = vertices[id1];
        let v2: PV.Vertex = vertices[id2];
        let v3: PV.Vertex = vertices[id3];
        let vSeedTransformed: PV.Vertex = transformedVertices3D[seedId];
        let v1Transformed: PV.Vertex = transformedVertices3D[id1];
        let v2Transformed: PV.Vertex = transformedVertices3D[id2];
        let v3Transformed: PV.Vertex = transformedVertices3D[id3];
        let pSeed: THREE.Vector2 = new THREE.Vector2(vSeedTransformed.x, vSeedTransformed.y);
        let p1: THREE.Vector2 = new THREE.Vector2(v1Transformed.x, v1Transformed.y);
        let p2: THREE.Vector2 = new THREE.Vector2(v2Transformed.x, v2Transformed.y);
        let p3: THREE.Vector2 = new THREE.Vector2(v3Transformed.x, v3Transformed.y);
        let overlap123: boolean = this.overlappingTriangles(pSeed, p1, p2, p3);
        let overlap213: boolean = this.overlappingTriangles(pSeed, p2, p1, p3);
        let overlap312: boolean = this.overlappingTriangles(pSeed, p3, p1, p2);
        let numberOfOverlaps: number = 0;
        if (overlap123)
            numberOfOverlaps++;
        if (overlap213)
            numberOfOverlaps++;
        if (overlap312)
            numberOfOverlaps++;
        if (numberOfOverlaps !== 2)
            return returnObject;

        let sharedId: number = id1;
        if (!overlap213)
            sharedId = id2;
        if (!overlap312)
            sharedId = id3;

        let firstPlanePoints: PV.Vertex[] = [];
        let secondPlanePoints: PV.Vertex[] = [];
        firstPlanePoints.push(vSeed);
        firstPlanePoints.push(vertices[sharedId]);
        let firstPlaneThirdVertexId: number = -1;
        let secondPlaneThirdVertexId: number = -1;
        if (currentGraph[seedId].adjacentVertexIds[0] !== sharedId)
            firstPlaneThirdVertexId = currentGraph[seedId].adjacentVertexIds[0];
        else
            firstPlaneThirdVertexId = currentGraph[seedId].adjacentVertexIds[1];
        firstPlanePoints.push(vertices[firstPlaneThirdVertexId]);
        secondPlanePoints.push(vSeed);
        secondPlanePoints.push(vertices[sharedId]);
        if (currentGraph[seedId].adjacentVertexIds[1] !== sharedId && currentGraph[seedId].adjacentVertexIds[1] !== firstPlaneThirdVertexId)
            secondPlaneThirdVertexId = currentGraph[seedId].adjacentVertexIds[1];
        else
            secondPlaneThirdVertexId = currentGraph[seedId].adjacentVertexIds[2];
        secondPlanePoints.push(vertices[secondPlaneThirdVertexId]);

        let firstPlane: PV.PlaneEquation = WireframeMathUtil.calculatePlaneParameters(firstPlanePoints, null);
        let secondPlane: PV.PlaneEquation = WireframeMathUtil.calculatePlaneParameters(secondPlanePoints, null);

        let firstPolygon: GraphNode[] = this.findPlanarPolygon(seedId, currentGraph, originalGraph, vertices, transformedVertices3D, firstPlane, secondPlaneThirdVertexId, -1);
        let secondPolygon: GraphNode[] = this.findPlanarPolygon(seedId, currentGraph, originalGraph, vertices, transformedVertices3D, secondPlane, firstPlaneThirdVertexId, -1);

        if (firstPolygon != null && firstPolygon.length > 2)
            polygons.push(firstPolygon);
        if (secondPolygon != null && secondPolygon.length > 2)
            polygons.push(secondPolygon);

        returnObject[0] = sharedId;
        return returnObject;
    }

    private overlappingTriangles(base: THREE.Vector2, p1: THREE.Vector2, p2: THREE.Vector2, p3: THREE.Vector2): boolean {
        let firstIntersection: THREE.Vector2 = this.lineLineIntersection2D(base, p2, p1, p3);
        let secondIntersection: THREE.Vector2 = this.lineLineIntersection2D(base, p3, p1, p2);
        if (firstIntersection != null || secondIntersection != null)
            return true;
        else
            return false;
    }

    private lineLineIntersection2D(p1: THREE.Vector2, p2: THREE.Vector2, p3: THREE.Vector2, p4: THREE.Vector2): THREE.Vector2 {
        let s1_x: number = 0;
        let s1_y: number = 0;
        let s2_x: number = 0;
        let s2_y: number = 0;
        s1_x = p2.x - p1.x;
        s1_y = p2.y - p1.y;
        s2_x = p4.x - p3.x;
        s2_y = p4.y - p3.y;

        let s: number = 0;
        let t: number = 0;
        s = (-s1_y * (p1.x - p3.x) + s1_x * (p1.y - p3.y)) / (-s2_x * s1_y + s1_x * s2_y);
        t = (s2_x * (p1.y - p3.y) - s2_y * (p1.x - p3.x)) / (-s2_x * s1_y + s1_x * s2_y);

        if (s > 0 && s < 1 && t > 0 && t < 1) {
            let pt: THREE.Vector2 = new THREE.Vector2(p1.x + (t * s1_x), p1.y + (t * s1_y));
            return pt;
        } else
            return null;
    }

    private findPlanarPolygon(seedId: number, graph: NodeMap, originalGraph: NodeMap, vertices: PV.VertexMap, transformedVertices3D: PV.VertexMap, seedPlane: PV.PlaneEquation, ignoreId1: number, ignoreId2: number): GraphNode[] {
        let polygon: GraphNode[] = [];

        let visitedVertices: number[] = [];
        let lastVisitedVertedId: number = seedId;
        let currentVertexId: number = seedId;
        let validPolygon: boolean = true;
        let iteration: number = 0;

        while (true && iteration < Object.keys(graph).length) {
            let baseNode: GraphNode = graph[currentVertexId];
            let minDistance: number = Number.MAX_VALUE;
            let minDistanceIndex: number = -1;
            for (let i = 0; i < baseNode.adjacentVertexIds.length; i++) {
                if (baseNode.adjacentVertexIds[i] == lastVisitedVertedId)
                    continue;

                if (baseNode.adjacentVertexIds[i] === ignoreId1 || baseNode.adjacentVertexIds[i] === ignoreId2)
                    continue;

                let currentVertex: PV.Vertex = vertices[baseNode.adjacentVertexIds[i]];
                let distance: number = WireframeMathUtil.pointDistanceFromPlaneEquation(currentVertex, seedPlane);
                if (distance < minDistance) {
                    minDistance = distance;
                    minDistanceIndex = i;
                }
            }

            if (minDistanceIndex === -1) {
                validPolygon = false;
                break;
            }

            let nextNodeId: number = baseNode.adjacentVertexIds[minDistanceIndex];

            if (visitedVertices.includes(Number(nextNodeId))) {
                validPolygon = false;
                break;
            }

            visitedVertices.push(Number(nextNodeId));
            lastVisitedVertedId = currentVertexId;
            currentVertexId = nextNodeId;

            if (nextNodeId == seedId)
                break;

            iteration++;
        }

        for (let i = 0; i < visitedVertices.length; i++)
            polygon.push(graph[visitedVertices[i]]);

        if (validPolygon) {
            let checkOverlap: boolean = false;
            validPolygon = this.isPolygonValid(polygon, originalGraph, transformedVertices3D, checkOverlap);
        }

        if (!validPolygon || visitedVertices.length < 3) {
            polygon = [];
            let path: GraphNode[] = this.shortestPathBFS(seedId, graph[seedId].adjacentVertexIds[0], graph, true);
            if (path != null && path.length >= 3) {
                for (let i = path.length - 1; i >= 0; i--)
                    polygon.push(path[i]);

                for (let i = 0; i < path.length; i++) {
                    if (path[i].vertexId === ignoreId1 || path[i].vertexId === ignoreId2) {
                        polygon = [];
                        break;
                    }
                }
            }
        }

        return polygon;
    }

    private isPolygonValid(polygon: GraphNode[], graph: NodeMap, transformedVertices3D: PV.VertexMap, overlapCheck: boolean): boolean {
        let validPolygon: boolean = true;

        let polygonVertexIds: number[] = [];
        for (let i = 0; i < polygon.length; i++) {
            polygonVertexIds.push(Number(polygon[i].vertexId));
        }
        for (let i = 0; i < polygon.length; i++) {
            let before: number = i - 1;
            let after: number = i + 1;
            if (before === -1)
                before = polygon.length - 1;
            if (after === polygon.length)
                after = 0;

            let id1: number = polygon[before].vertexId;
            let id2: number = polygon[after].vertexId;

            let adjacentIds: number[] = polygon[i].adjacentVertexIds;
            for (let j = 0; j < adjacentIds.length; j++) {
                if (adjacentIds[j] == id1 || adjacentIds[j] == id2)
                    continue;
                if (polygonVertexIds.includes(Number(adjacentIds[j]))) {
                    validPolygon = false;
                    break;
                }
            }

            if (!validPolygon)
                break;
        }

        if (validPolygon && overlapCheck) {
            let candidateNodes: GraphNode[] = [];
            let addedNodes: number[] = [];
            for (let i = 0; i < polygon.length; i++) {
                addedNodes.push(Number(polygon[i].vertexId));
            }
            for (let i = 0; i < polygon.length; i++) {
                for (let j = 0; j < polygon[i].adjacentVertexIds.length; j++) {
                    if (!addedNodes.includes(Number(polygon[i].adjacentVertexIds[j]))) {
                        let nodeId: number = polygon[i].adjacentVertexIds[j];
                        candidateNodes.push(graph[nodeId]);
                        addedNodes.push(Number(nodeId));
                    }
                }
            }

            let polygon2D: THREE.Vector2[] = [];
            for (let i = 0; i < polygon.length; i++) {
                let transferred: PV.Vertex = transformedVertices3D[polygon[i].vertexId];
                polygon2D.push(new THREE.Vector2(transferred.x, transferred.y));
            }
            for (let i = 0; i < candidateNodes.length; i++) {
                let transferred: PV.Vertex = transformedVertices3D[candidateNodes[i].vertexId];
                let pt: THREE.Vector2 = new THREE.Vector2(transferred.x, transferred.y);
                let inside: boolean = WireframeMathUtil.performPointInPolygonTest(pt, polygon2D);
                if (inside) {
                    let cutoutValid: boolean = false;
                    for (let j = 0; j < polygon.length; j++) {
                        if (polygon[j].adjacentVertexIds.includes(candidateNodes[i].vertexId)) {
                            let before: number = j - 1;
                            let after: number = j + 1;
                            if (before === -1)
                                before = polygon.length - 1;
                            if (after === polygon.length)
                                after = 0;

                            let v: number[] = [pt.x - polygon2D[j].x, pt.y - polygon2D[j].y];
                            let v1: number[] = [polygon2D[before].x - polygon2D[j].x, polygon2D[before].y - polygon2D[j].y];
                            let v2: number[] = [polygon2D[after].x - polygon2D[j].x, polygon2D[after].y - polygon2D[j].y];
                            WireframeMathUtil.normalizeVector(v);
                            WireframeMathUtil.normalizeVector(v1);
                            WireframeMathUtil.normalizeVector(v2);
                            let angle1: number = WireframeMathUtil.angleBetweenTwoUnitVectors2D(v, v1);
                            let angle2: number = WireframeMathUtil.angleBetweenTwoUnitVectors2D(v, v2);

                            if (angle1 < 10 || angle2 < 10)
                                cutoutValid = true;
                        }
                    }
                    if (!cutoutValid) {
                        validPolygon = false;
                        break;
                    }
                }
            }
        }

        return validPolygon;
    }

    private isPolygonRoughlyPlanar(polygon: GraphNode[], vertices: PV.VertexMap, planeEquation: PV.PlaneEquation): boolean {
        let plane: number[] = [planeEquation.a, planeEquation.b, planeEquation.c, planeEquation.d];
        let projectedPoints: PV.Vertex[] = [];
        for (let i = 0; i < polygon.length; i++)
            projectedPoints.push(WireframeMathUtil.projectPointOnPlane(vertices[polygon[i].vertexId], plane));

        let maxAngle: number = -1;
        for (let i = 0; i < polygon.length; i++) {
            let angle: number = WireframeMathUtil.pointAngleDistanceFromPlane(vertices[polygon[i].vertexId], projectedPoints, plane);
            if (angle === Number.MAX_SAFE_INTEGER)
                continue;
            if (angle > maxAngle)
                maxAngle = angle;
        }

        if (maxAngle === -1 || maxAngle > 10)
            return false;

        return true;
    }

    private shortestPathBFS(seedId: number, targetId: number, graph: NodeMap, ignoreIfSeedIsConnectedToTarget: boolean) {
        let visited: IntBooleanMap = {};
        let prev: NodeMap = {};

        let directions: GraphNode[] = [];
        let q: GraphNode[] = [];
        let current: GraphNode = graph[seedId];
        q.push(current);
        visited[current.vertexId] = true;

        while (q.length > 0) {
            current = q.shift();
            if (current.vertexId === graph[targetId].vertexId)
                break;
            else {
                for (let i = 0; i < current.adjacentVertexIds.length; i++) {
                    if (ignoreIfSeedIsConnectedToTarget)
                        if (current.vertexId === seedId && current.adjacentVertexIds[i] === targetId)
                            continue;
                    let node: GraphNode = graph[current.adjacentVertexIds[i]];
                    if (!visited.hasOwnProperty(node.vertexId)) {
                        q.push(node);
                        visited[node.vertexId] = true;
                        prev[node.vertexId] = current;
                    }
                }
            }
        }
        if (current.vertexId !== targetId)
            return directions;
        let node = graph[targetId];
        while (node !== null) {
            directions.push(node);
            if (!prev.hasOwnProperty(node.vertexId) && node.vertexId === seedId)
                node = null;
            else {
                //console.log("seed = " + seedId + ", target = " + targetId);
                let prevId = prev[node.vertexId].vertexId;
                node = graph[prevId];
            }
        }
        directions.reverse();
        return directions;
    }

    private getMaxVertexDistanceFromPlane(polygon: GraphNode[], vertices: PV.VertexMap, planeEquation: PV.PlaneEquation): number {
        let maxVertexDistanceFromPlane: number = 0;

        for (let i = 0; i < polygon.length; i++) {
            let pt: PV.Vertex = vertices[polygon[i].vertexId];
            let distance: number = WireframeMathUtil.pointDistanceFromPlaneEquation(pt, planeEquation);
            if (distance > maxVertexDistanceFromPlane)
                maxVertexDistanceFromPlane = distance;
        }

        return maxVertexDistanceFromPlane;
    }

    private determineRemovalOfVerticesWithTwoEdges(polygon: GraphNode[], originalOuterBoundary: number[]): boolean[] {
        let remove: boolean[] = [];
        for (let i = 0; i < polygon.length; i++) {
            if (polygon[i].adjacentVertexIds.length == 2)
                remove.push(true);
            else
                remove.push(false);
        }

        let firstFalseIndex: number = -1;
        for (let i = 0; i < remove.length; i++) {
            if (!remove[i]) {
                firstFalseIndex = i;
                break;
            }
        }

        if (firstFalseIndex === -1)
            return remove;

        let orderedPolygon: GraphNode[] = [];
        for (let i = 0; i < polygon.length; i++) {
            let index: number = (i + firstFalseIndex) % polygon.length;
            orderedPolygon.push(polygon[index]);
        }

        let twoEdgeChains: GraphNode[][] = [];
        let newChain: boolean = true;
        let currentChain: GraphNode[] = [];

        for (let i = 0; i < orderedPolygon.length; i++) {
            if (orderedPolygon[i].adjacentVertexIds.length === 2) {
                currentChain.push(orderedPolygon[i]);
                newChain = false;
            } else {
                if (newChain)
                    continue;
                twoEdgeChains.push(currentChain);
                currentChain = [];
                newChain = true;
            }
        }
        if (currentChain.length > 0)
            twoEdgeChains.push(currentChain);

        if (twoEdgeChains.length === 1)
            return remove;

        let validToRemove: boolean = true;
        for (let i = 0; i < twoEdgeChains.length; i++) {
            let found: boolean = false;
            for (let j = 0; j < twoEdgeChains[i].length; j++) {
                if (originalOuterBoundary.includes(twoEdgeChains[i][j].vertexId)) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                validToRemove = false;
                break;
            }
        }
        if (validToRemove)
            return remove;
        return null;
    }

    private identifyEdgesOnGraphConvexHull(graph: NodeMap, transformedVertices3D: PV.VertexMap): GraphNode[][] {
        let vertexIds: number[] = [];
        let graph2DPointsAll: THREE.Vector2[] = [];

        for (let nodeId in graph) {
            let vId: number = graph[nodeId].vertexId;
            vertexIds.push(vId);
            let v: PV.Vertex = transformedVertices3D[vId];
            graph2DPointsAll.push(new THREE.Vector2(v.x, v.y));
        }

        let edgesOnConvexHull: GraphNode[][] = [];
        for (let iteration = -1; iteration < graph2DPointsAll.length; iteration++) {
            let graph2DPoints: THREE.Vector2[] = [];
            for (let i = 0; i < graph2DPointsAll.length; i++)
                graph2DPoints.push(graph2DPointsAll[i]);

            if (iteration !== -1)
                graph2DPoints.splice(iteration, 1);

            let convexHull: Hull = new Hull();
            let hullPts = [];
            for (let i = 0; i < graph2DPoints.length; i++)
                hullPts.push(new THREE.Vector2(graph2DPoints[i].x, graph2DPoints[i].y));

            let hullPoints = convexHull.generateHull(hullPts);

            for (let i = 0; i < hullPoints.length; i++) {
                let x1: number = hullPoints[i].x;
                let y1: number = hullPoints[i].y;

                let x2: number = hullPoints[(i + 1) % hullPoints.length].x;
                let y2: number = hullPoints[(i + 1) % hullPoints.length].y;

                let startId: number = -1, endId: number = -1;

                for (let j = 0; j < vertexIds.length; j++) {
                    let x: number = graph2DPointsAll[j].x;
                    let y: number = graph2DPointsAll[j].y;
                    if (Math.abs(x - x1) < 1e-6 && Math.abs(y - y1) < 1e-6) {
                        startId = vertexIds[j];
                        break;
                    }
                }
                for (let j = 0; j < vertexIds.length; j++) {
                    let x: number = graph2DPointsAll[j].x;
                    let y: number = graph2DPointsAll[j].y;
                    if (Math.abs(x - x2) < 1e-6 && Math.abs(y - y2) < 1e-6) {
                        endId = vertexIds[j];
                        break;
                    }
                }

                if (Number(startId) !== -1 && Number(endId) !== -1 && graph[Number(startId)].adjacentVertexIds.includes(Number(endId))) {
                    let edgeNodes: GraphNode[] = [];
                    edgeNodes.push(graph[startId]);
                    edgeNodes.push(graph[endId]);
                    edgesOnConvexHull.push(edgeNodes);
                }
            }
            if (edgesOnConvexHull.length > 0)
                break;
        }

        let sortedByIds: number[] = [];
        let pairs = {};
        for (let i = 0; i < edgesOnConvexHull.length; i++) {
            sortedByIds.push(edgesOnConvexHull[i][0].vertexId);
            pairs[edgesOnConvexHull[i][0].vertexId] = i;
        }
        sortedByIds.sort(function (a, b) {
            return a - b
        });
        let sortedEdgesOnConvexHull: GraphNode[][] = [];
        for (let i = 0; i < sortedByIds.length; i++) {
            let index: number = pairs[sortedByIds[i]];
            sortedEdgesOnConvexHull.push(edgesOnConvexHull[index]);
        }

        return sortedEdgesOnConvexHull;
    }

    private bestFitPlaneToConvexHullEdge(seedId: number, targetId: number, graph: NodeMap, vertices: PV.VertexMap): PV.PlaneEquation[] {
        let bestPlanes: PV.PlaneEquation[] = [];

        let seedVertex: PV.Vertex = vertices[seedId];
        let targetVertex: PV.Vertex = vertices[targetId];

        let nodesFromSeed: GraphNode[] = [];
        let nodesFromTarget: GraphNode[] = [];

        let seedNode: GraphNode = graph[seedId];
        let ignoreIds: number[] = [];
        ignoreIds.push(Number(seedId));
        ignoreIds.push(Number(targetId));
        let found: boolean = false;
        let iteration: number = 0;

        while (!found && iteration < Object.keys(vertices).length) {
            for (let i = 0; i < seedNode.adjacentVertexIds.length; i++) {
                if (ignoreIds.includes(Number(seedNode.adjacentVertexIds[i])))
                    continue;
                let otherVertex: PV.Vertex = vertices[seedNode.adjacentVertexIds[i]];
                if (!WireframeMathUtil.arePointsCollinear(seedVertex, targetVertex, otherVertex, 5.0)) {
                    nodesFromSeed.push(graph[seedNode.adjacentVertexIds[i]]);
                    found = true;
                }
            }

            if (!found) {
                let foundNextNode: boolean = false;
                for (let i = 0; i < seedNode.adjacentVertexIds.length; i++) {
                    if (!ignoreIds.includes(Number(seedNode.adjacentVertexIds[i]))) {
                        ignoreIds.push(Number(seedNode.adjacentVertexIds[i]));
                        seedNode = graph[seedNode.adjacentVertexIds[i]];
                        foundNextNode = true;
                        break;
                    }
                }
                if (!foundNextNode)
                    return bestPlanes;
            }
            iteration++;
        }

        let targetNode: GraphNode = graph[targetId];
        ignoreIds = [];
        ignoreIds.push(Number(seedId));
        ignoreIds.push(Number(targetId));
        found = false;
        iteration = 0;
        while (!found && iteration < Object.keys(vertices).length) {
            for (let i = 0; i < targetNode.adjacentVertexIds.length; i++) {
                if (ignoreIds.includes(Number(targetNode.adjacentVertexIds[i])))
                    continue;
                let otherVertex: PV.Vertex = vertices[targetNode.adjacentVertexIds[i]];
                if (!WireframeMathUtil.arePointsCollinear(targetVertex, seedVertex, otherVertex, 5.0)) {
                    nodesFromTarget.push(graph[targetNode.adjacentVertexIds[i]]);
                    found = true;
                }
            }
            if (!found) {
                let foundNextNode: boolean = false;
                for (let i = 0; i < targetNode.adjacentVertexIds.length; i++) {
                    if (!ignoreIds.includes(Number(targetNode.adjacentVertexIds[i]))) {
                        ignoreIds.push(Number(targetNode.adjacentVertexIds[i]));
                        targetNode = graph[targetNode.adjacentVertexIds[i]];
                        foundNextNode = true;
                        break;
                    }
                }
                if (!foundNextNode)
                    return bestPlanes;
            }
            iteration++;
        }

        let bestPlaneInliers: number[] = [];
        let bestPlaneError: number[] = [];

        for (let i = 0; i < nodesFromSeed.length; i++) {
            for (let j = 0; j < nodesFromTarget.length; j++) {
                let planePointsSeed: PV.Vertex[] = [];
                planePointsSeed.push(vertices[seedNode.vertexId]);
                planePointsSeed.push(vertices[targetNode.vertexId]);
                planePointsSeed.push(vertices[nodesFromSeed[i].vertexId]);
                let planeSeed: PV.PlaneEquation = WireframeMathUtil.calculatePlaneParameters(planePointsSeed, null);

                let planePointsTarget: PV.Vertex[] = [];
                planePointsTarget.push(vertices[seedNode.vertexId]);
                planePointsTarget.push(vertices[targetNode.vertexId]);
                planePointsTarget.push(vertices[nodesFromTarget[j].vertexId]);
                let planeTarget: PV.PlaneEquation = WireframeMathUtil.calculatePlaneParameters(planePointsTarget, null);

                let angleSeed: number = WireframeMathUtil.pointAngleDistanceFromPlaneEquation(vertices[nodesFromTarget[j].vertexId], planePointsSeed, planeSeed);
                let angleTarget: number = WireframeMathUtil.pointAngleDistanceFromPlaneEquation(vertices[nodesFromSeed[i].vertexId], planePointsTarget, planeTarget);

                let inliers: number = 0;
                let error: number = 0;
                if (angleSeed < 4)
                    inliers++;
                if (angleTarget < 4)
                    inliers++;
                error += angleSeed;
                error += angleTarget;

                if (inliers < 1)
                    continue;

                let selectedPlane: PV.PlaneEquation = planeSeed;
                if (angleTarget < angleSeed) {
                    selectedPlane = planeTarget;
                }

                let inserted: boolean = false;
                for (let k = 0; k < bestPlanes.length; k++) {
                    if ((inliers > bestPlaneInliers[k]) || (inliers == bestPlaneInliers[k] && error < bestPlaneError[k])) {
                        bestPlanes.splice(k, 0, selectedPlane);
                        bestPlaneInliers.splice(k, 0, inliers);
                        bestPlaneError.splice(k, 0, error);
                        inserted = true;
                        break;
                    }
                }
                if (!inserted) {
                    bestPlanes.push(selectedPlane);
                    bestPlaneInliers.push(inliers);
                    bestPlaneError.push(error);
                }
            }
        }

        return bestPlanes;
    }

    private polygonInOriginalGraph(polygon: GraphNode[], originalGraph: NodeMap): GraphNode[] {
        let originalPolygon: GraphNode[] = [];
        for (let i = 0; i < polygon.length; i++)
            originalPolygon.push(originalGraph[polygon[i].vertexId]);
        return originalPolygon;
    }

    private extractPlanarPolygonFromConvexHullEdge(edge: GraphNode[], currentGraph: NodeMap, originalGraph: NodeMap, vertices: PV.VertexMap, seedPlane: PV.PlaneEquation, transformedVertices3D: PV.VertexMap): GraphNode[] {
        let polygon: GraphNode[] = [];

        let visitedVertices: number[] = [];
        visitedVertices.push(Number(edge[0].vertexId));
        let lastVisitedVertexId: number = Number(edge[0].vertexId);
        let currentVertexId: number = Number(edge[0].vertexId);
        let targetNodeId: number = Number(edge[1].vertexId);
        let validPolygon: boolean = true;
        let iteration: number = 0;

        while (true && iteration < Object.keys(vertices).length) {
            let baseNode: GraphNode = currentGraph[currentVertexId];
            let minDistance: number = Number.MAX_VALUE;
            let minDistanceIndex: number = -1;
            for (let i = 0; i < baseNode.adjacentVertexIds.length; i++) {
                if (baseNode.adjacentVertexIds[i] === lastVisitedVertexId)
                    continue;
                if (edge[0].vertexId === currentVertexId && edge[1].vertexId === baseNode.adjacentVertexIds[i])
                    continue;

                if (baseNode.adjacentVertexIds[i] === edge[1].vertexId) {
                    minDistanceIndex = i;
                    break;
                }

                let currentNode: GraphNode = currentGraph[baseNode.adjacentVertexIds[i]];
                let currentVertex: PV.Vertex = vertices[currentNode.vertexId];
                let distance: number = WireframeMathUtil.pointDistanceFromPlaneEquation(currentVertex, seedPlane);

                let minDistanceNext: number = Number.MAX_SAFE_INTEGER;
                for (let j = 0; j < currentNode.adjacentVertexIds.length; j++) {
                    if (currentNode.adjacentVertexIds[j] == baseNode.vertexId)
                        continue;
                    let nextVertex: PV.Vertex = vertices[currentNode.adjacentVertexIds[j]];
                    let distanceNext = WireframeMathUtil.pointDistanceFromPlaneEquation(nextVertex, seedPlane);
                    if (distanceNext < minDistanceNext)
                        minDistanceNext = distanceNext;
                }
                distance += minDistanceNext;

                if (distance < minDistance) {
                    minDistance = distance;
                    minDistanceIndex = i;
                }
            }

            if (minDistanceIndex === -1) {
                validPolygon = false;
                break;
            }

            let nextNodeId: number = baseNode.adjacentVertexIds[minDistanceIndex];

            if (visitedVertices.includes(Number(nextNodeId))) {
                validPolygon = false;
                break;
            }

            visitedVertices.push(Number(nextNodeId));
            lastVisitedVertexId = currentVertexId;
            currentVertexId = nextNodeId;
            if (nextNodeId == targetNodeId)
                break;

            iteration++;
        }

        for (let i = 0; i < visitedVertices.length; i++)
            polygon.push(currentGraph[visitedVertices[i]]);

        let overlapCheck: boolean = true;
        if (!validPolygon || visitedVertices.length < 3 || !this.isPolygonValid(polygon, originalGraph, transformedVertices3D, overlapCheck) || !this.isPolygonRoughlyPlanar(polygon, vertices, seedPlane))
            polygon = [];

        return polygon;
    }

    private cleanGraphFromOpenPolygons(graph: NodeMap): void {
        let iteration: number = 0;
        while (true && iteration < Object.keys(graph).length) {
            let found: boolean = false;
            let nodeId: any;
            for (nodeId in graph) {
                let nodeIdNumber = Number(nodeId);
                if (graph[nodeIdNumber].adjacentVertexIds.length < 2) {
                    this.removeNodeFromGraph(graph, nodeIdNumber);
                    found = true;
                    break;
                }
            }
            if (!found)
                break;
        }
    }

    private removeNodeFromGraph(graph: NodeMap, nodeId: number): void {
        if (graph.hasOwnProperty(nodeId)) {
            delete graph[nodeId];
        }

        for (let id in graph) {
            let idNumber: number = Number(id);
            let currentNode: GraphNode = graph[idNumber];
            for (let i = currentNode.adjacentVertexIds.length - 1; i >= 0; i--) {
                if (currentNode.adjacentVertexIds[i] === nodeId) {
                    currentNode.adjacentVertexIds.splice(i, 1);
                    currentNode.adjacentEdgeIds.splice(i, 1);
                }
            }
        }
    }

    private removeEdgeFromGraph(graph: NodeMap, vertex1Id: number, vertex2Id: number): void {
        if (graph.hasOwnProperty(vertex1Id)) {
            let node1: GraphNode = graph[vertex1Id];
            for (let i = 0; i < node1.adjacentVertexIds.length; i++) {
                if (node1.adjacentVertexIds[i] == vertex2Id) {
                    node1.adjacentVertexIds.splice(i, 1);
                    node1.adjacentEdgeIds.splice(i, 1);
                    break;
                }
            }
        }
        if (graph.hasOwnProperty(vertex2Id)) {
            let node2: GraphNode = graph[vertex2Id];
            for (let i = 0; i < node2.adjacentVertexIds.length; i++) {
                if (node2.adjacentVertexIds[i] == vertex1Id) {
                    node2.adjacentVertexIds.splice(i, 1);
                    node2.adjacentEdgeIds.splice(i, 1);
                    break;
                }
            }
        }
    }

    private buildGraph(wireframe: PV.Wireframe, cluster: number[]): NodeMap {
        let graph: NodeMap = {};

        if (cluster == null || cluster.length == 0) {
            for (let key in wireframe.vertices) {
                let keyNumber: number = Number(key);
                graph[keyNumber] = new GraphNode(keyNumber);
            }
        } else {
            for (let i = 0; i < cluster.length; i++) {
                let vertexId: number = cluster[i];
                graph[vertexId] = new GraphNode(vertexId);
            }
        }

        let missingVertices: IntegerListMap = {};
        for (let key in wireframe.edges) {
            let keyNumber: number = Number(key);
            let v1Id: number = Number(wireframe.edges[keyNumber].vertex1Id);
            let v2Id: number = Number(wireframe.edges[keyNumber].vertex2Id);

            if (!graph.hasOwnProperty(v1Id) || !graph.hasOwnProperty(v2Id))
                continue;

            graph[v1Id].adjacentVertexIds.push(v2Id);
            graph[v2Id].adjacentVertexIds.push(v1Id);
            graph[v1Id].adjacentEdgeIds.push(keyNumber);
            graph[v2Id].adjacentEdgeIds.push(keyNumber);
        }

        return graph;
    }

    private testPolygonForPlanarity(polygon: GraphNode[], vertices: PV.VertexMap, angleThresholdForPlanarityConstraint: number) {
        let maxInliers: number = 0;
        for (let i = 0; i < polygon.length; i++) {
            let before: number = i - 1;
            let after: number = i + 1;
            if (before === -1)
                before = polygon.length - 1;
            if (after === polygon.length)
                after = 0;

            let vs: PV.Vertex[] = [];
            vs.push(vertices[polygon[before].vertexId]);
            vs.push(vertices[polygon[i].vertexId]);
            vs.push(vertices[polygon[after].vertexId]);
            let parameters: PV.PlaneEquation = WireframeMathUtil.calculatePlaneParameters(vs, null);
            let inliers: number = 0;
            for (let j = 0; j < polygon.length; j++) {
                if (j === before || j === i || j === after)
                    inliers++;
                else {
                    let angleDistance: number = WireframeMathUtil.pointAngleDistanceFromPlaneEquation(vertices[polygon[j].vertexId], vs, parameters);
                    if (angleDistance <= angleThresholdForPlanarityConstraint)
                        inliers++;
                }
            }
            if (inliers > maxInliers)
                maxInliers = inliers;
        }
        if (maxInliers === polygon.length)
            return true;
        else
            return false;
    }

    public static updatePlaneParameters(plane: PlaneElement, wireframe: Wireframe,  gravity:THREE.Plane) {
        let planeVertices3D: PV.Vertex[] = [];
        for (let i = 0; i < plane.pvObject.vertexIds.length; i++) {
            planeVertices3D.push(wireframe.vertices[plane.pvObject.vertexIds[i]]);
        }
        //let planeParameters: PV.PlaneEquation = WireframeMathUtil.calculatePlaneParameters(planeVertices3D);
        //let planeParameters: PV.PlaneEquation = WireframeMathUtil.alignPlaneParameterEquationWRTGravity(planeParametersNotAligned, wireframe.transform.gravityPlane);
        //plane.bestFitPlane = planeParameters;

        let planeVertices3DProjected: PV.Vertex[] = [];
        for (let i = 0; i < planeVertices3D.length; i++)
            planeVertices3DProjected.push(WireframeMathUtil.projectPointOnPlaneUsingPlaneEquation(planeVertices3D[i], plane.pvObject.bestFitPlane));

        let RC: object = WireframeMathUtil.getPlaneCoordinateSystemUsingPlaneEquation(plane.pvObject.bestFitPlane, planeVertices3DProjected[0], planeVertices3DProjected[Math.floor(planeVertices3DProjected.length / 2)]);
        let planeVertices3DProjectedTransferred: PV.Vertex[] = [];
        let planeVertices2D: THREE.Vector2[] = [];
        for (let i = 0; i < planeVertices3DProjected.length; i++) {
            let projectedTransferred: PV.Vertex = WireframeMathUtil.convertPointCoordinateSystem(planeVertices3DProjected[i], RC[0], RC[1]);
            planeVertices3DProjectedTransferred.push(projectedTransferred);
            planeVertices2D.push(new THREE.Vector2(projectedTransferred.x, projectedTransferred.y));
        }

        let surfaceArea: number = WireframeMathUtil.computeSurfaceArea(planeVertices2D);
        let perimeter: number = WireframeMathUtil.computePerimeter(planeVertices3D);
        let slope: number = WireframeMathUtil.computePitchUsingPlaneEquation(plane.pvObject.bestFitPlane, [gravity.normal.x, gravity.normal.y, gravity.normal.z, gravity.constant] );
        slope = slope % 180;
        if (slope > 90) slope = 180 - slope;
        wireframe.findProperty(AreaProperty, true).setValue(plane, surfaceArea);
        wireframe.findProperty(PerimeterProperty, true).setValue(plane, perimeter);
        wireframe.findProperty(SlopeProperty, true).setValue(plane, slope);

        plane.pvObject.area = surfaceArea;
        plane.pvObject.perimeter = perimeter;
        plane.pvObject.slope = slope;
        plane.pvObject.pitch = slope;
    }

    private populatePlanarSurfaceObject(polygon: GraphNode[], vertices: PV.VertexMap): PV.Plane {
        let planeVertices3D: PV.Vertex[] = [];
        let planeVertexIds: number[] = [];
        let planeEdgeIds: number[] = [];

        for (let i = 0; i < polygon.length; i++) {
            let id: number = polygon[i].vertexId;
            planeVertices3D.push(vertices[id]);
            planeVertexIds.push(id);

            let next: number = i + 1;
            if (next === polygon.length)
                next = 0;
            for (let j = 0; j < polygon[i].adjacentVertexIds.length; j++) {
                if (polygon[i].adjacentVertexIds[j] === polygon[next].vertexId) {
                    planeEdgeIds.push(polygon[i].adjacentEdgeIds[j]);
                    break;
                }
            }
        }
        let planarSurface: PV.Plane = new PV.Plane();
        planarSurface.vertexIds = planeVertexIds;
        planarSurface.edgeIds = planeEdgeIds;

        return planarSurface;
    }

    private orderPlanarSurfacesBasedOnOverlap(surfaces: PV.PlaneMap, transformedVertices3D: PV.VertexMap, significantOverlapSurfaceRemovalIds: number[]): number[] {
        let polygons2D: THREE.Vector2[][] = [];
        for (let key in surfaces) {
            let polygonVertices2D: THREE.Vector2[] = [];
            let vertexIds: number[] = surfaces[Number(key)].vertexIds;
            for (let j = 0; j < vertexIds.length; j++) {
                let v: PV.Vertex = transformedVertices3D[vertexIds[j]];
                polygonVertices2D.push(new THREE.Vector2(v.x, v.y));
            }
            polygons2D.push(polygonVertices2D);
        }

        let transferredPlaneParameters: PlaneEquationMap = {};
        let overlapAboveInfo: IntegerListMap = {};
        let surfaceKeys: number[] = [];
        for (let key in surfaces) {
            overlapAboveInfo[Number(key)] = [];
            surfaceKeys.push(Number(key));
        }

        for (let i = 0; i < surfaceKeys.length - 1; i++) {
            for (let j = i + 1; j < surfaceKeys.length; j++) {
                let polygon1: LibraryPolygon = new LibraryPolygon(polygons2D[surfaceKeys[i]], false);
                let polygon2: LibraryPolygon = new LibraryPolygon(polygons2D[surfaceKeys[j]], false);

                let overlapResult: LibraryPolygon = PolyBool.intersect(polygon1, polygon2);
                let overlapVertices2D = this.getVertices2D(overlapResult);
                let area: number = WireframeMathUtil.computeSurfaceArea(overlapVertices2D);
                if (area > 0) {
                    let centroid: THREE.Vector2 = WireframeMathUtil.calculateCentroid(overlapVertices2D);
                    if (!transferredPlaneParameters.hasOwnProperty(surfaceKeys[i])) {
                        let planeVertices: PV.Vertex[] = [];
                        for (let k = 0; k < surfaces[surfaceKeys[i]].vertexIds.length; k++)
                            planeVertices.push(transformedVertices3D[surfaces[surfaceKeys[i]].vertexIds[k]]);
                        transferredPlaneParameters[surfaceKeys[i]] = WireframeMathUtil.calculatePlaneParameters(planeVertices, null);
                    }
                    if (!transferredPlaneParameters.hasOwnProperty(Number(surfaceKeys[j]))) {
                        let planeVertices: PV.Vertex[] = [];
                        for (let k = 0; k < surfaces[surfaceKeys[j]].vertexIds.length; k++)
                            planeVertices.push(transformedVertices3D[surfaces[surfaceKeys[j]].vertexIds[k]]);
                        transferredPlaneParameters[surfaceKeys[j]] = WireframeMathUtil.calculatePlaneParameters(planeVertices, null);
                    }

                    let iPlane: PV.PlaneEquation = transferredPlaneParameters[surfaceKeys[i]];
                    let jPlane: PV.PlaneEquation = transferredPlaneParameters[surfaceKeys[j]];
                    let zi: number = -1 * (iPlane.a * centroid.x + iPlane.b * centroid.y + iPlane.d) / iPlane.c;
                    let zj: number = -1 * (jPlane.a * centroid.x + jPlane.b * centroid.y + jPlane.d) / jPlane.c;

                    if (zi >= zj)
                        overlapAboveInfo[surfaceKeys[i]].push(surfaceKeys[j]);
                    else
                        overlapAboveInfo[surfaceKeys[j]].push(surfaceKeys[i]);
                }
            }
        }

        let edgeToSurface: IntegerListMap = {};
        for (let key in surfaces) {
            let edgeIds: number[] = surfaces[Number(key)].edgeIds;
            for (let i = 0; i < edgeIds.length; i++) {
                if (!edgeToSurface.hasOwnProperty(edgeIds[i]))
                    edgeToSurface[edgeIds[i]] = [];
                edgeToSurface[edgeIds[i]].push(Number(key));
            }
        }

        significantOverlapSurfaceRemovalIds = [];
        for (let key in Object.keys(overlapAboveInfo)) {
            let keyNum: number = Number(key);

            if (isNaN(keyNum))
                continue;
            if (overlapAboveInfo[keyNum].length == 0)
                continue;

            let surfaceIdsSharingEdges: number[] = [];
            for (let i = 0; i < surfaces[keyNum].edgeIds.length; i++) {
                let ids: number[] = edgeToSurface[surfaces[keyNum].edgeIds[i]];
                for (let j = 0; j < ids.length; j++) {
                    if (!surfaceIdsSharingEdges.includes(ids[j]))
                        surfaceIdsSharingEdges.push(ids[j]);
                }
            }
            let originalPolygon: LibraryPolygon = new LibraryPolygon(polygons2D[keyNum], false);
            let originalPolygonVertices: THREE.Vector2[] = this.getVertices2D(originalPolygon);
            let originalArea: number = Math.abs(WireframeMathUtil.computeSurfaceArea(originalPolygonVertices));
            for (let i = 0; i < overlapAboveInfo[keyNum].length; i++) {
                if (surfaceIdsSharingEdges.includes(overlapAboveInfo[keyNum][i])) {
                    let otherPolygon: LibraryPolygon = new LibraryPolygon(polygons2D[overlapAboveInfo[keyNum][i]], false);
                    originalPolygon = PolyBool.difference(originalPolygon, otherPolygon);
                }
            }
            let newPolygonVertices: THREE.Vector2[] = this.getVertices2D(originalPolygon);
            let newArea: number = Math.abs(WireframeMathUtil.computeSurfaceArea(newPolygonVertices));
            if (newArea / originalArea < 0.01)
                significantOverlapSurfaceRemovalIds.push(keyNum);
        }

        let surfaceOrder: number[] = [];
        let overlapAboveInfoTemp: IntegerListMap = {};
        for (let key in Object.keys(overlapAboveInfo)) {
            let keyNum: number = Number(key);
            if (isNaN(keyNum))
                continue;
            overlapAboveInfoTemp[keyNum] = [];
            for (let i = 0; i < overlapAboveInfo[keyNum].length; i++)
                overlapAboveInfoTemp[keyNum].push(overlapAboveInfo[keyNum][i]);
        }

        while (true) {
            let found: boolean = false;
            for (let key in Object.keys(overlapAboveInfoTemp)) {
                let keyNum: number = Number(key);
                if (isNaN(keyNum))
                    continue;
                if (overlapAboveInfoTemp[keyNum].length == 0 && !surfaceOrder.includes(keyNum)) {
                    surfaceOrder.push(keyNum);
                    found = true;
                    for (let keyTemp in Object.keys(overlapAboveInfoTemp)) {
                        let keyTempNum: number = Number(keyTemp);
                        if (isNaN(keyTempNum))
                            continue;
                        for (let k = 0; k < overlapAboveInfoTemp[keyTempNum].length; k++) {
                            if (overlapAboveInfoTemp[keyTempNum][k] == keyNum) {
                                overlapAboveInfoTemp[keyTempNum].splice(k, 1);
                                break;
                            }
                        }
                    }
                }
            }
            if (!found)
                break;
        }

        return surfaceOrder;
    }

    public getVertices2D = function (poly: LibraryPolygon): THREE.Vector2[] {
        let vertices: THREE.Vector2[] = [];
        if (poly.regions.length < 1)
            return vertices;

        for (let i = 0; i < poly.regions[0].length; i++) {
            let coordX: number = poly.regions[0][i][0];
            let coordY: number = poly.regions[0][i][1];
            vertices.push(new THREE.Vector2(coordX, coordY));
        }
        return vertices;
    }

    private getPlaneEdgeIds(vertexIds: number[], edges: PV.EdgeMap): number[] {
        let edgeIds: number[] = [];

        for (let i = 0; i < vertexIds.length; i++) {
            let v1Id: number = vertexIds[i];
            let v2Id: number = vertexIds[(i + 1) % vertexIds.length];

            let edgeId: number = -1;

            let key: any;
            for (key in edges) {
                if ((edges[key].vertex1Id == v1Id && edges[key].vertex2Id == v2Id) || (edges[key].vertex1Id == v2Id && edges[key].vertex2Id == v1Id)) {
                    edgeId = key;
                }
            }

            if (edgeId != -1) {
                edgeIds.push(Number(edgeId))
            }
            else {
                console.log("No edge found between vertexId " + v1Id + " and " + v2Id);
                throw DOMException.VALIDATION_ERR;
            }
        }

        return edgeIds;
    }

}
