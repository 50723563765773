import * as AWS from 'aws-sdk';
import * as AmazonS3URI from "amazon-s3-uri/lib/amazon-s3-uri";
import {S3Object} from "./s3Object";
import {PV} from "../../wireframe";
import FrameMetadata = PV.FrameMetadata;
import {PointivoS3Singleton} from "../../s3Singleton";

export interface ResourceType {
    id: number;
    name: string;
}

export interface Resource {
    name: string;
    url: string;
    metadata: any;
    resourceType: ResourceType;
}

export interface Credentials {
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken: string;
    expiration: Date;
}

export class ProjectResourceManager {
    public projectData: any;
    public s3Bucket: string;
    public projectKey: string;
    public credentials: Credentials;
    public db;
    public numCurrentTransfers = 0;
    public maxNumConcurrentTransfers = 1;
    public imageData: Map<string, string> = new Map<string, string>();
    public stringData: Map<string, string> = new Map<string, string>();
    public activeTransfers: Map<string, S3Object> = new Map<string, S3Object>();
    public queuedTransfers: S3Object[] = [];

    public setCredentials(creds: Credentials) {
        this.credentials = creds;
        AWS.config.update({
            credentials: this.credentials
        });
    }

    public getS3ObjectForResource(resource: Resource): S3Object {
        let xfer = new S3Object();
        xfer.key = this.projectKey + "/" + resource.url;
        xfer.bucket = this.s3Bucket;
        return xfer;
    }

    public getURL(s3Object: S3Object) {
        return "https://s3.amazonaws.com/" + s3Object.bucket + "/" + s3Object.key
    }

    public getS3ObjectFromURL(url: String) {
        let s3Uri = AmazonS3URI(url);
        let s3Obj = new S3Object();
        s3Obj.key = s3Uri.key;
        s3Obj.bucket = s3Uri.bucket;
        return s3Obj
    }

    public generatePresignedUrl(s3Object: S3Object) {
        let url = PointivoS3Singleton.newAWSS3Instance().getSignedUrl("getObject", {
            Bucket: s3Object.bucket,
            Key: s3Object.key,
            Expires: 86400 // 24 hours
        });
        return url
    }

    public getS3ObjectKey(key:string): S3Object {
        let s3Object: S3Object = {
            bucket: this.s3Bucket,
            key: key,
            onProgress: [],
            onComplete: []
        };
        return s3Object;
    }

    public getFrameKey(frameId: number): S3Object {
        let fmd: FrameMetadata = this.projectData.metadata.rendered["frame-" + frameId];
        if (!fmd) return null;
        let key = this.projectKey + "/metadata/rendered/" + fmd.filename;
        let s3Object: S3Object = this.getS3ObjectKey(key);
        return s3Object;
    }

    public hasFrameThumbnailKey(frameId: number) {
        let fmd: FrameMetadata = this.projectData.metadata.rendered["frame-" + frameId];
        let derivedThumbnailFrame = fmd.derivedFrames["thumb"];
        return !!derivedThumbnailFrame;
    }

    public getFrameThumbnailKey(frameId: number): S3Object {
        let fmd: FrameMetadata = this.projectData.metadata.rendered["frame-" + frameId];
        let key = this.projectKey + "/metadata/rendered/" + fmd.derivedFrames["thumb"].filename;
        let s3Object: S3Object = {
            bucket: this.s3Bucket,
            key: key,
            onProgress: [],
            onComplete: []
        };
        return s3Object;
    }

    public hasFramePrintKey(frameId: number) {
        let fmd: FrameMetadata = this.projectData.metadata.rendered["frame-" + frameId];
        let derivedThumbnailFrame = fmd.derivedFrames["print"];
        return !!derivedThumbnailFrame;
    }

    public getFramePrintKey(frameId: number): S3Object {
        let fmd: FrameMetadata = this.projectData.metadata.rendered["frame-" + frameId];
        let key = this.projectKey + "/metadata/rendered/" + fmd.derivedFrames["print"].filename;
        let s3Object: S3Object = {
            bucket: this.s3Bucket,
            key: key,
            onProgress: [],
            onComplete: []
        };
        return s3Object;
    }

    public getObjectListing(s3Object: S3Object, callback: Function) {
        let that = this;
        let params = {
            Delimiter: "/",
            Prefix: s3Object.key + "/",
            Bucket: s3Object.bucket
        };
        let req = PointivoS3Singleton.newAWSS3Instance().listObjects(params, function (err, data) {
            console.log("listObjects " + s3Object.key, data);
            console.log("err", err)

        });
    }

    public getImageData(s3Object: S3Object, onComplete: Function, onProgress: Function) {
        let self = this;
        let existingImg = this.imageData[s3Object.key];
        if (existingImg) {
            onComplete.apply(this, [existingImg]);
        } else {
            let existingTransfer: S3Object = this.activeTransfers[s3Object.key];
            if (existingTransfer) {
                if (onProgress) existingTransfer.onProgress.push(onProgress);
                if (onComplete) existingTransfer.onComplete.push(function () {
                    onComplete.apply(this, [self.imageData[s3Object.key]]);
                });
            } else {
                s3Object.onComplete.unshift(function (err, data) {
                    if (data && data.Body) {
                        let blob = new Blob([data.Body], {type: data.ContentType});
                        self.imageData[s3Object.key] = window.URL.createObjectURL(blob);
                    }
                });
                if (onComplete) s3Object.onComplete.push(function () {
                    onComplete.apply(this, [self.imageData[s3Object.key]])
                });
                if (onProgress) s3Object.onProgress.push(function (o) {
                    onProgress.apply(this, [o]);
                });
                this.downloadS3Key(s3Object);
            }
        }
    }

    public getStringData(s3Object: S3Object, onComplete: Function, onProgress: Function) {
        let self = this;
        let existingJson = this.stringData[s3Object.key];
        if (existingJson) {
            onComplete.apply(this, [existingJson]);
        } else {
            let existingTransfer: S3Object = this.activeTransfers[s3Object.key];
            if (existingTransfer) {
                if (onProgress) existingTransfer.onProgress.push(onProgress);
                if (onComplete) existingTransfer.onComplete.push(function () {
                    onComplete.apply(this, [self.stringData[s3Object.key]]);
                });
            } else {
                s3Object.onComplete.unshift(function (err, response) {
                    if (response && response.Body) {
                        self.stringData[s3Object.key] = response.Body.toString('utf-8');
                    }
                });
                if (onComplete) s3Object.onComplete.push(function () {
                    onComplete.apply(this, [self.stringData[s3Object.key]])
                });
                if (onProgress) s3Object.onProgress.push(function (o) {
                    onProgress.apply(this, [o]);
                });
                this.downloadS3Key(s3Object);
            }
        }
    }

    public downloadS3Key(s3Object: S3Object) {
        this.activeTransfers[s3Object.key] = s3Object;
        let that = this;
        let params = {
            Key: s3Object.key,
            Bucket: s3Object.bucket
        };
        let req = PointivoS3Singleton.newAWSS3Instance().getObject(params, function (err, data) {
            if (s3Object.onComplete) s3Object.onComplete.forEach(function (fn) {
                fn.apply(this, [err, data])
            });
            delete that.activeTransfers[s3Object.key];
        });
        req.on('httpDownloadProgress', function (progress, response) {
            if (s3Object.onProgress) s3Object.onProgress.forEach(function (fn) {
                fn.apply(this, [progress, response])
            });
        })
    }

    public enqueueTransfer(transfer: S3Object) {
        this.queuedTransfers.push(transfer);
        this.consumeQueuedTransfers();
    }

    public consumeQueuedTransfers() {
        let numToStart = this.maxNumConcurrentTransfers - this.numCurrentTransfers;
        if (numToStart > 0) {
            for (let i = 0; i < numToStart; i++) {
                let that = this;
                let transfer: S3Object = this.queuedTransfers.shift();
                if (!transfer) return;
                this.numCurrentTransfers++;
                this.getImageData(transfer, function () {
                        that.numCurrentTransfers--;
                        that.consumeQueuedTransfers();
                    },
                    null
                );
            }
        }
    }
}
