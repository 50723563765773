////////////////////////////////////////////////minpack /////////////////////////////////////////////////////////////////////////


//this object contains some functions from MinPack library
var MP = new Object;

//converted from minpack
MP.dpmpar = function (i) {
    "use strict";
    switch (i) {
        case 2:
            return 2.22507385852e-308;
        case 3:
            return 1.79769313485e308;
        //case 3:
        //	return 2.22044604926e-16 / 2;
        case 1:
            return 2.22044604926e-16;
    }
    return /* undefined */;
}

// converted from minpack
MP.enorm = function (n, x, offset) {
    "use strict";
    /* Initialized data */

    if (typeof offset === "undefined") {
        offset = 0;
    }

    var rdwarf = 3.834e-20;
    var rgiant = 1.304e19;

    /* System generated locals */
    var ret_val, d1;

    /* Local variables */
    var i;
    var s1, s2, s3, xabs, x1max, x3max, agiant, floatn;

    /*     ********** */

    /*     function enorm */

    /*     given an n-vector x, this function calculates the */
    /*     euclidean norm of x. */

    /*     the euclidean norm is computed by accumulating the sum of */
    /*     squares in three different sums. the sums of squares for the */
    /*     small and large components are scaled so that no overflows */
    /*     occur. non-destructive underflows are permitted. underflows */
    /*     and overflows do not occur in the computation of the unscaled */
    /*     sum of squares for the intermediate components. */
    /*     the definitions of small, intermediate and large components */
    /*     depend on two constants, rdwarf and rgiant. the main */
    /*     restrictions on these constants are that rdwarf**2 not */
    /*     underflow and rgiant**2 not overflow. the constants */
    /*     given here are suitable for every known computer. */

    /*     the function statement is */

    /*       double precision function enorm(n,x) */

    /*     where */

    /*       n is a positive integer input variable. */

    /*       x is an input array of length n. */

    /*     subprograms called */

    /*       fortran-supplied ... dabs,dsqrt */

    /*     argonne national laboratory. minpack project. march 1980. */
    /*     burton s. garbow, kenneth e. hillstrom, jorge j. more */

    /*     ********** */

    s1 = 0.0;
    s2 = 0.0;
    s3 = 0.0;
    x1max = 0.0;
    x3max = 0.0;
    floatn = n;
    agiant = rgiant / floatn;
    for (i = 0; i < n; ++i) {
        xabs = Math.abs(x[i + offset]);
        if (xabs <= rdwarf || xabs >= agiant) {
            if (xabs > rdwarf) {

                /*              sum for large components. */

                if (xabs > x1max) {
                    /* Computing 2nd power */
                    d1 = x1max / xabs;
                    s1 = 1.0 + s1 * (d1 * d1);
                    x1max = xabs;
                } else {
                    /* Computing 2nd power */
                    d1 = xabs / x1max;
                    s1 += d1 * d1;
                }
            } else {

                /*              sum for small components. */

                if (xabs > x3max) {
                    /* Computing 2nd power */
                    d1 = x3max / xabs;
                    s3 = 1.0 + s3 * (d1 * d1);
                    x3max = xabs;
                } else {
                    if (xabs !== 0.0) {
                        /* Computing 2nd power */
                        d1 = xabs / x3max;
                        s3 += d1 * d1;
                    }
                }
            }
        } else {

            /*           sum for intermediate components. */

            /* Computing 2nd power */
            s2 += xabs * xabs;
        }
    }

    /*     calculation of norm. */

    if (s1 !== 0.0) {
        ret_val = x1max * Math.sqrt(s1 + (s2 / x1max) / x1max);
    } else {
        if (s2 !== 0.0) {
            if (s2 >= x3max) {
                ret_val = Math.sqrt(s2 * (1.0 + (x3max / s2) * (x3max * s3)));
            } else {
                ret_val = Math.sqrt(x3max * ((s2 / x3max) + (x3max * s3)));
            }
        } else {
            ret_val = x3max * Math.sqrt(s3);
        }
    }
    return ret_val;

}

//find point projection on the plane
MP.Project = function (R, t, p, start_p) {
    var x = R[0][0] * p[start_p + 0] + R[0][1] * p[start_p + 1] + R[0][2] * p[start_p + 2] + t[0];
    var y = R[1][0] * p[start_p + 0] + R[1][1] * p[start_p + 1] + R[1][2] * p[start_p + 2] + t[1];
    var z = R[2][0] * p[start_p + 0] + R[2][1] * p[start_p + 1] + R[2][2] * p[start_p + 2] + t[2];

    var proj = [x / z, y / z];
    return proj;
}

//claculate error according to the estimated parameters
MP.Triangulation_n_Residual = function (num_pts, ps, Rs, ts, m, n, x, start_x, fvec, start_fvec, iflag) {
    for (var i = 0; i < num_pts; i++) {
        /* Project the point into the view */
        var projected = MP.Project(Rs[i], ts[i], x, start_x);

        fvec[start_fvec + 2 * i + 0] = ps[i][0] - projected[0];
        fvec[start_fvec + 2 * i + 1] = ps[i][1] - projected[1];
    }

    return fvec;
}

//converted from minpack
MP.fdjac2 = function (num_pts, p, R, t, m, n,
                      x, start_x,
                      fvec, start_fvec,
                      fjac, start_fjac,
                      ldfjac, iflag, epsfcn,
                      wa, start_wa) {
    var c__1 = 1, zero = 0.0, fjac_dim1, fjac_offset, i__1, i__2, h__, i__, j, eps, temp, epsmch;

    start_wa--;
    start_fvec--;
    start_x--;
    fjac_dim1 = ldfjac;
    fjac_offset = 1 + fjac_dim1 * 1;
    start_fjac -= fjac_offset;

    epsmch = MP.dpmpar(c__1);

    eps = Math.sqrt((Math.max(epsfcn, epsmch)));
    i__1 = n;
    for (j = 1; j <= i__1; ++j) {
        temp = x[start_x + j];
        h__ = eps * Math.abs(temp);
        if (h__ == zero)
            h__ = eps;
        x[start_x + j] = temp + h__;
        wa = MP.Triangulation_n_Residual(num_pts, p, R, t, m, n, x, start_x + 1, wa, start_wa + 1, iflag);
        if (iflag < 0) {
            return [x, fjac, wa];

        }
        x[start_x + j] = temp;
        i__2 = m;
        for (i__ = 1; i__ <= i__2; ++i__)
            fjac[start_fjac + i__ + j * fjac_dim1] = (wa[start_wa + i__] - fvec[start_fvec + i__]) / h__;
    }

    return [x, fjac, wa]


}

//converted from minpack
MP.qrsolv = function (n, r, start_r__, ldr, ipvt, start_ipvt, diag, start_diag, qtb, start_qtb, x, start_x, sdiag, start_sdiag, wa, start_wa) {


    /* Initialized data
     vector1Dd & r__, int start_r__,
     int ldr,
     vector1Di & ipvt, int start_ipvt,
     vector1Dd & diag, int start_diag,
     vector1Dd & qtb, int start_qtb,
     vector1Dd & x, int start_x,
     vector1Dd & sdiag, int start_sdiag,
     vector1Dd & wa, int start_wa
     */

    var p5 = 0.5;
    var p25 = 0.25;

    /* Local variables */
    var i, j, k, l, jp1, kp1;
    var tan, cos, sin, sum, temp, cotan;
    var nsing;
    var qtbpj;

    start_wa--;
    start_sdiag--;
    start_x--;
    start_qtb--;
    start_diag--;
    start_ipvt--;
    var r_dim1 = ldr;
    var r_offset = 1 + r_dim1 * 1;
    start_r__ -= r_offset;


    /*     ********** */

    /*     copy r and (q transpose)*b to preserve input and initialize s. */
    /*     in particular, save the diagonal elements of r in x. */

    for (j = 1; j <= n; ++j) {
        for (i = j; i <= n; ++i) {
            r[start_r__ + i + j * ldr] = r[start_r__ + j + i * ldr];
        }
        x[start_x + j] = r[start_r__ + j + j * ldr];
        wa[start_wa + j] = qtb[start_qtb + j];
    }

    /*     eliminate the diagonal matrix d using a givens rotation. */

    for (j = 1; j <= n; ++j) {

        /*        prepare the row of d to be eliminated, locating the */
        /*        diagonal element using p from the qr factorization. */

        l = ipvt[start_ipvt + j];
        if (diag[start_diag + l] !== 0.0) {
            for (k = j; k <= n; ++k) {
                sdiag[start_sdiag + k] = 0.0;
            }
            sdiag[start_sdiag + j] = diag[start_diag + l];

            /*        the transformations to eliminate the row of d */
            /*        modify only a single element of (q transpose)*b */
            /*        beyond the first n, which is initially zero. */

            qtbpj = 0.0;
            for (k = j; k <= n; ++k) {

                /*           determine a givens rotation which eliminates the */
                /*           appropriate element in the current row of d. */

                if (sdiag[start_sdiag + k] !== 0.0) {
                    if (Math.abs(r[start_r__ + k + k * ldr]) < Math.abs(sdiag[start_sdiag + k])) {
                        cotan = r[start_r__ + k + k * ldr] / sdiag[start_sdiag + k];
                        sin = p5 / Math.sqrt(p25 + p25 * (cotan * cotan));
                        cos = sin * cotan;
                    } else {
                        tan = sdiag[start_sdiag + k] / r[start_r__ + k + k * ldr];
                        cos = p5 / Math.sqrt(p25 + p25 * (tan * tan));
                        sin = cos * tan;
                    }

                    /*           compute the modified diagonal element of r and */
                    /*           the modified element of ((q transpose)*b,0). */

                    r[start_r__ + k + k * ldr] = cos * r[start_r__ + k + k * ldr] + sin * sdiag[start_sdiag + k];
                    temp = cos * wa[start_wa + k] + sin * qtbpj;
                    qtbpj = -sin * wa[start_wa + k] + cos * qtbpj;
                    wa[start_wa + k] = temp;

                    /*           accumulate the tranformation in the row of s. */

                    kp1 = k + 1;
                    if (n >= kp1) {
                        for (i = kp1; i <= n; ++i) {
                            temp = cos * r[start_r__ + i + k * ldr] + sin * sdiag[start_sdiag + i];
                            sdiag[start_sdiag + i] = -sin * r[start_r__ + i + k * ldr] + cos * sdiag[start_sdiag + i];
                            r[start_r__ + i + k * ldr] = temp;
                        }
                    }
                }
            }
        }

        /*        store the diagonal element of s and restore */
        /*        the corresponding diagonal element of r. */

        sdiag[start_sdiag + j] = r[start_r__ + j + j * ldr];
        r[start_r__ + j + j * ldr] = x[start_x + j];

    }

    /*     solve the triangular system for z. if the system is */
    /*     singular, then obtain a least squares solution. */

    nsing = n;
    for (j = 1; j <= n; ++j) {
        if (sdiag[start_sdiag + j] === 0.0 && nsing === n) {
            nsing = j;
        }
        if (nsing < n) {
            wa[start_wa + j] = 0.0;
        }
    }
    if (nsing >= 1) {
        for (k = 1; k <= nsing; ++k) {
            j = nsing - k + 1;
            sum = 0.0;
            jp1 = j + 1;
            if (nsing > jp1) {
                for (i = jp1; i <= nsing; ++i) {
                    sum += r[start_r__ + i + j * ldr] * wa[start_wa + i];
                }
            }
            wa[start_wa + j] = (wa[start_wa + j] - sum) / sdiag[start_sdiag + j];
        }
    }

    /*     permute the components of z back to components of x. */

    for (j = 1; j <= n; ++j) {
        l = ipvt[start_ipvt + j];
        x[start_x + l] = wa[start_wa + j];
    }
    return;

    /*     last card of subroutine qrsolv. */

};

//qrsolv test function
function test_qrsolv() {
    var n = 3;
    var arr = [0.348403, 0.353481, 0.167363, -2.28918e-006, -1.5705e-007, -7.99577e-007, 3.58088e-006, 4.3225e-006, 2.4775e-005, 0.348403, 0.353481, 0.167363, 0.293563, 0.297841, 0.141019, 1.24759e-006, 1.52792e-006, 4.14641e-006, -0.000327297, 0.353481, 0.108867, 0.69262, 0.129055, 0.000370778, 0.348403, -0.0888007, 0.700471, -0.154124, -0.0566957, -0.0322735, -0.961461,];
    var ipvt = [2, 1, 3];
    var ldr = 4;
    var start_r__ = 14;
    var start_ipvt = -1;
    var start_wa1 = 11;
    var start_qtb = 2;
    var start_x = 5;
    var start_sdiag = 8;
    var start_wa2 = 14;
    var r_offset = 5;

    MP.qrsolv(n, arr, start_r__ + r_offset, ldr, ipvt, start_ipvt + 1, arr, start_wa1 + 1, arr, start_qtb + 1,
        arr, start_x + 1, arr, start_sdiag + 1, arr, start_wa2 + 1);
}


//converted from minpack
MP.lmpar = function (N, R, start_r__, LDR, IPVT, start_ipvt, DIAG, start_diag, QTB, start_qtb, DELTA, PAR, X, start_x, SIGMA, start_sdiag, WA1, start_wa1, WA2, start_wa2) {
    /*
     C***END PROLOGUE  DMPAR
     */

    var I, ITER, J, JM1, JP1, K, L, NSING;
    var DXNORM, DWARF, FP, GNORM, PARC, PARL, PARU, P1, P001, SUM, TEMP, ZERO;
    var c__2 = 2;
    P1 = 0.1;
    P001 = 0.001;
    ZERO = 0.0;

    start_wa2--;
    start_wa1--;
    start_sdiag--;
    start_x--;
    start_qtb--;
    start_diag--;
    start_ipvt--;
    var r_dim1 = LDR;
    var r_offset = 1 + r_dim1 * 1;
    start_r__ -= r_offset;

    /*
     C***FIRST EXECUTABLE STATEMENT  DMPAR
     */

    var dwarf = MP.dpmpar(c__2);

    /*
     C
     C     COMPUTE AND STORE IN X THE GAUSS-NEWTON DIRECTION. IF THE
     C     JACOBIAN IS RANK-DEFICIENT, OBTAIN A LEAST SQUARES SOLUTION.
     C
     */

    NSING = N;
    for (J = 1; J <= N; J++) {
        WA1[start_wa1 + J] = QTB[start_qtb + J];
        if (R[start_r__ + J + J * LDR] == ZERO && NSING == N) {
            NSING = J - 1;
        }
        if (NSING < N) {
            WA1[start_wa1 + J] = ZERO;
        }
    }


    if (NSING >= 1) {
        for (K = 1; K <= NSING; K++) {
            J = NSING - K + 1;
            WA1[start_wa1 + J] = WA1[start_wa1 + J] / R[start_r__ + J + J * LDR];
            TEMP = WA1[start_wa1 + J];
            JM1 = J - 1;
            if (JM1 >= 1) {
                for (I = 1; I <= JM1; I++) {
                    WA1[start_wa1 + (I)] = WA1[start_wa1 + (I)] - R[start_r__ + (I) + J * LDR] * TEMP;
                }
            }
        }
    }

    for (J = 1; J <= N; J++) {
        L = IPVT[start_ipvt + J];
        X[start_x + (L)] = WA1[start_wa1 + J];
    }


    /*
     C
     C     INITIALIZE THE ITERATION COUNTER.
     C     EVALUATE THE FUNCTION AT THE ORIGIN, AND TEST
     C     FOR ACCEPTANCE OF THE GAUSS-NEWTON DIRECTION.
     C
     */

    ITER = 0;
    for (J = 1; J <= N; J++) {
        WA2[start_wa2 + J] = DIAG[start_diag + J] * X[start_x + J];
    }

    DXNORM = MP.enorm(N, WA2, start_wa2 + 1);
    FP = DXNORM - DELTA;

    if (FP > P1 * DELTA) {
        /*
         C
         C     IF THE JACOBIAN IS NOT RANK DEFICIENT, THE NEWTON
         C     STEP PROVIDES A LOWER BOUND, PARL, FOR THE ZERO OF
         C     THE FUNCTION. OTHERWISE SET THIS BOUND TO ZERO.
         C
         */
        PARL = ZERO;

        if (NSING >= N) {
            for (J = 1; J <= N; J++) {
                L = IPVT[start_ipvt + J];
                WA1[start_wa1 + J] = DIAG[start_diag + (L)] * (WA2[start_wa2 + (L)] / DXNORM);
            }
            for (J = 1; J <= N; J++) {
                SUM = ZERO;
                JM1 = J - 1;
                if (JM1 >= 1) {
                    for (I = 1; I <= JM1; I++) {
                        SUM = SUM + R[start_r__ + (I) + J * LDR] * WA1[start_wa1 + (I)];
                    }
                }
                WA1[start_wa1 + J] = (WA1[start_wa1 + J] - SUM) / R[start_r__ + J + J * LDR];
            }
            TEMP = MP.enorm(N, WA1, start_wa1 + 1);
            PARL = ((FP / DELTA) / TEMP) / TEMP;
        }

        /*
         C
         C     CALCULATE AN UPPER BOUND, PARU, FOR THE ZERO OF THE FUNCTION.
         C
         */

        for (J = 1; J <= N; J++) {
            SUM = ZERO;
            for (I = 1; I <= J; I++) {
                SUM = SUM + R[start_r__ + (I) + J * LDR] * QTB[start_qtb + (I)];
            }
            L = IPVT[start_ipvt + J];
            WA1[start_wa1 + J] = SUM / DIAG[start_diag + (L)];
        }

        GNORM = MP.enorm(N, WA1, start_wa1 + 1);
        PARU = GNORM / DELTA;

        if (PARU == ZERO) {
            PARU = DWARF / Math.min(DELTA, P1);
        }

        /*
         C
         C     IF THE INPUT PAR LIES OUTSIDE OF THE INTERVAL (PARL,PARU),
         C     SET PAR TO THE CLOSER ENDPOINT.
         C
         */
        PAR = Math.max(PAR, PARL);
        PAR = Math.min(PAR, PARU);

        if (PAR == ZERO) {
            PAR = GNORM / DXNORM;
        }


        /*
         C
         C     BEGINNING OF AN ITERATION.
         C
         */

        while (true) {
            ITER = ITER + 1;

            /*
             C
             C        EVALUATE THE FUNCTION AT THE CURRENT VALUE OF PAR.
             C
             */

            if (PAR == ZERO) {
                PAR = Math.max(DWARF, P001 * PARU);
            }

            TEMP = Math.sqrt(PAR);

            for (J = 1; J <= N; J++) {
                WA1[start_wa1 + J] = TEMP * DIAG[start_diag + J];
            }


            MP.qrsolv(N, R, start_r__ + r_offset, LDR, IPVT, start_ipvt + 1, WA1, start_wa1 + 1, QTB, start_qtb + 1,
                X, start_x + 1, SIGMA, start_sdiag + 1, WA2, start_wa2 + 1);

            for (J = 1; J <= N; J++) {
                WA2[start_wa2 + J] = DIAG[start_diag + J] * X[start_x + J];
            }

            DXNORM = MP.enorm(N, WA2, 0);
            TEMP = FP;
            FP = DXNORM - DELTA;

            /*
             C
             C        IF THE FUNCTION IS SMALL ENOUGH, ACCEPT THE CURRENT VALUE
             C        OF PAR. ALSO TEST FOR THE EXCEPTIONAL CASES WHERE PARL
             C        IS ZERO OR THE NUMBER OF ITERATIONS HAS REACHED 10.
             C
             */

            if ((Math.abs(FP) <= P1 * DELTA) || (PARL == ZERO) && (FP <= TEMP) && (TEMP < ZERO) || (ITER == 10)) {
                if (ITER == 0) {
                    PAR = ZERO;
                }
                return;
            }

            /*
             C
             C        COMPUTE THE NEWTON CORRECTION.
             C
             */

            for (J = 1; J <= N; J++) {
                L = IPVT[start_ipvt + J];
                WA1[start_wa1 + J] = DIAG[start_diag + (L)] * (WA2[start_wa2 + (L)] / DXNORM);
            }

            for (J = 1; J <= N; J++) {
                WA1[start_wa1 + J] = WA1[start_wa1 + J] / SIGMA[start_sdiag + J];
                TEMP = WA1[start_wa1 + J];
                JP1 = J + 1;
                if (N >= JP1) {
                    for (I = JP1; I <= N; I++) {
                        WA1[start_wa1 + (I)] = WA1[start_wa1 + (I)] - R[start_r__ + (I) + J * LDR] * TEMP;
                    }
                }
            }

            TEMP = MP.enorm(N, WA1, 0);
            PARC = ((FP / DELTA) / TEMP) / TEMP;

            /*
             C
             C        DEPENDING ON THE SIGN OF THE FUNCTION, UPDATE PARL OR PARU.
             C
             */

            if (FP > ZERO) {
                PARL = Math.max(PARL, PAR);
            }

            if (FP <= ZERO) {
                PARU = Math.min(PARU, PAR);
            }

            /*
             C
             C        COMPUTE AN IMPROVED ESTIMATE FOR PAR.
             C
             */

            PAR = Math.max(PARL, PAR + PARC);

            /*
             C
             C        END OF AN ITERATION.
             C
             */
        }
    }

    /*
     C
     C     TERMINATION.
     C
     */

    if (ITER == 0) {
        PAR = ZERO;
    }

    /*
     RETURN
     C
     C     LAST CARD OF SUBROUTINE DMPAR.
     C
     */
};

//test lmpar function
function test_lmpar() {
    var n = 3;
    var arr = [0.348403, 0.353481, 0.167363, -2.28918e-006, -1.5705e-007, -7.99577e-007, 0.353481, 0.348403, -0.0322735, 0.348403, 0.353481, 0.167363, -1.23499, -0.11478, -0.646888, -2.28918e-006, -1.5705e-007, -7.99577e-007, -0.000327297, 0.353481, 0.108867, 0.69262, 0.129055, 0.000370778, 0.348403, -0.0888007, 0.700471, -0.154124, -0.0566957, -0.0322735, -0.961461,];
    var start_fjac = 14;
    var fjac_offset = 5;
    var ldfjac = 4;


    var ipvt = [2, 1, 3];
    var start_ipvt = -1;
    var start_diag = -1;
    var start_qtf = 2;
    var delta = 139.88685453085608
    var par = 0.00000000000000000;
    var start_wa1 = 5;
    var start_wa2 = 8;
    var start_wa3 = 11;
    var start_wa4 = 14;

    MP.lmpar(n, arr, start_fjac + fjac_offset, ldfjac, ipvt, start_ipvt + 1, arr, start_diag + 1,
        arr, start_qtf + 1, delta, par, arr, start_wa1 + 1, arr, start_wa2 + 1,
        arr, start_wa3 + 1, arr, start_wa4 + 1);

}

//converted from minpack
MP.qrfac = function (m, n, a, start_a, lda, pivot, ipvt, start_ipvt, lipvt, rdiag, start_rdiag, acnorm, start_acnorm, wa, start_wa) {
    "use strict";
    /* Initialized data */
    var p05 = 0.05;

    /* System generated locals */
    var d1;

    /* Local variables */
    var i, j, k, jp1;
    var sum;
    var temp;
    var minmn;
    var epsmch;
    var ajnorm;
    var kmax;

    start_wa--;
    start_acnorm--;
    start_rdiag--;
    var a_dim1 = lda;
    var a_offset = 1 + a_dim1 * 1;
    start_a -= a_offset;
    start_ipvt--;


    epsmch = MP.dpmpar(1);

    /*     compute the initial column norms and initialize several arrays. */
    for (j = 1; j <= n; ++j) {
        acnorm[start_acnorm + j] = MP.enorm(m, a, start_a + j * a_dim1 + 1);
        rdiag[start_rdiag + j] = acnorm[start_acnorm + j];
        wa[start_wa + j] = rdiag[start_rdiag + j];
        if (pivot) {
            ipvt[start_ipvt + j] = j;
        }
    }


    /*     reduce a to r with householder transformations. */

    minmn = Math.min(m, n);
    for (j = 1; j <= minmn; ++j) {
        if (pivot) {

            /*        bring the column of largest norm into the pivot position. */

            kmax = j;
            for (k = j; k < n; ++k) {
                if (rdiag[start_rdiag + k] > rdiag[start_rdiag + kmax]) {
                    kmax = k;
                }
            }
            if (kmax !== j) {
                for (i = 1; i <= m; ++i) {
                    temp = a[start_a + i + j * a_dim1];
                    a[start_a + i + j * a_dim1] = a[start_a + i + kmax * a_dim1];
                    a[start_a + i + kmax * a_dim1] = temp;
                }
                rdiag[start_rdiag + kmax] = rdiag[start_rdiag + j];
                wa[start_wa + kmax] = wa[start_wa + j];
                k = ipvt[start_ipvt + j];
                ipvt[start_ipvt + j] = ipvt[start_ipvt + kmax];
                ipvt[start_ipvt + kmax] = k;
            }
        }

        /*        compute the householder transformation to reduce the */
        /*        j-th column of a to a multiple of the j-th unit vector. */

        ajnorm = MP.enorm(m - j + 1, a, start_a + j + j * a_dim1);
        if (ajnorm !== 0.0) {
            if (a[start_a + j + j * a_dim1] < 0.0) {
                ajnorm = -ajnorm;
            }
            for (i = j; i <= m; ++i) {
                a[start_a + i + j * a_dim1] /= ajnorm;
            }
            a[start_a + j + j * a_dim1] += 1.0;

            /*        apply the transformation to the remaining columns */
            /*        and update the norms. */
            jp1 = j + 1;
            if (n >= jp1) {
                for (k = jp1; k <= n; ++k) {
                    sum = 0.0;
                    for (i = j; i <= m; ++i) {
                        sum += a[start_a + i + j * a_dim1] * a[start_a + i + k * a_dim1];
                    }
                    temp = sum / a[start_a + j + j * a_dim1];
                    for (i = j; i <= m; ++i) {
                        a[start_a + i + k * a_dim1] -= temp * a[start_a + i + j * a_dim1];
                    }
                    if (pivot && rdiag[start_rdiag + k] !== 0.0) {
                        temp = a[start_a + j + k * a_dim1] / rdiag[start_rdiag + k];
                        /* Computing MAX */
                        d1 = 1.0 - temp * temp;
                        rdiag[start_rdiag + k] *= Math.sqrt((Math.max(0.0, d1)));
                        /* Computing 2nd power */
                        d1 = rdiag[start_rdiag + k] / wa[start_wa + k];
                        if (p05 * (d1 * d1) <= epsmch) {
                            rdiag[start_rdiag + k] = MP.enorm(m - (j + 1), a, jp1 + k * lda);
                            wa[start_wa + k] = rdiag[start_rdiag + k];
                        }
                    }
                }
            }
        }
        rdiag[start_rdiag + j] = -ajnorm;
    }

    return;
}

//test qrfac function
function test_qrfac() {
    m = 4;
    n = 3;
    var fjac = [0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00022662647263249625, 5.0411269291228411e-005, -0.00022313045142485777, -5.8660585804864995e-005, 0.036666239288183425, -0.24439371986310057, 0.045715059256429103, -0.24129272488644984, -0.24788358611653574, -0.038482594548748000, -0.24482808932441211, -0.045618649364618555, 0.097098438724401340, 0.078747882693592619, 0.10494570384439898, 0.036977712161273324];
    var start_fjac = 14;
    var fjac_offset = 5;
    var ldfjac = 4;
    var c_true = true;
    var ipvt = [0, 0, 0];
    var start_ipvt = -1;
    n = 3;
    var wa1 = [0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00022662647263249625, 5.0411269291228411e-005, -0.00022313045142485777, -5.8660585804864995e-005, 0.036666239288183425, -0.24439371986310057, 0.045715059256429103, -0.24129272488644984, -0.24788358611653574, -0.038482594548748000, -0.24482808932441211, -0.045618649364618555, 0.097098438724401340, 0.078747882693592619, 0.10494570384439898, 0.036977712161273324]
    var start_wa1 = 5;
    var wa2 = [0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00022662647263249625, 5.0411269291228411e-005, -0.00022313045142485777, -5.8660585804864995e-005, 0.036666239288183425, -0.24439371986310057, 0.045715059256429103, -0.24129272488644984, -0.24788358611653574, -0.038482594548748000, -0.24482808932441211, -0.045618649364618555, 0.097098438724401340, 0.078747882693592619, 0.10494570384439898, 0.036977712161273324]
    var start_wa2 = 8;
    var wa3 = [0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00000000000000000, 0.00022662647263249625, 5.0411269291228411e-005, -0.00022313045142485777, -5.8660585804864995e-005, 0.036666239288183425, -0.24439371986310057, 0.045715059256429103, -0.24129272488644984, -0.24788358611653574, -0.038482594548748000, -0.24482808932441211, -0.045618649364618555, 0.097098438724401340, 0.078747882693592619, 0.10494570384439898, 0.036977712161273324]
    var start_wa3 = 11;

    //[a_out, ipvt_out, rdiag_out, acnorm_out, wa_out] = MP.qrfac(m, n, fjac, start_fjac + fjac_offset, ldfjac, c_true, ipvt, start_ipvt + 1, n, wa1, start_wa1 + 1,
    //	wa2, start_wa2 + 1, wa3, start_wa3 + 1);
    //debugger;

    MP.qrfac(m, n, fjac, start_fjac + fjac_offset, ldfjac, c_true, ipvt, start_ipvt + 1, n, fjac, start_wa1 + 1,
        fjac, start_wa2 + 1, fjac, start_wa3 + 1);


}

//converted from minpack
MP.lmdif_ = function (num_pts, p, R, t, m, n, x, start_x, fvec, start_fvec, ftol, xtol, gtol, maxfev, epsfcn, diag, start_diag, mode, factor, nprint, nfev, fjac, start_fjac, ldfjac, ipvt, start_ipvt, qtf, start_qtf, wa1, start_wa1, wa2, start_wa2, wa3, start_wa3, wa4, start_wa4) {
    "use strict";
    /* Initialized data */


    var c__1 = 1;
    var c_true = true;

    var one = 1.0;
    var p1 = 0.1;
    var p5 = 0.5;
    var p25 = 0.25;
    var p75 = 0.75;
    var p0001 = 1e-4;
    var zero = 0.0;


    /* System generated locals */
    var d1, d2;

    /* Local variables */
    var i, j, l;
    var par = [0],
        sum;
    var iter;
    var temp, temp1, temp2;
    var iflag;
    var delta = 0.0;
    var ratio;
    var fnorm, gnorm;
    var pnorm, xnorm = 0.0,
        fnorm1, actred, dirder, epsmch, prered;
    var info;


    start_wa4--;
    start_fvec--;
    start_wa3--;
    start_wa2--;
    start_wa1--;
    start_qtf--;
    start_ipvt--;
    start_diag--;
    start_x--;
    var fjac_dim1 = ldfjac;
    var fjac_offset = 1 + fjac_dim1 * 1;
    start_fjac -= fjac_offset;


    /*     ********** */


    /*     epsmch is the machine precision. */

    epsmch = MP.dpmpar(1);

    info = 0;
    iflag = 0;
    nfev = 0;

    /*     check the input parameters for errors. */

    if (n <= 0 || m < n || ldfjac < m || ftol < 0.0 || xtol < 0.0 || gtol < 0.0 || maxfev <= 0 || factor <= 0.0) {
        //goto TERMINATE;
        if (iflag < 0) {
            info = iflag;
        }
        iflag = 0;
        if (nprint > 0) {
            MP.Triangulation_n_Residual(num_pts, p, R, t, m, n, x, start_x + 1, fvec, start_fvec + 1, iflag);

        }
        return info;
    }


    if (mode === 2) {
        for (j = 1; j <= n; ++j) {
            if (diag[j] <= 0.0) {
                //goto TERMINATE;
                if (iflag < 0) {
                    info = iflag;
                }
                iflag = 0;
                if (nprint > 0) {
                    MP.Triangulation_n_Residual(num_pts, p, R, t, m, n, x, start_x + 1, fvec, start_fvec + 1, iflag);

                }
                return info;
            }
        }
    }

    /*     evaluate the function at the starting point */
    /*     and calculate its norm. */

    iflag = 1;
    MP.Triangulation_n_Residual(num_pts, p, R, t, m, n, x, start_x + 1, fvec, start_fvec + 1, iflag);
    nfev = 1;
    if (iflag < 0) {
        //goto TERMINATE;
        if (iflag < 0) {
            info = iflag;
        }
        iflag = 0;
        if (nprint > 0) {
            MP.Triangulation_n_Residual(num_pts, p, R, t, m, n, x, start_x + 1, fvec, start_fvec + 1, iflag);

        }
        return info;
    }
    fnorm = MP.enorm(m, fvec, start_fvec + 1);

    /*     initialize levenberg-marquardt parameter and iteration counter. */

    par = 0.0;
    iter = 1;

    /*     beginning of the outer loop. */

    for (; ;) {

        /*        calculate the jacobian matrix. */
        iflag = 2;
        MP.fdjac2(num_pts, p, R, t, m, n, x, start_x + 1, fvec, start_fvec + 1, fjac, start_fjac + fjac_offset, ldfjac,
            iflag, epsfcn, wa4, start_wa4 + 1);
        nfev += n;
        if (iflag < 0) {
            //goto TERMINATE;
            if (iflag < 0) {
                info = iflag;
            }
            iflag = 0;
            if (nprint > 0) {
                MP.Triangulation_n_Residual(num_pts, p, R, t, m, n, x, start_x + 1, fvec, start_fvec + 1, iflag);

            }
            return info;
        }

        /*        if requested, call fcn to enable printing of iterates. */

        if (nprint > 0) {
            iflag = 0;
            if ((iter - 1) % nprint === 0) {
                Triangulation_n_Residual(num_pts, p, R, t, m, n, x, start_x + 1, fvec, start_fvec + 1, iflag);
            }
            if (iflag < 0) {
                //goto TERMINATE;
                if (iflag < 0) {
                    info = iflag;
                }
                iflag = 0;
                if (nprint > 0) {
                    MP.Triangulation_n_Residual(num_pts, p, R, t, m, n, x, start_x + 1, fvec, start_fvec + 1, iflag);

                }
                return info;
            }
        }

        /*        compute the qr factorization of the jacobian. */
        MP.qrfac(m, n, fjac, start_fjac + fjac_offset, ldfjac, c_true, ipvt, start_ipvt + 1, n, wa1, start_wa1 + 1,
            wa2, start_wa2 + 1, wa3, start_wa3 + 1);

        /*        on the first iteration and if mode is 1, scale according */
        /*        to the norms of the columns of the initial jacobian. */

        if (iter === 1) {
            if (mode !== 2) {
                for (j = 1; j <= n; ++j) {
                    diag[start_diag + j] = wa2[start_wa2 + j];
                    if (wa2[start_wa2 + j] == zero)
                        diag[start_diag + j] = one;
                }
            }

            /*        on the first iteration, calculate the norm of the scaled x */
            /*        and initialize the step bound delta. */

            for (j = 1; j <= n; ++j) {
                wa3[start_wa3 + j] = diag[start_diag + j] * x[start_x + j];
            }
            xnorm = MP.enorm(n, wa3, start_wa3 + 1);
            delta = factor * xnorm;
            if (delta === 0.0) {
                delta = factor;
            }
        }

        /*        form (q transpose)*fvec and store the first n components in */
        /*        qtf. */

        for (i = 1; i <= m; ++i) {
            wa4[start_wa4 + i] = fvec[start_fvec + i];
        }
        for (j = 1; j <= n; ++j) {
            if (fjac[start_fjac + j + j * fjac_dim1] !== 0.0) {
                sum = 0.0;
                for (i = j; i <= m; ++i) {
                    sum += fjac[start_fjac + i + j * fjac_dim1] * wa4[start_wa4 + i];
                }
                temp = -sum / fjac[start_fjac + j + j * fjac_dim1];
                for (i = j; i <= m; ++i) {
                    wa4[start_wa4 + i] += fjac[start_fjac + i + j * fjac_dim1] * temp;
                }
            }
            fjac[start_fjac + j + j * fjac_dim1] = wa1[start_wa1 + j];
            qtf[start_qtf + j] = wa4[start_wa4 + j];
        }

        /*        compute the norm of the scaled gradient. */

        gnorm = 0.0;
        if (fnorm !== 0.0) {
            for (j = 1; j <= n; ++j) {
                l = ipvt[start_ipvt + j];
                if (wa2[start_wa2 + l] !== 0.0) {
                    sum = 0.0;
                    for (i = 1; i <= j; ++i) {
                        sum += fjac[start_fjac + i + j * fjac_dim1] * (qtf[start_qtf + i] / fnorm);
                    }
                    /* Computing MAX */
                    d1 = Math.abs(sum / wa2[start_wa2 + l]);
                    gnorm = Math.max(gnorm, d1);
                }
            }
        }

        /*        test for convergence of the gradient norm. */

        if (gnorm <= gtol) {
            info = 4;
        }
        if (info !== 0) {

            //goto TERMINATE;
            if (iflag < 0) {
                info = iflag;
            }
            iflag = 0;
            if (nprint > 0) {
                MP.Triangulation_n_Residual(num_pts, p, R, t, m, n, x, start_x + 1, fvec, start_fvec + 1, iflag);

            }
            return info;
        }

        /*        rescale if necessary. */

        if (mode !== 2) {
            for (j = 1; j <= n; ++j) {
                /* Computing MAX */
                d1 = diag[start_diag + j];
                d2 = wa2[start_wa2 + j];
                diag[start_diag + j] = Math.max(d1, d2);
            }
        }

        /*        beginning of the inner loop. */

        do {

            /*           determine the levenberg-marquardt parameter. */
            MP.lmpar(n, fjac, start_fjac + fjac_offset, ldfjac, ipvt, start_ipvt + 1, diag, start_diag + 1,
                qtf, start_qtf + 1, delta, par, wa1, start_wa1 + 1, wa2, start_wa2 + 1,
                wa3, start_wa3 + 1, wa4, start_wa4 + 1);

            /*           store the direction p and x + p. calculate the norm of p. */

            for (j = 1; j <= n; ++j) {
                wa1[start_wa1 + j] = -wa1[start_wa1 + j];
                wa2[start_wa2 + j] = x[start_x + j] + wa1[start_wa1 + j];
                wa3[start_wa3 + j] = diag[start_diag + j] * wa1[start_wa1 + j];
            }
            pnorm = MP.enorm(n, wa3, start_wa3 + 1);

            /*           on the first iteration, adjust the initial step bound. */

            if (iter === 1) {
                delta = Math.min(delta, pnorm);
            }

            /*           evaluate the function at x + p and calculate its norm. */
            iflag = 1;

            MP.Triangulation_n_Residual(num_pts, p, R, t, m, n, wa2, start_wa2 + 1, wa4, start_wa4 + 1, iflag);
            if (iflag < 0) {
                //goto TERMINATE;
                if (iflag < 0) {
                    info = iflag;
                }
                iflag = 0;
                if (nprint > 0) {
                    MP.Triangulation_n_Residual(num_pts, p, R, t, m, n, x, start_x + 1, fvec, start_fvec + 1, iflag);

                }
                return info;
            }
            fnorm1 = MP.enorm(m, wa4, start_wa4 + 1);

            /*           compute the scaled actual reduction. */

            actred = -1.0;
            if (p1 * fnorm1 < fnorm) {
                /* Computing 2nd power */
                d1 = fnorm1 / fnorm;
                actred = 1.0 - d1 * d1;
            }

            /*           compute the scaled predicted reduction and */
            /*           the scaled directional derivative. */

            for (j = 1; j <= n; ++j) {
                wa3[start_wa3 + j] = 0.0;
                l = ipvt[start_ipvt + j];
                temp = wa1[start_wa1 + l];
                for (i = 1; i <= j; ++i) {
                    wa3[start_wa3 + i] += fjac[start_fjac + i + j * fjac_dim1] * temp;
                }
            }
            temp1 = MP.enorm(n, wa3, start_wa3 + 1) / fnorm;
            temp2 = (Math.sqrt(par) * pnorm) / fnorm;
            prered = temp1 * temp1 + temp2 * temp2 / p5;
            dirder = -(temp1 * temp1 + temp2 * temp2);

            /*           compute the ratio of the actual to the predicted */
            /*           reduction. */

            ratio = 0.0;
            if (prered !== 0.0) {
                ratio = actred / prered;
            }

            /*           update the step bound. */

            if (ratio <= p25) {
                if (actred >= 0.0) {
                    temp = p5;
                } else {
                    temp = p5 * dirder / (dirder + p5 * actred);
                }
                if (p1 * fnorm1 >= fnorm || temp < p1) {
                    temp = p1;
                }
                /* Computing MIN */
                d1 = pnorm / p1;
                delta = temp * Math.min(delta, d1);
                par /= temp;
            } else {
                if (par === 0.0 || ratio >= p75) {
                    delta = pnorm / p5;
                    par = p5 * par;
                }
            }

            /*           test for successful iteration. */

            if (ratio >= p0001) {

                /*           successful iteration. update x, fvec, and their norms. */

                for (j = 1; j <= n; ++j) {
                    x[start_x + j] = wa2[start_wa2 + j];
                    wa2[start_wa2 + j] = diag[start_diag + j] * x[start_x + j];
                }
                for (i = 1; i <= m; ++i) {
                    fvec[start_fvec + i] = wa4[start_wa4 + i];
                }
                xnorm = MP.enorm(n, wa2, start_wa2 + 1);
                fnorm = fnorm1;
                ++iter;
            }

            /*           tests for convergence. */

            if (Math.abs(actred) <= ftol && prered <= ftol && p5 * ratio <= 1.0) {
                info = 1;
            }
            if (delta <= xtol * xnorm) {
                info = 2;
            }
            if (Math.abs(actred) <= ftol && prered <= ftol && p5 * ratio <= 1.0 && info === 2) {
                info = 3;
            }
            if (info !== 0) {
                //goto TERMINATE;
                if (iflag < 0) {
                    info = iflag;
                }
                iflag = 0;
                if (nprint > 0) {
                    MP.Triangulation_n_Residual(num_pts, p, R, t, m, n, x, start_x + 1, fvec, start_fvec + 1, iflag);

                }
                return info;
            }

            /*           tests for termination and stringent tolerances. */

            if (nfev >= maxfev) {
                info = 5;
            }
            if (Math.abs(actred) <= epsmch && prered <= epsmch && p5 * ratio <= 1.0) {
                info = 6;
            }
            if (delta <= epsmch * xnorm) {
                info = 7;
            }
            if (gnorm <= epsmch) {
                info = 8;
            }
            if (info !== 0) {
                //goto TERMINATE;
                if (iflag < 0) {
                    info = iflag;
                }
                iflag = 0;
                if (nprint > 0) {
                    MP.Triangulation_n_Residual(num_pts, p, R, t, m, n, x, start_x + 1, fvec, start_fvec + 1, iflag);

                }
                return info;
            }

            /*           end of the inner loop. repeat if iteration unsuccessful. */

        } while (ratio < p0001);

        /*        end of the outer loop. */

    }
    //TERMINATE:
    /*     termination, either normal or user imposed. */
    if (iflag < 0) {
        info = iflag;
    }
    if (nprint > 0) {
        fcn(p, m, n, x, fvec, 0);
    }
    return info;
};

function lmdif(x, num_pts, p, R, t) {
    var m = 2 * num_pts;
    var n = 3;

    var info;
    var lwa = m * n + 5 * n + m;

    var iwa = [0, 0, 0];
    var fvec = [0.0000, 0.0000, 0.000, 0.0000];

    var wa = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0];

    if (n > m) {
        return false;
    }

    //IMPLEMENTATION OF THE LMDIF1_ FUNCTION
    var factor = 100.0;
    var zero = 0.0;
    var mp5n, mode, nfev = 0;
    var ftol, gtol, xtol;
    var epsfcn;
    var maxfev, nprint;

    var start_fvec = -1;
    var start_iwa = -1;
    var start_x = -1;
    var start_wa = -1;

    info = 0;
    var tol = 1.0000000000000001e-005;

    if (n <= 0 || m < n || tol < zero || lwa < m * n + n * 5 + m)
        return false;

    /*     call lmdif. */
    maxfev = (n + 1) * 200;
    ftol = tol;
    xtol = tol;
    gtol = zero;
    epsfcn = zero;
    mode = 1;
    nprint = 0;
    mp5n = m + n * 5;

    /*
    R1=[[-0.14580384997425300,0.98883632515940800,0.030723921916363001],[0.98929787221313903,0.14590587597279400,-0.0010933394564921900],[-0.0055639345108361802,0.030235697475815199,-0.99952731089796198]];
    t1=[-1.7873328070243499,2.2679717169217000,0.16748474847986899]

    R2=[[-0.18118055781630801,0.98297944470392296,0.030414088165514001],[0.98339297228818001,0.18141602217326599,-0.0051467419838429800],[-0.010576744470326900,0.028976510976950299,-0.99952413391974304]]
    t2=[-2.0678207835278402,2.9332439550633902,0.17771984453150000]

    p=[[-0.42304739723738705,-0.31720632556900702],[-0.45789435029859454,-0.14544879362425950]];
    R=[R1,R2];
    t=[t1,t2];

    var x = [-3.5447095600257472, -0.32471324893387304, -3.8651825802393360]
    */

    MP.lmdif_(num_pts, p, R, t, m, n, x, start_x + 1, fvec, start_fvec + 1, ftol, xtol, gtol, maxfev,
        epsfcn, wa, start_wa + 1, mode, factor, nprint, nfev,
        wa, start_wa + mp5n + 1, m, iwa, start_iwa + 1,
        wa, start_wa + n + 1, wa, start_wa + (n << 1) + 1, wa, start_wa + n * 3 + 1, wa, start_wa + (n << 2) + 1,
        wa, start_wa + n * 5 + 1);

    return x;
}

//test lmdif function
function test_lmdif() {
    var m = 4;
    var n = 3;

    var info;
    var lwa = m * n + 5 * n + m;

    var iwa = [0, 0, 0];
    var fvec = [0.0000, 0.0000, 0.000, 0.0000];

    var wa = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0];

    if (n > m) {
        return false;
    }

    //IMPLEMENTATION OF THE LMDIF1_ FUNCTION
    var factor = 100.0;
    var zero = 0.0;
    var mp5n, mode, nfev = 0;
    var ftol, gtol, xtol;
    var epsfcn;
    var maxfev, nprint;

    var start_fvec = -1;
    var start_iwa = -1;
    var start_x = -1;
    var start_wa = -1;

    info = 0;
    var tol = 1.0000000000000001e-005;

    if (n <= 0 || m < n || tol < zero || lwa < m * n + n * 5 + m)
        return false;

    /*     call lmdif. */
    maxfev = (n + 1) * 200;
    ftol = tol;
    xtol = tol;
    gtol = zero;
    epsfcn = zero;
    mode = 1;
    nprint = 0;
    mp5n = m + n * 5;

    var R1 = [[-0.14580384997425300, 0.98883632515940800, 0.030723921916363001], [0.98929787221313903, 0.14590587597279400, -0.0010933394564921900], [-0.0055639345108361802, 0.030235697475815199, -0.99952731089796198]];
    var t1 = [-1.7873328070243499, 2.2679717169217000, 0.16748474847986899]

    var R2 = [[-0.18118055781630801, 0.98297944470392296, 0.030414088165514001], [0.98339297228818001, 0.18141602217326599, -0.0051467419838429800], [-0.010576744470326900, 0.028976510976950299, -0.99952413391974304]]
    var t2 = [-2.0678207835278402, 2.9332439550633902, 0.17771984453150000]

    p = [[-0.42304739723738705, -0.31720632556900702], [-0.45789435029859454, -0.14544879362425950]];
    R = [R1, R2];
    t = [t1, t2];
    x = [-3.5447095600257472, -0.32471324893387304, -3.8651825802393360]
    var num_pts = 2;

    MP.lmdif_(num_pts, p, R, t, m, n, x, start_x + 1, fvec, start_fvec + 1, ftol, xtol, gtol, maxfev,
        epsfcn, wa, start_wa + 1, mode, factor, nprint, nfev,
        wa, start_wa + mp5n + 1, m, iwa, start_iwa + 1,
        wa, start_wa + n + 1, wa, start_wa + (n << 1) + 1, wa, start_wa + n * 3 + 1, wa, start_wa + (n << 2) + 1,
        wa, start_wa + n * 5 + 1);

}

//test_lmdif();

////////////////////////////////////////////////end of minpack /////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////Multi View Point Reconstruction /////////////////////////////////////////////////////////////////////////

//this object contains some Multi View Point Reconstruction functions
var MWPR = new Object();

// The pseudoinverse function computes the pseudoinverse of A, which solves least-squares problems.
MWPR.pinv = function (A) {
    return numeric.dot(numeric.inv(numeric.dot(numeric.transpose(A), A)), numeric.transpose(A));
}

// Another implementation of the pseudoinverse, using the SVD. May be more robust in certain cases,
// especially if one handles the near-0 entries of svd.S in a special way.
MWPR.pinv2 = function (A) {
    var svd = numeric.svd(A);
    return numeric.dot(numeric.dot(svd.V, numeric.diag(numeric.div(1, svd.S))), numeric.transpose(svd.U));
}

//Function to normalize a 2D point in a camera view (this converts from pixel coordinates to point in metric camera coordinate system)
MWPR.Normalize_Point_2D = function(point2DPixel, cameraView)
{
    var point2DtNorm = [null, null];
    point2DtNorm[0] = (point2DPixel[0] / cameraView.fx) - ((point2DPixel[1] * cameraView.skew) / (cameraView.fx * cameraView.fy)) +
        ((cameraView.skew * cameraView.cy) / (cameraView.fx * cameraView.fy)) - (cameraView.cx / cameraView.fx);
    point2DtNorm[1] = (point2DPixel[1] / cameraView.fy) - (cameraView.cy / cameraView.fy);
    return point2DtNorm;
}

// Function to denormalize a 2D point in a camera view (this converts from metric camera coordinate system to pixel coordinates)
MWPR.Denormalize_Point_2D = function(point2DNorm, cameraView)
{
    var point2DPixel = [null, null];
    point2DPixel[0] = cameraView.fx * point2DNorm[0] + cameraView.skew * point2DNorm[1] + cameraView.cx;
    point2DPixel[1] = cameraView.fy * point2DNorm[1] + cameraView.cy;
    return point2DPixel;
}

//Undistortion function
MWPR.Undistort_Normalized_Point_2D = function (point2DNorm, distortion, projectiontype) {
    var needUndistortion = false;
    for (var i = 0; i < distortion.length; i++) {
        if (distortion[i] != 0) {
            needUndistortion = true;
            break;
        }
    }
    if (needUndistortion == false)
        return point2DNorm;
    if (projectiontype == "PERSPECTIVE") {
        var x0 = point2DNorm[0];
        var y0 = point2DNorm[1];
        var x = x0;
        var y = y0;
        for (var iter = 0; iter < 10; iter++) {
            var r2 = x * x + y * y;
            var icdist = 1.0 / (1 + distortion[0] * r2 + distortion[1] * r2 * r2);
            x = x0 * icdist;
            y = y0 * icdist;
        }
        return [x, y];
    } else if (projectiontype == "PERSPECTIVE_5") {
        var x0 = point2DNorm[0];
        var y0 = point2DNorm[1];
        var x = x0;
        var y = y0;
        for (var iter = 0; iter < 10; iter++) {
            var r2 = x * x + y * y;
            var icdist = 1 / (1 + ((distortion[2] * r2 + distortion[1]) * r2 + distortion[0]) * r2);
            var deltaX = 2 * distortion[3] * x * y + distortion[4] * (r2 + 2 * x * x);
            var deltaY = distortion[3] * (r2 + 2 * y * y) + 2 * distortion[4] * x * y;
            x = (x0 - deltaX) * icdist;
            y = (y0 - deltaY) * icdist;
        }
        return [x, y];
    } else if (projectiontype == "PERSPECTIVE_8") {
        var x0 = point2DNorm[0];
        var y0 = point2DNorm[1];
        var x = x0;
        var y = y0;
        for (var iter = 0; iter < 10; iter++) {
            var r2 = x * x + y * y;
            var icdist = 1 / (1 + (((distortion[3] * r2 + distortion[2]) * r2 + distortion[1]) * r2 + distortion[0]) * r2);
            var deltaX = (2 * distortion[5] * x * y + distortion[4] * (r2 + 2 * x * x)) * (1 + distortion[6] * r2 + distortion[7] * r2 * r2);
            var deltaY = (distortion[5] * (r2 + 2 * y * y) + 2 * distortion[4] * x * y) * (1 + distortion[6] * r2 + distortion[7] * r2 * r2);
            x = (x0 - deltaX) * icdist;
            y = (y0 - deltaY) * icdist;
        }
        return [x, y]
    } else if (projectiontype == "BROWN_CAMERA") {
        var x0 = point2DNorm[0];
        var y0 = point2DNorm[1];
        var x = x0;
        var y = y0;
        for (var iter = 0; iter < 10; iter++) {
            var r2 = x * x + y * y;
            var icdist = 1 / (1.0 + r2 * (distortion[0] + r2 * (distortion[1] + r2 * (distortion[2] + r2 * distortion[3]))));
            var deltaX = distortion[4] * x * y * 2.0 + distortion[5] * (r2 + x * x * 2.0);
            var deltaY = distortion[4] * (r2 + y * y * 2.0) + distortion[5] * x * y * 2.0;
            x = (x0 - deltaX) * icdist;
            y = (y0 - deltaY) * icdist;
        }
        return [x, y]
    } else if (projectiontype == "FISHEYE") {
        var thetad = Math.sqrt(point2DNorm[0] * point2DNorm[0] + point2DNorm[1] * point2DNorm[1]);
        var theta = thetad;  // initial guess
        for (var i = 20; i > 0; i--) {
            var theta2 = theta * theta;
            var theta4 = theta2 * theta2;
            var theta6 = theta4 * theta2;
            var theta8 = theta4 * theta4;
            theta = thetad / (1 + distortion[0] * theta2 + distortion[1] * theta4 + distortion[2] * theta6 + distortion[3] * theta8);
        }
        var scaling = Math.tan(theta) / thetad;
        return [scaling * point2DNorm[0], scaling * point2DNorm[1]];
    }
}
//triangulation (ptsNorm constains normalized 2D points)
MWPR.Triangulate_Points = function (num_pts, ptsNorm, Rs, ts) {
    var num_eqs = 2 * num_pts;
    var num_vars = 3;
    var A = [];
    var b = [];
    for (var i = 0; i < num_pts; i++) {
        A.push([Rs[i][0][0] - ptsNorm[i][0] * Rs[i][2][0], Rs[i][0][1] - ptsNorm[i][0] * Rs[i][2][1], Rs[i][0][2] - ptsNorm[i][0] * Rs[i][2][2]]);
        A.push([Rs[i][1][0] - ptsNorm[i][1] * Rs[i][2][0], Rs[i][1][1] - ptsNorm[i][1] * Rs[i][2][1], Rs[i][1][2] - ptsNorm[i][1] * Rs[i][2][2]]);
        b.push(ts[i][2] * ptsNorm[i][0] - ts[i][0]);
        b.push(ts[i][2] * ptsNorm[i][1] - ts[i][1]);
    }
    var x = numeric.dot(MWPR.pinv2(A), b);

    x = lmdif(x, num_pts, ptsNorm, Rs, ts);
    return x;
}

function Clamp(x, mn, mx) {
    return ((x < mn) ? mn : ((x > mx) ? mx : x));
}

//Check Point Set Degeneracy Triangulation Angle (points contains original 2D point pixel coordinates in images)
MWPR.Check_Point_Set_Degeneracy_Out_Angle = function (points, camera_views, thresholdDegeneracyAnglePoint, angle_) {
    var PI = 3.141592653589793238;
    var RT_vectors = {}//std::map<int, std::vector<double>> RT_vectors;
    var n = points.length;
    var norm;
    var success;
    var stop = false;
    var degenerate = true;
    angle_.angle = 0.0;
    var maxAngle = -1;
    for (var i = 0; i < n - 1; i++) {
        if (stop == true)
            break;
        var RT_pv = [];
        if (i in RT_vectors) {
            RT_pv = RT_vectors[i];
        }
        if (!(i in RT_vectors)) {
            var p_norm = MWPR.Normalize_Point_2D(points[i], camera_views[i]);
            p_norm = MWPR.Undistort_Normalized_Point_2D(p_norm, camera_views[i].distortion, camera_views[i].projectionType);
            //http://visio.googlecode.com/svn-history/r52/trunk/CV/PointCloud.cpp
            //Method: ComputeRayAngle
            // we want to compute the angle between the rays which are (X_p - c_A) and (X_q - c_B)
            // we can calculate it from the formula R(X_p - c_A) = p => X_p - c_A = Rt*p
            var R = camera_views[i].rotation;
            RT_pv = [R[0][0] * p_norm[0] + R[1][0] * p_norm[1] + R[2][0],
                R[0][1] * p_norm[0] + R[1][1] * p_norm[1] + R[2][1],
                R[0][2] * p_norm[0] + R[1][2] * p_norm[1] + R[2][2]];
            norm = Math.sqrt(RT_pv[0] * RT_pv[0] + RT_pv[1] * RT_pv[1] + RT_pv[2] * RT_pv[2]);
            RT_pv[0] /= norm;
            RT_pv[1] /= norm;
            RT_pv[2] /= norm;
            RT_vectors[i] = RT_pv;
        }
        for (var j = i + 1; j < n; j++) {
            var RT_qv = [];
            if (j in RT_vectors) {
                RT_qv = RT_vectors[j];
            }
            if (!(j in RT_vectors)) {
                var q_norm = MWPR.Normalize_Point_2D(points[j], camera_views[j]);
                q_norm = MWPR.Undistort_Normalized_Point_2D(q_norm, camera_views[j].distortion, camera_views[j].projectionType);
                var R = camera_views[j].rotation;
                RT_qv = [R[0][0] * q_norm[0] + R[1][0] * q_norm[1] + R[2][0],
                    R[0][1] * q_norm[0] + R[1][1] * q_norm[1] + R[2][1],
                    R[0][2] * q_norm[0] + R[1][2] * q_norm[1] + R[2][2]];
                norm = Math.sqrt(RT_qv[0] * RT_qv[0] + RT_qv[1] * RT_qv[1] + RT_qv[2] * RT_qv[2]);
                RT_qv[0] /= norm;
                RT_qv[1] /= norm;
                RT_qv[2] /= norm;
                RT_vectors[j] = RT_qv;
            }
            var dot = RT_pv[0] * RT_qv[0] + RT_pv[1] * RT_qv[1] + RT_pv[2] * RT_qv[2];
            var rad2deg = 180 / PI;
            var angle = Math.acos(Clamp(dot, -1.0 + 1.0e-8, 1.0 - 1.0e-8)) * rad2deg; //Acos is between 0 and PI
            if (angle > 90)
                angle = 180 - angle;
            angle_.angle = angle;
            if (angle > maxAngle) {
                maxAngle = angle;
                angle_.h1 = i;
                angle_.h2 = j;
            }
            if (angle >= thresholdDegeneracyAnglePoint) {
                degenerate = false;
                //stop = true;
                //break;
            }
        }
    }
    return degenerate;
}
//Multi View Point Reconstruction Out Angle (match_points contains original 2D point pixel coordinates in images)
MWPR.Multi_View_Point_Reconstruction_Out_Angle = function (match_points, camera_views, check_degeneracy, angle_) {
    angle_.angle = 0.0;
    var thresholdDegeneracyAnglePoint = 2;
    if (check_degeneracy == true) {
        var degenerate = MWPR.Check_Point_Set_Degeneracy_Out_Angle(match_points, camera_views, thresholdDegeneracyAnglePoint, angle_);
        // if (degenerate == true)
        //     return [NaN, NaN, NaN];
    }
    var points = new Array(camera_views.length);
    for (var i = 0; i < camera_views.length; i++) {
        points[i] = new Array(2);
    }
    for (var i = 0; i < camera_views.length; i++) {
        points[i] = MWPR.Normalize_Point_2D(match_points[i], camera_views[i]);
        points[i] = MWPR.Undistort_Normalized_Point_2D(points[i], camera_views[i].distortion, camera_views[i].projectionType);
    }
    var rotations = new Array(camera_views.length);//std::vector<std::vector<std::vector<double>>>;
    var translations = new Array(camera_views.length);
    for (var i = 0; i < camera_views.length; i++) {
        rotations[i] = camera_views[i].rotation;
        translations[i] = camera_views[i].translation;
    }
    var X = MWPR.Triangulate_Points(camera_views.length, points, rotations, translations);
    return X;
}

////////////////////////////////////////////////Projection /////////////////////////////////////////////////////////////////////////
var Projection = new Object;

//Find 3D Point On Plane From 2DPoint.3D plane in the form of ax+by+cz+d=0, plane = [a,b,c,d]
// u and v are the original 2D pixel coordinates in the given image
Projection.Find3DPointOnPlaneFrom2DPoint = function(u, v, fx, fy, cx, cy, R, C, plane) {
    let point2DPixel = [u,v];
    let cameraView = {
        rotation:R,
        fx:fx,
        fy:fy,
        cx:cx,
        cy:cy,
        cameraCenter:C
    };
    return Projection.Find3DPointOnPlaneFrom2DPointWithCameraView(point2DPixel, cameraView, plane);
}

Projection.Find3DPointOnPlaneFrom2DPointWithCameraView = function (point2DPixel, cameraView, plane) {
    var point2DNorm = MWPR.Normalize_Point_2D(point2DPixel, cameraView);
    point2DNorm = MWPR.Undistort_Normalized_Point_2D(point2DNorm, cameraView.distortion, cameraView.projectionType);
    var x = point2DNorm[0];
    var y = point2DNorm[1];
    var nominator = plane[0] * cameraView.cameraCenter.x + plane[1] * cameraView.cameraCenter.y + plane[2] * cameraView.cameraCenter.z + plane[3];
    var denominator = plane[0] * (cameraView.rotation[0][0] * x + cameraView.rotation[1][0] * y + cameraView.rotation[2][0]) +
        plane[1] * (cameraView.rotation[0][1] * x + cameraView.rotation[1][1] * y + cameraView.rotation[2][1]) +
        plane[2] * (cameraView.rotation[0][2] * x + cameraView.rotation[1][2] * y + cameraView.rotation[2][2]);
    var t = -nominator / denominator;
    var X = cameraView.cameraCenter.x + t * (cameraView.rotation    [0][0] * x + cameraView.rotation[1][0] * y + cameraView.rotation[2][0]);
    var Y = cameraView.cameraCenter.y + t * (cameraView.rotation[0][1] * x + cameraView.rotation[1][1] * y + cameraView.rotation[2][1]);
    var Z = cameraView.cameraCenter.z + t * (cameraView.rotation[0][2] * x + cameraView.rotation[1][2] * y + cameraView.rotation[2][2]);
    var point3D = [X, Y, Z];
    return point3D;
}

Projection.Project_Point_With_Distortion = function (p3D, R, t,skew, fx,fy, cx, cy, distortion, projectionType) {
    let cameraView = {
        rotation:R,
        translation:t,
        skew:skew,
        fx:fx,
        fy:fy,
        cx:cx,
        cy:cy,
        distortion:distortion,
        projectionType:projectionType
    };
    return Projection.Project_Point_With_Camera_Distortion(p3D, cameraView);
}

Projection.Project_Point_With_Camera_Distortion = function (p3D, cameraView) {
    if (p3D.length == 4) {
        p3D[0] /= p3D[3];
        p3D[1] /= p3D[3];
        p3D[2] /= p3D[3];
        p3D[3] /= p3D[3];
    }
    var x = cameraView.rotation[0][0] * p3D[0] + cameraView.rotation[0][1] * p3D[1] + cameraView.rotation[0][2] * p3D[2] + cameraView.translation[0];
    var y = cameraView.rotation[1][0] * p3D[0] + cameraView.rotation[1][1] * p3D[1] + cameraView.rotation[1][2] * p3D[2] + cameraView.translation[1];
    var z = cameraView.rotation[2][0] * p3D[0] + cameraView.rotation[2][1] * p3D[1] + cameraView.rotation[2][2] * p3D[2] + cameraView.translation[2];
    var x_prime = x / z;
    var y_prime = y / z;
    var x_double_prime;
    var y_double_prime;
    if (cameraView.projectionType == "PERSPECTIVE") {
        var r2 = x_prime * x_prime + y_prime * y_prime;
        x_double_prime = x_prime * (1 + cameraView.distortion[0] * r2 + cameraView.distortion[1] * r2 * r2);
        y_double_prime = y_prime * (1 + cameraView.distortion[0] * r2 + cameraView.distortion[1] * r2 * r2);
    } else if (cameraView.projectionType == "PERSPECTIVE_5") {
        var r2 = x_prime * x_prime + y_prime * y_prime;
        var r4 = r2 * r2;
        var r6 = r4 * r2;
        x_double_prime = x_prime * (1 + cameraView.distortion[0] * r2 + cameraView.distortion[1] * r4 + cameraView.distortion[2] * r6) + (2 * cameraView.distortion[3] * x_prime * y_prime)
            + cameraView.distortion[4] * (r2 + 2 * x_prime * x_prime);
        y_double_prime = y_prime * (1 + cameraView.distortion[0] * r2 + cameraView.distortion[1] * r4 + cameraView.distortion[2] * r6)
            + cameraView.distortion[3] * (r2 + 2 * y_prime * y_prime) + (2 * cameraView.distortion[4] * x_prime * y_prime);
    } else if (cameraView.projectionType == "PERSPECTIVE_8") {
        var r2 = x_prime * x_prime + y_prime * y_prime;
        var r4 = r2 * r2;
        var r6 = r4 * r2;
        var r8 = r4 * r4;
        x_double_prime = x_prime * (1 + cameraView.distortion[0] * r2 + cameraView.distortion[1] * r4 + cameraView.distortion[2] * r6 + cameraView.distortion[3] * r8) + ((2 * cameraView.distortion[5] * x_prime * y_prime) + cameraView.distortion[4] * (r2 + 2 * x_prime * x_prime)) * (1 + cameraView.distortion[6] * r2 + cameraView.distortion[7] * r4);
        y_double_prime = y_prime * (1 + cameraView.distortion[0] * r2 + cameraView.distortion[1] * r4 + cameraView.distortion[2] * r6 + cameraView.distortion[3] * r8) + (cameraView.distortion[5] * (r2 + 2 * y_prime * y_prime) + (2 * cameraView.distortion[4] * x_prime * y_prime)) * (1 + cameraView.distortion[6] * r2 + cameraView.distortion[7] * r4);
    } else if (cameraView.projectionType == "BROWN_CAMERA") {
        var r2 = x_prime * x_prime + y_prime * y_prime;
        var coeff = 1.0 + r2 * (cameraView.distortion[0] + r2 * (cameraView.distortion[1] + r2 * (cameraView.distortion[2] + r2 * cameraView.distortion[3])));
        x_double_prime = x_prime * coeff + cameraView.distortion[4] * x_prime * y_prime * 2.0 + cameraView.distortion[5] * (r2 + x_prime * x_prime * 2.0);
        y_double_prime = y_prime * coeff + cameraView.distortion[4] * (r2 + y_prime * y_prime * 2.0) + cameraView.distortion[5] * x_prime * y_prime * 2.0;
    } else if (cameraView.projectionType == "FISHEYE") {
        var r = Math.sqrt(x_prime * x_prime + y_prime * y_prime);
        var theta = Math.atan(r);
        var theta2 = theta * theta;
        var theta4 = theta2 * theta2;
        var theta6 = theta4 * theta2;
        var theta8 = theta4 * theta4;
        var thetad = theta * (1 + cameraView.distortion[0] * theta2 + cameraView.distortion[1] * theta4 + cameraView.distortion[2] * theta6 + cameraView.distortion[3] * theta8);
        var scaling = (r > 1e-8) ? thetad / r : 1.0;
        x_double_prime = x_prime * scaling;
        y_double_prime = y_prime * scaling;
    } else {
        var p2D = [];
        return p2D;
    }

    var point2DNorm = [x_double_prime, y_double_prime];
    var point2DPixel = MWPR.Denormalize_Point_2D(point2DNorm, cameraView);
    return point2DPixel;
}

////////////////////////////////////////////////end of Projection /////////////////////////////////////////////////////////////////////////


var MINPACK = {
    MP: MP,
    MWPR: MWPR,
    Projection: Projection
};

export {MINPACK}