import {ChangeDetectorRef, Component, NgZone, OnDestroy,} from '@angular/core';
import {Measurement, MeasurementService} from "../../services/measurement/measurement.service";
import {WireframeApplication} from "../../application/wireframeApplication";
import {LabelProperty} from "../../models/property/labelProperty";
import MeasureTool from "../../application/measureTool";
import {WireframeElementType} from "../../application/wireframeElementType";
import {VertexElement} from "../../application/vertexElement";
import {Subscription} from "rxjs";
import {SceneService} from "../../services/scenes/scene.service";

@Component({
    selector: 'measure-panel',
    styles: [require('./measurePanel.component.scss')],
    template: require('./measurePanel.component.html')
})
export class MeasurePanelComponent implements OnDestroy {
    sceneMeasurements: Measurement[];
    displayedColumns: string[] = ['names', 'lengths', 'actions'];
    private app:WireframeApplication
    wireframeChangedSub:Subscription
    constructor(private zone: NgZone, private sceneService:SceneService, private measurementService: MeasurementService, private changeDetectorRef: ChangeDetectorRef) {
        let that = this;
        this.app = this.sceneService.wireframeApplication
        this.updateSceneMeasurements();
        this.wireframeChangedSub = this.app.eventEmitter.on("wireframeChanged").subscribe(() => { that.updateSceneMeasurements() })
    }

    ngOnDestroy(): void {
        this.wireframeChangedSub.unsubscribe()
    }

    get tool(): MeasureTool {
        return this.app.getTool("MeasureTool") as MeasureTool
    }

    updateSceneMeasurements() {
        this.sceneMeasurements = this.measurementService.getMeasurements(this.app.wireframeLayer)
    }

    getName(measurement: Measurement) {
        let labelProperty = this.app.wireframeLayer.wireframe.findProperty(LabelProperty, true);
        let labelValue = labelProperty.getValue(measurement.edgeElement.pvObject);
        if (null == labelValue || labelValue.length == 0) labelValue = "Measurement-" + measurement.edgeElement.pvObject.id;
        return labelValue
    }

    getLength(measurement: Measurement) {
        return measurement.edgeElement.getLength().toFixed(3) + "m"
    }

    selectMeasurement(measurement: Measurement) {
        if (!measurement || !measurement.edgeElement) return;
        this.app.lookAtElements([measurement.edgeElement])
    }

    focusMeasurement(m: Measurement) {
        if (!m || !m.edgeElement) return;
        this.app.highlightWireframeElement(m.edgeElement);
        this.app.focusWireframeElement(m.edgeElement)
    }

    labelChanged(m:Measurement) {
        m.edgeElement.updateMaterials()
    }

    startMeasurement() {
        this.tool.startCreateMeasurement()
    }

    deleteMeasurement(measurement: Measurement) {
        // remove the measurement from the scene
        let vert1 = this.app.wireframeLayer.findWireframeElement(WireframeElementType.vertex, measurement.edgeElement.pvObject.vertex1Id) as VertexElement;
        this.app.wireframeLayer.deleteVertex(vert1);
        let vert2 = this.app.wireframeLayer.findWireframeElement(WireframeElementType.vertex, measurement.edgeElement.pvObject.vertex2Id) as VertexElement;
        this.app.wireframeLayer.deleteVertex(vert2);
        //this.app.wireframeLayer.deleteEdge(m.edgeElement, false)
        this.updateSceneMeasurements()
    }
}