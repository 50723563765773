import {PV} from "../wireframe";
import {VertexElement} from "./vertexElement";
import {EdgeElement} from "./edgeElement";
import {PlaneElement} from "./planeElement";
import {WireframeElementType} from "./wireframeElementType";
import {WidgetElement} from "./widgetElement";

class ClassData {
    constructor (public dataClass:Function, public elementClass:Function) {}
}

export class WireframeElementMappings {
    private classMap = this.getClassMap()

    private getClassMap() {
        let map = {}
        map[WireframeElementType.vertex] = new ClassData(PV.Vertex, VertexElement)
        map[WireframeElementType.edge] = new ClassData(PV.Edge, EdgeElement)
        map[WireframeElementType.plane] = new ClassData(PV.Plane, PlaneElement)
        map[WireframeElementType.widget] = new ClassData(PV.Vertex, WidgetElement)
        map[WireframeElementType.cuboid] = new ClassData(PV.Cuboid, VertexElement)
        return map
    }

    getDataClass(type:WireframeElementType):Function {
        let c = this.classMap[type]
        return c ? c.dataClass : null
    }

    getElementClass(type:WireframeElementType):Function {
        return this.classMap[type].elementClass
    }

}