import {WireframeApplication} from "../../application/wireframeApplication";
import {WireframeOperations} from "../../wireframeOperations";
import {PV} from '../../wireframe';
import {WireframeElementType} from "../../application/wireframeElementType";
import {WireframeElement} from "../../application/wireframeElement";
import {PropertyValue} from "./propertyValue";
import {ElementReferenceProperty} from "./elementReferenceProperty";
import {PlaneElement} from "../../application/planeElement";
import * as THREE from 'three';
import {CompoundProperty} from "./compoundProperty";

export class EdgeGuideProperty extends CompoundProperty {

    edge1Property: ElementReferenceProperty;
    edge2Property: ElementReferenceProperty;

    constructor() {
        super();

        this.type = ".EdgeGuideProperty";
        this.name = "Edge Guide";
        this.allowableElementTypes.push(WireframeElementType.plane);
        this.isEditable = false;

        this.edge1Property = new ElementReferenceProperty();
        this.edge1Property.name = "Azimuth";
        this.edge1Property.selectableElementTypes.push(WireframeElementType.edge);
        this.edge1Property.isEditable = false;
        this.childProperties.push(this.edge1Property);

        this.edge2Property = new ElementReferenceProperty();
        this.edge2Property.name = "Elevation";
        this.edge2Property.selectableElementTypes.push(WireframeElementType.edge);
        this.edge2Property.isEditable = false;
        this.childProperties.push(this.edge2Property);
    }

    fromJson(obj, allProperties): void {
        super.fromJson(obj, allProperties);
        this.edge1Property = this.childProperties[0] as ElementReferenceProperty;
        this.edge2Property = this.childProperties[1] as ElementReferenceProperty;
    }

    update(el: PlaneElement, pv: PropertyValue) {
        //console.log("update edgeGuideProp", el);
        let azEdge: PV.Edge;
        let elEdge: PV.Edge;
        try {
            azEdge = el.wireframeLayer.wireframe.edges[pv.value["Azimuth"].elements[WireframeElementType.edge][0]];
            elEdge = el.wireframeLayer.wireframe.edges[pv.value["Elevation"].elements[WireframeElementType.edge][0]];
        } catch (ex) {
        }
        if (!azEdge || !elEdge) return;
        let gp = el.wireframeLayer.getGroundPlane();

        let azVerts: THREE.Vector3[] = [gp.projectPoint(el.wireframeLayer.wireframe.getVert3(azEdge.vertex1Id), new THREE.Vector3()),
            gp.projectPoint(el.wireframeLayer.wireframe.getVert3(azEdge.vertex2Id), new THREE.Vector3())
        ];
        let azVec: THREE.Vector3 = azVerts[1].sub(azVerts[0]);
        azVec.normalize();

        let target: THREE.Plane = new THREE.Plane();
        target.setFromNormalAndCoplanarPoint(azVec, el.getCenter());

        let verts: Number[] = el.pvObject.vertexIds;
        for (let vertId of verts) {
            let v: THREE.Vector3 = el.wireframeLayer.wireframe.getVert3(vertId);
            v = target.projectPoint(v, null);
            el.wireframeLayer.wireframe.setVert3(vertId, v);
        }

    }

}

