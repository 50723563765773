import * as THREE from 'three';
import * as Cesium from 'cesium/Source/Cesium';

export default class ProjectionUtils {

    /*
    static localToLatLon(l:THREE.Vector3, transform:Transform):THREE.Vector3 {
        let l2g:THREE.Matrix4 = app.getLocalToGlobalTransform();
        let g:Vector3 = l.applyMatrix4(l2g);

        let latLonWkt = "WGS84";
        let latLon:number[] = proj4(transform.proj4, latLonWkt, [ g.x, g.y, g.z]);
        return new Vector3(latLon[0], latLon[1], latLon[2]);
    }
    */

    public static toGeocentric(geodetic:THREE.Vector3):THREE.Vector3 {
        // input geodetic expected to be :
        // x: latitude
        // y: longitude
        // z: elevation
        let carto = new Cesium.Cartographic.fromDegrees(geodetic.y, geodetic.x, geodetic.z);
        let carte = Cesium.Cartographic.toCartesian(carto, Cesium.Ellipsoid.WGS84);
        return new THREE.Vector3(carte.x, carte.y, carte.z)
    }

    public static toGeodetic(geocentric:THREE.Vector3):THREE.Vector3 {
        let carte = new Cesium.Cartesian3(geocentric.x, geocentric.y, geocentric.z);
        //let carte = new Cesium.Cartesian3(0, 0, 0)
        let carto = Cesium.Cartographic.fromCartesian(carte, Cesium.Ellipsoid.WGS84);
        return new THREE.Vector3(Cesium.Math.toDegrees(carto.latitude), Cesium.Math.toDegrees(carto.longitude), carto.height)
    }

    public static getOrthoAxesForGeocentricTranslation(pos:THREE.Vector3):{north: THREE.Vector3, east:THREE.Vector3, up:THREE.Vector3} {
        let tangentPlane = new THREE.Plane(pos.clone().normalize(), -pos.length());
        let upVec = tangentPlane.normal.clone();

        let northPos = new THREE.Vector3(0, 0, pos.z + 10000);
        let northPosOnPlane = new THREE.Vector3();
        tangentPlane.projectPoint(northPos, northPosOnPlane);

        let northVec = northPosOnPlane.sub(pos).normalize();
        let eastVec = northVec.clone().cross(upVec);

        return { north: northVec, east: eastVec, up: upVec}
    }

    public static getOrthotransformForGeocentric(geocentPos:THREE.Vector3):THREE.Matrix4 {
        let orthoMat = new THREE.Matrix4();
        let orthoAxes = this.getOrthoAxesForGeocentricTranslation(geocentPos);
        orthoMat.set(-orthoAxes.east.x, -orthoAxes.east.y, -orthoAxes.east.z, 0,
            orthoAxes.up.x, orthoAxes.up.y, orthoAxes.up.z, 0,
            orthoAxes.north.x, orthoAxes.north.y, orthoAxes.north.z, 0,
            0, 0, 0, 1);
        let rotatedTrans = geocentPos.clone().negate().applyMatrix4(orthoMat);
        orthoMat.setPosition(rotatedTrans);

        return orthoMat
    }

}

