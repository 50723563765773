
import {Object3DLayer} from "./object3DLayer";
import {RaycastHit} from "./raycastHandler";
import {Raycaster} from "three";
import {WireframeElement} from "./wireframeElement";
import WireframeLayer from "./wireframeLayer";

export class ScenePicker {
    layers:Object3DLayer[]

    postFilter:(hits:RaycastHit[]) => RaycastHit[]

    pick(raycaster:Raycaster):RaycastHit[] {
        let hits:RaycastHit[] = []
        this.layers.forEach((layer) => {
            hits.push(...layer.raycast(raycaster))
        })
        if (this.postFilter) hits = this.postFilter(hits)

        hits.sort((a:RaycastHit, b:RaycastHit) => {
            return (a.intersect.distance - b.intersect.distance)
        })
        return hits
    }

    pickElements(raycaster:Raycaster, elements:WireframeElement[] = null):RaycastHit[] {
        let hits:RaycastHit[] = []
        this.layers.forEach((layer) => {
            if (layer instanceof WireframeLayer) {
                hits.push(...layer.raycastElements(raycaster, elements ? elements : layer.wireframeElements))
            } else {
                hits.push(...layer.raycast(raycaster))
            }
        })
        if (this.postFilter) hits = this.postFilter(hits)

        hits.sort((a:RaycastHit, b:RaycastHit) => {
            return (a.intersect.distance - b.intersect.distance)
        })
        return hits
    }

}