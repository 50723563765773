import {CameraView} from "./cameraView";
import {CameraElement} from "../../application/cameraElement";

export class CameraPointView {
    point:THREE.Vector3
    normal:THREE.Vector3

    cameraElement:CameraElement
    cameraView: CameraView;
    imageCoordinates: THREE.Vector2;
    vectorToPoint: THREE.Vector3;
    distance: number;
    centerFactor: number;
    angle: number;
    isGeometryOccluded: boolean;
    isPointcloudOccluded: boolean;
    score:number
}
