import {
    Component,
    ComponentFactoryResolver, ComponentRef,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {InteractiveFilterModel} from "../../models/interactive/interactiveFilter.model";
import {InteractiveModel} from "../../models/interactive/interactive.model";

@Component({
    selector: 'ptv-interactive-filter-editor',
    styles: [require('./interactiveFilterEditor.component.scss')],
    template: require('./interactiveFilterEditor.component.html')
})
export default abstract class InteractiveFilterEditorComponent<T extends InteractiveModel> implements OnInit, OnDestroy {
    @ViewChild("interactiveFilterEditorContainer", {read: ViewContainerRef, static: false}) interactiveFilterEditorContainerRef: ViewContainerRef;
    @Input() interactiveFilterModel: InteractiveFilterModel<T>;
    @Output() filterUpdated = new EventEmitter<InteractiveFilterModel<T>>();
    private interactiveFilterChangedSubscription;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        @Inject('interactiveFilterModel') private _interactiveFilterModel = null
    ) {
        if(this._interactiveFilterModel) this.interactiveFilterModel = this._interactiveFilterModel;
    }

    ngOnInit(): void {
        this.showInteractiveFilterEditor();
    }

    ngOnDestroy(): void {
        if (this.interactiveFilterChangedSubscription) this.interactiveFilterChangedSubscription.unsubscribe();
        this.clearInteractiveFilterEditorContainerRef();
    }

    get filterSymbol(): string {
        let symbol = '';
        if (!this.interactiveFilterModel) return symbol;
        symbol = this.interactiveFilterModel.filterSymbol;
        return symbol;
    }

    showInteractiveFilterEditor() {
        this.clearInteractiveFilterEditorContainerRef();
        let interactiveFilterEditorComponentRef = this.createInteractiveFilterEditorComponentRef();
        if(!interactiveFilterEditorComponentRef) return;
        let interactiveFilterEditor = interactiveFilterEditorComponentRef.instance;
        if(!interactiveFilterEditor) return;
        interactiveFilterEditor.interactiveFilterModel = this.interactiveFilterModel as InteractiveFilterModel<T>;
        this.interactiveFilterChangedSubscription = interactiveFilterEditor.filterUpdated.subscribe(this.onFilterUpdated.bind(this));
    }

    abstract createInteractiveFilterEditorComponentRef() : ComponentRef<any>;

    clearInteractiveFilterEditorContainerRef() {
        if (this.interactiveFilterEditorContainerRef) this.interactiveFilterEditorContainerRef.clear();
    }

    onFilterUpdated(interactiveFilterModel: InteractiveFilterModel<T>) {
        this.triggerFilterUpdated();
    }

    triggerFilterUpdated() {
        this.filterUpdated.emit(this.interactiveFilterModel);
    }

}