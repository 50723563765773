import {NumberProperty} from "./numberProperty";
import {UnitType} from "./unitType";

export class DoubleProperty extends NumberProperty {
    numDigits: number = 3;
    unitType: UnitType = UnitType.QUANTITY

    constructor() {
        super();
        this.type = ".DoubleProperty";
        this.name = "DoubleProperty";
        this.isInteger = false;
    }

    formatValue(v: any) {
        let num = Number.parseFloat(v);
        let factor = Math.pow(10, this.numDigits);
        return Math.round(num * factor) / factor;
    }
}