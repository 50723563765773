import {WireframeElement} from "./wireframeElement";
import {WireframeApplication} from "./wireframeApplication";

import {UserActivity} from "../models/userActivity";
import {PlaneElement} from "./planeElement";
import * as THREE from 'three';
import {OrthographicCamera} from 'three';

import ProjectionUtils from "../projectionUtils";
import {WireframeRaycastHit} from "./wireframeRaycastHandler";
import {Layer} from "./layer";
import WireframeLayer from "./wireframeLayer";
import ElementLayer from "./elementLayer";
import {WireframeElementType} from "./wireframeElementType";
import {CameraElement} from "./cameraElement";
import {CameraProjectionService} from "./cameraProjectionService";
import {bool} from "aws-sdk/clients/signer";
import {TransformControls} from "../TransformControls";
//import {THREE} from "../TransformControls";
//import TransformControls = THREE.TransformControls;


export enum ToolType {
    GEOMETRY,
    RECTANGLE,
    POLYGON
}

export enum CameraControlMode {
    LEFT_RIGHT_BUTTONS,
    MIDDLE
}

export abstract class Tool {
    app:WireframeApplication
    toolPanelComponent = null
    toolName:string
    cameraControlMode:CameraControlMode = CameraControlMode.LEFT_RIGHT_BUTTONS
    lastMouseUpDownEvent:MouseEvent
    mouse:THREE.Vector2 = new THREE.Vector2()
    mouseDragStart:THREE.Vector2
    mouseDragThreshold:number = .005;
    isHandlingMouseMove = false

    highlightableFunc:(el:WireframeElement) => boolean = (el) => { return false }
    highlightIntersect:WireframeRaycastHit

    protected raycaster:THREE.Raycaster = new THREE.Raycaster()
    enableTransformTool = false
    private transformTool:TransformControls
    protected keysDown = {}

    constructor(wfApp:WireframeApplication) {
        this.app = wfApp
    }

    getTransformTool() {
        if (!this.transformTool) {
            let that = this
            this.transformTool = this.createTransformTool()
            this.app.rootScene.add(this.transformTool)
            /*
            this.app.eventEmitter.on("sceneCameraTypeChanged").subscribe((e) => {
                // for some reason it's necessary to recreate the transformControls when the camera changes between ortho and perspective
                that.app.rootScene.remove(that.transformTool);
                let oldTool = that.transformTool
                that.transformTool = that.createTransformTool()
                that.transformTool.setSpace(oldTool.space)
                that.transformTool.setMode(oldTool.getMode())
                if (that.app.selectedElements.length > 0) that.transformTool.attach(that.app.selectedElements[0])
                that.app.wireframeLayer.disposeHierarchy(oldTool)
            })*/

        }
        return this.transformTool
    }

    private createTransformTool():TransformControls {
        let tool = new TransformControls(this.app, this.app.viewer.camera, this.app.viewer.targetEl)
        tool.setSize(.75)
        tool.traverse((o) => { o.renderOrder = 20 })
        this.app.rootScene.add(tool)
        return tool
    }

    public update() {
        if (this.transformTool) {
            this.transformTool.update()
            this.transformTool.updateMatrix()
            this.transformTool.updateMatrixWorld(true)
        }
    }

    protected getMouseDrag():THREE.Vector2 {
        if (this.mouseDragStart) {
            return this.mouseDragStart.clone().sub(this.mouse)
        } else {
            return new THREE.Vector2(0, 0)
        }
    }

    public isMouseDrag():boolean {
        return this.getMouseDrag().length() > this.mouseDragThreshold
    }

    public on(event:string, ...args) {
        console.log("tool event " + event, args)
    }

    public onToolActivated() {

    }

    public onToolDectivated() {

    }

    public onWheelEvent(event:WheelEvent):boolean {

        if (this.keysDown[CameraProjectionService.hotkey]) {
            if (this.app.cameraProjectionService.active) {
                this.app.cameraProjectionService.cycleCameras(event.deltaY > 0)
            }
            return true
        }
        return false
    }

    public onKeyUp(event:KeyboardEvent) {
        //console.log("tool onKeyUp", event)
        delete this.keysDown[event.code]
        this.app.onKeyUp(event)
    }

    public onKeyDown(event:KeyboardEvent) {
        this.keysDown[event.code] = true
        //console.log("tool onKeyDown", event)
        switch (event.code) {
            case CameraProjectionService.hotkey:
                if (event.altKey) {
                    this.app.cameraProjectionService.active = !this.app.cameraProjectionService.active
                    if (this.app.cameraProjectionService.active) {
                        if (this.app.cameraProjectionService.activeProjector && this.app.cameraProjectionService.activeProjector.cameraElement) {
                            this.app.cameraProjectionService.activeProjector.enabled = true
                        } else if (this.app.selectedElements.length > 0) {
                            this.app.cameraProjectionService.activateProjectionForElement(this.app.selectedElements[0])
                        }
                        //let proj = this.app.cameraProjectionService.activeProjector
                        //if (proj) proj.cameraElement = this.app.cameraLayer.wireframeElements[0] as CameraElement
                    }
                }
                break
            case "Escape":
                this.keysDown = {}
                break
            case "KeyP":
                this.app.sceneManager.pointCloudLayer.isVisible = !this.app.sceneManager.pointCloudLayer.isVisible
                break
            case "KeyO":
                this.app.sceneManager.cesiumLayer.isVisible = !this.app.sceneManager.cesiumLayer.isVisible
                break
            case "KeyR":
                this.app.sceneManager.roiLayer.isVisible = !this.app.sceneManager.roiLayer.isVisible
                break
            case "KeyL":
                if (event.altKey) {
                    this.app.layerPanelVisible = !this.app.layerPanelVisible
                } else {
                    let edgeLabelLayer = this.app.wireframeLayer.elementLayers[WireframeElementType.edge].labelLayer
                    if (edgeLabelLayer) edgeLabelLayer.isVisible = !edgeLabelLayer.isVisible
                }
                break
            default:

        }

        this.app.onKeyDown(event)
    }

    public onMouseDown(event:MouseEvent):boolean {
        if (this.keysDown[CameraProjectionService.hotkey]) {
            let hits = this.raycastElements(this.getInteractiveElements())
            if (hits.length > 0) {
                this.app.cameraProjectionService.active = true
                this.app.cameraProjectionService.activateProjectionForElement(hits[0].element, hits[0].intersect.point)
            }
            let point = this.getMousePointCloudIntersection()
            if (point) {
                this.app.cameraProjectionService.active = true
                this.app.cameraProjectionService.activateProjectionForPointNormal(point, null)
            }
            return false
        }
        return true
    }

    private lastProjectionActivateTime = 0
    public onMouseMove(event:MouseEvent) {
        this.app.viewer.targetEl.focus()
        this.app.broadcast("userActive", new UserActivity(false))
        if (this.keysDown[CameraProjectionService.hotkey] && !this.isMouseDrag()) {
            let now = Date.now()
            if (now - this.lastProjectionActivateTime > 100) {
                let hits = this.raycastElements(this.app.wireframeLayer.wireframeElements.filter((el) => el instanceof PlaneElement))
                if (hits.length > 0) {
                    this.app.cameraProjectionService.active = true
                    this.app.cameraProjectionService.activateProjectionForElement(hits[0].element, hits[0].intersect.point)
                }
                this.lastProjectionActivateTime = now
            }
        }
    }

    public onMouseUp(event:MouseEvent) {

    }

    public onMouseClick(event:MouseEvent) {

    }

    public onMouseDoubleClick(event:MouseEvent) {
        if (event.button == 0) {
            let els = this.raycastElements(this.app.wireframeLayer.wireframeElements)
            let point:THREE.Vector3

            if (els.length > 0) {
                point = els[0].intersect.point
            } else {
                point = this.getMousePointCloudIntersection();

            }
            if (point) {
                let camPos = this.app.viewer.camera.position.clone().sub(this.app.viewer.cameraControls.target)
                camPos.setLength(camPos.length() * .75)
                camPos.add(point)
                this.app.viewer.setCameraTargets(point, camPos)
            }
        }
    }

    protected getRaycaster():THREE.Raycaster {
        this.raycaster.setFromCamera({ x: this.mouse.x, y:this.mouse.y }, this.app.viewer.camera)
        return this.raycaster
    }

    protected rayCastCameraToPoint3D(point3D, camera, windowSize) {
        let t = performance.now()
        windowSize = windowSize || 17;
        let raycaster = new THREE.Raycaster()

        raycaster.setFromCamera({ x: this.mouse.x, y:this.mouse.y }, camera)
        //console.log("raycaster origin", raycaster.ray.origin)
        //console.log("raycaster direction", raycaster.ray.direction)
        //console.log("raycastElements to point", raycaster.ray)
        //var vector = new THREE.Vector3(point3D.x, point3D.y, point3D.z);
        //var direction = vector.sub(camera.position).normalize();
        //var ray = new THREE.Ray(camera.position, direction);

        /*
        var pointClouds = [];
        this.app.rootScene.traverse(function (object) {
            if (object instanceof PointCloudOctree) {
                pointClouds.push(object);
            }
        });
        */

        var closestPoint = null;
        var closestPointDistance = null;
        var maxFrameCount;
        var pointCloudIndex;


        // var renderer= new THREE.WebGLRenderer();
        // renderer.setSize( window.innerWidth, window.innerHeight );
        // renderer.render(viewer.scene, camera);
        // renderer.render(viewer.scenePointCloud, camera);

        let materials = []

        for (let i = 0; i < this.app.sceneManager.pointClouds.length; i++) {
            let pco = this.app.sceneManager.pointClouds[i]
            let matClone = pco.material.clone()
            materials.push(matClone)
        }

        let renderer = this.app.viewer.renderer
        let camPos = new THREE.Vector3().applyMatrix4(camera.matrixWorld)
        //console.log(JSON.stringify(renderer, null, 4))
        for (var i = 0; i < this.app.sceneManager.pointClouds.length; i++) {
            let pointcloud = this.app.sceneManager.pointClouds[i];

            //renderer.setRenderTarget(this.app.viewer.renderer.getRenderTarget())

            let point = pointcloud.pick(renderer, camera, raycaster.ray, {
                pickOutsideClipRegion: true,
                pickWindowSize: windowSize,
               // mouse: { x: this.mouse.x, y: this.mouse.y }
            });
            if (point) {
                pointCloudIndex = i;
                //maxFrameCount = pointcloud.pcoGeometry.maxFrameCount;
            }

            if (!point) {
                continue;
            }

            //console.log("point", point)

            var distance = camPos.distanceTo(point.position);

            if (!closestPoint || distance < closestPointDistance) {
                closestPoint = point;
                closestPointDistance = distance;
            }
        }
        //console.log(JSON.stringify(renderer, null, 4))

        for (let i = 0; i < this.app.sceneManager.pointClouds.length; i++) {
            //this.app.viewer.pointClouds[i].material = materials[i]
            //this.app.viewer.setPointcloudMaterial(this.app.viewer.pointClouds[i].material)
        }

        if (closestPoint) {
            //we added pointIndex and hframes properties to returned point
            closestPoint.position.pointIndex = closestPoint.pointIndex;

            //if hframes are not embeded in point obtain them from projection to frames.
            let cameras = [];
            if (maxFrameCount == 0 || maxFrameCount == null) {
                //cameras = pointVisibility.getPoint3DFrames(viewer.toGeo(closestPoint.position));
            } else {
                // assumed first element is cameraCount
                let hFrames = closestPoint.hframes;
                let cameraCount = Math.min(hFrames.length - 1, hFrames[0]);
                for (let i = 1; i < cameraCount + 1; i++) {
                    cameras.push(Number(hFrames[i]))
                }
            }

            closestPoint.position.hframes = cameras;
        }
        this.app.viewer.renderer.clear()
        let pos = closestPoint ? closestPoint.position : null
        //this.app.viewer.renderer.setRenderTarget(this.app.viewer.targetEl)
        if (this.app.debug) console.log("pointcloud raycast in " + (performance.now() - t))
        //console.log("raycast point",pos)
        return pos
    }

    protected getMousePointCloudIntersection():THREE.Vector3 {
        let point3D = new THREE.Vector3(this.mouse.x, this.mouse.y, 0.5);
        point3D.unproject(this.app.viewer.camera);
        let pos = this.rayCastCameraToPoint3D(point3D, this.app.viewer.camera, null);
        return pos;
    };

    protected getMousePointOnPlane(mouse, planeWorld:THREE.Plane):THREE.Vector3 {
        let rc = new THREE.Raycaster();
        rc.setFromCamera({ x: this.mouse.x, y: this.mouse.y }, this.app.viewer.camera)
        let point = new THREE.Vector3()
        rc.ray.intersectPlane(planeWorld, point);
        return point;
    }

    protected getMousePointOnLine(mouse, line):THREE.Vector3 {
        let vector = new THREE.Vector3(mouse.x, mouse.y, 0.5);
        vector.unproject(this.app.viewer.camera);
        let point = new THREE.Vector3()
        line.closestPointToPoint(vector, point);
        return point;
    }

    protected getCameraVector():THREE.Vector3 {
        let cameraVector = new THREE.Vector3(0, 0, .5);
        cameraVector.unproject(this.app.viewer.camera);
        cameraVector.sub(this.app.viewer.camera.position);
        cameraVector.normalize();
        return cameraVector;
    }



    updateHighlightedElements() {
        let t = performance.now()

        this.highlightIntersect = null;
        this.app.highlightWireframeElement(null);

        // highlight first element of type within list of types
        let filterFunc:(el:WireframeElement) => boolean
        if (this.app.currentPickOperation) {
            filterFunc = (el:WireframeElement) => { return this.app.currentPickOperation.testPickable(el) }
        } else if (this.highlightableFunc) {
            filterFunc = this.highlightableFunc
        } else {
            filterFunc = (el) => { return true }
        }

        let elements = this.getInteractiveElements().filter(filterFunc)

        let mouseOverElements = this.raycastElements(elements)
        //console.log(mouseOverElements)
        for (let i = 0; i < mouseOverElements.length; i++) {
            let el: WireframeElement = mouseOverElements[i].element;

            /*
            if (this.app.actionMode == ActionMode.CREATE && (event as any).ctrlKey && el.pvType == WireframeElementType.plane) {
                // highlight plane primary edge
                let edge: WireframeElement = this.app.findWireframeElement(WireframeElementType.edge, this.app.wireframe.getPrimaryEdgeId(el.pvObject));
                this.app.highlightWireframeElement(edge);
                break;
            }
            */
            if (this.app.currentPickOperation) {
                if (this.app.currentPickOperation.testPickable(el)) {
                    this.highlightIntersect = mouseOverElements[i];
                    this.app.highlightWireframeElement(el);
                    break;
                }
            } else if (this.highlightableFunc.apply(this, [el])) {
                this.highlightIntersect = mouseOverElements[i];
                this.app.highlightWireframeElement(el);
                break;
            }
        }
        if (this.app.debug) console.log("updateHighlightedElements in " + (performance.now() - t))
    }

    getCameraPlane():THREE.Plane {
        let p = new THREE.Plane()
        p.setFromNormalAndCoplanarPoint(this.app.viewer.camera.getWorldDirection(new THREE.Vector3()), this.app.viewer.cameraLookatTarget)
        return p
    }

    raycastElements(elements:WireframeElement[]):WireframeRaycastHit[] {
        let rc = this.getRaycaster()
        return this.app.wireframeLayer.raycastElements(rc, elements) as WireframeRaycastHit[]
    }

    getInteractiveElements():WireframeElement[] {
        let elements:WireframeElement[] = []
        this.app.sceneManager.getInteractiveLayers().forEach((l:Layer) => {
            if (l instanceof WireframeLayer) {
                elements.push(...l.wireframeElements)
            }
        })
        return elements
    }

    getMouseOverWireframeElements(): WireframeRaycastHit[] {
        let raycaster = new THREE.Raycaster();
        raycaster.setFromCamera({x: this.mouse.x, y: this.mouse.y}, this.app.viewer.camera)
        let elements = this.getInteractiveElements()
        let that = this
        if (this.highlightableFunc) {
            elements = elements.filter((el) => {
                return that.highlightableFunc(el)
            })
        }
        return this.app.wireframeLayer.raycastElements(raycaster, elements) as WireframeRaycastHit[]
    }

    protected setPositionInWorld(point3d: THREE.Vector3, object) {
        if (!point3d) return;
        if (!object) return;
        if (!object.position) return;
        object.position.set(point3d.x, point3d.y, point3d.z);
    }

    protected setPositionOnScreenPlane(positionIn2D:THREE.Vector2, object3d, distanceInFrontOfCamera = 2, maintainObjectScale = true) {
        if (!object3d) return;
        if (!object3d.position) return;

        //TODO - implement this for OrthoCamera
        if (this.app.viewer.camera instanceof OrthographicCamera) {
            console.warn("Not implemented for OrthographicCamera")
            return
        }


        // camera
        let camera = this.app.viewer.camera as THREE.PerspectiveCamera

        // find a point in the view volume that projects to the position (i.e. 2D to 3D)
        let vector = new THREE.Vector3(positionIn2D.x, positionIn2D.y, -1);
        vector.unproject(camera);

        // camera
        let cameraPosition = camera.position.clone();
        let cameraDirection = vector.sub(cameraPosition.clone());

        // convert FOV to radians
        let effectiveFieldOfView = camera.getEffectiveFOV(); //FOV at current zoom
        let vFOV = effectiveFieldOfView * Math.PI / 180;

        // determine the position on the plane
        let positionOnPlane = cameraPosition.clone().add(cameraDirection.multiplyScalar(distanceInFrontOfCamera));

        // move the object
        object3d.position.set(positionOnPlane.x, positionOnPlane.y, positionOnPlane.z);

        // keep the rotation
        object3d.rotation.copy(camera.rotation);

        // maintain the object's scale - effectively creates a 3d sprite
        if(maintainObjectScale && object3d.geometry) {
            if(!object3d.geometry.boundingBox) object3d.geometry.computeBoundingBox();
            let boundingBox = object3d.geometry.boundingBox;
            let objectHeight = Math.ceil(boundingBox.max.y - boundingBox.min.y);

            // calculate the height of the visible plane at a depth distance in front of the camera
            let visibleHeight = 2 * Math.tan(vFOV / 2) * distanceInFrontOfCamera;
            let visibleScale = objectHeight/visibleHeight;

            object3d.scale.set(visibleScale, visibleScale, visibleScale);
        }
        object3d.updateMatrix()
        object3d.updateMatrixWorld(true)
    }

    getDrawingPlane():{ point:THREE.Vector3, plane: THREE.Plane, element: PlaneElement} {
        //let plane:THREE.Plane
        //let pvPlane:PV.Plane
        let point:THREE.Vector3
        let planeEl:PlaneElement
        let plane:THREE.Plane
        let hits = this.getMouseOverWireframeElements()
        let geomPlaneHit = hits.find((hit) => { return hit.element instanceof PlaneElement } )
        if (geomPlaneHit) {
            //pvPlane = geomPlaneHit.wireframeElement.pvObject as PV.Plane
            planeEl = (geomPlaneHit.element as PlaneElement)
            plane = planeEl.getPlane3(true)
            point = geomPlaneHit.intersect.point

        } else {
            let projectGround = this.app.getProjectGroundPlane()

            plane = projectGround.applyMatrix4(this.app.sceneManager.geocentricFrame.matrix)
            //plane = this.app.wireframeLayer.toWorldCRS(this.app.getRepresentativeGroundPlane())
            point = this.getMousePointOnPlane(this.mouse, plane)
        }
        return { point: point, plane: plane, element: planeEl }
    }

    printPointDebug(pos:THREE.Vector3) {
        let geocent = pos.clone().applyMatrix4(this.app.sceneManager.geocentricMat)
        let pointDebug = {
            scene: pos,
            geocent: geocent,
            geodetic: ProjectionUtils.toGeodetic(geocent),
            local: this.app.wireframeLayer.toWireframeCRS(pos)
        }
        console.log(pointDebug)
    }


    protected isEmptyObject(obj):boolean {
        let name;
        for (name in obj) {
            return false;
        }
        return true;
    };
}