import {Tool} from "./tool";
import {WireframeApplication} from "./wireframeApplication";
import {WireframeElementType} from "./wireframeElementType";
import * as THREE from 'three';

export class InspectTool extends Tool {
    private inspectionPointMesh:THREE.Mesh
    private inspectionPointRadius = 1
    private inspectionPointMaterial:THREE.Material
    private inspectionPointGeometry:THREE.BufferGeometry

    constructor(wfApp:WireframeApplication) {
        super(wfApp)
        this.toolName = "InspectTool"
    }

    onToolActivated() {
        super.onToolActivated();
    }

    onToolDectivated() {
        super.onToolDectivated();
        this.removeInspectionPoint()
    }

    onMouseUp(event:MouseEvent) {

        let pos = this.getMousePointCloudIntersection() as any;

        // try geometry raycastElements
        if (event.button == 0 && this.getMouseDrag().length() < .01) {
            if (pos == null) {
                let mouseOverElements = this.getMouseOverWireframeElements();
                for (let hit of mouseOverElements) {
                    if (hit.element.pvObject.pvType == WireframeElementType.plane) {
                        pos = hit.intersect.point;
                        break;
                    }
                }
            }
            if (pos && !this.isEmptyObject(pos)) {
                this.createInspectionPoint.bind(this)();
                this.moveInspectionPoint.bind(this)(pos);
                this.app.broadcast("pointInspected", pos);
            }
        }
    }

    private createInspectionPoint() {

        // create an inspection point mesh if it doesn't already exist
        if (!this.inspectionPointMesh) {
            this.inspectionPointRadius = 0.4;
            this.inspectionPointGeometry = new THREE.SphereBufferGeometry(this.inspectionPointRadius, 30, 30);
            this.inspectionPointMaterial = new THREE.MeshBasicMaterial({
                color: 0xff0000,
                transparent: true,
                opacity: 0.70,
                side: THREE.DoubleSide
            });
            this.inspectionPointMesh = new THREE.Mesh(this.inspectionPointGeometry, this.inspectionPointMaterial);

            // add it to the scene
            this.app.rootScene.add(this.inspectionPointMesh);
        }
    };

    private moveInspectionPoint(point3d: THREE.Vector3) {
        if (this.inspectionPointMesh) {
            // move the inspection point
            this.setPositionInWorld(point3d, this.inspectionPointMesh);
            this.inspectionPointMesh.updateMatrixWorld(true);
        }
    };

    private removeInspectionPoint() {
        if (this.inspectionPointMesh) {
            this.app.rootScene.remove(this.inspectionPointMesh);
            this.inspectionPointMesh = null;
        }
    };


}