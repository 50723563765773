import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {ProjectImageCollectionFilterModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilter.model";
import ProjectImageListFilterLabelComponent from "../projectImageListFilterLabel/projectImageListFilterLabel.component";
import ProjectImageListFilterEditorComponent from "../projectImageListFilterEditor/projectImageListFilterEditor.component";

@Component({
    selector: 'project-image-list-filter',
    styles: [require('./projectImageListFilter.component.scss')],
    template: require('./projectImageListFilter.component.html')
})
export default class ProjectImageListFilterComponent implements AfterViewInit, OnDestroy {
    @ViewChild("projectImageListFilterContainer", {read: ViewContainerRef, static: false}) projectImageListFilterContainerRef: ViewContainerRef;
    @Input() projectImageListFilterModel: ProjectImageCollectionFilterModel;
    @Input() allAnnotationClasses: Array<string> = [];
    @Input() allTags: Array<string>  = [];
    @Output() filterUpdated = new EventEmitter<ProjectImageCollectionFilterModel>();
    projectImageListFilterLabelComponent: ComponentRef<ProjectImageListFilterLabelComponent>;
    projectImageListFilterEditorComponent: ComponentRef<ProjectImageListFilterEditorComponent>;
    private projectImageCollectionFilterChangedSubscription;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver) {
    }

    ngAfterViewInit(): void {
        this.projectImageCollectionFilterChangedSubscription = this.projectImageListFilterModel.focusedChanged.subscribe(this.onFilterFocusChanged.bind(this));
        this.showFocusedState();
    }

    ngOnDestroy(): void {
        if(this.projectImageCollectionFilterChangedSubscription) this.projectImageCollectionFilterChangedSubscription.unsubscribe();
        this.clearProjectImageListFilterContainerRef();
    }

    onClick(event){
        this.projectImageListFilterModel.focused = true;
    }

    onFilterFocusChanged(){
        this.showFocusedState();
    }

    onFilterUpdated(projectImageListFilterModel:ProjectImageCollectionFilterModel){
        this.showProjectImageListFilterLabel();
        this.triggerFilterUpdated();
    }

    triggerFilterUpdated(){
        this.filterUpdated.emit(this.projectImageListFilterModel);
    }

    showProjectImageListFilterLabel(){
        this.clearProjectImageListFilterContainerRef();
        let projectImageListFilterLabelComponentFactory = this.componentFactoryResolver.resolveComponentFactory(ProjectImageListFilterLabelComponent);
        this.projectImageListFilterLabelComponent = this.projectImageListFilterContainerRef.createComponent(projectImageListFilterLabelComponentFactory);
        this.projectImageListFilterLabelComponent.instance.projectImageListFilterModel = this.projectImageListFilterModel;
    }

    showProjectImageListFilterEditor(){
        this.clearProjectImageListFilterContainerRef();
        let projectImageListFilterEditorComponentFactory = this.componentFactoryResolver.resolveComponentFactory(ProjectImageListFilterEditorComponent);
        this.projectImageListFilterEditorComponent = this.projectImageListFilterContainerRef.createComponent(projectImageListFilterEditorComponentFactory);
        this.projectImageListFilterEditorComponent.instance.projectImageListFilterModel = this.projectImageListFilterModel;
        this.projectImageListFilterEditorComponent.instance.allAnnotationClasses = this.allAnnotationClasses;
        this.projectImageListFilterEditorComponent.instance.allTags = this.allTags;
        this.projectImageListFilterEditorComponent.instance.filterUpdated.subscribe(this.onFilterUpdated.bind(this));
    }

    showFocusedState(){
        let focused = this.projectImageListFilterModel.focused;
        if(focused){
            this.showProjectImageListFilterEditor();
        } else {
            this.showProjectImageListFilterLabel();
        }
    }

    clearProjectImageListFilterContainerRef(){
        this.projectImageListFilterContainerRef.clear();
    }
}