import firebase from 'firebase/app';
import 'firebase/database';
import {Injectable} from "@angular/core";
import {ApiService} from "../api/api.service";
import {UserModel} from "../../models/users/user.model";
import {ProjectModel} from "../../models/projects/project.model";
import {CustomerModel} from "../../models/customers/customer.model";
import {PointivoFirebaseSingleton} from "../../firebaseSingleton";
import {PointivoS3Singleton} from "../../s3Singleton";

@Injectable({
    providedIn: 'root',
})
export class ApplicationService {

    constructor(private apiService: ApiService) {
    }

    _applicationConfig: any = null;
    get applicationConfig(): any {
        return this._applicationConfig;
    }

    set applicationConfig(applicationConfig: any) {
        this._applicationConfig = applicationConfig;
    }

    get amazonConstants(): any {
        let amazonConstants = null;
        if (!this.applicationConfig) return amazonConstants;
        amazonConstants = this.applicationConfig.amazonConstants;
        return amazonConstants;
    }

    get firebaseConfig(): any {
        let firebaseConfig = null;
        if (!this.applicationConfig) return firebaseConfig;
        firebaseConfig = this.applicationConfig.firebaseConfig;
        return firebaseConfig;
    }

    get firebaseConstants(): any {
        let firebaseConstants = null;
        if (!this.applicationConfig) return firebaseConstants;
        firebaseConstants = this.applicationConfig.firebaseConstants;
        return firebaseConstants;
    }

    get internalConstants(): any {
        let internalConstants = null;
        if (!this.applicationConfig) return internalConstants;
        internalConstants = this.applicationConfig.internalConstants;
        return internalConstants;
    }

    private _userModel: UserModel;
    get userModel(): UserModel {
        return this._userModel;
    }

    set userModel(userModel: UserModel) {
        this._userModel = userModel;
    }

    private _customerModel: CustomerModel;
    get customerModel(): CustomerModel {
        return this._customerModel;
    }

    set customerModel(customerModel: CustomerModel) {
        this._customerModel = customerModel;
    }

    isApplicationInitialized(): boolean {
        return this.applicationConfig !== null;
    }

    initializeApplication(): Promise<void> {
        let self = this;
        let nullResponse = new Promise<void>(resolve => {
            resolve(null);
        });
        if (this.isApplicationInitialized()) return nullResponse;
        return this.apiService.getConfig().then((applicationConfig: any) => {
            self.applicationConfig = applicationConfig;
            if (!PointivoFirebaseSingleton.isInitialized()) {
                PointivoFirebaseSingleton.initialize(this.firebaseConfig);
            }
            if (!PointivoS3Singleton.isInitialized()){
                PointivoS3Singleton.initialize(this.amazonConstants)
            }
        });
    }

    login(username: string, password: string): Promise<UserModel> {
        let self = this;
        return new Promise<UserModel>((resolve, reject) => {
            this.apiService.login(username, password).then((userModel: UserModel) => {
                self.userModel = userModel;
                resolve(userModel);
            }, (error) => {
                reject(error)
            })
        });
    }

    resumeUserSession(): Promise<UserModel> {
        let self = this;
        return new Promise<UserModel>((resolve, reject) => {
            self.apiService.getUser(0).then(
                (userResponse: any) => {
                    if (userResponse.success) {
                        let currentUser = userResponse.data;
                        self.userModel = new UserModel().deserialize(currentUser);

                        // associated customer
                        self.apiService.getCustomer(self.userModel.customerId).then((customerResponse: any) => {
                            let currentCustomer = customerResponse.data;
                            self.customerModel = new CustomerModel().deserialize(currentCustomer);

                            resolve(self.userModel);
                        }, (err) => {
                            reject(err);
                        });

                    } else {
                        resolve(null);
                    }
                },
                (err) => {
                    reject(err);
                })
        });
    }

    authorizeProjects(): Promise<void> {
        let self = this;
        return new Promise<void>((resolve, reject) => {
            this.apiService.createFirebaseToken(0).then((response: any) => {
                let projectToken = response.token;
                // authorize by token
                return PointivoFirebaseSingleton.getInstance().auth.signInWithCustomToken(projectToken)
                    .then((projectUserCredentials: firebase.auth.UserCredential) => {
                    resolve();
                }, (error) => {
                    reject(error)
                });
            }, (error) => {
                reject(error)
            });
        });
    }

    authorizeProject(projectId: string): Promise<void> {
        let self = this;

        let alreadyAuthorized = self.userModel.hasProjectAuthorization(projectId);
        if (alreadyAuthorized) {
            let cachedResponse = new Promise<void>(resolve => {
                resolve(null);
            });
            return cachedResponse;
        }

        return new Promise<void>((resolve, reject) => {
            this.apiService.createFirebaseToken(projectId).then((response: any) => {
                let projectToken = response.token;

                // authorize by token
                let auth = PointivoFirebaseSingleton.getInstance().auth;
                return auth.signInWithCustomToken(projectToken).then((projectUserCredentials: firebase.auth.UserCredential) => {
                    this.userModel.addProjectAuthorization(projectId, projectToken, projectUserCredentials);
                    resolve();
                }, (error) => {
                    reject(error)
                });
            }, (error) => {
                reject(error)
            });
        });
    }

    getProject(projectId: string): Promise<ProjectModel> {
        return this.apiService.getViewerMetadata(projectId).then((projectMetadata) => {
            let projectModel = new ProjectModel(projectMetadata);

            // project data is already loaded
            if (projectModel.isProjectDataReady) {
                return new Promise<ProjectModel>(((resolve, reject) => {
                    resolve(projectModel);
                }))
            }

            // wait until project data is loaded
            return new Promise<ProjectModel>((resolve, reject) => {
                projectModel.projectDataReady.subscribe(() => {
                    resolve(projectModel);
                })
            })
        })
    }
}