import {PV} from "../wireframe";
import {VertexElement} from "./vertexElement";
import {EdgeElement} from "./edgeElement";
import {PlaneElement} from "./planeElement";

export enum WireframeElementType {
    vertex = 1,
    edge = 2,
    plane = 3,
    camera = 4,
    widget = 5,
    group = 6,
    volume = 7,
    cuboid = 8
}

