export enum EdgeType {
    RIDGE,
    VALLEY,
    EAVE,
    RAKE,
    HIP,
    FLASHING,
    STEP_FLASHING,
    UNKNOWN,
    PARAPET,
    TRANSITION
}