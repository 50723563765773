import tpl from './layers.html'
import {WireframeApplication} from "../../application/wireframeApplication";
import {PV} from "../../wireframe";
import {Layer} from "../../application/layer";
import PVUtils from "../../pvUtils";

declare var wfApp:WireframeApplication

export default {
    bindings: {},
    controller: ['$scope', '$sce', function ($scope, $sce) {
        let ctrl = this;

        this.selectedLayerInfo = null

        this.ngOnInit = function() {
            ctrl.layerInfoList = ctrl.getLayers()
        }

        interface LayerInfo {
            expanded:boolean
            depth:number
            layer:Layer
        }

        this.selectLayer = function(layerInfo:LayerInfo) {
            if (layerInfo.layer.resource) {
                this.selectedLayerInfo = layerInfo
                console.log(layerInfo.layer.resource)
            }
            this.toggleLayerVisibility(layerInfo.layer);
        }

        this.layerInfoCache = new Map<Layer, LayerInfo>()
        this.getLayerInfoForLayer = function(layer:Layer) {
            if (!this.layerInfoCache.get(layer)) this.layerInfoCache.set(layer, {
                expanded: true
            })
            return this.layerInfoCache.get(layer)
        }

        this.layerInfoList = []

        this.getLayers = function () {
            this.layerInfoList.length = 0
            for (let layer of wfApp.sceneManager.rootLayers) {
                ctrl.recurseLayers(this.layerInfoList, layer, 0)
            }
            return this.layerInfoList
        };

        this.recurseLayers = function(layerList:LayerInfo[], layer:Layer, depth:number) {
            let layerInfo:LayerInfo = this.getLayerInfoForLayer(layer)
            layerInfo.layer = layer
            layerInfo.depth = depth
            layerList.push(layerInfo)
            if (layerInfo.layer.isExpandable && layerInfo.expanded) {
                for (let childLayer of layer.children) {
                    ctrl.recurseLayers(layerList, childLayer, depth + 1)
                }
            }
        }

        this.toggleLayerVisibility = function (layer:Layer) {
            let isActive = layer.isVisible
            if (isActive) {
                layer.isVisible = false
            } else {
                let currentLayer = layer;
                while (null != currentLayer) {
                    currentLayer.isVisible = true
                    currentLayer = currentLayer.parentLayer;
                }
            }
        };

        this.toggleLayerInteraction = function (layer:Layer) {
            layer.isInteractionEnabled = !layer.isInteractionEnabled
        };

        this.toggleExpansion = function(layerInfo:LayerInfo) {
            if (!layerInfo.layer.isExpandable) return
            layerInfo.expanded = !layerInfo.expanded
            $scope.$evalAsync()
        }

        this.getExpansionCharacter = function(layerInfo:LayerInfo) {
            if (layerInfo.layer.children.length < 1 || !layerInfo.layer.isExpandable) return "&nbsp&nbsp&nbsp"
            if (layerInfo.expanded) return "&#9660;"
            return "&#9658;"
        }

        this.formatUnixTime = function(time) {
            return PVUtils.formatUnixTime(time / 1000)
        }

    }],
    template: tpl
}

