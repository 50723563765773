import {WireframeElementType} from "../../application/wireframeElementType";
import {Property} from "./property";
import {VertexAdjustState} from "./vertexAdjustState";

export class VertexAdjustStateProperty extends Property {
    constructor() {
        super();
        this.type = ".VertexAdjustStateProperty";
        this.name = "Vertex Adjustment";
        this.defaultValue = new VertexAdjustState();
        this.isEditable = false;
        this.isReadonly = true;
        this.isVisible = false;
        this.allowMultiplePerElement = false;
        this.allowMultiplePerWireframe = false;
        this.allowableElementTypes = [WireframeElementType.plane];
    }
}