import { Component } from "@angular/core";
import { SceneService } from "src/app/services/scenes/scene.service";

@Component({
    selector: 'ptv-project-management-resources',
    styles: [require('./projectManagementResources.component.scss')],
    template: require('./projectManagementResources.component.html')
})
export default class ProjectManagementResourcesComponent {
    
    constructor(private sceneService: SceneService) {
    }

    
}