import tpl from './selectionInfo.html';
import {WireframeApplication} from "../../application/wireframeApplication";
import {WireframeElementType} from "../../application/wireframeElementType";

declare var wfApp: WireframeApplication;

export default {
    bindings: {},
    controller: [function () {
        let ctrl = this;

        this.getElementTypeCount = function (type) {
            if (wfApp.propertyManager.elementsByType[type]) {
                return wfApp.propertyManager.elementsByType[type].length;
            }
            return 0;
        };

        this.getSelectionSize = function () {
            return wfApp.selectedElements.length;
        };

        this.getPrimarySelection = function () {
            let sel = wfApp.selectedElements;
            if (sel.length > 0) {
                return WireframeElementType[sel[0].pvObject.pvType] + "-" + sel[0].pvObject.id;
            }
            return "-";
        };

        this.deselectByType = function (type) {
            wfApp.wireFrameElements.forEach(function (el) {
                if (el.isSelected && el.pvObject.pvType == type) wfApp.selectWireframeElement([el], false, false);
            });
        };

    }],
    template: tpl
}

