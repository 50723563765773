import {
    Component, Input
} from '@angular/core';

@Component({
    selector: 'json',
    styles: [require('./json.component.scss')],
    template: require('./json.component.html')
})
export default class JsonComponent {
    @Input() json;
    constructor(
    ) {
    }
}