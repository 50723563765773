import {
    Component, Inject, Input
} from '@angular/core';
import {InteractiveFilterModel} from "../../models/interactive/interactiveFilter.model";
import {InteractiveModel} from "../../models/interactive/interactive.model";

@Component({
    selector: 'ptv-interactive-filter-labelSprite',
    styles: [require('./interactiveFilterLabel.component.scss')],
    template: require('./interactiveFilterLabel.component.html')
})
export default class InteractiveFilterLabelComponent<T extends InteractiveModel> {
    @Input() interactiveFilterModel: InteractiveFilterModel<T>;

    constructor(
        @Inject('interactiveFilterModel') private _interactiveFilterModel = null) {
        if(this._interactiveFilterModel) this.interactiveFilterModel = this._interactiveFilterModel;
    }

    get filterSymbol(): string{
        let symbol = '';
        if (!this.interactiveFilterModel) return symbol;
        symbol = this.interactiveFilterModel.filterSymbol;
        return symbol;
    }

    get filterLabel(): string {
        let name = '';
        if (!this.interactiveFilterModel) return name;
        name = this.interactiveFilterModel.filterLabel;
        return name;
    }
}