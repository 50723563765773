import { ModuleData } from '../../models/modules/module.model';
import { Router, Route } from '@angular/router';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class RouterService {
    existingRoutes: BehaviorSubject<Route[]>;
    private debug = false;

    constructor(private router: Router) {
        this.existingRoutes = new BehaviorSubject<Route[]>(this.routes);
    }

    private get routes(): Route[]{
        let routesToReturn = this.router.config;
        return routesToReturn.filter(x => x.path !== "");
    }

    isRouteActive(url:string, exact:boolean = false):boolean{
       return this.router.isActive(url, exact)
    }

    createAndRegisterRoute(moduleToRegister: ModuleData, loader:(moduleData:ModuleData)=>Observable<any>) {
        let route: Route = { 
            path: moduleToRegister.routePath,
            loadChildren: async () => {
                if(this.debug) console.log("loadChildren", moduleToRegister.moduleName);
                let exports = await loader(moduleToRegister).toPromise();
                return exports[`${moduleToRegister.moduleName}`];
            }
        };
        this.registerRoute(route);
    }

    routeIsRegistered(path: string) {
        return this.router.config.filter(r => r.path === path).length > 0;
    }

    registerRoute(route: Route){
        if(this.routeIsRegistered(route.path)) return;
        if(this.debug) console.log("Register", route.path, route);
        this.router.config.push(route);
        this.updateRouteConfig(this.router.config);
    }

    unRegisterRoute(path: string){
        if(this.debug) console.log("Unregister", path);
        this.updateRouteConfig(this.router.config.filter(route => route.path !== path));
    }    

    private updateRouteConfig(config) {
        this.router.resetConfig(config);
        this.existingRoutes.next(this.routes);
    }

    navigate(hash:string){
        this.router.navigate([hash]);
    }

    navigateWindowLocationHash(){
        let hash = window.location.hash;
        if(!hash) {
            hash = '/wireframe';
        } else {
            hash = window.location.hash.slice(1);
        }
        this.navigate(hash)
    }
}