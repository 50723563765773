import {InteractiveModel} from "./interactive.model";
import { v4 as uuid } from 'uuid';

export class InteractiveOperationModel<T extends InteractiveModel> {
    id: string = uuid();
    operation: string;
    operand: T;
    parameters: any;

    constructor(
        operation: string,
        operand: T,
        parameters: any
    ) {
        this.operation = operation;
        this.operand = operand;
        this.parameters = parameters;
    }
}