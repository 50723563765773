import {DoubleProperty} from "./doubleProperty";
import {WireframeElementType} from "../../application/wireframeElementType";

export class PointDensityProperty extends DoubleProperty {
    constructor() {
        super();
        this.isEditable = false;
        this.name = "Point Density";
        this.type = ".PointDensityProperty";
        this.allowableElementTypes = [WireframeElementType.plane];
    }
}