import {WireframeElementType} from "../../application/wireframeElementType";
import {DictionaryProperty} from "./dictionaryProperty";

export class PlaneAnnotationProperty extends DictionaryProperty {
    constructor() {
        super();
        this.isEditable = false;
        this.allowMultiplePerElement = false;
        this.allowMultiplePerWireframe = false;
        this.name = "Plane Annotation";
        this.type = ".PlaneAnnotationProperty";
        this.allowableElementTypes = [WireframeElementType.plane];
        this.knownValues = {
            "0": "Wall, Parapet",
            "1": "Wall, Gable",
            "2": "Wall, Dormer",
            "3": "Wall, Cupola",
            "4": "Wall, Roof Screen",
            "5": "Roof, Downspout",
            "6": "Roof, Parapet Cap Coping",
            "7": "Penetration, Vent, Plumbing / Soil",
            "8": "Penetration, Vent, Exhaust",
            "9": "Penetration, Hatch",
            "10": "Penetration, Skylight",
            "11": "Penetration, HVAC, Equipment",
            "12": "Penetration, HVAC, Ductwork",
            "13": "Penetration, Electrical Conduit",
            "14": "Penetration, Expansion Joint",
            "15": "Penetration, Pitch Pan / Pocket",
            "16": "Penetration, Curb Support / Sleeper",
            "17": "Penetration, Drain, Interior",
            "18": "Penetration, Drain, Scupper",
            "19": "Other, Equipment",
            "20": "Other, Walkway",
            "21": "Other, Pad",
            "22": "Material, Single-ply, Rubber/EPDM",
            "23": "Material, Single-ply, PVC",
            "24": "Material, Single-ply, TPO",
            "25": "Material, Built-up (BUR), Asphalt",
            "26": "Material, Built-up (BUR), Tar & Gravel",
            "27": "Material, Built-up (BUR), Rubber/Modified Bitumen",
            "28": "Material, Spray Foam, Poly",
            "29": "Material, Asphalt, Shingle",
            "30": "Material, Asphalt, Rolled/Flat",
            "31": "Material, Metal, Standing Seam",
            "32": "Material, Metal, Aluminum Shake",
            "33": "Material, Tile, Concrete",
            "34": "Material, Tile, Clay/Spanish",
            "35": "Material, Tile, Slate",
            "36": "Material, Wood, Shake/Shingle",
            "37": "Material, Plastic, Polymer",
            "38": "Material, Other",
            "39": "Damage, Water Ponding",
            "40": "Damage, Wetted Insulation",
            "41": "Damage, Other"
        };
        this.setValuesFromObject(this.knownValues);
    }
}