import {PV} from "../../wireframe";
import {WireframeElementType} from "../../application/wireframeElementType";
import {WireframeElement} from "../../application/wireframeElement";
import {PropertyValue} from "./propertyValue";
import Component = PV.Component;

export abstract class Property {
    type: string = ".Property";
    subType: string
    name: string;
    label: string;
    onChange: Function;
    defaultValue: any;
    allowableElementTypes: WireframeElementType[] = [];
    allowMultiplePerElement: boolean = true;
    allowMultiplePerWireframe: boolean = true;
    isEditable: boolean = true;
    isReadonly: boolean = false;
    isVisible: boolean = true;
    id: number;

    fromJson(obj, allProperties: Function[] = []): void {
        this.name = obj.name;
        this.label = obj.label;
        this.type = obj.type;
        this.subType = obj.subType
        this.defaultValue = obj.defaultValue;
        this.allowableElementTypes = obj.allowableElementTypes;
        this.allowMultiplePerWireframe = obj.allowMultiplePerWireframe;
        this.isEditable = obj.isEditable;
        this.isReadonly = obj.isReadonly;
        this.isVisible = obj.isVisible;
        this.id = obj.id;
    }

    clamp(val: any): any {
        return val;
    }

    getLabel(): String {
        if (null != this.label) return this.label;
        return this.name;
    }

    formatValue(v: any) {
        return v;
    }

    removeValue(el: WireframeElement) {
        let pv: PropertyValue = this.getPropertyValue(el.pvObject);
        if (null == pv) return;
        let that = this;
        el.pvObject.properties = el.pvObject.properties.filter(function (pv: PropertyValue) {
            return (pv.id != that.id);
        });
        this.onRemoveFromElement(el, pv);
    }

    addValue(el: WireframeElement, value: any) {
        let pv = new PropertyValue(this.id, value);
        el.pvObject.properties.push(pv);
        this.onAddToElement(el)
        return pv;
    }

    setValue(el: WireframeElement, value: any) {
        value = this.formatValue(value);
        let pv: PropertyValue = this.getPropertyValue(el.pvObject);
        if (!pv) {
            pv = this.addValue(el, value);
        }
        pv.value = value;
    }

    getPropertyValue(component: Component): PropertyValue {
        if(!component) return null;
        if(!component.properties) return null;
        for (let i = 0; i < component.properties.length; i++) {
            let pv = component.properties[i];
            if (Number(pv.id) == Number(this.id)) {
                return pv;
            }
        }
        return null;
    }

    getValue(component: Component) {
        let pv = this.getPropertyValue(component);
        if (pv) return pv.value;
        return null;
    }

    getDefaultValue() {
        return this.defaultValue;
    }

    equals(other: Property): boolean {
        if (this.constructor.name === other.constructor.name && this.name === other.name) {
            return true
        }
        return false
    }

    onLoad() {
    }

    onAddToWireframe() {
    }

    onAddToElement(e: WireframeElement) {
    }

    onRemoveFromElement(el: WireframeElement, pv: PropertyValue) {
    }

    onRemoveFromWireframe() {
    }

    apply(el: WireframeElement, pv: PropertyValue) {
    }

    valueFromJson(json:any):any {
        return json
    }

    static deserialize(propClass: string, obj: any, allPropClasses: Function[]): Property {
        let prop = new allPropClasses[propClass]();
        prop.fromJson(obj, allPropClasses);
        return prop;
    }
}

