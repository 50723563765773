import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, OnDestroy } from "@angular/core";
import { SceneService } from "src/app/services/scenes/scene.service";
import { EdgeTypeProperty } from "src/app/models/property/edgeTypeProperty";
import { PropertyValue } from "src/app/models/property/propertyValue";
import { GeoJsonFeatureCollection } from "src/app/collections/geoJsonFeatures/geoJsonFeature.collection";
import { GeoJsonFeatureModel } from "src/app/models/geoJsonFeatures/geoJsonFeature.model";
import { LeafletEvent } from "leaflet";
import { InteractiveSelectEventModel } from "src/app/models/interactive/interactiveSelectEvent.model";
import { InteractiveSelectionModel } from "src/app/models/interactive/interactiveSelection.model";

@Component({
    selector: 'ptv-edge-verification-item',
    styles: [require('./edgeVerificationItem.component.scss')],
    template: require('./edgeVerificationItem.component.html')
})
export default class EdgeVerificationItemComponent implements OnInit, OnDestroy {
    @Input() edgeType: any;
    @Input() edgeCollection: GeoJsonFeatureCollection;
    @ViewChild('thumbnailOutlet', {static: true}) thumbnailOutlet: ElementRef;

    private L: any;
    private map: any;
    private wireframeFeatures: any;
    private itemAddedSubscription;
    private itemRemovedSubscription;
    private interactiveSelectionModel;
    private focusedChangingSubscription;
    private focusedChangedSubscription;
    private selectedChangingSubscription;
    private selectedChangedSubscription;
    private highlightedChangingSubscription;
    private highlightedChangedSubscription;
    count: number;


    constructor(
        private zone: NgZone,
        private sceneService: SceneService
    ) {
        this.L = (window as any).L;
    }

    ngOnInit(): void {
        let self = this;
        self.interactiveSelectionModel = new InteractiveSelectionModel<GeoJsonFeatureModel>(() => {
            return self.edgeCollection.toArray();
        });
        self.addCollectionListeners();
        self.createMap();
    }

    changesTimer;
    onChanges(): void {
        let self = this;
        if (self.changesTimer) clearTimeout(self.changesTimer);
        self.changesTimer = setTimeout(function () {
            self.updateWireframeFeatures();
        }, 100);

    }

    ngOnDestroy(): void {
        this.removeCollectionListeners();
        this.removeMap();
    }

    addCollectionListeners() {
        if (this.edgeCollection) {
            this.itemAddedSubscription = this.edgeCollection.itemAdded.subscribe(this.onChanges.bind(this));
            this.itemRemovedSubscription = this.edgeCollection.itemRemoved.subscribe(this.onChanges.bind(this));
            this.focusedChangingSubscription = this.edgeCollection.focusedChanging.subscribe(this.onFeatureCollectionFocusChanging.bind(this));
            this.focusedChangedSubscription = this.edgeCollection.focusedChanged.subscribe(this.onFeatureCollectionFocusChanged.bind(this));
            this.selectedChangingSubscription = this.edgeCollection.selectedChanging.subscribe(this.onFeatureCollectionSelectChanging.bind(this));
            this.selectedChangedSubscription = this.edgeCollection.selectedChanged.subscribe(this.onFeatureCollectionSelectChanged.bind(this));
            this.highlightedChangingSubscription = this.edgeCollection.highlightedChanging.subscribe(this.onFeatureCollectionHighlightChanging.bind(this));
            this.highlightedChangedSubscription = this.edgeCollection.highlightedChanged.subscribe(this.onFeatureCollectionHighlightChanged.bind(this));
        }
    }

    removeCollectionListeners() {
        if (this.itemAddedSubscription) this.itemAddedSubscription.unsubscribe();
        if (this.itemRemovedSubscription) this.itemRemovedSubscription.unsubscribe();
        if (this.focusedChangingSubscription) this.focusedChangingSubscription.unsubscribe();
        if (this.focusedChangedSubscription) this.focusedChangedSubscription.unsubscribe();
        if (this.selectedChangingSubscription) this.selectedChangingSubscription.unsubscribe();
        if (this.selectedChangedSubscription) this.selectedChangedSubscription.unsubscribe();
        if (this.highlightedChangingSubscription) this.highlightedChangingSubscription.unsubscribe();
        if (this.highlightedChangedSubscription) this.highlightedChangedSubscription.unsubscribe();
    }

    createMap() {
        let self = this;

        // Create the map outside of angular so the various map events don't trigger change detection
        this.zone.runOutsideAngular(() => {

            // map
            this.map = self.L.map(this.thumbnailOutlet.nativeElement, {
                crs: self.L.CRS.Simple,
                attributionControl: false,
                minZoom: -5,
                zoomSnap: 0,
                zoomControl: false,
                scrollWheelZoom: false
            });
            this.map.on('focus', () => { this.map.scrollWheelZoom.enable(); });
            this.map.on('blur', () => { this.map.scrollWheelZoom.disable(); });

            // wireframe features
            self.wireframeFeatures = new self.L.FeatureGroup();
            self.map.addLayer(self.wireframeFeatures);

            self.updateWireframeFeatures();
        });
    }

    removeMap() {
        if (!this.map) return;
        this.map.remove();
    }

    protected updateWireframeFeatures() {
        if (!this.map) return;
        let self = this;
        this.count = 0;

        this.edgeCollection.forEach(e => {
            if (e.properties.edgeType == self.edgeType.value) this.count++;
        });
        this.clearWireframeFeatures();
        this.createWireframeFeatures();
    }

    protected clearWireframeFeatures() {
        if (this.wireframeFeatures) this.wireframeFeatures.clearLayers();
    }

    protected createWireframeFeatures() {
        let self = this;

        // all wireframe features
        let wireframeFeatures = self.edgeCollection.toArray();

        let wireframeGeoJsonLayers = new self.L.GeoJSON(wireframeFeatures, {
            style(feature) {
                let edgeProp = self.sceneService.wireframeApplication.wireframe.findProperty(EdgeTypeProperty, true);
                let desiredValue = new PropertyValue(edgeProp.id, self.edgeType.value);
                let rules = self.sceneService.wireframeApplication.propertyDisplayConfig.rules.filter(r => r.criterion.matchesPropertyValue(desiredValue))

                let color = '#FFFFFF';
                if (rules && rules.length > 0)
                    color = '#' + self.rgbToHex(rules[0].color[0]) + self.rgbToHex(rules[0].color[1]) + self.rgbToHex(rules[0].color[2])

                return {
                    color: color,
                    className: self.getFeatureClassName(feature)
                };
            }
        });

        wireframeGeoJsonLayers.eachLayer((layer: any) => {
            if (!layer) return;
            let geoJsonFeature = layer.toGeoJSON();
            if (!geoJsonFeature) return;
            let featureModel = self.findFeatureModelByFeature(geoJsonFeature);
            layer.on('mouseover', () => {
                if (featureModel) featureModel.highlighted = true;
            });
            layer.on('mouseout', () => {
                if (featureModel) featureModel.highlighted = false;
            });
            layer.on('click', (event) => {
                let append = event.originalEvent.ctrlKey;
                if (featureModel) featureModel.setSelected(!featureModel.selected, false, append);
            });
            layer.on('dblclick', (event) => {
                self.map.doubleClickZoom.disable();
                setTimeout(function () { self.map.doubleClickZoom.enable(); }, 1000);
                if (featureModel) featureModel.focused = true;
            });
            self.wireframeFeatures.addLayer(layer);
        });

        self.map.fitBounds(self.wireframeFeatures.getBounds(), { padding: [50, 50] });        
    }

    rgbToHex(rgb) {
        var hex = Number(rgb).toString(16);
        if (hex.length < 2) {
            hex = "0" + hex;
        }
        return hex;
    }

    onFeatureCollectionFocusChanging(featureModel) {
    }

    onFeatureCollectionFocusChanged(featureModel) {
        this.focusFeature(featureModel);
    }

    onFeatureCollectionSelectChanging(interactiveSelectEventModel: InteractiveSelectEventModel<GeoJsonFeatureModel>) {
        this.interactiveSelectionModel.selectionChangingHandler(interactiveSelectEventModel);
    }

    onFeatureCollectionSelectChanged(interactiveSelectEventModel: InteractiveSelectEventModel<GeoJsonFeatureModel>) {
        this.interactiveSelectionModel.selectionChangedHandler(interactiveSelectEventModel);
        let featureModel = interactiveSelectEventModel.interactiveModel;
        this.selectFeature(featureModel);
    }

    onFeatureCollectionHighlightChanging(featureModel: GeoJsonFeatureModel) {
    }

    onFeatureCollectionHighlightChanged(featureModel: GeoJsonFeatureModel) {
        this.highlightFeature(featureModel);
    }

    focusFeature(featureModel: GeoJsonFeatureModel) {
        let self = this;
        self.updateFeatureClassName(featureModel);
        // if (featureModel.focused)
        //     self.panToFeature(featureModel);
    }

    selectFeature(featureModel: GeoJsonFeatureModel) {
        let self = this;
        self.updateFeatureClassName(featureModel);
    }

    highlightFeature(featureModel: GeoJsonFeatureModel) {
        let self = this;
        self.updateFeatureClassName(featureModel);
    }

    findFeatureModelByFeature(geoJsonFeature: any) {
        let featureModel = null;
        if (!geoJsonFeature) return featureModel;
        let properties = geoJsonFeature.properties;
        if (!properties) return featureModel;
        let id = properties.id;
        if (id == null) return featureModel;
        if (!this.edgeCollection) return featureModel;
        this.edgeCollection.forEach((currentFeatureModel) => {

            if (currentFeatureModel.properties && currentFeatureModel.properties.id == id) {
                featureModel = currentFeatureModel;
            }
            return featureModel === null;
        });
        return featureModel;
    }

    findFeatureLayerByModel(featureModel: GeoJsonFeatureModel) {
        let self = this;
        let featureLayer = null;
        self.wireframeFeatures.eachLayer((currentLayer) => {
            if (!currentLayer.feature) return;
            let feature = currentLayer.feature;
            if (!feature.properties) return;
            let properties = feature.properties;
            if (properties.id == null) return;
            if (featureModel.properties && featureModel.properties.id === properties.id) {
                featureLayer = currentLayer;
            }
        });
        return featureLayer;
    }

    updateFeatureClassName(featureModel: GeoJsonFeatureModel) {
        let self = this;
        let featureLayer = self.findFeatureLayerByModel(featureModel);
        if (!featureLayer) return;
        let className = self.getFeatureClassName(featureLayer.feature);
        let interactionClassName = self.getInteractionStyle(featureModel);
        if (interactionClassName) {
            className += " " + interactionClassName;
        }
        self.setLayerClassName(featureLayer, className);
    }
    getFeatureClassName(feature: any): string {
        return feature.properties.edgeType === this.edgeType.value ? 'ptv-verify-edge' : 'ptv-edge';
    }

    getInteractionStyle(featureModel: GeoJsonFeatureModel): string {
        let className: string = '';
        if (featureModel.highlighted) {
            className += " ptv-highlighted"
        }

        if (featureModel.selected) {
            className += " ptv-selected"
        }

        if (featureModel.focused) {
            className += " ptv-focused"
        }

        return className;
    }

    setLayerClassName(layer: any, className: string) {
        if (!layer) return;
        layer.setStyle({
            className: className
        });
        if (layer._path) {
            layer._path.setAttribute('class', className + " leaflet-interactive");
        }
    }

    panToFeature(featureModel: GeoJsonFeatureModel): void {
        let featureLayer = this.findFeatureLayerByModel(featureModel);
        if (!featureLayer) return;
        this.panToFeatureLayer(featureLayer);
    }

    panToFeatureLayer(featureLayer: any) {
        if (!this.map) return;
        if (!featureLayer) return;

        // get the bounds of the layer
        let bounds = null;
        if (featureLayer.getBounds) {
            bounds = featureLayer.getBounds();
        }

        if (!bounds) {
            // use the pixel bounds of the feature to determine lat/lng bounds
            if (featureLayer._pxBounds) {
                featureLayer._updateBounds();
                let pxBounds = featureLayer._pxBounds;
                let p1 = this.map.layerPointToLatLng(pxBounds.min);
                let p2 = this.map.layerPointToLatLng(pxBounds.max);
                bounds = this.L.latLngBounds(p1, p2);
            }
        }

        this.map.fitBounds(bounds);
    }

    selectAllMatchingElements() {
        let edgeProp = this.sceneService.wireframeApplication.wireframe.findProperty(EdgeTypeProperty, true);                
        let matching = this.sceneService.wireframeApplication.findElementsByPropertyValue(edgeProp, this.edgeType.value);
        this.sceneService.wireframeApplication.selectWireframeElement(null);
        this.sceneService.wireframeApplication.selectWireframeElement(matching, true, true);
    }


}