import {ProjectImageListFilterType} from "./projectImageCollectionFilterType.enum";
import {ProjectImageCollectionFilterModel} from "./projectImageCollectionFilter.model";

export class ProjectImageCollectionFilterAnnotationModel extends ProjectImageCollectionFilterModel {

    private _annotationClass:string;

    constructor() {
        super(ProjectImageListFilterType.Annotation);
        this.filterFunction = this.filterAnnotation.bind(this);
    }

    filterAnnotation(projectImageModel){
        if(!projectImageModel) return false;
        return  projectImageModel.classesUsedInAnnotation
            ? projectImageModel.classesUsedInAnnotation.indexOf(this.annotationClass) >= 0
            : false;
    }

    get annotationClass(): string {
        return this._annotationClass;
    }

    set annotationClass(value){
        this._annotationClass = value;
        this.triggerFilterChanged();
    }

    get filterName(): string {
        return "annotated with " + this.annotationClass;
    }
}