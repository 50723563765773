import WireframeLayer from "./wireframeLayer";
import {WireframeApplication} from "./wireframeApplication";
import {CameraRegistration, Resource} from "../resourceManager";
import {CameraView} from "../models/cameras/cameraView";
import {WireframeElementType} from "./wireframeElementType";

export class CameraLayer extends WireframeLayer {

    reconstructionResourceId:number;
    imageResources:Resource[] = [];

    constructor(app:WireframeApplication, name:string, label:string) {
        super(app, name, label);
        this.isEditable = false;
        this.elementTypes = [ WireframeElementType.camera ];
        this.planeDetectionEnabled = false;
        this.isExpandable = true
    }

    init() {
        super.init();
        this.elementLayers[WireframeElementType.camera].isVisible = true;
        this.elementLayers[WireframeElementType.camera].isLayerVisible = false;
        this.elementLayers[WireframeElementType.camera].labelLayer.dimensionLayer.isLayerVisible = false
    }

    redrawWireframe() {
        super.redrawWireframe();
    }

    addImageResource(imgResource:Resource) {
        if (!imgResource.metadata) {
            console.warn("Image Resource missing metadata", imgResource);
            return
        }
        let that = this;
        if (this.imageResources.find((r) => r == imgResource)) {

        } else {

            let reg = imgResource.registrations.find((reg) => reg.contextResourceId == that.reconstructionResourceId);
            if (!reg) {
                console.warn("Image Resource missing cameraRegistration", imgResource);
                return
            }
            this.imageResources.push(imgResource);
            let camReg = new CameraRegistration();
            camReg.fromJson(reg);
            let cv = new CameraView(camReg, imgResource);
            cv.id = reg.id;
            let camElement = that.addCamera(cv);
        }
    }
}