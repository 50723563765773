import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from "@angular/core";
import InteractiveListComponent from "../interactiveList/interactiveList.component";
import {VertexElementModel} from "../../models/vertexElements/vertexElement.model";
import {InteractiveFilterTypeModel} from "../../models/interactive/interactiveFilterType.model";
import {VertexFilterTypes} from "../../models/vertexFilters/vertexFilterTypes.enum";
import {ActiveDescendantKeyManager} from "@angular/cdk/a11y";
import VertexPreviewItemComponent from "../vertexPreviewItem/vertexPreviewItem.component";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {A, DOWN_ARROW, ENTER, S, UP_ARROW} from "@angular/cdk/keycodes";
import {MatSliderChange} from "@angular/material";
import {ResizedEvent} from 'angular-resize-event';

@Component({
    selector: 'ptv-vertex-preview-list',
    styles: [require('./vertexPreviewList.component.scss')],
    template: require('./vertexPreviewList.component.html')
})
export default class VertexPreviewListComponent extends InteractiveListComponent<VertexElementModel> implements OnInit, AfterViewInit, OnDestroy {
    @Input() zoom: number = 66;
    @Input() thumbnailType: string = 'tiles';

    // all supported types
    interactiveFilterTypes: Array<InteractiveFilterTypeModel> = new Array<InteractiveFilterTypeModel>();

    @ViewChild(CdkVirtualScrollViewport, {static: false}) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
    @ViewChildren(VertexPreviewItemComponent) vertexPreviewItemComponentQueryList: QueryList<VertexPreviewItemComponent>;

    private keyManager: ActiveDescendantKeyManager<VertexPreviewItemComponent>;
    private isDestroyed = false;

    constructor(
        private vertexListChangeDetectorRef: ChangeDetectorRef
    ) {
        super(vertexListChangeDetectorRef);
        this.interactiveFilterTypes.push(new InteractiveFilterTypeModel(VertexFilterTypes.VertexVerification, 'radio_button_checked'));
        this.interactiveFilterTypes.push(new InteractiveFilterTypeModel(VertexFilterTypes.VertexSeen, 'remove_red_eye'));
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.cdkVirtualScrollViewport.elementRef.nativeElement.focus();
        if (this.interactiveDataView.dataView.length > 0)
            this.interactiveDataView.dataView.at(0).focused = true;

        this.keyManager = new ActiveDescendantKeyManager(this.vertexPreviewItemComponentQueryList);
        this.keyManager.change.subscribe((activeIndex) => {
            // todo: suspect...
            setTimeout(() => {
                if (this.isDestroyed) return;
                // the key manager doesn't quite focus the preview item component, so assist it by scrolling into view
                let focusedVertexElementModel = this.getFocusedVertexElementModel();
                this.scrollToInteractiveModel(focusedVertexElementModel);
            }, 100);
        })
    }

    ngOnDestroy(): void {
        this.isDestroyed = true;
        super.ngOnDestroy();
    }

    getFocusedVertexElementModel(): VertexElementModel {
        let focusedVertexElementModels = this.interactiveDataView.dataView.filter((vertexElementModel) => {
            return vertexElementModel.focused;
        });
        if (!focusedVertexElementModels || focusedVertexElementModels.length == 0) return null;
        let focusedVertexElementModel = focusedVertexElementModels[0];
        return focusedVertexElementModel
    }

    getNextVertexElementModel(fromVertexElementModel: VertexElementModel): VertexElementModel {
        let nextVertexElementModel: VertexElementModel = null;
        let vertexIndex = this.interactiveDataView.dataView.indexOf(fromVertexElementModel);
        if (vertexIndex < this.interactiveDataView.dataView.size() - 1) {
            let nextVertexIndex = vertexIndex + 1;
            nextVertexElementModel = this.interactiveDataView.dataView.at(nextVertexIndex);
        }
        return nextVertexElementModel;
    }

    getPreviousVertexElementModel(fromVertexElementModel: VertexElementModel): VertexElementModel {
        let previousVertexElementModel: VertexElementModel = null;
        let vertexIndex = this.interactiveDataView.dataView.indexOf(fromVertexElementModel);
        if (vertexIndex > 0) {
            let previousVertexIndex = vertexIndex - 1;
            previousVertexElementModel = this.interactiveDataView.dataView.at(previousVertexIndex);
        }
        return previousVertexElementModel;
    }

    moveToVertexElementModel(vertexElementModel: VertexElementModel) {
        if (!vertexElementModel) return;
        vertexElementModel.focused = true;
        let vertexPreviewItemComponent = this.findVertexPreviewItemComponent(vertexElementModel);
        if (vertexPreviewItemComponent) {
            this.keyManager.setActiveItem(vertexPreviewItemComponent);
        }
    }

    findVertexPreviewItemComponent(vertexElementModel): VertexPreviewItemComponent {
        let vertexPreviewItemComponent: VertexPreviewItemComponent = null;
        this.vertexPreviewItemComponentQueryList.forEach((currentVertexPreviewItemComponent) => {
            if (currentVertexPreviewItemComponent.vertexElementModel == vertexElementModel) {
                vertexPreviewItemComponent = currentVertexPreviewItemComponent;
            }
        });
        return vertexPreviewItemComponent;
    }

    moveToNextVertexElementModel() {
        let focusedVertexElementModel = this.getFocusedVertexElementModel();
        if (!focusedVertexElementModel) return;
        let nextVertexElementModel = this.getNextVertexElementModel(focusedVertexElementModel);
        if (!nextVertexElementModel) return;
        this.moveToVertexElementModel(nextVertexElementModel);
    }

    moveToPreviousVertexElementModel() {
        let focusedVertexElementModel = this.getFocusedVertexElementModel();
        if (!focusedVertexElementModel) return;
        let previousVertexElementModel = this.getPreviousVertexElementModel(focusedVertexElementModel);
        if (!previousVertexElementModel) return;
        this.moveToVertexElementModel(previousVertexElementModel);
    }

    onKeydown(event) {
        let focusedVertexElementModel = this.getFocusedVertexElementModel();
        if (focusedVertexElementModel) {

            if (event.keyCode === ENTER || event.keyCode === A) {
                let vertexPreviewItemComponent = this.findVertexPreviewItemComponent(focusedVertexElementModel);
                if (vertexPreviewItemComponent) vertexPreviewItemComponent.verified = !vertexPreviewItemComponent.verified;
                this.moveToNextVertexElementModel();

            } else if (event.keyCode === S) {
                let vertexPreviewItemComponent = this.findVertexPreviewItemComponent(focusedVertexElementModel);
                if (vertexPreviewItemComponent) vertexPreviewItemComponent.unverified = !vertexPreviewItemComponent.unverified;
                this.moveToNextVertexElementModel();

            } else if (event.keyCode === UP_ARROW) {
                this.moveToPreviousVertexElementModel();

            } else if (event.keyCode === DOWN_ARROW) {
                this.moveToNextVertexElementModel();
            }
        } else {

            // pass through
            this.keyManager.onKeydown(event);

        }
    }

    trackBy(index, vertexElementModel) {
        if (!vertexElementModel) return index;
        if (!vertexElementModel.vertexElement) return index;
        return vertexElementModel.vertexElement.toString();
    }

    onZoomSliderChange(change: MatSliderChange) {
        this.zoom = change.value;
    }

    lastIndexes: number[] = [];

    onScrolledIndexChange(index: number) {
        let self = this;

        // todo: suspect...
        // debounce scroll index changes
        if (self.lastIndexes.indexOf(index) >= 0) {
            self.vertexListChangeDetectorRef.detach();
            setTimeout(() => {
                self.vertexListChangeDetectorRef.reattach();
                self.detectChanges();
            }, 300);
        }

        self.lastIndexes.unshift(index);
        if (self.lastIndexes.length > 2) {
            self.lastIndexes.pop();
        }

    }

    onResized(event: ResizedEvent) {
        if(!this.cdkVirtualScrollViewport) return;
        this.cdkVirtualScrollViewport.checkViewportSize();
    }
}