import {
    ChangeDetectorRef,
    Component, DoCheck,
    Input, IterableDiffers, ViewChild
} from '@angular/core';
import WireframeElementCollection from "../../collections/wireframeElements/wireframeElement.collection";
import {PropertyManager} from "../../models/property/propertyManager";
import {Property} from "../../models/property/property";
import * as PropertyLoaders from "../../models/property/propertyLoaders";
import {WireframeApplication} from "../../application/wireframeApplication";

@Component({
    selector: 'wireframe-selection-property-list',
    styles: [require('./wireframeSelectionPropertyList.component.scss')],
    template: require('./wireframeSelectionPropertyList.component.html')
})
export default class WireframeSelectionPropertyListComponent implements DoCheck {
    @ViewChild('addPropertySelect', {static: false}) private selectElement: HTMLSelectElement;
    @Input() selectedWireframeElements: WireframeElementCollection;
    propertyManager: PropertyManager;

    private selectedWireframeElementsDiffer;
    private _mergedProperties:Property[] = [];
    private _availableProperties:Property[] = [];

    constructor(
        private wireframeApplication: WireframeApplication,
        private iterableDiffers: IterableDiffers,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.propertyManager = new PropertyManager(wireframeApplication)
        this.selectedWireframeElementsDiffer = this.iterableDiffers.find([]).create(null);
    }

    ngDoCheck(): void {
        let changes = this.selectedWireframeElementsDiffer.diff(this.selectedWireframeElements);
        if(changes){
            this.detectChanges();
        }
    }

    get mergedProperties(): Property[] {
        if(!this.propertyManager) return this._mergedProperties;
        let selectedWireframeElementArray = [];
        if (this.selectedWireframeElements) {
            this.selectedWireframeElements.forEach((selectedWireframeElement) => {
                selectedWireframeElementArray.push(selectedWireframeElement);
            })
        }
        this.propertyManager.aggregateProperties(selectedWireframeElementArray);
        this._mergedProperties.length = 0;
        this._mergedProperties.push(...this.propertyManager.properties);
        return this._mergedProperties;
    }

    get availableProperties(): Property[] {
        if (!this.propertyManager) return this._availableProperties;
        let availableProperties: Property[] =  this.propertyManager.getAvailableProperties(this.selectedWireframeElements);
        this._availableProperties.length = 0;
        this._availableProperties.push(...availableProperties);
        return this._availableProperties;
    }

    onAddPropertyInputType(propertyIdx) {

        let property: Property = this.propertyManager.getAvailableProperties(this.selectedWireframeElements)[Number(propertyIdx)]


        if (!property.id) {
            let propClone = Object.create(property)
            Object.assign(propClone, property)
            property = propClone
            //let PropertyLoader = PropertyLoaders[propertyLoaderType];
            //if (!PropertyLoader) return;
            //property = new PropertyLoader();
        }


        this.propertyManager.addProperty(property, this.selectedWireframeElements);
        (this.selectElement as any).nativeElement.value = "";
    }

    detectChanges(): void {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }
}