import {InteractiveModel} from "./interactive.model";

export class InteractiveSelectEventModel<T extends InteractiveModel> {
    interactiveModel: T = null;
    append: boolean = false;
    toggle: boolean = false;

    constructor(interactiveModel: T, append: boolean = false, toggle: boolean = false) {
        this.interactiveModel = interactiveModel;
        this.append = append;
        this.toggle = toggle;
    }
}