import {ProjectImageCollectionSortModel} from "./projectImageCollectionSort.model";
import {ProjectImageModel} from "../projectImages/projectImage.model";
import {CameraPointView} from "../cameras/cameraPointView";

export class ProjectImageCollectionSortByCameraPointViewsModel extends ProjectImageCollectionSortModel {

    private _cameraPointViews:CameraPointView[];

    constructor(cameraPointViews:CameraPointView[] = []) {
        super();
        this.cameraPointViews = cameraPointViews;
        this.sortFunction = this.sortByCameraPointViews.bind(this);
    }

    sortByCameraPointViews(a:ProjectImageModel, b:ProjectImageModel){
        let frameIdA = a.frameId;
        let frameIdB = b.frameId;

        // find a
        let indexOfFrameIdA = this.cameraPointViews.findIndex((cameraPointView) => {
            if(!cameraPointView) return false;
            let cameraView = cameraPointView.cameraView;
            if(!cameraView) return false;
            let frameId = cameraView.frameId;
            if(typeof frameId === 'undefined' || frameId === null) return false;
            return frameIdA == frameId;
        });

        // find b
        let indexOfFrameIdB = this.cameraPointViews.findIndex((cameraPointView) => {
            if(!cameraPointView) return false;
            let cameraView = cameraPointView.cameraView;
            if(!cameraView) return false;
            let frameId = cameraView.frameId;
            if(typeof frameId === 'undefined' || frameId === null) return false;
            return frameIdB == frameId;
        });

        if (indexOfFrameIdA < indexOfFrameIdB)
            return -1;
        if (indexOfFrameIdA > indexOfFrameIdB)
            return 1;
        return 0;
    }

    get cameraPointViews(): CameraPointView[] {
        return this._cameraPointViews;
    }

    set cameraPointViews(cameraPointViews:CameraPointView[]){
        this._cameraPointViews = cameraPointViews;

        // todo: extend sort options
        // sort
        this.sortCameraPointViewArray(this._cameraPointViews, p => p.distance, "ASC");

        // notify
        this.triggerSortUpdated();
    }

    private sortCameraPointViewArray<T>(cameraPointViewArray:CameraPointView[], prop: (c: CameraPointView) => T, order: "ASC" | "DESC"): void {
        cameraPointViewArray.sort((a, b) => {
            if (prop(a) < prop(b))
                return -1;
            if (prop(a) > prop(b))
                return 1;
            return 0;
        });

        if (order === "DESC") {
            cameraPointViewArray.reverse();
        }
    }
}