import {PV} from "../wireframe";
import * as PVProperty from "../models/property/propertyLoaders";
import {PolygonShapePropertyValue} from "../models/property/polygonShapePropertyValue";
import {PlaneElement} from "./planeElement";

export class ContinuePolygon {
    bestFitPlane:THREE.Plane
    plane: PV.Plane;
    planeElement: PlaneElement
    propVal: PolygonShapePropertyValue;
    prop: PVProperty.PolygonShapeProperty;
    startVert: THREE.Vector3;
}