import * as TWEEN from '@tweenjs/tween.js'
import {PropertyCriterion} from '../models/property/propertyLoaders'
import {DisplayRule} from '../models/displayRules/displayRule'
import {ResourceManager} from "../resourceManager";
import {Property} from "../models/property/property";
import {PV} from "../wireframe";
import {PropertyManager} from "../models/property/propertyManager";
import {WireframeUtils} from "../wireframeUtils";
import {CameraView} from "../models/cameras/cameraView";
import {WireframeElementType} from "./wireframeElementType";
import {WireframeElement} from "./wireframeElement";
import {UndoActionType} from "./undoActionType";
import {UndoEntry} from "./undoEntry";
import {UndoAction} from "./undoAction";
import {PlaneDetectionMode} from "./planeDetectionMode";
import {SidebarMode} from "./sidebarMode";
import {SnapMode} from "./snapMode";
import {ValidationResult} from "./validationResult";
import {ElementPickOperation} from "./elementPickOperation";
import {MeasurementUnit} from "./measurementUnit";
import {LogLevel} from "./logLevel";
import {PropertyDisplayConfig} from "../models/propertyDisplay/propertyDisplayConfig";
import {LabelProperty} from "../models/property/labelProperty";
import {LayerProperty} from "../models/property/layerProperty";
import {VerifiedState} from "../models/property/verifiedState";
import {VerifiedProperty} from "../models/property/verifiedProperty";
import {EdgeType} from "../models/property/edgeType";
import {EdgeTypeProperty} from "../models/property/edgeTypeProperty";
import {UserActivity} from "../models/userActivity";
import {Tool} from "./tool";
import {GeometryTool} from "./geometryTool";
import {VertexElement} from "./vertexElement";
import {PointCloudOctree} from '@pix4d/three-potree-loader';
import {SceneViewer} from "../sceneViewer";
import * as THREE from 'three';
import WidgetTemplateLibrary from "./widgetTemplateLibrary";
import {WidgetElement} from "./widgetElement";
import {SceneService} from "../services/scenes/scene.service";
import SceneManager from "./sceneManager";
import WireframeLayer from "./wireframeLayer";
import ProjectionUtils from "../projectionUtils";
import CesiumViewComponent from "../components/cesium/cesiumView.component";
import {CameraElement} from "./cameraElement";
import {PointVisibility} from "../pointVisibility";
import {CanvasOperations} from "../canvasOperations";
import jQuery from "jquery";
import WireframeValidator from "./wireframeValidator";
import WireframeLoader from "./wireframeLoader";
import PVUtils from "../pvUtils";
import {PlaneOperations} from "../planeOperations";
import {AdjustSection} from "../components/adjust/adjustSection";
import {InspectTool} from "./inspectTool";
import RectangleTool from "./rectangleTool";
import PolygonTool from "./polygonTool";
import WidgetTool from "./widgetTool";
import EraserTool from "./eraserTool";
import MeasureTool from "./measureTool";
import {CameraLayer} from "./cameraLayer";
import {CameraProjectionService} from "./cameraProjectionService";
import ProjectImageCollection from "../collections/projectImages/projectImage.collection";
import {Broadcaster} from "../Broadcaster";
import {PlaneElement} from "./planeElement";
import {EdgeElement} from "./edgeElement";

export class WireframeApplication {
    debug = false
    useAppFramework = false
    public eventEmitter:Broadcaster = new Broadcaster()
    viewerMode:string = 'full'
    projectId:number
    currentUser:any

    _pickMessage:string

    cameraProjectionService = new CameraProjectionService(this)

    //pointVisibility:PointVisibility = new PointVisibility(this)

    layerPanelVisible = false

    planeOperations:PlaneOperations = new PlaneOperations(this)
    apiService:any;
    cesiumComponent:CesiumViewComponent;
    viewer:SceneViewer;
    sceneService:SceneService

    private projectGroundplane:THREE.Plane

    tools:Map<String, Tool> = new Map()

    activeTool:Tool = null;
    sceneManager:SceneManager = new SceneManager(this)

    widgetLibraries:WidgetTemplateLibrary[] = []


    appHasFocus:boolean = true;

    planeDetectionMode: PlaneDetectionMode = PlaneDetectionMode.ON;
    numRejectedPlanes: number = 0;
    validationResults: ValidationResult[] = [];
    arePointCloudsLoaded: boolean = false;
    //gravityAlignedGeocentricTransform: THREE.Matrix4 = new THREE.Matrix4().identity();
    resourceManager: ResourceManager = new ResourceManager(this);
    photoLabels = [];
    photos = [];
    unitTypes = [];
    selectedUnit: MeasurementUnit;
    displayMeasurementScale: number = 1.0;
    currentDisplayUnit: MeasurementUnit = MeasurementUnit.M;
    projectMeasurementUnit: MeasurementUnit;
    readOnlyMode: boolean = false;
    propertyManager: PropertyManager = new PropertyManager(this);

    isSceneLoaded: boolean = false;
    isRedrawing: boolean = false;


    vertexSize: number = .1;
    edgeSize: number = .06;
    lineVertexSizeRatio = .3;
    edgeLabelSize: number = .5;

    uiLayers = [];
    addPointModeHistory = [];


    cameraDict: Map<number, CameraView> = new Map();

    focusedElement: WireframeElement = null;
    highlightedElements:WireframeElement[] = [];
    lockedElements: WireframeElement[] = [];
    copyBuffer: WireframeElement[] = [];
    selectedElements: WireframeElement[] = [];

    private _timeOfLastSave: Number = 0;


    viewMode: string;
    sidebarMode: SidebarMode[] = [];
    sidebarModeChanging = false;

    blockGeometryUpdate: boolean = false;
    undoEnabled: boolean;
    currentUndoEntry: UndoEntry;
    undoStack: UndoEntry[] = [];
    hasChanges: boolean;
    
    propertyDisplayConfig: PropertyDisplayConfig = new PropertyDisplayConfig();
    currentPickOperation: ElementPickOperation = null;
    projectRef = null;
    projectData:any = null;
    userActivityRef = null;
    userActivity:any = null

    currentResource = null;
    sessionStart = null;
    snapMode: SnapMode = SnapMode.POINT_CLOUD;

    // scale for measurement
    dimensionViewPrecision: number = 2;

    //a flag to show or hide vertex on wireframe
    showVertex: boolean = true;

    //toggle degrees vs slope (x/12)
    degreeMode: boolean = true;

    //a flag that will be true if use adjusted a vertex
    vertexPositionAdjusted: boolean = false;

    addPointModeIndex: number = 0;

    //manual plane using to obtain 3d point from 2d point that placed on this plane (it seems it is used for line draging which is disabled for now)
    plane: number[] = [0.6406426948631128, 0.07749296330488105, -0.7745677415480787, -0.7254306877159621];

    get pickMessage():string {
        if (this.currentPickOperation) return this.currentPickOperation.message
        return this._pickMessage
    }

    set pickMessage(msg:string) {
        this._pickMessage = msg
    }

    get wireframe():PV.Wireframe {
        return this.sceneManager.getActiveWireframeLayer().wireframe
    }

    get wireframeLayer():WireframeLayer {
        return this.sceneManager.getActiveWireframeLayer()
    }

    get cameraLayer():CameraLayer {
        return this.sceneManager.rootLayers.find((l) => l instanceof CameraLayer) as CameraLayer
    }

    get wireFrameElements():WireframeElement[] {
        let elements = []
        this.sceneManager.getInteractiveLayers().forEach((l) => {
            if (l instanceof WireframeLayer && l.isVisible && l.isInteractionEnabled) elements.push(...l.wireframeElements)
        })
        return elements
    }

    get rootScene():THREE.Scene {
        return this.sceneManager.rootScene
    }

    set timeOfLastSave(time: Number) {
        this._timeOfLastSave = time;
        this.hasChanges = false;
    }

    get timeOfLastSave(): Number {
        return this._timeOfLastSave;
    }

    constructor() {
        console.log("wireframeApplication", "THREE.js r" + THREE.REVISION);
        this.setUnitTypes();

    }

    eraserRadiusDefault: number = 15;
    eraserRadiusLocalStorageKey: string = 'eraserRadius';
    get eraserRadius(): number {
        let eraserRadius = this.eraserRadiusDefault;
        let eraserRadiusStringValue = window.localStorage.getItem(this.eraserRadiusLocalStorageKey);
        if(eraserRadiusStringValue){
            eraserRadius = parseInt(eraserRadiusStringValue);
        }
        return eraserRadius;
    }

    set eraserRadius(radius:number){
        window.localStorage.setItem(this.eraserRadiusLocalStorageKey, radius + "");
    }

    setActiveTool(tool:Tool) {
        if (this.activeTool) this.activeTool.onToolDectivated()
        this.activeTool = tool
        this.activeTool.onToolActivated()
        this.broadcast("activeToolChanged", this.activeTool)
    }

    init() {
        this.initTools()
        this.viewer = new SceneViewer(this)
        this.viewer.initialize(document.getElementById("viewPort"))
        this.projectGroundplane = this.getProjectGroundPlane()
        // needed for cesium integration
        this.viewer.renderer.domElement.style.position = "absolute"

        this.sessionStart = new Date().getTime();
        let that = this;

        this.sceneManager.initLayers();
        this.sceneManager.setGeodeticCenter(this.sceneService.getProjectGeodetic())
        this.rootScene.updateMatrixWorld(true)
        this.broadcast("userActive", new UserActivity())

        $(window).blur(function() {
            that.appHasFocus = false
        });
        $(window).focus(function() {
            that.appHasFocus = true
        });

        // use the cesium zone as the angular zone
        let angularZone = this.cesiumComponent.zone;

        // run animation outside of angular, so it doesn't cause digests

        angularZone.runOutsideAngular(()=>{
            function animate(time) {
                requestAnimationFrame(animate);
                (TWEEN as any).default.update(time);
                that.viewer.loop(time)
                that.cesiumComponent.updateCesium()
            }
            requestAnimationFrame(animate);
        });
    }

    initTools() {
        this.tools["GeometryTool"] = new GeometryTool(this);
        this.tools["InspectTool"] = new InspectTool(this);
        this.tools["RectangleTool"] = new RectangleTool(this);
        this.tools["PolygonTool"] = new PolygonTool(this);
        this.tools["WidgetTool"] = new WidgetTool(this);
        this.tools["EraserTool"] = new EraserTool(this);
        this.tools["MeasureTool"] = new MeasureTool(this);

        if (this.viewerMode == "measure") {
            this.setActiveTool(this.tools["MeasureTool"])
        } else {
            this.setActiveTool(this.tools["GeometryTool"])
        }
    }

    getTool(toolName:string):Tool {
        return this.tools[toolName]
    }

    lockLargestPlane() {
        console.log("lock largest Plane");
        let foundPlane: boolean = false;
        let firstVert: PV.Vertex;
        let firstEl:WireframeElement = this.selectedElements.find((el: WireframeElement) => {
            return el.pvObject.pvType == WireframeElementType.vertex || el.pvObject.pvType == WireframeElementType.edge;
        });
        if (firstEl) {
            if (firstEl.pvObject.pvType == WireframeElementType.vertex) {
                firstVert = firstEl.pvObject as PV.Vertex;
            } else {
                firstVert = this.wireframe.vertices[(firstEl.pvObject as PV.Edge).vertex1Id];
            }
        }
        if (firstVert) {
            let plane: PV.Plane = this.wireframeLayer.getLargestPlane(firstVert);
            if (plane) {
                this.lockWireframeElement(this.wireframeLayer.findElement(plane));
                foundPlane = true;
            }
        }
        if (!foundPlane) this.lockWireframeElement(null);
    }

    setSidebarMode(s: SidebarMode) {
        this.sidebarModeChanging = true;
        if (!this.sidebarMode.includes(s)) {
            this.sidebarMode.push(s);
        } else {
            this.sidebarMode.splice(this.sidebarMode.indexOf(s), 1);
        }

        this.sidebarModeChanging = false;

        // this is needed to force the webgl renderer to resize when side panels are hidden/unhidden
        setTimeout(() => {
            if (this.viewer) this.viewer.resize()
        }, 50)
    }

    /*
    setAdjustVertex(vert: WireframeElement) {
        let that = this
        if (true || this.sidebarMode.includes(SidebarMode.TOOLS)) {
            let windowElement = window as any;
            setTimeout(function() {
                that.adjustSection.adjustWireFrameVertex.apply(that.adjustSection)
            }, 0);
        }
        this.broadcast("adjustVertexChanged");
    }

     */

    startUndoEntry() {
        if (this.undoStack.length > 0 && this.undoStack[0].actions.length < 1) {
            // remove empty entry
            this.undoStack.pop()
        }
        this.currentUndoEntry = new UndoEntry();
        this.undoStack.push(this.currentUndoEntry);
    }

    appendUndoAction(action: UndoAction) {
        if (!this.currentUndoEntry) this.startUndoEntry();
        this.currentUndoEntry.actions.push(action);
        this.hasChanges = true;
    }

    undoLastEntry() {
        if (this.undoStack.length < 1) return;
        let entry: UndoEntry = this.undoStack.pop();
        if (this.undoStack.length > 0) {
            this.currentUndoEntry = this.undoStack[this.undoStack.length - 1];
        } else {
            this.currentUndoEntry = null;
        }
        for (let i = entry.actions.length - 1; i >= 0; i--) {
            this.undoAction(entry.actions[i]);
        }
        this.wireframeLayer.redrawWireframe();
        this.wireframeTopologyChanged();
        this.hasChanges = true;
    }

    undoAction(action: UndoAction) {
        console.log("undoAction " + UndoActionType[action.actionType] + " " + action.element.name, action);
        let tmpUndoEnabled = this.undoEnabled;
        this.undoEnabled = false;
        let layer = action.element.wireframeLayer
        switch (action.actionType) {
            case UndoActionType.CREATE :
                layer.deleteWireframeElement(action.element, false);
                break;
            case UndoActionType.DELETE:
                if (!this.wireframe.isValid(action.element.pvObject)) {
                    console.log("not undeleting invalid component", action.element.pvObject)
                    break
                }
                let unDeletedElement:WireframeElement
                action.state.pvObject.id = action.element.pvObject.id
                switch (action.element.pvObject.pvType) {
                    case WireframeElementType.vertex:
                        this.wireframe.vertices[action.element.pvObject.id] = action.state.pvObject as PV.Vertex;
                        unDeletedElement = layer.addVertex(action.state.pvObject as PV.Vertex);
                        break;
                    case WireframeElementType.edge:
                        this.wireframe.edges[action.element.pvObject.id] = action.state.pvObject as PV.Edge;
                        unDeletedElement = layer.addEdge(action.state.pvObject as PV.Edge, null);
                        break;
                    case WireframeElementType.plane:
                        this.wireframe.planes[action.element.pvObject.id] = action.state.pvObject as PV.Plane;
                        unDeletedElement = layer.addPlane(action.state.pvObject as PV.Plane);
                        break;
                    case WireframeElementType.widget:
                        this.wireframe.widgets[action.element.pvObject.id] = action.state.pvObject as PV.Widget;
                        unDeletedElement = layer.addWidget(action.state.pvObject as PV.Widget);
                        break;
                }
                unDeletedElement.isDeleted = false
                break;
            case UndoActionType.MODIFY:

                //let vert: PV.Vertex = app.wireframe.vertices[action.element.pvObject.id];
                //Object.assign(vert, action.state.pvObject);
                let el = this.findWireframeElement(action.element.pvObject.pvType, action.element.pvObject.id);
                if (el) {
                    el.applyState(action.state)
                    el.updateGeometry()
                    el.updateMaterials()
                    el.updateDependentGeometry()
                    el.updateSupportingGeometry()
                }

                break;
        }

        this.undoEnabled = tmpUndoEnabled;
    }

    findWireframeElement(type: WireframeElementType, id: Number):WireframeElement {
        let layers = this.sceneManager.getInteractiveLayers().filter((l) => l instanceof WireframeLayer) as WireframeLayer[]
        for (let i = 0; i < layers.length; i++) {
            let l = layers[i]
            let lookup = l.findWireframeElement(type, id)
            if (lookup) return lookup
        }
        return null
    }

    getLabelForElement(el: WireframeElement): String {
        let labelProp = this.wireframe.findProperty(LabelProperty, true);
        let labelValue = labelProp.getPropertyValue(el.pvObject);
        let elName = WireframeElementType[el.pvObject.pvType] + "-" + el.pvObject.id;
        if (null != labelValue && null != labelValue.value && labelValue.value.length > 0) {
            elName = labelValue.value + "(" + elName + ")";
        }
        return elName;
    }

    startElementPick(pickOp: ElementPickOperation) {
        let that = this;
        this.endElementPick();
        this.currentPickOperation = pickOp;
        this.wireFrameElements.forEach(function (el: WireframeElement) {
            el.isDisabled = !that.currentPickOperation.testPickable(el);
        });
        this.wireframeLayer.updateWireframeMaterials();
    }

    endElementPick() {
        if (this.currentPickOperation) {
            if (!this.currentPickOperation.isComplete) {
                this.currentPickOperation.complete();
            }
            this.currentPickOperation = null;
            this.wireFrameElements.forEach(function (el: WireframeElement) {
                el.isDisabled = false;
            });

            this.highlightWireframeElement(null);
            this.wireframeLayer.updateWireframeMaterials();
        }
    }



    setUnitTypes() {
        for (let type in MeasurementUnit) {
            if (isNaN(Number(type))) {
                this.unitTypes.push(type);
            }
        }
        this.selectedUnit = MeasurementUnit.M;
        this.currentDisplayUnit = MeasurementUnit.M;
    }

    onKeyDown(event:KeyboardEvent) {
        switch (event.code) {
            case "KeyZ":
                if (event.ctrlKey) this.undoLastEntry();
                break;
            case "KeyS":
                // TODO handle save without reference to controller
                if (event.shiftKey && event.ctrlKey) {
                    //this.mainController.openSaveModal();
                    this.broadcast("saveAsRequested")
                } else if (event.ctrlKey) {
                    //this.mainController.quickSave();
                    this.broadcast("saveRequested")
                }
                break;
        }

        event.preventDefault();
    }

    onKeyUp(event:KeyboardEvent) {
        switch (event.code) {
            case "Escape":
                this.endElementPick();
                break;
        }
        this.activeTool.updateHighlightedElements();
        event.preventDefault();
    }

    focusWireframeElement(element: WireframeElement) {

        // unfocus the previous element
        if (this.focusedElement) {
            this.focusedElement.isFocused = false;
            this.focusedElement.updateMaterials()
            this.focusedElement = null;
            this.broadcast('unfocusWireframeElement', this.focusedElement);
        }

        // focus the new element
        if (element && !element.isDisabled) {
            if (this.currentPickOperation && !this.currentPickOperation.testPickable(element)) {
                // don't focus unincluded types if we're in a pick operation
            } else {
                element.isFocused = true;
                this.focusedElement = element;
                element.updateMaterials()
                this.broadcast('focusWireframeElement', element);
            }
        }
    }

    highlightWireframeElement(element: WireframeElement) {
        this.highlightedElements.forEach(function (el: WireframeElement) {
            el.isHighlighted = false;
            el.updateMaterials()
        });
        this.highlightedElements = [];
        if (element && !element.isDisabled) {
            if (this.currentPickOperation && !this.currentPickOperation.testPickable(element)) {
                // dont highlight unincluded types if we're in a pick operation
            } else {
                element.isHighlighted = true;
                element.updateMaterials()
                this.highlightedElements.push(element);
                this.broadcast('highlightWireframeElement', element);
            }
        }
    }

    lockWireframeElement(element: WireframeElement, setLocked: boolean = true) {
        console.log("lockWireframeElement " + setLocked, element);
        if (null == element) {
            this.wireFrameElements.forEach(function (el: WireframeElement) {
                el.isLocked = false
            });
            this.lockedElements = [];
        } else {
            element.isLocked = setLocked;
            if (setLocked) {
                if (!this.lockedElements.includes(element)) {
                    this.lockedElements.push(element);
                }
            } else {
                this.lockedElements = this.lockedElements.filter(function (o) {
                    return o != element;
                });
            }
        }
        this.broadcast("lockWireframeElement", this.lockedElements)

        let lockedElement = this.lockedElements.find((el) => el instanceof PlaneElement || el instanceof EdgeElement)
        if (lockedElement) {
            this.snapMode = SnapMode.LOCKED_GEOMETRY
        } else {
            if (this.snapMode == SnapMode.LOCKED_GEOMETRY) this.snapMode = SnapMode.POINT_CLOUD;
        }

        this.wireframeLayer.updateWireframeMaterials();
    }

    lockedElementsChanged() {
        let el = this.getFirstLockedElement(null);
        if (el && (el.pvObject.pvType == WireframeElementType.plane || el.pvObject.pvType == WireframeElementType.edge)) {
            //this.triangulationMode = 1;
            this.snapMode = SnapMode.LOCKED_GEOMETRY;
        } else {
            // Reset triangulationMode if no plane is locked
            //if (this.triangulationMode == 1) this.triangulationMode = 2;
            if (this.snapMode == SnapMode.LOCKED_GEOMETRY) this.snapMode = SnapMode.POINT_CLOUD;
        }
    }

    propertiesChanged() {
        if (this.isSceneLoaded) this.broadcast("userActive", new UserActivity())
        this.propertyDisplayConfig.mapToProperties(Object.values(this.wireframe.properties));
        this.broadcast('propertiesChanged');
    }

    getFirstLockedElement(type: WireframeElementType) {
        if (this.lockedElements.length < 1) return null;
        if (null == type) {
            return this.lockedElements[0];
        }
        for (let i = 0; i < this.lockedElements.length; i++) {
            if (this.lockedElements[i].pvObject.pvType == type) return this.lockedElements[i];
        }
        return null;
    }

    findElementsByPropertyValue(prop: Property, val: any) {
        let matching: WireframeElement[] = this.wireFrameElements.filter(function (el: WireframeElement) {
            if (!el.pvObject || !el.pvObject.properties) return false;
            for (let pv of el.pvObject.properties) {
                if (pv.id == prop.id && pv.value == val) {
                    return true;
                }
            }
            return false;
        });
        return matching;
    }

    selectWireframeElement(elements: WireframeElement[], setSelected: boolean = false, doApply: boolean = true) {
        if (null != elements && !Array.isArray(elements)) elements = [elements];
        let that = this;
        if (this.currentPickOperation && null == elements) return;
        if (null == elements) {
            this.wireFrameElements.forEach(function (el: WireframeElement) {
                if (el.isSelected) {
                    el.isSelected = false;
                    el.updateMaterials()
                }
            });
            this.selectedElements = [];
        } else {
            elements.forEach(function (el: WireframeElement) {
                if (!el.isPickable) return;
                if (setSelected && that.currentPickOperation) {
                    if (that.currentPickOperation.testPickable(el)) {
                        that.currentPickOperation.elementPicked(el);
                        that.endElementPick();
                        return;
                    }
                    that.endElementPick();
                }
                let wasSelected = el.isSelected;
                el.isSelected = setSelected;
                if (wasSelected != setSelected) el.updateMaterials()
                if (setSelected) {
                    if (!that.selectedElements.includes(el)) {
                        that.selectedElements.push(el);
                    }
                } else {
                    that.selectedElements = that.selectedElements.filter(function (o) {
                        return o != el;
                    });
                }
            });
        }

        if (this.selectedElements.length == 1) {
            if (this.selectedElements[0] instanceof VertexElement) {
                let selectedElement = this.selectedElements[0] as any;
                let vertex = new PV.Vertex();
                vertex.x = selectedElement.x;
                vertex.y = selectedElement.y;
                vertex.z = selectedElement.z;
                (vertex as any).pvObject = selectedElement.pvObject;
            }
        }
        if (this.activeTool.enableTransformTool) {
            let transformTool = this.activeTool.getTransformTool()
            if (this.selectedElements.length > 0) {
                transformTool.attach(this.selectedElements[0])
            } else if (this.selectedElements.length == 0) {
                transformTool.detach()
            }

        }
        if (this.selectedElements.length > 0) console.log("selectedWireframeElements", this.selectedElements);
        let geomTool = this.getTool("GeometryTool") as GeometryTool

        if (geomTool.autoLockMode) {
            this.lockLargestPlane();
        }
        this.propertyManager.aggregateProperties(this.selectedElements);

        this.broadcast("selectWireframeElement", this.selectedElements)
    }

    wireframeChanged() {
        if (this.blockGeometryUpdate) return;
        //console.log("wireframeChanged");
        this.broadcast("userActive", new UserActivity(true))

        this.broadcast("wireframeChanged");
    }

    wireframeTopologyChanged() {
        if (this.blockGeometryUpdate) return;
        if (this.isSceneLoaded) this.broadcast("userActive", new UserActivity(true))
        this.broadcast("wireframeTopologyChanged");
    }



    setDefaultDisplayRules() {
        let edgeProp = this.wireframe.findProperty(EdgeTypeProperty, true);
        let layerProp = this.wireframe.findProperty(LayerProperty, true);
        this.propertyDisplayConfig.rules = [
            new DisplayRule(new PropertyCriterion(layerProp, "measurement"), [255, 255, 255, 0.6]),
            new DisplayRule(new PropertyCriterion(layerProp, "roi"), [255, 0, 255, 0.2]),
            new DisplayRule(new PropertyCriterion(edgeProp, EdgeType.RIDGE), [228, 72, 146, 1.0]),
            new DisplayRule(new PropertyCriterion(edgeProp, EdgeType.VALLEY), [100, 66, 126, 1.0]),
            new DisplayRule(new PropertyCriterion(edgeProp, EdgeType.EAVE), [230, 79, 39, 1.0]),
            new DisplayRule(new PropertyCriterion(edgeProp, EdgeType.RAKE), [135, 89, 61, 1.0]),
            new DisplayRule(new PropertyCriterion(edgeProp, EdgeType.HIP), [26, 117, 208, 1.0]),
            new DisplayRule(new PropertyCriterion(edgeProp, EdgeType.FLASHING), [119, 123, 129, 1.0]),
            new DisplayRule(new PropertyCriterion(edgeProp, EdgeType.STEP_FLASHING), [47, 74, 57, 1.0]),
            new DisplayRule(new PropertyCriterion(edgeProp, EdgeType.PARAPET), [209, 192, 0, 1.0]),
        ];

        let verifiedProp: VerifiedProperty = new VerifiedProperty();
        this.propertyDisplayConfig.rules.push(
            new DisplayRule(new PropertyCriterion(verifiedProp, VerifiedState.VERIFIED), [0, 255, 0, 0.7]),
        );
        this.propertyDisplayConfig.mapToProperties(Object.values(this.wireframe.properties))
    }


    getProjectGroundPlane(inWorldCRS:boolean = false):THREE.Plane {
        //if (!this.projectGroundplane) {
            let geodetic = this.sceneService.getProjectGeodetic()
            let geocent = ProjectionUtils.toGeocentric(geodetic)
            this.projectGroundplane = new THREE.Plane()
            this.projectGroundplane.setFromNormalAndCoplanarPoint(geocent.clone().normalize(), geocent)
        //}
        return inWorldCRS ? this.projectGroundplane.applyMatrix4(this.sceneManager.geocentricFrame.matrix) : this.projectGroundplane.clone()
    }

    getRepresentativeGroundPlane():THREE.Plane {
        if (!this.wireframe.transform || !this.wireframe.transform.representativeGroundPlane) return this.getGravityPlane()
        let p: THREE.Plane = new THREE.Plane();
        p.setComponents(this.wireframe.transform.representativeGroundPlane[0], this.wireframe.transform.representativeGroundPlane[1], this.wireframe.transform.representativeGroundPlane[2], this.wireframe.transform.representativeGroundPlane[3]);
        return p;
    }

    getGravityPlane():THREE.Plane {
        let p: THREE.Plane = new THREE.Plane();
        if (!this.wireframe.transform || !this.wireframe.transform.gravityPlane) return p
        p.setComponents(this.wireframe.transform.gravityPlane[0], this.wireframe.transform.gravityPlane[1], this.wireframe.transform.gravityPlane[2], this.wireframe.transform.gravityPlane[3]);
        return p;
    }

    getPointcloudBoundingBox():THREE.Box3 {
        let bbox = new THREE.Box3()
        if (this.wireframeLayer.wireframe.orthoTransform) {
            bbox.min.set(this.wireframe.orthoTransform.minX, this.wireframe.orthoTransform.minY, this.wireframe.orthoTransform.minZ)
            bbox.max.set(this.wireframe.orthoTransform.maxX, this.wireframe.orthoTransform.maxY, this.wireframe.orthoTransform.maxZ)
        }
        return bbox;
    }


    //retun true if layer is active
    isLayerActive(layerName) {
        for (let i = 0; i < this.uiLayers.length; i++) {
            let layer = this.uiLayers[i];
            if (layer.name == layerName)
                return layer.active;
        }
        return true;
    }

    loadWireframeFromFile(file: File) {
        //let file = e.target.files[0];
        console.log("loading from file " + file);
        let that = this
        if (!file) {
            that.log("Unable to load file " + file, LogLevel.DANGER);
            return;
        }
        let reader = new FileReader();

        let oldTransform: PV.Transform = new PV.Transform();
        let oldOrthoTransform: PV.OrthographicTransform = new PV.OrthographicTransform();
        let oldLayers: PV.LayerMap = JSON.parse(JSON.stringify(that.wireframe.layers));
        oldTransform = JSON.parse(JSON.stringify(that.wireframe.transform));
        oldOrthoTransform = JSON.parse(JSON.stringify(that.wireframe.orthoTransform));

        reader.onload = function (e) {
            let contents = (e.target as any).result;
            let wf = JSON.parse(contents);
            that.setWireframeFromJson(wf);
            that.wireframe.layers = oldLayers;
            that.wireframe.transform = oldTransform;
            that.wireframe.orthoTransform = oldOrthoTransform;
            let modifiedwf: PV.Wireframe = JSON.parse(JSON.stringify(that.wireframe.toJson()));
            that.setWireframeFromJson(modifiedwf);
        };
        reader.readAsText(file);
    }


    broadcast(event: string, ...args: any[]) {
        this.eventEmitter.broadcast(event, ...args)
        //this.mainController.broadcast(event, ...args);
    }

    /*
    on(event: string, listener: (event, data) => void) {
        this.mainController.on(event, listener);
    }

    off(event: string, listener: (event, data) => void) {
        this.mainController.off(event, listener);
    }

    */

    render() {
        //if (this.activeTool.enableTransformTool) this.activeTool.getTransformTool().render()
    }

    log(message: string, level: LogLevel = LogLevel.SUCCESS, exception: any = null) {
        let levelString: string = LogLevel[level].toLowerCase();
        let settings = {
            className: levelString,
            content: message,
            timeOut: 10000,
            dismissOnClick: true
        };
        this.eventEmitter.broadcast("logMessage", settings)
        //this.mainController.ngToast.create(settings);
    }

    logException(msg) {
        let notify = localStorage.getItem("notifyOnErrors") === "true";
        if (notify) {
            this.log("Unhandled Error has occurred, check the console", LogLevel.DANGER);
        }
    }

    notifyWireframeLoaded() {
        let label = this.currentResource.branch + "/" + this.currentResource.id;
        if (this.wireframe.name) {
            label += " (" + this.wireframe.name + ")";
        }
        this.log(label + " loaded successfully");
    }

    initViewport(cameraOffset:THREE.Vector3 = null) {
        let wfCenter: THREE.Vector3 = new THREE.Vector3();
        if (null == cameraOffset) {
            cameraOffset = new THREE.Vector3(0, 30, -0.01).normalize()
            //let invMat = new THREE.Matrix4();
            //invMat = invMat.getInverse(app.gravityAlignedGeocentricTransform);
            //cameraOffset.applyMatrix4(invMat);
        }
        let sph:THREE.Sphere = new THREE.Sphere()
        let bbox:THREE.Box3 = new THREE.Box3()

        let nonCameras = this.wireframeLayer.object.children.filter((el) => { return !(el instanceof CameraElement) && !this.wireframeLayer.groundPlaneElements.includes(el as any) })
        if (nonCameras.length > 0) {
            //app.wireframeScene.updateMatrix()
            //app.wireframeScene.updateMatrixWorld(true)
            bbox = new THREE.Box3().setFromObject(nonCameras[0])
            nonCameras.forEach((el) => { bbox.expandByObject(el) })
            sph = bbox.getBoundingSphere(new THREE.Sphere())
            //sph = app.wireframeLayer.toWorldCRS(sph)
        } else if (this.sceneManager.pointCloudLayer.object.children.length > 0) {
            let firstCloud = this.sceneManager.pointCloudLayer.object.children[0].children[0] as PointCloudOctree
            this.sceneManager.pointCloudLayer.object.updateMatrix()
            this.sceneManager.pointCloudLayer.object.updateMatrixWorld(true)
            sph = firstCloud.boundingSphere.clone()
            sph.applyMatrix4(firstCloud.matrixWorld)
        } else {
            bbox.setFromObject(this.rootScene)
            sph = bbox.getBoundingSphere(sph)
        }

        //sph = new THREE.Sphere(new THREE.Vector3(), 30)
        let center = sph.center
        let offset = cameraOffset.clone()
        let camDistance = Math.min(((this.viewer.camera as any).far || 10) * .7, Math.max(sph.radius * 2, 10))
        offset.setLength(camDistance)
        offset.add(center)

        this.viewer.setCameraTargets(sph.center, offset)
    }

    validateWireframe() {
        try {
            this.validationResults = WireframeValidator.validateWireframe(this.wireframeLayer)
        } catch (e) {
            this.log("Error during validation", LogLevel.DANGER, e)
        }
    }

    saveWireframeToServer(props, callback) {
        let that = this
        if (!props) {
            props = {branch: that.currentResource.branch};
        }
        let url = that.apiService.apiUrl + 'projects/' + that.projectId + '/wireframe/saveWireframe?branch=' + props.branch;

        try {
            that.validateWireframe()
        }
        catch (e) {
            console.error(e);
        }

        let jsonObject = that.wireframeLayer.wireFrameToJson();
        if (props) Object.assign(jsonObject, props);

        let wireFrameJsonStr = JSON.stringify(jsonObject);
        let response = jQuery.ajax({
            type: "POST",
            url: url,
            contentType: 'application/json',
            headers: {"authorization": window.localStorage.getItem("authorization")},
            data: wireFrameJsonStr,
            async: true,
            processData: false,
            success: function (result, status, xhr) {

                that.timeOfLastSave = new Date().getTime();
                if (callback) {
                    callback.call(this, result, status, xhr);
                }
                //console.log("result", result);
                let name = result.data.branch + "/" + result.data.id + "(" + result.data.name + ")";
                if (that.validationResults.length < 1) {
                    that.log(name + " saved successfully", LogLevel.SUCCESS);
                } else {
                    that.log(name + " saved with " + that.validationResults.length + " validation error(s)", LogLevel.WARNING);
                }
            },
            error: function (error, status, xhr) {
                console.log(error);
                that.log("Wireframe save failed", LogLevel.DANGER);

            }
        }).responseText;
    }

    lookAtElements(elements: WireframeElement[], reposition: boolean = true, distance: number = null) {
        if (elements.length < 1) return;
        let that = this;
        let center: THREE.Vector3 = new THREE.Vector3();
        elements.forEach(function (el: WireframeElement) {
            center.add(el.getCenter(true));
            /*
            if (reposition) {
                let planeIds: number[] = [];
                if (el.pvObject.pvType == WireframeElementType.vertex) {
                    planeIds = that.wireframe.findPlanesForVertex(el.pvObject.id);
                } else if (el.pvObject.pvType == WireframeElementType.edge) {
                    planeIds = that.wireframe.findPlanesForEdge(el.pvObject.id);
                } else if (el.pvObject.pvType == WireframeElementType.plane) {
                    planeIds.push(el.pvObject.id);
                } else if (el.pvObject.pvType == WireframeElementType.camera) {

                }

                planeIds.forEach(function (planeId: number) {
                    let plane: THREE.Plane = that.wireframe.getPlane3(planeId);
                    if (plane.normal.dot(gravityPlane.normal) > 0) plane.normal.multiplyScalar(-1);
                    avgPlaneNormal.add(plane.normal);
                    numPlanes++;
                })
            }
            */

        });
        center.divideScalar(elements.length);
        let viewingPlane = this.wireframeLayer.getOptimiumViewingPlaneForElements(elements)
        let viewDirection = viewingPlane.normal.clone()
        let target = center.clone()
        let camPosition = this.viewer.camera.position.clone()
        if (reposition) {

            if (null != distance) {
                viewDirection.setLength(distance);
            } else {
                let currentDistance = this.viewer.cameraControls.target.distanceTo(this.viewer.camera.position);
                viewDirection.setLength(currentDistance);
            }
            center.add(viewDirection);
            camPosition.copy(center)
        }
        //app.wireframeLayer.toWorldCRS(target)
        this.viewer.setCameraTargets(target, camPosition)
    }

    setWireframeFromJson(wfJson: PV.Wireframe) {
        let that = this
        try {
            let tsJson: PV.Wireframe = <PV.Wireframe> wfJson;
            that.wireframe.fromJson(tsJson);
            that.wireframe.validate();
            that.propertyManager.rewriteProperties(that.wireframe)

            this.updateVersionHistory();

            that.displayMeasurementScale = WireframeUtils.getUnitConversionFactor(Number(that.wireframe.measurementUnit), Number(that.projectMeasurementUnit));
            that.currentDisplayUnit = that.projectMeasurementUnit;

            //that.mainController.selectedUnit = MeasurementUnit[that.currentDisplayUnit];
            //that.settingsScope.unitScaleFactor = that.displayMeasurementScale;

            if (!that.displayMeasurementScale) {
                that.displayMeasurementScale = 1;
            }

            //wfOps.setPointCloudsFromJson(tsJson);

            new WireframeLoader(this).jsonToWireFrame(tsJson, that.wireframeLayer);

            if (that.currentResource.branch == "autogen") {
                this.wireframeLayer.alignAllWithGravity();
            }

            //newImg.src = imagePath; // this must be done AFTER setting onload
            //app.createROIGeometry();
            //wfOps.updateTransforms()
            that.broadcast("userActive", new UserActivity(false))
            //app.wireframeTopologyChanged();

            that.wireframeLayer.redrawWireframe();
        } catch (e) {
            console.error("Error loading wireframe", e);
            that.logException("Error loading wireframe");
            throw e;
        }
    }


    updateVersionHistory() {
        if (this.currentResource.id) {
            if (this.wireframe.versionHistory.length < 1) {
                this.wireframe.versionHistory.push(this.currentResource.id);
            } else if (this.wireframe.versionHistory[this.wireframe.versionHistory.length - 1] != this.currentResource.id) {
                this.wireframe.versionHistory.push(this.currentResource.id);
            }
        }
    }

    downloadWireFrame() {
        let jsonObject = this.wireframeLayer.wireFrameToJson();
        let str = JSON.stringify(jsonObject, null, 4);
        PVUtils.download(str, this.projectId + "-wireframe.json", "text");
    }

    //add cameras to scene from json
    initCameras(viewDict) {
        this.cameraDict = new Map<number, CameraView>();
        if (!viewDict) return;
        let views = Object.values(viewDict);
        for (let i = 0; i < views.length; i++) {
            let view = views[i];
            let camera: CameraView = new CameraView(null, null);
            Object.assign(camera, view);
            if (!camera.skew) camera.skew = 0;
            this.cameraDict[camera.frameId] = camera;
        }
    }

}


