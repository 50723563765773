import {
    Component,
    EventEmitter,
    Input
} from '@angular/core';
import {ENTER} from "@angular/cdk/keycodes";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'ptv-login',
    styles: [require('./login.component.scss')],
    template: require('./login.component.html')
})
export class LoginComponent {
    @Input() errorMessage:string = null;
    username: string;
    password: string;
    loginRequested:EventEmitter<any> = new EventEmitter<any>();

    constructor(private titleService: Title) {
        let hash = window.location.hash;
        if (hash && hash.startsWith('#/facilities')) {
            this.titleService.setTitle('Facility Management');
        }
    }

    keyDown(event) {
        if(event.keyCode == ENTER) {
            this.login();
        }
    }

    login() : void {
        let loginInformation = {
            username: this.username,
            password: this.password
        };
        this.loginRequested.emit(loginInformation);
    }
}