import {
    Component, Input
} from '@angular/core';
import {ProjectImageCollectionFilterExpressionModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterExpression.model";
import {ProjectImageListFilterType} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterType.enum";
import {ProjectImageCollectionFilterModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilter.model";
import {ProjectImageCollectionFilterTagModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterTag.model";
import {ProjectImageCollectionFilterAnnotationModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterAnnotation.model";
import {ProjectImageCollectionFilterMetadataModel} from "../../models/projectImageCollectionFilter/projectImageCollectionFilterMetadata.model";

@Component({
    selector: 'project-image-list-filter-expression',
    styles: [require('./projectImageListFilterExpression.component.scss')],
    template: require('./projectImageListFilterExpression.component.html')
})
export default class ProjectImageListFilterExpressionComponent {
    @Input() allAnnotationClasses: Array<string> = [];
    @Input() allTags: Array<string>  = [];
    @Input() projectImageCollectionFilterExpressionModel: ProjectImageCollectionFilterExpressionModel = new ProjectImageCollectionFilterExpressionModel();
    ProjectImageListFilterType = ProjectImageListFilterType;
    constructor(
    ) {
    }

    get allFilterTypes():Array<string>{
        let types = Object.values(ProjectImageListFilterType);
        return types;
    }

    addFilter(projectImageListFilterModel:ProjectImageCollectionFilterModel){
        this.projectImageCollectionFilterExpressionModel.addFilter(projectImageListFilterModel);
    }

    removeFilter(projectImageListFilterModel:ProjectImageCollectionFilterModel){
        this.projectImageCollectionFilterExpressionModel.removeFilter(projectImageListFilterModel);
    }

    onAddFilterClicked(event, filterType:ProjectImageListFilterType){
        switch (filterType) {
            case ProjectImageListFilterType.Annotation:
                let projectImageListFilterAnnotationModel = new ProjectImageCollectionFilterAnnotationModel();
                projectImageListFilterAnnotationModel.annotationClass = this.allAnnotationClasses[0];
                this.addFilter(projectImageListFilterAnnotationModel);
                projectImageListFilterAnnotationModel.focused = true;
                break;
            case ProjectImageListFilterType.Metadata:
                let projectImageListFilterMetadataModel = new ProjectImageCollectionFilterMetadataModel();
                this.addFilter(projectImageListFilterMetadataModel);
                projectImageListFilterMetadataModel.focused = true;
                break;
            case ProjectImageListFilterType.Tag:
                let projectImageListFilterTagModel = new ProjectImageCollectionFilterTagModel();
                projectImageListFilterTagModel.tag = this.allTags[0];
                this.addFilter(projectImageListFilterTagModel);
                projectImageListFilterTagModel.focused = true;
                break;
        }
    }
}