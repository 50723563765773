import {
    AfterViewInit,
    Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild
} from '@angular/core';
import {ProjectImageCollectionFilterAnnotationModel} from "../../../models/projectImageCollectionFilter/projectImageCollectionFilterAnnotation.model";
import {ProjectImageCollectionFilterModel} from "../../../models/projectImageCollectionFilter/projectImageCollectionFilter.model";

@Component({
    selector: 'project-image-list-filter-editor-annotation',
    styles: [require('./projectImageListFilterEditorAnnotation.component.scss')],
    template: require('./projectImageListFilterEditorAnnotation.component.html')
})
export default class ProjectImageListFilterEditorAnnotationComponent implements AfterViewInit, OnDestroy {
    @ViewChild('projectImageListEditorAnnotationClassSelect', {static: false}) selectElement: ElementRef;
    @Input() projectImageListFilterModel: ProjectImageCollectionFilterAnnotationModel;
    @Input() allAnnotationClasses: Array<string> = [];
    @Output() filterUpdated = new EventEmitter<ProjectImageCollectionFilterModel>();

    constructor(
    ) {
    }

    ngAfterViewInit(): void {
        this.selectElement.nativeElement.focus();
    }

    ngOnDestroy(): void {
    }

    onAnnotationClassSelectBlur(event){
        this.triggerFilterUpdated();
    }

    onAnnotationClassSelected(annotationClass:string){
        this.projectImageListFilterModel.annotationClass = annotationClass;
        this.triggerFilterUpdated();
    }

    triggerFilterUpdated(){
        this.filterUpdated.emit(this.projectImageListFilterModel);
    }
}