import {PV} from "../../wireframe";
import {Property} from "../property/property";
import {DisplayRule} from "../displayRules/displayRule";
import {PropertyValue} from "../property/propertyValue";

export class PropertyDisplayConfig {
    rules: DisplayRule[] = [];

    findMatchingRules(c: PV.Component): DisplayRule[] {
        let matchingRules: DisplayRule[] = [];
        if (c && c.properties) {
            matchingRules = this.rules.filter(function (rule) {
                return c.properties.find(function (pv: PropertyValue) {
                    return rule.criterion.matchesPropertyValue(pv);
                })
            });
        }
        return matchingRules;
    }

    mapToProperties(props: Property[]) {
        this.rules.forEach(function (rule) {
            let matchingProp = props.find(function (prop: Property) {
                return prop.equals(rule.criterion.property);
            });
            if (matchingProp) {
                rule.criterion.property.id = matchingProp.id;
            } else {
                rule.criterion.property.id = null;
            }
        });
    }
}