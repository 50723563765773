import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UserModel} from '../../models/users/user.model'
import {Resource} from "../../models/resources/projectResourceManager";
import {ProjectModel} from "../../models/projects/project.model";

// deferred anti-pattern support
class Deferred<T> {
    promise: Promise<T>;
    resolve: any;
    reject: any;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiService {

    apiUrl = null;
    projectModel: ProjectModel
    projectData: any

    constructor(private http: HttpClient) {
        this.apiUrl = this.getApiUrl();
    }

    getProjectResources(): Resource[] {
        if (this.projectModel) {
            return this.projectModel.resources
        } else {
            return Object.values(this.projectData["resources"])
        }
    }

    protected getQueryVariable(variable) {
        let query = window.location.search.substring(1);
        let vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        return null;
    }

    getApiUrl() {
        let env = this.getQueryVariable("env");
        let url: String;
        if (env == "local") {
            url = "http://localhost:11443/v2/";
        } else if (env == "local2") {
            url = "http://192.168.74.69:8080/v2/";
        } else if (env == "qa") {
            url = "http://qaintranet.pointivo.com:8080/v2/";
        } else if (env == "prod") {
            url = "http://intranet.pointivo.com:8080/v2/";
        } else if (env == "k8s-master") {
            url = "http://api.pv-platform-master.pointivo.com/v2/";
        } else if (env == "k8s-qa") {
            url = "http://api.pv-platform-qa.pointivo.com/v2/";
        }  else if (env == "k8s-prod") {
            url = "http://api.pv-platform-prod.pointivo.com/v2/";
        } else {
            url = this.getBaseUrl() + '/v2/';
        }
        return url;
    }

    private getBaseUrl(): string {
        return window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "");
    }

    getAuthorizationToken(): string {
        let token = localStorage.getItem("authorization");
        return token ? token : "";
    }

    setAuthorizationToken(token: string) {
        localStorage.setItem("authorization", token);
    }

    login(username: string, password: string): Promise<UserModel> {
        let self = this;
        let deferred = new Deferred<UserModel>();
        let apiUrl = this.apiUrl + 'auth/login';
        this.http.post<UserModel>(
            apiUrl,
            {
                username: username,
                password: password
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .toPromise()
            .then(function (userModelData: any) {
                let userModel = new UserModel().deserialize(userModelData);
                let token = userModel.token;
                self.setAuthorizationToken(token);
                deferred.resolve(userModel);
            })
            .catch(function (data) {
                deferred.reject(data);
            });

        return deferred.promise;
    }

    getConfig() {
        let deferred = new Deferred();
        let apiUrl = this.apiUrl + 'config';
        this.http.get(
            apiUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.getAuthorizationToken()
                }
            })
            .toPromise()
            .then(function (data) {
                deferred.resolve(data);
            }).catch(function (data) {
            console.error("get config error", data);
        });

        return deferred.promise;
    }

    getCredentials(projectId) {
        let deferred = new Deferred();
        let apiUrl = this.apiUrl + 'projects/' + projectId + '/credentials?type=viewer';
        this.http.get(
            apiUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.getAuthorizationToken()
                }
            })
            .toPromise()
            .then(function (data) {
                deferred.resolve(data);
            })
            .catch(function (data) {
                console.error("get credentials error", data);
            });

        return deferred.promise;
    }

    getResources(projectId, resourceTypeId) {
        let deferred = new Deferred();

        let apiUrl = this.apiUrl + 'projects/' + projectId + "/resources";
        if (resourceTypeId)
            apiUrl += "?resourceTypeId=" + resourceTypeId;

        this.http.get(
            apiUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.getAuthorizationToken()
                }
            })
            .toPromise()
            .then(function (data) {
                deferred.resolve(data);
            })
            .catch(function (data) {
                console.error("get resource error", data);
            });

        return deferred.promise;
    }

    getWireframe(projectId, resourceId) {
        let self = this;
        let deferred = new Deferred();
        let apiUrl = this.apiUrl + 'projects/' + projectId + "/wireframe/" + resourceId;
        this.http.get(
            apiUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.getAuthorizationToken()
                }
            })
            .toPromise()
            .then(function (data) {
                deferred.resolve(data);
            })
            .catch(function (data) {
                self.log("Error loading wireframe resource " + resourceId + " : " + data.data.message);
                console.error("getWireframe error", data);
            });

        return deferred.promise;
    }

    getWireframeXls(projectId, wireframeData) {
        let self = this;
        let deferred = new Deferred();
        let apiUrl = this.apiUrl + 'projects/' + projectId + "/wireframexls";
        this.http.post(
            apiUrl,
            wireframeData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.getAuthorizationToken()
                },
                responseType: "blob"
            })
            .toPromise()
            .then(function (data) {
                deferred.resolve(data);
            })
            .catch(function (data) {
                self.log("Error loading wireframexls resource " + " : " + data.data.message);
                console.error("getWireframeXls error", data);
            });

        return deferred.promise;
    }

    getProject(projectId) {
        let self = this;
        let deferred = new Deferred();
        this.http.get(
            this.apiUrl + 'projects/' + projectId,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.getAuthorizationToken()
                }
            })
            .toPromise()
            .then(function (data) {
                deferred.resolve(data);
            })
            .catch(function (data) {
                self.log("Error loading project data");
                console.error("get project error", data);
            });

        return deferred.promise;
    }

    getViewerMetadata(id) {
        let self = this;
        let deferred = new Deferred();
        this.http.get(
            this.apiUrl + 'projects/' + id + '/viewerMetadata',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.getAuthorizationToken()
                }
            })
            .toPromise()
            .then(function (data) {
                deferred.resolve(data);
            })
            .catch(function (data) {
                self.log("Error loading viewer metadata");
                console.error("get viewerMetadata error", data);
            });

        return deferred.promise;
    }

    classifyEdges(projectId, wireframe) {
        let self = this;
        let deferred = new Deferred();
        this.http.post(
            this.apiUrl + 'projects/' + projectId + '/classifyEdges',
            wireframe,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.getAuthorizationToken()
                }
            })
            .toPromise()
            .then(function (data) {
                deferred.resolve(data);
            })
            .catch(function (data) {
                self.log("Edge Classification error");
                console.error("classifyEdges", data);
            });

        return deferred.promise;
    }

    applyMeasurements(projectId, wireframe) {
        let self = this;
        let deferred = new Deferred();
        this.http.post(
            this.apiUrl + 'projects/' + projectId + '/applyMeasurements',
            wireframe,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.getAuthorizationToken()
                }
            })
            .toPromise()
            .then(function (data) {
                deferred.resolve(data);
            })
            .catch(function (data) {
                self.log("Apply Measurements error");
                console.error("applyMeasurements", data);
            });

        return deferred.promise;
    }

    detectCutouts(projectId, wireframe) {
        let self = this;
        let deferred = new Deferred();
        this.http.post(
            this.apiUrl + 'projects/' + projectId + '/detectCutouts',
            wireframe,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.getAuthorizationToken()
                }
            })
            .toPromise()
            .then(function (data) {
                deferred.resolve(data);
            })
            .catch(function (data) {
                self.log("Cutout Detection Error");
                console.error("detectCutouts", data);
            });

        return deferred.promise;
    }

    finalize(projectId, wireframe) {
        let self = this;
        let deferred = new Deferred();
        this.http.post(
            this.apiUrl + 'projects/' + projectId + '/finalize',
            wireframe,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.getAuthorizationToken()
                }
            })
            .toPromise()
            .then(function (data) {
                deferred.resolve(data);
            })
            .catch(function (data) {
                self.log("Finalize Error");
                console.error("finalize", data);
            });

        return deferred.promise;
    }

    getUser(id) {
        let deferred = new Deferred();
        this.http.get(
            this.apiUrl + 'users/' + id,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.getAuthorizationToken()
                }
            })
            .toPromise()
            .then(function (data) {
                deferred.resolve(data);
            })
            .catch(function (data) {
                console.error("get user error", data);
                deferred.resolve(data);
            });

        return deferred.promise;
    }

    getCustomer(id) {
        let deferred = new Deferred();
        this.http.get(
            this.apiUrl + 'customers/' + id,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.getAuthorizationToken()
                }
            })
            .toPromise()
            .then(function (data) {
                deferred.resolve(data);
            })
            .catch(function (data) {
                console.error("get customer error", data);
                deferred.resolve(data);
            });

        return deferred.promise;
    }

    createFirebaseToken(projectId) {
        let deferred = new Deferred();
        this.http.get(
            this.apiUrl + 'projects/' + projectId + '/createFirebaseToken',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.getAuthorizationToken()
                }
            })
            .toPromise()
            .then(function (data) {
                deferred.resolve(data)
            });
        return deferred.promise;
    }

    log(message: string) {
        console.log(message);
    }

}