import TexturedQuad from "./texturedQuad";
import Location from "./models/locations/location"
import {PV} from "./wireframe";
import ComponentMap = PV.ComponentMap;
import * as THREE from 'three';
import Vector3 = THREE.Vector3;
import {WireframeApplication} from "./application/wireframeApplication";
import ProjectionUtils from "./projectionUtils";
import {WireframeOperations} from "./wireframeOperations";
import {WireframeUtils} from "./wireframeUtils";
import {Resource, S3Object} from "./resourceManager";
import WireframeLayer from "./application/wireframeLayer";


export default class OrthoMapQuad extends TexturedQuad {
    public location:Location = new Location()
    public zoomLevel:number = 20;
    private scale:number = 1;
    public pixelSize:number = 0.019;
    public orthoResource:Resource
    private texture:THREE.Texture;
    private isInit = false

    public init() {
        if (this.isInit) return
        this.material = new THREE.MeshBasicMaterial();
        this.material.depthTest = false;
        //this.material.depthWrite = false;
        this.material.transparent = true;
        this.material.opacity = 1;

        this.createGeometry();
        this.planeElement.children[0].renderOrder = -999;
        for (let el of this.elements) {
            el.isEditable = false;
        }
        this.setLayer("ortho-" + this.orthoResource.id)

        //let geocent = ProjectionUtils.toGeocentric(new THREE.Vector3(this.location.lat, this.location.lon, 0))
        //this.setPos(geocent, geocent.clone().normalize(), new THREE.Vector3(0, 1, 0), 50, 50)

        this.fetchImage();
        this.isInit = true
    }

    public setPositionFromLocation() {
        let width = this.pixelSize * this.texture.image.width / this.scale;
        let height = this.pixelSize * this.texture.image.height / this.scale;
        console.log("zoom/pixelSize/width/height " + this.zoomLevel + "/" + this.pixelSize + "/" + this.texture.image.width + "/" + this.texture.image.height)

        let geocent = ProjectionUtils.toGeocentric(new THREE.Vector3(this.location.lat, this.location.lon, 0))
        let orthoTransform = ProjectionUtils.getOrthotransformForGeocentric(geocent)

        let pos = new THREE.Vector3()
        let rot = new THREE.Quaternion()
        orthoTransform.decompose(pos, rot, new THREE.Vector3())
        this.setPos(geocent, rot.inverse(), width, height);
    }

    public fetchImage() {
        if (this.orthoResource && this.orthoResource.metadata && this.orthoResource.metadata["orthoParameters"]) {
            let that = this;
            let orthoXfer = this.app.resourceManager.getS3ObjectForResource(this.orthoResource);
            this.app.resourceManager.getImageData(orthoXfer, function(o) {
                let loader:THREE.TextureLoader = new THREE.TextureLoader();
                loader.load(o, (tx) => {
                    that.texture = tx;
                    that.material.map = that.texture;
                    that.material.needsUpdate = true;
                    that.planeElement.baseMaterial = that.material;
                    WireframeUtils.copyMaterial(that.planeElement.material, that.material);
                    //this.planeElement.material = this.material.clone();
                    try {
                        that.planeElement.updateMaterials()
                    } catch (e) {
                        console.log("error", e)
                    }
                    that.setPositionFromLocation();
                    that.layer.redrawWireframe()
                });
                //let texture:THREE.Texture = new THREE.Texture();
            }, null);
        }
    }
}
