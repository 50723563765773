import tpl from './properties.html'

export default {
    bindings: {},
    controller: [function () {
        let ctrl = this;

    }],
    template: tpl
}

