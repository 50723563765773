import * as THREE from 'three';
import {WireframeElementType} from "./wireframeElementType";
import {ElementMatProps} from "./elementMatProps";

export var DefaultMaterialProps: Map<WireframeElementType, ElementMatProps> = new Map<WireframeElementType, ElementMatProps>();
{
    var highlightColor = 0xffff00;
    var disabledColor = 0x555555;
    var selectColor = 0xff0000;
    var vertProps: ElementMatProps = new ElementMatProps();
    vertProps.baseMaterial = new THREE.MeshBasicMaterial({
        color: 0xff0000,
        transparent: true,
        opacity: .75,
        depthTest: false,
        depthWrite: false
    });
    vertProps.baseMaterial.color = new THREE.Color(0xff0000);
    vertProps.highlightMaterial = new THREE.MeshBasicMaterial({
        color: highlightColor,
        transparent: true,
        opacity: .5,
        depthTest: false
    });
    vertProps.selectMaterial = new THREE.MeshBasicMaterial({
        color: 0xff69b4,
        transparent: true,
        opacity: .75,
        depthTest: false,
        depthWrite: false,
        side: THREE.BackSide
    });
    vertProps.disabledMaterial = new THREE.MeshBasicMaterial({
        color: disabledColor,
        transparent: true,
        opacity: .75,
        depthTest: false
    });
    DefaultMaterialProps[WireframeElementType.vertex] = vertProps;

    var edgeProps: ElementMatProps = new ElementMatProps();
    edgeProps.baseMaterial = new THREE.MeshBasicMaterial({
        color: 0x00ff00,
        transparent: true,
        opacity: .75,
        depthTest: false
    });
    edgeProps.highlightMaterial = new THREE.MeshBasicMaterial({
        color: highlightColor,
        transparent: true,
        opacity: .75,
        depthTest: false
    });
    edgeProps.selectMaterial = new THREE.MeshBasicMaterial({
        color: selectColor,
        transparent: true,
        opacity: .75,
        depthTest: false,
        depthWrite: false,
        side: THREE.BackSide
    });
    edgeProps.lockMaterial = new THREE.MeshBasicMaterial({
        color: 0x0000ff,
        transparent: true,
        opacity: .75,
        depthTest: false
    });
    edgeProps.disabledMaterial = new THREE.MeshBasicMaterial({
        color: disabledColor,
        transparent: true,
        opacity: .75,
        depthTest: false
    });
    DefaultMaterialProps[WireframeElementType.edge] = edgeProps;

    var planeProps: ElementMatProps = new ElementMatProps();
    planeProps.baseMaterial = new THREE.MeshBasicMaterial({
        color: 0x00ff00,
        transparent: true,
        opacity: .1,
        depthTest: false
    });
    planeProps.holeMaterial = new THREE.MeshBasicMaterial({
        color: 0xff00ff,
        transparent: true,
        opacity: .3,
        depthTest: false
    });
    planeProps.highlightMaterial = new THREE.MeshBasicMaterial({
        color: highlightColor,
        transparent: true,
        opacity: .2,
        depthTest: false
    });
    planeProps.selectMaterial = new THREE.MeshBasicMaterial({
        color: selectColor,
        transparent: true,
        opacity: .2,
        depthTest: false
    });
    planeProps.lockMaterial = new THREE.MeshBasicMaterial({
        color: 0x0000ff,
        transparent: true,
        opacity: .3,
        depthTest: false
    });
    planeProps.disabledMaterial = new THREE.MeshBasicMaterial({
        color: disabledColor,
        transparent: true,
        opacity: .3,
        depthTest: false
    });

    DefaultMaterialProps[WireframeElementType.plane] = planeProps;

    var cameraProps: ElementMatProps = new ElementMatProps();
    cameraProps.baseMaterial = new THREE.MeshBasicMaterial({color: 0xff0000, wireframe: true});
    cameraProps.highlightMaterial = new THREE.MeshBasicMaterial({color: highlightColor, wireframe: true});
    cameraProps.selectMaterial = new THREE.MeshBasicMaterial({color: selectColor, wireframe: true});
    cameraProps.lockMaterial = new THREE.MeshBasicMaterial({color: 0x0000ff, wireframe: true});
    cameraProps.disabledMaterial = new THREE.MeshBasicMaterial({color: disabledColor, wireframe: true});

    DefaultMaterialProps[WireframeElementType.camera] = cameraProps;

    var widgetProps: ElementMatProps = new ElementMatProps();
    DefaultMaterialProps[WireframeElementType.widget] = widgetProps
    widgetProps.baseMaterial = new THREE.MeshLambertMaterial({
        color: 0x00ff00,
        transparent: true,
        opacity: .9,
        depthTest: false
    });
    widgetProps.highlightMaterial = new THREE.MeshLambertMaterial({
        color: highlightColor,
        transparent: true,
        opacity: .5,
        depthTest: false
    });
    widgetProps.selectMaterial = new THREE.MeshLambertMaterial({
        color: selectColor,
        transparent: true,
        opacity: .5,
        depthTest: false
    });
    widgetProps.lockMaterial = new THREE.MeshLambertMaterial({
        color: 0x0000ff,
        transparent: true,
        opacity: .9,
        depthTest: false
    });
    widgetProps.disabledMaterial = new THREE.MeshLambertMaterial({
        color: disabledColor,
        transparent: true,
        opacity: .25,
        depthTest: false
    });
}