import {InteractiveFilterModel} from "../interactive/interactiveFilter.model";
import {VertexElementModel} from "../vertexElements/vertexElement.model";
import {VertexFilterTypes} from "./vertexFilterTypes.enum";

export class VertexFilterVerifiedModel extends InteractiveFilterModel<VertexElementModel> {

    private _verifiedValue: boolean = false;

    constructor() {
        super(VertexFilterTypes.VertexVerification);
        this.filterFunction = this.filterVerified.bind(this);
    }

    filterVerified(vertexElementModel:VertexElementModel) {
        if (!vertexElementModel) return false;
        return vertexElementModel.verified === this.verifiedValue;
    }

    get verifiedValue(): boolean {
        return this._verifiedValue;
    }

    set verifiedValue(value:boolean) {
        this._verifiedValue = value;
        this.triggerFilterChanged();
    }

    get filterName(): string {
        return "verified is " + this.verifiedValue;
    }

    get filterLabel(): string {
        let verifiedLabel = this.verifiedValue ? "verified" : "unverified";;
        return "vertex is " + verifiedLabel;
    }

    get filterEditLabel(): string {
        return "vertex is ";
    }

    get filterSymbol():string {
        return "radio_button_checked";
    }

}