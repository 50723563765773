import {EnumProperty} from "./enumProperty";
import {WireframeElementType} from "../../application/wireframeElementType";
import {EdgeType} from "./edgeType";

export class EdgeTypeProperty extends EnumProperty {
    constructor() {
        super();
        this.isEditable = false;
        this.allowMultiplePerElement = false;
        this.allowMultiplePerWireframe = false;
        this.name = "Edge Type";
        this.type = ".EdgeTypeProperty";
        this.allowableElementTypes = [WireframeElementType.edge];
        this.setValuesFromEnum(EdgeType);
    }
}