import {InteractiveModel} from "../interactive/interactive.model";

export interface Customer {
    id: number;
    name: string;
    description: string;
    autoPublish: boolean;
    defaultSubscription: any;
    editMediaUriTemplateString: string;
    enableResourceBranching: boolean;
    maxSecondsBeforePublish: number;
    notifySlackEnabled: boolean;
    resourceTypes: any[];
    runMLByDefault: boolean;
}

export class CustomerModel extends InteractiveModel implements Customer {
    id: number;
    name: string;
    description: string;
    autoPublish: boolean;
    defaultSubscription: any;
    editMediaUriTemplateString: string;
    enableResourceBranching: boolean;
    maxSecondsBeforePublish: number;
    notifySlackEnabled: boolean;
    resourceTypes: any[];
    runMLByDefault: boolean;

    constructor() {
        super();
    }
}