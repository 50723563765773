import {Directive, ElementRef, Injector, Input} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({
    selector: 'wireframe-viewer-directive'
})
export class WireframeViewerDirective extends UpgradeComponent {
    @Input() wireframeViewerMode:string;
    constructor(elementRef: ElementRef, injector: Injector) {
        // upgrade the angularjs wireframe-viewer to angular 6
        super('wireframeViewer', elementRef, injector);
    }
}
