import moment = require('moment');

export default class PVUtils {

    public static formatSeconds(sec) {
        return moment().startOf('day').seconds(sec).format('HH:mm:ss');
    }

    public static formatUnixTime(time) {
        return moment.unix(time).format("YYYY-MM-DD HH:mm:ss")
    }

    public static formatBytes(bytes, decimals, useSi, unitStr) {
        if (bytes == null) return null;
        if (null == unitStr) unitStr = "B"; // bytes
        if (bytes == 0) return '0 ' + unitStr;
        let k = useSi ? 1000 : 1024;
        let dm = decimals + 1 || 3;
        let sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
        let i = Math.floor(Math.log(bytes) / Math.log(k));
        let val = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i] + unitStr;
        return val;
    }

    public static formatNumber(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    public static formatPercent(x, digits) {
        if (!x) return 0;
        if (null == digits) digits = 2;
        return Number.parseFloat(x * 100 + "").toFixed(digits);
    }

    public static arrayRotate(arr, count, reverse) {
        for (let i = 0; i < count; i++) {
            if (reverse)
                arr.unshift(arr.pop())
            else
                arr.push(arr.shift())

        }
        return arr
    }

    public static getQueryVariable(variable) {
        let query = window.location.search.substring(1);
        let vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        return null;
    }

    public static arrayIntersection(ar1: Number[], ar2: Number[]): Number[] {
        let intersect: Number[] = [];
        for (let i = 0; i < ar1.length; i++) {
            if (ar2.includes(ar1[i])) {
                intersect.push(ar1[i]);
            }
        }
        return intersect;
    }

    public static arrayContentsEquals(ar1, ar2) {
        if (!ar1 || !ar2)
            return false;
        if (ar1.length != ar2.length)
            return false;
        for (let i = 0, l = ar1.length; i < l; i++) {
            if (!ar2.includes(ar1[i])) {
                return false;
            }
        }
        return true;
    }

    public static deepCopy(obj) {
        let copy;

        // Handle the 3 simple types, and null or undefined
        if (null == obj || "object" != typeof obj) return obj;

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (let i = 0, len = obj.length; i < len; i++) {
                copy[i] = this.deepCopy(obj[i]);
            }
            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (let attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
            }
            return copy;
        }

        throw new Error("Unable to copy obj! Its type isn't supported.");
    }

    // Function to download data to a file
    public static download(data, filename, type) {
        let a = document.createElement("a"),
            file = new Blob([data], {
                type: type
            });
        if (window.navigator.msSaveOrOpenBlob) // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        else { // Others
            let url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }


    public static clearSelection() {
        let doc = document as any;
        if (doc.selection && doc.selection.empty) {
            doc.selection.empty();
        }
        else if (window.getSelection) {
            let sel = window.getSelection();
            sel.removeAllRanges();
        }
    }
}