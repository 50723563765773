import {WireframeElementType} from "./wireframeElementType";
import {PV} from "../wireframe";
import {DefaultMaterialProps} from "./defaultMaterialProps";
import {ElementMatProps} from "./elementMatProps";
import {DisplayRule} from "../models/displayRules/displayRule";
import {WireframeApplication} from "./wireframeApplication";
import {WireframeUtils} from "../wireframeUtils";
import * as THREE from 'three';
import TextSprite from "../TextSprite";
import WireframeLayer from "./wireframeLayer";
import {LabelProperty} from "../models/property/labelProperty";
import {VertexAnnotationProperty} from "../models/property/vertexAnnotationProperty";
import {PropertyValue} from "../models/property/propertyValue";
import {Layer} from "./layer";


export class WireframeElement extends THREE.Object3D {
    pvObject: PV.Component;
    elementType:WireframeElementType
    isFocused: boolean = false;
    isSelected: boolean = false;
    isHighlighted: boolean = false;
    visible: boolean = true;
    isLocked: boolean = false;
    isDisabled: boolean = false;
    isEditable: boolean = true;
    isPickable: boolean = true;
    isDeleted:boolean = false
    enableBoundingBox: boolean = true

    wireframeLayer:WireframeLayer

    protected isDefaultState = true
    //drawable: Drawable;
    name: string;
    material: THREE.MeshBasicMaterial = new THREE.MeshBasicMaterial();
    baseMaterial: THREE.MeshBasicMaterial = new THREE.MeshBasicMaterial();
    materialProps:ElementMatProps

    labelSprite:TextSprite
    baseGeometry:THREE.Mesh[] = []
    hitGeometry:THREE.Mesh[] = []
    highlightGeometry:THREE.Mesh[] = []
    boundingBox:THREE.BoxHelper
    //selectGeometry:THREE.Mesh[] = []

    baseGeometryFunc:() => THREE.Geometry
    hitGeometryFunc:() => THREE.Geometry
    highlightGeometryFunc:() => THREE.Geometry

    toString():string {
        return WireframeElementType[this.elementType] + "-" + this.pvObject.id
    }

    getHashCode() {
        return this.elementType + "-" + this.pvObject.id
    }

    get app():WireframeApplication {
        return this.wireframeLayer.app
    }

    getState() {
        return {
            pvObject: this.pvObject.getSerializableClone(),
            visible: this.visible,
            isHighlighted: this.isHighlighted,
            isSelected: this.isSelected,
            isFocused: this.isFocused,
            isLocked: this.isLocked,
            isDisabled: this.isDisabled,
            isEditable: this.isEditable,
            isPickable: this.isPickable,
            name: this.name,
            material: this.material.clone(),
            baseMaterial: this.baseMaterial.clone(),
            matrix: this.matrix.clone()
        }
    }

    applyState(state:any) {
        let mat = state.matrix as THREE.Matrix4
        this.position.setFromMatrixPosition(mat)
        this.scale.setFromMatrixScale(mat)
        this.rotation.setFromRotationMatrix(mat)
        Object.assign(this.pvObject, JSON.parse(JSON.stringify(state.pvObject)))
    }

    constructor(_pvObject:PV.Component) {
        super()
        this.pvObject = _pvObject
        this.elementType = this.pvObject.pvType
        this.name = WireframeElementType[this.pvObject.pvType] + "-" + this.pvObject.id
        this.renderOrder = 100
        //this.matrixAutoUpdate = false
        this.matrixWorldNeedsUpdate = false
        if (DefaultMaterialProps[this.pvObject.pvType]) {
            this.materialProps = DefaultMaterialProps[this.pvObject.pvType]
            this.baseMaterial = this.materialProps.baseMaterial.clone()
            this.material = this.baseMaterial.clone()
        }
    }

    createGeometry() {

    }

    updateGeometry() {
        let that = this
        //this.children.forEach((o) => { that.remove(o) })
        this.updateMatrix()
        this.updateMatrixWorld(true)
        //this.traverse((o) => o.matrixAutoUpdate = false)
        //this.matrixAutoUpdate = false
        //this.matrixWorldNeedsUpdate = true
        //this.updateMatrixWorld(true)
        if (this.labelSprite) this.labelSprite.scale.set(this.app.edgeLabelSize * this.wireframeLayer.linearWorldScale, this.app.edgeLabelSize * this.wireframeLayer.linearWorldScale, 1)
        if (this.enableBoundingBox) {
            if (!this.boundingBox) {
                this.boundingBox = new THREE.BoxHelper(this, new THREE.Color(0xffff00))
                this.boundingBox.name = this.name + "-bbox"
                this.wireframeLayer.boundingBoxLayer.object.add(this.boundingBox)
            }
            if (this.boundingBox) {
                this.boundingBox.update()
                //this.boundingBox.applyMatrix(this.matrixWorld)
                //app.boundingBoxLayer.object.updateMatrixWorld(true)
            }
        } else {
            if (this.boundingBox) {
                this.wireframeLayer.object.remove(this.boundingBox)
                this.wireframeLayer.disposeHierarchy(this.boundingBox)
                this.boundingBox = null
            }
        }
    }

    updateHitGeometry() {
        let that = this
        this.hitGeometry.forEach((o) => {
            o.updateMatrix()
            o.matrixWorld.multiplyMatrices(that.matrixWorld, o.matrix)
        })
    }

    createLabelSprite() {
        // labels
        this.labelSprite = new TextSprite();
        this.labelSprite.setBorderColor({r: 0, g: 0, b: 0, a: 0.8});
        this.labelSprite.setBackgroundColor({r: 0, g: 0, b: 0, a: 0.3});
        this.labelSprite.material.depthTest = false;
        this.labelSprite.material.transparent = true
        this.labelSprite.visible = true;
        this.labelSprite.parent = this;
        this.labelSprite.traverse((o) => o.renderOrder = 20)
        //this.add(this.labelSprite);
    }

    getDimensionStrings():string[] {
        return []
    }

    getLabelText():string[] {
        let labelText:string[] = []
        let labelLayer = this.wireframeLayer.elementLayers[this.elementType].labelLayer
        if (labelLayer.nameLayer.isVisible) {
            let l = this.label
            if (l) labelText.push(l)
        }
        if (labelLayer.dimensionLayer.isVisible) {
            labelText.push(...this.getDimensionStrings())
        }
        if (labelLayer.annotationLayer.isVisible) {
            let vertexAnnotationProperty = this.wireframeLayer.wireframe.findPropertyByName("Vertex Annotation") as VertexAnnotationProperty;
            let vertexAnnotationPropertyValue: PropertyValue = null;
            if (vertexAnnotationProperty) {
                vertexAnnotationPropertyValue = vertexAnnotationProperty.getPropertyValue(this.pvObject);
            }
            if (vertexAnnotationPropertyValue) {
                labelText.push(vertexAnnotationProperty.getLabelForValue(vertexAnnotationPropertyValue.value));
            }
        }
        return labelText
    }

    get label():string {
        let labelProp = this.wireframeLayer.wireframe.findProperty(LabelProperty,true);
        let text = null
        if (labelProp) {
            let labelValue = labelProp.getPropertyValue(this.pvObject);
            if (labelValue) text = labelValue.value
        }
        return text
    }

    set label(l:string) {
        this.wireframeLayer.wireframe.findProperty(LabelProperty,true).setValue(this, l)
    }

    protected storeBaseMaterials() {
        this.baseGeometry.forEach((b) => {
            b.traverse((o:any) => {
                if (o.material) {
                    Object.assign(o.userData, {
                        transient: {
                            baseMaterial: o.material
                        }
                    })
                }
            })
        })
    }

    protected restoreBaseMaterials() {
        let that = this
        this.baseGeometry.forEach((b) => {
            b.traverse((o: any) => {
                try {
                    let baseMat = o.userData['transient']['baseMaterial']
                    if (o.material && baseMat && o.material != baseMat) {
                        o.material = baseMat
                        o.material.needsUpdate = true
                        //WireframeUtils.copyMaterial(o.material, o.userData['transient']['originalMaterial'])
                    }
                } catch (e) {}
            })
        })
    }

    protected setDefaultAppearance() {
        let displayRuleColor = this.getDisplayRuleColor()
        if (displayRuleColor) {
            this.material.color.copy(displayRuleColor.color)
            this.material.opacity = displayRuleColor.alpha
        } else {
            this.material.color.copy(this.baseMaterial.color)
            this.material.opacity = this.baseMaterial.opacity
        }
        this.restoreBaseMaterials()
    }

    protected setMaterialRecursive(meshes:THREE.Object3D[], mat:THREE.Material) {
        let that = this
        meshes.forEach((bm) => {
            if (that.labelSprite == bm) return
            bm.traverse((m:any) => {
                if (m.material && m.material != mat) {
                    m.material = mat
                    m.material.needsUpdate = true
                }
            })
        })
    }

    protected setDisabledAppearance() {
        this.setMaterialRecursive(this.children, this.materialProps.disabledMaterial)
    }

    protected setHighlightedAppearance() {
        //this.add(...this.highlightGeometry)
        this.setMaterialRecursive(this.children, this.materialProps.highlightMaterial)
    }

    protected setSelectedAppearance() {
        if (this.highlightGeometry.length > 0) {
            //this.children = []
            this.add(...this.highlightGeometry)
            this.setMaterialRecursive(this.highlightGeometry, this.materialProps.selectMaterial)
        } else {
            this.setMaterialRecursive(this.children, this.materialProps.selectMaterial)
        }
    }

    protected setLockedAppearance() {
        this.setMaterialRecursive(this.children, this.materialProps.lockMaterial)
    }

    protected getDisplayRuleColor():{color:THREE.Color, alpha: number} {
        let displayRules: DisplayRule[] = this.app.propertyDisplayConfig.findMatchingRules(this.pvObject);
        if (displayRules.length > 0) {
            let color = new THREE.Color()
            let alpha = 1
            let rule = displayRules[0];
            //material = DefaultMaterialProps[el.pvType].baseMaterial.clone();
            color.r = rule.color[0] / 255
            color.g = rule.color[1] / 255
            color.b = rule.color[2] / 255
            alpha = rule.color[3]
            return { color: color, alpha: alpha}
        }
        return null
    }

    updateMaterials() {
        //if (this.hitGeometry && this.hideHitGeometry && this.hitGeometry instanceof THREE.Mesh) (this.hitGeometry.material as THREE.MeshBasicMaterial).visible = false
        let that = this

        let isVisible = this.wireframeLayer.elementLayers[this.elementType].isVisible
        if (isVisible) {
            this.wireframeLayer.object.add(this)
        } else {
            this.wireframeLayer.object.remove(this)
        }
        this.children = []
        this.add(...this.baseGeometry)
        this.isDefaultState = false
        this.setDefaultAppearance()

        if (this.isDisabled) {
            this.setDisabledAppearance()
        } else if (this.isSelected) {
            this.setSelectedAppearance()
        } else if (this.isHighlighted) {
            this.setHighlightedAppearance()
        } else if (this.isLocked) {
            this.setLockedAppearance()
        } else {
            this.isDefaultState = true
        }
        let labelVisible = this.wireframeLayer.elementLayers[this.elementType].labelLayer.isVisible
        if (this.labelSprite && labelVisible) {
            let labelText = this.getLabelText().join("\n")
            this.labelSprite.setText(labelText);
            if (!labelText || labelText.length < 1) {
                //this.labelSprite.visible = false
                this.remove(this.labelSprite)
            } else {
                this.add(this.labelSprite)
                this.labelSprite.updateMatrixWorld(true)
            }
        }
    }

    setPosition(worldPos:THREE.Vector3) {

    }

    getDependentGeometry():WireframeElement[] { return []}

    getSupportingGeometry():WireframeElement[] { return [] }

    updateDependentGeometry() {
        this.getDependentGeometry().forEach((el) => el.updateGeometry())
    }

    updateSupportingGeometry() {
        this.getSupportingGeometry().forEach((el) => el.updateGeometry())
    }

    getCenter(inWorldCRS:boolean = false):THREE.Vector3 {
        let pos = new THREE.Vector3(this.matrix.elements[12], this.matrix.elements[13], this.matrix.elements[14])
        return inWorldCRS ? this.wireframeLayer.toWorldCRS(pos) : pos
    }

    getPlane3(inWorldCRS = false):THREE.Plane {
        let plane = new THREE.Plane()
        let direction = new THREE.Vector3(0, 1, 0).applyQuaternion(this.quaternion)
        plane.setFromNormalAndCoplanarPoint(direction, this.position)
        if (inWorldCRS) plane = this.wireframeLayer.toWorldCRS(plane)
        return plane
    }

    aboutToBeRemoved() {
        if (this.boundingBox) {
            this.wireframeLayer.boundingBoxLayer.object.remove(this.boundingBox)
            this.wireframeLayer.disposeHierarchy(this.boundingBox)
            this.boundingBox = null
        }
    }

    getProjectionPlane(from:THREE.Vector3):THREE.Plane {
        let p = new THREE.Plane()
        p.setFromNormalAndCoplanarPoint(this.getCenter(true).sub(from).normalize(), this.getCenter(true))
        return p
    }

    getOptimumViewingPlane():THREE.Plane {
        return null
    }

    applyGeometryConstraints() {}
}