import {WireframeApplication} from "./wireframeApplication";
import {CameraProjector} from "./cameraProjector";
import {CameraElement} from "./cameraElement";
import * as THREE from 'three';
import {CameraPointView} from "../models/cameras/cameraPointView";
import {WireframeElement} from "./wireframeElement";
import {PlaneElement} from "./planeElement";
import {Broadcaster} from "../Broadcaster";

export class CameraProjectionService {

    public static readonly hotkey = "KeyC"

    public eventEmitter:Broadcaster = new Broadcaster()

    private _active:boolean = false
    private projectors:CameraProjector[] = []
    private _cameraPointViews:CameraPointView[]
    private _activeProjector:CameraProjector

    constructor(private app:WireframeApplication) {
    }

    addProjector(proj:CameraProjector) {
        this.projectors.push(proj)
    }

    set active(a:boolean) {
        let that = this
        if (this._activeProjector) this._activeProjector.enabled = a
        this._active = a
    }

    get active():boolean {
        return this._active
    }

    get activeProjector():CameraProjector {
        return this._activeProjector
    }

    activateProjectionForElement(el:WireframeElement, point:THREE.Vector3 = null) {
        if (!point) point = el.getCenter(true)
        this.activateProjectionForPointNormal(point, this.getProjectionNormalForElement(el))
    }

    activateProjectionForPointNormal(point:THREE.Vector3, normal:THREE.Vector3) {
        this._cameraPointViews = this.app.cameraProjectionService.findCamerasForPoint(point, normal)
        this._cameraPointViews.sort((a, b) => { return b.score - a.score })
        if (this._cameraPointViews.length > 0) {
            let v = this._cameraPointViews[0]
            //let pointBehind = point.clone().add(v.cameraElement.cameraDirectionWorld.clone().setLength(1))
            this.activateProjection(v.cameraElement.projection, v.normal, v.point)
        }
    }

    activateProjection(proj:CameraProjector, normal:THREE.Vector3, point:THREE.Vector3) {
        //if (this._activeProjector && this._activeProjector == proj) {
            // TODO handle changing normal & point
        //    return
        //}
        let differentProjector = proj == this._activeProjector
        if (this._activeProjector && proj != this._activeProjector) this._activeProjector.enabled = false
        console.log("activate Projection " + proj.cameraElement.pvObject.id)
        //if (this._activeProjector == proj && (normal && proj.targetNormal && normal.equals(proj.targetNormal))) return
        this._activeProjector = proj
        this._activeProjector.enabled = true
        this._activeProjector.setProjectionForPlane(normal, point)
        this._activeProjector.updateProjection()
        if (differentProjector) this.eventEmitter.broadcast("projectionChanged", this._activeProjector)
    }

    findCamerasForPoint(pointWorld:THREE.Vector3, normal:THREE.Vector3 = null):CameraPointView[] {
        let t = performance.now()
        let that = this
        let views:CameraPointView[] = []
        this.app.cameraLayer.wireframeElements.forEach((cam) => {
            if (!(cam instanceof CameraElement)) return
            let imgCoord = new THREE.Vector2()
            cam.getDistortedImageCoordForPoint3d(pointWorld, imgCoord, true)
            if (imgCoord.x >= 0 && imgCoord.x <= 1 && imgCoord.y >= 0 && imgCoord.y <= 1) {
                let cpv = new CameraPointView()
                views.push(cpv)
                cpv.point = pointWorld
                cpv.normal = normal
                cpv.cameraElement = cam
                cpv.cameraView = cam.pvObject
                cpv.imageCoordinates = imgCoord
                cpv.vectorToPoint = pointWorld.clone().sub(cam.cameraPositionWorld)
                cpv.distance = cpv.vectorToPoint.length()
                cpv.angle = 0
                if (normal) {
                    let n = normal.clone()
                    if (n.dot(cpv.vectorToPoint) < 0) n.negate()
                    cpv.angle = n.angleTo(cpv.vectorToPoint)
                }
                let xCenter = 2 * Math.min(imgCoord.x, 1 - imgCoord.x)
                let yCenter = 2 * Math.min(imgCoord.y, 1 - imgCoord.y)
                cpv.centerFactor = Math.min(xCenter, yCenter)
            }
        })
        //if (normal) views = views.filter((cpv) => cpv.angle < Math.PI / 3)
        this.testGeometryOcclusion(views)
        this.computeScores(views)
        if (this.app.debug) console.log("findCameras in " + (performance.now() - t).toFixed(3))
        return views
    }

    computeScores(views:CameraPointView[]) {
        views.forEach((v) => {
            v.score = 0
            let angleScore = Math.abs(v.angle) / (Math.PI / 3)
            v.score += 2 * (1 - angleScore)
            v.score += 1 * (1 / v.distance)
            v.score += 1 * (v.centerFactor)
            if (v.isGeometryOccluded) v.score -= 4
        })
    }

    testGeometryOcclusion(views:CameraPointView[]) {
        let planes = this.app.wireframeLayer.wireframeElements.filter((el) => el instanceof PlaneElement)
        let rc = new THREE.Raycaster()
        let that = this
        views.forEach((cpv) => {
            rc.ray.origin.copy(cpv.cameraElement.cameraPositionWorld)
            rc.ray.direction.copy(cpv.vectorToPoint.clone().normalize())
            rc.far = cpv.vectorToPoint.length() - 1
            let hits = this.app.wireframeLayer.raycastElements(rc, planes)
            cpv.isGeometryOccluded = hits.length > 0
            //if (cpv.isGeometryOccluded) console.log("cpv-" + cpv.cameraElement.pvObject.id + " occluded")
        })
    }

    cycleCameras(direction:boolean = true) {
        let proj = this._activeProjector
        if (!proj) return
        if (!this._cameraPointViews || this._cameraPointViews.length == 0) return

        let newIdx = 0
        let currentview = this._cameraPointViews.find((cpv) => cpv.cameraElement == proj.cameraElement)
        if (currentview) {
            let currentIdx = this._cameraPointViews.indexOf(currentview)
            newIdx = direction ? currentIdx + 1 : currentIdx - 1
            if (newIdx < 0) newIdx = 0
            if (newIdx > this._cameraPointViews.length - 1) newIdx = this._cameraPointViews.length - 1
        }
        let nextView = this._cameraPointViews[newIdx]
        if (nextView == currentview) return
        this.activateProjection(nextView.cameraElement.projection, nextView.normal, nextView.point)
    }

    get cameraPointViews():CameraPointView[] {
        if (!this._cameraPointViews) return []
        return this._cameraPointViews
    }

    getProjectionNormalForElement(el:WireframeElement):THREE.Vector3 {
        let lockedPlane = this.app.lockedElements.find((el) => el instanceof PlaneElement)
        let cameraPos = this.app.viewer.cameraPositionTarget.clone()
        let targetNormal = lockedPlane ? lockedPlane.getPlane3(true).normal : el.getProjectionPlane(cameraPos).normal
        return targetNormal
    }
}