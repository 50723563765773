import {Injectable} from '@angular/core';
import {WireframeApplication} from "../../application/wireframeApplication";
import {ProjectImageInventoryModel} from "../../models/projectImageInventories/projectImageInventory.model";
import {FrameService} from "../frames/frame.service";
import {ProjectImageModel} from "../../models/projectImages/projectImage.model";
import {SceneService} from "../scenes/scene.service";

@Injectable({
    providedIn: 'root',
})
export class ProjectImageCollectionService {

    constructor(
        private frameService: FrameService,
        private sceneService: SceneService,
        private wireframeApplication: WireframeApplication
    ) {
    }

    private get project(): any {
        if (!this.wireframeApplication) return null;
        if (!this.wireframeApplication.projectData) return null;
        return this.wireframeApplication.projectData;
    }

    private get projectRef(): any {
        if (!this.wireframeApplication) return null;
        if (!this.wireframeApplication.projectData) return null;
        return this.wireframeApplication.projectRef;
    }

    private get projectImageCollections(): any {
        if (!this.project) return null;
        if (!this.project.imageCollections) return null;
        return this.project.imageCollections;
    }

    private getProjectImageModelIndex(imageCollectionKey: string, projectImageModel: ProjectImageModel){
        let index = -1;
        if(!imageCollectionKey) return index;
        if(!projectImageModel) return index;
        let imageKey = projectImageModel.url;
        let imageKeys = this.getProjectImageKeys(imageCollectionKey);
        index = imageKeys.indexOf(imageKey);
        return index;
    }

    public getProjectImageCollectionKeys(): string[] {
        if (!this.projectImageCollections) return [];
        return Object.keys(this.projectImageCollections);
    }

    public getProjectImageInventoryModel(imageCollectionKey: string): any {
        if (!this.projectImageCollections) return null;
        let projectImageInventoryEntity = this.projectImageCollections[imageCollectionKey];
        let projectImageInventoryModel = new ProjectImageInventoryModel().deserialize(projectImageInventoryEntity);
        return projectImageInventoryModel;
    }

    public getProjectImageKeys(imageCollectionKey: string): string[] {
        let imageKeys: string[] = [];
        let projectImageInventoryModel = this.getProjectImageInventoryModel(imageCollectionKey);
        if (!projectImageInventoryModel) return imageKeys;
        if (!projectImageInventoryModel.images) return imageKeys;
        projectImageInventoryModel.images.forEach((currentImageModel) => {
            imageKeys.push(currentImageModel.url)
        });
        return imageKeys;
    }

    public getProjectImageModel(imageCollectionKey: string, imageKey: string): ProjectImageModel {

        // get collection
        let projectImageInventoryModel = this.getProjectImageInventoryModel(imageCollectionKey);

        // get image
        if (!projectImageInventoryModel) return null;
        if (!projectImageInventoryModel.images) return null;
        let imageModel = projectImageInventoryModel.images.find(currentImageModel => {
            return currentImageModel.url === imageKey;
        });
        if (!imageModel) return null;

        // get frame key
        imageModel = new ProjectImageModel().deserialize(imageModel);

        // associate camera
        imageModel.cameraView = this.sceneService.getProjectImageCameraView(imageModel);

        // associate  tiled image
        imageModel.tiledImageHref = this.wireframeApplication.resourceManager.getTiledImageHref(imageModel.frameId);
        imageModel.tiledImageMetadata = this.wireframeApplication.resourceManager.getTiledImageMetadata(imageModel.frameId);

        if(imageModel.hasCamera){
            imageModel.cameraFocusRequested.subscribe(this.onRequestCameraFocus.bind(this));
        }

        return imageModel;
    }

    private onRequestCameraFocus(projectImageModel:ProjectImageModel){
        if(!projectImageModel) return;
        this.sceneService.focusProjectImageCamera(projectImageModel);
    }

    public getProjectImageModelThumbnail(imageCollectionKey: string, imageKey: string, onProgress: any = null): Promise<ProjectImageModel> {
        let nullResponse = new Promise<ProjectImageModel>(resolve => {
            resolve(null);
        });
        let imageModel = this.getProjectImageModel(imageCollectionKey, imageKey);
        let frameKey = imageModel.frameKey;
        if (!frameKey) return nullResponse;

        return this.frameService.getFrameThumbnail(frameKey, onProgress).then((imageData) => {
            imageModel.thumbnailUrl = imageData;
            return imageModel;
        })
    }

    public getProjectImageModelPrint(imageCollectionKey: string, imageKey: string, onProgress: any = null): Promise<ProjectImageModel> {
        let nullResponse = new Promise<ProjectImageModel>(resolve => {
            resolve(null);
        });
        let imageModel = this.getProjectImageModel(imageCollectionKey, imageKey);
        let frameKey = imageModel.frameKey;
        if (!frameKey) return nullResponse;

        return this.frameService.getFramePrint(frameKey, onProgress).then((imageData) => {
            imageModel.printUrl = imageData;
            return imageModel;
        })
    }

    public getProjectImageModelMedia(imageCollectionKey: string, imageKey: string, onProgress: any = null): Promise<ProjectImageModel> {
        let nullResponse = new Promise<ProjectImageModel>(resolve => {
            resolve(null);
        });
        let imageModel = this.getProjectImageModel(imageCollectionKey, imageKey);
        let frameKey = imageModel.frameKey;
        if (!frameKey) return nullResponse;

        return this.frameService.getFrameData(frameKey, onProgress).then((imageData) => {
            imageModel.mediaUrl = imageData;
            return imageModel;
        })
    }

    public saveProjectImageModel(imageCollectionKey: string, projectImageModel: ProjectImageModel): void {

        // get the index of the project model in the collection
        let projectImageModelIndex = this.getProjectImageModelIndex(imageCollectionKey, projectImageModel);
        if(projectImageModelIndex < 0) return;

        // create an update
        let updates = {};
        
        // update the image
        let imagePath = '/imageCollections/' + imageCollectionKey + '/images/' + projectImageModelIndex + '/';
        
        // update the tags
        let tagsPath = imagePath + 'tags/';
        let updatedTagArray = [];
        updates[tagsPath] = updatedTagArray;
        projectImageModel.tags.forEach((tagValue) => {
            updatedTagArray.push(tagValue);
        });

        // update the annotations
        let annotationsPath = imagePath + 'annotations/';
        let updatedAnnotationArray = [];
        updates[annotationsPath] = updatedAnnotationArray;
        projectImageModel.annotations.forEach((annotationModel) => {
            updatedAnnotationArray.push({
                geometry: annotationModel.geometry,
                classes: annotationModel.classes ? annotationModel.classes : null,
                memo: annotationModel.memo ? annotationModel.memo : '',
                isOccluded: annotationModel.isOccluded ? true : false
            });
        });

        // update the project
        if (this.projectRef) {
            this.projectRef.update(updates, (error) => {
                if (error) {
                    console.log(error);
                } else {
                    console.log(JSON.stringify(updates));
                }
            });
        }

    }

}