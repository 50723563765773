import { Component, OnDestroy, OnInit } from "@angular/core";
import { SceneService } from "src/app/services/scenes/scene.service";
import { EdgeTypeProperty } from "src/app/models/property/propertyLoaders";
import { EdgeElement } from "src/app/application/edgeElement";
import { PropertyService } from "src/app/services/properties/property.service";
import { GeoJsonFeatureCollection } from "src/app/collections/geoJsonFeatures/geoJsonFeature.collection";
import { GeoJsonFeatureModel } from "src/app/models/geoJsonFeatures/geoJsonFeature.model";
import { WireframeElementType } from "src/app/application/wireframeElementType";
import { InteractiveSelectEventModel } from "src/app/models/interactive/interactiveSelectEvent.model";
import { WireframeElement } from "src/app/application/wireframeElement";

@Component({
    selector: 'ptv-edge-verification-manager',
    styles: [require('./edgeVerificationManager.component.scss')],
    template: require('./edgeVerificationManager.component.html')
})
export default class EdgeVerificationManagerComponent implements OnDestroy, OnInit {

    edgeCount: number = 0;
    edgeCollection: GeoJsonFeatureCollection;
    selectedWireframeEdgeElements: WireframeElement[] = [];


    sceneServiceWireframeChangedSubscription;
    wireframeElementDeletedSubscription;
    propertyServicePropertiesChangedSubscription;
    sceneServiceWireframeElementHighlightedSubscription;
    sceneServiceWireframeElementSelectedSubscription;
    sceneServiceWireframeElementFocusedSubscription;

    interactiveSelectionModel;
    edgeCollectionFocusedChangedSubscription;
    edgeCollectionSelectedChangedSubscription;
    edgeCollectionHighlightedChangedSubscription;

    constructor(private sceneService: SceneService, private propertyService: PropertyService) {
        this.sceneServiceWireframeChangedSubscription = this.sceneService.wireframeChanged.subscribe(this.onSceneServiceWireframeChanged.bind(this));
        this.wireframeElementDeletedSubscription = this.sceneService.wireframeApplication.eventEmitter.on('wireframeElementDeleted').subscribe(this.onWireframeElementDeleted.bind(this))

        this.propertyServicePropertiesChangedSubscription = this.propertyService.propertiesChanged.subscribe(this.onPropertyServicePropertiesChanged.bind(this));
        this.sceneServiceWireframeElementHighlightedSubscription = this.sceneService.wireframeElementHighlighted.subscribe(this.onSceneServiceWireframeElementHighlighted.bind(this));
        this.sceneServiceWireframeElementSelectedSubscription = this.sceneService.wireframeElementSelected.subscribe(this.onSceneServiceWireframeElementSelected.bind(this));
        this.sceneServiceWireframeElementFocusedSubscription = this.sceneService.wireframeElementFocused.subscribe(this.onSceneServiceWireframeElementFocused.bind(this));

        this.edgeCollection = new GeoJsonFeatureCollection()
        this.edgeCollectionFocusedChangedSubscription = this.edgeCollection.focusedChanged.subscribe(this.onEdgeCollectionFocusChanged.bind(this));
        this.edgeCollectionSelectedChangedSubscription = this.edgeCollection.selectedChanged.subscribe(this.onEdgeCollectionSelectChanged.bind(this));
        this.edgeCollectionHighlightedChangedSubscription = this.edgeCollection.highlightedChanged.subscribe(this.onEdgeCollectionHighlightChanged.bind(this));


        this.updateEdgeCollection();
    }

    ngOnInit(): void {
        let edgeEls = this.sceneService.wireframeApplication.selectedElements.filter((el) => (el instanceof EdgeElement));
        this.selectedWireframeEdgeElements = edgeEls;
    }

    ngOnDestroy(): void {
        if (this.sceneServiceWireframeChangedSubscription) this.sceneServiceWireframeChangedSubscription.unsubscribe();
        if (this.wireframeElementDeletedSubscription) this.wireframeElementDeletedSubscription.unsubscribe();
        if (this.propertyServicePropertiesChangedSubscription) this.propertyServicePropertiesChangedSubscription.unsubscribe();
        if (this.sceneServiceWireframeElementHighlightedSubscription) this.sceneServiceWireframeElementHighlightedSubscription.unsubscribe();
        if (this.sceneServiceWireframeElementSelectedSubscription) this.sceneServiceWireframeElementSelectedSubscription.unsubscribe();
        if (this.sceneServiceWireframeElementFocusedSubscription) this.sceneServiceWireframeElementFocusedSubscription.unsubscribe();

        if (this.edgeCollectionFocusedChangedSubscription) this.edgeCollectionFocusedChangedSubscription.unsubscribe();
        if (this.edgeCollectionSelectedChangedSubscription) this.edgeCollectionSelectedChangedSubscription.unsubscribe();
        if (this.edgeCollectionHighlightedChangedSubscription) this.edgeCollectionHighlightedChangedSubscription.unsubscribe();
    }

    onSceneServiceWireframeChanged() {
        this.updateEdgeCollection();
    }

    onWireframeElementDeleted() {
        this.updateEdgeCollection();
    }

    onPropertyServicePropertiesChanged() {
        this.updateEdgeCollection();
    }

    onSceneServiceWireframeElementHighlighted(wireframeElement: WireframeElement) {
        if (!wireframeElement) return;
        if (wireframeElement.pvObject.pvType !== WireframeElementType.edge) return;
        let edgeElement = wireframeElement as EdgeElement;
        if (!edgeElement) return;
        let edgeModel = this.findEdgeModel(edgeElement);
        if (!edgeModel) return;
        if (edgeModel.highlighted != wireframeElement.isHighlighted) edgeModel.highlighted = wireframeElement.isHighlighted;
    }

    onSceneServiceWireframeElementSelected(wireframeElementArray: Array<WireframeElement>) {
        if (!wireframeElementArray) return;
        this.selectedWireframeEdgeElements = wireframeElementArray.filter(e => e.pvObject.pvType === WireframeElementType.edge)
        let selectedIds = this.selectedWireframeEdgeElements.map(e => e.pvObject.id);

        this.edgeCollection.forEach(edge => {
            let index = selectedIds.indexOf(Number(edge.id));
            let selected = index !== -1;
            if (edge.selected !== selected)
                edge.setSelected(selected, false, true);
        });

    }

    onSceneServiceWireframeElementFocused(wireframeElement: WireframeElement) {
        if (!wireframeElement) return;
        if (wireframeElement.pvObject.pvType !== WireframeElementType.edge) return;
        let edgeElement = wireframeElement as EdgeElement;
        if (!edgeElement) return;
        let edgeElementModel = this.findEdgeModel(edgeElement);
        if (!edgeElementModel) return;
        if (edgeElementModel.focused != wireframeElement.isFocused) {
            edgeElementModel.focused = wireframeElement.isFocused;
        }
    }

    onEdgeCollectionFocusChanged(featureModel: GeoJsonFeatureModel) {
        if (!featureModel) return;
        if (!this.sceneService) return;

        let element = this.findWireframeElement(featureModel.properties.id);
        if (!element) return;

        if (featureModel.focused) {
            this.sceneService.setSceneSelectedWireframeElements([element]);
            this.sceneService.setSceneFocusedWireframeElement(element);
            this.sceneService.lookAtSceneWireframeElements([element])
        } else {
            this.sceneService.unselectAllSceneWireframeElements();
            this.sceneService.unfocusAllSceneWireframeElements();
        }
    }

    onEdgeCollectionSelectChanged(selectEventModel: InteractiveSelectEventModel<GeoJsonFeatureModel>) {
        if (!selectEventModel) return;
        let featureModel = selectEventModel.interactiveModel;
        if (!featureModel) return;
        if (!this.sceneService) return;

        let element = this.findWireframeElement(featureModel.properties.id);
        if (!element) return;
        
        if (featureModel.selected) {
            this.sceneService.setSceneSelectedWireframeElements([element]);
        } else {
            if (!selectEventModel.toggle)
                this.sceneService.unselectAllSceneWireframeElements();
            else {
                let selectedEdgeElements = this.sceneService.wireframeApplication.selectedElements.filter((el) => (el instanceof EdgeElement));
                selectedEdgeElements = selectedEdgeElements.filter(e => e.pvObject.id !== element.pvObject.id);
                this.sceneService.setSceneSelectedWireframeElements(selectedEdgeElements);
            }
        }
    }

    onEdgeCollectionHighlightChanged(featureModel: GeoJsonFeatureModel) {
        if (!featureModel) return;
        if (!this.sceneService) return;
        let element = this.findWireframeElement(featureModel.properties.id);
        if (!element) return;

        if (featureModel.highlighted) {
            this.sceneService.setSceneHighlightedWireframeElements(element);
        } else {
            this.sceneService.unHighlightAllSceneWireframeElements();
        }
    }


    updateEdgeCollection() {
        let wireframeModel = this.sceneService.wireframeApplication.wireframe;
        this.edgeCollection.clear();

        if (wireframeModel.edges) {
            let edgeTypeProperty = this.sceneService.wireframeApplication.wireframe.findProperty(EdgeTypeProperty, false);
            for (let edge of Object.values(wireframeModel.edges)) {

                let vertex1Id = edge.vertex1Id;
                let vertex2Id = edge.vertex2Id;
                let vertex1 = wireframeModel.vertices[vertex1Id];
                let vertex2 = wireframeModel.vertices[vertex2Id];
                if (!vertex1 || !vertex2) continue;
                let geometry = {
                    "type": "LineString",
                    "coordinates": [
                        [vertex1.x, vertex1.y, vertex1.z], [vertex2.x, vertex2.y, vertex2.z]
                    ]
                };
                let properties: any = {};
                properties.id = edge.id;
                properties.vertex1Id = edge.vertex1Id;
                properties.vertex2Id = edge.vertex2Id;

                let edgeElement = new EdgeElement(edge);
                if (edgeTypeProperty)
                    properties.edgeType = edgeTypeProperty.getValue(edgeElement.pvObject);

                let feature = new GeoJsonFeatureModel(geometry, properties);
                feature.id = edge.id;

                this.edgeCollection.add(feature);
            }
        }
        this.edgeCount = this.edgeCollection.length;

    }

    findWireframeElement(edgeId: number) {
        if (!this.sceneService || !this.sceneService.wireframeApplication) return;
        if (edgeId == null) return;
        let element = this.sceneService.wireframeApplication.findWireframeElement(WireframeElementType.edge, edgeId);
        return element;
    }

    findEdgeModel(edgeElement: EdgeElement) : GeoJsonFeatureModel {
        if (!this.edgeCollection) return;
        if (!edgeElement) return;

        let model: GeoJsonFeatureModel = null;
        this.edgeCollection.forEach(e => {
            if (e.properties.id === edgeElement.pvObject.id)
                model = e;
        });

        return model;
    }

}