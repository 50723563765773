import {Property} from "./property";
import {PropertyValue} from "./propertyValue";

export class PropertyCriterion {
    property: Property;
    value: any;

    matchesValue(v: any): boolean {
        return v == this.value;
    }

    matchesPropertyValue(pv: PropertyValue): boolean {
        return pv.id == this.property.id && this.matchesValue(pv.value);
    }

    constructor(p?: Property, v?: any) {
        this.property = p;
        this.value = v;
    }
}