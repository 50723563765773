import {
    AfterViewInit,
    Component
} from '@angular/core';
import {VertexFilterVerifiedModel} from "../../models/vertexFilters/vertexFilterVerified.model";
import InteractiveFilterTypeEditorComponent from "../interactiveFilterTypeEditor/interactiveFilterTypeEditor.component";
import {VertexElementModel} from "../../models/vertexElements/vertexElement.model";

@Component({
    selector: 'ptv-vertex-filter-verified-editor',
    styles: [require('./vertexFilterVerifiedEditor.component.scss')],
    template: require('./vertexFilterVerifiedEditor.component.html')
})
export default class VertexFilterVerifiedEditorComponent extends InteractiveFilterTypeEditorComponent<VertexElementModel> implements AfterViewInit {

    constructor() {
        super();
    }

    get vertexFilterVerifiedModel(): VertexFilterVerifiedModel{
        return this.interactiveFilterModel as VertexFilterVerifiedModel;
    }

    ngAfterViewInit(): void {
    }

    onSelectionBlur() {
        this.triggerFilterUpdated();
    }

    onSelectionChange(value){
        this.vertexFilterVerifiedModel.verifiedValue = value == "true" ? true : false;
        this.triggerFilterUpdated();
    }
}