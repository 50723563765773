import {
    AfterViewInit,
    Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild
} from '@angular/core';
import {ProjectImageCollectionFilterTagModel} from "../../../models/projectImageCollectionFilter/projectImageCollectionFilterTag.model";
import {ProjectImageCollectionFilterModel} from "../../../models/projectImageCollectionFilter/projectImageCollectionFilter.model";

@Component({
    selector: 'project-image-list-filter-editor-tag',
    styles: [require('./projectImageListFilterEditorTag.component.scss')],
    template: require('./projectImageListFilterEditorTag.component.html')
})
export default class ProjectImageListFilterEditorTagComponent implements AfterViewInit, OnDestroy {
    @ViewChild('projectImageListEditorTagSelect', {static: false}) selectElement: ElementRef;
    @Input() projectImageListFilterModel: ProjectImageCollectionFilterTagModel;
    @Input() allTags: Array<string> = [];
    @Output() filterUpdated = new EventEmitter<ProjectImageCollectionFilterModel>();

    constructor(
    ) {
    }

    ngAfterViewInit(): void {
        this.selectElement.nativeElement.focus();
    }

    ngOnDestroy(): void {
    }

    onTagSelectBlur(){
        this.triggerFilterUpdated();
    }

    onTagSelected(tag){
        this.projectImageListFilterModel.tag = tag;
        this.triggerFilterUpdated();
    }

    triggerFilterUpdated(){
        this.filterUpdated.emit(this.projectImageListFilterModel);
    }
}