import {Component,  NgZone, OnDestroy, } from '@angular/core';
import {WireframeApplication} from "../../application/wireframeApplication";
import {SceneService} from "../../services/scenes/scene.service";
import WidgetTool from "../../application/widgetTool";


@Component({
    selector: 'viewport-control',
    styles: [require('./widgetPanel.component.scss')],
    template: require('./widgetPanel.component.html')
})
export default class WidgetPanelComponent implements OnDestroy {
    private app:WireframeApplication
    constructor(private zone:NgZone, private sceneService:SceneService) {
        this.app = sceneService.wireframeApplication
    }

    getWidgetLibraries() {
        return this.app.widgetLibraries
    }
    dragStart(event) {
        //console.log("Drag start", event)
    }

    dragOver(event) {
        //console.log("Drag over", event)
        //event.preventDefault()
    }

    dragEnter(event) {
        //console.log("Drag enter", event)
    }

    dragEnd(event:DragEvent) {
        let dataset = (event.currentTarget as HTMLElement).dataset
        let widgetName = dataset.widgetname
        let libName = dataset.libraryname
        let lib = this.app.widgetLibraries.find((wl) => wl.name == libName)
        let widget = lib.templates.find((t) => t.name == widgetName )
        let tool = this.app.activeTool as WidgetTool
        tool.createWidget(widget)
    }

    drop(event) {
        //console.log("Drop", event)

    }

    ngOnDestroy(): void {
    }
}