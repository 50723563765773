import {
    AfterViewInit,
    Component, EventEmitter, Input, OnDestroy, Output
} from '@angular/core';
import {ProjectImageCollectionFilterModel} from "../../../models/projectImageCollectionFilter/projectImageCollectionFilter.model";
import {ProjectImageCollectionFilterCameraPointViewModel} from "../../../models/projectImageCollectionFilter/projectImageCollectionFilterCameraPointView.model";

@Component({
    selector: 'project-image-list-filter-editor-point-visibility',
    styles: [require('./projectImageListFilterEditorPointVisibility.component.scss')],
    template: require('./projectImageListFilterEditorPointVisibility.component.html')
})
export default class ProjectImageListFilterEditorPointVisibilityComponent implements AfterViewInit, OnDestroy {
    @Input() projectImageListFilterModel: ProjectImageCollectionFilterCameraPointViewModel;
    @Output() filterUpdated = new EventEmitter<ProjectImageCollectionFilterModel>();

    constructor() {
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
    }

    triggerFilterUpdated() {
        this.filterUpdated.emit(this.projectImageListFilterModel);
    }

    get filterName(): string {
        let name = '';
        if (!this.projectImageListFilterModel) return name;
        name = this.projectImageListFilterModel.filterName;
        return name;
    }
}