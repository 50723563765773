import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

export class PointivoFirebaseSingleton {
    public db = firebase.database();
    public auth = firebase.auth();

    static initialize(firebaseConfig) {
        firebase.initializeApp(firebaseConfig);
        let singleton = new PointivoFirebaseSingleton();
        if (firebaseConfig.useEmulator) {
            singleton.db.useEmulator("localhost", 9000);
            singleton.auth.useEmulator("http://localhost:9099");
        }
        PointivoFirebaseSingleton.setWindowInstance(singleton);
    }

    static isInitialized() {
        return !!PointivoFirebaseSingleton.getWindowInstance();
    }

    static getInstance(firebaseConfig?) {
        if (!PointivoFirebaseSingleton.isInitialized) {
            if (typeof firebaseConfig !== 'undefined') {
                console.error("Firebase is not initialized and no config was supplied.")
            }
            PointivoFirebaseSingleton.initialize(firebaseConfig);
        }

        return PointivoFirebaseSingleton.getWindowInstance();
    }

    private static getWindowInstance(): PointivoFirebaseSingleton {
        return ((window as any).pointivoFirebaseSingleton) as PointivoFirebaseSingleton;
    }

    private static setWindowInstance(singleton: PointivoFirebaseSingleton) {
        (window as any).pointivoFirebaseSingleton = singleton;
    }
}