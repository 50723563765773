import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, DoCheck, IterableDiffers, OnDestroy
} from '@angular/core';
import {SceneService} from "../../services/scenes/scene.service";
import WireframeElementCollection from "../../collections/wireframeElements/wireframeElement.collection";
import {WireframeElement} from "../../application/wireframeElement";
import {PropertyService} from "../../services/properties/property.service";

@Component({
    selector: 'scene-inspector',
    styles: [require('./sceneInspector.component.scss')],
    template: require('./sceneInspector.component.html')
})
export default class SceneInspectorComponent implements DoCheck, AfterViewInit, OnDestroy {
    // change detection
    private changeDetectorInterval: any;
    private viewInitialized: boolean = false;

    focusedWireframeElement: WireframeElement = null;
    selectedWireframeElements: WireframeElementCollection = new WireframeElementCollection();
    selectedWireframeElementsDiffer;

    constructor(
        private iterableDiffers: IterableDiffers,
        private sceneService: SceneService,
        private propertyService: PropertyService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.selectedWireframeElementsDiffer = this.iterableDiffers.find([]).create(null);
        this.sceneService.wireframeElementFocused.subscribe(this.onWireframeElementFocused.bind(this));
        this.sceneService.wireframeElementSelected.subscribe(this.onWireframeElementSelected.bind(this));
        this.propertyService.propertiesChanged.subscribe(this.onPropertiesChanged.bind(this));

        // manage change detection manually
        this.changeDetectorRef.detach();
    }

    ngAfterViewInit(): void {
        // this.changeDetectorInterval = setInterval(() => {
        //     this.detectChanges();
        // }, 250);

        let focusedWireframeElement = this.sceneService.getSceneFocusedWireframeElement();
        this.onWireframeElementFocused(focusedWireframeElement);

        let selectedWireframeElements = this.sceneService.getSceneSelectedWireframeElements();
        this.onWireframeElementSelected(selectedWireframeElements);

        // change detection
        this.viewInitialized = true;
    }

    ngDoCheck(): void {
        let changes = this.selectedWireframeElementsDiffer.diff(this.selectedWireframeElements);
        if(changes){
            this.detectChanges();
        }
    }

    ngOnDestroy(): void {
        if (this.changeDetectorInterval) clearInterval(this.changeDetectorInterval);
    }

    hasFocusedWireframeElement(){
        return this.focusedWireframeElement !== null;
    }

    hasSelectedWireframeElements(){
        return this.selectedWireframeElements.size() > 0;
    }

    onPropertiesChanged(){
        this.detectChanges();
    }

    onWireframeElementFocused(focusedWireframeElement){
        this.focusedWireframeElement = focusedWireframeElement;
        this.detectChanges();
    }

    onWireframeElementSelected(selectedWireframeElements:Array<WireframeElement>){
        this.selectedWireframeElements.clear();
        let primarySelection = null;
        if(selectedWireframeElements && selectedWireframeElements.length > 0){
            primarySelection = selectedWireframeElements[0];
            selectedWireframeElements.forEach((selectedWireframeElement) => {
                this.selectedWireframeElements.add(selectedWireframeElement);
            })
        }

        // todo: primary selection is different from focus
        this.onWireframeElementFocused(primarySelection);
    }

    detectChanges() {
        if (!this.viewInitialized) return;
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }
}